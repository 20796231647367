import React, { useEffect, useState } from 'react';
import { ArrayParam, useQueryParam } from 'use-query-params';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import Button from '../../../../components/button';
import { hasAccessToShipmentTransaction } from '../../../../api/shipment.request';

const PrintLabelsPage = () => {
	const [queryUrls] = useQueryParam('urls[]', ArrayParam);
	const [labels, setLabels] = useState([]);
	const [currentLabel, setCurrentLabel] = useState(null);
	const [index, setIndex] = useState(0);

	useEffect(() => {
		if (!queryUrls) return;
		const parseUrlsLabels = async () => {
			const parsedLabels = queryUrls.map((queryUrl) => JSON.parse(queryUrl));
			try {
				const accessRes = await hasAccessToShipmentTransaction(
					parsedLabels[0].transactionId
				);
				if (accessRes.data) {
					setLabels(parsedLabels);
					setCurrentLabel(parsedLabels[0]);
				}
			} catch (err) {
				console.error(err);
			}
		};
		parseUrlsLabels();
	}, [queryUrls]);

	if (!currentLabel) return null;

	return (
		<>
			<div className="flex items-center py-4 justify-center">
				<h4 className="font-bold text-center">
					{currentLabel.buyerName}
					<br />
					{' '}
					<span className="font-normal">
						{`${currentLabel.buyerAddress1}, ${
							currentLabel.buyerAddress2 && `${currentLabel.buyerAddress2},`
						} ${currentLabel.buyerCity}, ${currentLabel.buyerState}, ${
							currentLabel.buyerZip
						}`}
					</span>
				</h4>
			</div>
			<iframe
				key={index}
				src={currentLabel.url}
				title="Label"
				type="application/pdf"
				width="100%"
				height="900px"
			/>
			<div className="flex items-center space-x-2 px-2 py-2">
				<Button
					additionalClassName="flex items-center justify-center px-4"
					height="16"
					width="full"
					disabled={index == 0}
					mobileHeight="8"
					onClick={() => {
						setIndex((i) => {
							const newIndex = i - 1;
							setCurrentLabel(labels[newIndex]);
							return newIndex;
						});
					}}
				>
					<div className="flex justify-between items-center space-x-2">
						<ArrowLeftIcon className="h-8 w-8 text-white" />
						<span>Previous</span>
					</div>
				</Button>
				<Button
					additionalClassName="flex items-center justify-center px-4"
					height="16"
					width="full"
					disabled={index == labels.length - 1}
					mobileHeight="8"
					onClick={() => {
						setIndex((i) => {
							const newIndex = i + 1;
							setCurrentLabel(labels[newIndex]);
							return newIndex;
						});
					}}
				>
					<div className="flex justify-between items-center space-x-2">
						<span>Next</span>
						<ArrowRightIcon className="h-8 w-8 text-white" />
					</div>
				</Button>
			</div>

			<p className="flex font-bold justify-center pt-3">
				{`${index + 1} / ${
					labels.length
				}`}
			</p>
		</>
	);
};

export default PrintLabelsPage;
