import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/outline';
import HeaderAuthLinkButton from './header-auth-link-button';

const HeaderMobileAuthenticationButton = ({ onClick, onClickHasValue }) => (
	<Menu as="div" className="relative inline-block text-left h-full">
		<div className="h-full w-full">
			<Menu.Button className="w-12 h-12 focus:outline-none p-3">
				<UserIcon className="w-6 h-6 fill-current" />
			</Menu.Button>
		</div>

		<Transition
			as={Fragment}
			enter="transition ease-out duration-100"
			enterFrom="transform opacity-0 scale-95"
			enterTo="transform opacity-100 scale-100"
			leave="transition ease-in duration-75"
			leaveFrom="transform opacity-100 scale-100"
			leaveTo="transform opacity-0 scale-95"
		>
			<Menu.Items className="absolute right-0 z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-28">
				<div className="py-1 block h-full w-full">
					<Menu.Item>
						<HeaderAuthLinkButton
							to="/login"
							onClick={onClick}
							className="block text-center text-gray-700 text-sm my-auto mobile-auth-link-wrapper px-4 py-2"
							onClickHasValue={onClickHasValue}
						>
							Log In
						</HeaderAuthLinkButton>
					</Menu.Item>
					<Menu.Item>
						<HeaderAuthLinkButton
							to="/signup"
							onClick={onClick}
							className="block text-center text-gray-700 text-sm my-auto mobile-auth-link-wrapper px-4 py-2"
							onClickHasValue={onClickHasValue}
						>
							Sign Up
						</HeaderAuthLinkButton>
					</Menu.Item>
				</div>
			</Menu.Items>
		</Transition>
	</Menu>
);

export default HeaderMobileAuthenticationButton;
