import React from 'react';
import { navigate } from 'gatsby';
import clsx from 'clsx';
import Button from '../../components/button';
import Modal from '../../components/modals/v2';

const UpdatePhoneNumberModal = ({
	showModal,
	setShowModal,
	onCancel,
	showCancelButton
}) => (
	<Modal
		showModal={showModal}
		setShowModal={setShowModal}
		closeOnOutsideClick={false}
		hasCloseIcon={false}
		showHeader={false}
	>
		<div className="p-3 pb-5 rounded text-center">
			<h1 className="text-2xl font-bold">Stay Connected!</h1>
			<div className="flex flex-col mt-4">
				<p>
					Please add your mobile number to stay connected to DJAWN and get
					access to upcoming events and promotions.
				</p>
			</div>

			<div
				className={clsx(
					'flex justify-center text-center mt-5',
					showCancelButton && 'space-x-2'
				)}
			>
				<Button
					height="12"
					variant="secondary"
					mobileHeight="12"
					width="24"
					mobileWidth="24"
					onClick={() => {
						navigate('/profile', { state: { focusPhoneNumber: true } });
						setShowModal(false);
					}}
				>
					OK
				</Button>
				{showCancelButton && (
					<Button
						height="12"
						width="24"
						mobileHeight="12"
						mobileWidth="24"
						onClick={() => {
							onCancel();
						}}
					>
						No Thanks!
					</Button>
				)}
			</div>
		</div>
	</Modal>
);

export default UpdatePhoneNumberModal;
