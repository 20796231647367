import React, { useState, useContext, useEffect } from 'react';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../../components/button';
import Modal from '../../../components/modals';
import { TwilioTrackContext } from '../../../contexts/twilio-track';

const clipLoaderCss = css`
	display: block;
	border-color: white;
`;
const ParticipantMicBuyerOptions = ({ showModal, setShowModal }) => {
	const [closeMicClicked, setCloseMicClicked] = useState(false);
	const [mutingMicClicked, setMutingMicClicked] = useState(false);
	const {
		mutedByBuyer,
		twilioParticipantId,
		muteOrUnmuteMicParticipants,
		closeMic,
		closingMic,
		setClosingMic,
		mutingMic,
		setMutingMic
	} = useContext(TwilioTrackContext);

	useEffect(() => {
		if (!closeMicClicked || closingMic) return;
		setShowModal(false);
	}, [closingMic, closeMicClicked]);

	useEffect(() => {
		if (!mutingMicClicked || mutingMic) return;
		setShowModal(false);
	}, [mutingMic, mutingMicClicked]);

	if (!showModal) return <></>;
	return (
		<Modal
			showModal={showModal}
			onClose={() => setShowModal(false)}
			hideCloseButton
		>
			<div>
				<p>Your mic is active.</p>
				<div className="mt-3 flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5">
					<div className="mr-3">
						<Button
							height="12"
							width="32"
							additionalClassName={
								closingMic ? 'flex items-center justify-center' : ''
							}
							mobileWidth="24"
							mobileHeight="10"
							disabled={closingMic}
							onClick={() => {
								setClosingMic(true);
								setCloseMicClicked(true);
								closeMic([twilioParticipantId], false, false);
							}}
						>
							{closingMic && <ClipLoader size={30} css={clipLoaderCss} />}
							<>
								<span>Close the Mic</span>
							</>
						</Button>
					</div>
					<div className="mr-3">
						<Button
							height="12"
							width="24"
							mobileWidth="24"
							mobileHeight="10"
							additionalClassName={
								mutingMic ? 'flex items-center justify-center' : ''
							}
							disabled={mutingMic}
							onClick={() => {
								setMutingMicClicked(true);
								setMutingMic(true);
								// TODO: Confirm if the null userId is going as null rather then "null"
								muteOrUnmuteMicParticipants(
									[
										{
											twilioParticipantId,
											userId: null
										}
									],
									!mutedByBuyer,
									false,
									false
								);
							}}
						>
							{mutingMic && <ClipLoader size={30} css={clipLoaderCss} />}
							<>
								<span>{mutedByBuyer ? 'Unmute' : 'Mute'}</span>
							</>
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ParticipantMicBuyerOptions;
