import React from 'react';

import LayoutHome from '../components/layouts/layout-home';
import HeaderHome from '../components/common/header-home';
import SEO from '../components/seo';

const FavoritesPage = () => (
	<LayoutHome>
		<SEO title="Home" auth />
		<div className="bg-gray-100 mx-auto w-full h-full pt-0">
			<HeaderHome auth />
			<div className="flex bg-red-100">Favorites</div>
		</div>
	</LayoutHome>
);

export default FavoritesPage;
