import React, { Fragment, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

const Modal = ({
	showModal,
	setShowModal,
	children,
	title,
	closeOnOutsideClick = true,
	hasCloseIcon = true,
	showHeader = true
}) => {
	const closeModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const doNothing = useCallback(() => {}, []);

	return (
		<>
			<Transition appear show={showModal} as={Fragment}>
				<Dialog
					as="div"
					static
					className="fixed inset-0 overflow-y-auto"
					style={{ zIndex: 120 }}
					onClose={closeOnOutsideClick ? closeModal : doNothing}
				>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							{/* <Dialog.Overlay className="bg-gray-500 bg-opacity-75 transition-opacity" style={{ zIndex: '1040!important' }} /> */}
							<Dialog.Overlay
								className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
								style={{ zIndex: '1040!important' }}
							/>
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="animate-modal-enter duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="animate-modal-exit duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="inline-block overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl bg-white dark:bg-darkGray-50">
								{showHeader && (
									<div
										className={clsx(
											'px-2 my-4',
											hasCloseIcon && 'flex justify-between'
										)}
									>
										<Dialog.Title
											as="h3"
											className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
										>
											{title}
										</Dialog.Title>
										{hasCloseIcon && (
											<XIcon
												className="w-6 h-6 cursor-pointer text-gray-500 hover:text-gray-400"
												onClick={closeModal}
											/>
										)}
									</div>
								)}
								<div
									className={clsx(
										'mt-2 text-gray-500 dark:border-gray-600 pt-2',
										showHeader && 'border-t'
									)}
								>
									{children}
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};
export default Modal;
