import React, { useEffect, useState, useContext } from 'react';
import { navigate } from 'gatsby';
import { useQueryParam, StringParam } from 'use-query-params';
import { isMobile, isSafari } from 'react-device-detect';
import Button from './button';
import useBooleanQueryParam from '../hooks/useBooleanQueryParam';
import { SoundNotificationContext } from '../contexts/sound-notification';

// const silentAudio = require('../assets/sounds/1-second-of-silence.mp3');

const RoomPolicyConfirmation = ({
	showModal,
	onConfirm,
	onCancel,
	showEventDetailsButton,
	eventId,
	isHost
}) => {
	const [linkedEventId] = useQueryParam('linkedEventId', StringParam);
	const [isBreak] = useBooleanQueryParam('isBreak');
	const [emptyAudio, setEmptyAudio] = useState(null);
	const [safariAudioLoaded, setSafariAudioLoaded] = useState(false);
	const { onCreateAudioElements } = useContext(SoundNotificationContext);

	useEffect(() => {
		if (
			typeof window === 'undefined'
			|| safariAudioLoaded
			|| !isSafari
			|| !isHost
			|| !isMobile
		) {
			return;
		}

		const audio = new Audio(
			'https://cdndev.djawn.com/sound_notifications/src_assets_sounds_1-second-of-silence.mp3'
		);

		const canPlayThroughEvent = () => {
			setEmptyAudio(audio);
			setSafariAudioLoaded(true);
		};

		audio.addEventListener('canplaythrough', canPlayThroughEvent);
		audio.addEventListener('error', (err) => {
			console.error(err);
			setSafariAudioLoaded(true);
		});
		audio.load();

		return () => audio.removeEventListener('canplaythrough', canPlayThroughEvent);
	}, [window, isSafari, isMobile, isHost, emptyAudio, safariAudioLoaded]);

	return (
		<>
			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
							<div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
								<svg
									className="cursor-pointer w-6 h-6"
									onClick={() => {
										onCancel();
									}}
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</div>
							<div className="flex flex-col px-6 py-5 bg-gray-50 mb-10">
								<p className="flex flex-col font-semibold text-gray-700 pt-10 mb-5 px-10 text-lg">
									{linkedEventId && linkedEventId.length && isBreak ? (
										<span className="text-center">
											This linked event is live because the main event is now
											live.
											<br />
											You can click "Buy Slots" to view and purchase slot/s or
											enter the live streaming event.
										</span>
									) : (
										<span>
											This event is being recorded. By joining, you are giving
											consent for this event to be recorded.
										</span>
									)}
									<a
										href="#"
										onClick={() => {
											window.open(
												'https://support.djawn.com/privacy-policy',
												'_blank'
											);
										}}
										className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 flex justify-center"
									>
										Please click here to read our privacy policy.
									</a>
								</p>
								<div className="flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5 space-x-1 md:space-x-2">
									<div>
										<Button
											width="auto"
											mobileWidth="auto"
											height="12"
											roundedSize="sm"
											textSize="sm"
											mobileHeight="12"
											onClick={onCancel}
											additionalClassName="flex items-center justify-center px-6 py-2 mx-auto md:py-4 md:text-lg  md:px-10 "
										>
											Cancel
										</Button>
									</div>
									{showEventDetailsButton
									|| (linkedEventId && linkedEventId.length) ? (
											<div>
												<Button
													width="auto"
													mobileWidth="auto"
													height="12"
													mobileHeight="12"
													textSize="sm"
													roundedSize="sm"
													additionalClassName="flex items-center justify-center px-6 py-2 mx-auto md:py-4 md:text-lg md:px-10 font-medium"
													onClick={() => {
														if (linkedEventId && linkedEventId.length) {
															navigate(`/event/${linkedEventId}`);
														} else {
															navigate(`/event/${eventId}`);
														}
													}}
												>
													{isBreak ? 'Buy Slots' : 'Event Details'}
												</Button>
											</div>
										) : null}
									<div>
										<Button
											width="auto"
											mobileWidth="auto"
											height="12"
											textSize="sm"
											mobileHeight="12"
											roundedSize="sm"
											variant="secondary"
											disabled={
												isHost && isSafari && isMobile && !safariAudioLoaded
											}
											additionalClassName="flex items-center justify-center px-6 py-2 mx-auto md:py-4 md:text-lg md:px-10 font-medium"
											onClick={() => {
												if (isSafari && isHost && isMobile) {
													onCreateAudioElements();
													emptyAudio.play();
												}

												onConfirm();
											}}
										>
											Enter Room
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black" />
				</>
			) : null}
		</>
	);
};

export default RoomPolicyConfirmation;
