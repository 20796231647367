import React, { useState, useMemo } from 'react';
import { XCircleIcon } from '@heroicons/react/outline';
import { ClipLoader } from 'react-spinners';
import clsx from 'clsx';
import TextInput from '../input/text-input';
import Button from '../button';

const PromoCode = ({
	appliedDiscount,
	onPromoCodeApplied,
	onRemoveDiscount
}) => {
	const [promoCode, setPromoCode] = useState('');
	const [loading, setLoading] = useState(false);

	const promoCodeDisabled = useMemo(() => {
		if (loading) return true;
		if (!promoCode || !promoCode.length) return true;
		return false;
	}, [promoCode, loading]);

	return (
		<div className="h-1/2 md:w-1/2 lg:w-1/3 w-full p-4">
			<div className="flex flex-col space-y-2 border p-3 rounded-md shadow-md">
				{appliedDiscount ? (
					<div className="flex justify-center items-center space-x-4">
						<div className="flex flex-col justify-center items-center">
							<div className="flex justify-center items-center">
								<h4>Promo code has been applied!</h4>
							</div>
							<h5 className="text-gray-400">{appliedDiscount.discountCode}</h5>
						</div>
						<XCircleIcon
							onClick={() => {
								setPromoCode('');
								onRemoveDiscount();
							}}
							className="flex justify-center items-center text-center h-8 w-8 cursor-pointer hover:text-gray-500"
						/>
					</div>
				) : (
					<>
						<h4>Add a Promo Code</h4>
						<div className="flex h-10">
							<TextInput
								style={{ borderRadius: '0.375rem 0 0 0.375rem' }}
								value={promoCode}
								height="full"
								mobileHeight="full"
								onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
							/>
							<Button
								style={{ borderRadius: '0 0.375rem 0.375rem 0' }}
								variant="dark"
								height="full"
								mobileHeight="full"
								mobileWidth="1/3"
								width="1/3"
								additionalClassName={clsx(
									'px-4',
									loading && 'flex justify-evenly items-center'
								)}
								disabled={promoCodeDisabled}
								onClick={() => {
									setLoading(true);
									onPromoCodeApplied(promoCode).finally(() => setLoading(false));
								}}
							>
								{loading && <ClipLoader color="white" size={30} />}
								<span>APPLY</span>
							</Button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default PromoCode;
