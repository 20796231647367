const config = {
	// api_url: process.env.GATSBY_API_URL || 'https://djawndev.com/',
	// api_url: process.env.GATSBY_API_URL || 'https://localhost:44311/',
	api_url: process.env.GATSBY_API_URL || 'https://uatapi.djawn.com/',
	// frontend_rul: process.env.GATSBY_FE_URL || 'https://dev.djawn.com/',
	fb_app_id: process.env.GATSBY_FB_APPID || '402314480870207',
	google_places_key:
        process.env.GATSBY_GOOGLE_PLACES_API_KEY
        || 'AIzaSyDzWN4ogJ1_pfc41k8tZrIAfTqrGu3XHzo',
	google_captcha_key: process.env.GATSBY_GOOGLE_CAPTCHA_KEY || '',
	google_captcha_secret: process.env.GATSBY_GOOGLE_CAPTCHA_SECRET || '',
	google_client_id:
        process.env.GATSBY_GOOGLE_AUTH_CLIENT
        || '752116776790-i10f3c209g0p86k1om6lug7hiskp6ap1.apps.googleusercontent.com',
	google_secret:
        process.env.GATSBY_GOOGLE_AUTH_SECRET || 'gN4gwMRRJvznuC7nZHVcx5A1',
	stripe_key: process.env.GATSBY_STRIPE_PK || 'pk_test_51JNomQEuvNQkdmUEDY7pha18yJmONxh27KxpUbCELYLZBCK63YUKRc7rgkZkRd4fz3UdBHjplQDt6Rg9uek1372e00EAsOzxmC',
	google_analytics_key: process.env.GATSBY_GOOGLE_ANALYTICS_KEY || 'G-2RQWB6Z1K0',
	apple_client_id: process.env.GATSBY_APPLE_CLIENT_ID || 'com.djawn.djawn.sid',
	klaviyo_company_id: process.env.GATSBY_KLAVIYO_COMPANY_ID || 'TrDEBw'
};

const env = config;
export default env;
