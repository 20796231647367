import React, { useState } from 'react';
import Button from '../../components/button';
import AddEventProductModal from './add-event-product-modal';

const EventDetailsAddProduct = ({
	excludedProductIds,
	eventId,
	onProductsAdded,
	isRipNShip,
	onAddClicked = () => {},
}) => {
	const [showAddProductModal, setShowAddProductModal] = useState(false);
	return (
		<>
			<AddEventProductModal
				showModal={showAddProductModal}
				setShowModal={setShowAddProductModal}
				excludedProductIds={excludedProductIds}
				onProductsAdded={onProductsAdded}
				eventId={eventId}
				isRipNShip={isRipNShip}
			/>
			<div className="flex flex-row w-2/3 xxs:w-11/12 mx-auto text-left py-2">
				<Button
					outline
					width="32"
					mobileWidth="full"
					onClick={() => {
						onAddClicked();
						setShowAddProductModal(true);
					}}
				>
					Add Product
				</Button>
			</div>
		</>
	);
};

export default EventDetailsAddProduct;
