import React from 'react';
import { ExclamationIcon } from '@heroicons/react/outline';

export const isTextTruncated = (el) => el.offsetWidth < el.scrollWidth;
export const isMultilineTextTruncated = (el) => el.scrollHeight > el.clientHeight;
export const getOrderStatusParagaph = (row, status, prefixText = '') => {
	if (row.hasPendingRefund) {
		return (
			<div className="inline-flex items-center">
				{prefixText && prefixText.length && (
					<b className="pr-1">{prefixText}</b>
				)}
				<ExclamationIcon className="w-6 h-6 mr-2" />
				{status
					&& status.referenceDataValue
					&& status.referenceDataDescription && (
					<>
						{status.referenceDataValue}
						<div data-tip={status.referenceDataDescription}>
							<svg
								className="w-6 h-6"
								fill="black"
								stroke="white"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
								/>
							</svg>
						</div>
					</>
				)}
			</div>
		);
	}
	return (
		<div className="inline-flex items-center">
			{prefixText && prefixText.length && (
				<b className="pr-1">
					{prefixText}
					{' '}
				</b>
			)}
			{status && status.referenceDataValue && status.referenceDataDescription && (
				<>
					{status.referenceDataValue}
					<div data-tip={status.referenceDataDescription}>
						<svg
							className="w-6 h-6"
							fill="black"
							stroke="white"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
					</div>
				</>
			)}
		</div>
	);
};

export const focusInputToTheNextEmptyInput = (inputRefs) => {
	let index = 0;
	for (let i = 0; i < inputRefs.current.length; i++) {
		if (inputRefs.current[i].value === '') {
			index = i;
			break;
		}
	}

	inputRefs.current[index].focus();
};
