import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getReferenceDataById } from '../api/referenceData.request';

const useReferenceDataValue = (
	referenceDataId,
	type = 'boolean',
	onFetch = null,
	dependencies = []
) => {
	const [value, setValue] = useState(null);
	const [loading, setLoading] = useState(true);

	const fetchedRef = useRef(false);

	useEffect(() => {
		if (!referenceDataId) return;
		if (dependencies.find((dep) => dep == false) != null) return;
		if (fetchedRef.current == true) return;
		getReferenceDataById(referenceDataId)
			.then((res) => {
				if (res && res.data) {
					let { referenceDataValue } = res.data;
					if (type == 'boolean') {
						referenceDataValue = referenceDataValue == '1';
					}

					setValue(referenceDataValue);

					if (onFetch && typeof onFetch == 'function') {
						onFetch(referenceDataValue);
					}

					fetchedRef.current = true;
				}
				return null;
			})
			.finally(() => setLoading(false));
	}, [referenceDataId, dependencies]);

	return { value, loading, type };
};
useReferenceDataValue.propTypes = {
	referenceDataId: PropTypes.number.isRequired
};

export default useReferenceDataValue;
