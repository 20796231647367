import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import Layout from '../components/layouts/layout-default';
import Header from '../components/header';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const ShippingPolicy = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// authState();
		setLoading(false);
	}, []);

	return (
		<Layout>
			<div className="flex flex-col flex-1">
				<Header auth />
				{loading && (
					<div className="w-screen h-screen bg-white absolute left-0 top-0 z-50">
						<div className="flex h-full items-center">
							<BounceLoader loading css={override} size={30} />
						</div>
					</div>
				)}

				{!loading && (
					<div className="flex flex-col flex-1 justify-start content-center">
						<div className="flex flex-1 flex-col justify-items-center bg-white w-8/12 mx-auto px-6 py-10 rounded-lg">
							<h2 className="text-xl text-gray-700 font-semibold text-center sm:text-lg sm:max-w-xl sm:mx-auto  md:text-xl mb-4">
								Shipping Policy
							</h2>
							<div className="w-6 h-0.5 mx-auto bg-gray-600 mb-6" />

							<div className="flex flex-col w-full mx-auto px-16">
								<p className="text-lg font-semibold text-gray-800 mt-0 mb-2">
									Mainland USA
								</p>
								<p className="text-base font-normal text-gray-900">
									Free shipping on all items ordered and delivered to locations
									within the United States. All items will ship by the following
									Monday(after the event).
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-8 mb-2">
									Does DJAWN ship outside the USA
								</p>
								<p className="text-base font-normal text-gray-900">
									We currently ship anywhere inside the United States. We do not
									ship internationally outside the United States at this time.
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default ShippingPolicy;
