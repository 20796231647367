/* eslint-disable no-nested-ternary */
/* eslint-disable array-bracket-spacing */
import React, { useCallback, useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useQueryParam, StringParam } from 'use-query-params';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import { isMobile } from 'react-device-detect';
import Layout from '../components/layouts/layout-default';
import SEO from '../components/seo';
import Sidebar from '../components/common/sidebar';
import HeaderHome from '../components/common/header-home';
import { reserveProduct } from '../api/product.request';
import {
	getStripeMerchantOnBoard,
	saveShipmentCode,
	showOnBoardProcess,
	showShipmentOnboard
} from '../api/seller.request';
import MiniCart from '../components/common/mini-cart';
import {
	addCartItem,
	removeCartItem,
	updateCartItemTimer
} from '../state/action';
import { defaultToastError } from '../utils/toast-error';
import SellerOnBoardModal from '../components/seller-onboard-modal';
import StripeMissingInformationModal from '../components/stripe-missing-information-modal';
import { redirectToDefaultBrowserIfInsideWebViewAndroid } from '../utils/redirect';
import useBooleanQueryParam from '../hooks/useBooleanQueryParam';
import WordpressPostContent from '../components/wordpress-post-content';
import { getExternalHomepageContentUrl } from '../api/externalPages.request';
import Loader from '../components/loader/index';
import { isUrl } from '../utils/url';
import {
	SHIPPO_ONBOARD_STATE,
	SHIPPO_REDIRECT_TO_PROFILE_PAGE_STATE
} from '../utils/constants/cache-keys';
import SellerShipmentOnBoardModal from '../components/seller-shipment-onboard-modal';

const browser = typeof window !== 'undefined' && window;

const showError = (message) => {
	toast.error(message, {
		position: 'top-center',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'colored'
	});
};

const HomePage = ({ cartItems, dispatch }) => {
	const [homePageContentUrl, setHomePageContentUrl] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [isCartOpen, setIsCartOpen] = useState(false);
	const [showOnBoardProcessModal, setShowOnBoardProcessModal] = useState(false);
	const [onboardingUrl, setOnboardingUrl] = useState(null);
	const [
		showSellerShipmentOnBoardModal,
		setShowSellerShipmentOnBoardModal
	] = useState(false);
	const [shipmentOnboardingUrl, setShipmentOnboardingUrl] = useState(null);
	const [shipmentOnboardingState, setShipmentOnboardingState] = useState(null);
	const [buyNowPaymentIdToProcess] = useQueryParam(
		'buyNowPaymentIdToProcess',
		StringParam
	);
	const [openCart] = useBooleanQueryParam('openCart');
	const [redirectEventId] = useQueryParam('redirectEventId', StringParam);
	const [processIframe, setProcessIframe] = useState(false);
	const [shipmentState] = useQueryParam('state', StringParam);
	const [shipmentCode] = useQueryParam('code', StringParam);
	const [showSellerOnBoardModal, setShowSellerOnBoardModal] = useState(false);
	const [stripeDashboardLoginLink, setStripeDashboardLoginLink] = useState(
		null
	);
	const [showStripeDashboardModal, setShowStripeDashboardModal] = useState(
		false
	);
	const [redirectToStep3, setRedirectToStep3] = useState(false);
	const [, setItemsLoaded] = useState(false);

	useEffect(() => {
		redirectToDefaultBrowserIfInsideWebViewAndroid();
	}, [window]);

	useEffect(() => {
		if (openCart === true) {
			setIsCartOpen(true);
		}
	}, [openCart]);

	useEffect(() => {
		if (!buyNowPaymentIdToProcess) return;
		const intervalId = setInterval(() => {
			const payment = localStorage.getItem(buyNowPaymentIdToProcess);
			if (payment) {
				const paymentObject = JSON.parse(payment);
				if (paymentObject && paymentObject.url) {
					clearInterval(intervalId);
					window.location.href = paymentObject.url;
				}
			}
		}, 1000);
	}, [buyNowPaymentIdToProcess]);

	const changeProductQuantityToCart = async (product, quantity) => {
		if (product.quantity + quantity <= 0) {
			showError('You cannot choose a quantity of 0');
			return;
		}

		reserveProduct(product.eventId, product.id, quantity)
			.then(() => {
				dispatch(removeCartItem(product));
				product.quantity += quantity;
				dispatch(addCartItem(product));
			})
			.catch((err) => {
				if (err.response) {
					const { isSlotAvailable } = err.response.data;
					if (!isSlotAvailable) {
						showError('Adding additional products is not possible');
					}
				}
			});
	};

	useEffect(() => {
		if (!shipmentState || !shipmentCode) {
			setProcessIframe(true);
			return;
		}
		setProcessIframe(false);
		const shipmentStateInLocalStorage = localStorage.getItem(
			SHIPPO_ONBOARD_STATE
		);
		if (
			!shipmentStateInLocalStorage
			|| shipmentStateInLocalStorage != shipmentState
		) {
			const shipmentRedirectStateInLocalStorage = localStorage.getItem(
				SHIPPO_REDIRECT_TO_PROFILE_PAGE_STATE
			);
			if (
				shipmentRedirectStateInLocalStorage
				&& shipmentRedirectStateInLocalStorage == shipmentState
			) {
				localStorage.removeItem(SHIPPO_REDIRECT_TO_PROFILE_PAGE_STATE);
				navigate('/profile', {
					state: {
						shipmentCode
					}
				});
				return;
			}
			setProcessIframe(true);
			return;
		}

		saveShipmentCode(shipmentCode)
			.then(() => {
				localStorage.removeItem(SHIPPO_ONBOARD_STATE);
				navigate('/');
			})
			.catch((err) => {
				console.error(err);
			});
	}, [shipmentState, shipmentCode]);

	useEffect(() => {
		if (!processIframe) return;
		setLoading(true);
		getExternalHomepageContentUrl()
			.then((response) => {
				if (
					response.data
					&& response.data.isExternalHomePageEnabled
					&& response.data.homePageContentUrl
					&& isUrl(response.data.homePageContentUrl)
				) {
					setHomePageContentUrl(response.data.homePageContentUrl);
				} else {
					navigate('/events');
				}
			})
			.catch((err) => {
				console.error(err);
				navigate('/events');
			});

		showOnBoardProcess()
			.then((onBoardRes) => {
				if (onBoardRes.data === true) {
					getStripeMerchantOnBoard(false)
						.then((res) => {
							if (res.data.url) {
								setOnboardingUrl(res.data.url);
								setShowOnBoardProcessModal(true);
							}
						})
						.catch((err) => {
							console.error(err);
						});
				} else {
					showShipmentOnboard()
						.then((res) => {
							if (res.data.showOnBoard) {
								setShipmentOnboardingState(res.data.state);
								setShipmentOnboardingUrl(res.data.onboardUrl);
								setShowSellerShipmentOnBoardModal(true);
							}
						})
						.catch((err) => {
							console.error(err);
						});
				}
			})
			.catch((err) => {
				console.error('err', err);
			});
	}, [processIframe]);

	const toggleSidebar = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	return (
		browser && (
			<>
				<Layout>
					<div className="flex flex-col flex-grow w-full bg-white">
						<SEO title="Upcoming Events" />
						<ToastContainer
							position="top-center"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						{stripeDashboardLoginLink && (
							<StripeMissingInformationModal
								showModal={showStripeDashboardModal}
								setShowModal={setShowStripeDashboardModal}
								url={stripeDashboardLoginLink}
							/>
						)}
						{showSellerOnBoardModal && (
							<SellerOnBoardModal
								showModal={showOnBoardProcessModal}
								setShowModal={setShowOnBoardProcessModal}
								url={onboardingUrl}
							/>
						)}
						{showSellerShipmentOnBoardModal && (
							<SellerShipmentOnBoardModal
								showModal={showSellerShipmentOnBoardModal}
								setShowModal={setShowSellerShipmentOnBoardModal}
								url={shipmentOnboardingUrl}
								state={shipmentOnboardingState}
							/>
						)}
						<HeaderHome
							showCart
							showHelpIconByDefault
							cartOnClick={useCallback(() => setIsCartOpen(!isCartOpen), [
								isCartOpen
							])}
							cartItemCount={useCallback(() => {
								let total = 0;
								cartItems.forEach((cartItem) => {
									if (cartItem.quantity) {
										total += cartItem.quantity;
									} else {
										total += 1;
									}
								});
								return total;
							}, [cartItems])}
							onChange={toggleSidebar}
							setLoading={setLoading}
							hideSearch
							setShowSellerOnBoardModal={setShowSellerOnBoardModal}
							onSellerConfirmed={useCallback(() => {}, [])}
							onError={useCallback((errorMessage) => {
								defaultToastError(errorMessage);
							}, [])}
							onStripeLoginLinkGenerated={useCallback((url) => {
								setStripeDashboardLoginLink(url);
								setShowStripeDashboardModal(true);
							}, [])}
						/>
						<div className="flex flex-row flex-1">
							<Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
							<div
								className={`flex flex-1 flex-col pl-0 sm:pl-0 ${
									isOpen && 'md:pl-60 lg:pl-60'
								}`}
							>
								{loading ? (
									<div className="flex flex-row text-3xl not-italic font-sans font-semibold tracking-wide mb-1 cursor-pointer break-all justify-center items-center content-center justify-center items-center h-full">
										<Loader
											size={30}
											overridenCss={css`
												top: 50%;
												left: 50%;
												position: ${isMobile ? 'fixed' : 'static'};
												transform: translate(-50%, -50%);
											`}
										/>
									</div>
								) : null}
								<WordpressPostContent
									showContent={!loading}
									url={homePageContentUrl}
									onLoad={() => {
										setLoading(false);
									}}
									onError={() => {
										navigate('/events');
									}}
								/>
							</div>
						</div>

						<MiniCart
							isOpen={isCartOpen}
							showError={(item) => {
								showError(item);
							}}
							cartOpenedFromLiveEvent={() => openCart === true}
							redirectEventId={redirectEventId}
							updateTimerDispatch={(item) => {
								dispatch(updateCartItemTimer(item));
							}}
							cartItemLength={() => {
								let total = 0;
								cartItems.forEach((cartItem) => {
									if (cartItem.quantity) {
										total += cartItem.quantity;
									} else {
										total += 1;
									}
								});
								return total;
							}}
							removeItemDispatch={(item) => {
								dispatch(removeCartItem(item));
							}}
							addProductQuantityToCart={(product) => {
								changeProductQuantityToCart(product, 1);
							}}
							decreaseProductQuantityFromCart={(product) => changeProductQuantityToCart(product, -1)}
							closeCart={() => {
								if (openCart === true && window && window.close) {
									window.close();
								} else {
									setIsCartOpen(!isCartOpen);
								}
							}}
							items={cartItems}
							redirectToStep3={redirectToStep3}
							setRedirectToStep3={setRedirectToStep3}
							onLoadedItems={() => {
								setItemsLoaded(true);
							}}
						/>
					</div>
				</Layout>
			</>
		)
	);
};

export default connect(
	(state) => ({
		cartItems: state.cart.cartItems
	}),
	null
)(HomePage);
