import React, {
	useState, useEffect, useRef, useCallback
} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import DataTable from 'react-data-table-component';
import { Link } from 'gatsby';
import { isMobile } from 'react-device-detect';
import Layout from '../components/layouts/layout-default';
import HeaderHome from '../components/common/header-home';
import { defaultToastError } from '../utils/toast-error';
import {
	getProductsPagination,
	updateProductStatus
} from '../api/product.request';
import { getProductStatuses } from '../api/referenceData.request';
import ProductStatusConfirmation from '../components/product-status-confirmation';
import ProductCsvImportModal from '../components/product-csv-import-modal';
import Loader from '../components/loader';
import useDebounce from '../hooks/useDebounce';
import StatusBadge from '../components/status-badge/index';
import Button from '../components/button';
import Sidebar from '../components/common/sidebar';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const columns = [
	{
		name: 'Product Name',
		selector: 'productName',
		sortable: true,
		cell: (row) => (
			<Link
				className="font-bold hover:underline"
				to={`/product/${row.productId}`}
			>
				{row.productName}
			</Link>
		),
		wrap: true
	},
	{
		name: 'Status',
		selector: 'productStatus',
		cell: (row) => <StatusBadge referenceDataId={row.productStatusId} />,
		sortable: true,
		wrap: true
	},
	{
		name: 'Qty Available',
		selector: 'inventory',
		sortable: true,
		wrap: true
	},
	{
		name: 'Qty Reserved',
		selector: 'reserved',
		sortable: true,
		wrap: true
	}
];

const ProductPage = () => {
	const [loading, setLoading] = useState(true);
	const [productFetchingLoading, setProductFetchingLoading] = useState(true);
	const [products, setProducts] = useState([]);
	const [tableProducts, setTableProducts] = useState([]);
	const [status, setStatus] = useState(null);
	const [statuses, setStatuses] = useState([]);
	const [prevSelectedProducts, setPrevSelectedProducts] = useState([]);
	const [clearSelectedProducts, setClearSelectedProducts] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showCsvImportModal, setShowCsvImportModal] = useState(false);
	const [storeName, setStoreName] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const debounceProductSearchValue = useDebounce(searchValue, 500);
	const [selectAll, setSelectAll] = useState(false);
	const [checkForSelectedRows, setCheckForSelectedRows] = useState(true);
	const perPageRef = useRef(15);
	const currentPageRef = useRef(1);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [totalRows, setTotalRows] = useState(30);

	const dataTableRef = useRef();
	const showError = (message) => {
		toast.error(message, {
			position: 'top-center',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};

	const fetchProductsPagination = useCallback(
		async (page, size = perPageRef.current, fetchingByStatus = false) => {
			setProductFetchingLoading(true);
			getProductsPagination(
				page,
				size,
				debounceProductSearchValue,
				status === 0 ? null : status
			)
				.then((res) => {
					if (res.data && res.data.length > 0) {
						if (res.data.length < perPageRef.current) {
							setTotalRows(currentPageRef.current * perPageRef.current);
						} else {
							setTotalRows(
								currentPageRef.current * perPageRef.current + perPageRef.current
							);
						}
						const data = res.data.filter((p) => p.productStatusId !== 119);
						if (data && data.length > 0) {
							const tempProducts = data.map((product, index) => {
								if (index == 0 && !storeName) {
									setStoreName(product.account.companyName);
								}
								const tempProduct = {
									productName: product.productName,
									productStatusId: product.productStatusId,
									productStatus: product.productStatus.referenceDataValue,
									productTypeId: product.productTypeId,
									productType: product.productType.referenceDataValue,
									inventory: product.availableStock,
									productId: product.productId,
									hasImage: product.hasImage,
									reserved:
										product.availableStock - product.totalAvailableStock,
									tags: product.tags
								};
								return tempProduct;
							});
							setProducts(tempProducts);
						}
					} else if (fetchingByStatus && currentPageRef.current == 1) {
						setTableProducts([]);
						setPrevSelectedProducts([]);
					} else {
						setTableProducts([]);
						setPrevSelectedProducts([]);
					}
				})
				.catch((err) => {
					if (err.response && err.response.data.error) {
						defaultToastError(err.response.data.error);
					}
				})
				.finally(() => {
					setLoading(false);
					setProductFetchingLoading(false);
				});
		},
		[
			perPageRef.current,
			storeName,
			debounceProductSearchValue,
			status,
			currentPageRef.current
		]
	);

	useEffect(() => {
		fetchProductsPagination(currentPageRef.current, perPageRef.current);
	}, [perPageRef.current]);

	useEffect(() => {
		getProductStatuses()
			.then((res) => {
				const tempStatuses = res.data
					.map((s) => {
						if (s.referenceDataId !== 119) {
							return {
								id: s.referenceDataId,
								value: s.referenceDataValue
							};
						}
						return {
							id: 0,
							value: 'All'
						};
					})
					.filter((f) => f != null)
					.sort((a, b) => a.id - b.id);

				setStatuses(tempStatuses);
			})
			.catch((err) => {
				console.error(err);
				if (err.response && err.response.data.error) {
					defaultToastError(err.response.data.error);
				}
			});
	}, []);

	const updateSelectedProducts = useCallback(
		(selectedStatus) => {
			const productIds = [...prevSelectedProducts].map((p) => p.productId);

			updateProductStatus(productIds, selectedStatus)
				.then(() => {
					setPrevSelectedProducts([]);
					setClearSelectedProducts((prev) => !prev);
					fetchProductsPagination(currentPageRef.current - 1);
				})
				.catch((err) => {
					console.error(err);
					if (err.response && err.response.data.error) {
						defaultToastError(err.response.data.error);
					}
				});
		},
		[prevSelectedProducts]
	);

	const handleSearch = useCallback((e, text) => {
		let value = '';
		if (e && e.target) {
			value = e.target.value;
			setSearchValue(value);
		} else {
			value = text;
		}
		currentPageRef.current = 1;
	}, []);

	useEffect(() => {
		fetchProductsPagination(currentPageRef.current - 1);
	}, [debounceProductSearchValue]);

	useEffect(() => {
		if (!products.length) return;

		const tempProducts = [...products].filter((product) => {
			const isSelected =				prevSelectedProducts
				&& prevSelectedProducts.length > 0
				&& prevSelectedProducts.some((p) => p.productId === product.productId);
			product.isSelected = isSelected;

			return product;
		});
		setTableProducts(tempProducts);
	}, [products]);

	useEffect(() => {
		fetchProductsPagination(
			currentPageRef.current - 1,
			perPageRef.current,
			true
		);
		currentPageRef.current = 1;
	}, [status]);

	const onStatusChange = (value) => {
		setStatus(Number(value));
	};

	const initializePrevSelectedRows = (selectedRows) => {
		const productsToConcat = [];
		selectedRows.forEach((selectedProduct) => {
			const productExistsToPrevProducts = prevSelectedProducts.some(
				(prevProduct) => prevProduct.productId === selectedProduct.productId
			);
			if (!productExistsToPrevProducts) {
				productsToConcat.push({
					productId: selectedProduct.productId
				});
			}
		});

		const tempProductsThatUnselected = tableProducts.filter(
			(product) => !selectedRows.some(
				(selectedProduct) => product.productId === selectedProduct.productId
			)
		);
		const tempPrevSelectedProducts = prevSelectedProducts
			.filter(
				(p) => !tempProductsThatUnselected.some(
					(unselectedProducts) => unselectedProducts.productId === p.productId
				)
			)
			.filter(
				(prevProduct) => !productsToConcat.some(
					(product) => prevProduct.productId === product.productId
				)
			)
			.concat(productsToConcat);
		setPrevSelectedProducts(tempPrevSelectedProducts);
	};
	const handleSelectedRows = (state) => {
		if (!checkForSelectedRows) {
			setCheckForSelectedRows(true);
			return;
		}

		if (selectAll && !state.allSelected) {
			setSelectAll(false);
		} else if (state.allSelected) {
			setSelectAll(true);
		}

		initializePrevSelectedRows(state.selectedRows);
	};

	useEffect(() => {
		fetchProductsPagination(currentPageRef.current - 1, perPageRef.current);
	}, [currentPageRef.current]);

	const handlePageChange = (page) => {
		setCheckForSelectedRows(false);
		setClearSelectedProducts(!clearSelectedProducts);
		currentPageRef.current = page;
	};

	const handlePerRowsChange = async (newPerPage) => {
		setCheckForSelectedRows(false);
		setClearSelectedProducts(!clearSelectedProducts);
		fetchProductsPagination(currentPageRef.current - 1, newPerPage);
		perPageRef.current = newPerPage;
	};

	const toggleSidebar = useCallback(() => {
		setIsSidebarOpen(!isSidebarOpen);
	}, [isSidebarOpen]);

	if (loading) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}
	return (
		<Layout auth>
			<div className="flex flex-col flex-1">
				{isMobile ? (
					<Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
				) : null}
				<HeaderHome
					hideSearch
					onChange={toggleSidebar}
					setLoading={setLoading}
					redirectToHomeIfNotSeller
				/>
				<div className="flex flex-col flex-grow w-full h-full mt-4 items-center">
					<ToastContainer />
					<ProductCsvImportModal
						showModal={showCsvImportModal}
						setShowModal={setShowCsvImportModal}
						onError={(errorMessage) => {
							defaultToastError(errorMessage);
						}}
						setLoading={setLoading}
						onImport={() => {
							fetchProductsPagination(currentPageRef.current - 1);
						}}
					/>
					<div className="flex flex-col bg-white md:w-5/6 w-full rounded-lg content-center justify-center">
						<ProductStatusConfirmation
							showModal={showConfirmation}
							setShowModal={setShowConfirmation}
							onConfirm={() => updateSelectedProducts('deleted')}
						/>
						{products && products.length === 0 ? (
							<>
								<div className="flex w-full justify-center pb-8">
									<p className="text-2xl text-gray-700 text-center mx-auto mt-5">
										You don't have any products yet
									</p>
								</div>
								<div className="flex flex-col w-full mb-4 md:mb-0 lg:mb-0">
									<div>
										<Link to="/product">
											<Button outline height="10" mobileHeight="10">
												Add Product
											</Button>
										</Link>
									</div>
								</div>
							</>
						) : (
							<>
								<div className="w-full justify-center pb-8 shadow-lg">
									<div className="flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-start lg:items-stretch w-full">
										<div className="w-full flex flex-col md:flex-row  justify-start">
											{!prevSelectedProducts
											|| prevSelectedProducts.length === 0 ? (
													<>
														<div className="relative text-gray-600 mb-2 md:mb-0 w-full md:w-auto">
															<input
																type="search"
																name="serch"
																placeholder="Search"
																className="h-10 px-5 pl-10 text-sm focus:outline-none border rounded-md w-full md:w-auto"
																onChange={handleSearch}
															/>
															<button
																type="submit"
																className="absolute left-0 top-0 mt-3 ml-4"
															>
																<svg
																	className="h-4 w-4 fill-current"
																	xmlns="http://www.w3.org/2000/svg"
																	// xmlns:xlink="http://www.w3.org/1999/xlink"
																	version="1.1"
																	id="Capa_1"
																	x="0px"
																	y="0px"
																	viewBox="0 0 56.966 56.966"
																	// style="enable-background:new 0 0 56.966 56.966;"
																	// xml:space="preserve"
																	width="512px"
																	height="512px"
																>
																	<path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
																</svg>
															</button>
														</div>
														<div className="">
															<div className="relative rounded-md shadow-sm mb-2 md:mb-0  w-full md:w-auto">
																<select
																	id="event_type"
																	name="event_type"
																	autoComplete="event_type"
																	className="md:ml-3 ml-0 h-10 px-5 pr-10 text-sm focus:outline-none border rounded-md  w-full md:w-auto"
																	onBlur={(e) => onStatusChange(e.target.value)}
																	onChange={(e) => onStatusChange(e.target.value)}
																	value={status}
																>
																	{statuses.map((object) => (
																		<option key={object.id} value={object.id}>
																			{object.value}
																		</option>
																	))}
																</select>
															</div>
														</div>
														<div className="relative text-gray-600 md:ml-3 ml-0 mb-2 md:mb-0   w-full md:w-auto">
															<input
																type="text"
																name="serch"
																disabled
																value={storeName}
																className="h-10 px-5 pl-10 text-sm focus:outline-none border rounded-md   w-full md:w-auto"
															/>
														</div>
													</>
												) : (
													<>
														<div className="lg:ml-2 flex items-center justify-center w-40">
															<button
																type="button"
																className="border-2  border-red-600 justify-center w-full rounded-lg px-3 py-2 text-red-600 cursor-pointer hover:bg-red-600 hover:text-gray-100 text-center"
																onClick={() => {
																	updateSelectedProducts('draft');
																}}
															>
															Set as draft
															</button>
														</div>
														{' '}
														<div className="lg:ml-6 flex items-center justify-center w-40">
															<button
																type="button"
																className="border-2  border-red-600 justify-center w-full rounded-lg px-3 py-2 text-red-600 cursor-pointer hover:bg-red-600 hover:text-gray-100 text-center
																disabled:opacity-50"
																// disabled={!areProductsValidForActiveUpdate}
																onClick={() => {
																	if (!prevSelectedProducts.length > 0) return;
																	const canChange =																	[...products].filter(
																		(product) => prevSelectedProducts
																			.map((p) => p.productId)
																			.includes(product.productId)
																			&& !product.hasImage
																	).length === 0;

																	if (canChange) {
																		updateSelectedProducts('active');
																	} else {
																		showError(
																			"Products cannot be activated as some products don't have image"
																		);
																	}
																}}
															>
															Set as active
															</button>
														</div>
														{' '}
														<div className="lg:ml-6 flex items-center justify-center w-40">
															<button
																type="button"
																className="border-2  border-red-600 justify-center w-full rounded-lg px-3 py-2 text-red-600 cursor-pointer hover:bg-red-600 hover:text-gray-100 text-center"
																onClick={() => {
																// setShowModal(true);
																	updateSelectedProducts('archived');
																}}
															>
															Set as archived
															</button>
														</div>
														{' '}
														<div className="lg:ml-6 flex items-center justify-center w-40">
															<button
																type="button"
																className="border-2  border-red-600 justify-center w-full rounded-lg px-3 py-2 text-red-600 cursor-pointer hover:bg-red-600 hover:text-gray-100 text-center"
																onClick={() => {
																	setShowConfirmation(true);
																}}
															>
															Delete products
															</button>
														</div>
													</>
												)}
										</div>
										{prevSelectedProducts && prevSelectedProducts.length === 0 && (
											<div className="w-full flex flex-row items-start lg:items-center md:justify-end justify-center">
												<div className="lg:ml-6 flex h-10 items-center justify-center w-24 md:mr-0 mr-4">
													<Button
														outline
														height="full"
														mobileHeight="full"
														textSize="sm"
														width="full"
														mobileWidth="full"
														onClick={() => {
															setShowCsvImportModal(true);
														}}
													>
														Import
													</Button>
												</div>
												<div className="lg:ml-6 flex w-24 h-10 items-center">
													<Link
														to="/product"
														className="w-full h-full flex justify-center items-center"
													>
														<Button
															outline
															mobileHeight="full"
															mobileWidth="full"
															textSize="sm"
															width="full"
															height="full"
														>
															Add Products
														</Button>
													</Link>
												</div>
											</div>
										)}
									</div>
									{productFetchingLoading && (
										<div className="w-full h-32 flex items-center justify-center">
											<Loader
												overridenCss={`display: block;
													margin: 0 auto;`}
											/>
										</div>
									)}
									<div
										className="relative w-full "
										hidden={productFetchingLoading}
									>
										<div className="grid w-full overflow-x-scroll xl:overflow-x-hidden rounded-md border">
											<DataTable
												ref={dataTableRef}
												columns={columns}
												data={tableProducts}
												pagination
												paginationServer
												paginationTotalRows={totalRows}
												paginationServerOptions={{
													persistSelectedOnPageChange: true,
													persistSelectedOnSort: true
												}}
												paginationDefaultPage={1}
												onChangeRowsPerPage={handlePerRowsChange}
												onChangePage={handlePageChange}
												selectableRows
												onSelectedRowsChange={handleSelectedRows}
												selectableRowsHighlight
												noContextMenu
												className=""
												contextActions={null}
												striped
												paginationComponentOptions={{
													rowsPerPageText: 'Rows per page:',
													rangeSeparatorText: 'of',
													selectAllRowsItem: false,
													selectAllRowsItemText: 'All'
												}}
												paginationPerPage={perPageRef.current}
												paginationRowsPerPageOptions={[15, 25, 500]}
												customStyles={{
													rows: {
														style: {}
													},
													headCells: {
														style: {
															backgroundColor: '#e82e2c',
															color: 'white',
															fontSize: '1rem',
															fontWeight: 'bold'
														}
													},
													headRow: {
														style: {
															backgroundColor: '#e82e2c'
														}
													}
												}}
												clearSelectedRows={clearSelectedProducts}
												selectableRowSelected={(row) => row.isSelected}
											/>
										</div>
										{tableProducts && tableProducts.length > 0 && (
											<div
												className="absolute inset-x-0 w-max -top-2"
												style={{ maxWidth: '100%', zIndex: '20' }}
											>
												<div className="my-5 inline-block md:mr-0 mr-2 pl-4">
													<input
														type="checkbox"
														onChange={() => {
															setClearSelectedProducts(!clearSelectedProducts);
															setCheckForSelectedRows(false);
															if (tableProducts && tableProducts.length > 0) {
																const tempTableProducts = tableProducts.map(
																	(product) => ({
																		...product,
																		isSelected: !selectAll
																	})
																);
																setTableProducts(tempTableProducts);
																setSelectAll(!selectAll);
															}
															if (!selectAll) {
																initializePrevSelectedRows(products);
															} else {
																initializePrevSelectedRows([]);
															}
														}}
														style={{
															width: '17px',
															height: '17px',
															verticalAlign: 'middle'
														}}
														checked={selectAll}
													/>
												</div>
											</div>
										)}
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ProductPage;
