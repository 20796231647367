import React, { createContext } from 'react';
import useLiveEventData from '../hooks/useLiveEventData';

const LiveEventDataContext = createContext();

const LiveEventDataProvider = ({
	initialEventDetails,
	authenticated,
	eventId,
	initialLinkedEvents,
	webSocketGroupName,
	children,
	isSeller
}) => {
	const {
		views,
		activeView,
		setActiveView,
		isRandomizedBreak,
		isRipNShip,
		isPickYourSlotsBreak,
		teamsRandomization,
		loadTeamsRandomization,
		setAttendees,
		attendees,
		linkedEvents,
		setLinkedEvents,
		eventDetails,
		setEventDetails,
		hasRipNShipEvent,
		ripNShipEvent,
		setRipNShipEvent,
		mainEventDetails,
		storeInfo,
		sellerImage,
		ripNShipEventIdRef,
		preselectedBreakEventIdOnOpenedView,
		setPreselectedBreakEventIdOnOpenedView,
		ownerNickname,
		setOwnerNickname,
		eventName,
		setEventName,
		products,
		setProducts,
		loadRipNShipProducts,
		productsLoading,
		eventStartDate,
		setEventStartDate,
		productsCurrentPage,
		setProductsCurrentPage,
		productSearchValue,
		setProductSearchValue,
		productsInitiallyLoaded,
		setProductsInitiallyLoaded,
		productsTotalRows,
		productsPerPageRef,
		attendeesCount,
		attendeesSearchValue,
		setAttendeesSearchValue,
		attendeesFilterSelectedOption,
		setAttendeesFilterSelectedOption,
		generateAttendeesByParticipants,
		twilioParticipants,
		setTwilioParticipants,
		presenterIdentityRef,
		twilioParticipantsRef,
		isHost,
		setIsHost,
		isHostRef,
		eventExpirationInMinutes,
		setEventExpirationInMinutes,
		eventEndedDueToTechnicalIssues,
		setEventEndedDueToTechnicalIssues,
		hostingTheStream,
		setHostingTheStream,
		hostingTheStreamRef,
		initiallyAuthenticated,
		setProductsSortBy
	} = useLiveEventData(
		eventId,
		initialLinkedEvents,
		initialEventDetails,
		isSeller,
		authenticated
	);

	return (
		<LiveEventDataContext.Provider
			value={{
				views,
				activeView,
				setActiveView,
				isRandomizedBreak,
				isRipNShip,
				isPickYourSlotsBreak,
				teamsRandomization,
				loadTeamsRandomization,
				setAttendees,
				attendees,
				isHost,
				setIsHost,
				isHostRef,
				linkedEvents,
				setLinkedEvents,
				eventId,
				webSocketGroupName,
				eventDetails,
				setEventDetails,
				hasRipNShipEvent,
				ripNShipEvent,
				setRipNShipEvent,
				mainEventDetails,
				storeInfo,
				sellerImage,
				ripNShipEventIdRef,
				preselectedBreakEventIdOnOpenedView,
				setPreselectedBreakEventIdOnOpenedView,
				authenticated,
				ownerNickname,
				setOwnerNickname,
				eventName,
				setEventName,
				products,
				setProducts,
				loadRipNShipProducts,
				productsLoading,
				eventStartDate,
				setEventStartDate,
				productsCurrentPage,
				setProductsCurrentPage,
				productSearchValue,
				setProductSearchValue,
				productsInitiallyLoaded,
				setProductsInitiallyLoaded,
				productsTotalRows,
				productsPerPageRef,
				attendeesCount,
				attendeesSearchValue,
				setAttendeesSearchValue,
				attendeesFilterSelectedOption,
				setAttendeesFilterSelectedOption,
				generateAttendeesByParticipants,
				twilioParticipants,
				setTwilioParticipants,
				presenterIdentityRef,
				twilioParticipantsRef,
				eventExpirationInMinutes,
				setEventExpirationInMinutes,
				eventEndedDueToTechnicalIssues,
				setEventEndedDueToTechnicalIssues,
				isSeller,
				hostingTheStream,
				setHostingTheStream,
				hostingTheStreamRef,
				initiallyAuthenticated,
				setProductsSortBy
			}}
		>
			{children}
		</LiveEventDataContext.Provider>
	);
};

export { LiveEventDataContext, LiveEventDataProvider };
