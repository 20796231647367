import React from 'react';
import { UserIcon } from '../icons';

const HeaderLoginButton = ({ text = 'LOG IN', onClick }) => (
	<button
		type="button"
		onClick={onClick}
		className="bg-transparent hover:bg-red-500 text-blue-dark font-semibold hover:text-white py-1 px-2 xs:px-1 xxxs:px-1 xxs:px-2 md:py-2 md:px-4 border border-gray-800 hover:border-transparent rounded xxxs:text-xs xs:text-base text-base xxs:text-base sm:text-base"
	>
		<div className="flex flex-row items-center justify-center">
			<div className="justify-center items-center pr-1">
				<UserIcon className="w-4 h-4 fill-current" />
			</div>
			<div className="">
				<p>{text}</p>
			</div>
		</div>
	</button>
);

export default HeaderLoginButton;
