/* eslint-disable no-nested-ternary */
import React, {
	useState, useEffect, useRef, useContext, useMemo, useCallback
} from 'react';
import { createLocalAudioTrack, createLocalVideoTrack } from 'twilio-video';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import ReactTooltip from 'react-tooltip';
import {
	isMobile as isMobileSize, isTablet, isIOS, isDesktop
} from 'react-device-detect';
import clsx from 'clsx';
import { RefreshIcon } from '@heroicons/react/solid';
import { isFunction } from '../../utils/isFunction';
import { MicOffIcon, MicIcon } from './mic-icon';
import { VideoOnIcon, VideoOffIcon, ResizeIcon } from './video-icon';
import { SoundOffIcon, SoundOnIcon } from './sound-icon';
import { VideoCameraIcon, ChatIcon } from './options-icon';
import {
	formatJsonDateToProperDateObject,
} from '../../utils/formatter';
import { RaiseHandIcon } from './raise-hand-icon';
import {
	addBookmark, updateRoomStatusToStarted, updateEventRoomDate, getRipNShipBuyersQueue
} from '../../api/event.request';
import BookmarkIcon from '../bookmark-icon';

import { defaultToastSucesss } from '../../utils/toast-success';
import { defaultToastError } from '../../utils/toast-error';
import { defaultToastWarning } from '../../utils/toast-warning';
import {
	isLoggedIn
} from '../../utils/auth';
import { isTruthy } from '../../utils/commonFunction';
import EventNameInformationButton from '../../containers/event/room/event-information-button';
import LinkedEventsMobileSliderButton from '../../containers/event/room/linked-events-mobile-slider-button';
import ArrowButton from '../../containers/event/room/arrow-button';
import { TwilioTrackContext } from '../../contexts/twilio-track';
import { RipNShipActiveLineContext } from '../../contexts/ripnship-activeline';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import ParticipantMicRequest from '../../containers/event/room/participant-mic-request';
import ParticipantMicRequestDecision from '../../containers/event/room/participant-mic-request-decision';
import ParticipantMicBuyerOptions from '../../containers/event/room/participant-mic-buyer-options';
import ParticipantMicBuyerNotifier from '../../containers/event/room/participant-mic-buyer-notifier';
import { LiveEventViewsContainerContext } from '../../contexts/live-event-views-container';
import VideoTimestamp from './video-timestamp';
import AttendeesCount from './attendees-count';
import ConfirmationModal from '../confirmation-modal';
import { LiveEventChatContext } from '../../contexts/live-event-chat';
import { SettingsIcon } from './settings-icon';

function useScreenOrientation(onOrientationChange) {
	if (!window) return;
	const [orientation, setOrientation] = useState(window.orientation);
	const [landscapeMode, setLandscapeMode] = useState(false);

	useEffect(() => {
		if (!isMobileSize) return;
		if (orientation == 0 || orientation == 180) {
			document.body.style.overflowY = 'hidden';
			onOrientationChange(true);
			setLandscapeMode(false);
		} else {
			document.body.style.overflow = 'visible';
			onOrientationChange(false);
			setLandscapeMode(true);
		}
	}, [orientation]);

	useEffect(() => {
		if (!window) return;
		const handleOrientationChange = () => setOrientation(window.orientation);
		window.addEventListener('orientationchange', handleOrientationChange);
		return () => window.removeEventListener('orientationchange', handleOrientationChange);
	}, []);

	return [orientation, landscapeMode];
}

const Participant = ({
	participant,
	onRefreshButtonClicked,
	muted,
	setMuted,
	localParticipant,
	noParticipantHostingTheStream,
	isLocal = false,
	resize,
	setResize,
	onEndTurn,
	isStopped,
	changeCameraOnClick,
	lastAudioTrackIdsBeforeChangeRef,
	deviceId,
	microphoneDeviceId,
	speakerDeviceId,
	exitEventRoom,
	setVideoHeight,
	eventId,
	cameraIndex,
	onChangeHostSession,
	saveTrack,
	setLeftArrowButtonPosition,
	setTopArrowButtonPosition,
	showThankYou,
	onOrientationChange,
	showEventNameInChatContainer,
	setShowEventNameInChatContainer,
	showLinkedEventsSliderInMobileChatContainer,
	setShowLinkedEventsSliderInMobileChatContainer,
	isSeller,
	cam,
	setCam,
	videoTracks,
	audioTracks,
	videoDisabled,
	setVideoDisabled,
	hostParticipantAudioTracksRef,
	roomRef,
	onSettingsClick,
}) => {
	const iosMutedLocalAudioTimeoutRef = useRef(null);
	const [dateUpdated, setDateUpdate] = useState(false);
	const [bookmarkClicked, setBookmarkClicked] = useState(false);
	const [orientation, landscapeMode] = useScreenOrientation(onOrientationChange);
	const [remoteAudios, setRemoteAudios] = useState([]);
	const [showChangeHostSessionModal, setShowChangeHostSessionModal] = useState(false);
	const {
		requestedMicAccess,
		micAccessApprovedByBuyer,
		mutedByBuyer: isBuyerMuted,
		hasSound,
		hasSoundRef,
		setHasSound,
		setHostAudioTrackMuted,
		twilioRoomIdentityRef
	} = useContext(TwilioTrackContext);
	const [showMicRequestConfirmationModal, setShowMicRequestConfirmationModal] = useState(false);
	const [showParticipantMicOptions, setShowParticipantMicOptions] = useState(false);
	const videoRef = useRef();
	const videoDivRef = useRef();
	const audioRef = useRef();
	const attachedTracksRef = useRef([]);
	const {
		eventStartDate, setEventStartDate, authenticated,
		linkedEvents, attendeesCount, eventExpirationInMinutes,
		eventEndedDueToTechnicalIssues, setRipNShipEvent, ripNShipEventIdRef,
		hostingTheStreamRef, presenterIdentityRef, eventName
	} = useContext(LiveEventDataContext);
	const {
		arrowButtonContainerRef,
		eventNamePopupRef,
		openQueueContainer,
		arrowButtonRef,
	} = useContext(LiveEventViewsContainerContext);

	const jsonFormattedEventStartDate = useMemo(() => formatJsonDateToProperDateObject(eventStartDate), [eventStartDate]);
	const {
		ripNShipActiveBuyersInLine, breakIsInActiveLine, nameInActiveLine, endingTurn
	} = useContext(RipNShipActiveLineContext);
	const { conversation, initializeConversation } = useContext(LiveEventChatContext);

	useEffect(() => {
		if (!localParticipant) return;

		localParticipant.audioTracks.forEach((track) => {
			track.track.disable();
		});
	}, [localParticipant]);

	useEffect(() => {
		if (isLocal) {
			setDateUpdate(false);
			updateRoomStatusToStarted(eventId, true).then(() => {
				updateEventRoomDate(eventId).then((newDateRes) => {
					if (setEventStartDate) {
						setEventStartDate(newDateRes.data);
						setDateUpdate(true);
					}
				}).catch((err) => {
					console.error(err);
				});
			}).catch((err) => {
				console.error(err);
			});
		}

		if (videoRef.current.clientHeight) {
			setVideoHeight(videoRef.current.clientHeight);
		}
	}, []);

	useEffect(() => {
		if (!dateUpdated || !ripNShipEventIdRef.current) return;
		getRipNShipBuyersQueue(ripNShipEventIdRef.current).then((buyersQueue) => {
			setRipNShipEvent((tempRipNShipEvent) => ({
				...tempRipNShipEvent,
				buyersQueue: buyersQueue.data,
			}));
		}).catch((err) => {
			console.error(err);
		});
	}, [dateUpdated]);

	useEffect(() => {
		if (!deviceId || !isLocal || !videoTracks) return;

		/*
			Ideally this chunk of code can go inside the createLocalVideoTrack
			however in android cases we cant create a new local video track without
			stopping the current tracks
		*/
		const tracks = Array.from(participant.videoTracks.values()).map((t) => t.track);
		tracks.forEach((track) => track.stop());

		createLocalVideoTrack({ deviceId: { exact: deviceId } }).then((
			localVideoTrack
		) => {
			participant.videoTracks.forEach((trackPublication) => trackPublication.unpublish());
			tracks.forEach((track) => track.detach());
			participant.publishTrack(localVideoTrack);
			localVideoTrack.attach(videoRef.current);
		}).catch((err) => {
			console.log('ERROR', err);
		});
	}, [deviceId]);

	useEffect(() => {
		if (!microphoneDeviceId || !isLocal) return;

		/*
			Ideally this chunk of code can go inside the createLocalAudioTrack
			however in android cases we cant create a new local video track without
			stopping the current tracks
		*/
		const tracks = Array.from(participant.audioTracks.values()).map((t) => t.track);
		lastAudioTrackIdsBeforeChangeRef.current = tracks.map((t) => t.id);
		tracks.forEach((track) => track.stop());

		createLocalAudioTrack({ deviceId: { exact: microphoneDeviceId } }).then((
			localAudioTrack
		) => {
			participant.audioTracks.forEach((trackPublication) => trackPublication.unpublish());
			tracks.forEach((track) => track.detach());
			participant.publishTrack(localAudioTrack);
			localAudioTrack.attach(audioRef.current);
		}).catch((err) => {
			console.log('here ERROR', err);
		});
	}, [microphoneDeviceId]);

	useEffect(() => {
		if (!speakerDeviceId) return;

		if (audioRef.current && audioRef.current.setSinkId) {
			audioRef.current.setSinkId(speakerDeviceId);
		}

		const remoteAudiosEl = document.getElementById('remote-audios');
		if (remoteAudiosEl && remoteAudiosEl.childNodes && remoteAudiosEl.childNodes.length) {
			remoteAudiosEl.childNodes.forEach((audioEl) => {
				if (audioEl.setSinkId) {
					audioEl.setSinkId(speakerDeviceId);
				}
			});
		}
	}, [speakerDeviceId]);

	const iconHeight = useMemo(() => (isMobileSize ? 8 : 12), [isMobileSize]);

	useEffect(() => {
		const isSafari = /^((?!chrome|android).)*safari/i.test(
			navigator.userAgent
		);
		if (isSafari || isIOS) {
			setHasSound(false);
		}
	}, []);

	useEffect(() => {
		if (isStopped == true) {
			const track = [...videoTracks.values()][0];
			if (track) {
				track.stop();
			}
		}
	}, [isStopped]);

	useEffect(() => {
		if (!isLocal) return;

		participant.audioTracks.forEach((track) => {
			if (muted) track.track.disable();
			else track.track.enable();
		});
	}, [muted]);

	useEffect(() => {
		if (!isLocal) return;
		participant.videoTracks.forEach((track) => {
			if (!cam) track.track.disable();
			else track.track.enable();
		});
	}, [cam]);

	useEffect(() => {
		if (isFunction(participant.on)) {
			if (!isLocal) {
				participant.on('trackDisabled', (track) => {
					if (track.kind == 'video') {
						setVideoDisabled(true);
					}
				});

				participant.on('trackEnabled', (track) => {
					if (track.kind == 'video') {
						setVideoDisabled(false);
						if (isFunction(track.attach)) {
							track.attach(videoRef.current);
						}
					}
				});
			}
		}

		return () => {
			if (isFunction(participant.removeAllListeners)) {
				participant.removeAllListeners();
			}
		};
	}, [participant]);

	useEffect(() => {
		if (!videoTracks || videoTracks.length == 0) {
			return;
		}

		const videoTrack = videoTracks[0];

		if (videoTrack) {
			if (saveTrack) {
				if (typeof videoTrack != 'undefined' && videoTrack.id) {
					saveTrack(videoTrack.id, 'video', twilioRoomIdentityRef.current);
				}
			}
			videoTrack.attach(videoRef.current);
			if (!hostingTheStreamRef.current) {
				setVideoDisabled(!videoTrack.isEnabled);
			}
			return () => {
				videoTrack.detach();
			};
		}
	}, [videoTracks]);

	useEffect(() => {
		let audioTrack = audioTracks[0];

		if (audioTracks.length && isIOS && hostParticipantAudioTracksRef.current.length) {
			const foundHostAudioTracks = audioTracks.filter((track) => {
				if (track.sid) {
					return hostParticipantAudioTracksRef.current.find((hostAudioTrack) => hostAudioTrack.trackSid == track.sid && hostAudioTrack.identity == presenterIdentityRef.current) != null;
				}
				return hostParticipantAudioTracksRef.current.find((hostAudioTrack) => hostAudioTrack.trackSid == track.id == presenterIdentityRef.current) != null;
			});

			if (foundHostAudioTracks && foundHostAudioTracks.length) {
				// eslint-disable-next-line prefer-destructuring
				audioTrack = foundHostAudioTracks[0];
				setRemoteAudios([...audioTracks].filter((t) => {
					if (t.sid) return !hostParticipantAudioTracksRef.current.find((hostAudioTrack) => hostAudioTrack.trackSid == t.sid && hostAudioTrack.identity == presenterIdentityRef.current);
					return !hostParticipantAudioTracksRef.current.find((hostAudioTrack) => hostAudioTrack.trackSid == t.id && hostAudioTrack.identity == presenterIdentityRef.current);
				}));
			} else {
				setRemoteAudios([...audioTracks].filter((f, i) => i != 0));
			}
		} else {
			setRemoteAudios([...audioTracks].filter((f, i) => i != 0));
		}

		if (audioTrack) {
			if (typeof audioTrack != 'undefined' && audioTrack.id && isLocal && isLocal === true) {
				saveTrack(audioTrack.id, 'audio', twilioRoomIdentityRef.current);
			}

			setHostAudioTrackMuted(!audioTrack.isEnabled);

			audioTrack.on('disabled', () => {
				setHostAudioTrackMuted(true);
			});

			audioTrack.on('enabled', () => {
				setHostAudioTrackMuted(false);
			});

			// DJAWN-722: solve for echo
			audioTrack.mediaStreamTrack.applyConstraints({
				echoCancellation: true,
				noiseSuppression: true,
			});
			audioTrack.attach(audioRef.current);
			return () => {
				audioTrack.detach();
			};
		}
	}, [audioTracks]);

	useEffect(() => {
		if (!remoteAudios.length) return;

		const audioEl = document.getElementById('remote-audios');

		remoteAudios.forEach((audio) => {
			if (attachedTracksRef.current.find((sid) => audio.sid == sid) == null) {
				const el = document.createElement('audio');
				const elementId = audio.id ? audio.id : audio.sid;
				if (!document.getElementById(elementId)) {
					el.id = audio.id ? audio.id : audio.sid;
					el.setAttribute('autoplay', '');
					el.muted = !hasSoundRef.current;
					audioEl.appendChild(el);
					audio.attach(el);
					attachedTracksRef.current.push(audio.sid);
				}
			}
		});

		if (isLocal) {
			const isSafari = /^((?!chrome|android).)*safari/i.test(
				navigator.userAgent
			);
			if (isSafari) {
				setHasSound(true);
			}
		}
	}, [remoteAudios]);

	useEffect(() => {
		const audioEl = document.getElementById('remote-audios');
		if (!audioEl || !audioEl.children || !audioEl.children.length) return;

		for (let i = 0; i < audioEl.children.length; i++) {
			if (audioEl.children[i]) {
				audioEl.children[i].muted = !hasSound;
			}
		}
	}, [hasSound]);

	const setBookmark = useCallback(() => {
		setBookmarkClicked(true);
		const video = document.getElementsByTagName('video')[0];
		const scaleFactor = 1;
		const w = video.videoWidth * scaleFactor;
		const h = video.videoHeight * scaleFactor;
		const canvas = document.createElement('canvas');
		canvas.width = w;
		canvas.height = h;
		const ctx = canvas.getContext('2d');
		ctx.drawImage(video, 0, 0, w, h);

		canvas.toBlob((blob) => {
			const formData = new FormData();
			formData.append('image', blob, 'test.png');
			addBookmark(formData, eventId)
				.then(() => {
					defaultToastSucesss(
						'Bookmark added successfully!'
					);
				})
				.catch((err) => {
					defaultToastError(err.message);
				}).finally(() => {
					setBookmarkClicked(false);
				});
		});
	}, [eventId]);

	const participantScreen = useFullScreenHandle();

	useEffect(() => {
		if (!arrowButtonRef || !arrowButtonRef.current) return;
		const arrowButtonRect = arrowButtonRef.current.getBoundingClientRect();
		setLeftArrowButtonPosition(arrowButtonRect.left + window.scrollY);
		setTopArrowButtonPosition(arrowButtonRect.top + window.scrollY);
	}, [arrowButtonRef]);

	const onMicButtonClick = () => {
		if (!authenticated) {
			defaultToastWarning('Anonymous user can not activate the mic, please log in to activate your mic');
			return;
		}
		if (micAccessApprovedByBuyer == true) {
			setShowParticipantMicOptions(true);
		} else {
			setShowMicRequestConfirmationModal(true);
		}
	};

	const turnOffOwnLocalAudio = useCallback(() => {
		if (!micAccessApprovedByBuyer) return;
		let localAudioTrackId = null;
		roomRef.current.localParticipant.audioTracks.forEach((t) => {
			localAudioTrackId = t.track.id;
		});

		if (!localAudioTrackId) return;
		const audioEl = document.getElementById(localAudioTrackId);
		if (audioEl) {
			iosMutedLocalAudioTimeoutRef.current = setTimeout(() => {
				audioEl.muted = true;
			}, 600);
		}

		return () => clearTimeout(iosMutedLocalAudioTimeoutRef.current);
	}, [micAccessApprovedByBuyer]);

	return (
		<FullScreen
			handle={participantScreen}
			onChange={(e) => {
				if (isMobileSize && orientation == 0) {
					if (e == false) return;
					defaultToastWarning('Change orientation to landscape for fullscreen mode!');
					return;
				}
				setResize(e);
			}}
			className={`${isMobileSize || (isTablet && !landscapeMode) ? 'bg-white dark:bg-darkGray-50 border-radius-25px' : ''}`}
		>
			<ParticipantMicRequest
				showMicRequestConfirmationModal={showMicRequestConfirmationModal}
				onClose={() => setShowMicRequestConfirmationModal(false)}
				disabledRequestMicButton={noParticipantHostingTheStream}
			/>
			<ParticipantMicBuyerOptions
				showModal={showParticipantMicOptions}
				setShowModal={setShowParticipantMicOptions}
			/>
			<ConfirmationModal
				showModal={showChangeHostSessionModal}
				setShowModal={setShowChangeHostSessionModal}
				showConfirmationIcon={false}
				onConfirm={() => {
					setShowChangeHostSessionModal(false);
					if (onChangeHostSession) {
						onChangeHostSession();
					}
				}}
				cancelText="Cancel"
				confirmText="Yes"
				onCancel={() => setShowChangeHostSessionModal(false)}
				text="Your camera and mic are active in another session, do you want to active your camera and mic on this session?"
			/>
			<ParticipantMicBuyerNotifier />
			<ParticipantMicRequestDecision />
			<div
				className={` 
		 ${(isMobileSize || (isTablet && !landscapeMode)) && resize && 'h-screen'}
		 ${!isMobileSize && ('participant w-full h-full')}
    ${isTruthy(isMobileSize || (isTablet && !landscapeMode)) && 'flex flex-col justify-center'}
		`}
				id="participant"
			>
				<ReactTooltip />
				{
					isMobileSize && participantScreen.active && (orientation == 0 || orientation == 180)
						? <p className="mb-5 text-white">Change orientation to landscape for a better experience</p>
						: null
				}
				<div
					ref={videoDivRef}
					className={`relative z-10 ${isMobileSize && resize ? 'w-11/12 h-5/6 float-right' : 'h-full'} ${isTruthy(videoDisabled || showThankYou || noParticipantHostingTheStream) && (isMobileSize ? (!isTablet ? (`${landscapeMode ? 'landscape-mobile-video' : 'small-mobile-video-placeholder'}`) : '') : 'flex justify-center')}`}
				>
					{resize === false ? (
						<>
							{(videoDisabled || showThankYou || noParticipantHostingTheStream) && (
								<div
									className={`bg-black  ${isMobileSize ? (!isTablet ? `${landscapeMode ? 'landscape-mobile-video flex items-center justify-center participant-video' : 'small-mobile-video-placeholder flex items-center justify-center'}` : 'w-full h-80 flex') : 'participant-video-placeholder chat-desktop-height w-full h-full flex items-center justify-center'}`}
									// className="participant-video xs:small-mobile-video md:chat-desktop-height w-auto h-auto md:w-full md:h-full"
									style={{ objectFit: 'cover' }}
									hidden={!videoDisabled && !showThankYou && !noParticipantHostingTheStream}
								>
									{!showThankYou ? (<h2 className={`text-white font-lg ${!!(isMobileSize) && 'video-paused-font'}`}>{noParticipantHostingTheStream ? `The seller had an unexpected issue. If they do not rejoin in ${eventExpirationInMinutes} minutes the event will be closed` : 'Seller has paused the video'}</h2>) : (
										<div className="mx-10 flex items-center">
											<svg className="w-14 h-14" fill="red" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
											<p className="text-lg text-red-500">
												{eventEndedDueToTechnicalIssues ? "The event is ended by the system as the seller had an unexpected issue and couldn't rejoin" : 'The live video stream has ended. Thank you for attending!'}
											</p>
										</div>
									)}
								</div>
							)}
							<video
								ref={videoRef}
								autoPlay
								hidden={videoDisabled || showThankYou || noParticipantHostingTheStream}
								controls={isMobileSize}
								className={`participant-video ${(!videoDisabled && !showThankYou && !noParticipantHostingTheStream) ? 'block' : 'hidden'} ${isMobileSize ? (!isTablet ? `${landscapeMode ? 'landscape-mobile-video' : 'small-mobile-video no-bottom-borders'} ` : (isTablet ? ((landscapeMode && !isTablet ? '' : (isTablet && !landscapeMode ? 'tablet-portait-live-event-video-height tablet-portait-live-event-video-width' : 'chat-desktop-height'))) : 'w-full h-80')) : `participant-video ${landscapeMode && !isTablet ? '' : (isTablet && !landscapeMode ? 'tablet-portait-live-event-video-height tablet-portait-live-event-video-width' : 'chat-desktop-height')} w-full h-full`}`}
								style={{ objectFit: 'cover' }}
							/>
						</>
					) : (
						<>
							{(videoDisabled || showThankYou || noParticipantHostingTheStream) && (
								<div
									className="bg-black w-full h-full flex items-center justify-center"
									style={{ objectFit: 'cover' }}
									hidden={!videoDisabled || !showThankYou || !noParticipantHostingTheStream}
								>
									{!showThankYou ? (<h2 className={`text-white font-lg ${!!(isMobileSize) && 'video-paused-font'}`}>Seller has paused the video</h2>) : (
										<div className="pt-20 mx-10 flex items-center">
											<svg className="w-14 h-14" fill="red" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
											<p className="text-lg text-red-500">
												{eventEndedDueToTechnicalIssues ? "The event is ended by the system as the seller had an unexpected issue and couldn't rejoin" : 'The live video stream has ended. Thank you for attending!'}
											</p>
										</div>
									)}
								</div>
							)}
							<video
								ref={videoRef}
								autoPlay
								className={`mobile-participant-video w-full h-full ${(!videoDisabled && !showThankYou) ? 'block' : 'hidden'}`}
								style={{ objectFit: 'cover' }}
							/>
						</>
					)}
					{
						isMobileSize
							? (
								<div
									className="absolute top-0 right-0 h-16 bg-black"
									style={{ maxWidth: '100%', zIndex: '20' }}
								>
									<div
										className={`flex justify-between ${isMobileSize && orientation != 0 && !resize && 'w-10/12'} ${isMobileSize && orientation == 0 && !resize && 'w-11/12'}`}
										style={{ zIndex: '30' }}
									>
										<div className="flex flex-col absolute top-0 right-0 py-3" style={{ zIndex: '30' }}>
											<button
												type="button"
												onClick={exitEventRoom}
												className={`${!isMobileSize ? 'leave-button' : 'mobile-leave-button'}`}
												tabIndex={-1}
												style={{ zIndex: '30' }}
											>
												{isLocal ? 'End Event' : 'Leave'}
											</button>
										</div>
									</div>
								</div>
							)

							:						(
								<div
									className="absolute inset-x-0 top-0 h-16"
									style={{ maxWidth: '100%', zIndex: '20' }}
								>
									<div
										className={`flex justify-between ${isMobileSize && orientation != 0 && !resize && 'w-10/12'} ${isMobileSize && orientation == 0 && !resize && 'w-11/12'}`}
										style={{ zIndex: '30' }}
									>
										<div className="absolute" style={{ zIndex: '30' }}>
											{!showThankYou && !isTablet ? (
												<VideoTimestamp
													date={jsonFormattedEventStartDate}
													className="text-xl not-italic font-sans tracking-wide text-white top-0 left-0 px-3 py-3 flex flex-col"
													marginRight="auto"
												>
													<AttendeesCount
														count={attendeesCount}
														className="text-xl not-italic flex items-center justify-start font-sans tracking-wide text-white"
														marginRight="auto"
													/>
												</VideoTimestamp>
											) : null}
										</div>
										{!isMobileSize && !isTablet ? (
											<div className="absolute" style={{ zIndex: '30', left: '50%', transform: 'translateX(-50%)' }}>
												<p className="text-xl text-white">
													{nameInActiveLine}
												</p>
											</div>
										) : null}
										<div className="flex flex-col absolute top-0 right-0 py-3 px-3" style={{ zIndex: '30' }}>
											<button
												type="button"
												onClick={exitEventRoom}
												className={`${!isMobileSize ? 'leave-button' : 'mobile-leave-button'}`}
												tabIndex={-1}
												style={{ zIndex: '30' }}
											>
												{isLocal ? 'End Event' : 'Leave'}
											</button>
										</div>
									</div>
								</div>
							)
					}

					{
						!isMobileSize || (isTablet && landscapeMode) ? (
							<div
								className="absolute inset-x-0 bottom-0 h-16"
								style={{ zIndex: '20' }}
							>
								{(isSeller && ripNShipActiveBuyersInLine && ripNShipActiveBuyersInLine.length) ? (
									<div
										className="absolute bottom-0 left-0 h-16 bg-black"
										style={{ maxWidth: '100%', zIndex: '20' }}
									>
										<div
											className={`flex justify-between ${isMobileSize && orientation != 0 && !resize && 'w-10/12'} ${isMobileSize && orientation == 0 && !resize && 'w-11/12'}`}
											style={{ zIndex: '30' }}
										>
											<div className="flex flex-col absolute top-0 left-0 py-5 px-3" style={{ zIndex: '30' }}>
												<button
													type="button"
													onClick={onEndTurn}
													className={`${!isMobileSize ? 'leave-button' : 'mobile-leave-button'} disabled:opacity-50`}
													tabIndex={-1}
													disabled={endingTurn}
													style={{ zIndex: '30' }}
												>
													{breakIsInActiveLine ? 'End Break' : 'End Turn'}
												</button>
											</div>
										</div>
									</div>
								) : null}
								<div>

									{isLocal ? (
										<div className="flex items-center justify-center mt-2">
											<button
												type="button"
												className={`video-control-btn 
						bg-white
						${muted ? 'bg-pressedIconsRedColor text-white pulsing-button' : ' dark:text-white dark:bg-darkGray-50 '
										}
						hover:text-pressedIconsRedColor
						rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
												onClick={() => {
													setMuted(!muted);
												}}
											>
												{!muted ? (
													<MicIcon
														width="12"
														height="17"
														stroke="currentColor"
														fill="currentColor"
													/>
												) : (
													<MicOffIcon
														width="25"
														height="24"
														stroke="currentColor"
														fill="currentColor"
													/>
												)}
											</button>
											<button
												type="button"
												className={`video-control-btn 
						bg-white
						${!cam ? 'bg-pressedIconsRedColor text-white' : 'dark:text-white dark:bg-darkGray-50 '
										}
						hover:text-pressedIconsRedColor
						rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center`}
												onClick={() => {
													if (videoDisabled) {
														setVideoDisabled(false);
													}
													setCam(!cam);
												}}
											>
												{!cam ? <VideoOffIcon /> : <VideoOnIcon />}
											</button>
											<button
												type="button"
												className={`video-control-btn 
						bg-white dark:text-white dark:bg-darkGray-50 hover:text-pressedIconsRedColor
						rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center ml-2`}
												onClick={() => {
													onSettingsClick();
												}}
											>
												<SettingsIcon />
											</button>
											{
												!participantScreen.active
													? (
														<button
															type="button"
															className={`video-control-btn bg-white dark:text-white dark:bg-darkGray-50 
						hover:text-pressedIconsRedColor
						rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center px-3 ml-2`}
															onClick={() => {
																if (isMobileSize && orientation == 0) {
																	defaultToastWarning('Change orientation to landscape for fullscreen mode!');
																	return;
																}
																setResize(true);
																if (!isMobileSize) participantScreen.enter();
															}}
														>
															<ResizeIcon />
														</button>
													)
													: (
														<button
															type="button"
															className={`video-control-btn bg-pressedIconsRedColor text-white
					dark:text-white dark:bg-darkGray-50
					hover:text-pressedIconsRedColor
					rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center px-3 ml-2`}
															onClick={() => {
																if (isMobileSize && orientation == 0) {
																	defaultToastWarning('Change orientation to landscape for fullscreen mode!');
																	return;
																}
																setResize(false);
																if (!isMobileSize) participantScreen.exit();
															}}
														>
															<ResizeIcon />
														</button>
													)
											}
											{conversation ? null : (
												<button
													type="button"
													className={`video-control-btn bg-white dark:text-white dark:bg-darkGray-50hover:text-pressedIconsRedColor rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
													onClick={() => {
														initializeConversation(true, eventName);
													}}
												>
													<ChatIcon />
												</button>
											)}
										</div>
									) : (
										<div className="flex items-center justify-center mt-2">
											{isSeller && (
												<button
													type="button"
													className={`video-control-btn 
										bg-white
										${cameraIndex == 1 ? 'bg-pressedIconsRedColor text-white' : 'dark:text-white dark:bg-darkGray-50 '
												}
										hover:text-pressedIconsRedColor
										rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
													onClick={() => setShowChangeHostSessionModal(true)}
												>
													<VideoOnIcon />
												</button>
											)}
											{!isSeller && (
												<>
													<button
														type="button"
														data-tip="Refresh the Video"
														className={`video-control-btn bg-white dark:text-white dark:bg-darkGray-50
					hover:text-pressedIconsRedColor
					 rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2
					disabled:opacity-50`}
														onClick={onRefreshButtonClicked}
													>
														<RefreshIcon className="w-5 h-5" />
													</button>
													<button
														type="button"
														className={`video-control-btn bg-white
						${requestedMicAccess || isBuyerMuted ? 'bg-pressedIconsRedColor dark:bg-pressedIconsRedColor text-white' : ' dark:text-white dark:bg-darkGray-50'
												}
						hover:text-pressedIconsRedColor
						 rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2
						disabled:opacity-50`}
														onClick={onMicButtonClick}
													>
														{micAccessApprovedByBuyer === true ? (
															!isBuyerMuted ? (
																<MicIcon
																	width="12"
																	height="17"
																	stroke="currentColor"
																	fill="currentColor"
																/>
															) : (
																<MicOffIcon
																	width="25"
																	height="24"
																	stroke="currentColor"
																	fill="currentColor"
																/>
															)
														) : <RaiseHandIcon /> }
													</button>
												</>
											)}
											<button
												type="button"
												className={`video-control-btn bg-white
						${!hasSound ? 'bg-pressedIconsRedColor dark:bg-pressedIconsRedColor text-white pulsing-button' : 'dark:text-white dark:bg-darkGray-50'
										}
						hover:text-pressedIconsRedColor
						  rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
												onClick={() => {
													setHasSound(!hasSound);
													if (isIOS) {
														turnOffOwnLocalAudio();
													}
												}}
											>
												{!hasSound ? (
													<SoundOffIcon
														stroke="currentColor"
														fill="currentColor"
														width="24"
														height="24"
													/>
												) : (
													<SoundOnIcon
														stroke="currentColor"
														fill="currentColor"
														width="24"
														height="24"
													/>
												)}
											</button>
											{
												isLoggedIn() && ((isDesktop) || (isTablet && landscapeMode))
															&& (
																<button
																	type="button"
																	className={clsx('outline-none focus:outline-none dark:text-white dark:bg-darkGray-50',
																		bookmarkClicked ? 'hover:text-white text-white hover:bg-djawn bg-djawn' : 'bg-white',
																		'rounded-full flex items-center justify-center mx-2 disabled:opacity-50',
																		isTablet && landscapeMode ? 'h-8 w-8' : 'h-12 w-12')}
																	style={{ zIndex: '30' }}
																	onClick={setBookmark}
																>
																	{isTablet && landscapeMode ? (
																		<BookmarkIcon fill={bookmarkClicked ? 'currentColor' : 'none'} stroke="currentColor" className="h-5 w-5" />

																	) : (<BookmarkIcon fill={bookmarkClicked ? 'currentColor' : 'none'} stroke="currentColor" height="24" width="24" />)}
																</button>
															)

											}
											{
												!participantScreen.active && !resize
													? (
														<button
															type="button"
															className={`video-control-btn bg-white dark:text-white dark:bg-darkGray-50 
						hover:text-pressedIconsRedColor
						rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center px-3 ml-2`}
															onClick={() => {
																if (isMobileSize && orientation == 0) {
																	defaultToastWarning('Change orientation to landscape for fullscreen mode!');
																	return;
																}
																setResize(true);
																if (!isMobileSize) participantScreen.enter();
															}}
														>
															<ResizeIcon />
														</button>
													)
													: (
														<button
															type="button"
															className={`video-control-btn bg-pressedIconsRedColor text-white
					dark:text-white dark:bg-darkGray-50
					hover:text-pressedIconsRedColor
					rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center px-3 ml-2`}
															onClick={() => {
																if (isMobileSize && orientation == 0) {
																	defaultToastWarning('Change orientation to landscape for fullscreen mode!');
																	return;
																}
																setResize(false);
																if (!isMobileSize) participantScreen.exit();
															}}
														>
															<ResizeIcon />
														</button>
													)
											}

										</div>
									)}

								</div>
							</div>

						)
							: (
								<>
									<div
										className="absolute bottom-0 text-center h-10"
										style={{ zIndex: '20', left: '40%', right: '40%' }}
									>
										<div className="w-max">
											<div className="flex items-center justify-center mt-2">
												{showThankYou ? ''
													: (
														<VideoTimestamp
															date={jsonFormattedEventStartDate}
															className="text-xl not-italic font-sans tracking-wide text-white px-2"
															margin="0 auto"
														/>
													)}
											</div>
										</div>
									</div>
									<div
										className="absolute bottom-0 left-2 h-10"
										style={{ zIndex: '20' }}
									>
										<div className="w-max">
											<div className="flex items-center justify-center mt-2">
												{showThankYou ? ''
													: (
														<AttendeesCount
															count={attendeesCount}
															className="text-xl not-italic flex items-center justify-center font-sans tracking-wide text-white"
															marginRight="auto"
														/>
													)}
											</div>
										</div>
									</div>
								</>
							)
					}

				</div>

				<audio
					ref={audioRef}
					id={audioRef.current}
					autoPlay
					muted={!hasSound}
				/>
				<div id="remote-audios" />
				{isMobileSize && !landscapeMode && (
					<div className="my-1">
						<div className="w-full">
							<div className="">
								{isLocal ? (
									<div className="flex flex-nowrap items-center">
										<div className="relative flex-1 flex justify-start">
											{((isMobileSize || (isTablet && !landscapeMode)) && !openQueueContainer) && (
												<ArrowButton
													openQueueContainer={openQueueContainer}
													buttonRef={arrowButtonRef}
													containerRef={arrowButtonContainerRef}
												/>
											)}
										</div>
										<div className="flex flex-grow text-center">
											<button
												type="button"
												className={`video-control-btn 
${muted ? 'bg-djawn text-white pulsing-button' : ' dark:text-white dark:bg-darkGray-50 '
									}
rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
												onClick={() => {
													setMuted(!muted);
												}}
											>
												{!muted ? (
													<MicIcon
														width="12"
														height="17"
														stroke="currentColor"
														fill="currentColor"
													/>
												) : (
													<MicOffIcon
														width="25"
														height="24"
														stroke="currentColor"
														fill="currentColor"
													/>
												)}
											</button>
											<button
												type="button"
												className={`video-control-btn 
${!cam ? 'text-red-600' : 'dark:text-white dark:bg-darkGray-50 '
									}
rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center`}
												onClick={() => {
													if (videoDisabled) {
														setVideoDisabled(false);
													}
													setCam(!cam);
												}}
											>
												{!cam ? <VideoOffIcon /> : <VideoOnIcon />}
											</button>
											<button
												type="button"
												className={`video-control-btn 
				${cameraIndex == 1 ? 'text-red-600' : 'dark:text-white dark:bg-darkGray-50 '
									}
				hover:text-pressedIconsRedColor
				rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
												onClick={() => {
													changeCameraOnClick(videoRef, videoTracks);
												}}
											>
												<VideoCameraIcon />
											</button>
											<EventNameInformationButton
												buttonRef={eventNamePopupRef}
												showEventNameInChatContainer={showEventNameInChatContainer}
												setShowEventNameInChatContainer={setShowEventNameInChatContainer}
											/>
											{linkedEvents && linkedEvents.length ? (
												<LinkedEventsMobileSliderButton
													showLinkedEventsSliderInMobileChatContainer={showLinkedEventsSliderInMobileChatContainer}
													setShowLinkedEventsSliderInMobileChatContainer={setShowLinkedEventsSliderInMobileChatContainer}
												/>
											) : null}
											{conversation ? null : (
												<button
													type="button"
													className={`video-control-btn bg-white dark:text-white dark:bg-darkGray-50hover:text-pressedIconsRedColor rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
													onClick={() => {
														initializeConversation(true, eventName);
													}}
												>
													<ChatIcon />
												</button>
											)}
										</div>
									</div>
								) : (
									<div className="flex flex-nowrap items-center">
										<div className="relative flex-1 flex justify-start">
											{((isMobileSize || (isTablet && !landscapeMode)) && !openQueueContainer) && (
												<ArrowButton
													openQueueContainer={openQueueContainer}
													buttonRef={arrowButtonRef}
													containerRef={arrowButtonContainerRef}
												/>
											)}
										</div>
										<div className="flex flex-grow text-center">
											{!isSeller && (
												<>
													<button
														type="button"
														className={`video-control-btn border-djawn border-2 hover:bg-djawn bg-white dark:text-white dark:bg-darkGray-50
			hover:text-pressedIconsRedColor
			 rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2
			disabled:opacity-50`}
														onClick={onRefreshButtonClicked}
													>
														<RefreshIcon className="w-5 h-5 text-djawn" />
													</button>
													<button
														type="button"
														className={clsx('rounded-full border-djawn hover:bg-djawn border-2 video-control-btn bg-white',
															requestedMicAccess || isBuyerMuted ? 'bg-pressedIconsRedColor dark:bg-pressedIconsRedColor text-white' : 'dark:text-white dark:bg-darkGray-50',
															requestedMicAccess && !micAccessApprovedByBuyer && 'pulsing-button',
															`hover:text-pressedIconsRedColor rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center disabled:opacity-50`,)}
														onClick={onMicButtonClick}
													>
														{micAccessApprovedByBuyer === true ? (
															!isBuyerMuted ? (
																<MicIcon
																	width="12"
																	height="17"
																	stroke="#e82e2c"
																	fill="#e82e2c"
																/>
															) : (
																<MicOffIcon
																	width="25"
																	height="24"
																	stroke="white"
																	fill="white"
																/>
															)
														) : <RaiseHandIcon className={clsx('h-8 w-8', requestedMicAccess ? 'text-white' : 'text-djawn')} />}
													</button>
												</>
											)}
											{isSeller && (
												<button
													type="button"
													className={`video-control-btn border-djawn border-2 dark:text-white dark:bg-darkGray-50 rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center text-djawn`}
													onClick={() => setShowChangeHostSessionModal(true)}
												>
													<VideoOnIcon />
												</button>
											)}
											<button
												type="button"
												className={`video-control-btn
												border-djawn border-2 
	${!hasSound ? 'bg-djawn text-white pulsing-button' : 'dark:text-white dark:bg-darkGray-50'}
	rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
												onClick={() => {
													setHasSound(!hasSound);
													if (isIOS) {
														turnOffOwnLocalAudio();
													}
												}}
											>
												{!hasSound ? (
													<SoundOffIcon
														stroke="white"
														fill="white"
														className="h-6 w-6 hover:fill-djawn hover:text-djawn"
													/>
												) : (
													<SoundOnIcon
														stroke="#e82e2c"
														fill="#e82e2c"
														className="h-5 w-5 hover:fill-white hover:text-black"
													/>
												)}
											</button>
											{isLoggedIn() && (
												<button
													type="button"
													className={clsx('border-2 bg-opacity-75 border-djawn focus:outline-none dark:text-white dark:bg-darkGray-50',
														bookmarkClicked ? 'hover:text-white text-white hover:bg-djawn bg-djawn' : 'bg-white',
														'rounded-full h-8 w-8 flex items-center justify-center disabled:opacity-50')}
													style={{ zIndex: '30' }}
													onClick={setBookmark}
												>
													<BookmarkIcon fill={bookmarkClicked ? 'currentColor' : '#e82e2c'} stroke="none" className="h-5 w-5" />
												</button>
											)}
											<EventNameInformationButton
												buttonRef={eventNamePopupRef}
												showEventNameInChatContainer={showEventNameInChatContainer}
												setShowEventNameInChatContainer={setShowEventNameInChatContainer}
											/>

											{linkedEvents && linkedEvents.length ? (
												<LinkedEventsMobileSliderButton
													showLinkedEventsSliderInMobileChatContainer={showLinkedEventsSliderInMobileChatContainer}
													setShowLinkedEventsSliderInMobileChatContainer={setShowLinkedEventsSliderInMobileChatContainer}
												/>
											) : null}
										</div>
									</div>
								)}

							</div>
						</div>
					</div>
				)}
			</div>
		</FullScreen>
	);
};

export default Participant;
