import React, { useState, useContext } from 'react';
import { navigate as gatsbyNavigate } from 'gatsby';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { isAndroid, isMobile, isTablet } from 'react-device-detect';
import { connect } from 'react-redux';
import clsx from 'clsx';
import SEO from './seo';
import logo from '../images/djawn_logo.png';
import Conversation from './conversation';
import { setDarkMode, setLightMode } from '../state/action';
import { LiveEventDataContext } from '../contexts/live-event-data';
import HeaderAuthenticationButtons from '../containers/header/header-authentication-buttons';

const RoomHeader = ({
	siteTitle,
	showCart = false,
	cartOnClick,
	exitRoom,
	cartItemCount,
	landscapeMode,
	unreadMessages,
	isDarkMode,
	showEventName,
	eventName,
	dispatch
}) => {
	const [showSigninPopup, setShowSigninPopup] = useState(true);
	const [showConversations, setShowConversations] = useState(false);
	const { eventId, isRipNShip, authenticated } = useContext(
		LiveEventDataContext
	);

	const conversationIcon = () => (
		<button
			type="button"
			className="focus:outline-none text-black hover:text-black text-lg rounded-md rounded-tr-none rounded-br-none mt-2"
			onClick={() => {
				if (!showConversations && !isAndroid) {
					document.body.style.overflow = 'hidden';
				} else {
					document.body.style.overflow = 'visible';
				}
				setShowConversations(!showConversations);
			}}
		>
			<div className="relative">
				<svg
					className="w-8 h-8 md:w-9 md:h-9"
					fill="currentColor"
					stroke="white"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
					/>
				</svg>
			</div>
			{unreadMessages > 0 && (
				<div className="rounded-full h-4 w-4 flex items-center justify-center mb-1 cursor-pointer absolute bottom-2 m-5 bg-red-400">
					<p className="font-sans font-semibold text-white text-xs">
						{unreadMessages}
					</p>
				</div>
			)}
		</button>
	);

	const onHelpClick = () => {
		window.open(
			isRipNShip
				? 'https://support.djawn.com/help-ras/'
				: 'https://support.djawn.com/help-breaks/',
			'_blank',
			'noopener,noreferrer'
		);
	};

	return (
		<>
			<header
				className={clsx(
					'flex w-full sticky top-0 z-30 dark:bg-darkGray-50 h-12',
					!isTablet && 'lg:h-auto'
				)}
			>
				<SEO title={siteTitle} />
				<nav
					className={clsx(
						'px-0 lg:px-2 flex w-full bg-white dark:bg-darkGray-50',
						isMobile && 'justify-between'
					)}
				>
					<div
						className={clsx(
							'pt-3 lg:pt-0 px-4 sm:px-6 lg:px-8',
							!isMobile && 'mx-auto'
						)}
					>
						<div
							className={clsx(
								'flex items-center justify-start',
								isMobile ? '.room-header-small-height' : 'h-16',
								isTablet && (landscapeMode ? 'h-full' : 'h-auto')
							)}
						>
							<div className="flex items-center">
								<div
									className="flex-shrink-0 flex-1"
									style={{ transform: 'translateX(10px)' }}
									tabIndex={-1}
									onClick={() => {
										if (showConversations) {
											setShowConversations(false);
										}
										// onQuit();
									}}
									onKeyDown={() => {
										if (showConversations) {
											setShowConversations(false);
										}
										// onQuit();
									}}
								>
									<img
										className="w-auto cursor-pointer h-6 lg:h-10"
										src={logo}
										alt="Workflow"
									/>
								</div>
							</div>

							{!authenticated && <div className="flex flex-grow" />}

							{!authenticated && (
								<div className="flex items-center justify-center md:flex absolute right-2">
									<div
										className={clsx(
											'flex items-center',
											!isMobile && 'space-x-2'
										)}
									>
										<HeaderAuthenticationButtons
											onClick={(link) => {
												document.body.style.overflow = 'visible';
												exitRoom(false, true);
												gatsbyNavigate(link, {
													state: {
														redirectUrl: `/event/room/${eventId}`
													}
												});
											}}
										/>
									</div>
								</div>
							)}
						</div>
					</div>

					<div
						className={clsx(
							'flex flex-grow hidden md:block md:flex md:flex-1',
							showEventName && eventName && 'justify-center items-center'
						)}
					>
						{showEventName && eventName ? (
							<h3 className="text-black dark:text-white">{eventName}</h3>
						) : null}
					</div>

					{showSigninPopup && !authenticated && (
						<div
							className="flex justify-end flex-col items-end pr-2 absolute right-0 top-0 signin-popup"
							style={{ zIndex: '60' }}
						>
							<div className="triangle-up mr-8" style={{ zIndex: '60' }} />
							<div
								className="w-80 h-28 border rounded"
								style={{
									backgroundColor: '#0c6ae6',
									borderColor: '#0c6ae6',
									zIndex: '60'
								}}
							>
								<h3
									className="text-white text-sm pt-4 pl-3"
									style={{ zIndex: '60' }}
								>
									You are not signed in to DJAWN.
								</h3>
								<p
									className="text-white text-sm pt-2 pl-3"
									style={{ zIndex: '60' }}
								>
									Please signin to chat, purchase items, etc.
								</p>
								<div
									className="flex justify-end pr-3 pt-3 mb-3"
									style={{ zIndex: '60' }}
								>
									<p
										className="cursor-pointer text-white text-md"
										onClick={() => setShowSigninPopup(false)}
										onKeyDown={() => setShowSigninPopup(false)}
									>
										GOT IT
									</p>
								</div>
							</div>
						</div>
					)}
					{authenticated && (
						<div className="flex items-center justify-center md:flex absolute md:static right-2 md:right-0 flex-row">
							<div className="md:mb-1">
								<button
									type="button"
									className="w-12 h-12 focus:outline-none text-black hover:text-black dark:text-white text-lg p-3 rounded-md rounded-tr-none rounded-br-none"
									onClick={onHelpClick}
								>
									<div className="relative">
										<svg
											className="w-6 h-6"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg%22"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
											/>
										</svg>
									</div>
								</button>
							</div>
							<div className="">{conversationIcon()}</div>
							{showCart && (
								<div className="mr-3">
									<button
										type="button"
										className="w-12 h-12 focus:outline-none text-black dark:text-white text-lg p-3 rounded-md rounded-tr-none rounded-br-none"
										onClick={() => {
											setShowConversations(false);
											cartOnClick();
										}}
									>
										<div className="relative">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
												/>
											</svg>
										</div>

										{cartItemCount() > 0 && (
											<div className="rounded-full h-4 w-4 flex items-center justify-center mb-1 cursor-pointer absolute bottom-1 m-3 bg-red-400">
												<p className="font-sans font-semibold text-white text-xs">
													{cartItemCount()}
												</p>
											</div>
										)}
									</button>
								</div>
							)}
							<Switch
								onColor="#000000"
								onHandleColor="#ffffff"
								handleDiameter={30}
								uncheckedIcon={false}
								checkedIcon={false}
								boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
								activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
								height={20}
								width={48}
								className="react-switch"
								onChange={() => {
									if (isDarkMode) {
										dispatch(setLightMode());
									} else {
										dispatch(setDarkMode());
									}
								}}
								checked={isDarkMode}
							/>
						</div>
					)}
				</nav>
			</header>
			<div
				style={{
					zIndex: 9998,
					height: '94.5vh',
					marginTop: isMobile ? '3rem' : '4rem'
				}}
				className={`h-screen w-screen absolute overflow-hidden bg-white dark:bg-darkGray-50 ${
					showConversations ? 'visible' : 'hidden'
				}`}
			>
				<Conversation showConversations={showConversations} />
			</div>
		</>
	);
};

RoomHeader.propTypes = {
	siteTitle: PropTypes.string
};

RoomHeader.defaultProps = {
	siteTitle: ''
};

export default connect(
	(state) => ({
		unreadMessages: state.utils.unreadMessages,
		isDarkMode: state.theme.isDarkMode
	}),
	null
)(RoomHeader);
