import clsx from 'clsx';
import React from 'react';

const ErrorFieldMessage = ({ error, className = '', textSize = 'xs' }) => (
	<span
		className={clsx(
			'flex items-left w-full font-medium tracking-wide text-red-500 mt-1 ml-1',
			`test-${textSize}`,
			className
		)}
	>
		{error}
	</span>
);

export default ErrorFieldMessage;
