import React, { useState } from 'react';
import Button from '../../../components/button';
import { ALL_PRODUCTS_PRODUCT_SELECTION_OPTION } from '../../../utils/constants';
import AddEventProductModal from '../add-event-product-modal';

const RoomAddEventProduct = ({
	excludedProductIds,
	eventId,
	onProductsAdded,
	userCanAddOrRemoveEventProducts,
	userCanAddProducts,
	productSelectionId
}) => {
	const [showAddProductModal, setShowAddProductModal] = useState(false);

	return (
		<>
			<AddEventProductModal
				showModal={showAddProductModal}
				setShowModal={setShowAddProductModal}
				excludedProductIds={excludedProductIds}
				onProductsAdded={onProductsAdded}
				eventId={eventId}
				isRipNShip
			/>
			<div
				className={`flex flex-row w-full justify-center py-2 space-x-2
			${userCanAddOrRemoveEventProducts || userCanAddProducts ? 'border-t-2' : ''} `}
			>
				{userCanAddOrRemoveEventProducts && (
					<Button
						outline
						textSize="md"
						width="32"
						mobileHeight="12"
						mobileWidth="full"
						onClick={() => setShowAddProductModal(true)}
					>
						Add Product
					</Button>
				)}
				{userCanAddProducts && (
					<Button
						outline
						textSize="md"
						width="32"
						mobileHeight="12"
						mobileWidth="full"
						onClick={() => {
							let productUrl = `${window.location.origin}/product?closeOnSave=true&eventId=${eventId}`;
							if (productSelectionId != ALL_PRODUCTS_PRODUCT_SELECTION_OPTION) {
								productUrl += '&addEventProduct=true';
							}
							window.open(productUrl, '_blank');
						}}
					>
						Create Product
					</Button>
				)}
			</div>
		</>
	);
};

export default RoomAddEventProduct;
