export const sellerAttendeeFilterOptions = [{
	label: 'All',
	value: '',
	all: true,
	micRequests: false,
	activeMics: false,
	mutedMic: false
}, {
	label: 'User/s with mic requests',
	value: 'mic-requests',
	all: false,
	micRequests: true,
	activeMics: false,
	mutedMic: false
}, {
	label: 'User/s with active mic',
	value: 'active-mic',
	all: false,
	micRequests: false,
	activeMics: true,
	mutedMic: false
},
{
	label: 'User/s with muted mic',
	value: 'muted-mic',
	all: false,
	micRequests: false,
	activeMics: false,
	mutedMic: true
}];
