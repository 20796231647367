/* eslint-disable prefer-destructuring */
import { useCallback, useEffect, useState } from 'react';
import {
	addTwilioParticipantInfoToEventAttendee,
	cancelMicRequest,
	closeActiveMic,
	deleteMicRequest,
	expireActiveMicSessionsOrRequests,
	requestedMicDecision,
	requestMic,
	unsubcribeAudioTracksFromEvent,
	updateMutedMicStatus
} from '../api/event.request';
import { isTimeDue } from '../utils/formatter';
import { informationToast } from '../utils/information-toast';
import {
	CLOSED_ALL_MIC_NOTIFIER,
	CLOSED_MIC_NOTIFIER,
	MUTED_MIC_NOTIFIER,
	UNMUTED_MIC_NOTIFIER
} from '../utils/mic-notification-buyer-types';

const useTwilioTracks = (
	eventId,
	isHost,
	twilioRoomIdentity,
	websocketGroupName
) => {
	const [alreadyExpiredMic, setAlreadyExpiredMic] = useState(false);
	const [hostAudioTrackMuted, setHostAudioTrackMuted] = useState(false);
	const [requestedMicAccess, setRequestedMicAccess] = useState(false);
	const [canceledMicRequestAccess, setCanceledMicRequestAccess] = useState(
		false
	);
	const [twilioParticipantId, setTwilioParticipantId] = useState(null);
	const [audioParticipants, setAudioParticipants] = useState([]);
	const [newAudioParticipant, setNewAudioParticipant] = useState(null);
	const [localAudioTracks, setLocalAudioTracks] = useState(new Map());
	const [
		newCanceledMicRequestAccess,
		setNewCanceledMicRequestAccess
	] = useState(null);
	const [expiredMicAccessMessage, setExpiredMicAccessMessage] = useState(null);
	const [approvedAudioParticipants, setApprovedAudioParticipants] = useState(
		[]
	);
	const [micAccessApprovedBySeller, setMicAccessApprovedBySeller] = useState(
		null
	);
	const [micAccessApprovedByBuyer, setMicAccessApprovedByBuyer] = useState(
		null
	);
	const [newMutedAudioParticipants, setNewMutedAudioParticipants] = useState(
		[]
	);
	const [mutedByBuyer, setMutedByBuyer] = useState(false);
	const [mutedBySeller, setMutedBySeller] = useState(false);
	const [buyerNotificationType, setBuyerNotificationType] = useState(null);

	// boolean conditions for button spinners
	const [mutingMic, setMutingMic] = useState(false);
	const [closingMic, setClosingMic] = useState(false);
	const [requestingMic, setRequestingMic] = useState(false);
	const [cancellingMicRequest, setCancellingMicRequest] = useState(false);
	const [usersApprovingMic, setUsersApprovingMic] = useState([]);
	const [usersDecliningMic, setUsersDecliningMic] = useState([]);

	const requestMicrophoneAccess = useCallback(async () => {
		try {
			await requestMic(
				eventId,
				twilioParticipantId,
				twilioRoomIdentity,
				websocketGroupName
			);
		} catch (err) {
			console.warn(err);
		} finally {
			setRequestingMic(false);
		}
	}, [eventId, twilioParticipantId, twilioRoomIdentity, websocketGroupName]);

	const removeAudioParticipantsFromApprovedAudioParticipants = useCallback(
		(userIds) => {
			setApprovedAudioParticipants((oldApprovedAudioParticipants) => [...oldApprovedAudioParticipants].filter((audioParticipant) => {
				const foundExpiredAudioParticipant = userIds.find(
					(userId) => userId == audioParticipant.buyerId
				);
				if (foundExpiredAudioParticipant) return false;
				return true;
			}));
		},
		[]
	);

	useEffect(() => {
		if (!approvedAudioParticipants || !approvedAudioParticipants.length) return;
		const foundLocalApprovedAudioParticipant = approvedAudioParticipants.find(
			(participant) => participant.twilioParticipantId == twilioParticipantId
		);
		if (requestedMicAccess && foundLocalApprovedAudioParticipant != null) {
			setMicAccessApprovedBySeller(foundLocalApprovedAudioParticipant.approved);
			if (!foundLocalApprovedAudioParticipant.approved) {
				setRequestedMicAccess(false);
			}
		}

		const declinedParticipants = approvedAudioParticipants.filter(
			(participant) => !participant.approved
		);

		if (declinedParticipants && declinedParticipants.length) {
			removeAudioParticipantsFromApprovedAudioParticipants(
				declinedParticipants.map((u) => u.buyerId)
			);
		}
	}, [approvedAudioParticipants]);

	const disableAudioTracks = useCallback((audioTracks) => {
		audioTracks.forEach((trackPub) => {
			trackPub.track.disable();
		});
	}, []);

	const enableAudioTracks = useCallback((audioTracks) => {
		audioTracks.forEach((trackPub) => {
			trackPub.track.enable();
		});
	}, []);

	useEffect(() => {
		if (!twilioParticipantId || !twilioRoomIdentity || !eventId) return;
		addTwilioParticipantInfoToEventAttendee(
			eventId,
			twilioRoomIdentity,
			twilioParticipantId,
			websocketGroupName
		)
			.then((res) => {
				if (!res || !res.data || !res.data.length) return;
				const fetchedAudioParticipants = res.data.map((participant) => ({
					limitInSeconds: participant.limitInSeconds,
					startDate: participant.startDate,
					twilioParticipantId: participant.twilioParticipantId,
					trackId: participant.trackId,
					userId: participant.buyerId,
					muted: participant.muted,
					approved: participant.approved
				}));
				setAudioParticipants(fetchedAudioParticipants);
			})
			.catch((err) => {
				console.warn(err);
			});
	}, [twilioParticipantId, twilioRoomIdentity, eventId]);

	useEffect(() => {
		if (isHost || !twilioParticipantId || !twilioRoomIdentity) return;
		if (requestedMicAccess) {
			requestMicrophoneAccess();
		}
		disableAudioTracks(localAudioTracks);
	}, [requestedMicAccess, localAudioTracks, isHost, twilioRoomIdentity]);

	useEffect(() => {
		if (!newMutedAudioParticipants || !newMutedAudioParticipants.length) return;
		const foundLocalParticipant = newMutedAudioParticipants.find(
			(participant) => participant.twilioParticipantId == twilioParticipantId
		);
		if (foundLocalParticipant != null) {
			if (foundLocalParticipant.muted) {
				disableAudioTracks(localAudioTracks);
			} else {
				enableAudioTracks(localAudioTracks);
			}
			if (foundLocalParticipant.initiatedBySeller === true) {
				setMutedBySeller(foundLocalParticipant.muted);
				if (foundLocalParticipant.muted == true) {
					setBuyerNotificationType(MUTED_MIC_NOTIFIER);
				} else {
					setBuyerNotificationType(UNMUTED_MIC_NOTIFIER);
				}
			}
			setMutedByBuyer(foundLocalParticipant.muted);
		}

		const remoteAudiosContainerEl = document.getElementById('remote-audios');
		if (
			remoteAudiosContainerEl
			&& remoteAudiosContainerEl.children
			&& remoteAudiosContainerEl.children.length
		) {
			newMutedAudioParticipants.forEach((newMutedAudioParticipant) => {
				const audioEl = Array.from(remoteAudiosContainerEl.children).find(
					(audio) => audio.id === newMutedAudioParticipant.trackId
				);
				if (audioEl) audioEl.muted = newMutedAudioParticipant.muted;
			});
		}

		setAudioParticipants((tempParticipants) => tempParticipants.map((participant) => {
			const foundMutedParticipant = newMutedAudioParticipants.find(
				(mutedParticipant) => mutedParticipant.twilioParticipantId
						== participant.twilioParticipantId
			);
			if (foundMutedParticipant) {
				participant.muted = foundMutedParticipant.muted;

				if (
					remoteAudiosContainerEl
						&& remoteAudiosContainerEl.children
						&& remoteAudiosContainerEl.children.length
				) {
					const audioEl = Array.from(remoteAudiosContainerEl.children).find(
						(audio) => audio.id === participant.trackId
					);
					if (audioEl) audioEl.muted = foundMutedParticipant.muted;
				}
			}
			return participant;
		}));
	}, [newMutedAudioParticipants]);

	const cancelMicRequestAccess = useCallback(async () => {
		try {
			await cancelMicRequest(eventId, twilioParticipantId, websocketGroupName);
		} catch (err) {
			console.warn(err);
		} finally {
			setCancellingMicRequest(false);
		}
	}, [eventId, twilioParticipantId, websocketGroupName]);

	useEffect(() => {
		if (!newCanceledMicRequestAccess) return;
		if (
			newCanceledMicRequestAccess.twilioParticipantId == twilioParticipantId
		) {
			setRequestedMicAccess(false);
			setCanceledMicRequestAccess(false);
			setMutedByBuyer(false);
			setMutedBySeller(null);
		}
	}, [newCanceledMicRequestAccess]);

	useEffect(() => {
		if (!canceledMicRequestAccess) return;
		cancelMicRequestAccess();
	}, [canceledMicRequestAccess]);

	// TODO: check if likely to be redundant
	useEffect(() => {
		if (!twilioParticipantId || !newAudioParticipant) return;
		removeAudioParticipantsFromApprovedAudioParticipants([
			newAudioParticipant.buyerId
		]);
		if (newAudioParticipant.twilioParticipantId == twilioParticipantId) {
			if (newAudioParticipant.approved) {
				enableAudioTracks(localAudioTracks);
			} else {
				setRequestedMicAccess(false);
			}
		}
		if (!newAudioParticipant.approved) return;
		setAudioParticipants([
			...audioParticipants,
			{
				limitInSeconds: newAudioParticipant.limitInSeconds,
				startDate: newAudioParticipant.startDate,
				twilioParticipantId: newAudioParticipant.twilioParticipantId,
				trackId: newAudioParticipant.trackId,
				userId: newAudioParticipant.buyerId,
				muted: newAudioParticipant.muted
			}
		]);
	}, [newAudioParticipant, twilioParticipantId, localAudioTracks]);

	useEffect(() => {
		if (!audioParticipants || !audioParticipants.length) return;

		const localAudioParticipant = audioParticipants.find(
			(participant) => participant.twilioParticipantId == twilioParticipantId
		);
		if (!localAudioParticipant) return;

		const intervalId = setInterval(() => {
			if (
				isTimeDue(
					localAudioParticipant.startDate,
					localAudioParticipant.limitInSeconds
				)
			) {
				unsubcribeAudioTracksFromEvent(
					eventId,
					twilioParticipantId,
					true,
					websocketGroupName
				)
					.then(() => {
						informationToast(
							'Time is expired for your mic. Please raise a mic request to talk again'
						);
					})
					.catch((err) => {
						console.warn(err);
					});

				setRequestedMicAccess(false);
				setMicAccessApprovedByBuyer(false);
				setMutedByBuyer(false);
				setMutedBySeller(false);
				disableAudioTracks(localAudioTracks);
				clearInterval(intervalId);
			}
		}, 1000);

		return () => clearInterval(intervalId);
	}, [audioParticipants]);

	useEffect(() => {
		if (
			!expiredMicAccessMessage
			|| !expiredMicAccessMessage.users
			|| !expiredMicAccessMessage.users.length
		) return;

		const foundLocalAudioParticipant = expiredMicAccessMessage.users.find(
			(u) => u.twilioParticipantId == twilioParticipantId
		);

		if (foundLocalAudioParticipant) {
			if (expiredMicAccessMessage.closed === true) {
				disableAudioTracks(localAudioTracks);
				setRequestedMicAccess(false);
				setMicAccessApprovedByBuyer(false);
				setMicAccessApprovedBySeller(null);
			}
			if (expiredMicAccessMessage.closedBySeller == true) {
				if (expiredMicAccessMessage.closed === true) {
					setBuyerNotificationType(
						expiredMicAccessMessage.closeAllMicsNotificationMessage === true
							? CLOSED_ALL_MIC_NOTIFIER
							: CLOSED_MIC_NOTIFIER
					);
				}
			}
			setMutedByBuyer(false);
			setMutedBySeller(null);
		}

		const newAudioParticipants = [...audioParticipants].filter(
			(audioParticipant) => {
				const foundExpiredAudioParticipant = expiredMicAccessMessage.users.find(
					(u) => u.twilioParticipantId == audioParticipant.twilioParticipantId
				);
				if (foundExpiredAudioParticipant) return false;
				return true;
			}
		);

		removeAudioParticipantsFromApprovedAudioParticipants(
			expiredMicAccessMessage.users.map((u) => u.buyerId)
		);
		setAudioParticipants(newAudioParticipants);
	}, [expiredMicAccessMessage]);

	const removeLocalMicAccess = useCallback(() => {
		setMicAccessApprovedByBuyer(false);
		setMicAccessApprovedBySeller(null);
		setRequestedMicAccess(false);
	}, []);

	const approveOrDeclineMicRequest = useCallback(
		async (users, approve = true, updateAll = false) => {
			try {
				await requestedMicDecision(
					eventId,
					approve,
					users,
					updateAll,
					websocketGroupName
				);
			} catch (err) {
				console.warn(err);
			}
		},
		[eventId, websocketGroupName]
	);

	const muteOrUnmuteMicParticipants = useCallback(
		(users, mute, initiatedBySeller, muteOrUnmuteAll) => {
			updateMutedMicStatus(
				eventId,
				users,
				mute,
				initiatedBySeller,
				muteOrUnmuteAll,
				websocketGroupName
			)
				.catch((err) => {
					console.warn(err);
				})
				.finally(() => {
					if (!initiatedBySeller) {
						setMutedByBuyer(mute);
					}
					setMutingMic(false);
				});

			// doing this for the seller to quickly update the mic mute/unmute functionality
			if (!initiatedBySeller) {
				if (mute) {
					disableAudioTracks(localAudioTracks);
				} else {
					enableAudioTracks(localAudioTracks);
				}
			}
		},
		[eventId, websocketGroupName]
	);

	const currentUserIsOnMic = useCallback(
		() => audioParticipants.find(
			(audioParticipant) => audioParticipant.twilioParticipantId == twilioParticipantId
		) != null,
		[twilioParticipantId, audioParticipants]
	);

	const closeMic = useCallback(
		(userTwilioParticipantIds, initiatedBySeller, closeAll) => {
			closeActiveMic(
				eventId,
				userTwilioParticipantIds,
				initiatedBySeller,
				closeAll,
				websocketGroupName
			)
				.catch((err) => {
					console.warn(err);
				})
				.finally(() => {
					setClosingMic(false);
				});
		},
		[eventId, websocketGroupName]
	);

	const expireActiveMicRequestsOrSessions = useCallback(() => {
		if (alreadyExpiredMic) return;
		expireActiveMicSessionsOrRequests(
			eventId,
			twilioRoomIdentity,
			websocketGroupName
		)
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setAlreadyExpiredMic(true);
			});
	}, [eventId, twilioRoomIdentity, websocketGroupName, alreadyExpiredMic]);

	useEffect(() => {
		if (!eventId || !twilioRoomIdentity) return;
		expireActiveMicRequestsOrSessions();
	}, [eventId, twilioRoomIdentity]);

	const deleteCurrentMicRequest = useCallback(
		async (sendCanceledMicMessage = false) => {
			try {
				await deleteMicRequest(
					eventId,
					twilioParticipantId,
					websocketGroupName,
					sendCanceledMicMessage
				);
			} catch (err) {
				console.warn(err);
			}
		},
		[eventId, twilioParticipantId, websocketGroupName]
	);

	return {
		setLocalAudioTracks,
		twilioParticipantId,
		setTwilioParticipantId,
		requestedMicAccess,
		setRequestedMicAccess,
		setNewAudioParticipant,
		approveOrDeclineMicRequest,
		audioParticipants,
		setAudioParticipants,
		setCanceledMicRequestAccess,
		setNewCanceledMicRequestAccess,
		setExpiredMicAccessMessage,
		approvedAudioParticipants,
		setApprovedAudioParticipants,
		micAccessApprovedByBuyer,
		micAccessApprovedBySeller,
		setMicAccessApprovedByBuyer,
		setMicAccessApprovedBySeller,
		muteOrUnmuteMicParticipants,
		setNewMutedAudioParticipants,
		closeMic,
		mutedByBuyer,
		mutedBySeller,
		buyerNotificationType,
		setBuyerNotificationType,
		deleteCurrentMicRequest,
		removeLocalMicAccess,
		hostAudioTrackMuted,
		setHostAudioTrackMuted,
		removeAudioParticipantsFromApprovedAudioParticipants,
		requestingMic,
		setRequestingMic,
		cancellingMicRequest,
		setCancellingMicRequest,
		closingMic,
		setClosingMic,
		mutingMic,
		setMutingMic,
		usersApprovingMic,
		setUsersApprovingMic,
		usersDecliningMic,
		setUsersDecliningMic,
		currentUserIsOnMic
	};
};

export default useTwilioTracks;
