import React, { useState } from 'react';
import { ShieldCheckIcon } from '@heroicons/react/outline';
import useConversation from '../../hooks/useConversation';
import { getUsersByIds } from '../../api/auth.request';
import useAsyncEffect from '../../hooks/useAsyncEffect';

const ConversationParticipants = ({
	showModal,
	setShowModal,
	removeParticipant,
	canRemoveParticipants,
	setParticipants,
	authenticatedUserId,
	conversationId,
	hostUserId
}) => {
	const [participantsLoaded, setParticipantsLoaded] = useState(false);
	const [currentParticipants, setCurrentPartipants] = useState([]);
	const { getConversationParticipants } = useConversation();

	useAsyncEffect(async () => {
		setParticipantsLoaded(false);
		if (!conversationId || !hostUserId) return;
		setCurrentPartipants([]);
		setParticipants([]);
		const conversationParticipants = await getConversationParticipants(
			conversationId
		);
		const participantUserIds = conversationParticipants
			.map((p) => p.user_id)
			.filter((id) => id !== authenticatedUserId);
		if (authenticatedUserId != hostUserId) participantUserIds.push(hostUserId);
		const usersRes = await getUsersByIds(participantUserIds);
		if (usersRes && usersRes.data && usersRes.data.length) {
			const fetchedParticipants = usersRes.data.map((user) => {
				const conversationParticipant = conversationParticipants.find(
					(p) => p.user_id === user.id
				);
				if (conversationParticipant) {
					user.show_conversation = conversationParticipant.show_conversation;
				}
				if (user.id == hostUserId) {
					user.owner = true;
				}
				return user;
			});

			setCurrentPartipants(fetchedParticipants);
			setParticipants(
				fetchedParticipants.filter(
					(fetchedParticipant) => fetchedParticipant.show_conversation
				)
			);
		}
		setParticipantsLoaded(true);
	}, [conversationId, hostUserId]);

	return (
		<>
			{showModal && participantsLoaded ? (
				<>
					<div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="border-0 rounded-lg shadow-lg flex flex-col bg-white outline-none focus:outline-none w-full md:w-auto">
							<div className="flex flex-row items-center justify-between p-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg md:ml-0">
								<div style={{ textAlign: 'center' }}>
									<p className="text-opacity-100 font-bold">Participants</p>
								</div>
								<div className="flex">
									<button
										type="button"
										className="focus:outline-none items-center text-black hover:text-black text-lg rounded-md rounded-tr-none rounded-br-none cursor-pointer"
										onClick={() => {
											setShowModal(!showModal);
										}}
									>
										<svg
											className="w-6 h-6"
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>
							</div>
							<div className="flex flex-col px-6 py-5 bg-gray-50 mb-5 md:mx-0">
								{currentParticipants
									&& currentParticipants.map((object, i) => (
										<div key={i} className="w-full px-5 py-3">
											<div className="flex flex-row justify-between">
												<div className="flex flex-none items-center">
													<img
														src={object.image}
														alt="foto"
														className="w-10 h-10 mr-3 border rounded-2xl"
													/>
													<div className="flex flex-col">
														<div className="flex items-center text-center">
															<span className="font-bold text-md mr-2 inline-flex font-sans">
																{object.name}
																{object.owner && (
																	<ShieldCheckIcon className="ml-1 w-6 h-6" />
																)}
															</span>
														</div>
														<div>{object.nickname}</div>
													</div>
												</div>
												{canRemoveParticipants
													&& currentParticipants
													&& !(
														currentParticipants.length <= 2
														&& currentParticipants.some(
															(s) => s.user_id === authenticatedUserId
														)
													) && (
													<div className="flex ml-5 mb-auto mt-auto items-center">
														<div
															disabled={object.show_conversation}
															className={`focus:outline-none items-center text-red hover:text-black text-lg rounded-md rounded-tr-none rounded-br-none -mr-3 ${
																object.show_conversation && 'cursor-pointer'
															}`}
															onClick={() => {
																removeParticipant(object.id, i);
															}}
															onKeyDown={() => {
																removeParticipant(object.id, i);
															}}
														>
															<svg
																className="w-7 h-7"
																fill="none"
																stroke={`${
																	object.show_conversation ? 'red' : 'gray'
																}`}
																viewBox="0 0 24 24"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	strokeWidth="2"
																	d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
																/>
															</svg>
														</div>
													</div>
												)}
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black" />
				</>
			) : null}
		</>
	);
};

export default ConversationParticipants;
