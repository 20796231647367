/* eslint-disable no-unused-vars */
import {
	useEffect, useState, useCallback, useRef
} from 'react';
import useStateRef from './useStateRef';

const useVideoRoom = (
	room,
	presenterIdentity,
	onNewParticipantConnected,
	onNewParticipantDisconnected,
	onHostDisconnected
) => {
	const oldHostIdentitiesRef = useRef([]);
	const lastAudioTrackIdsBeforeChangeRef = useRef([]);
	const [videoTracks, setVideoTracks] = useState([]);
	const [audioTracks, setAudioTracks] = useState([]);
	const [hostParticipant, setHostParticipant] = useState(null);
	const [
		hostParticipantVideoTracks,
		setHostParticipantVideoTracks,
		hostParticipantVideoTracksRef
	] = useStateRef([]);
	const [
		hostParticipantAudioTracks,
		setHostParticipantAudioTracks,
		hostParticipantAudioTracksRef
	] = useStateRef([]);
	const [mutedLocalMic, setMutedLocalMic] = useState(false);
	const [
		noParticipantHostingTheStream,
		setNoParticipantHostingTheStream,
		noParticipantHostingTheStreamRef
	] = useStateRef(false);
	const trackpubsToTracks = useCallback((trackMap) => {
		if (!trackMap) return;
		const array = Array.from(trackMap.values())
			.map((publication) => publication.track)
			.filter((track) => track !== null);
		return array;
	}, []);

	const participantConnected = useCallback(
		(participant) => {
			if (participant.identity === presenterIdentity) setHostParticipant(participant);

			participant.on('trackPublished', (publication) => {
				publication.on('subscribed', (track) => {
					console.log('subscribe', track);
				});

				publication.on('subscriptionFailed', (track) => {
					console.error('SUBSCRIPTION FAILED', track);
				});
			});

			participant.on('trackUnsubscribed', (track, publication) => {
				if (track.kind === 'audio') {
					setAudioTracks((oldAudioTracks) => oldAudioTracks.filter((t) => t !== track));
					setHostParticipantAudioTracks((oldHostParticipantAudioTracks) => oldHostParticipantAudioTracks.filter((t) => t.trackSid != track.sid));
				} else {
					setVideoTracks((oldVideoTracks) => oldVideoTracks.filter((t) => t !== track));
					setHostParticipantVideoTracks((oldHostParticipantVideoTracks) => oldHostParticipantVideoTracks.filter((t) => t.trackSid != track.sid));
				}
			});

			participant.on('trackSubscribed', (track, publication) => {
				if (track.kind === 'audio') {
					setAudioTracks((oldAudioTracks) => [...oldAudioTracks, track]);
					setHostParticipantAudioTracks((oldHostParticipantAudioTracks) => [
						...oldHostParticipantAudioTracks,
						{
							trackSid: track.sid,
							identity: participant.identity
						}
					]);
				} else {
					setVideoTracks((oldVideoTracks) => [...oldVideoTracks, track]);
					setHostParticipantVideoTracks((oldHostParticipantVideoTracks) => [
						...oldHostParticipantVideoTracks,
						{
							trackSid: track.sid,
							identity: participant.identity
						}
					]);
				}
			});

			participant.on('trackSubscriptionFailed', (error, pub) => {
				console.error('err', error);
			});

			if (onNewParticipantConnected) {
				onNewParticipantConnected(participant);
			}
		},
		[onNewParticipantConnected, presenterIdentity]
	);

	const participantDisconnected = useCallback(
		(participant) => {
			const videoTracksToRemove = Array.from(participant.videoTracks.values());
			const audioTracksToRemove = Array.from(participant.audioTracks.values());

			if (videoTracksToRemove.length > 0) {
				setVideoTracks((oldVideoTracks) => oldVideoTracks.filter(
					(track) => !videoTracksToRemove.map((t) => t.trackSid).includes(track.sid)
				));
			}

			if (audioTracksToRemove.length > 0) {
				setAudioTracks((oldAudioTracks) => oldAudioTracks.filter(
					(track) => !audioTracksToRemove.map((t) => t.trackSid).includes(track.sid)
				));
			}

			if (onNewParticipantDisconnected) {
				onNewParticipantDisconnected(participant);
			}
		},
		[onNewParticipantDisconnected]
	);

	useEffect(() => {
		setVideoTracks([]);
		setAudioTracks([]);
		if (!room) return;

		room.on('participantConnected', participantConnected);
		room.on('participantDisconnected', participantDisconnected);

		room.participants.forEach(participantConnected);

		const participantVideoTracks = trackpubsToTracks(
			room.localParticipant.videoTracks
		);
		setVideoTracks((prev) => [...prev, ...participantVideoTracks]);

		const participantAudioTracks = trackpubsToTracks(
			room.localParticipant.audioTracks
		);

		setAudioTracks((prev) => [...prev, ...participantAudioTracks]);

		room.localParticipant.on('trackStopped', (track) => {
			if (track.kind === 'audio') {
				// there was a bug when we used to remove the current audio track all the tracks would be broken? so we got rid of this?
				setAudioTracks((oldAudioTracks) => oldAudioTracks.filter((t) => {
					if (lastAudioTrackIdsBeforeChangeRef.current && lastAudioTrackIdsBeforeChangeRef.current.length && lastAudioTrackIdsBeforeChangeRef.current.includes(t.id)) {
						return true;
					}
					return t.id !== track.id;
				}));
			} else {
				setVideoTracks((oldVideoTracks) => oldVideoTracks.filter((t) => t.id !== track.id));
			}
		});

		if (room.localParticipant.identity == presenterIdentity) {
			room.on('disconnected', () => {
				if (onHostDisconnected) {
					onHostDisconnected();
				}
			});

			// The reason we added this was because there are cases when u can change ur microphone from ur pc directly so it needs to be able to add the new track automatically
			room.localParticipant.on('trackStarted', (track) => {
				if (track.kind === 'audio') {
					setAudioTracks((oldAudioTracks) => {
						const foundTrack = oldAudioTracks.find((t) => t.id === track.id);
						if (foundTrack) return oldAudioTracks;
						return oldAudioTracks.concat(track);
					});
				}
			});
		}

		return () => {
			room.off('participantConnected', participantConnected);
			room.off('participantDisconnected', participantDisconnected);
			if (room.disconnect) {
				room.disconnect();
			}
		};
	}, [room]);

	useEffect(() => {
		if (videoTracks.length > 1) {
			setVideoTracks((oldVideoTracks) => {
				const oldHostVideoTracks = hostParticipantVideoTracksRef.current.filter(
					(videoTrack) => oldHostIdentitiesRef.current.includes(videoTrack.identity)
				);
				if (!oldHostVideoTracks || !oldHostVideoTracks.length) return oldVideoTracks;
				return oldVideoTracks.filter(
					(track) => !oldHostVideoTracks.map((t) => t.trackSid).includes(track.sid)
				);
			});

			setAudioTracks((oldAudioTracks) => {
				const oldHostAudioTracks = hostParticipantAudioTracksRef.current.filter(
					(audioTrack) => oldHostIdentitiesRef.current.includes(audioTrack.identity)
				);
				if (!oldHostAudioTracks || !oldHostAudioTracks.length) return oldAudioTracks;
				return oldAudioTracks.filter(
					(track) => !oldHostAudioTracks.map((t) => t.trackSid).includes(track.sid)
				);
			});
		}

		setNoParticipantHostingTheStream(!videoTracks || !videoTracks.length);
	}, [videoTracks]);

	return {
		audioTracks,
		videoTracks,
		hostParticipant,
		setHostParticipant,
		muted: mutedLocalMic,
		setMuted: setMutedLocalMic,
		noParticipantHostingTheStream,
		noParticipantHostingTheStreamRef,
		hostParticipantAudioTracks,
		hostParticipantAudioTracksRef,
		hostParticipantVideoTracks,
		hostParticipantVideoTracksRef,
		setVideoTracks,
		setAudioTracks,
		oldHostIdentitiesRef,
		lastAudioTrackIdsBeforeChangeRef
	};
};
export default useVideoRoom;
