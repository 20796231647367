/* eslint-disable no-mixed-operators */
import React, { useMemo, useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import clsx from 'clsx';
import EllipisWithTooltip from '../ellipsis-tooltip';
import HamburgerMenuIcon from '../../assets/icons/hamburger-menu-icon';
import { SellerEventAttendeeActionsContext } from '../../contexts/seller-event-attendee-actions';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import { TwilioTrackContext } from '../../contexts/twilio-track';
import MicOrRequestIcon from '../mic-or-request-mic-icon/index';
import { sellerAttendeeFilterOptions } from '../../utils/seller-attendees-filter-options';

const AttendeesEventQueue = () => {
	const { audioParticipants } = useContext(TwilioTrackContext);
	const { setSelectedUserForActions } = useContext(
		SellerEventAttendeeActionsContext
	);
	const {
		isHost,
		ownerNickname,
		attendees,
		attendeesFilterSelectedOption,
		attendeesSearchValue
	} = useContext(LiveEventDataContext);

	const filteredEventAttendees = useMemo(() => {
		if (!isHost) return attendees;
		return attendees.filter((at) => {
			const nicknameSearched = at.nickname
				.toLowerCase()
				.includes(attendeesSearchValue.toLowerCase());

			if (attendeesFilterSelectedOption) {
				const option = sellerAttendeeFilterOptions.find(
					(o) => o.value == attendeesFilterSelectedOption
				);
				if (option) {
					const hasAudioParticipants =						audioParticipants && audioParticipants.length;
					if (option.all) {
						return nicknameSearched;
					}
					if (option.activeMics) {
						if (!hasAudioParticipants) return false;
						return (
							audioParticipants.find(
								(ap) => ap.twilioParticipantId == at.twilioParticipantId && !ap.muted
							) != null && nicknameSearched
						);
					}
					if (option.micRequests) {
						return at.micRequest === true && nicknameSearched;
					}
					if (option.mutedMic) {
						if (!hasAudioParticipants) return false;
						return (
							audioParticipants.find(
								(ap) => ap.twilioParticipantId == at.twilioParticipantId && ap.muted
							) != null && nicknameSearched
						);
					}
				}
			}
			return nicknameSearched;
		});
	}, [
		JSON.stringify(attendees),
		JSON.stringify(audioParticipants),
		attendeesSearchValue,
		isHost,
		attendeesFilterSelectedOption
	]);

	return (
		<>
			<table className="table-fixed text-left w-full">
				<thead>
					<tr>
						<th
							className={clsx(
								isHost ? 'w-10/12' : 'w-full',
								'p-4 text-left text-sm font-bold text-gray-100 bg-djawn'
							)}
						>
							Screen name
						</th>
						{isHost && (
							<th className="w-3/12 p-4 text-center text-sm font-bold text-gray-100 bg-djawn">
								Actions
							</th>
						)}
					</tr>
				</thead>
				<tbody className="text-gray-800 text-sm dark:text-white dark:bg-darkGray-50">
					{(!filteredEventAttendees || !filteredEventAttendees.length) && (
						<p className="flex justify-center items-center m-5">
							There are no records to display
						</p>
					)}
					{filteredEventAttendees.map((attendee) => (
						<React.Fragment key={attendee.nickname}>
							<ReactTooltip />
							<tr>
								<td className="px-4 xxs:px-4 py-2">
									<div className="flex items-center">
										<p className="text-sm font-light leading-relaxed text-left flex w-full">
											{isHost ? (
												<span
													data-tip={attendee.name}
													className="text-sm truncate"
												>
													{attendee.nickname}
												</span>
											) : (
												<EllipisWithTooltip
													isParagraph
													placement="bottom"
													className="text-sm"
													overridenStyle={{
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis'
													}}
												>
													{attendee.nickname}
												</EllipisWithTooltip>
											)}
											<div className="w-1/12">
												<MicOrRequestIcon
													attendee={attendee}
													audioParticipants={audioParticipants}
												/>
											</div>
										</p>
									</div>
								</td>
								{isHost
									&& attendee.nickname != ownerNickname
									&& `${ownerNickname}-2` != attendee.nickname && (
									<td
										className="px-4 py-2 cursor-pointer whitespace-nowrap underline flex justify-center"
										onClick={() => setSelectedUserForActions(attendee)}
										onKeyDown={() => setSelectedUserForActions(attendee)}
									>
										<HamburgerMenuIcon className="w-6 h-6" />
									</td>
								)}
							</tr>
						</React.Fragment>
					))}
				</tbody>
			</table>
		</>
	);
};

export default AttendeesEventQueue;
