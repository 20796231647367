/* eslint-disable  no-mixed-spaces-and-tabs */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { connect } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';
import { css } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import { isTablet } from 'react-device-detect';
import clsx from 'clsx';
import { getUsersByIds } from '../../api/auth.request';
import EllipisWithTooltip from '../ellipsis-tooltip';
import useScreenOrientation from '../../hooks/useScreenOrientation';
import { CURRENT_CHAT_ID_FOR_NON_LIVE_EVENT } from '../../utils/constants';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const ConversationSidebar = ({
	onCreateNewConversation,
	onConversationChange,
	authenticatedUserId,
	mobileSize,
	showSidebar,
	setShowSidebar,
	setConversations,
	conversations,
	reload,
	userParticipants,
	setUserParticipants,
	selectedConversation
}) => {
	const database = firebase.database();
	const [
		conversationParticipantData,
		setConversationParticipantData
	] = useState([]);
	const [conversationData, setConversationData] = useState([]);
	const [firebaseDataLoaded, setFirebaseDataLoaded] = useState(false);
	const [conversationsLoaded, setConversationsLoaded] = useState(false);
	const participantUnreadMessagesRef = database
		.ref('participant_unread_messages')
		.orderByChild('user_id')
		.equalTo(authenticatedUserId);
	const [directConversationNames, setDirectConversationNames] = useState([]);
	const [showAllConversations, setShowAllConversations] = useState(true);
	const [showUnreadConversations, setShowUnreadConversations] = useState(true);
	const [
		conversationsStartedByEvent,
		setConversationsStartedByEvent
	] = useState([]);
	const { isLandscape } = useScreenOrientation();

	const sortAndfilterConversationNamesIfItsADirectMessage = async (
		unfilteredConversations
	) => {
		const tempUserIds = [];
		unfilteredConversations.forEach((conversation) => {
			const foundDirectConversation = directConversationNames.filter(
				(f) => f.id == conversation.id
			);
			if (!conversation.is_group) {
				if (foundDirectConversation && foundDirectConversation.length) {
					conversation.name = foundDirectConversation[0].name;
				}

				let participantUserId = conversation.user_id;

				if (conversation.user_id == authenticatedUserId) {
					const filteredParticipants = conversationParticipantData.filter(
						(f) => f.conversation_id == conversation.id
					);
					if (
						filteredParticipants
						&& filteredParticipants.length > 0
						&& filteredParticipants[0].user_id
					) {
						participantUserId = filteredParticipants[0].user_id;
					}
				}
				conversation.participant_user_id = participantUserId;

				if (
					!userParticipants.some((p) => p.user_id === participantUserId)
					&& !conversation.name
				) {
					tempUserIds.push(participantUserId);
				}
			}
		});

		let results = unfilteredConversations;

		results = unfilteredConversations.map((conversation) => {
			const foundUser = userParticipants
				.filter((u) => !tempUserIds.find((o) => o.user_id === u.user_id))
				.find((user) => user.user_id === conversation.participant_user_id);

			if (foundUser) {
				conversation.name = foundUser.name;
			}

			return conversation;
		});

		if (tempUserIds && tempUserIds.length) {
			const fetchedUsers = await getUsersByIds(tempUserIds);
			if (fetchedUsers && fetchedUsers.data && fetchedUsers.data.length) {
				setUserParticipants((old) => [
					...old,
					...fetchedUsers.data.filter(
						(u) => !old.find((o) => o.user_id === u.user_id)
					)
				]);
				results = unfilteredConversations.map((conversation) => {
					const foundUser = fetchedUsers.data.find(
						(user) => user.user_id === conversation.participant_user_id
					);

					if (foundUser) {
						conversation.name = foundUser.name;
					}

					return conversation;
				});
			}
		}

		const tempConversationNames = [];
		results.forEach((conversation) => {
			tempConversationNames.push({
				id: conversation.id,
				name: conversation.name
			});
		});

		results = Promise.all(
			await results.map(async (conversation) => {
				const messagesRef = firebase
					.database()
					.ref('message')
					.orderByChild('conversation_id')
					.equalTo(conversation.id)
					.limitToLast(1);

				const tempMessages = [];
				tempMessages.push(messagesRef.once('value'));
				const promisedConversations = await Promise.all(tempMessages);
				promisedConversations.forEach((snapshot) => {
					snapshot.forEach((childSnapshot) => {
						const messageObj = childSnapshot.val();
						conversation.date = messageObj.sent_date;
					});
				});
				return conversation;
			})
		);

		setDirectConversationNames(tempConversationNames);
		return results;
	};

	useEffect(() => {
		sessionStorage.removeItem(CURRENT_CHAT_ID_FOR_NON_LIVE_EVENT);
		database.ref('conversation_participant').once('value', (snapshot) => {
			const tempConversationParticipant = [];
			snapshot.forEach((childSnapshot) => {
				const conversationParticipant = childSnapshot.val();
				conversationParticipant.id = childSnapshot.key;
				if (
					conversationParticipant.show_conversation
					&& !conversationParticipant.live_event
				) {
					tempConversationParticipant.push(conversationParticipant);
				}
			});
			setConversationParticipantData(tempConversationParticipant);
		});

		let tempConversations = [];
		database.ref('conversation').once('value', (snapshot) => {
			tempConversations = [];
			const tempConverationsStartedByEvent = [];
			snapshot.forEach((childSnapshot) => {
				const conversation = childSnapshot.val();
				const showConversation =					(conversation.show_conversation || conversation.started_by_event)
					&& !conversation.live_event;
				if (showConversation && conversation.user_id !== authenticatedUserId) {
					tempConverationsStartedByEvent.push(childSnapshot.key);
				}
				if (
					!(conversation.user_id == authenticatedUserId && !showConversation)
				) {
					conversation.id = childSnapshot.key;
					tempConversations.push(conversation);
				}
			});
			setConversationsStartedByEvent(tempConverationsStartedByEvent);

			setConversationData(tempConversations);
			setFirebaseDataLoaded(true);
		});

		if (tempConversations && tempConversations.length > 0) {
			database.ref('message_mention').on('value', (mentionSnapshot) => {
				const tempMessageMentions = [];
				mentionSnapshot.forEach((childSnapshot) => {
					const messageMention = childSnapshot.val();
					messageMention.id = childSnapshot.key;
					tempMessageMentions.push(messageMention);
				});

				tempConversations = tempConversations.map((c) => {
					const filteredMessageMentions = tempMessageMentions.filter(
						(m) => m.conversation_id == c.id
							&& m.seen == false
							&& m.user_mentioned.id == authenticatedUserId
					);
					c.message_mentions = filteredMessageMentions;
					return c;
				});
				setConversationData(tempConversations);
				setFirebaseDataLoaded(true);
			});
			database.ref('conversation').on('value', (snapshot) => {
				tempConversations = [];
				const tempConverationsStartedByEvent = [];
				snapshot.forEach((childSnapshot) => {
					const conversation = childSnapshot.val();
					const showConversation =						(conversation.show_conversation || conversation.started_by_event)
						&& !conversation.live_event;
					if (
						showConversation
						&& conversation.user_id !== authenticatedUserId
					) {
						tempConverationsStartedByEvent.push(childSnapshot.key);
					}
					if (
						!(conversation.user_id == authenticatedUserId && !showConversation)
					) {
						conversation.id = childSnapshot.key;
						tempConversations.push(conversation);
					}
				});
				setConversationsStartedByEvent(tempConverationsStartedByEvent);
			});
		}
	}, [reload]);

	useEffect(() => {
		if (!firebaseDataLoaded) return;

		const tempConversations = [];
		const participatedConversations = conversationParticipantData
			.map((participant) => {
				const isStartedByEvent = conversationsStartedByEvent.filter(
					(c) => c == participant.conversation_id
				);
				if (
					participant
					&& participant.user_id
					&& participant.user_id === authenticatedUserId
					&& isStartedByEvent
				) {
					return participant;
				}
				return null;
			})
			.filter((f) => f != null);

		const otherUsersParticipatedConversations = conversationParticipantData
			.map((participant) => {
				if (
					participant
					&& participant.user_id
					&& participant.user_id !== authenticatedUserId
				) {
					return participant;
				}
				return null;
			})
			.filter((f) => f != null);

		conversationData.forEach((conversation) => {
			const participatedConversation = participatedConversations.find(
				(p) => p.conversation_id == conversation.id
			);
			if (
				conversation
				&& conversation.user_id
				&& (conversation.user_id === authenticatedUserId
					|| participatedConversation != null)
			) {
				if (!conversation.is_group) {
					const participant = conversationParticipantData[0];

					if (
						participant
						&& participant.conversation_id == conversation.id
						&& participant.user_id != authenticatedUserId
					) {
						conversation.participant_user_id = participant.user_id;
						conversation.host_user_id = conversation.user_id;
						conversation.user_id = authenticatedUserId;
					} else {
						if (participant && participant.conversation_id) {
							if (participant.conversation_id == conversation.id) {
								conversation.participant_user_id = participant.user_id;
							}
						}

						const foundOtherParticipantUser = otherUsersParticipatedConversations.find(
							(p) => p.conversation_id == conversation.id
						);
						if (foundOtherParticipantUser) {
							conversation.participant_user_id =								foundOtherParticipantUser.user_id;
							conversation.host_user_id = foundOtherParticipantUser.user_id;
						} else {
							conversation.host_user_id = authenticatedUserId;
							conversation.participant_user_id = authenticatedUserId;
						}
					}
				}
				if (
					(!conversation.is_group && conversation.host_user_id)
					|| (conversation.is_group && !conversation.host_user_id)
				) {
					if (participatedConversation != null) {
						if (conversation.last_seen_date) {
							conversation.last_seen_date =								participatedConversation.last_seen_date;
						}
						conversation.last_seen_date =							participatedConversation.last_seen_date;
						conversation.participant_id = participatedConversation.id;
					}
					tempConversations.push(conversation);
				}
			}
		});

		database.ref('message_mention').once('value', (mentionSnapshot) => {
			const tempMessageMentions = [];
			mentionSnapshot.forEach((childSnapshot) => {
				const messageMention = childSnapshot.val();
				messageMention.id = childSnapshot.key;
				tempMessageMentions.push(messageMention);
			});

			participantUnreadMessagesRef.once('value', (snapshot) => {
				let tempConversationsUnreadMessagesTemp = [];
				if (snapshot.val() && Object.keys(snapshot.val())[0]) {
					const participantUnreadMessage = snapshot.val()[
						Object.keys(snapshot.val())[0]
					];
					tempConversationsUnreadMessagesTemp =						participantUnreadMessage.conversation_unread_messages;
				}

				sortAndfilterConversationNamesIfItsADirectMessage(tempConversations)
					.then((c) => {
						let sortedConversationsWithMessages = c;

						sortedConversationsWithMessages = sortedConversationsWithMessages.map(
							(sortedConversation) => {
								const foundedConversationUnreadMessages = tempConversationsUnreadMessagesTemp.find(
									(conversationUnreadMessages) => sortedConversation.id
										== conversationUnreadMessages.conversation_id
								);

								if (foundedConversationUnreadMessages) {
									sortedConversation.numberOfUnreadMessages =										foundedConversationUnreadMessages.number_of_unread_messages;
								} else {
									sortedConversation.numberOfUnreadMessages = 0;
								}

								return sortedConversation;
							}
						);

						sortedConversationsWithMessages.sort(
							(a, b) => new Date(b.date) - new Date(a.date)
						);
						setConversations(sortedConversationsWithMessages);
					})
					.finally(() => {
						setConversationsLoaded(true);
					});
			});
		});
	}, [conversationData, firebaseDataLoaded]);

	useEffect(() => {
		if (!conversationsLoaded) return;

		participantUnreadMessagesRef.on('value', (snapshot) => {
			let tempConversationsUnreadMessagesTemp = [];
			if (snapshot.val() && Object.keys(snapshot.val())[0]) {
				const participantUnreadMessage = snapshot.val()[
					Object.keys(snapshot.val())[0]
				];
				tempConversationsUnreadMessagesTemp =					participantUnreadMessage.conversation_unread_messages;
			}

			setConversations((tempConversations) => [...tempConversations].map((sortedConversation) => {
				const foundedConversationUnreadMessages = tempConversationsUnreadMessagesTemp.find(
					(conversationUnreadMessages) => sortedConversation.id
							== conversationUnreadMessages.conversation_id
				);

				if (foundedConversationUnreadMessages) {
					const currentChatId = sessionStorage.getItem(
						CURRENT_CHAT_ID_FOR_NON_LIVE_EVENT
					);
					if (currentChatId && sortedConversation.id == currentChatId) {
						sortedConversation.numberOfUnreadMessages = 0;
					} else {
						sortedConversation.numberOfUnreadMessages =								foundedConversationUnreadMessages.number_of_unread_messages;
					}
				} else {
					sortedConversation.numberOfUnreadMessages = 0;
				}
				return sortedConversation;
			}));
		});
	}, [conversationsLoaded]);

	const handleConversationSelect = (object) => {
		setShowSidebar(false);
		sessionStorage.setItem(CURRENT_CHAT_ID_FOR_NON_LIVE_EVENT, object.id);
		onConversationChange(object);
	};

	const conversationComponent = (object, i) => (
		<div
			className={clsx(
				'flex px-4 hover:bg-gray-800',
				selectedConversation
					&& selectedConversation.id == object.id
					&& 'bg-djawn-300'
			)}
			key={i}
		>
			<div
				className="cursor-pointer item mt-0 flex items-center justify-between text-base font-normal text-gray-50 w-full px-6 py-2 active:bg-green-700"
				key={i}
				onClick={() => handleConversationSelect(object)}
				onKeyDown={() => handleConversationSelect(object)}
			>
				<EllipisWithTooltip
					placement="bottom"
					className="item-text text-md text-gray-50"
				>
					{object.name}
				</EllipisWithTooltip>
				{object.message_mentions
				&& object.message_mentions.length > 0
				&& object.numberOfUnreadMessages ? (
						<div className="rounded-full h-10 w-10 flex items-center justify-center border-2 border-grey border-b">
							<span className="font-bold text-sm px-10">
								{object.message_mentions.length}
							</span>
						</div>
					) : null}
			</div>
		</div>
	);
	if (!conversationsLoaded) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}

	return (
		<>
			<div
				className={`pb-6 bg-purple-darker text-purple-lighter overflow-y bg-djawn-light overflow-x-hidden h-full z-50 ${
					mobileSize
						? isTablet && isLandscape
							? 'w-2/5 md:block'
							: 'w-screen absolute left-0'
						: 'w-1/5 md:block'
				}
			${mobileSize && !showSidebar && 'hidden'}`}
				style={{ maxHeight: '95vh' }}
			>
				<ReactTooltip />
				<div className="flex mb-3">
					<div
						className="cursor-pointer item mt-0 flex items-center text-base font-normal hover:bg-gray-800 text-white w-full px-6 py-4 active:bg-green-700 border-b-4"
						onClick={() => {
							setShowSidebar(false);
							onCreateNewConversation();
						}}
						onKeyDown={() => {
							setShowSidebar(false);
							onCreateNewConversation();
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="mr-4"
							width="20"
							height="20"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
							/>
						</svg>
						<span className="item-text">Create new message</span>
					</div>
				</div>
				<div className="flex">
					<div
						className="w-full cursor-pointer shadow-sm flex-row items-center justify-between text-left text-gray-100 text-sm py-2 px-3 rounded-md bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-none flex font-bold tracking-wide"
						onClick={() => setShowUnreadConversations(!showUnreadConversations)}
						onKeyDown={() => setShowUnreadConversations(!showUnreadConversations)}
					>
						<div className="flex flex-row">
							Unread Messages
							{conversations
								&& conversations.filter((s) => s.numberOfUnreadMessages > 0)
									.length > 0 && (
								<div className="rounded-full h-6 w-6 flex items-center justify-center border-2 border-grey border-b ml-2">
									<span className="font-bold text-sm px-5">
										{
											conversations.filter(
												(s) => s.numberOfUnreadMessages > 0
											).length
										}
									</span>
								</div>
							)}
						</div>
						<span className="flex w-5 h-5">
							{showUnreadConversations && (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M19 9l-7 7-7-7"
									/>
								</svg>
							)}
							{!showUnreadConversations && (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M5 15l7-7 7 7"
									/>
								</svg>
							)}
						</span>
					</div>
				</div>
				{showUnreadConversations
					&& conversations
					&& conversations.filter((s) => s.numberOfUnreadMessages > 0).length
						> 0 && (
					<div
						className="overflow-y-auto mb-1"
						style={{ maxHeight: mobileSize && '75%' }}
					>
						{conversations.length
							? conversations
								.filter((s) => s.numberOfUnreadMessages > 0)
								.map((object, i) => conversationComponent(object, i))
							: null}
					</div>
				)}
				<div className="flex">
					<div
						className="w-full cursor-pointer shadow-sm flex-row items-center justify-between text-left text-gray-100 text-sm py-2 px-3 rounded-md bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-none flex font-bold tracking-wide"
						onClick={() => setShowAllConversations(!showAllConversations)}
						onKeyDown={() => setShowAllConversations(!showAllConversations)}
					>
						All
						<span className="flex w-5 h-5">
							{showAllConversations && (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M19 9l-7 7-7-7"
									/>
								</svg>
							)}
							{!showAllConversations && (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M5 15l7-7 7 7"
									/>
								</svg>
							)}
						</span>
					</div>
				</div>
				{showAllConversations && (
					<div
						className="overflow-y-auto"
						style={{ maxHeight: mobileSize && '75%' }}
					>
						{conversations.length
							? conversations
								.filter((s) => s.numberOfUnreadMessages <= 0)
								.map((object, i) => conversationComponent(object, i))
							: null}
					</div>
				)}
			</div>
		</>
	);
};

export default connect(
	(state) => ({
		unreadMessages: state.utils.unreadMessages
	}),
	null
)(ConversationSidebar);
