const useInAppBrowserDetection = () => {
	const isAppCalledWithinFacebookAppBrowser = () => {
		const ua = navigator.userAgent || navigator.vendor || window.opera;
		return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
	};

	const isAppCalledWithinInstagramAppBrowser = () => {
		const ua = navigator.userAgent || navigator.vendor || window.opera;
		return ua.indexOf('Instagram') > -1;
	};

	return {
		isAppCalledWithinFacebookAppBrowser,
		isAppCalledWithinInstagramAppBrowser
	};
};

export default useInAppBrowserDetection;
