import React, { useContext } from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';
import Popup from '../../../components/popup';
import { LiveEventViewsContainerContext } from '../../../contexts/live-event-views-container';

const EventNameInformationButton = ({
	showEventNameInChatContainer,
	setShowEventNameInChatContainer,
	buttonRef
}) => {
	const { updatePopupsStatus } = useContext(LiveEventViewsContainerContext);

	return (
		<Popup
			ref={buttonRef}
			removeTriggerEvents
			trigger={(
				<button
					type="button"
					className={`border-2 rounded-full md:h-10 h-8 md:w-10 w-8 flex items-center justify-center
							disabled:opacity-50 focus:outline-none  border-djawn mx-2 ${
				showEventNameInChatContainer ? 'bg-djawn' : 'bg-none'
				} `}
					style={{ zIndex: '30' }}
					onClick={() => setShowEventNameInChatContainer(!showEventNameInChatContainer)}
				>
					<InformationCircleIcon
						fill={`${showEventNameInChatContainer ? 'white' : 'red'}`}
						stroke={`${showEventNameInChatContainer ? 'red' : 'white'}`}
						className="w-6 h-6"
					/>
				</button>
			)}
		>
			<div
				style={{
					zIndex: '60'
				}}
			>
				<h3 className="text-white text-sm pt-4 pl-3" style={{ zIndex: '60' }}>
					Please click here to view the event name.
				</h3>
				<div
					className="flex justify-between px-3 pt-3 mb-1"
					style={{ zIndex: '60' }}
				>
					<p
						className="cursor-pointer text-white text-md"
						onClick={() => updatePopupsStatus('show_event_name', true)}
						onKeyDown={() => updatePopupsStatus('show_event_name', true)}
					>
						Dismiss
					</p>
					<p
						className="cursor-pointer text-white text-md"
						onClick={() => updatePopupsStatus('show_event_name', false)}
						onKeyDown={() => updatePopupsStatus('show_event_name', false)}
					>
						Don't show again
					</p>
				</div>
			</div>
		</Popup>
	);
};

export default EventNameInformationButton;
