/* eslint-disable array-bracket-spacing */
import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import { navigate } from 'gatsby-link';
import { ToastContainer } from 'react-toastify';
import HeaderHome from '../../../components/common/header-home';
import EventSidebar from '../../../components/common/event-sidebar';
import {
	loadEventBuyers,
	messageBuyers,
	verifySeller
} from '../../../api/event.request';
import MessageSenderModal from '../../../components/message-sender-modal';
import { formatUTCDateToLocal } from '../../../utils/formatter';

import { defaultToastError } from '../../../utils/toast-error';
import { defaultToastSucesss } from '../../../utils/toast-success';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const MessageBuyerPage = ({ params }) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [defaultSubject, setDefaultSubject] = useState(null);
	const [isSeller] = useState(true);
	const [buyers, setBuyers] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectAll, setSelectAll] = useState(true);

	useEffect(() => {
		verifySeller(params.eventId)
			.then(() => {
				loadEventBuyers(params.eventId)
					.then((res) => {
						const data = res.data.buyers.map((buyer) => {
							buyer.isSelected = false;
							return buyer;
						});

						setBuyers(data);

						setDefaultSubject(res.data.defaultEmailSubject);

						setLoading(false);
					})
					.catch((err) => {
						console.error(err);
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch(() => {
				navigate(`/event/${params.eventId}`);
			});
	}, [params.eventId]);

	const areAllBuyersSelectedOrUnselected = (selected) => {
		let result = false;

		[...buyers].forEach((buyer) => {
			if (buyer.isSelected == selected) {
				result = true;
			} else {
				result = false;
			}
		});

		return result;
	};
	useEffect(() => {
		if (!buyers || buyers.length == 0) return;

		if (areAllBuyersSelectedOrUnselected(true)) {
			setSelectAll(false);
		} else {
			setSelectAll(true);
		}
	}, [buyers]);

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	const onTableRowClick = (selectedBuyer) => {
		const tempBuyers = [...buyers].map((buyer) => {
			if (selectedBuyer.orderId == buyer.orderId) {
				buyer.isSelected = !buyer.isSelected;
			}
			return buyer;
		});
		setBuyers(tempBuyers);
	};

	if (loading) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}
	return (
		<div className="flex flex-col flex-1 h-full w-full relative">
			<HeaderHome hideSearch onChange={toggleSidebar} />

			{window && (
				<MessageSenderModal
					showModal={showModal}
					setShowModal={setShowModal}
					sendEmail={(requestBody) => {
						setLoading(true);
						requestBody.buyers = buyers.filter((b) => b.isSelected);
						messageBuyers(requestBody)
							.then(() => {
								setLoading(false);
								defaultToastSucesss('The emails were sent succesfully!');
							})
							.catch((err) => {
								if (err.response) {
									defaultToastError(err.response.data.error);
								}
							})
							.finally(() => {
								const tempBuyers = [...buyers].map((buyer) => {
									buyer.isSelected = false;
									return buyer;
								});
								setBuyers(tempBuyers);
							});
					}}
					defaultSubject={defaultSubject}
				/>
			)}

			<EventSidebar
				isOpen={isSidebarOpen}
				isSeller={isSeller}
				liveEventId={params.eventId}
			/>

			<div className="bg-white mx-auto h-full pt-0">
				<ToastContainer />
				<div className="mx-auto container bg-white dark:bg-gray-800 dark:bg-gray-800 shadow-lg rounded mt-5 mb-5">
					<div className="flex flex-row justify-end p-4 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
						<svg
							className="cursor-pointer w-6 h-6"
							onClick={() => navigate(`/event/${params.eventId}`)}
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</div>
					<div className="flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-start lg:items-stretch w-full">
						<div className="w-full flex flex-col items-start justify-end">
							<p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
								Message Buyers
							</p>
						</div>
						<div className="w-full flex flex-col lg:flex-row items-start lg:items-center justify-end">
							<div className="lg:ml-6 flex items-center">
								<div
									className="border-2 border-red-600 rounded-lg px-3 py-2 text-gray-800 cursor-pointer hover:bg-red-600 hover:text-gray-100"
									style={{ pointerEvents: 'none' }}
									disabled
									onClick={() => {
										setShowModal(true);
									}}
									onKeyDown={() => {
										setShowModal(true);
									}}
								>
									Send Email
								</div>
							</div>
						</div>
					</div>
					<div className="w-full overflow-x-scroll xl:overflow-x-hidden">
						<table className="min-w-full bg-white dark:bg-gray-800">
							<thead>
								<tr className="w-full h-8 border-gray-300 border-b py-8">
									<th className="pl-8 text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">
										<input
											type="checkbox"
											className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white dark:bg-gray-800 outline-none"
											onClick={() => {
												const tempBuyers = [...buyers].map((buyer) => {
													buyer.isSelected = selectAll == true;
													return buyer;
												});

												setBuyers(tempBuyers);
											}}
										/>
									</th>
									<th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">
										<div className="text-gray-600 dark:text-gray-400 opacity-0 cursor-default relative w-10">
											<div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-2 rounded-full bg-indigo-700 text-white flex justify-center items-center text-xs">
												#
											</div>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="icon icon-tabler icon-tabler-file"
												width="28"
												height="28"
												viewBox="0 0 24 24"
												strokeWidth="1.5"
												stroke="currentColor"
												fill="none"
												strokeLinecap="round"
												strokeLinejoin="round"
											>
												<path stroke="none" d="M0 0h24v24H0z" />
												<path d="M14 3v4a1 1 0 0 0 1 1h4" />
												<path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
											</svg>
										</div>
									</th>
									<th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">
										Name
									</th>
									<th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">
										Nickname
									</th>
									<th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">
										Email
									</th>
									<th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">
										Purchased Date
									</th>
									<th className="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4">
										<div className="opacity-0 w-2 h-2 rounded-full bg-indigo-400" />
									</th>
								</tr>
							</thead>
							<tbody>
								{buyers.map((buyer, index) => (
									<tr
										className="cursor-pointer h-16 border-gray-300 border-b"
										onClick={() => onTableRowClick(buyer)}
										key={index}
									>
										<td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4">
											<input
												type="checkbox"
												className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white dark:bg-gray-800 outline-none"
												checked={buyer.isSelected}
											/>
										</td>
										<td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
											<div className="relative w-10 text-gray-600 dark:text-gray-400">
												<div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-2 rounded-full bg-red-700 text-white flex justify-center items-center text-xs">
													{index + 1}
												</div>
											</div>
										</td>
										<td className="pr-6 whitespace-no-wrap">
											<div className="flex items-center">
												<div className="h-8 w-8">
													<img
														src={buyer.imageUrl}
														alt=""
														className="h-full w-full rounded-full overflow-hidden shadow"
													/>
												</div>
												<p className="ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm">
													{buyer.name}
												</p>
											</div>
										</td>

										<td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
											{buyer.nickname}
										</td>
										<td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
											{buyer.email}
										</td>
										<td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4">
											{formatUTCDateToLocal(buyer.purchaseDate)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MessageBuyerPage;
