/* eslint-disable indent */
/* eslint-disable array-bracket-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect, useRef } from 'react';
import CurrencyInput from 'react-currency-input-field';
import Modal from 'react-modal';
import BounceLoader from 'react-spinners/BounceLoader';
import { css } from '@emotion/core';
import { toast } from 'react-toastify';
import {
 Tab, Tabs, TabList, TabPanel
} from 'react-tabs';
import { navigate } from 'gatsby-link';
import shippoLogo from '../../images/shippo.png';
import { referenceData } from '../../api/referenceData.request';
import {
	getDefaultLogo,
	getSellerProfile,
	sellerProfileUpdate,
	getMerchantOnBoard,
	getShipmentOAuthUrl
} from '../../api/seller.request';
import { validateEmail } from '../../utils/commonFunction';
import { findAddressComponent } from '../../utils/placesParser';
import ImageDimensionInfo from '../common/image-dimension-info';
import success from '../../images/successful.png';

import AvatarImageCropModal from '../avatar-image-crop';
import paypalLogo from '../../images/payPal_logo.png';
import { FREE_SHIPPING_REFERENCE_DATA } from '../../utils/constants';
import TextInput from '../input/text-input';
import MaskInput from '../input/mask-input';
import AddressAutocompleteInput from '../input/address-autocomplete-input';
import { SHIPPO_REDIRECT_TO_PROFILE_PAGE_STATE } from '../../utils/constants/cache-keys';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		textAlign: 'center',
		borderRadius: '10px'
	}
};

function SellerEditViewProfilePage({
	paypalMerchantId,
	merchantId,
	shipmentCodeToSave
}) {
	const [addressChosenViaGoogleApi, setAddressChosenViaGoogleApi] = useState(
		false
	);
	const [storeLogoUpdated, setStoreLogoUpdated] = useState(false);
	const [storeName, setStoreName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState();
	const addressRef = useRef();
	const [addressLine1, setAddressLine1] = useState('');
	const [storeLogoName, setStoreLogoName] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [state, setState] = useState('');
	const [city, setCity] = useState('');
	const [apartmentSuite, setApartmentSuite] = useState('');
	const [isRemoveBtn, setIsRemoveBtn] = useState(false);
	const [photoSizeErr, setPhotoSizeErr] = useState(false);
	const [, setPhotoRatioErr] = useState(false);
	const [defaultLogo, setDefaultLogo] = useState('');
	const [photo, setPhoto] = useState('');
	const [referenceDataValue, setReferenceDataValue] = useState('');
	const [shippingTypesDataValue, setShippingTypesDataValue] = useState('');
	const [, setPaymentMethodDataValue] = useState('');
	const [frequencyShipping, setFrequencyShipping] = useState('');
	const [shippingType, setShippingType] = useState('');
	const [rate, setRate] = useState('');
	const [shipCardValue, setShipCardValue] = useState('');
	const [paymentUserName, setPaymentUserName] = useState({});
	const [frequencyShippingErr, setFrequencyShippingErr] = useState(false);
	const [rateErr, setRateErr] = useState(false);
	const [emailErr, setEmailErr] = useState(false);
	const [emailValidErr, setEmailValidErr] = useState(false);
	const [phoneNumberErr, setPhoneNumberErr] = useState(false);
	const [addressLine1Err, setAddressLine1Err] = useState(false);
	const [postalCodeErr, setPostalCodeErr] = useState(false);
	const [stateErr, setStateErr] = useState(false);
	const [cityErr, setCityErr] = useState(false);
	const [apartmentSuiteErr] = useState(false);
	const [loading, setLoading] = useState(true);
	const [shipCardValueErr, setShipCardValueErr] = useState(false);
	const [shippingTypeErr, setShippingTypeErr] = useState(false);
	const [modalIsOpen, setIsOpen] = useState(false);
	const [storeLogo, setStoreLogo] = useState('');
	const [tabValue, setTabValue] = useState(0);
	const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
	const [defaultStoreLogoUrl, setDefaultStoreLogoUrl] = useState(null);
	const [initialData, setInitialData] = useState({
		storeName: null,
		email: null,
		phoneNumber: null,
		postalCode: null,
		addressLine1: null,
		apartmentSuite: null,
		city: null,
		state: null,
		photoChanged: false,
		rate: null,
		shippingType: null,
		shippingCards: null,
		shippingFrequency: null,
		paypalUserName: null,
		venmoUserName: null,
		storeLogo: null
	});
	const [showCropper, setShowCropper] = useState(false);
	const [photoDataUrl, setPhotoDataUrl] = useState(null);
	const [merchantOnBoardUrl, setMerchantOnBoardUrl] = useState('');
	const [sellerMerchantId, setSellerMerchantId] = useState(paypalMerchantId);
	const [trackingId, setTrackingId] = useState(merchantId);
	const [showPaypalTab, setShowPaypalTab] = useState(false);
	const [hasAccessToShipment, setHasAccessToShipment] = useState(false);
	const [shipmentOAuthUrl, setShipmentOAuthUrl] = useState(null);

	useEffect(() => {
		if (sellerMerchantId && trackingId) {
			setTabValue(3);
			setSaveButtonDisabled(false);
		} else {
			setSaveButtonDisabled(true);
		}
	}, [sellerMerchantId]);

	useEffect(() => {
		if (!shipmentCodeToSave) return;
		setTabValue(2);
	}, [shipmentCodeToSave]);

	const calculateTopToPlacesContainer = () => {
		const pacContainer = document.querySelectorAll('.pac-container');
		const addressLine1Container = pacContainer[2];

		if (
			addressLine1Container
			&& addressRef.current
			&& addressRef.current.getBoundingClientRect()
		) {
			const topFirstAddress =				addressRef.current.getBoundingClientRect().top + 40;
			addressLine1Container.style.top = `${topFirstAddress}px`;

			let isDone = true;

			const interval = setInterval(() => {
				if (isDone) {
					addressLine1Container.style.top = `${topFirstAddress}px`;
					isDone = false;
				} else {
					clearInterval(interval);
				}
			}, 10);
		}
	};

	const showToast = (message) => {
		toast.error(message, {
			position: 'top-center',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};

	const getBlobFromUrl = (myImageUrl) => new Promise((resolve, reject) => {
			const request = new XMLHttpRequest();
			request.open('GET', myImageUrl, true);
			request.responseType = 'blob';
			request.onload = () => {
				resolve(request.response);
			};
			request.onerror = reject;
			request.send();
		});

	const getDataFromBlob = (myBlob) => new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				resolve(reader.result);
			};
			reader.onerror = reject;
			reader.readAsDataURL(myBlob);
		});

	const convertUrlToImageData = async (myImageUrl) => {
		setIsRemoveBtn(true);
		try {
			const myBlob = await getBlobFromUrl(myImageUrl);
			const myImageData = await getDataFromBlob(myBlob);
			setDefaultLogo(myImageData);
			return myImageData;
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const handleValidateEmail = (emailAddress) => {
		if (emailAddress) {
			setEmail(emailAddress);
			const result = validateEmail(emailAddress);
			if (result === false) {
				setEmailValidErr(true);
				setEmailErr(false);
				return false;
			}
			setEmailValidErr(false);
			setEmailErr(false);
			return true;
		}
		setEmail(emailAddress);
		setEmailValidErr(false);
		setEmailErr(false);
		return false;
	};

	useEffect(() => {
		if (!loading) {
			const fieldsUpdated =				initialData.email != email
				|| initialData.storeName != storeName
				|| initialData.phoneNumber != phoneNumber
				|| initialData.postalCode != postalCode
				|| initialData.addressLine1 != addressLine1
				|| initialData.apartmentSuite != apartmentSuite
				|| initialData.city != city
				|| initialData.state != state
				|| initialData.rate != rate
				|| initialData.shippingType != shippingType
				|| initialData.shippingCards != shipCardValue
				|| initialData.shippingFrequency != frequencyShipping
				|| initialData.paypalUserName != paymentUserName.a1
				|| initialData.venmoUserName != paymentUserName.a2
				|| shipmentCodeToSave
				|| photo;

			if (!handleValidateEmail(email) || !fieldsUpdated) {
				setSaveButtonDisabled(true);
			} else {
				setSaveButtonDisabled(false);
			}
		}
	}, [
		email,
		phoneNumber,
		addressLine1,
		storeLogoName,
		postalCode,
		state,
		city,
		apartmentSuite,
		photo,
		frequencyShipping,
		shippingType,
		rate,
		shipCardValue,
		paymentUserName,
		shipmentCodeToSave
	]);

	useEffect(() => {
		if (!photoDataUrl) return;

		setShowCropper(true);
	}, [photoDataUrl]);

	const handlePhoneNumber = (event) => {
		setPhoneNumber(event.target.value);
		if (event.target.value) {
			setPhoneNumberErr(false);
		} else {
			// setPhoneNumberErr(true);
		}
	};

	const beforeMaskedValueChange = (newState, oldState, userInput) => {
		let { value } = newState;
		let { selection } = newState;
		let cursorPosition = selection ? selection.start : null;

		// keep minus if entered by user
		if (value.endsWith('-') && userInput !== '-') {
			if (cursorPosition === value.length) {
				cursorPosition--;
				selection = { start: cursorPosition, end: cursorPosition };
			}
			value = value.slice(0, -1);
		}

		return {
			value,
			selection
		};
	};

	function handleDefaultLogo() {
		getDefaultLogo()
			.then((res) => {
				setDefaultStoreLogoUrl(res.data);
			})
			.catch(() => {
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
				setIsRemoveBtn(false);
			});
	}

	function handleRemoveImage() {
		setStoreLogoUpdated(true);
		setPhotoRatioErr(false);
		handleDefaultLogo();
	}

	const ratioAspectImage = async (file) => {
		if (file) {
			const outputImageAspectRatio = 1;
			const imageAspect = await ImageDimensionInfo(file);
			const inputImageAspectRatio = imageAspect.width / imageAspect.height;
			if (inputImageAspectRatio != outputImageAspectRatio) {
				setPhotoRatioErr(true);
			} else {
				setPhotoRatioErr(false);
			}
		}
	};
	function handlePhoto(e) {
		setIsRemoveBtn(true);
		if (e && e.target && e.target.files && e.target.files[0]) {
			setStoreLogoUpdated(true);
			ratioAspectImage(e.target.files[0]);
			setStoreLogoName(e.target.files[0].name);
			setStoreLogo(e.target.files[0]);
		} else {
			setStoreLogoName('');
			setStoreLogo('');
		}

		let bytes = 0;
		if (e.target.files[0] && e.target.files[0].size) {
			bytes = e.target.files[0].size;
		}
		const kb = (bytes / 1024).toFixed(2);

		const file = e.target.files[0];
		const reader = new FileReader();

		if (reader !== undefined && file !== undefined) {
			reader.onloadend = () => {
				// setDefaultLogo(reader.result);
				setPhotoDataUrl(reader.result);
			};
			reader.readAsDataURL(file);
		}

		if (kb > 2048) {
			setPhotoSizeErr(true);
		} else {
			setPhoto(e.target.files[0]);
		}
	}

	const handleShippingRate = (rateValue) => {
		setRate(rateValue);
		if (!rateValue && shippingType === 139) {
			// return setRateErr(true);
		} else {
			setRateErr(false);
		}
	};

	const handleShipCards = (id) => {
		setShipCardValue(id);
		if (!id) {
			// return setShipCardValueErr(true);
		} else {
			setShipCardValueErr(false);
		}
	};

	useEffect(() => {
		if (!hasAccessToShipment) return;
		getShipmentOAuthUrl()
			.then((res) => {
				setShipmentOAuthUrl(res.data.url);
				localStorage.setItem(
					SHIPPO_REDIRECT_TO_PROFILE_PAGE_STATE,
					res.data.state
				);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [hasAccessToShipment]);

	const getSellerProfileData = () => {
		setLoading(true);
		getSellerProfile(true)
			.then((res) => {
				if (res && res.data) {
					setHasAccessToShipment(res.data.hasAccessToShipment);
					setStoreName(res.data.storeName);
					setEmail(res.data.email);
					setPhoneNumber(res.data.phoneNumber);
					setPostalCode(res.data.postalCode);
					setAddressLine1(res.data.mailingAddress1);
					setApartmentSuite(res.data.mailingAddress2);
					setCity(res.data.mailingCity);
					setState(res.data.state);
					setRate(res.data.rate);
					setShippingType(res.data.shippingType);
					setShipCardValue(res.data.shippingCards);
					setFrequencyShipping(res.data.shippingFrequency);
					setPaymentUserName({
						a1: res.data.paypalUserName,
						a2: res.data.venmoUserName
					});

					if (res.data.isDefaultLogo) {
						setIsRemoveBtn(false);
					}

					if (!res.data.storeLogo) {
						handleDefaultLogo();
					} else if (!res.data.isDefaultLogo) {
						convertUrlToImageData(res.data.storeLogo);
					} else {
						setDefaultLogo(res.data.storeLogo);
					}
					setShowPaypalTab(res.data.paypalOnBoard);

					const sellerProfileData = res.data;
					const tempInitData = {
						storeName: sellerProfileData.storeName,
						email: sellerProfileData.email,
						phoneNumber: sellerProfileData.phoneNumber,
						postalCode: sellerProfileData.postalCode,
						addressLine1: sellerProfileData.mailingAddress1,
						apartmentSuite: sellerProfileData.mailingAddress2,
						city: sellerProfileData.mailingCity,
						state: sellerProfileData.state,
						storeLogo: sellerProfileData.storeLogo,
						rate: sellerProfileData.rate,
						shippingType: sellerProfileData.shippingType,
						shippingCards: sellerProfileData.shippingCards,
						shippingFrequency: sellerProfileData.shippingFrequency,
						paypalUserName: sellerProfileData.paypalUserName,
						venmoUserName: sellerProfileData.venmoUserName,
						photoChanged: false,
						photo: null
					};

					setInitialData(tempInitData);
					if (!sellerMerchantId && !trackingId && !shipmentCodeToSave) {
						setSaveButtonDisabled(true);
					} else {
						setSaveButtonDisabled(false);
					}
				}
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setLoading(true);

		if (window) {
			window.addEventListener(
				'scroll',
				() => {
					calculateTopToPlacesContainer();
				},
				true
			);
			window.addEventListener(
				'touchmove',
				() => {
					calculateTopToPlacesContainer();
				},
				true
			);
		}

		getSellerProfileData();

		const referenceTypeIdShipCards = 38;
		referenceData(referenceTypeIdShipCards)
			.then((res) => {
				setReferenceDataValue(res.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.error(err.response);
			});

		const referenceTypeIdShippingTypes = 39;
		referenceData(referenceTypeIdShippingTypes)
			.then((res) => {
				setShippingTypesDataValue(res.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.error(err.response);
			});

		const referenceTypeIdPaymentMethod = 40;
		referenceData(referenceTypeIdPaymentMethod)
			.then((res) => {
				setPaymentMethodDataValue(res.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.error(err.response);
			});

		getMerchantOnBoard(true)
			.then((res) => {
				if (res.data) {
					if (res.data.links && res.data.links.length > 0) {
						setMerchantOnBoardUrl(res.data.links[1].href);
					}
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const handleFrequencyShipping = (value) => {
		setFrequencyShipping(value);
		if (!value) {
			// return setFrequencyShippingErr(true);
		} else {
			setFrequencyShippingErr(false);
		}
	};

	const handleShippingType = (id) => {
		setShippingType(id);
		if (!id) {
			// return setShippingTypeErr(true);
		} else {
			setShippingTypeErr(false);
		}
	};

	const handleAddressLine1 = (event) => {
		calculateTopToPlacesContainer();
		if (event) {
			if (event.target.value === '') {
				setAddressLine1('');
			} else {
				setAddressLine1(event.target.value);
			}
		}

		if (addressLine1) {
			setAddressLine1Err(false);
		} else {
			// setAddressLine1Err(true);
		}
		if (!city) {
			// setCityErr(true);
		} else {
			setCityErr(false);
		}
		if (!state) {
			// setStateErr(true);
		} else {
			setStateErr(false);
		}
		if (!postalCode) {
			// setPostalCodeErr(true);
		} else {
			setPostalCodeErr(false);
		}
	};

	useEffect(() => {
		handleAddressLine1();
	}, [addressLine1]);

	const handleUpdateProfile = () => {
		if (
			!addressChosenViaGoogleApi
			&& addressRef
			&& addressRef.current != null
			&& initialData.addressLine1 !== addressRef.current.value
		) {
			showToast('Please enter a valid address');
			return;
		}
		const paypalUserName =			paymentUserName && paymentUserName.a1 ? paymentUserName.a1 : '';
		const venmoUserName =			paymentUserName && paymentUserName.a2 ? paymentUserName.a2 : '';

		if (email) {
			setEmailErr(false);
		} else {
			setEmailErr(true);
		}
		if (phoneNumber) {
			setPhoneNumberErr(false);
		} else {
			setPhoneNumberErr(true);
		}
		if (addressLine1) {
			setAddressLine1Err(false);
		} else {
			setAddressLine1Err(true);
		}
		if (postalCode) {
			setPostalCodeErr(false);
		} else {
			setPostalCodeErr(true);
		}
		if (city) {
			setCityErr(false);
		} else {
			setCityErr(true);
		}
		if (state) {
			setStateErr(false);
		} else {
			setStateErr(true);
		}
		if (shipCardValue) {
			setShipCardValueErr(false);
		} else {
			setShipCardValueErr(true);
		}
		if (shippingType) {
			setShippingTypeErr(false);
		} else {
			setShippingTypeErr(true);
		}
		if (frequencyShipping) {
			setFrequencyShippingErr(false);
		} else {
			setFrequencyShippingErr(true);
		}

		if (!rate && shippingType === 139) {
			setRateErr(true);
		} else {
			setRateErr(false);
		}

		if (document.getElementById('ap1') != null) {
			if (
				!paymentUserName.a1
				|| paymentUserName.a1 === ''
				|| paymentUserName == {}
				|| JSON.stringify(paymentUserName) === JSON.stringify({})
			) {
				document.getElementById('ap1').style.display = 'block';
			} else {
				document.getElementById('ap1').style.display = 'none';
			}
		}

		if (document.getElementById('ap2') != null) {
			if (
				!paymentUserName.a2
				|| paymentUserName.a2 === ''
				|| paymentUserName == {}
				|| JSON.stringify(paymentUserName) === JSON.stringify({})
			) {
				document.getElementById('ap2').style.display = 'block';
			} else {
				document.getElementById('ap2').style.display = 'none';
			}
		}

		let formStatus = false;

		if (
			email
			&& phoneNumber
			&& addressLine1
			&& postalCode
			&& city
			&& state
			&& shipCardValue
			&& shippingType
			&& frequencyShipping
			&& ((rate && shippingType === 139) || shippingType != 139)
			&& paymentUserName
		) {
			setLoading(true);
			formStatus = true;
		} else {
			formStatus = false;
		}

		const storeLogo64 = defaultLogo.replace(/^data:image\/\w+;base64,/, '');

		let rateValue = rate;
		if (shippingType === FREE_SHIPPING_REFERENCE_DATA) {
			rateValue = 0;
		}
		const body = {
			email,
			phone: phoneNumber,
			mailingAddress1: addressLine1,
			mailingAddress2: apartmentSuite,
			mailingState: state,
			mailingZip: postalCode,
			mailingCity: city,
			storeLogoName,
			shipAllCards: shipCardValue,
			shippingType,
			fixedShiprates: rateValue,
			shippingFrequency: frequencyShipping,
			payPalUser: paypalUserName,
			venmoUser: venmoUserName,
			updatedStoreLogo: storeLogoUpdated,
			shipmentCode: shipmentCodeToSave
		};

		if (sellerMerchantId && trackingId) {
			body.paypalMerchantId = sellerMerchantId;
			body.paypalTrackingId = trackingId;
		}

		if (storeLogo && isRemoveBtn) {
			body.storeLogo = storeLogo64;
		}

		if (formStatus) {
			sellerProfileUpdate(body)
				.then(() => {
					setIsOpen(true);
					setLoading(false);
					setSellerMerchantId(null);
					setTrackingId(null);

					if (sellerMerchantId) {
						setSaveButtonDisabled(true);
						navigate('/profile');
					} else if (shipmentCodeToSave) {
						setSaveButtonDisabled(true);
						navigate('/profile');
					} else {
						getSellerProfileData();
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.response && error.response.status === 400) {
							setLoading(false);
							return showToast('Bad request');
						}
						if (error.response && error.response.status === 401) {
							setLoading(false);
							return showToast(error.response.data.message);
						}
						if (error.response && error.response.status === 500) {
							setLoading(false);
							return showToast('Internal server error');
						}
					}

					setLoading(false);
					return showToast('Something went wrong');
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	};

	function saveButton() {
		return (
			<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0">
				<div>
					<button
						type="button"
						disabled={saveButtonDisabled}
						className={`${
							saveButtonDisabled ? 'bg-red-300' : 'bg-djawn hover:bg-red-900'
						} w-2/3 h-10 mx-auto flex items-center justify-center px-6 py-2 border border-transparent text-base font-medium rounded-md text-white  md:py-4 md:text-lg md:px-10`}
						onClick={handleUpdateProfile}
					>
						SAVE
					</button>
				</div>
			</div>
		);
	}
	function closeModal() {
		setIsOpen(false);
	}

	return (
		<div className="grid grid-flow-row auto-rows-max gap-4 bg-white px-4">
			<AvatarImageCropModal
				open={showCropper}
				setOpen={setShowCropper}
				image={photoDataUrl}
				onUpload={(img) => {
					const xhr = new XMLHttpRequest();
					xhr.responseType = 'blob';

					xhr.onload = function () {
						const recoveredBlob = xhr.response;

						const reader = new FileReader();

						reader.onload = function () {
							const blobAsDataUrl = reader.result;
							setDefaultLogo(blobAsDataUrl);
							setShowCropper(false);
						};

						reader.readAsDataURL(recoveredBlob);
					};

					xhr.open('GET', img);
					xhr.send();
				}}
			/>
			{loading ? (
				<div className="w-screen bg-white absolute left-0 top-0">
					<div className="flex h-full items-center">
						<BounceLoader loading css={override} size={30} />
					</div>
				</div>
			) : (
				<>
					<div
						className="relative flex flex-col min-w-0 break-words bg-white w-full"
						style={{ marginTop: '1rem' }}
					>
						<div className="px-4 flex-auto">
							<Tabs
								className="w-full"
								onSelect={(e) => setTabValue(e)}
								selectedIndex={tabValue}
								selectedTabClassName="selected-tab"
							>
								<TabList className="cursor-pointer flex mb-0 list-none flex-wrap flex-row bg-gray-200 rounded-lg">
									<Tab className="-mb-px last:mr-0 flex-auto pt-3 pb-4 text-center ">
										General
									</Tab>

									<Tab className="-mb-px last:mr-0 flex-auto pt-3 pb-4 text-center">
										Shipping
									</Tab>
									{hasAccessToShipment && (
										<Tab className="-mb-px last:mr-0 flex-auto pt-3 pb-4 text-center">
											Shippo information
										</Tab>
									)}
									{showPaypalTab && (
										<Tab className="-mb-px last:mr-0 flex-auto pt-3 pb-4 text-center">
											Paypal information
										</Tab>
									)}
								</TabList>
								<TabPanel className="tab-content tab-space mt-5">
									<div className="flex xxs:flex-col bg-gray-50 mx-auto w-4/5 xxs:w-full h-auto py-4 px-12 xxs:px-0 mt-12 xxs:mt-0 rounded-lg max-w-3xl">
										<div className="flex xxs:flex-col sm:flex-col md:flex-row w-full max-w-lg mx-auto mt-2">
											<div className="flex-grow p-3">
												<div className="pl-2 mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
													<label className="block text-sm font-medium text-gray-700 pl-2">
														<h1 className="mb-4 mt-2 pl-2">
															<b>{storeName}</b>
														</h1>
													</label>
													<div className="flex flex-row-reverse justify-center flex-grow-0 relative xxs:w-full sm:w-full md:w-1/2 h-full xxs:h-auto mx-auto p-4 pt-0">
														{isRemoveBtn && (
															<button
																type="button"
																className="w-10 h-10 p-2 rounded-full text-gray-900 hover:bg-gray-200 focus:outline-none"
																onClick={handleRemoveImage}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	className="h-6 w-6"
																	fill="none"
																	viewBox="0 0 24 24"
																	stroke="currentColor"
																>
																	<path
																		strokeLinecap="round"
																		strokeLinejoin="round"
																		strokeWidth="2"
																		d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
																	/>
																</svg>
															</button>
														)}

														<div className="">
															<button
																type="button"
																className="flex justify-center items-center w-10 h-10 rounded-full text-gray-900 hover:bg-gray-200 focus:outline-none"
															>
																<label
																	htmlFor="photo"
																	className="seller-view-custom-file-upload fas"
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			strokeWidth="2"
																			d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
																		/>
																	</svg>

																	<input
																		type="file"
																		name="photo"
																		id="photo"
																		className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
																		accept="image/png, image/jpg, image/jpeg"
																		onChange={handlePhoto}
																	/>
																</label>
															</button>
														</div>
														<img
															alt="seller default"
															htmlFor="default_photo"
															id="defaultLogo"
															src={defaultLogo || defaultStoreLogoUrl}
															style={{
																width: '192px',
																height: '192px'
															}}
														/>
													</div>

													{photoSizeErr && (
														<p className="text-red-700">
															Maximum size can be 2 mb
														</p>
													)}
												</div>

												<div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
													<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
														<label
															htmlFor="email"
															className="block text-sm font-medium text-gray-700 pl-2 required"
														>
															Email
														</label>
														<div className="mt-1 relative rounded-md shadow-sm">
															<TextInput
																type="email"
																name="email"
																id="email"
																width="full"
																mobileWidth="full"
																height="10"
																mobileHeight="10"
																placeholder="Emample : store@store.com"
																value={email}
																required
																maxLength={50}
																onChange={(e) => handleValidateEmail(e.target.value)}
															/>
															{emailErr && (
																<p className="text-red-700">
																	This field is required
																</p>
															)}
															{emailValidErr && (
																<p className="text-red-700">
																	Enter a valid email address
																</p>
															)}
														</div>
													</div>

													<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
														<label
															htmlFor="phone_number"
															className="block text-sm font-medium text-gray-700 pl-2 required"
														>
															Phone Number
														</label>
														<div className="mt-1 relative rounded-md shadow-sm">
															<MaskInput
																width="full"
																mobileWidth="full"
																height="10"
																mobileHeight="10"
																placeholder="Example : +1 (205) 258-5898"
																value={phoneNumber}
																onChange={handlePhoneNumber}
																beforeMaskedValueChange={
																	beforeMaskedValueChange
																}
																mask="+1 (999) 999-9999"
																maskChar=" "
															/>
															{phoneNumberErr && (
																<p className="text-red-700">
																	This field is required
																</p>
															)}
														</div>
													</div>
												</div>

												<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full store-address">
													<label
														htmlFor="address_line1"
														className="block text-sm font-medium text-gray-700 pl-2 required"
													>
														Address
													</label>
													<div className="mt-1 relative rounded-md shadow-sm">
														<AddressAutocompleteInput
															onPlaceSelected={(place, e) => {
																setAddressChosenViaGoogleApi(true);
																const streetNumber = findAddressComponent(
																	place.address_components,
																	'street_number'
																);
																const foundPostalCode = findAddressComponent(
																	place.address_components,
																	'postal_code'
																);
																if (foundPostalCode) {
																	setPostalCode(foundPostalCode);
																}
																if (!foundPostalCode) {
																	setPostalCode('');
																}

																const foundState = findAddressComponent(
																	place.address_components,
																	'administrative_area_level_1',
																	true
																);
																if (foundState) {
																	setState(foundState);
																}
																if (!foundState) {
																	setState('');
																}

																if (place.vicinity) {
																	setCity(place.vicinity);
																}
																if (!place.vicinity) setCity('');

																const route = findAddressComponent(
																	place.address_components,
																	'route'
																);

																if (route) {
																	if (streetNumber) {
																		setAddressLine1(`${streetNumber} ${route}`);
																		if (e.value) {
																			e.value = `${streetNumber} ${route}`;
																		}
																	} else {
																		setAddressLine1(route);
																		if (e.value) {
																			e.value = route;
																		}
																	}
																}
															}}
															width="full"
															mobileWidth="full"
															height="10"
															mobileHeight="10"
															options={{
																fields: [
																	'address_components',
																	'vicinity',
																	'formatted_address'
																],
																types: ['address'],
																componentRestrictions: {
																	country: ['us']
																}
															}}
															defaultValue={addressLine1}
															placeholder="123 Ave Rackford, WI, 6383q - Required value"
															onChange={(e) => {
																if (
																	e.target.value
																	|| e.target.value.length === 0
																) {
																	setCity('');
																	setPostalCode('');
																	setState('');
																}
																setAddressChosenViaGoogleApi(false);
																handleAddressLine1();
															}}
															ref={addressRef}
															onFocus={() => {
																calculateTopToPlacesContainer();
															}}
															onBlur={() => {
																calculateTopToPlacesContainer();
															}}
														/>
														{addressLine1Err && (
															<p className="text-red-700">
																This field is required
															</p>
														)}
													</div>
												</div>

												<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full store-apartment">
													<label
														htmlFor="apartment_suite"
														className="block text-sm font-medium text-gray-700 pl-2 "
													>
														Apartment,suite,etc
													</label>
													<div className="mt-1 relative rounded-md shadow-sm">
														<TextInput
															type="text"
															name="apartment_suite"
															id="apartment_suite"
															width="full"
															mobileWidth="full"
															height="10"
															mobileHeight="10"
															placeholder="1716 East 7th Street"
															value={apartmentSuite}
															maxLength={100}
															onBlur={(e) => setApartmentSuite(e.target.value)}
															onChange={(e) => setApartmentSuite(e.target.value)}
														/>
														{apartmentSuiteErr && (
															<p className="text-red-700">
																This field is required
															</p>
														)}
													</div>
												</div>

												<div className="flex px-0 grid sm:grid-rows-3 md:grid-rows-3 lg:grid-rows-1 xl:grid-rows-1 lg:grid-flow-col xl:grid-flow-col gap-4">
													<div className="flex-1 mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
														<label
															htmlFor="city"
															className="block text-sm font-medium text-gray-700 pl-2 required"
														>
															City
														</label>
														<div className="mt-1 relative rounded-md shadow-sm">
															<TextInput
																type="text"
																name="city"
																id="city"
																width="full"
																mobileWidth="full"
																height="10"
																mobileHeight="10"
																value={city}
																required
																disabled
																onBlur={(e) => setCity(e.target.value)}
																onChange={(e) => setCity(e.target.value)}
															/>
															{cityErr && (
																<p className="text-red-700">
																	This field is required
																</p>
															)}
														</div>
													</div>

													<div className="flex-1 mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
														<label
															htmlFor="state"
															className="block text-sm font-medium text-gray-700 pl-2 required"
														>
															State
														</label>
														<div className="mt-1 relative rounded-md shadow-sm">
															<TextInput
																type="text"
																name="state"
																id="state"
																width="full"
																mobileWidth="full"
																height="10"
																mobileHeight="10"
																value={state}
																required
																disabled
																onBlur={(e) => setState(e.target.value)}
																onChange={(e) => setState(e.target.value)}
															/>
															{stateErr && (
																<p className="text-red-700">
																	This field is required
																</p>
															)}
														</div>
													</div>

													<div className="flex-1 mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
														<label
															htmlFor="postal_code"
															className="block text-sm font-medium text-gray-700 pl-2 required"
														>
															Postal code
														</label>
														<div className="mt-1 relative rounded-md shadow-sm">
															<TextInput
																type="text"
																name="postal_code"
																id="postal_code"
																width="full"
																mobileWidth="full"
																height="10"
																mobileHeight="10"
																value={postalCode}
																required
																disabled
																onBlur={(e) => setPostalCode(e.target.value)}
																onChange={(e) => setPostalCode(e.target.value)}
															/>
															{postalCodeErr && (
																<p className="text-red-700">
																	This field is required
																</p>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
										{saveButton()}
									</div>
								</TabPanel>
								<TabPanel className="tab-content tab-space">
									<div className="flex xxs:flex-col bg-gray-50 mx-auto w-4/5 xxs:w-full h-auto py-4 px-12 xxs:px-0 mt-12 xxs:mt-0 rounded-lg max-w-3xl">
										<div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 mb-2.5 mx-10">
											<div className="">
												<div className="sm:w-full md:w-full lg:w-full xl:w-full py-4">
													<label
														htmlFor="ship_cards"
														className="block text-sm font-medium text-gray-700 pl-2 required"
													>
														Ship Cards
													</label>
													<div className="flex flex-row xxs:flex-col md:flex-row  justify-center ">
														{referenceDataValue
															&& referenceDataValue.length > 0
															&& referenceDataValue.map((data, index) => (
																<div
																	className="mt-2 sm:w-full md:w-full lg:w-full xl:w-1/2 pl-2"
																	key={index}
																>
																	<label className="inline-flex items-center">
																		<input
																			type="radio"
																			className="form-radio"
																			name="shipCards"
																			// value={data.referenceDataValue}
																			checked={
																				shipCardValue === data.referenceDataId
																			}
																			onChange={() => handleShipCards(data.referenceDataId)}
																		/>
																		<span className="ml-2">
																			{data.referenceDataValue}
																		</span>
																	</label>
																</div>
															))}
													</div>
													{shipCardValueErr && (
														<p className="text-red-700">
															This field is required
														</p>
													)}
												</div>

												<div className="sm:w-full md:w-full lg:w-full xl:w-full pu-4">
													<div className="sm:mb-3 col-span-2">
														<div className="sm:w-full md:w-full lg:w-full xl:w-full ">
															<label
																htmlFor="ship_cards"
																className="block text-sm font-medium text-gray-700 pl-2 required"
															>
																Shipping Type
															</label>
															<div className="flex flex-row xxs:flex-col md:flex-row ">
																{shippingTypesDataValue
																	&& shippingTypesDataValue.length > 0
																	&& shippingTypesDataValue.map((data, index) => (
																		<div
																			className="mt-2 sm:w-full md:w-full lg:w-full xl:w-full pl-2"
																			key={index}
																		>
																			<label className="inline-flex items-center">
																				<input
																					type="radio"
																					className="form-radio"
																					name="shippingType"
																					checked={
																						shippingType
																						=== data.referenceDataId
																					}
																					onChange={() => handleShippingType(
																							data.referenceDataId
																						)}
																				/>
																				<span className="ml-2">
																					{data.referenceDataValue}
																				</span>
																			</label>
																		</div>
																	))}
															</div>

															{shippingTypeErr && (
																<p className="text-red-700">
																	This field is required
																</p>
															)}
														</div>
													</div>

													<div className="col-span-1">
														{shippingType === 139 && (
															<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full ">
																<label
																	htmlFor="rate"
																	className="block text-sm font-medium text-gray-700 pl-2 required"
																>
																	Rate
																</label>
																<div className="mt-1 relative rounded-md shadow-sm">
																	{rate ? (
																		<CurrencyInput
																			id="rate"
																			className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
																			name="rate"
																			placeholder="Please enter a value"
																			decimalsLimit={2}
																			defaultValue={rate}
																			maxLength={10}
																			allowNegativeValue={false}
																			value={rate}
																			onValueChange={(value) => handleShippingRate(value)}
																			intlConfig={{
																				locale: 'en-US',
																				currency: 'GBP'
																			}}
																			prefix="$"
																		/>
																	) : (
																		<CurrencyInput
																			id="rate"
																			className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
																			name="rate"
																			placeholder="Please enter a value"
																			decimalsLimit={2}
																			// defaultValue={rate}
																			maxLength={10}
																			allowNegativeValue={false}
																			// value={rate}
																			onValueChange={(value) => handleShippingRate(value)}
																			intlConfig={{
																				locale: 'en-US',
																				currency: 'GBP'
																			}}
																			prefix="$"
																		/>
																	)}
																</div>
																{rateErr && (
																	<p className="text-red-700">
																		This field is required
																	</p>
																)}
															</div>
														)}
													</div>
												</div>
											</div>

											<div className="mb-3 sm:w-full md:w-full lg:w-full xl:w-full mt-3">
												<label
													htmlFor="frequency_shipping"
													className="block text-sm font-medium text-gray-700 pl-2 required"
												>
													Frequency of shipping
												</label>
												<div className="mt-1 relative rounded-md ">
													<textarea
														type="textarea"
														name="frequency_shipping"
														id="frequency_shipping"
														className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none h-36"
														rows="4"
														// className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-32 sm:text-sm border border-gray-200 rounded-md"
														placeholder="Name of your store"
														value={frequencyShipping}
														required
														maxLength={100}
														// onBlur={(e) => setFrequencyShipping(e.target.value)}
														onChange={(e) => handleFrequencyShipping(e.target.value)}
													/>
												</div>
												{frequencyShippingErr && (
													<p className="text-red-700">This field is required</p>
												)}
											</div>
										</div>
										<p className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4 sm:mb-4 lg:mb-0 px-6 pb-6 text-gray-500">
											Please note that these shipping options are defaults that
											can be changed for individual rips when planning the
											event.
										</p>
										{saveButton()}
									</div>
								</TabPanel>
								<TabPanel className="tab-content tab-space">
									<div className="flex xxs:flex-col bg-gray-50 mx-auto w-4/5 xxs:w-full h-auto py-4 px-12 xxs:px-0 mt-12 xxs:mt-0 rounded-lg max-w-3xl">
										<div className="flex flex-col items-center mt-1 relative sm:w-full md:w-full lg:w-full xl:w-full space-y-2">
											<img
												alt="stripe"
												src={shippoLogo}
												width={250}
												height={100}
											/>
											<a
												href={shipmentOAuthUrl}
												className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
											>
												You are linked to Shippo, please click here to link to
												another account
											</a>
										</div>
										{shipmentCodeToSave && (
											<p className="flex justify-center my-1 text-gray-600">
												Please click save button to connect to new Shippo
												account
											</p>
										)}
										{saveButton()}
									</div>
								</TabPanel>
								<TabPanel className="tab-content tab-space">
									<div className="flex flex-col justify-center items-center bg-gray-50 mx-auto w-4/5 xxs:w-full h-auto py-4 px-12 xxs:px-0  xxs:mt-0 rounded-lg max-w-3xl">
										<div className="sm:col-auto md:col-span-1 lg:col-span-1 xl:col-s store-logo-info mb-10 ">
											<div className="flex flex-col items-center mt-1 relative sm:w-full md:w-full lg:w-full xl:w-full">
												<img
													alt="paypal"
													src={paypalLogo}
													width={200}
													height={100}
												/>
												<a
													href={merchantOnBoardUrl}
													className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
												>
													You are linked to Paypal please click here to link to
													another account
												</a>
											</div>
											<div className="flex items-center justify-center mt-3 mb-3">
												{paypalMerchantId && (
													<p>
														Please click save button to connect to new Paypal
														account
													</p>
												)}
											</div>
										</div>
										{saveButton()}
									</div>
								</TabPanel>
							</Tabs>
						</div>
					</div>

					<div>
						<Modal
							isOpen={modalIsOpen}
							onRequestClose={closeModal}
							style={customStyles}
							contentLabel="Submit_Modal"
						>
							<span
								className="justify-between mx-auto"
								style={{
									marginLeft: '0px',
									left: '116px',
									right: '88px',
									alignItems: 'center',
									position: 'absolute'
								}}
							>
								<img alt="success" src={success} width="40px" height="40px" />
							</span>
							<br />
							<h1 className="mt-8">
								<b>Successful</b>
							</h1>
							<br />
							<p>Changes are saved successfully</p>
							<br />
							<button
								type="button"
								style={{ backgroundColor: '#008000' }}
								className="min-w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded-lg"
								onClick={closeModal}
							>
								Done
							</button>
						</Modal>
					</div>
				</>
			)}
		</div>
	);
}
export default SellerEditViewProfilePage;
