import React, { useState, useEffect } from 'react';

import { useQueryParam, StringParam } from 'use-query-params';
import { navigate } from 'gatsby-link';

import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import Layout from '../components/layouts/layout-default';
import HeaderHome from '../components/common/header-home';
import { sellerApplicationDecision } from '../api/seller.request';
import { getUserRoles } from '../api/auth.request';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const SellerApplicationDecision = () => {
	const [token] = useQueryParam('token', StringParam);
	const [decision] = useQueryParam('decision', StringParam);
	const [loading, setLoading] = useState(true);
	const [result, setResult] = useState(false);
	const [message, setMessage] = useState('');

	useEffect(() => {
		if (!decision && !token) return;

		getUserRoles()
			.then((rolesRes) => {
				if (rolesRes.data.isAdmin === false) {
					setMessage(
						"You don't have admin rights to update the application status"
					);
					setResult(true);
					setLoading(false);
				} else if (rolesRes.data.isAdmin === true) {
					sellerApplicationDecision(token, decision === 'approve')
						.then(() => {
							setResult(true);
							if (decision === 'approve') {
								setMessage('You approved the application');
							} else {
								setMessage('You rejected the application');
							}
						})
						.catch((err) => {
							if (err && err.response) {
								setMessage(err.response.data.error.longMessage);
								setResult(true);
							}
						})
						.finally(() => {
							setLoading(false);
						});
				}
			})
			.catch(() => {
				const redirectUrl = `/seller-application?token=${token}&decision=${decision}`;
				navigate('/login', {
					state: { redirectUrl }
				});
			});
	}, [decision, token]);

	if (loading) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0 z-50">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}

	return (
		<Layout auth>
			<div className="flex flex-col flex-1">
				<HeaderHome hideSearch />
				<div className="flex flex-col flex-grow w-full h-full mt-4 items-center">
					<div className="flex flex-col bg-white w-5/6 rounded-lg content-center justify-center items-center h-80">
						{result === true && message && !loading && (
							<p
								className={`font-bold text-xl ${
									decision === 'approve'
										? 'text-greenModified-700'
										: 'text-djawn'
								}`}
							>
								{message}
							</p>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default SellerApplicationDecision;
