import React, { useState, useEffect, useCallback } from 'react';
import { isDesktop } from 'react-device-detect';
import SelectInput from '../../../components/input/select-input';

const StreamAudioSettings = ({ settings, setSettings }) => {
	const [microphones, setMicrophones] = useState([]);
	const [speakers, setSpeakers] = useState([]);
	const [selectedMicrophoneId, setSelectedMicrophoneId] = useState(null);
	const [selectedSpeakerId, setSelectedSpeakerId] = useState(null);

	const onSpeakerChange = useCallback(
		(deviceId, updateSettings = false, setState = true) => {
			if (setState) setSelectedSpeakerId(deviceId);
			if (updateSettings) {
				setSettings((prevSettings) => {
					let foundSetting = false;
					let newSettings = [...prevSettings]
						.map((setting) => {
							if (settings.type == 'speaker') {
								setting.deviceId = deviceId;
								foundSetting = true;
							}
							return settings;
						})
						.filter((setting) => !!setting.type);
					if (!foundSetting) {
						if (newSettings.length > 0) {
							newSettings.push({
								type: 'speaker',
								deviceId
							});
						} else {
							newSettings = [
								{
									type: 'speaker',
									deviceId
								}
							];
						}
					}
					return newSettings;
				});
			}
		},
		[settings]
	);

	const onMicrophoneChange = useCallback(
		(deviceId, updateSettings = false, setState = true) => {
			if (setState) setSelectedMicrophoneId(deviceId);
			if (updateSettings) {
				setSettings((prevSettings) => {
					let foundSetting = false;
					let newSettings = [...prevSettings]
						.map((setting) => {
							if (settings.type == 'microphone') {
								setting.deviceId = deviceId;
								foundSetting = true;
							}
							return settings;
						})
						.filter((setting) => !!setting.type);
					if (!foundSetting) {
						if (newSettings.length > 0) {
							newSettings.push({
								type: 'microphone',
								deviceId
							});
						} else {
							newSettings = [
								{
									type: 'microphone',
									deviceId
								}
							];
						}
					}
					return newSettings;
				});
			}
		},
		[settings]
	);

	useEffect(() => {
		let microphoneSettings = null;
		let speakerSettings = null;
		if (settings && settings.length) {
			microphoneSettings = settings.find(
				(setting) => setting.type === 'microphone'
			);
			speakerSettings = settings.find((setting) => setting.type === 'speaker');
		}
		navigator.mediaDevices
			.enumerateDevices()
			.then((mediaDevices) => {
				const microphoneDevices = mediaDevices.filter(
					(device) => device.kind == 'audioinput'
				);
				setMicrophones(microphoneDevices);
				if (microphoneDevices.length > 0 && !microphoneSettings) {
					onMicrophoneChange(
						microphoneDevices[0].deviceId,
						false,
						!microphoneSettings
					);
				}

				const speakerDevices = mediaDevices.filter(
					(device) => device.kind == 'audiooutput'
				);
				setSpeakers(speakerDevices);
				if (speakerDevices.length > 0 && !speakerSettings) {
					onSpeakerChange(speakerDevices[0].deviceId, false, !speakerSettings);
				}
			})
			.catch((err) => {
				console.error('error fetching media devices', err);
			});

		if (microphoneSettings && microphoneSettings.deviceId) {
			onMicrophoneChange(microphoneSettings.deviceId, false, true);
		}

		if (speakerSettings && speakerSettings.deviceId) {
			onSpeakerChange(speakerSettings.deviceId, false, true);
		}
	}, [settings]);

	return (
		<>
			<div className="relative px-3 flex-auto flex items-start space-x-2">
				<div>
					<label
						htmlFor="microphone"
						className="block text-sm font-medium text-gray-700 dark:text-white pl-2"
					>
						<div className="flex justify-start space-x-2">
							<div>Microphone</div>
						</div>
					</label>
					<div>
						<SelectInput
							id="microphone"
							width="full"
							mobileWidth="full"
							height="16"
							mobileHeight="16"
							name="audio"
							onBlur={(e) => onMicrophoneChange(e.target.value, true)}
							onChange={(e) => onMicrophoneChange(e.target.value, true)}
							value={selectedMicrophoneId}
						>
							{microphones.map((microphone) => (
								<option key={microphone.deviceId} value={microphone.deviceId}>
									{microphone.label}
								</option>
							))}
						</SelectInput>
					</div>
				</div>
			</div>
			{/* this feature is experimental as of now and only works on desktop devices: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId */}
			{isDesktop && (
				<div className="relative px-3 flex-auto flex items-start space-x-2">
					<div>
						<label
							htmlFor="speaker"
							className="block text-sm font-medium text-gray-700 dark:text-white pl-2"
						>
							<div className="flex justify-start space-x-2">
								<div>Speakers</div>
							</div>
						</label>
						<div>
							<SelectInput
								id="speakers"
								width="full"
								mobileWidth="full"
								height="16"
								mobileHeight="16"
								name="audio"
								onBlur={(e) => onSpeakerChange(e.target.value, true)}
								onChange={(e) => onSpeakerChange(e.target.value, true)}
								value={selectedSpeakerId}
							>
								{speakers.map((speaker) => (
									<option key={speaker.deviceId} value={speaker.deviceId}>
										{speaker.label}
									</option>
								))}
							</SelectInput>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default StreamAudioSettings;
