import { useCallback, useState, useRef } from 'react';
import { isFunction } from '../utils/isFunction';

const useStateRef = (defaultValue = null) => {
	const [state, setState] = useState(defaultValue);
	const ref = useRef(state);

	const dispatch = useCallback((value) => {
		ref.current = isFunction(value) ? value(ref.current) : value;
		setState(ref.current);
	}, []);

	return [state, dispatch, ref];
};

export default useStateRef;
