import clsx from 'clsx';
import React from 'react';

const WordpressPostContent = ({
	url = '', showContent, onError, onLoad
}) => {
	if (!url) return null;
	return (
		<div
			className={clsx(
				'flex justify-center h-full',
				showContent ? 'flex' : 'hidden'
			)}
		>
			<div className="h-full w-full">
				<iframe
					src={url}
					title="Homepage"
					width="100%"
					height="100%"
					onError={onError}
					onLoad={onLoad}
				/>
			</div>
		</div>
	);
};

export default WordpressPostContent;
