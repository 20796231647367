import React, { useContext } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { ProfileContext } from '../../../contexts/profile';
import useReferenceData from '../../../hooks/use-reference-data';
import TextArea from '../../../components/input/text-area';

const SellerShippingInformation = () => {
	const { formik } = useContext(ProfileContext);
	const [shipCards] = useReferenceData(38);
	const [shippingTypes] = useReferenceData(39);

	return (
		<>
			<div className="flex w-full">
				<p className="md:text-2xl text-xl text-gray-500 text-center mx-auto">
					Shipping Information
				</p>
			</div>
			<div className="flex flex-col w-full px-8 md:px-32 mx-auto">
				<div className="flex-grow pt-4 flex space-x-4 items-start justify-center md:flex-row flex-col">
					<div className="flex flex-col w-full mb-4">
						<div className="sm:w-full md:w-full lg:w-full xl:w-full py-4">
							<label
								htmlFor="ship_cards"
								className="block text-sm font-medium text-gray-700 pl-2 required"
							>
								Ship Cards
							</label>
							<div className="flex flex-row xxs:flex-col md:flex-row  justify-center ">
								{shipCards
									&& shipCards.length > 0
									&& shipCards.map((data, index) => (
										<div
											className="mt-2 sm:w-full md:w-full lg:w-full xl:w-1/2 pl-2"
											key={index}
										>
											<label className="inline-flex items-center">
												<input
													type="radio"
													className="form-radio"
													name="seller.shipAllCardsId"
													checked={
														formik.values.seller.shipAllCardsId === data.id
													}
													onChange={() => formik.setFieldValue(
														'seller.shipAllCardsId',
														data.id
													)}
													// value={data.referenceDataValue}
													// checked={shipCardValue === data.referenceDataId}
													// onChange={() => handleShipCards(data.referenceDataId)}
												/>
												<span className="ml-2">{data.value}</span>
											</label>
										</div>
									))}
							</div>
							<div className="sm:w-full md:w-full lg:w-full xl:w-full py-4">
								<label
									htmlFor="ship_cards"
									className="block text-sm font-medium text-gray-700 pl-2 required"
								>
									Shipping type
								</label>
								<div className="flex flex-row xxs:flex-col md:flex-row  justify-center ">
									{shippingTypes
										&& shippingTypes.length > 0
										&& shippingTypes.map((data, index) => (
											<div
												className="mt-2 sm:w-full md:w-full lg:w-full xl:w-1/2 pl-2"
												key={index}
											>
												<label className="inline-flex items-center">
													<input
														type="radio"
														className="form-radio"
														name="shippingTypes"
														checked={
															formik.values.seller.shippingTypeId === data.id
														}
														onChange={() => formik.setFieldValue(
															'seller.shippingTypeId',
															data.id
														)}
														// value={data.referenceDataValue}
														// checked={shipCardValue === data.referenceDataId}
														// onChange={() => handleShipCards(data.referenceDataId)}
													/>
													<span className="ml-2">{data.value}</span>
												</label>
											</div>
										))}
								</div>
							</div>

							<div className="col-span-1">
								{formik.values.seller.shippingTypeId === 139 && (
									<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full ">
										<label
											htmlFor="rate"
											className="block text-sm font-medium text-gray-700 pl-2 required"
										>
											Rate
										</label>
										<div className="mt-1 relative rounded-md shadow-sm">
											<CurrencyInput
												id="rate"
												className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
												name="rate"
												placeholder="Please enter a value"
												decimalsLimit={2}
												defaultValue={formik.values.seller.fixedShipRates}
												maxLength={10}
												allowNegativeValue={false}
												value={formik.values.seller.fixedShipRates}
												onValueChange={(value) => formik.setFieldValue('seller.fixedShipRates', value)}
												intlConfig={{
													locale: 'en-US',
													currency: 'GBP'
												}}
												prefix="$"
											/>
										</div>
										{/* {rateErr && (
											<p className="text-red-700">
																		This field is required
											</p>
										)} */}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="mb-3 sm:w-full md:w-full lg:w-full xl:w-full mt-3">
						<label
							htmlFor="shippingFrequency"
							className="block text-sm font-medium text-gray-700 pl-2 required"
						>
							Frequency of shipping
						</label>
						<div className="mt-1 relative rounded-md ">
							<TextArea
								name="seller.shippingFrequency"
								id="seller.shippingFrequency"
								height="full"
								mobileHeight="full"
								// className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none h-36"
								rows="4"
								// className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-32 sm:text-sm border border-gray-200 rounded-md"
								placeholder="Shipping frequency"
								value={formik.values.seller.shippingFrequency}
								isTouched={
									formik.touched
									&& formik.touched.seller
									&& formik.touched.seller.shippingFrequency
								}
								error={
									formik.touched
									&& formik.touched.seller
									&& formik.touched.seller.shippingFrequency
									&& formik.errors
									&& formik.errors.seller
									&& formik.errors.seller.shippingFrequency
								}
								required
								maxLength={100}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								// onBlur={(e) => setFrequencyShipping(e.target.value)}
								// onChange={(e) => handleFrequencyShipping(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div className="p-4 items-center justify-center">
					<h4>
						Please note that these shipping options are defaults that can be
						changed for individual rips when planning the event.
					</h4>
				</div>
			</div>
		</>
	);
};

export default SellerShippingInformation;
