import React from 'react';

const ShipmentSummarySkeletonItem = () => (
	<div className="flex w-full flex-1 flex-col items-center">
		<div className="w-full h-full animate-pulse flex-row items-center justify-center space-x-1">
			<div className="flex flex-col space-y-2">
				<div className="h-6 w-12/12 rounded-md bg-gray-300 " />
				<div className="h-6 w-5/12 rounded-md bg-gray-300 " />
				<div className="h-6 w-9/12 rounded-md bg-gray-300 " />
				<div className="h-6 w-9/12 rounded-md bg-gray-300 " />
				<div className="h-6 w-9/12 rounded-md bg-gray-300 " />
				<div className="h-6 w-12/12 rounded-md bg-gray-300 " />
			</div>
		</div>
	</div>
);

export default ShipmentSummarySkeletonItem;
