import axios from 'axios';
import axiosConfig, { getConfig, getConfigDefaultHeadersWithAdditionalHeaders, getConfigHeadersWithCheckoutSessionIdHeaders } from './config';
import { getAccessToken } from '../utils/auth';
import { CHECKOUT_SESSION_ID } from '../utils/constants';

export const authHeaders = () => ({ headers: { Authorization: `Bearer ${getAccessToken()}` } });

export const getProducts = async () => await axiosConfig.get('product/list');
export const getProductsPagination = async (page, perPage, searchValue = null, statusId = null) => {
	const config = getConfig();
	config.params = {
		page, per_page: perPage, searchValue, statusId
	};
	return await axios.get('product/list', config);
};
export const getProductsByStatus = async (page, perPage, searchValue = null, status) => {
	const config = getConfig();
	config.params = {
		page, per_page: perPage, searchValue
	};
	return await axios.get(`product/list/${status}`, config);
};
export const addProduct = async (product) => await axiosConfig.post('product/add', product);

export const getProduct = async (productId) => await axiosConfig.get(`product/${productId}`);
export const updateProductStatus = async (products, status) => await axiosConfig.put(`product/update/status/${status}`, products);
export const uploadProductImage = async (formData, productId, token) => {
	let headers = authHeaders();
	if (token) {
		headers = { headers: { Authorization: `Bearer ${token}` } };
	}

	headers['Content-Type'] = 'multipart/form-data';
	headers.redirect = 'follow';
	headers.mode = 'no-cors';
	return await axiosConfig.post(
		`product/image/${productId}`,
		formData,
		headers
	);
};
export const updateProduct = async (product) => await axiosConfig.put('product/update', product);
export const deleteProductImage = async (productId) => await axiosConfig.delete(`product/image/${productId}`);
export const hasAvailableProductsInStock = async () => await axiosConfig.get('product/has-available-stock');
export const importMultipleProducts = async (products) => await axiosConfig.post('product/import', products);
export const uploadProductsViaCsv = async (formData, token) => {
	let headers = authHeaders();
	if (token) {
		headers = { headers: { Authorization: `Bearer ${token}` } };
	}

	headers['Content-Type'] = 'multipart/form-data';
	headers.redirect = 'follow';
	headers.mode = 'no-cors';
	return await axiosConfig.post(
		'product/validate/csv',
		formData,
		headers
	);
};
export const reserveProduct = async (eventId, eventProductId, quantity, checkoutSessionId = null) => {
	const headers = [];
	if (checkoutSessionId) {
		headers.push({
			key: CHECKOUT_SESSION_ID,
			value: checkoutSessionId
		});
	}
	return await axios.post(
		`product/reserve/${eventId}/${eventProductId}/${quantity}`,
		null, getConfigDefaultHeadersWithAdditionalHeaders(headers)
	);
};
export const releaseProduct = async (eventId, eventProductId, checkoutSessionId = null) => {
	let config = null;
	if (checkoutSessionId) {
		config = getConfigHeadersWithCheckoutSessionIdHeaders(checkoutSessionId);
	} else {
		config = getConfig();
	}
	return await axios.post(`product/release/${eventId}/${eventProductId}`, null, config);
};
export const startProductPayment = async (eventId, eventProductIds) => await axiosConfig.post(
	`product/startpayment/${eventId}`,
	eventProductIds
);
export const updatePendingProducts = async (eventId) => await axiosConfig.put(`product/update-pending/${eventId}`, null);
export const getProductPath = async (productId) => await axiosConfig.get(`product/image/${productId}`);
export const getProductsQuantityByEventId = async (eventId) => await axiosConfig.get(`product/quantity/${eventId}`);
