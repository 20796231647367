import React, { useState, useEffect } from 'react';
import EllipisWithTooltip from '../ellipsis-tooltip';
import AvailableSlots from '../common/available-slots';
import { PICK_YOUR_SLOTS, RANDOMIZED, RIP_N_SHIP } from '../../utils/constants';
import { formatDate, getTimezoneName } from '../../utils/formatter';

const LinkedEventSliderItem = ({ onEventLinkClick, linkedEvent }) => {
	const [availableSlots, setAvailableSlots] = useState(0);
	const [totalSlots, setTotalSlots] = useState(0);
	const [eventTime, setEventTime] = useState(null);
	const [breakPrice, setBreakPrice] = useState(0);

	useEffect(() => {
		if (!linkedEvent) return;

		if (linkedEvent.event.eventTypeId == RIP_N_SHIP) {
			let { eventTime: time } = linkedEvent.event;

			time = `${time}.000Z`;
			time = new Date(time);

			const formated_date = time;
			const local_date = formatDate(formated_date, 'en');

			const time_format_options = {
				hour: 'numeric',
				minute: '2-digit'
			};
			const local_time = formated_date.toLocaleTimeString(
				'en-US',
				time_format_options
			);

			time = `${local_date} • ${local_time} (${getTimezoneName(time)})`;

			setEventTime(time);
		}

		if (linkedEvent.event.eventTypeId != RIP_N_SHIP) {
			let soldSlots = 0;
			let smallestSlotPrice = null;
			linkedEvent.breaks.forEach((b, i) => {
				if (i == 0 && linkedEvent.event.eventTypeId == RANDOMIZED) {
					setBreakPrice(b.slotPrice);
				} else if (linkedEvent.event.eventTypeId == PICK_YOUR_SLOTS) {
					if (smallestSlotPrice == null) {
						smallestSlotPrice = b.slotPrice;
					} else if (b.slotPrice < smallestSlotPrice) {
						smallestSlotPrice = b.slotPrice;
					}
				}
				if (b.slotStatusId === 49) soldSlots++;
			});

			if (linkedEvent.event.eventTypeId == PICK_YOUR_SLOTS) {
				setBreakPrice(smallestSlotPrice);
			}

			setAvailableSlots(linkedEvent.breaks.length - soldSlots);
			setTotalSlots(linkedEvent.breaks.length);
		}
	}, [linkedEvent]);

	return (
		<div
			className="flex flex-col text-black dark:text-white hover:bg-gray-100 dark:hover:bg-darkGray-200 p-2 cursor-pointer"
			onClick={() => onEventLinkClick(linkedEvent.event.eventId)}
			onKeyDown={() => onEventLinkClick(linkedEvent.event.eventId)}
		>
			<h4 className="md:w-48 xl:w-64">
				<EllipisWithTooltip
					isParagraph
					placement="bottom"
					className="text-sm"
					overridenStyle={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}
				>
					{availableSlots == 0 && linkedEvent.event.eventTypeId != RIP_N_SHIP
						? `(FULL) - ${linkedEvent.event.eventName}`
						: linkedEvent.event.eventName}
				</EllipisWithTooltip>
			</h4>
			<p className="text-gray-700 dark:text-white">
				{`Event Number #${linkedEvent.event.eventNumber}`}
			</p>
			{linkedEvent.event.eventTypeId == RANDOMIZED ? (
				<p>{`$${breakPrice}`}</p>
			) : null}
			{linkedEvent.event.eventTypeId == PICK_YOUR_SLOTS ? (
				<p>{`$${breakPrice}+`}</p>
			) : null}
			{linkedEvent.event.eventTypeId == RIP_N_SHIP ? <p>{eventTime}</p> : null}
			{linkedEvent.event.eventTypeId != RIP_N_SHIP && (
				<AvailableSlots
					availableSlotNumber={availableSlots}
					totalNumberOfSlots={totalSlots}
				/>
			)}
		</div>
	);
};

export default LinkedEventSliderItem;
