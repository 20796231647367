/* eslint-disable no-undef */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'firebase/database';
import React from 'react';
import { navigate } from 'gatsby';
import { isLoggedIn } from './src/utils/auth';
import { getUserRoles } from './src/api/auth.request';

const BUYER_PAGES = [
	'/view-profile',
	'/profile',
	'/orders',
	'/orders/',
	'/cancel',
	'/payment-error/',
	'/seller-register',
];

const SELLER_PAGES = [
	'/event',
	'/no-available-products/',
	'/no-available-products',
	'/product-register',
	'/sales',
	'/sales/',
	'/product',
	'/products',
	'/products/',
	'/product/',
	'/event/messages/'
];

const ADMIN_PAGES = [
	'/admin',
	'/admin/',
	'/admin/event/',
	'/seller-application',
	'/seller-application/',
];

const SHIPMENT_PAGES = [
	'/shipments',
	'/shipment/label',
	'/shipment/label/details',
	'/shipment/label/print',
];

require('./src/styles/global.css');
require('./src/styles/buttons.css');
require('./src/styles/inputs.css');
require('./src/styles/tabs.css');

export const onPreRouteUpdate = ({ location }) => {
	window.document.body.style.display = 'none';
	if (location.pathname == '/not-authorized') {
		window.document.body.style.display = 'block';
		return;
	}

	const locations = location.pathname.split('/');
	let hasMoreSubRoutes = false;
	if (locations.length > 2) {
		locations.splice(-1, 1);
		hasMoreSubRoutes = true;
	}
	let pathName = locations.join('/');

	if (hasMoreSubRoutes) {
		pathName += '/';
	}

	const onUnauthorized = () => navigate('/not-authorized');

	if (!BUYER_PAGES.some((e) => e === pathName) && !SELLER_PAGES.some((e) => e === pathName)
		&& !ADMIN_PAGES.some((e) => e === pathName) && !SHIPMENT_PAGES.some((e) => e === pathName)) {
		window.document.body.style.display = 'block';
		return;
	}

	const isUserLoggedIn = isLoggedIn();

	if (
		!isUserLoggedIn
	) {
		if (BUYER_PAGES.some((e) => e === pathName) || SELLER_PAGES.some((e) => e === pathName)
		|| ADMIN_PAGES.some((e) => e === pathName) || SHIPMENT_PAGES.some((e) => e === pathName)) {
			onUnauthorized();
		}
	} else if (isUserLoggedIn) {
		// This is for double check on server
		if (BUYER_PAGES.some((e) => e === pathName)) {
			window.document.body.style.display = 'block';
			return;
		}

		getUserRoles()
			.then((res) => {
				if (SELLER_PAGES.some((e) => e === pathName) && !res.data.isSeller) {
					onUnauthorized();
					window.document.body.style.display = 'block';
					return;
				}

				if (ADMIN_PAGES.some((e) => e === pathName) && !res.data.isAdmin) {
					onUnauthorized();
					window.document.body.style.display = 'block';
				}

				if (SHIPMENT_PAGES.some((e) => e === pathName) && !res.data.hasAccessToShipment) {
					onUnauthorized();
					window.document.body.style.display = 'block';
				}
			})
			.catch(() => {
				onUnauthorized();
			});
		window.document.body.style.display = 'block';
	} else {
		window.document.body.style.display = 'block';
	}
};

export const onClientEntry = () => {
	if (process.env.NODE_ENV !== 'production') {
		const whyDidYouRender = require('@welldone-software/why-did-you-render');
		whyDidYouRender(React, {
			trackAllPureComponents: false
		});
	}
};
export { default as wrapRootElement } from './src/state/redux-wrapper';
