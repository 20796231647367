/* eslint-disable no-useless-escape */
export const getUrlParametersByName = (paramNames, url) => {
	if (!paramNames || !url) return null;
	const resultSet = {};
	const params = paramNames.split('&');
	params.forEach((param) => {
		const parsedName = param.replace(/[\[\]]/g, '\\$&');
		const regex = new RegExp(`[?&]${parsedName}(=([^&#]*)|&|#|$)`);
		const results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		resultSet[param] = decodeURIComponent(results[2].replace(/\+/g, ' '));
	});
	return resultSet;
};

export const isUrl = (string) => {
	try { return Boolean(new URL(string)); } catch (e) { return false; }
};
