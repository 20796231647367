/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { XCircleIcon } from '@heroicons/react/outline';
import { isTablet } from 'react-device-detect';
import clsx from 'clsx';
import { isTextTruncated } from '../../../utils/element';

const ConversationEventName = ({
	eventName,
	closeEventName,
	nameInActiveLine
}) => {
	const [truncated, setTruncated] = useState(false);
	const nameRef = useRef();

	useEffect(() => {
		if (!nameRef || !nameRef.current) return;
		setTruncated(isTextTruncated(nameRef.current));
	}, [eventName]);

	return (
		<div className="sticky top-0 w-full h-10 flex px-4 pt-2">
			<div className="flex flex-1 items-start justify-center w-full">
				{truncated ? (
					// eslint-disable-next-line jsx-a11y/no-distracting-elements
					<marquee
						className={clsx(
							'text-grey-darkest text-md font-extrabold mt-1',
							isTablet ? 'w-full' : nameInActiveLine ? 'w-81' : 'w-72'
						)}
					>
						{nameInActiveLine && nameInActiveLine.length
							? `${eventName} - `
							: eventName}
						<span className="text-djawn">{nameInActiveLine}</span>
					</marquee>
				) : (
					<h4
						ref={nameRef}
						className={clsx(
							'truncate line-clamp-1',
							isTablet ? 'w-full' : nameInActiveLine ? 'w-81' : 'w-72',
							'text-center'
						)}
					>
						{nameInActiveLine && nameInActiveLine.length
							? `${eventName} - `
							: eventName}
						<span className="text-djawn">{nameInActiveLine}</span>
					</h4>
				)}
			</div>
			<button
				type="button"
				className="flex items-start"
				onClick={closeEventName}
			>
				<XCircleIcon className="w-6 h-6" />
			</button>
		</div>
	);
};

export default ConversationEventName;
