import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import {
	Tab, Tabs, TabList, TabPanel
} from 'react-tabs';
import BounceLoader from 'react-spinners/BounceLoader';
import Layout from '../../../components/layouts/layout-default';
import HeaderHome from '../../../components/common/header-home';
import {
	getFailedAttendees,
	getParticipantsFromTwilio,
	showRoomMonitoringTool,
	getUndetailedEvent,
	getTwilioMonitoringToolToken,
	generateTwilioMonitoringToolToken,
	removeUsersFromEvent,
	removeMonitoringToolTwilioToken
} from '../../../api/event.request';
import AdminEventInformation from '../../../components/admin/admin-event-information';
import AdminEventAttendees from '../../../components/admin/admin-event-attendees';
import TwilioRoomMonitoringToolModal from '../../../components/admin/twilio-room-monitoring-tool-modal';
import AdminFailedEventAttendees from '../../../components/admin/admin-failed-attendees';
import RemoveAttendeesConfirmationModal from '../../../components/admin/remove-attendees-confirmation-modal';
import { loadProfileByUserId } from '../../../api/auth.request';
import AdminShowUserInfoModal from '../../../components/admin/admin-user-info-modal';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const AdminEventPanelPage = ({ params }) => {
	const [loading, setLoading] = useState(true);
	const [twilioVideoToken, setTwilioVideoToken] = useState(null);
	const [activeTab, setActiveTab] = useState(0);
	const [event, setEvent] = useState(null);
	const [showMonitoringTool, setShowMonitoringTool] = useState(false);
	const [attendees, setAttendees] = useState([]);
	const [failedAttendees, setFailedAttendees] = useState([]);
	const [attendeesLoaded, setAttendeesLoaded] = useState(false);
	const [failedAttendeesLoaded, setFailedAttendeesLoaded] = useState(false);
	const [monitoringToolLoaded, setMonitoringToolLoaded] = useState(false);
	const [eventLoaded, setEventLoaded] = useState(false);
	const [showUserModal, setShowUserModal] = useState(false);
	const [openTwilioMonitoringTool, setOpenTwilioMonitoringTool] = useState(
		false
	);
	const [selectedUserIds, setSelectedUserIds] = useState([]);
	const [selectedAnonymousTokens, setSelectedAnonymousTokens] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);

	const [
		showRemoveAttendeesConfirmationModal,
		setShowRemoveAttendeesConfirmationModal
	] = useState(false);

	useEffect(() => {
		if (!selectedUser) return;
		setShowUserModal(true);
	}, [selectedUser]);

	const onUserClicked = (userId) => {
		loadProfileByUserId(userId).then((res) => {
			setSelectedUser(res.data);
		});
	};

	const onTwilioDataClicked = () => {
		if (twilioVideoToken) {
			setOpenTwilioMonitoringTool(true);
		}
		getTwilioMonitoringToolToken(params.eventId).then((res) => {
			if (res.data) {
				setTwilioVideoToken(res.data);
				setOpenTwilioMonitoringTool(true);
			} else {
				generateTwilioMonitoringToolToken(params.eventId).then(
					(generatedRes) => {
						setTwilioVideoToken(generatedRes.data);
						setOpenTwilioMonitoringTool(true);
					}
				);
			}
		});
	};

	const loadEventInfo = (eventId) => {
		showRoomMonitoringTool().then((res) => {
			setShowMonitoringTool(res.data);
			setMonitoringToolLoaded(true);
		});
		getParticipantsFromTwilio(eventId).then((res) => {
			setAttendees(res.data);
			setAttendeesLoaded(true);
		});
		getFailedAttendees(eventId).then((res) => {
			setFailedAttendees(res.data);
			setFailedAttendeesLoaded(true);
		});

		getUndetailedEvent(eventId).then((res) => {
			setEvent(res.data);
			setEventLoaded(true);
		});
	};

	useEffect(() => {
		if (!params.eventId) return;
		const { eventId } = params;
		loadEventInfo(eventId);
		getTwilioMonitoringToolToken(params.eventId).then((res) => {
			if (res.data) {
				setTwilioVideoToken(res.data);
			}
		});
	}, [params]);

	useEffect(() => {
		setLoading(
			!(
				attendeesLoaded
				&& failedAttendeesLoaded
				&& monitoringToolLoaded
				&& eventLoaded
			)
		);
	}, [
		attendeesLoaded,
		failedAttendeesLoaded,
		monitoringToolLoaded,
		eventLoaded
	]);

	const onRemoveUsers = () => {
		removeUsersFromEvent(
			params.eventId,
			selectedUserIds,
			selectedAnonymousTokens
		).then(() => {
			loadEventInfo(params.eventId);
			setShowRemoveAttendeesConfirmationModal(false);
			setSelectedUserIds([]);
			setSelectedAnonymousTokens([]);
		});
	};

	if (loading) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}
	return (
		<Layout auth>
			<div className="flex flex-col flex-1">
				<HeaderHome hideSearch setLoading={setLoading} />
				<div className="flex flex-col flex-grow w-full h-full mt-4 items-center">
					<ToastContainer />
					<RemoveAttendeesConfirmationModal
						showModal={showRemoveAttendeesConfirmationModal}
						setShowModal={setShowRemoveAttendeesConfirmationModal}
						onRemoveUsers={onRemoveUsers}
					/>
					{twilioVideoToken && (
						<TwilioRoomMonitoringToolModal
							open={openTwilioMonitoringTool}
							close={() => setOpenTwilioMonitoringTool(false)}
							token={twilioVideoToken}
						/>
					)}
					{selectedUser && (
						<AdminShowUserInfoModal
							user={selectedUser}
							showModal={showUserModal}
							setShowModal={setShowUserModal}
						/>
					)}
					<div className="flex flex-col bg-white w-80 md:w-5/6 rounded-lg content-center justify-center">
						<div className="w-full justify-center pb-8 shadow-lg">
							<div className="flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-between lg:items-stretch md:w-full w-80">
								<div className="w-full flex flex-col md:flex-row  justify-start">
									<div className="relative text-gray-600 mb-2 md:mb-0 w-full md:w-auto">
										<p>{event.eventName}</p>
									</div>
								</div>
								<div className="w-full flex flex-col md:flex-row justify-end">
									{(selectedUserIds.length > 0
										|| selectedAnonymousTokens.length > 0) && (
										<button
											type="button"
											onClick={() => setShowRemoveAttendeesConfirmationModal(true)}
											className="rounded-lg px-3 py-2 bg-djawn text-white cursor-pointer hover:bg-djawn-300 text-center md:h-auto h-10 flex items-center mr-5"
										>
											Remove selected users
										</button>
									)}
									<Link to="/admin">
										<div className="rounded-lg px-3 py-2 bg-djawn text-white cursor-pointer hover:bg-djawn-300 text-center md:h-auto h-10 flex items-center">
											<span className="rounded-xl bg-black mr-2">
												<svg
													className="w-6 h-6"
													fill="none"
													stroke="currentColor"
													viewBox="0 0 24 24"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="3"
														d="M15 19l-7-7 7-7"
													/>
												</svg>
											</span>
											Back to Live events
										</div>
									</Link>
								</div>
							</div>
							<div className="grid w-full xl:overflow-x-hidden px-5 rounded-md">
								<div className="mx-5">
									<Tabs
										className="md:w-full w-64"
										onSelect={(e) => setActiveTab(e)}
										selectedIndex={activeTab}
										selectedTabClassName="selected-tab"
									>
										<TabList className="cursor-pointer flex mb-0 list-none flex-wrap bg-gray-200 flex-row md:w-112 w-64 border rounded-lg">
											<Tab className="-mb-px last:mr-0 flex-auto pt-3 pb-4 text-center">
												Event Information
											</Tab>
											<Tab className="-mb-px last:mr-0 flex-auto pt-3 pb-4 text-center">
												Attendees List
											</Tab>
											<Tab className="flex-auto pt-3 pb-4 text-center">
												Failed Attendees
											</Tab>
										</TabList>
										<div className="border rounded-md px-2 py-2">
											<TabPanel className="tab-content tab-space">
												<AdminEventInformation
													event={event}
													onTwilioDataClicked={onTwilioDataClicked}
													showMonitoringTool={showMonitoringTool}
													showTwilioRemoveToken={twilioVideoToken != null}
													onTwilioRemoveTokenClicked={() => removeMonitoringToolTwilioToken(
														params.eventId
													).then(() => {
														setTwilioVideoToken(null);
													})}
												/>
											</TabPanel>
											<TabPanel className="tab-content tab-space">
												{event && (
													<AdminEventAttendees
														attendees={attendees}
														sellerNickname={event.seller.nickName}
														setSelectedUserIds={setSelectedUserIds}
														onUserClicked={onUserClicked}
														setSelectedAnonymousTokens={
															setSelectedAnonymousTokens
														}
													/>
												)}
											</TabPanel>
											<TabPanel className="tab-content tab-space">
												<AdminFailedEventAttendees
													failedAttendees={failedAttendees}
												/>
											</TabPanel>
										</div>
									</Tabs>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default AdminEventPanelPage;
