import React from 'react';
import clsx from 'clsx';

const LinkedEventsMobileSliderButton = ({
	showLinkedEventsSliderInMobileChatContainer,
	setShowLinkedEventsSliderInMobileChatContainer
}) => (
	<button
		type="button"
		className={clsx(
			'border-2 rounded-full md:h-10 h-8 md:w-10 w-8 flex items-center justify-center disabled:opacity-50 focus:outline-none border-djawn font-semibold',
			showLinkedEventsSliderInMobileChatContainer
				? 'bg-djawn text-white'
				: 'bg-none text-djawn'
		)}
		style={{ zIndex: '30', fontSize: '9px', letterSpacing: '-0.02rem' }}
		onClick={() => setShowLinkedEventsSliderInMobileChatContainer(
			!showLinkedEventsSliderInMobileChatContainer
		)}
	>
		Breaks
	</button>
);

export default LinkedEventsMobileSliderButton;
