import { navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useFormik } from 'formik';
import { StringParam, useQueryParam } from 'use-query-params';
import { ClipLoader } from 'react-spinners';
import clsx from 'clsx';
import Layout from '../components/layouts/layout-default';
import Header from '../components/header';
import { saveLogin } from '../utils/auth';
import {
	uploadProfileImage,
	userHasPendingEmailConfirmation
} from '../api/auth.request';
import useAuthentication from '../hooks/useAuthentication';
import PersonalInformation from '../containers/auth/personal-information';
import Button from '../components/button';
import Card from '../components/Card';
import PhoneInformation from '../containers/auth/phone-information';
import { getInvalidSignupPasswordError } from '../utils/passwordRules';
import { ProfileProvider } from '../contexts/profile';
import {
	APPLE,
	CUSTOM,
	FACEBOOK,
	GOOGLE
} from '../utils/constants/registration-type';
import useReferenceDataValue from '../hooks/useReferenceDataValue';
import { REQUIRED_PHONE_NUMBER_REFERENCE_DATA } from '../utils/constants/reference-data';
import { validateEmail } from '../utils/commonFunction';
import VerifyEmailModal from '../containers/auth/verify-email-modal';

const RegisterPage = ({ location }) => {
	const [emailParam] = useQueryParam('email', StringParam);
	const [emailTokenParam] = useQueryParam('token', StringParam);
	const [idParam] = useQueryParam('id', StringParam);

	const [
		redirectToSellerApplication,
		setRedirectToSellerApplication
	] = useState(false);

	const { value: requiredPhoneNumber } = useReferenceDataValue(
		REQUIRED_PHONE_NUMBER_REFERENCE_DATA,
		'boolean'
	);

	const { authenticate } = useAuthentication(false);

	const formik = useFormik({
		initialValues: {
			userId: '',
			firstName: '',
			lastName: '',
			password: '',
			confirmPassword: '',
			blobImage: null,
			emailAddress: '',
			emailAddressToVerify: '',
			verifiedEmailAddress: '',
			requiresEmailVerification: false,
			isEmailAddressVerified: true,
			profilePic: '',
			accessToken: '',
			city: '',
			state: '',
			postal: '',
			registrationTypeId: CUSTOM,
			address: '',
			address2: '',
			addressHasStreetNumber: false,
			nickname: '',
			provider: 'facebook',
			imageFiles: [],
			validPhoneNumber: false,
			verifiedPhoneNumber: '',
			phoneNumber: '',
			isPhoneNumberVerified: true,
			twoFactorAuthentication: false,
			phoneNumberToken: '',
			code: '',
			idToken: '',
			codeVerificationToken: ''
		},
		validate: ({
			firstName,
			lastName,
			nickname,
			emailAddress,
			blobImage,
			profilePic,
			imageFiles,
			password,
			confirmPassword,
			provider,
			addressHasStreetNumber,
			city,
			state,
			postal,
			validPhoneNumber,
			phoneNumber,
			verifiedPhoneNumber,
			verifiedEmailAddress,
			isEmailAddressVerified,
			requiresEmailVerification
		}) => {
			const errors = {
				firstName: '',
				lastName: '',
				nickname: '',
				image: '',
				emailAddress: ''
			};
			const requiredMessage = 'This field is required';

			if (!firstName || !firstName.length) {
				errors.firstName = requiredMessage;
			}
			if (!lastName || !lastName.length) {
				errors.lastName = requiredMessage;
			}
			if (!nickname || !nickname.length) {
				errors.nickname = requiredMessage;
			}

			if (!blobImage && !profilePic && !imageFiles.length) {
				errors.image = 'Please upload a profile picture';
			}

			if (provider === 'custom') {
				if (!password || !password.length) {
					errors.password = requiredMessage;
				} else if (password.length < 8) {
					errors.password = 'Password must be at least 8 characters';
				} else {
					errors.password = getInvalidSignupPasswordError(password);
				}

				if (!confirmPassword || !confirmPassword.length) {
					errors.confirmPassword = requiredMessage;
				} else if (password !== confirmPassword) {
					errors.confirmPassword = 'Passwords do not match';
				}
			}

			if (!city || !state || !postal) {
				errors.address = 'Please enter a valid street address';
			} else if (!addressHasStreetNumber) {
				errors.address = 'Please enter an address with a street number';
			}

			if (requiredPhoneNumber) {
				if (!phoneNumber || !phoneNumber.length) {
					errors.phoneNumber = requiredMessage;
				}
			} else if (verifiedPhoneNumber != phoneNumber && validPhoneNumber) {
				errors.phoneNumber = 'Please verify your phone number';
			}

			if (requiresEmailVerification) {
				if (!emailAddress || !emailAddress.length) {
					errors.emailAddress = requiredMessage;
				} else {
					const validEmail = validateEmail(emailAddress);
					if (!validEmail) {
						errors.emailAddress = 'Please enter a valid email address';
					} else if (
						verifiedEmailAddress != emailAddress
						&& !isEmailAddressVerified
					) {
						errors.emailAddress = 'Please verify your email address';
					}
				}
			}

			const allFieldsEmpty = Object.values(errors).every(
				(value) => value == ''
			);

			if (allFieldsEmpty) return {};

			return errors;
		},
		onSubmit: async ({
			firstName,
			lastName,
			blobImage,
			address,
			address2,
			city,
			state,
			postal,
			nickname,
			provider,
			imageFiles,
			password,
			emailAddress,
			confirmPassword,
			accessToken,
			phoneNumberToken,
			code,
			idToken,
			codeVerificationToken,
			twoFactorAuthentication
		}) => {
			const userData = {
				mailingAddress1: address,
				mailingAddress2: address2,
				mailingCity: city,
				mailingState: state,
				mailingZip: postal,
				accountType: 'Individual',
				legalEntityName: `${firstName} ${lastName}`,
				nickName: nickname,
				firstName,
				lastName,
				accessToken,
				isSignUpInitiated: true,
				email: emailAddress,
				phoneNumberToken,
				code,
				idToken,
				codeVerificationToken,
				twoFactorAuthenticationEnabled: twoFactorAuthentication
			};

			if (emailTokenParam) {
				userData.emailConfirmationToken = emailTokenParam;
			}

			if (password && confirmPassword) {
				userData.password = password;
				userData.confirmPassword = confirmPassword;
			}

			const user = await authenticate(
				userData,
				provider,
				'',
				() => {},
				false,
				true
			);

			if (!user) return;

			const formData = new FormData();
			if (imageFiles && imageFiles.length > 0) {
				formData.append('image', imageFiles[0]);
			} else {
				formData.append('image', blobImage);
			}

			let image = null;

			try {
				const imageResponse = await uploadProfileImage(
					formData,
					user.data.token
				);
				image = imageResponse.data.imageUrl;

				saveLogin({
					name: user.data.name,
					id: user.data.id,
					email: user.data.email,
					token: user.data.token,
					refreshToken: user.data.refreshToken,
					userDetails: user.data.userDetails,
					expirationDate: user.data.expirationDate,
					firstName: user.data.firstName,
					lastName: user.data.lastName,
					image
				});
			} catch (err) {
				console.error(err);
			}

			if (redirectToSellerApplication) {
				navigate('/seller-register');
				return;
			}
			navigate('/events');
		}
	});

	useEffect(() => {
		const customProviderData = [emailParam, idParam, emailTokenParam];
		if (customProviderData && customProviderData.length) {
			if (!customProviderData.every((data) => !data || !data.length)) {
				if (!customProviderData.every((data) => data && data.length)) {
					navigate('/events');
				} else {
					formik.setValues({
						...formik.values,
						emailAddress: emailParam,
						provider: 'custom',
						userId: idParam
					});

					const redirectToHomePageIfUserDoesntHavePendingEmailConfirmation = async () => {
						try {
							await userHasPendingEmailConfirmation(
								emailParam,
								emailTokenParam
							);
						} catch (err) {
							navigate('/events');
						}
					};

					redirectToHomePageIfUserDoesntHavePendingEmailConfirmation();
				}
			}
		}
	}, [emailParam, emailTokenParam, idParam]);

	useEffect(() => {
		if (!location || !location.state) return;
		const { externalProviderResponse } = location.state;

		if (externalProviderResponse) {
			let provider = 'apple';
			if (externalProviderResponse.registrationType == FACEBOOK) {
				provider = 'facebook';
			} else if (externalProviderResponse.registrationType == GOOGLE) {
				provider = 'google';
			} else if (externalProviderResponse.registrationType == APPLE) {
				provider = 'apple';
			}

			const values = {
				...formik.values,
				emailAddress: externalProviderResponse.email,
				firstName: externalProviderResponse.firstName,
				lastName: externalProviderResponse.lastName,
				profilePic: externalProviderResponse.pictureUrl,
				registrationTypeId: externalProviderResponse.registrationType,
				externalId: externalProviderResponse.externalId,
				accessToken: externalProviderResponse.accessToken,
				provider
			};

			if (!values.emailAddress || !values.emailAddress.length) {
				values.requiresEmailVerification = true;
				values.isEmailAddressVerified = false;
			}

			if (provider == 'apple') {
				values.code = externalProviderResponse.code;
				values.codeVerificationToken =					externalProviderResponse.codeVerificationToken;
				values.idToken = externalProviderResponse.idToken;
			}

			formik.setValues(values);

			if (externalProviderResponse.pictureUrl) {
				fetch(externalProviderResponse.pictureUrl)
					.then((res) => res.blob())
					.then((blobImage) => {
						formik.setFieldValue('blobImage', blobImage);
					})
					.catch((err) => {
						console.error(err);
					});
			}
		}
		if (
			location.state.redirectToSellerApplication
			&& location.state.redirectToSellerApplication === true
		) {
			setRedirectToSellerApplication(true);
		}
	}, [location]);

	return (
		<Layout auth>
			<div className="flex flex-col flex-1">
				<Header auth />
				<div className="flex flex-col flex-1 justify-center content-center">
					<ToastContainer />
					<VerifyEmailModal
						showModal={
							formik.values.emailAddressToVerify != null
							&& formik.values.emailAddressToVerify.length > 0
						}
						setShowModal={(status) => {
							if (!status) {
								formik.setFieldValue('emailAddressToVerify', '');
							}
						}}
						email={formik.values.emailAddressToVerify}
						onVerified={() => {
							const emailAddress = formik.values.emailAddressToVerify;
							formik.setValues({
								...formik.values,
								isEmailAddressVerified: true,
								verifiedEmailAddress: emailAddress,
								emailAddressToVerify: ''
							});
						}}
					/>
					<div className="px-4">
						<Card
							className="w-full h-auto py-4 md:px-12 mx-auto"
							transition
							transitionTimeout={100}
						>
							<ProfileProvider
								formik={formik}
								showCreatePasswordFields={
									formik.values.registrationTypeId == CUSTOM
								}
								isSignup
							>
								<div className="flex flex-col md:flex-row pb-4">
									<div className="w-full md:w-1/2">
										<PersonalInformation />
									</div>
									<div className="pt-2 md:pt-0 w-full md:w-1/2 border-l-0 md:border-l border-t md:border-t-0">
										<PhoneInformation />
									</div>
								</div>
								<div className="flex w-full justify-center">
									<Button
										onClick={formik.handleSubmit}
										width="1/2"
										height="10"
										textSize="lg"
										mobileHeight="10"
										disabled={formik.isSubmitting}
										mobileWidth="full"
										additionalClassName={clsx(
											formik.isSubmitting && 'flex justify-center items-center'
										)}
									>
										{formik.isSubmitting && (
											<ClipLoader color="white" size={20} />
										)}
										<span className={clsx(formik.isSubmitting && 'pl-1')}>
											SAVE
										</span>
									</Button>
								</div>
							</ProfileProvider>
						</Card>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default RegisterPage;
