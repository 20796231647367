import clsx from 'clsx';
import React from 'react';
import { checkboxTypes } from './types';

const CheckboxInput = ({
	checked,
	onChange,
	className,
	children,
	type = 'secondary',
	disabled = false
}) => {
	const checkboxType = checkboxTypes[type];

	return (
		<label className="pb-1">
			<input
				type="checkbox"
				className="checkbox-input"
				onChange={() => {
					onChange();
				}}
				value={checked}
				hidden
				disabled={disabled}
			/>
			<svg
				className={clsx(
					'checkbox rounded-md',
					checked === true
						? `checkbox-checked border border-${checkboxType.checkedColor} bg-${checkboxType.checkedColor} dark:border-${checkboxType.darkCheckedColor} dark:bg-${checkboxType.darkCheckedColor}`
						: `bg-${checkboxType.defaultColor} dark:bg-${checkboxType.defaultDarkColor} border-2 border-${checkboxType.defaultBorderColor} dark:border-${checkboxType.defaultDarkBorderColor}`,
					className
				)}
				// This element is purely decorative so
				// we hide it for screen readers
				aria-hidden="true"
				viewBox="0 0 15 11"
				fill="none"
			>
				<path
					d="M1 4.5L5 9L14 1"
					strokeWidth="2"
					stroke={checked ? '#fff' : 'none'}
				/>
			</svg>
			{children && children}
		</label>
	);
};

export default CheckboxInput;
