/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, {
	useState,
	useRef,
	useEffect,
	useCallback,
	useContext
} from 'react';
import moment from 'moment';
import linkifyHtml from 'linkifyjs/html';
import { UserIcon } from '@heroicons/react/solid';
import firebase from 'gatsby-plugin-firebase';
import ConversationTypeMessage from '../conversation-type-message';
import EllipsisWithTooltip from '../../ellipsis-tooltip';
import {
	PURCHASED_PRODUCTS_HYPERLINK_TYPE,
	RANDOMIZATION_HYPERLINK_TYPE
} from '../../../utils/conversation-hyperlink-types';
import { MICROPHONE_REQUEST_DECISION } from '../../../utils/conversation-action-types';
import SellerMicDecisionMessage from '../../../containers/event/seller-mic-decision-message';
import { MAXIMUM_NICKNAME_CHARACTER_MESSAGE_BEFORE_TRUNCATE } from '../../../utils/constants';
import { LiveEventViewsContainerContext } from '../../../contexts/live-event-views-container';

const ConversationMessage = ({
	message,
	isHost,
	isSeller,
	selectedForReply,
	onAttachmentDownloaded,
	onReply,
	authenticatedUserId,
	mobileSize,
	disableMessaging,
	reduceReplyMessageSize,
	truncateName,
	disableReplying,
	authenticated,
	onPurchasedItemsHyperlinkClicked,
	onRandomizationHyperlinkClicked,
	removeDayDivider,
	setReactions,
	setShowReactions,
	setShowMessageOptions,
	setSelectedForEdit,
	selectedForEdit,
	setSelectedMessage
}) => {
	const [showReplyButton, setShowReplyButton] = useState(false);
	const [onEditingMode, setOnEditingMode] = useState(false);
	const editingRef = useRef();
	const liveEventViewsContainerContext = useContext(
		LiveEventViewsContainerContext
	);

	useEffect(() => {
		if (!selectedForEdit) {
			setOnEditingMode(false);
			return;
		}
		if (selectedForEdit.id == message.id) setOnEditingMode(true);
		if (!editingRef || !editingRef.current) return;
		editingRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
			inline: 'center'
		});
	}, [selectedForEdit]);

	const processOnMessageClickBasedOnHyperlinkType = (
		hyperlinkType = '',
		hyperlinkEl
	) => {
		switch (hyperlinkType) {
			case PURCHASED_PRODUCTS_HYPERLINK_TYPE:
				if (!onPurchasedItemsHyperlinkClicked) return;
				if (
					hyperlinkEl.attributes
					&& hyperlinkEl.attributes['purchased-item-user-id']
				) {
					onPurchasedItemsHyperlinkClicked(
						hyperlinkEl.attributes['purchased-item-user-id'].value
					);
				}
				break;
			case RANDOMIZATION_HYPERLINK_TYPE:
				if (!onRandomizationHyperlinkClicked) return;
				let childWithAttribute = null;
				if (
					hyperlinkEl
					&& hyperlinkEl.children
					&& hyperlinkEl.children.length > 0
				) {
					childWithAttribute = Array.from(hyperlinkEl.children).find(
						(el) => el.attributes['break-event-id'] != null
					);
				}
				if (childWithAttribute) {
					if (childWithAttribute.attributes['open-randomization-view']) {
						onRandomizationHyperlinkClicked(
							childWithAttribute.attributes['break-event-id'].value,
							true
						);
					} else {
						onRandomizationHyperlinkClicked(
							childWithAttribute.attributes['break-event-id'].value,
							false
						);
					}
				} else {
					onRandomizationHyperlinkClicked(null, true);
				}
				break;
			default:
				console.warn(`On click hyperlink type was not found: ${hyperlinkType}`);
				break;
		}
	};

	const onMessageClicked = useCallback(
		(e) => {
			if (disableMessaging) return;
			if (!message || !message.has_hyperlink) {
				if (message && mobileSize) {
					setSelectedMessage(message);
					setShowMessageOptions(true);
				}
				return;
			}
			const el = e.target.closest('div');
			if (el && e.currentTarget.contains(el)) {
				// if user contains user-id attribute we know the user has purchased items
				processOnMessageClickBasedOnHyperlinkType(message.hyper_link_type, el);
			}
		},
		[disableMessaging, message, mobileSize]
	);

	/* eslint-disable no-mixed-spaces-and-tabs */
	const messageHtml = `
				<div class="${
	!reduceReplyMessageSize
		? 'md:p-1 space-y-4 ml-1'
		: `md:w-64 ${
			message.is_html_table ? 'pl-2 w-64' : 'pl-3 w-56'
						  } pb-2 ${
			!mobileSize
								&& liveEventViewsContainerContext
								&& liveEventViewsContainerContext.openQueueContainer
				? liveEventViewsContainerContext.width <= 720
					? 'lg:w-64'
					: 'lg:w-48'
				: 'lg:w-full'
						  }`
} text-xs lg:text-sm text-left md:text-left overflow-x-hidden conversation-message" style="word-break: break-word;">${
	message.message
}</div>`;

	return (
		<>
			<div
				className={`flex w-full bg-white dark:bg-darkGray-50 justify-items-start ${
					reduceReplyMessageSize ? '' : 'p-1'
				} ${
					!onEditingMode
					&& message.reactions
					&& (!removeDayDivider ? 'mb-2' : 'mb-7')
				} items-center ${onEditingMode && 'border rounded-2xl'}`}
				style={{
					backgroundColor: onEditingMode && '#E29890',
					maxWidth:
						mobileSize
						|| (message.name
							&& message.name.length
								< MAXIMUM_NICKNAME_CHARACTER_MESSAGE_BEFORE_TRUNCATE)
							? '95%'
							: '81.5%'
				}}
				onMouseOver={() => {
					if (mobileSize) return;
					if (!disableReplying) {
						setShowReplyButton(true);
					}
				}}
				onFocus={() => {}}
				onMouseEnter={() => {
					if (mobileSize) return;
					if (!disableReplying) {
						setShowReplyButton(true);
					}
				}}
				onMouseLeave={() => {
					if (mobileSize) return;
					if (!disableReplying) {
						if (mobileSize) return;
						setShowReplyButton(false);
					}
				}}
			>
				{message.image ? (
					<img
						src={message.image}
						alt="Profile"
						className={`rounded-full ${
							reduceReplyMessageSize ? 'w-8 h-8 mr-1' : 'w-10 h-10 mr-3'
						} object-cover`}
					/>
				) : (
					<div
						className={`rounded-full ${
							reduceReplyMessageSize ? 'w-8 h-8 mr-1' : 'w-10 h-10 mr-3'
						} flex justify-center items-center border-2`}
					>
						<UserIcon className="w-6 h-6" />
					</div>
				)}

				<div
					className={`${!!isHost && 'border-2 border-djawn'} flex flex-1 ${
						reduceReplyMessageSize ? '' : 'px-3 pt-1'
					} flex-col ${
						onEditingMode
							? 'w-full'
							: 'bg-gray-100 dark:bg-darkGray-100 dark:text-white'
					}`}
					style={
						onEditingMode
							? { maxWidth: '99%' }
							: { maxWidth: '99%', borderRadius: '2.5rem' }
					}
				>
					<div
						className={`flex items-center mt-1 ml-1 w-full ${
							reduceReplyMessageSize ? 'py-1 px-2' : ''
						}`}
						onClick={() => {
							if (mobileSize) {
								setSelectedMessage(message);
								setShowMessageOptions(true);
							}
						}}
					>
						<span
							className={`font-semibold ${
								truncateName ? 'text-xs' : 'text-sm'
							} pr-1 font-sans truncate conversation-message-user-name w-40 md:w-full lg:w-full`}
							style={
								truncateName ? { maxWidth: mobileSize ? '10rem' : '18rem' } : {}
							}
						>
							{truncateName ? (
								<EllipsisWithTooltip
									isParagraph
									placement="bottom"
									className="text-xs"
									overridenStyle={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis'
									}}
								>
									{message.name}
								</EllipsisWithTooltip>
							) : (
								<span className="text-xs" data-tip={message.fullName}>
									{message.name}
								</span>
							)}
						</span>
						<div className="rounded-full h-1.5 w-1.5 bg-djawn flex items-center justify-center mr-1" />
						<span
							className={`text-grey ${
								!onReply && 'text-xs'
							} font-light whitespace-nowrap`}
							style={onReply ? { fontSize: '0.70rem' } : {}}
						>
							{moment(message.sent_date).toDate().toLocaleTimeString()}
						</span>
						{message.edited && (
							<div className="ml-2">
								<svg
									className="w-4 h-4"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"
									/>
								</svg>
							</div>
						)}
					</div>

					{onEditingMode ? (
						<div className="md:p-1 text-left md:text-left space-y-4 break-all overflow-x-hidden">
							<div className="flex flex-col w-full">
								<div className="flex" ref={editingRef}>
									<ConversationTypeMessage
										onEditingMessage
										messageToEdit={message}
										mobileSize={mobileSize}
										toolbarId="crrr"
										authenticated={authenticated}
										setSelectedForEdit={setSelectedForEdit}
									/>
								</div>
								<div className="flex">
									<div className="w-1/6" style={{ zIndex: '30' }}>
										<button
											type="button"
											className={`
												mt-5
												${
						!mobileSize
							? 'cancel-editing-button  -mr-10 mt-5'
							: 'mobile-leave-button mt-3'
						}`}
											style={{ zIndex: '30' }}
											onClick={() => {
												setOnEditingMode(false);
											}}
										>
											Cancel
										</button>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="flex flex-col">
							<div
								onClick={onMessageClicked}
								dangerouslySetInnerHTML={{
									__html: linkifyHtml(messageHtml, {
										target: '_blank',
										className: 'link-message-text ml-1',
										ignoreTags: ['em']
									})
								}}
							/>
							{isSeller
								&& !disableMessaging
								&& message
								&& message.action_type
								&& message.action_type === MICROPHONE_REQUEST_DECISION && (
								<SellerMicDecisionMessage message={message} />
							)}
						</div>
					)}

					{!onEditingMode
						&& message.attachments
						&& message.attachments.length > 0 && (
						<div className="md:p-1 text-left md:text-left space-y-4 break-all overflow-x-hidden">
							<div className="flex p-1 flex-wrap">
								{message.attachments.map((object, i) => (
									<div className="flex pt-2 col-span-0" key={i}>
										<div className="md:p-1 text-left md:text-left space-y-4 break-all overflow-x-hidden">
											<div className="flex flex-none items-center border rounded-2xl">
												<div className="flex items-center justify-between text-center w-full">
													<span className="text-md mr-1 p-1 font-sans w-full pl-5">
														{object.display_file_name}
													</span>
													<button
														type="button"
														className="focus:outline-none text-black hover:text-black text-lg p-3 rounded-md rounded-tr-none rounded-br-none"
														onClick={() => onAttachmentDownloaded(
															object.generated_file_name,
															object.display_file_name
														)}
													>
														<svg
															className="w-5 h-4"
															fill="none"
															stroke="currentColor"
															viewBox="0 0 24 24"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth="2"
																d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
															/>
														</svg>
													</button>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					)}

					{message.reactions && message.reactions.length && (
						<div className="bg-djawn-300">
							<div className="relative">
								<button
									type="button"
									className="focus:outline-none items-center border-2 border-djawn text-black hover:text-black text-lg rounded-md rounded-tr-none rounded-br-none -mr-10 cursor-pointer absolute left-0 dark:bg-darkGray-100 dark:text-white"
									style={{
										borderRadius: '3rem'
									}}
									onClick={() => {
										setReactions(message.reactions);
										setShowReactions(true);
									}}
								>
									<div className="flex flex-row">
										<div className="h-2 mt-0.5 ml-2 mb-2">
											<span className="text-sm font-semibold block">
												{message.reactions.length}
											</span>
										</div>
										<div className="relative">
											<svg
												className="w-4 h-4 m-1 thumbs-up-icon"
												// fill="black"
												viewBox="0 0 20 20"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
											</svg>
										</div>
									</div>
								</button>
							</div>
						</div>
					)}
				</div>

				{!onReply && !onEditingMode && (
					<div className="flex flex-row">
						<div
							className="md:block ml-3 mb-auto mt-auto items-center"
							style={{
								display: showReplyButton && !disableMessaging ? 'block' : 'none'
							}}
						>
							<button
								type="button"
								className="focus:outline-none items-center text-black hover:text-black text-lg rounded-md rounded-tr-none rounded-br-none -mr-3 cursor-pointer"
								onClick={() => {
									selectedForReply(message);
								}}
							>
								<div className="relative">
									<svg
										className="w-5 h-5"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
										/>
									</svg>
								</div>
							</button>
						</div>
						<div
							className="md:block ml-5 mb-auto mt-auto items-center"
							style={{
								display:
									showReplyButton
									&& authenticatedUserId == message.user_id
									&& !disableMessaging
										? 'block'
										: 'none'
							}}
						>
							<button
								type="button"
								className="focus:outline-none items-center text-black hover:text-black text-lg rounded-md rounded-tr-none rounded-br-none -mr-3 cursor-pointer"
								onClick={() => {
									setSelectedForEdit(message);
								}}
							>
								<div className="relative">
									<svg
										className="w-5 h-5"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
										/>
									</svg>
								</div>
							</button>
						</div>
						{authenticatedUserId && (
							<div
								className="md:block ml-5 mb-auto mt-auto items-center"
								style={{
									display: !disableMessaging ? 'block' : 'none'
								}}
							>
								<button
									type="button"
									className="focus:outline-none items-center text-black hover:text-black text-lg rounded-md rounded-tr-none rounded-br-none -mr-3 cursor-pointer"
									onClick={() => {
										let reactions = [];

										if (message.reactions && message.reactions.length) {
											reactions = message.reactions;
										}

										if (
											reactions
											&& reactions.length
											&& reactions.some((v) => v.user_id == authenticatedUserId)
										) {
											reactions = message.reactions.filter(
												(r) => r.user_id != authenticatedUserId
											);
										} else {
											reactions.push({
												type: 'like',
												user_id: authenticatedUserId
											});
										}

										firebase
											.database()
											.ref('/message')
											.child(message.id)
											.update({
												reactions
											})
											.then(() => {
												// setReload(!reload);
											});
									}}
								>
									<div className="relative">
										<svg
											className="w-6 h-6"
											fill={
												message.reactions
												&& message.reactions.length
												&& message.reactions.some(
													(v) => v.user_id == authenticatedUserId
												)
													? '#e82e2c'
													: 'gray'
											}
											borderRadius="1"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
										</svg>
									</div>
								</button>
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default ConversationMessage;
