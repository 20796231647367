/* eslint-disable import/no-named-default */
import React from 'react';
import { default as ReactDataTable } from 'react-data-table-component';
import { DEFAULT_STYLES } from './DEFAULT_STYLES';

const DataTable = ({
	columns,
	data,
	noContextMenu,
	pagination = false,
	className,
	onRowClicked
}) => (
	<ReactDataTable
		noContextMenu={noContextMenu}
		columns={columns}
		data={data}
		onRowClicked={onRowClicked}
		pagination={pagination}
		className={className}
		contextActions={null}
		striped
		paginationComponent={null}
		customStyles={DEFAULT_STYLES}
	/>
);

export default DataTable;
