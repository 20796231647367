/* This array holds all the possible reference data status of Order Statuses and Order Detail Statuses */

export const referenceDataStatuses = [{
	referenceDataId: 281,
	text: 'Completed',
	badgeColor: 'bg-green-100',
	textColor: 'text-green-700'
}, {
	referenceDataId: 282,
	text: 'Pending Refund',
	badgeColor: 'bg-red-100',
	textColor: 'text-red-700'
}, {
	referenceDataId: 283,
	text: 'Refunded',
	badgeColor: 'bg-gray-300',
	textColor: 'text-gray-700'
}, {
	referenceDataId: 284,
	text: 'Partially Refund',
	badgeColor: 'bg-gray-300',
	textColor: 'text-gray-700'
}, {
	referenceDataId: 285,
	text: 'Refund denied',
	badgeColor: 'bg-red-100',
	textColor: 'text-red-700'
}, {
	referenceDataId: 286,
	text: 'Completed',
	badgeColor: 'bg-green-100',
	textColor: 'text-green-700'
}, {
	referenceDataId: 287,
	text: 'Pending Refund',
	badgeColor: 'bg-red-100',
	textColor: 'text-red-700'
}, {
	referenceDataId: 289,
	text: 'Refund denied',
	badgeColor: 'bg-red-100',
	textColor: 'text-red-700'
}, {
	referenceDataId: 288,
	text: 'Refunded',
	badgeColor: 'bg-gray-300',
	textColor: 'text-gray-700'
}, {
	referenceDataId: 118,
	text: 'Active',
	badgeColor: 'bg-green-100',
	textColor: 'text-green-700',
	additionalClasses: 'md:px-0 px-4 md:py-0 py-1'
}, {
	referenceDataId: 117,
	text: 'Draft',
	badgeColor: 'bg-amber-100',
	textColor: 'text-amber-700',
	additionalClasses: 'md:px-0 px-4 md:py-0 py-1'
}, {
	referenceDataId: 120,
	text: 'Archived',
	badgeColor: 'bg-gray-300',
	textColor: 'text-gray-700',
	additionalClasses: 'md:px-0 px-4 md:py-0 py-1'
}];
