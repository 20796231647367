/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import Moment from 'moment';

export const isDateAfter = (date, compareeDate) => Moment(date).isAfter(Moment(compareeDate));

export const formatDateTimeToUTC = (date, hours, minutes, meridiem) => {
	const dateArray = date.split('/');
	const formated_month = Number(dateArray[0]);
	const formated_day = Number(dateArray[1]);
	const formated_year = Number(dateArray[2]);
	let formated_hours = Number(hours);
	const formated_minutes = Number(minutes);

	if (meridiem.toLowerCase() === 'pm') {
		if (formated_hours < 12) {
			formated_hours += 12;
		}
	}

	if (meridiem.toLowerCase() === 'am') {
		if (formated_hours === 12) {
			formated_hours = 0;
		}
	}

	if (formated_hours === 12 && meridiem.toLowerCase() === 'pm') {
		formated_hours = 12;
	}

	return new Date(
		formated_year,
		formated_month - 1,
		formated_day,
		formated_hours,
		formated_minutes
	).toISOString();
};

export const getCurrentDateAsJson = () => {
	const jsonCurrentDate = Moment.utc().toDate().toJSON();
	return jsonCurrentDate;
};

export const formatUTCToDate = (utcDate) => {
	const localDate = new Date(utcDate);
	return localDate;
};

export const getTimezoneName = (value) => {
	const today = new Date(value);
	const short = today.toLocaleDateString(undefined);
	const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

	// Trying to remove date from the string in a locale-agnostic way
	const shortIndex = full.indexOf(short);
	if (shortIndex >= 0) {
		const trimmed = full.substring(0, shortIndex)
            + full.substring(shortIndex + short.length);

		const tempArray = trimmed.split(' ');
		let tempTime = '';
		tempArray.forEach((item) => {
			tempTime = tempTime.concat(item.slice(0, 1));
		});

		//   return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
		return tempTime.replace(',', '');
	}
	// in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
	return full;
};

export const formatDate = (date, langCode) => {
	const day = date.toLocaleString(langCode, { day: '2-digit' }); // DD
	const month = date.toLocaleString(langCode, { month: 'short' }); // MMM
	const year = date.toLocaleString(langCode, { year: 'numeric' }); // YYYY
	return `${day} ${month}, ${year}`;
};

export const formatDateToLocal = (date) => {
	if (date !== null) {
		const formated_date = new Date(date);

		const local_date = Moment(formated_date).format('dddd, DD-MMM-YYYY');

		const time_format_options = { hour: 'numeric', minute: '2-digit' };
		const local_time = formated_date.toLocaleTimeString(
			'en-US',
			time_format_options
		);

		let local_time_zone = formated_date.toTimeString();

		local_time_zone = local_time_zone.split('(')[1];
		local_time_zone = local_time_zone.slice(0, local_time_zone.length - 1);
		const zone_array = local_time_zone.split(' ');

		let final_time_zone = '';
		zone_array.forEach((item) => {
			final_time_zone = final_time_zone.concat(item.slice(0, 1));
		});

		return `${local_date} • ${local_time} (${getTimezoneName(date)})`;
	}
	return 'No Date';
};

export const getTimeWithAdditionalSeconds = (date, additionalSeconds) => Moment(date).add(additionalSeconds, 'seconds').toDate();
export const getMonthName = (date) => Moment(date).format('MMM');
export const getYear = (date) => Moment(date).year();
export const getDayOfMonth = (date) => Moment(date).format('D');
export const isDateBigger = (firstDate, secondDate) => Moment.utc(secondDate).diff(Moment.utc(firstDate), 'seconds');
export const getElapsedTimestamp = (dateFromStr, dateTo) => {
	const formatedDateFrom = Moment(dateFromStr).toDate();
	const formatedDateto = Moment(dateTo).toDate();
	const difference = Moment.utc(
		Moment(formatedDateto, 'DD/MM/YYYY HH:mm:ss').diff(
			Moment(formatedDateFrom, 'DD/MM/YYYY HH:mm:ss')
		)
	).format('HH:mm:ss');
	if (difference) return difference;
	return '00:00:00';
};

export const getElapsedTimestampWithCurrentDate = (dateFrom) => {
	const difference = Moment.utc(
		Moment(new Date(), 'DD/MM/YYYY HH:mm:ss').diff(
			Moment(dateFrom, 'DD/MM/YYYY HH:mm:ss')
		)
	).format('HH:mm:ss');

	if (difference) return difference;
	return '00:00:00';
};

export const isTimeDue = (date, seconds) => {
	const time = Moment.utc(date).add(seconds, 'seconds').toDate();
	const now = Moment().utc().toDate();
	return now > time;
};

export const getElapsedTimestampWithCurrentDateQueue = (dateFrom) => {
	const difference = Moment.utc(
		Moment(new Date(), 'DD/MM/YYYY HH:mm:ss').diff(
			Moment.utc(dateFrom).toDate()
		)
	).format('HH:mm:ss');

	if (difference) return difference;
	return '00:00:00';
};

export const getElapsedTimestampWithCurrentDateQueueTwoDatesForSeeking = (
	firstDateFrom,
	secondDateFrom
) => {
	const currentDate = Moment(Moment.utc(firstDateFrom).toDate(), 'DD/MM/YYYY HH:mm:ss');
	const firstDifference = Moment.utc(
		currentDate.diff(Moment.utc(firstDateFrom).toDate())
	).format('HH:mm:ss');
	const secondDifference = Moment.utc(
		currentDate.diff(Moment.utc(secondDateFrom).toDate())
	).format('HH:mm:ss');

	if (firstDifference) return [firstDifference, secondDifference];
	return ['00.00.00', '00.00.00'];
};

export const getElapsedTimestampWithCurrentDateQueueTwoDates = (
	firstDateFrom,
	secondDateFrom
) => {
	const currentDate = Moment(new Date(), 'DD/MM/YYYY HH:mm:ss');
	let firstDifference = Moment.utc(
		currentDate.diff(Moment.utc(firstDateFrom).toDate())
	).format('HH:mm:ss');
	const secondDifference = Moment.utc(
		currentDate.diff(Moment.utc(secondDateFrom).toDate())
	).format('HH:mm:ss');
	if (firstDifference == '23:59:59') {
		firstDifference = '00:00:00';
	}
	if (firstDifference) return [firstDifference, secondDifference];
	return ['00:00:00', '00:00:00'];
};

export const formatJsonDateToProperDateObject = (strDate) => Moment(strDate).toDate();

export const formatUTCDateToLocal = (date, format = 'MM/DD/YYYY HH:mm') => {
	let utcDate = Moment.utc(date).format(format);
	utcDate = Moment.utc(utcDate).toDate();
	return Moment(utcDate).local().format(format);
};

export const secondsBetweenFirstAndSecondDate = (firstDate, secondDate) => Math.abs((Moment(firstDate).toDate().getTime() - Moment(secondDate).toDate().getTime()) / 1000);

export const getDurationTime = (durationInSeconds) => new Date(durationInSeconds * 1000).toISOString().substr(11, 8);

export const getMinimumTime = (value) => {
	const startDateYear = value.getFullYear();
	const startDateMonth = value.getMonth();
	const startDateDate = value.getDate();

	const currentDateYear = new Date().getFullYear();
	const currentDateMonth = new Date().getMonth();
	const currentDateDate = new Date().getDate();

	if (
		startDateYear === currentDateYear
		&& startDateMonth === currentDateMonth
		&& startDateDate === currentDateDate
	) {
		return new Date(0, 0, 0, new Date().getHours() + 1);
	}
	return new Date(0, 0, 0, 0);
};
