import React, { useEffect, useContext, useState } from 'react';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import { PICK_YOUR_SLOTS } from '../../utils/constants';

const BreaksEventQueue = () => {
	const [selectedEventId, setSelectedEventId] = useState(null);
	const [events, setEvents] = useState([]);
	const [breaks, setBreaks] = useState([]);
	const {
		eventDetails,
		preselectedBreakEventIdOnOpenedView,
		setPreselectedBreakEventIdOnOpenedView
	} = useContext(LiveEventDataContext);

	useEffect(() => {
		if (!eventDetails || !eventDetails.length) {
			setEvents([]);
			return;
		}
		const eventBreaks = eventDetails.filter(
			(e) => e.event.eventTypeId == PICK_YOUR_SLOTS
		);

		if (eventBreaks && eventBreaks.length) {
			const updatedBreaks = eventBreaks.map((event) => {
				let allSlotsPurchased = false;
				for (let i = 0; i < event.breaks.length; i++) {
					if (!event.breaks[i].canPurchase) {
						allSlotsPurchased = true;
						// eslint-disable-next-line no-continue
						continue;
					}
					allSlotsPurchased = false;
					break;
				}
				return {
					...event,
					isFull: allSlotsPurchased
				};
			});

			setEvents(updatedBreaks.sort((a, b) => a.isFull - b.isFull));
		}
		if (!selectedEventId) {
			const foundFirstPickYourSlot = eventDetails.find(
				(e) => e.event.eventTypeId == PICK_YOUR_SLOTS
			);
			if (foundFirstPickYourSlot) {
				setSelectedEventId(foundFirstPickYourSlot.event.eventId);
			}
			return;
		}
		const pickYourSlotBreaks = eventDetails.find(
			(e) => e.event.eventId == selectedEventId
		);
		if (pickYourSlotBreaks) {
			setBreaks(pickYourSlotBreaks.breaks);
		}
		if (preselectedBreakEventIdOnOpenedView) {
			setSelectedEventId(preselectedBreakEventIdOnOpenedView);
			setPreselectedBreakEventIdOnOpenedView(null);
		}
	}, [eventDetails, selectedEventId, preselectedBreakEventIdOnOpenedView]);

	return (
		<div className="">
			<div className="flex row dark:border-black px-2 items-center space-x-2 py-2">
				<label
					htmlFor="product_status"
					className="block text-sm font-medium text-gray-700 pl-2 dark:text-white "
				>
					Event:
				</label>
				<select
					id="product_status"
					name="product_status"
					autoComplete="event_type"
					className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border dark:border-black bg-white dark:bg-darkGray-50 border-gray-200 dark:text-white rounded-md shadow-md mb-1"
					onBlur={(e) => setSelectedEventId(e.target.value)}
					onChange={(e) => setSelectedEventId(e.target.value)}
					value={selectedEventId}
				>
					{events.map((eventDetail, i) => (
						<option key={i} value={eventDetail.event.eventId}>
							{eventDetail.isFull
								? `(Full) - ${eventDetail.event.eventName} - Event Number #${eventDetail.event.eventNumber}`
								: `${eventDetail.event.eventName} - Event Number #${eventDetail.event.eventNumber}`}
						</option>
					))}
				</select>
			</div>
			<table className="table-fixed text-left w-full">
				<thead>
					<tr>
						<th className="w-1/12 p-4 text-left text-sm font-medium text-gray-100 bg-djawn">
							#
						</th>
						<th className="w-3/12 p-4 text-left text-sm font-medium text-gray-100 bg-djawn">
							Buyer
						</th>
						<th className="w-3/12 p-4 text-left text-sm font-medium text-gray-100 bg-djawn">
							Team
						</th>
					</tr>
				</thead>
				<tbody className="text-gray-800 text-sm dark:text-white dark:bg-darkGray-50">
					{breaks.map((brk) => (
						<tr>
							{/* <td style={{margin:'13px'}}>{attendee.isOnline ? <OnlineIcon /> : <OfflineIcon />}</td>
										<td><p className="text-base font-light leading-relaxed mb-2 text-center">{attendee.nickname}</p></td> */}
							<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
								{brk.slot}
							</td>
							<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
								<p className="text-base font-light leading-relaxed mb-2 text-left">
									{brk.buyerNickname}
								</p>
							</td>
							<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
								<p className="text-base font-light leading-relaxed mb-2 text-left">
									{brk.slotName}
								</p>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default BreaksEventQueue;
