import { useState, useCallback } from 'react';
import { navigate } from 'gatsby';
import {
	appleAuthentication,
	customAuthentication,
	facebookAuthentication,
	googleAuthentication
} from '../api/auth.request';
import { saveLogin } from '../utils/auth';
import useErrorNotifier from './use-error-notifier';
import { SIGNIN_REDIRECT_URL } from '../utils/constants/cache-keys';
import { DEFAULT_AUTH_ERROR_MESSAGE } from '../utils/constants';

const useAuthentication = (isLogin) => {
	const [authenticating, setAuthenticating] = useState(false);
	const [twoFactorResult, setTwoFactorResult] = useState(null);

	const { showToastError } = useErrorNotifier();

	const onSuccessfullyAuthenticated = useCallback(
		(data, provider, shouldNavigate, redirectUrl, onSignupSucceeded) => {
			if (data.twoFactor && data.token) {
				setTwoFactorResult(data);
				return;
			}

			if (data) {
				saveLogin({
					name: data.name,
					id: data.id,
					email: data.email,
					token: data.token,
					refreshToken: data.refreshToken,
					userDetails: data.userDetails,
					expirationDate: data.expirationDate,
					firstName: data.firstName,
					lastName: data.lastName
				});
			}

			if (provider == 'custom' && !isLogin) {
				if (onSignupSucceeded && typeof onSignupSucceeded === 'function') {
					onSignupSucceeded();
				}
				setAuthenticating(false);
				return data;
			}
			if (!shouldNavigate) {
				setAuthenticating(false);
				return data;
			}
			if (redirectUrl && redirectUrl.length > 0) {
				navigate(redirectUrl);
			} else {
				const redirectUrlInSessionStorage = sessionStorage.getItem(
					SIGNIN_REDIRECT_URL
				);
				if (redirectUrlInSessionStorage) {
					sessionStorage.removeItem(SIGNIN_REDIRECT_URL);
					navigate(redirectUrlInSessionStorage);
				} else {
					navigate('/');
				}
			}
		},
		[isLogin]
	);

	const authenticate = useCallback(
		async (
			requestBody,
			provider = 'facebook',
			redirectUrl = '',
			onSignupSucceeded,
			shouldNavigate = true,
			isSignUpInitiated = false,
			onAccountExpired = null,
		) => {
			setAuthenticating(true);
			let providerAuthentication;
			if (provider === 'facebook') {
				providerAuthentication = facebookAuthentication;
			} else if (provider === 'google') {
				providerAuthentication = googleAuthentication;
			} else if (provider === 'apple') {
				providerAuthentication = appleAuthentication;
			} else {
				providerAuthentication = customAuthentication;
			}

			if (!providerAuthentication) return undefined;

			let data = null;
			requestBody.isSignUpInitiated = isSignUpInitiated;
			requestBody.isSigningIn = isLogin;

			try {
				const authenticationResponse = await providerAuthentication(
					requestBody
				);
				data = authenticationResponse;
				if (data.data && data.data.accountToReset) {
					if (onAccountExpired && typeof onAccountExpired === 'function') {
						onAccountExpired(data.data.accountToReset);
					}
					return;
				}
				onSuccessfullyAuthenticated(
					authenticationResponse.data,
					provider,
					shouldNavigate,
					redirectUrl,
					onSignupSucceeded
				);
			} catch (err) {
				if (err && err.response && err.response.data) {
					if (err.response.data.signUpRequired) {
						navigate('/register', {
							state: { externalProviderResponse: err.response.data.body }
						});
						return false;
					} if (err.response.data.emailConfirmationResent) {
						onSignupSucceeded(true);
					}
				}
				showToastError(
					err && err.response && err.response.data
						? err
						: DEFAULT_AUTH_ERROR_MESSAGE
				);
				setAuthenticating(false);
				return false;
			} finally {
				setAuthenticating(false);
			}
			return data;
		},
		[isLogin, onSuccessfullyAuthenticated]
	);

	return {
		authenticating,
		authenticate,
		twoFactorResult,
		setTwoFactorResult,
		onSuccessfullyAuthenticated
	};
};

export default useAuthentication;
