/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

const CameraChangeModal = ({ showModal, setShowModal, changeCamera }) => {
	const [cameras, setCameras] = useState([]);

	useEffect(() => {
		if (!showModal) return;
		navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
			mediaDevices = setCameras(
				mediaDevices.filter((device) => device.kind == 'videoinput')
			);
		});
	}, [showModal]);

	return (
		<>
			{showModal ? (
				<>
					<div
						className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
						onClick={() => setShowModal(false)}
						onKeyDown={() => setShowModal(false)}
					>
						<div className="relative w-auto my-6 mx-auto max-w-3xl">
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								<div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
									<h3 className="text-3xl font-semibold">Event Options</h3>
									<button
										type="button"
										className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => setShowModal(false)}
									>
										<span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
											×
										</span>
									</button>
								</div>
								{/* body */}
								<div className="relative p-3 flex-auto">
									<p className="my-4 text-gray-600 text-lg leading-relaxed">
										{cameras
											&& cameras.map((camera, i) => (
												<button
													type="button"
													className="w-full bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
													style={{
														transition: 'all .15s ease'
													}}
													onClick={() => {
														changeCamera(camera);
													}}
												>
													Camera:
													{' '}
													{camera.label}
													{' '}
-
													{' '}
													{i + 1}
												</button>
											))}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black" />
				</>
			) : null}
		</>
	);
};

export default CameraChangeModal;
