import axiosConfig, { getConfig } from './config';
import { getAccessToken } from '../utils/auth';

export const authHeaders = () => ({ headers: { Authorization: `Bearer ${getAccessToken()}` } });

export const logPaypalError = async (err) => await axiosConfig.put(`payment/paypal-error/${err}`, null);
export const getCustomPaypalError = async () => await axiosConfig.get('payment/paypal-error');
export const createOrder = async (data) => await axiosConfig.post('payment/create-order', data);
export const createRapidOrder = async (order) => await axiosConfig.post('payment/create-rapid-order', order);
export const removeOrder = async (data) => await axiosConfig.post('payment/back-to-order', data);
export const getPaymentOrder = async (order) => await axiosConfig.post('payment/payment-order', order);
export const getOrders = async (isSalesList = false, limit = 0) => await axiosConfig.get(`payment/orders/list/${isSalesList}/${limit}`);
export const getFilteredOrders = async (eventTypeId, eventName, useSalesList = false, limit = 0) => await axiosConfig.post('payment/orders/search', {
	eventTypeId,
	eventName,
	useSalesList,
	limit
});
export const getOrderDetails = async (orderId, useSalesList) => await axiosConfig.get(`payment/orders/${orderId}/${useSalesList}`);
export const getPurchasedProductImage = async (image) => await axiosConfig.get(`payment/product/image/${image}`);
export const getStripeFeeSchema = async () => await axiosConfig.get('payment/stripe-fee');
export const getShippingPrice = async (orderIds, buyerId, useSalesList) => await axiosConfig.post('payment/orders/shipping-price', { orderIds, buyerId, useSalesList });
export const getShippingPriceByEventId = async (orderId, eventId, buyerId) => await axiosConfig.get(`payment/orders/shipping-price/${orderId}/${eventId}/${buyerId}`);
export const sendRefundRequest = async (orderId, orderDetails, sellerId, refundReasonId, isPartial) => await axiosConfig.post('payment/refund/request', {
	orderId,
	orderDetails,
	sellerId,
	refundReasonId,
	isPartial
});
export const uploadRefundAttachments = async (formData, refundId) => {
	const headers = authHeaders();

	headers['Content-Type'] = 'multipart/form-data';
	headers.redirect = 'follow';
	headers.mode = 'no-cors';
	return await axiosConfig.post(
		`payment/refund/attachments/${refundId}`,
		formData,
		headers
	);
};
export const getRefundRequestedOrderDetailIds = async (orderId) => await axiosConfig.get(`payment/refund/order-details/${orderId}`);
export const orderDetailHasRefundRequested = async (orderDetailId) => await axiosConfig.get(`payment/refund/order-details/requested/${orderDetailId}`);
export const getPendingRequests = async (orderId) => await axiosConfig.get(`payment/refund/requests/pending/${orderId}`);
export const getRefundDetails = async (refundId) => await axiosConfig.get(`payment/refund/details/${refundId}`);
export const updateRefundStatusToPending = async (refundId) => await axiosConfig.put(`payment/refund/request/status/pending/${refundId}`);
export const declineRefund = async (refundId, rejectedRefundReasonId, orderDetailAmounts) => await axiosConfig.put('payment/refund/request/decision',
	{
		refundId, orderDetailAmounts, rejectedRefundReasonId, approved: false
	});
export const approveRefund = async (
	refundId, orderDetailAmounts,
	shippingFee, paymentProcessorFee, djawnFee,
	djawnDiscount, refundTotal, djawnSellerFee) => await axiosConfig.put('payment/refund/request/decision',
	{
		refundId,
		orderDetailAmounts,
		shippingFee,
		paymentProcessorFee,
		djawnFee,
		djawnDiscount,
		refundTotal,
		djawnSellerFee,
		approved: true
	});
export const isUserEligibleToViewRefundDetails = async (refundId) => await axiosConfig.get(`payment/refund/request/details/eligible/${refundId}`);
export const getRefundRequestedItemQuantity = async (orderDetailId) => await axiosConfig.get(`payment/refund/order-details/requested/quantity/${orderDetailId}`);
/**
 *
 * @param {number} amount
 * @param {number} shipping
 * @param {number} refundReasonId
 * @returns {Promise}
 */
export const calculateFees = async (amount, shipping, originalAmount,
	originalShipping, originalPaymentProcessFee, refundReasonId, inProportion = true) => await axiosConfig.get('payment/fees/calculate', {
	params: {
		amount,
		shipping,
		refundReasonId,
		originalAmount,
		originalShipping,
		originalPaymentProcessFee,
		inProportion
	}
});
export const hasUsedShippingPriceInARefundPreviously = async (orderId, sellerId) => await axiosConfig.get(`payment/refund/used-shipping-price/${orderId}/${sellerId}`);
export const applyPromoCode = async (code, eventProductIds, breakIds) => await axiosConfig.post('payment/discount-code/apply', {
	code,
	eventProductIds,
	breakIds
});

export const hasPaymentIntentSucceeded = async (checkoutSessionId = null, paymentIntentId = null) => {
	if (!checkoutSessionId && !paymentIntentId) return null;
	const config = getConfig();
	const params = {};

	if (checkoutSessionId) {
		params.checkoutSessionId = checkoutSessionId;
	}
	if (paymentIntentId) {
		params.paymentIntentId = paymentIntentId;
	}
	config.params = params;
	return await axiosConfig.get('payment/succeeded', config);
};
