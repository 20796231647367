import React from 'react';
import { XCircleIcon } from '@heroicons/react/outline';
import Slider from 'react-slick';
import LinkedEventSliderMobileItem from '../../../components/linked-event-slider/linked-event-slider-mobile-item';

const MAX_SLIDES_TO_SCROLL = 1;
const LinkedEventsMobileSliderChat = ({ opened, linkedEvents, onClose }) => {
	if (!opened) return null;

	const onEventLinkClick = (eventId) => {
		window.open(
			`${window.location.origin}/event/${eventId}?closeOnPurchase=true`,
			'_blank'
		);
	};

	return (
		<div className="sticky top-0 w-full flex justify-center px-4 pt-2 h-24">
			<button
				type="button"
				className="absolute top-1 right-5 flex items-start"
				onClick={onClose}
			>
				<XCircleIcon className="w-6 h-6" />
			</button>
			<div className="relative items-start justify-start w-9/12">
				<div style={{ maxWidth: '100vw', width: '100%' }}>
					<Slider
						className="linked-events-mobile-slider"
						slidesToShow={
							linkedEvents.length > MAX_SLIDES_TO_SCROLL
								? MAX_SLIDES_TO_SCROLL
								: linkedEvents.length
						}
						infinite={false}
						arrows
					>
						{linkedEvents.map((linkedEvent) => (
							<LinkedEventSliderMobileItem
								linkedEvent={linkedEvent}
								onEventLinkClick={onEventLinkClick}
								key={linkedEvent.event.eventId}
							/>
						))}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default LinkedEventsMobileSliderChat;
