import React, {
	useState, useContext, useEffect, useMemo
} from 'react';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '../../../components/modals';
import Button from '../../../components/button';
import { SellerEventAttendeeActionsContext } from '../../../contexts/seller-event-attendee-actions';
import { TwilioTrackContext } from '../../../contexts/twilio-track';
import { LiveEventDataContext } from '../../../contexts/live-event-data';
import ConfirmationModal from '../../../components/confirmation-modal';
import {
	getParticipantsFromTwilio,
	removeUsersFromEvent
} from '../../../api/event.request';

const clipLoaderCss = css`
	display: block;
	border-color: white;
`;

const EventActions = ({ user, closeModal }) => {
	const { setUserNicknameToBan } = useContext(
		SellerEventAttendeeActionsContext
	);

	return (
		<div className="w-full flex flex-col items-center space-y-2">
			<h5>Event Actions</h5>
			<Button
				width="full"
				height="10"
				mobileHeight="10"
				onClick={() => {
					closeModal();
					setUserNicknameToBan(user.nickname);
				}}
			>
				Remove user
			</Button>
		</div>
	);
};

const MicActions = ({ user, closeModal }) => {
	const [closeMicClicked, setCloseMicClicked] = useState(false);
	const [mutingMicClicked, setMutingMicClicked] = useState(false);
	const [approveMicClicked, setApproveMicClicked] = useState(false);
	const [declineMicClicked, setDeclineMicClicked] = useState(false);
	const {
		approveOrDeclineMicRequest,
		audioParticipants,
		approvedAudioParticipants,
		muteOrUnmuteMicParticipants,
		closeMic,
		closingMic,
		setClosingMic,
		mutingMic,
		setMutingMic
	} = useContext(TwilioTrackContext);

	useEffect(() => {
		if (!closeMicClicked || closingMic) return;
		closeModal();
	}, [closingMic, closeMicClicked]);

	useEffect(() => {
		if (!mutingMicClicked || mutingMic) return;
		closeModal();
	}, [mutingMic, mutingMicClicked]);

	const enableCloseMicButton = useMemo(() => {
		if (
			audioParticipants
			&& audioParticipants.length
			&& audioParticipants.find(
				(participant) => participant.twilioParticipantId == user.twilioParticipantId
			) != null
		) {
			return true;
		}
		if (
			approvedAudioParticipants
			&& approvedAudioParticipants
			&& approvedAudioParticipants.find(
				(participant) => participant.twilioParticipantId == user.twilioParticipantId
			) != null
		) {
			return true;
		}

		return false;
	}, [user, audioParticipants, approvedAudioParticipants]);

	return (
		<div className="w-full flex flex-col items-center space-y-2">
			<h5>Mic Actions</h5>
			<Button
				width="full"
				height="10"
				mobileHeight="10"
				additionalClassName={
					approveMicClicked ? 'flex items-center justify-center' : ''
				}
				disabled={
					!user.micRequest
					|| (audioParticipants
						&& audioParticipants.length
						&& audioParticipants.find(
							(p) => p.twilioParticipantId == user.twilioParticipantId
						) != null)
					|| approveMicClicked
				}
				onClick={() => {
					setApproveMicClicked(true);
					approveOrDeclineMicRequest(
						[
							{
								userId: user.userId,
								twilioParticipantId: user.twilioParticipantId
							}
						],
						true,
						false
					).finally(() => {
						setApproveMicClicked(false);
						closeModal();
					});
				}}
			>
				{approveMicClicked && <ClipLoader size={30} css={clipLoaderCss} />}
				<>
					<span>Approve mic request</span>
				</>
			</Button>
			<Button
				width="full"
				height="10"
				mobileHeight="10"
				additionalClassName={
					declineMicClicked ? 'flex items-center justify-center' : ''
				}
				disabled={
					!user.micRequest
					|| (audioParticipants
						&& audioParticipants.length
						&& audioParticipants.find(
							(p) => p.twilioParticipantId == user.twilioParticipantId
						) != null)
					|| declineMicClicked
				}
				onClick={() => {
					setDeclineMicClicked(true);
					approveOrDeclineMicRequest(
						[
							{
								userId: user.userId,
								twilioParticipantId: user.twilioParticipantId
							}
						],
						false,
						false
					).finally(() => {
						setDeclineMicClicked(false);
						closeModal();
					});
				}}
			>
				{declineMicClicked && <ClipLoader size={30} css={clipLoaderCss} />}
				<>
					<span>Decline mic request</span>
				</>
			</Button>
			<Button
				width="full"
				height="10"
				mobileHeight="10"
				additionalClassName={
					mutingMic ? 'flex items-center justify-center' : ''
				}
				disabled={
					!audioParticipants
					|| !audioParticipants.length
					|| audioParticipants.find(
						(participant) => participant.twilioParticipantId == user.twilioParticipantId
							&& participant.muted == false
					) == null
					|| mutingMic
				}
				onClick={() => {
					setMutingMic(true);
					setMutingMicClicked(true);
					muteOrUnmuteMicParticipants(
						[
							{
								twilioParticipantId: user.twilioParticipantId,
								userId: user.userId
							}
						],
						true,
						true,
						false
					);
				}}
			>
				{mutingMic && <ClipLoader size={30} css={clipLoaderCss} />}
				<>
					<span>Mute user</span>
				</>
			</Button>
			<Button
				width="full"
				height="10"
				mobileHeight="10"
				disabled={!enableCloseMicButton || closingMic}
				additionalClassName={
					closingMic ? 'flex items-center justify-center' : ''
				}
				onClick={() => {
					setCloseMicClicked(true);
					setClosingMic(true);
					closeMic([user.twilioParticipantId], true, false);
				}}
			>
				{closingMic && <ClipLoader size={30} css={clipLoaderCss} />}
				<>
					<span>Close mic</span>
				</>
			</Button>
		</div>
	);
};

const AnonymousUserActions = ({ closeModal }) => {
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const { attendees, eventId, webSocketGroupName } = useContext(
		LiveEventDataContext
	);

	const onRemoveAllAnonymousUsers = async () => {
		const anonymousAttendees = attendees.filter(
			(atendee) => atendee.isAnonymous
		);
		if (!anonymousAttendees || !anonymousAttendees.length) return;
		try {
			let anonymousTokens = [];
			const twilioParticipantsRes = await getParticipantsFromTwilio(eventId);
			if (twilioParticipantsRes.data) {
				anonymousTokens = twilioParticipantsRes.data
					.filter((participant) => participant.isAnonymous)
					.map((participant) => participant.anonymousToken);
			}
			if (anonymousTokens && anonymousTokens.length) {
				removeUsersFromEvent(
					eventId,
					null,
					anonymousTokens,
					webSocketGroupName
				);
			}
		} catch (err) {
			console.error(err);
		} finally {
			closeModal();
			setShowConfirmationModal(false);
		}
	};

	return (
		<div className="w-full flex flex-col items-center space-y-2 h-36 justify-center">
			<ConfirmationModal
				showModal={showConfirmationModal}
				setShowModal={setShowConfirmationModal}
				confirmText="Yes"
				onConfirm={onRemoveAllAnonymousUsers}
				onCancel={() => setShowConfirmationModal(false)}
				cancelText="No"
				text="Do you want to remove all anonymous users?"
				showConfirmationIcon={false}
				hideFooter
				textCss="text-center font-semibold dark:text-white text-gray-700 text-lg p-2"
				hideHeader
			/>
			<Button
				width="full"
				height="10"
				mobileHeight="10"
				onClick={() => setShowConfirmationModal(true)}
			>
				Remove all Anonymous user
			</Button>
		</div>
	);
};

const SellerAttendeeActionsModal = ({
	fetchAttendeesAndUpdateAudioParticipants
}) => {
	const { selectedUserForActions, setSelectedUserForActions } = useContext(
		SellerEventAttendeeActionsContext
	);

	useEffect(() => {
		if (selectedUserForActions != null) return;
		fetchAttendeesAndUpdateAudioParticipants();
	}, [selectedUserForActions]);

	if (!selectedUserForActions) return null;

	return (
		<Modal
			showModal={selectedUserForActions != null}
			onClose={() => setSelectedUserForActions(null)}
			hideCloseButton
			header={`Seller Actions - ${selectedUserForActions.nickname}`}
		>
			<div className="w-full py-2">
				{selectedUserForActions.isAnonymous ? (
					<AnonymousUserActions
						closeModal={() => setSelectedUserForActions(null)}
					/>
				) : (
					<>
						<div className="border-b-2 border-t-2 py-4">
							<EventActions
								user={selectedUserForActions}
								closeModal={() => setSelectedUserForActions(null)}
							/>
						</div>
						<div className="py-4">
							<MicActions
								user={selectedUserForActions}
								closeModal={() => setSelectedUserForActions(null)}
							/>
						</div>
					</>
				)}
			</div>
		</Modal>
	);
};

export default SellerAttendeeActionsModal;
