import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import PropType from 'prop-types';
import ErrorFieldMessage from '../error-field-message';

const TextInput = ({
	width = 'full',
	variant = 'primary',
	mobileWidth = 'full',
	height = '10',
	mobileHeight = 'auto',
	textSize = 'sm',
	type = 'text',
	additionalClassName = '',
	error = '',
	floatingLabelText = '',
	floatingLabelClassName = '',
	value = '',
	onFocus = () => {},
	disabled = false,
	onFloatingLabel,
	focused = false,
	containerClassName = '',
	...restProps
}) => {
	const inputRef = useRef(null);
	const [useFloatingLabelClassName, setUseFloatingLabelClassName] = useState(
		false
	);

	useEffect(() => {
		if (!focused) return;
		if (inputRef.current && inputRef.current.focus) {
			inputRef.current.focus();
		}
	}, [focused]);
	useEffect(() => {
		if (!onFloatingLabel || typeof onFloatingLabel !== 'function') return;
		onFloatingLabel(useFloatingLabelClassName);
	}, [onFloatingLabel, useFloatingLabelClassName]);

	return (
		<div
			className={clsx(
				error
					&& `flex flex-col items-center justify-center ${additionalClassName}`,
				floatingLabelText && floatingLabelText.length > 0
					? `relative w-${mobileWidth} md:w-${width}`
					: 'w-full',
				(value && value.length > 0) || useFloatingLabelClassName
					? floatingLabelClassName
					: '',
				containerClassName
			)}
		>
			<input
				type={type}
				className={clsx(
					`input input--${variant}`,
					floatingLabelText && floatingLabelText.length > 0
						? 'w-full placeholder-transparent'
						: `w-${mobileWidth} md:w-${width}`,
					`h-${mobileHeight} md:h-${height} text-${textSize}`,
					!error && additionalClassName,
					error && 'input--invalid'
				)}
				ref={inputRef}
				value={value}
				disabled={disabled}
				onFocus={() => {
					if (inputRef.current && floatingLabelClassName) {
						setUseFloatingLabelClassName(true);
					}

					onFocus();
				}}
				{...restProps}
			/>
			{floatingLabelText && floatingLabelText.length ? (
				<label
					tabIndex={-3}
					onClick={() => inputRef.current.focus()}
					onKeyDown={() => inputRef.current.focus()}
					disabled={disabled}
					className={clsx(
						'input--floating-label',
						value && value.length > 0 && 'input--floating-label--active',
						disabled && 'input--floating-label--disabled'
					)}
				>
					{floatingLabelText}
				</label>
			) : null}

			{error && <ErrorFieldMessage error={error} />}
		</div>
	);
};

TextInput.propTypes = {
	width: PropType.string,
	mobileWidth: PropType.string,
	height: PropType.string,
	mobileHeight: PropType.string,
	textSize: PropType.string,
	type: PropType.string,
	additionalClassName: PropType.string,
	error: PropType.string,
	onFloatingLabel: PropType.func,
	containerClassName: PropType.string
};

export default TextInput;
