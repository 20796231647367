import { useState, useRef, useCallback } from 'react';
import { isAndroid } from 'react-device-detect';
import { VIEWS_DESKTOP_MIN_WIDTH } from '../utils/constants';
import useLocalStorage from './use-local-storage';

const useLiveEventViewsContainer = () => {
	const [openQueueContainer, setOpenQueueContainer] = useState(false);
	const [viewPopupFadingOut, setViewPopupFadingOut] = useState(false);
	const [addToCartPopupFadingOut, setAddToCartPopupFadingOut] = useState(false);
	const arrowButtonContainerRef = useRef();
	const eventNamePopupRef = useRef();
	const arrowButtonRef = useRef();
	const [popups, setPopups] = useLocalStorage('live_event_views_popups', {
		show_cards: true,
		show_views: true,
		show_addtocart_information: true,
		show_event_name: true
	});
	const [dismissedPopups, setDismissedPopups] = useState({
		show_cards: true,
		show_views: true,
		show_addtocart_information: true,
		show_event_name: true
	});
	const [width, setWidth] = useLocalStorage(
		'desktop-views-width',
		VIEWS_DESKTOP_MIN_WIDTH
	);

	const updatePopupsStatus = (type = '', dismissed = false) => {
		switch (type) {
			case 'show_cards':
				if (
					arrowButtonContainerRef
					&& arrowButtonContainerRef.current
					&& arrowButtonContainerRef.current.close
				) {
					arrowButtonContainerRef.current.close();
				}
				break;
			case 'show_views':
				if (!viewPopupFadingOut) setViewPopupFadingOut(true);
				break;
			case 'show_addtocart_information':
				if (!setAddToCartPopupFadingOut) setAddToCartPopupFadingOut(true);
				break;
			case 'show_event_name':
				if (
					eventNamePopupRef
					&& eventNamePopupRef.current
					&& eventNamePopupRef.current.close
				) {
					eventNamePopupRef.current.close();
				}
				break;
			default:
				return;
		}
		setTimeout(() => {
			let tempPopups = {};
			if (dismissed) {
				tempPopups = { ...dismissedPopups };
			} else {
				tempPopups = { ...popups };
			}
			switch (type) {
				case 'show_cards':
					tempPopups.show_cards = false;
					break;
				case 'show_views':
					tempPopups.show_views = false;
					break;
				case 'show_addtocart_information':
					tempPopups.show_addtocart_information = false;
					break;
				case 'show_event_name':
					tempPopups.show_event_name = false;
					break;
				default:
					return;
			}
			if (dismissed) {
				if (JSON.stringify(tempPopups) != JSON.stringify(dismissedPopups)) {
					setDismissedPopups(tempPopups);
				}
			} else if (JSON.stringify(tempPopups) != JSON.stringify(popups)) {
				setPopups(tempPopups);
			}
		}, 300);
	};

	const onQueueContainerOpen = useCallback(() => {
		if (isAndroid && !openQueueContainer == true) {
			if (window) {
				// eslint-disable-next-line no-multi-assign
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			}
		}

		setOpenQueueContainer(!openQueueContainer);
	}, [openQueueContainer, updatePopupsStatus, isAndroid, window]);

	return {
		popups,
		dismissedPopups,
		updatePopupsStatus,
		arrowButtonContainerRef,
		eventNamePopupRef,
		onQueueContainerOpen,
		viewPopupFadingOut,
		addToCartPopupFadingOut,
		openQueueContainer,
		arrowButtonRef,
		setOpenQueueContainer,
		width,
		setWidth
	};
};

export default useLiveEventViewsContainer;
