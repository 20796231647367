/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, {
	useState,
	useEffect,
	useRef,
	useMemo,
	useCallback
} from 'react';
import { navigate } from 'gatsby';
import BounceLoader from 'react-spinners/BounceLoader';
import { css } from '@emotion/core';
import { Beforeunload } from 'react-beforeunload';
import { isSupported } from 'twilio-video';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { isMobile, isSafari } from 'react-device-detect';
import {
	getAccessToken,
	isLoggedIn,
	removeUserFromLocalStorage,
	shouldRedirectToSignIn
} from '../../../utils/auth';
import { redirectToDefaultBrowserIfInsideWebViewAndroid } from '../../../utils/redirect';
import axiosConfig from '../../../api/config';

import {
	getEventDetails,
	isEventStopped,
	exitRoom,
	isRoomHost,
	isUserEligibleForEvent,
	exitRoomByAnonymousToken,
	sellerHasNoLiveEventInProgress,
	isEventOwner,
	getMainEventId,
	cancelEvent
} from '../../../api/event.request';
import Room from '../../../components/room/room';

import RoomPolicyConfirmation from '../../../components/room-policy-confirmation';
import UnsupportedBrowserModal from '../../../containers/event/room/unsupported-browser-modal';
import {
	hideProductSavedNotification,
	setAuthenticatedUserId
} from '../../../state/action';
import { getAuthenticatedUserId } from '../../../api/auth.request';
import useErrorNotifier from '../../../hooks/use-error-notifier';
import { LiveEventDataProvider } from '../../../contexts/live-event-data';
import { SoundNotificationProvider } from '../../../contexts/sound-notification';
import useAsyncEffect from '../../../hooks/useAsyncEffect';
import { EVENT_STOPPED_STATUS, RIP_N_SHIP } from '../../../utils/constants';
import { LiveEventViewsContainerProvider } from '../../../contexts/live-event-views-container';
import LiveEventFinishedModal from '../../../containers/event/room/live-event-finished-modal';
import useNavigatorDevices from '../../../hooks/useNavigatorDevices';
import useBooleanQueryParam from '../../../hooks/useBooleanQueryParam';
import { LiveEventChatProvider } from '../../../contexts/live-event-chat';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const RoomPage = ({
	params, location, dispatch, userId
}) => {
	const [hostParticipantRejoining] = useBooleanQueryParam(
		'hostParticipantRejoining'
	);
	const { stop: stopNavigatorDevices } = useNavigatorDevices();
	const exitingByLeaveButtonRef = useRef(false);
	const [mainEventId, setMainEventId] = useState(null);
	const nicknameRef = useRef('');
	const roomRef = useRef();
	const [joinEvent, setJoinEvent] = useState(false);
	const [isHost, setIsHost] = useState(false);
	const [isSeller, setIsSeller] = useState(false);
	const [loading, setLoading] = useState(true);
	const [eventDetails, setEventDetails] = useState(null);
	const [showPolicyModal, setShowPolicyModal] = useState(false);
	const [linkedEvents, setLinkedEvents] = useState([]);
	const [isEligible, setIsEligible] = useState(false);
	const [showEventDetailsButton, setShowEventDetailsButton] = useState(false);
	const [hasSound, setHasSound] = useState(true);
	const [
		showUnsupportedBrowserModal,
		setShowUnsupportedBrowserModal
	] = useState(false);
	const [showLiveEventFinishedModal, setShowLiveEventFinishedModal] = useState(
		false
	);
	const [isFetchingHostStatus, setIsFetchingHostStatus] = useState(true);
	const { showToastError } = useErrorNotifier();
	const cancelEventOnClose = useMemo(() => {
		if (!location || !location.state) return false;
		return location.state.onCloseCancelEvent;
	}, [location]);

	const stopNavigatorDevicesOnClose = useMemo(() => {
		if (!location || !location.state) return false;
		return location.state.stopNavigatorDevicesOnCancel;
	}, [location]);

	useAsyncEffect(async () => {
		if (!isSupported) {
			setShowUnsupportedBrowserModal(true);
			return;
		}
		if (shouldRedirectToSignIn()) {
			removeUserFromLocalStorage();
		}
		if (!params || !params.eventId) return;

		let mainEventIdRes;
		try {
			mainEventIdRes = await getMainEventId(params.eventId);
		} catch (err) {
			console.error(err);
			return;
		}

		setMainEventId(mainEventIdRes.data);

		isEventOwner(mainEventIdRes.data)
			.then((res) => {
				setShowEventDetailsButton(!res.data);
			})
			.catch((err) => {
				console.error(err);
			});

		isUserEligibleForEvent(
			mainEventIdRes.data,
			localStorage.getItem(`anon-${mainEventIdRes.data}`) || 'undefined'
		)
			.then((_) => {
				setIsEligible(true);
			})
			.catch((err) => {
				if (err.response && err.response.data && err.response.data.error) {
					navigate('/events', {
						state: {
							toastError: err.response.data.error
						}
					});
				} else if (window.location) {
					window.location.href = `${window.location.origin}/events`;
				} else {
					navigate('/events');
				}
			})
			.finally(() => {
				setShowPolicyModal(true);
			});

		if (!userId) {
			getAuthenticatedUserId()
				.then((userRes) => {
					dispatch(setAuthenticatedUserId(userRes.data));
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [params]);

	useEffect(() => {
		if (!window || !mainEventId) return;
		window.addEventListener('unload', () => {
			if (roomRef.current && roomRef.current.disconnect) {
				roomRef.current.disconnect();
			}
			const baseUrl = axiosConfig.defaults.baseURL;
			const accessToken = getAccessToken();
			if (!accessToken) {
				const anonToken = localStorage.getItem(`anon-${mainEventId}`);
				if (anonToken) {
					navigator.sendBeacon(
						`${baseUrl}event/room/delete/post/anonymous/${mainEventId}/${anonToken}`,
						'deleting'
					);
				}
			} else {
				navigator.sendBeacon(
					`${baseUrl}event/room/delete/post/${mainEventId}/${nicknameRef.current}`,
					'deleting'
				);
			}
		});

		window.addEventListener('popstate', () => {
			if (roomRef.current && roomRef.current.disconnect) {
				roomRef.current.disconnect();
			}
			let exitRoomParam = nicknameRef.current;
			let exitRoomMethod = exitRoom;
			const accessToken = getAccessToken();
			if (!accessToken) {
				const anonToken = localStorage.getItem(`anon-${mainEventId}`);
				if (anonToken) {
					exitRoomParam = anonToken;
					exitRoomMethod = exitRoomByAnonymousToken;
				}
			}

			if (
				eventDetails
				&& eventDetails.length
				&& eventDetails[0].event.webSocketChannelGroupName
			) {
				exitRoomMethod(
					mainEventId,
					exitRoomParam,
					eventDetails[0].event.webSocketChannelGroupName
				).catch((err) => {
					const mute = err;
				});
			}
		});

		return () => (window.onpopstate = () => {});
	}, [nicknameRef.current, isHost, mainEventId]);

	useEffect(() => {
		if (!mainEventId || (showPolicyModal && !isEligible) || !loading) return;
		setLoading(true);
		authState();
		isRoomHost(mainEventId)
			.then(async (res) => {
				setIsHost(res.data.isHost);
				setIsSeller(res.data.isHost);
				if (res.data.isHost) {
					sellerHasNoLiveEventInProgress(mainEventId).catch((err) => {
						showToastError(err);
						navigate('/events');
					});
				}

				await eventStopped(true);

				getEventDetails(mainEventId, true, false)
					.then((eventDetailRes) => {
						if (eventDetailRes.data.length > 0) {
							setLinkedEvents(
								eventDetailRes.data.filter(
									(e) => e.event.eventId != mainEventId
										&& e.event.statusId != EVENT_STOPPED_STATUS
								)
							);
							const mainEvent = eventDetailRes.data.find(
								(e) => e.event.eventId === mainEventId
							);
							if (
								mainEvent
								&& mainEvent.event
								&& mainEvent.event.statusId == EVENT_STOPPED_STATUS
							) {
								setShowPolicyModal(false);
								setShowLiveEventFinishedModal(true);
							}
						}
						setEventDetails(eventDetailRes.data);
						setLoading(false);
					})
					.catch((err) => {
						const mute = err;
					});
			})
			.catch((err) => {
				const tempTries = 0;
				const hasBeenStarted = false;

				getEventDetails(mainEventId, true, false)
					.then((res) => {
						if (res.data.length > 0) {
							setLinkedEvents(
								res.data.filter(
									(e) => e.event.eventId != mainEventId
										&& e.event.statusId != EVENT_STOPPED_STATUS
								)
							);
							const mainEvent = res.data.find(
								(e) => e.event.eventId === mainEventId
							);
							if (
								mainEvent
								&& mainEvent.event
								&& mainEvent.event.statusId == EVENT_STOPPED_STATUS
							) {
								setShowPolicyModal(false);
								setShowLiveEventFinishedModal(true);
							} else {
								setShowPolicyModal(true);
							}
						}
						setEventDetails(res.data);
						setLoading(false);
					})
					.catch((eventDetailErr) => {
						const mute = eventDetailErr;
					});

				setLoading(false);
			})
			.finally(() => {
				setIsFetchingHostStatus(false);
			});
	}, [showPolicyModal, isEligible, mainEventId]);

	const eventStopped = async (quit) => {
		try {
			const stoppedEventRes = await isEventStopped(mainEventId);
			if (stoppedEventRes.data && stoppedEventRes.data === true) {
				if (quit) {
					navigate(`/event/${mainEventId}`);
					return;
				}
			}
			const loggedIn = isLoggedIn();
			if (
				((quit && isHost) === false && loggedIn === false)
				|| (!joinEvent && isLoggedIn() === false)
			) {
				if (window.location) {
					window.location.href = `${window.location.origin}/events`;
				} else {
					navigate('/events');
				}
			}
		} catch (err) {
			const loggedIn = isLoggedIn();
			if (
				((quit && isHost) === false && loggedIn === false)
				|| (!joinEvent && isLoggedIn() === false)
			) {
				if (window.location) {
					window.location.href = `${window.location.origin}/events`;
				} else {
					navigate('/events');
				}
			}
		}
	};

	const authState = useCallback(() => {
		if (!isLoggedIn()) {
			const cachedNickname = localStorage.getItem(
				`event-nickname-${mainEventId}`
			);
			if (cachedNickname && cachedNickname.length > 0) {
				// setNickname(cachedNickname);
				nicknameRef.current = cachedNickname;
				setJoinEvent(true);
			}
		} else {
			setJoinEvent(true);
		}
	}, [mainEventId]);

	useEffect(() => {
		redirectToDefaultBrowserIfInsideWebViewAndroid();
		dispatch(hideProductSavedNotification());

		return () => {
			if (roomRef.current && roomRef.current.disconnect) {
				roomRef.current.disconnect();
			}
		};
	}, [window]);

	if ((loading || !isEligible) && !showUnsupportedBrowserModal) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}

	return (
		<Beforeunload
			onBeforeunload={(e) => {
				if (exitingByLeaveButtonRef.current === true) return null;
				e = e || window.event;
				if (e) {
					e.returnValue = 'Djawn';
				}
				return 'Djawn';
			}}
		>
			<SoundNotificationProvider
				isSeller={isHost}
				isMuted={isHost && isSafari && isMobile ? false : !hasSound}
			>
				<ToastContainer />
				<UnsupportedBrowserModal
					showModal={showUnsupportedBrowserModal}
					onClose={() => setShowUnsupportedBrowserModal(false)}
					eventId={mainEventId}
				/>

				<LiveEventFinishedModal
					showModal={showLiveEventFinishedModal}
					eventId={mainEventId}
					isHost={isHost}
					hostParticipantRejoining={hostParticipantRejoining}
				/>
				<RoomPolicyConfirmation
					showModal={showPolicyModal && !isFetchingHostStatus && !loading}
					isHost={isHost}
					setShowModal={setShowPolicyModal}
					eventId={mainEventId}
					showEventDetailsButton={showEventDetailsButton}
					onConfirm={() => {
						setShowPolicyModal(false);
					}}
					onCancel={async () => {
						if (isHost && cancelEventOnClose) {
							try {
								await cancelEvent(params.eventId, false);
							} catch (err) {
								console.error(err);
							}
						}
						if (isHost && (cancelEventOnClose || stopNavigatorDevicesOnClose)) {
							await stopNavigatorDevices();
							navigate(`/event/${params.eventId}`);
							return;
						}
						navigate('/events');
					}}
				/>
				{isEligible && !showPolicyModal && !showLiveEventFinishedModal && (
					<LiveEventDataProvider
						webSocketGroupName={
							eventDetails
							&& eventDetails.length
							&& eventDetails[0].event.webSocketChannelGroupName
						}
						eventId={mainEventId}
						initialLinkedEvents={linkedEvents}
						isSeller={isSeller}
						initialEventDetails={eventDetails}
						authenticated={userId != null}
					>
						<LiveEventViewsContainerProvider>
							<LiveEventChatProvider eventId={mainEventId} userId={userId}>
								<Room
									nicknameRef={nicknameRef}
									isRoomHost={isHost}
									exitingByLeaveButtonRef={exitingByLeaveButtonRef}
									isSeller={isSeller}
									hasSound={hasSound}
									setIsRoomHost={setIsHost}
									setHasSound={setHasSound}
									roomRef={roomRef}
									hostParticipantRejoining={hostParticipantRejoining}
								/>
							</LiveEventChatProvider>
						</LiveEventViewsContainerProvider>
					</LiveEventDataProvider>
				)}
			</SoundNotificationProvider>
		</Beforeunload>
	);
};

export default connect(
	(state) => ({
		userId: state.utils.userId
	}),
	null
)(RoomPage);
