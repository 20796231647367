/* eslint-disable no-restricted-globals */
import React, { useCallback, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { css } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import BounceLoader from 'react-spinners/BounceLoader';
import { navigate } from 'gatsby';
import HeaderHome from '../../../../components/common/header-home';
import Layout from '../../../../components/layouts/layout-default';
import Button from '../../../../components/button';
import {
	getLabelUrl,
	getShipmentTotal
} from '../../../../api/shipment.request';
import { openPopupWindow } from '../../../../utils/window';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const ShipmentLabelDetailsPage = ({ location }) => {
	const [loading, setLoading] = useState(true);
	const [shipmentTransactions, setShipmentTransactions] = useState([]);
	const [labels, setLabels] = useState([]);
	const [total, setTotal] = useState(0);

	useEffect(() => {
		if (!location.state) return;
		const fetchTotalAndTransactions = async () => {
			setShipmentTransactions(location.state.transactions);
			if (location.state.total) {
				setTotal(location.state.total);
			} else if (
				location.state.transactions.length
				&& location.state.transactions[0].transactionId
			) {
				try {
					const totalRes = await getShipmentTotal(
						location.state.transactions[0].transactionId
					);
					setTotal(totalRes.data);
				} catch (err) {
					console.error(err);
				}
			}
			setLoading(false);
		};

		fetchTotalAndTransactions();
	}, [location.state]);

	const fetchLabelUrls = useCallback(async (transactions, onFetched) => {
		try {
			const urls = await Promise.all(
				transactions.map(async (transaction) => {
					try {
						const urlRes = await getLabelUrl(transaction.transactionId);
						return {
							...transaction,
							url: urlRes.data
						};
					} catch (err) {
						console.error(err);
						return {
							...transaction,
							url: ''
						};
					}
				})
			);

			setLabels(urls);
			if (onFetched) {
				onFetched(urls);
			}
			return urls;
		} catch (error) {
			console.log(error);
			return [];
		}
	}, []);

	if (loading) {
		return (
			<div className="flex h-full items-center">
				<BounceLoader loading css={override} size={30} />
			</div>
		);
	}

	return (
		<Layout auth>
			<ReactTooltip />
			<div className="flex flex-col flex-1 bg-white w-full">
				<HeaderHome hideSearch setLoading={setLoading} />
				<div className="flex flex-col flex-grow w-full h-full m-4 items-center">
					<ToastContainer />
					{/* <ConfirmationModal
						showModal={showCreateLabelConfirmationModal}
						setShowModal={setShowCreateLabelConfirmationModal}
						onCancel={() => setShowCreateLabelConfirmationModal(false)}
						onConfirm={() => onCreateLabel()}
						text="Label will be created. Do you want to continue?"
						confirmText="Yes"
						cancelText="No"
						showConfirmationIcon={false}
					/> */}
					<div className="flex flex-col rounded-lg content-center justify-center w-full">
						<div className="flex flex-col p-16">
							<div>
								<h3>
									{shipmentTransactions.length}
									{' '}
									{`label ${
										shipmentTransactions.length > 1 ? 's have' : 'has'
									} been succesfully created.`}
								</h3>
								<div className="flex py-24 space-x-6">
									<Button
										additionalClassName="flex items-center justify-center px-4"
										height="16"
										width="full"
										mobileHeight="8"
										onClick={async () => {
											let urls = [];
											if (!labels || !labels.length) {
												urls = await fetchLabelUrls(shipmentTransactions);
											} else {
												urls = labels;
											}

											const stringifiedLabels = urls.map((label) => JSON.stringify(label));

											let urlsEncodedParams = stringifiedLabels.map(
												encodeURIComponent
											);
											urlsEncodedParams = `?urls[]=${urlsEncodedParams.join(
												'&urls[]='
											)}`;
											openPopupWindow(
												`${window.origin}/shipment/label/print${urlsEncodedParams}`,
												'Labels',
												1200,
												1100
											);
										}}
									>
										Print label
									</Button>
									<Button
										additionalClassName="flex items-center justify-center px-4"
										height="16"
										width="full"
										mobileHeight="8"
										onClick={() => navigate('/shipments')}
									>
										Go back to shipment view
									</Button>
									<Button
										additionalClassName="flex items-center justify-center px-4"
										height="16"
										width="full"
										mobileHeight="8"
									>
										Cancel label
									</Button>
								</div>
							</div>
							<hr className="pb-12" />
							<div>
								<div className="border border-gray-300 p-4 h-full shadow-md rounded-md">
									<h4 className="pb-4">Billing Summary</h4>
									<div className="flex flex-col justify-between">
										<div className="inline-flex space-x-2 w-full">
											<p className="font-bold">Total: </p>
											<p>
$
												{total}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ShipmentLabelDetailsPage;
