/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, {
	useState, useEffect, useContext, useCallback, useRef, useMemo
} from 'react';
import { navigate } from 'gatsby';
import {
	isMobile as isMobileSize, isAndroid, isTablet, isMobile
} from 'react-device-detect';
import moment from 'moment';
import firebase from 'gatsby-plugin-firebase';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Participant from './participant';
import {
	joinRipNShipQueue, endTurnRequest, initiateEndTurnRequest, exitQueuePosition,
	getRipNShipEventDetail, hasUserPurchasedItemsInEvent,
	updateRoomStatusToStarted,
	isRipNShipQueueClosed,
	closeRipNShipQueue,
	shouldShowKeepItemsOptionsInRipNShip,
	openRipNShipQueueLine,
	isEventStopped,
	approveMicRequestByBuyer,
	getEventDetails,
	updateNewLiveEventHostParticipant,
	getCurrentLiveEventHostIdentity,
	excludeIdentityFromLiveEventChatNotifications,
	resolveMicConversationMessage,
} from '../../api/event.request';
import CameraChangeModal from '../room/camera-change-modal';
import { formatUTCDateToLocal } from '../../utils/formatter';
import { createHubConnection } from '../../utils/hubConnection';
import StoreModal from '../common/store-modal';
import EventQueue from '../room/event-queue';
import ProductModalEventQueue from '../room/product-modal-event-queue';
import { getCurrentUser } from '../../utils/auth';
import PurchasedProductsModal from '../room/purchased-products-modal';
import LiveEventActionsModal from '../room/live-event-actions-modal';
import { defaultToastError } from '../../utils/toast-error';
import BuyerRipNShipExitConfirmation from '../buyer-ripnship-exit-confirmation';
import SellerRipNShipExitConfirmation from '../seller-ripnship-exit-confirmation';
import { dontShowAgainToast } from '../../utils/dont-show-again-toast';
import { informationToast } from '../../utils/information-toast';
import CloseLineModal from '../../containers/event/room/close-line-modal';
import { isTruthy } from '../../utils/commonFunction';
import useErrorNotifier from '../../hooks/use-error-notifier';
import { ProductsProvider } from '../../contexts/products';
import EventProductsRemove from '../../containers/event/event-products-remove';
import AddedProductModal from '../../containers/event/room/added-product-modal';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import { TwilioTrackContext } from '../../contexts/twilio-track';
import { SoundNotificationContext } from '../../contexts/sound-notification';
import { NOTIFY_MIC_REQUEST, NOTIFY_PRODUCT_TRIGGER } from '../../utils/constants/webSocketNameConstants';
import LinkedEventsSliderContainer from '../../containers/event/room/linked-events-slider-container';
import { EVENT_STOPPED_STATUS, RIP_N_SHIP } from '../../utils/constants';
import { LiveEventViewsContainerContext } from '../../contexts/live-event-views-container';
import { RipNShipActiveLineContext } from '../../contexts/ripnship-activeline';
import useCartItems from '../../hooks/useCartItems';
import { addCartItem, setBuyNowData } from '../../state/action';
import useVideoRoom from '../../hooks/useVideoRoom';
import HostDisconnectedFromLiveEventModal from '../../containers/event/room/host-disconnected-from-live-event-modal';
import useNetwork from '../../hooks/useNetwork';
import useStateRef from '../../hooks/useStateRef';
import DesktopResizableViewsContainer from '../../containers/event/room/desktop-resizable-views-container';
import StreamSettingsModal from '../../containers/event/room/stream-settings-modal';

// TODO: Refactor:: too many props
const VideoStream = ({
	dispatch,
	exitingByLeaveButtonRef,
	additionalEndTimeInSeconds,
	room,
	setSearchInputActive,
	landscapeMode,
	isSeller,
	eventDescription,
	showThankYou,
	isStopped,
	onTwilioVideoReconnect,
	children,
	exitEventRoom,
	setRoom,
	setVideoHeight,
	changeProductQuantityToCart,
	addProductToCart,
	cartItems,
	saveTrack,
	resize,
	setResize,
	setLeftArrowButtonPosition,
	setTopArrowButtonPosition,
	removeChildrenContainerHeight,
	onOrientationChange,
	onEventStopped,
	eventStopped,
	roomRef,
	purchasedProductsUserId,
	setPurchasedProductsUserId,
	showEventNameInChatContainer,
	setShowEventNameInChatContainer,
	showLinkedEventsSliderInMobileChatContainer,
	setShowLinkedEventsSliderInMobileChatContainer,
	onChatHeightRecalalculate,
	setIsRoomHost
}) => {
	const [cam, setCam] = useState(true);
	const [showCameraChange, setShowCameraChange] = useState(false);
	const [isInRipnShipQueueActiveLine, setIsInRipnShipQueueActiveLine] = useState(null);
	const [deviceId, setDeviceId] = useState(null);
	const [microphoneDeviceId, setMicrophoneDeviceId] = useState(null);
	const [speakerDeviceId, setSpeakerDeviceId] = useState(null);
	const [hubConnection, setHubConnection] = useState(null);
	const [notifyMicRequestMessage, setNotifyMicRequestMessage] = useState(
		null
	);
	const [changeWsMessage, setChangeWsMessage] = useState(null);
	const [swapChangeWsMessage, setSwapChangeWsMessage] = useState(null);
	const [
		initiatedEndTurnsWsMessage,
		setInitiatedEndTurnsWsMessage
	] = useState(null);
	const [videoDisabled, setVideoDisabled, videoDisabledRef] = useStateRef(false);
	const [endTurnWsMessage, setEndTurnWsMessage] = useState(null);
	const [exitWsMessage, setExitWsMessage] = useState(null);
	const [newBuyersWsMessage, setNewBuyersWsMessage] = useState(null);
	const [showHostDisconnectedModal, setShowHostDisconnectedModal] = useState(false);
	const [quantityUpdateWsMessage, setQuantityUpdateWsMessage] = useState(null);
	const [showProductModal, setShowProductModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [bannedUserWsMessage, setBannedUserWsMessage] = useState(null);
	const [trigger, setTrigger] = useState(false);
	const [showPurchasedItemsModal, setShowPurchasedItemsModal] = useState(false);
	const [updatedBuyersQueueWsMessage, setUpdatedBuyersQueueWsMessage] = useState(null);
	const [showRipNShipModal, setShowRipNShipModal] = useState(false);
	const [showBuyerExitConfirmationModal, setShowBuyerExitConfirmationModal] = useState(false);
	const [showSellerExitConfirmationModal, setShowSellerExitConfirmationModal] = useState(false);
	const [buyerExitId, setBuyerExitId] = useState(null);
	const [queueOrderId, setQueueOrderId] = useState(null);
	const [isStoreModalOpen, setIsStoreModalOpen] = useState(false);
	const [wsTriggeredWsMessage, setWsTriggeredWsMessage] = useState(null);
	const [isEventInformationReinitiating, setIsEventInformationReinitiating] = useState(false);
	const [cameraIndex, setCameraIndex] = useState(-1);
	const [showCloseLineModal, setShowCloseLineModal] = useState(false);
	const [showEndEventModal, setShowEndEventModal] = useState(false);
	const [futureEventDate, setFutureEventDate] = useState(null);
	const [closeLineTime, setCloseLineTime] = useState(null);
	const [eventStoppedWsMessage, setEventStoppedWsMessage] = useState(null);
	const [attendeesChangedWsMessage, setAttendeesChangedWsMessage] = useState(null);
	const [lastRefreshedDateTime, setLastRefreshedDateTime] = useState(null);
	const { showToastError } = useErrorNotifier();
	const {
		eventId, attendees, setAttendees, loadTeamsRandomization, isRipNShip,
		webSocketGroupName, mainEventDetails, setEventDetails, setLinkedEvents, ripNShipEvent, ripNShipEventIdRef,
		authenticated, eventName, loadRipNShipProducts, products, setProducts, productsInitiallyLoaded, setProductsInitiallyLoaded, eventDetails,
		generateAttendeesByParticipants, setTwilioParticipants, presenterIdentityRef,
		twilioParticipantsRef, setIsHost, isHost, isHostRef, hostingTheStream, hostingTheStreamRef, setHostingTheStream
	} = useContext(LiveEventDataContext);
	const [rejoiningTheStream, setRejoiningTheStream] = useState(false);
	const [showStreamSettingsModal, setShowStreamSettingsModal] = useState(false);
	const [streamSettings, setStreamSettings] = useState([]);

	const {
		setLocalAudioTracks,
		twilioParticipantId,
		setTwilioParticipantId,
		setApprovedAudioParticipants,
		setNewAudioParticipant,
		setNewCanceledMicRequestAccess,
		setExpiredMicAccessMessage,
		micAccessApprovedByBuyer,
		setNewMutedAudioParticipants,
		audioParticipants,
		setAudioParticipants,
		closeMic,
		removeLocalMicAccess,
		removeAudioParticipantsFromApprovedAudioParticipants,
		twilioRoomIdentity,
		setTwilioRoomIdentity,
		twilioRoomIdentityRef,
		currentUserIsOnMic,
		requestedMicAccess
	} = useContext(TwilioTrackContext);
	const { playSoundNotificationSound } = useContext(SoundNotificationContext);
	const { openQueueContainer } = useContext(LiveEventViewsContainerContext);
	const {
		ripNShipActiveBuyersInLine,
		disableRipNShipFunctionality,
		setDisableRipNShipFunctionality,
		setUserWantsToKeepItemsWsMessage,
		setJoinedQueue,
		setEndingTurn
	} = useContext(RipNShipActiveLineContext);
	const { buyNow } = useCartItems([], true);
	const [newHostParticipantWsMessage, setNewHostParticipantWsMessage] = useState(null);

	const onNewParticipantAdded = useCallback((participant) => {
		setTwilioParticipants((prevParticipants) => {
			const newParticipants = [...prevParticipants];
			if (!newParticipants.find((p) => p.identity === participant.identity)) {
				newParticipants.push(participant);
			}
			return newParticipants;
		});
	}, []);

	const onNewParticipantRemoved = useCallback((participant) => {
		if (isSeller && !isHostRef.current && participant.identity == presenterIdentityRef.current) {
			setShowHostDisconnectedModal(true);
			// eslint-disable-next-line no-use-before-define
			setHostParticipant(null);
		}

		if (participant.identity === presenterIdentityRef.current) presenterIdentityRef.current = null;
		setTwilioParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
	}, [isSeller]);

	const onHostDisconnected = useCallback(() => {
		if (!isSeller) return;
		setShowHostDisconnectedModal(true);
	}, [isSeller]);

	const {
		audioTracks, videoTracks, hostParticipant, setHostParticipant,
		muted, setMuted, noParticipantHostingTheStream, noParticipantHostingTheStreamRef,
		hostParticipantAudioTracksRef, hostParticipantVideoTracksRef, setVideoTracks, setAudioTracks, oldHostIdentitiesRef, lastAudioTrackIdsBeforeChangeRef
	} = useVideoRoom(room, presenterIdentityRef.current, onNewParticipantAdded,
		onNewParticipantRemoved, onHostDisconnected);
	const reconnectedAfterNetworkIsOnlineRef = useRef(false);

	const isOnline = useNetwork(isHost, true, async () => {
		setShowHostDisconnectedModal(false);
		if (!isSeller) return;
		try {
			const currentHostIdentityRes = await getCurrentLiveEventHostIdentity(eventId);
			let reconnectThroughLiveEventAsHost = true;
			if (currentHostIdentityRes.data !== twilioRoomIdentity) {
				reconnectThroughLiveEventAsHost = false;
			}

			if (reconnectThroughLiveEventAsHost) {
				reconnectedAfterNetworkIsOnlineRef.current = true;
				// eslint-disable-next-line no-use-before-define
				onChangeHostParticipantSession('Reconnecting to the event', 'Successfully reconnnected to the event');
			} else {
				onTwilioVideoReconnect({
					pendingMessage: 'Reconnecting to the event',
					successMessage: 'Successfully reconnnected to the event',
				});
			}
			// eslint-disable-next-line no-use-before-define
			reloadAllEventDetails();
			setHubConnection(createHubConnection('event'));
		} catch (err) {
			console.error(err);
		} finally {
			setShowHostDisconnectedModal(false);
		}
	});

	const fetchAttendeesAndUpdateAudioParticipants = useCallback(() => {
		generateAttendeesByParticipants().then((newAttendees) => {
			if (!audioParticipants || !audioParticipants.length) return;
			setAudioParticipants((tempAudioParticipants) => tempAudioParticipants.filter((audioParticipant) => {
				const foundAttendee = newAttendees.find((attendee) => attendee.twilioParticipantId == audioParticipant.twilioParticipantId);
				return foundAttendee != null;
			}));
		}).catch((err) => {
			console.error(err);
		});
	}, [audioParticipants]);

	useEffect(() => {
		if (!bannedUserWsMessage) return;
		const user = getCurrentUser();
		const anonToken = localStorage.getItem(`anon-${eventId}`);
		fetchAttendeesAndUpdateAudioParticipants();
		if ((bannedUserWsMessage.userId && bannedUserWsMessage.userId.length > 0 && user.id == bannedUserWsMessage.userId)
			|| (bannedUserWsMessage.anonymousToken && bannedUserWsMessage.anonymousToken.length > 0 && anonToken == bannedUserWsMessage.anonymousToken)) {
			if (bannedUserWsMessage.error) {
				navigate('/events', {
					state: {
						toastError: bannedUserWsMessage.error
					}
				});
			} else {
				navigate('/events');
			}
		}
	}, [bannedUserWsMessage]);

	useEffect(() => {
		if (!newHostParticipantWsMessage || !twilioRoomIdentityRef.current) return;
		setShowHostDisconnectedModal(false);
		const oldIdentity = presenterIdentityRef.current;
		oldHostIdentitiesRef.current = [...oldHostIdentitiesRef.current, oldIdentity];
		presenterIdentityRef.current = newHostParticipantWsMessage;
		if (twilioParticipantsRef.current && twilioParticipantsRef.current.length) {
			setHostParticipant(twilioParticipantsRef.current.find((p) => p.identity === newHostParticipantWsMessage));
		}
		const isPreviousHost = isHost;
		const isNewEventHost = newHostParticipantWsMessage === twilioRoomIdentityRef.current;
		setIsHost(isNewEventHost);
		setIsRoomHost(isNewEventHost);
		isHostRef.current = isNewEventHost;

		setVideoTracks((oldVideoTracks) => {
			const oldHostVideoTracks = hostParticipantVideoTracksRef.current.filter((videoTrack) => videoTrack.identity == oldIdentity);
			if (!oldHostVideoTracks || !oldHostVideoTracks.length) return oldVideoTracks;
			return oldVideoTracks.filter((track) => oldHostVideoTracks.map((t) => t.trackSid).includes(track.sid));
		});
		setAudioTracks((oldAudioTracks) => {
			const oldHostAudioTracks = hostParticipantAudioTracksRef.current.filter((audioTrack) => audioTrack.identity == oldIdentity);
			if (!oldHostAudioTracks || !oldHostAudioTracks.length) return oldAudioTracks;
			return oldAudioTracks.filter((track) => oldHostAudioTracks.map((t) => t.trackSid).includes(track.sid));
		});
		if (isPreviousHost && !isNewEventHost) {
			roomRef.current.localParticipant.audioTracks.forEach((t) => {
				t.track.stop();
			});
			roomRef.current.localParticipant.videoTracks.forEach((t) => {
				t.track.stop();
			});

			hostingTheStreamRef.current = false;
			setHostingTheStream(false);
			informationToast('Your camera is activated in another device');
			setCameraIndex(-1);
			setDeviceId(null);
		} else if (isNewEventHost) {
			if (roomRef.current.localParticipant.audioTracks) {
				roomRef.current.localParticipant.audioTracks.forEach((trackPublication) => {
					if (muted) {
						trackPublication.track.disable();
					} else {
						trackPublication.track.enable();
					}
				});
			}

			roomRef.current.localParticipant.videoTracks.forEach((trackPublication) => {
				if (videoDisabledRef.current) {
					trackPublication.track.disable();
				} else {
					trackPublication.track.enable();
				}
			});
			setCam(!videoDisabledRef.current);

			if (reconnectedAfterNetworkIsOnlineRef.current === true) {
				setCam(false);
				reconnectedAfterNetworkIsOnlineRef.current = false;
			}
		}
	}, [newHostParticipantWsMessage, twilioRoomIdentity, isHost, muted]);

	const reconnectOnTwilioWithMic = useCallback(async () => {
		let oldTwilioParticipantId = null;
		try {
			await excludeIdentityFromLiveEventChatNotifications(eventId, twilioRoomIdentityRef.current);
		} catch (err) {
			console.error(err);
		}

		if (roomRef.current && roomRef.current.disconnect) {
			if (roomRef.current.localParticipant && roomRef.current.localParticipant.sid) oldTwilioParticipantId = roomRef.current.localParticipant.sid;
			roomRef.current.disconnect();
			setTwilioParticipants([]);
			twilioParticipantsRef.current = [];
		}
		const randomNumber = Math.floor(Math.random() * 100);
		const newTwilioRoomIdentity = `${twilioRoomIdentityRef.current}#$$#${randomNumber}`;
		onTwilioVideoReconnect({
			overrideRefreshCheck: true,
			identity: newTwilioRoomIdentity,
			audio: true,
			pendingMessage: 'Setting up the microphone...',
			successMessage: 'Your microphone has been connected to the event successfully.',
			onSuccess: () => {
				twilioRoomIdentityRef.current = newTwilioRoomIdentity;
				setTwilioRoomIdentity(newTwilioRoomIdentity);
			},
			onFailure: () => {
				if (oldTwilioParticipantId) {
					/*
						if we got here that means that the user probably blocked the mic access so we
						need to update the mic message to user declined the request
					*/
					resolveMicConversationMessage(eventId, twilioParticipantId, false)
						.catch((err) => {
							console.error(err);
						});
				}
				twilioRoomIdentityRef.current = newTwilioRoomIdentity;
				setTwilioRoomIdentity(newTwilioRoomIdentity);
				setTimeout(() => {
					closeMic([twilioParticipantId], false, false);
					removeLocalMicAccess();
				}, 2000);
			}
		});
	}, [eventId, twilioParticipantId]);

	useEffect(() => {
		if (!micAccessApprovedByBuyer) return;
		reconnectOnTwilioWithMic();
	}, [micAccessApprovedByBuyer]);

	const reloadAllEventDetails = useCallback(() => {
		getEventDetails(eventId, true, false).then((eventDetailRes) => {
			if (eventDetailRes.data.length > 0) {
				setLinkedEvents(eventDetailRes.data.filter((e) => e.event.eventId != eventId && e.event.statusId != EVENT_STOPPED_STATUS));
			}
			setEventDetails(eventDetailRes.data);
		});
	}, [eventId]);

	useEffect(() => {
		if (!openQueueContainer || !ripNShipEvent || (products && products.length)) return;
		if (productsInitiallyLoaded) return;
		setProductsInitiallyLoaded(true);
	}, [openQueueContainer, ripNShipEvent]);

	useEffect(() => {
		if (!attendeesChangedWsMessage) return;
		fetchAttendeesAndUpdateAudioParticipants();
		setAttendeesChangedWsMessage(null);
	}, [attendeesChangedWsMessage]);

	useEffect(() => {
		if (isHost || !eventStoppedWsMessage) return;
		if (onEventStopped) {
			onEventStopped(eventStoppedWsMessage.additionalEndTimeInSeconds, eventStoppedWsMessage.endedDueToTechnicalProblems);
		}
	}, [eventStoppedWsMessage]);

	const onEventEndingMethod = useCallback(() => {
		if (isRipNShip && ripNShipActiveBuyersInLine
				&& ripNShipActiveBuyersInLine.length && ripNShipActiveBuyersInLine.filter(((p) => p.userId != null && p.purchasedItem == true)).length > 1) {
			setShowRipNShipModal(false);
			setShowEndEventModal(true);
			setShowCloseLineModal(true);
		} else {
			setShowRipNShipModal(false);
			setShowEndEventModal(false);
			exitEventRoom();
		}
	}, [ripNShipActiveBuyersInLine, isRipNShip, isHost]);

	const onRipNShipLineClosed = () => {
		closeRipNShipQueue(ripNShipEventIdRef.current, false, false, webSocketGroupName).then(() => {
			setShowRipNShipModal(false);
			setShowCloseLineModal(false);
		}).catch((err) => {
			console.error(err);
		});
	};

	const updateRipNShipFunctionalityOnRipNShipLineClosed = (obj) => {
		const ripnshipKey = `event-${eventId}-ripnship`;
		const ripnshipStringified = sessionStorage.getItem(ripnshipKey);
		let ripnship = {};
		if (ripnshipStringified) {
			ripnship = JSON.parse(ripnshipStringified);
		}
		if (ripnship == null) {
			ripnship = {};
		}
		if (ripnship == null || ripnship.closeLine != obj.closeLine) {
			informationToast(obj.closeLine == true ? 'The seller has closed the line' : 'The seller has opened the line');
			ripnship.closeLine = obj.closeLine;

			sessionStorage.setItem(ripnshipKey, JSON.stringify(ripnship));
		}
		setDisableRipNShipFunctionality(obj.closeLine);
		if (obj.hasFutureEvent) {
			shouldShowKeepItemsOptionsInRipNShip(eventId).then((res) => {
				if (!res.data.showBuyerKeepItemsOption && !res.data.isSeller) return;
				if (obj.futureEventTime && res.data.showBuyerKeepItemsOption) {
					const date = formatUTCDateToLocal(obj.futureEventTime, 'DD-MMM-YYYY HH:mm').replace(/--/g, '-');
					setFutureEventDate(date);
				}

				if (obj.countdownTimerInSeconds && obj.timerStartTime && closeLineTime == null && (ripnship == null || !ripnship.endEventTimerStarted)) {
					ripnship.endEventTimerStarted = true;
					sessionStorage.setItem(ripnshipKey, JSON.stringify(ripnship));
					const timerObject = {
						countdownTimerStartTime: obj.timerStartTime,
						timerInSeconds: obj.countdownTimerInSeconds
					};
					setCloseLineTime(timerObject);
					setShowCloseLineModal(true);
				}
			}).catch((err) => {
				console.error(err);
			});
		}
	};

	useEffect(() => {
		if (!notifyMicRequestMessage) return;
		const tempAttendees = [...attendees].map((at) => {
			if (at.userId == notifyMicRequestMessage.buyerId) {
				at.micRequest = true;
			}
			return at;
		});
		setAttendees(tempAttendees);
		setNotifyMicRequestMessage(null);
	}, [notifyMicRequestMessage]);

	useEffect(() => {
		if (!wsTriggeredWsMessage) return;

		setEventDetails((tempEventDetails) => {
			const newEventDetails = tempEventDetails.map((e) => {
				if (e.event.eventTypeId === RIP_N_SHIP) {
					e.buyersQueue.map((b) => {
						if (b.userId == wsTriggeredWsMessage) {
							b.purchasedItem = true;
						}
						return b;
					});
				}
				return e;
			});
			return newEventDetails;
		});

		loadRipNShipProducts(0, false, false, true);
		setWsTriggeredWsMessage(null);
	}, [wsTriggeredWsMessage]);

	const joinQueue = useCallback(() => {
		if (!ripNShipEventIdRef.current) return;

		joinRipNShipQueue(ripNShipEventIdRef.current, webSocketGroupName)
			.then(() => {
				setJoinedQueue(true);
				dontShowAgainToast('Please complete purchases as quickly as possible in order to keep your place in line.', 'ACTIVE_LINE');
			})
			.catch((err) => {
				if (err && err.response) {
					defaultToastError(err.response.data.error);
				}
			});
	}, [webSocketGroupName]);

	useEffect(() => {
		const swapJson = JSON.stringify(swapChangeWsMessage);
		const changeJson = JSON.stringify(changeWsMessage);
		if (swapJson != changeJson) {
			setChangeWsMessage(swapChangeWsMessage);
		}
	}, [swapChangeWsMessage]);

	const initiateEndTurn = useCallback(() => {
		initiateEndTurnRequest(ripNShipEventIdRef.current, webSocketGroupName)
			.catch((err) => {
				console.error(err);
			}).finally(() => {
				setShowRipNShipModal(false);
			});
	}, [webSocketGroupName]);

	const endQueueActiveTurn = useCallback((position, userId, linkedEventId) => {
		setEndingTurn(true);
		endTurnRequest(ripNShipEventIdRef.current, userId, position, linkedEventId, webSocketGroupName)
			.catch((err) => {
				if (err.response && err.response.data.error) {
					defaultToastError(err.response.data.error);
				}
			}).finally(() => {
				setEndingTurn(false);
				setShowRipNShipModal(false);
			});
	}, [webSocketGroupName]);

	useEffect(() => {
		if (!quantityUpdateWsMessage || !isRipNShip) return;

		setProducts((oldProducts) => {
			const newProducts = oldProducts.map((product) => {
				if (product.eventProductId == quantityUpdateWsMessage.eventProductId) {
					product.reservedQuantityByBuyers += quantityUpdateWsMessage.quantity;
					product.product.totalAvailableStock -= quantityUpdateWsMessage.quantity;
				}
				return product;
			});

			return newProducts;
		});
		setTrigger(!trigger);
	}, [quantityUpdateWsMessage]);

	const onRefreshButtonClicked = useCallback(async () => {
		if (requestedMicAccess || currentUserIsOnMic()) return;
		if (lastRefreshedDateTime) {
			const differenceBetweenCurrentDateAndLastRefreshedDateTime = moment().diff(lastRefreshedDateTime, 'seconds');
			if (differenceBetweenCurrentDateAndLastRefreshedDateTime < 60) return;
		}
		try {
			await excludeIdentityFromLiveEventChatNotifications(eventId, twilioRoomIdentityRef.current);
		} catch (err) {
			console.error(err);
		}

		if (roomRef.current && roomRef.current.disconnect) {
			roomRef.current.disconnect();
			setTwilioParticipants([]);
			twilioParticipantsRef.current = [];
		}
		const randomNumber = Math.floor(Math.random() * 100);
		const newTwilioRoomIdentity = `${twilioRoomIdentityRef.current}#$$#${randomNumber}`;
		onTwilioVideoReconnect({
			pendingMessage: 'Refreshing the video',
			successMessage: 'Video refreshed successfully',
			overrideRefreshCheck: true,
			identity: newTwilioRoomIdentity,
			onSuccess: () => {
				setTwilioRoomIdentity(newTwilioRoomIdentity);
				twilioRoomIdentityRef.current = newTwilioRoomIdentity;
			}
		});
		reloadAllEventDetails();
		setHubConnection(createHubConnection('event'));

		setLastRefreshedDateTime(moment());
	}, [eventId, requestedMicAccess, lastRefreshedDateTime, onTwilioVideoReconnect, twilioParticipantId, audioParticipants]);

	useEffect(() => {
		if (additionalEndTimeInSeconds == 0) return;
		if (showThankYou === true && isSeller === false) {
			setTimeout(() => {
				if (typeof window !== 'undefined') {
					isEventStopped(eventId).then((res) => {
						if (res.data && res.data.stopped === true && res.data.additionalEndTimeInSeconds && res.data.additionalEndTimeInSeconds > 0) {
							if (eventId === res.data.eventId) {
								if (window.location) {
									window.location.href = `${window.location.origin}/events`;
								} else {
									navigate('/events');
								}
							}
						}
					}).catch((err) => {
						console.error(err);
					});
				}
			}, additionalEndTimeInSeconds * 1000);
		}
	}, [showThankYou, additionalEndTimeInSeconds]);

	useEffect(() => {
		if (ripNShipEventIdRef.current) {
			isRipNShipQueueClosed(ripNShipEventIdRef.current)
				.then((res) => {
					if (res.data && res.data.noRipNShipAtAll === false) {
						updateRipNShipFunctionalityOnRipNShipLineClosed(res.data);
					} else {
						setDisableRipNShipFunctionality(false);
					}
				}).catch(() => {
					setDisableRipNShipFunctionality(false);
				});
		}

		const onVisibilityChangeListener = () => {
			if (document.visibilityState === 'visible') {
				firebase.database().goOffline();
				firebase.database().goOnline();
				// setTimeout is a hack for loading the stopped status
				setTimeout(() => {
					eventStopped(false, () => {
						if (onTwilioVideoReconnect && !isHost) {
							const randomNumber = Math.floor(Math.random() * 100);
							const newTwilioRoomIdentity = `${twilioRoomIdentityRef.current}#$$#${randomNumber}`;
							onTwilioVideoReconnect({
								identity: newTwilioRoomIdentity,
								overrideRefreshCheck: isMobile && noParticipantHostingTheStreamRef.current,
								onBeforeReconnect: async () => {
									if (roomRef.current && roomRef.current.disconnect) {
										roomRef.current.disconnect();
										setTwilioParticipants([]);
										twilioParticipantsRef.current = [];
									}
									try {
										await excludeIdentityFromLiveEventChatNotifications(eventId, twilioRoomIdentityRef.current);
									} catch (err) {
										console.error(err);
									}
								},
								onSuccess: () => {
									setTwilioRoomIdentity(newTwilioRoomIdentity);
									twilioRoomIdentityRef.current = newTwilioRoomIdentity;
								}
							});
							fetchAttendeesAndUpdateAudioParticipants();
						}
					});
				}, 1500);

				reloadAllEventDetails();
				if (ripNShipEventIdRef.current) {
					isRipNShipQueueClosed(ripNShipEventIdRef.current)
						.then((res) => {
							if (res.data && res.data.noRipNShipAtAll === false) {
								updateRipNShipFunctionalityOnRipNShipLineClosed(res.data);
							} else {
								setDisableRipNShipFunctionality(false);
							}
						}).catch(() => {
							setDisableRipNShipFunctionality(false);
						});
				}

				setHubConnection(createHubConnection('event'));
			}
		};

		document.addEventListener('visibilitychange', onVisibilityChangeListener);
		return () => {
			setDisableRipNShipFunctionality(false);
			document.removeEventListener('visibilitychange', onVisibilityChangeListener);
		};
	}, [eventId]);

	const onLineClosedWsMessage = useCallback((wsMessage) => {
		if (!wsMessage) return;
		updateRipNShipFunctionalityOnRipNShipLineClosed(wsMessage);
	}, []);

	useEffect(() => {
		if (!hubConnection) return;
		hubConnection
			.start()
			.then((res) => {
				hubConnection.invoke('JoinEvent', webSocketGroupName);
			})
			.then((result) => {
				if (isSeller) {
					hubConnection.on('NotifyBuyerActiveLineWantsToKeepItems', (message) => {
						setUserWantsToKeepItemsWsMessage(message);
					});
				}

				hubConnection.on(NOTIFY_MIC_REQUEST, (message) => {
					playSoundNotificationSound(NOTIFY_MIC_REQUEST);
					setNotifyMicRequestMessage(message);
					fetchAttendeesAndUpdateAudioParticipants();
				});

				hubConnection.on('NotifyExpiredMicAccess', (message) => {
					setExpiredMicAccessMessage(message);
					fetchAttendeesAndUpdateAudioParticipants();
				});

				hubConnection.on('NotifyCancelledMicRequest', (message) => {
					setNewCanceledMicRequestAccess(message);
					fetchAttendeesAndUpdateAudioParticipants();
				});

				hubConnection.on('NotifyMicRequestDecision', (message) => {
					setApprovedAudioParticipants(message);
					fetchAttendeesAndUpdateAudioParticipants();
				});

				hubConnection.on('NotifyDeclinedMicRequestParticipant', (message) => {
					if (message) {
						removeAudioParticipantsFromApprovedAudioParticipants([message]);
						setAttendeesChangedWsMessage(message);
					}
				});

				hubConnection.on('NotifyMicRequestApprovedAndSubscribed', (message) => {
					fetchAttendeesAndUpdateAudioParticipants();
					setNewAudioParticipant(message);
				});

				hubConnection.on('NotifyForJoinedBuyer', (message) => {
					setNewBuyersWsMessage(message);
				});

				hubConnection.on('NotifyIfQueueIsClosed', (message) => {
					onLineClosedWsMessage(message);
				});

				hubConnection.on('NotifyForMutedMicStatus', (message) => {
					setNewMutedAudioParticipants(message);
				});

				hubConnection.on(NOTIFY_PRODUCT_TRIGGER, (message) => {
					playSoundNotificationSound(NOTIFY_PRODUCT_TRIGGER);
					setWsTriggeredWsMessage(message);
				});

				hubConnection.on('NotifyForReservedProducts', (message) => {
					setQuantityUpdateWsMessage(message);
				});

				hubConnection.on('NotifyIfUserGotBanned', (message) => {
					setBannedUserWsMessage(message);
				});

				hubConnection.on('NotifyForInitiatedEndTurn', (message) => {
					setInitiatedEndTurnsWsMessage(message);
				});

				hubConnection.on('NotifyNewPositionsAfterUpdate', (message) => {
					setUpdatedBuyersQueueWsMessage(message);
				});

				hubConnection.on('NotifyEventStoppedStatus', (message) => {
					setEventStoppedWsMessage(message);
				});

				hubConnection.on('NotifyEventAttendeesChanged', (message) => {
					setAttendeesChangedWsMessage(message);
				});

				if (!isSeller) {
					hubConnection.on('NotifyForPositionSwap', (message) => {
						setSwapChangeWsMessage(message);
					});
				}

				hubConnection.on('NotifyUsersForBreakRandomization', (message) => {
					loadTeamsRandomization();
				});

				hubConnection.on('ReceiveMessage', (message) => {
					if (message.type === 'feeds') {
						reloadAllEventDetails();
					}
				});

				hubConnection.on('NotifyForEventDetailsRefresh', (message) => {
					reloadAllEventDetails();
				});

				hubConnection.on('NotifyForNewHostParticipant', (newIdentity) => {
					setNewHostParticipantWsMessage(newIdentity);
				});

				hubConnection.on(
					'NotifyNewPositionsAfterEndTurn',
					(message) => {
						setEndTurnWsMessage(message);
					}
				);
				hubConnection.on(
					'NotifyUsersToRefreshProducts',
					(message) => {
						loadRipNShipProducts(0);
					}
				);

				hubConnection.on(
					'NotifyNewPositionsAfterExitTurn',
					(message) => {
						setExitWsMessage(message);
					}
				);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			if (hubConnection && hubConnection.stop) {
				hubConnection.stop().catch((err) => {
					console.error(err);
				});
			}
		};
	}, [hubConnection]);

	const changeCamera = useCallback((camera) => {
		setDeviceId(camera.deviceId);
	}, []);

	const reinitiateEventInformation = useCallback(() => {
		if (!ripNShipEventIdRef.current) return;
		setIsEventInformationReinitiating(true);
		getRipNShipEventDetail(ripNShipEventIdRef.current).then((res) => {
			setEventDetails((tempEventDetails) => {
				const newEventDetails = tempEventDetails.map((e) => {
					if (e.event.eventTypeId == RIP_N_SHIP) {
						e.buyersQueue = res.data.buyersQueue;
						e.buyersHistoryQueue = res.data.buyersHistoryQueue;
					}
					return e;
				});
				return newEventDetails;
			});
			setIsEventInformationReinitiating(false);
		});
	}, []);

	useEffect(() => {
		if (!room) return;
		if (!room.localParticipant) return;
		if (!isHost) {
			setLocalAudioTracks(room.localParticipant.audioTracks);
		}

		if (!hubConnection && webSocketGroupName) {
			setHubConnection(createHubConnection('event'));
		}

		const oldTwilioParticipantId = twilioParticipantId;
		const newTwilioParticipantId = room.localParticipant.sid;

		setTwilioParticipantId(room.localParticipant.sid);

		// If we got here that means we have reconnected with turned on audio
		if (micAccessApprovedByBuyer != null && oldTwilioParticipantId != null) {
			if (room.localParticipant.audioTracks && room.localParticipant.audioTracks.size > 0) {
				approveMicRequestByBuyer(eventId, oldTwilioParticipantId, newTwilioParticipantId,
					room.localParticipant.audioTracks.entries().next().value[0], webSocketGroupName)
					.catch((err) => {
						console.warn(err);
					});
			}
		}

		if (isHost) {
			updateRoomStatusToStarted(eventId);
		}

		roomRef.current = room;
		setTwilioParticipants((prev) => {
			const newParticipants = [...prev];
			if (!newParticipants.find((p) => p.identity === room.localParticipant.identity)) {
				newParticipants.push(room.localParticipant);
			}
			return newParticipants;
		});

		return () => {
			setRoom(null);
		};
	}, [room]);

	const userCanResizeViews = useMemo(() => authenticated && !isMobile && openQueueContainer, [authenticated, isMobile, openQueueContainer]);

	const handleExitQueue = useCallback((userId = undefined, exitModal = false) => {
		if (exitModal == true) {
			exitQueuePosition(ripNShipEventIdRef.current, userId, webSocketGroupName)
				.catch((err) => { console.error(err); })
				.finally(() => {
					if (exitModal) {
						setShowBuyerExitConfirmationModal(false);
						setShowSellerExitConfirmationModal(false);
					}
					setJoinedQueue(false);
				});
		}
	}, [webSocketGroupName]);

	useEffect(() => {
		if (!purchasedProductsUserId) return;
		setShowPurchasedItemsModal(true);
	}, [purchasedProductsUserId]);

	useEffect(() => {
		if (!showPurchasedItemsModal) {
			setPurchasedProductsUserId(null);
		}
	}, [showPurchasedItemsModal]);

	const openPurchasedItemsModalByQueueOrderId = useCallback((selectedQueueOrderId) => {
		setQueueOrderId(selectedQueueOrderId);
		setShowPurchasedItemsModal(true);
	}, []);

	useEffect(() => {
		if (cameraIndex == -1) return;

		navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
			const devices = mediaDevices.filter((device) => device.kind == 'videoinput');
			if (devices.length > 1) {
				/* TODO: find a better solution for this, this is a hack for samsung devices
					because some samsung devices have more then 2 cameras and we dont know
					which one is the back or the front the only way to know is to check the label of the camera
				*/

				if (cameraIndex > 0 && devices.length > 2 && isMobile) {
					const backDevices = devices.filter((i) => i.label.toLowerCase().includes('back'));
					if (backDevices && backDevices.length) {
						const device = backDevices[backDevices.length - 1];
						if (device) {
							setDeviceId(device.deviceId);
						}
					}
				} else {
					setDeviceId(devices[cameraIndex].deviceId);
				}
			}
		});
	}, [cameraIndex, isMobile]);

	useEffect(() => {
		if (isMobileSize) {
			document.body.style.overflowY = 'hidden';
		}
	}, [isMobileSize]);

	const nextUpOrEndTurn = useCallback(() => {
		endQueueActiveTurn(ripNShipEvent.buyersQueue[0].position, ripNShipEvent.buyersQueue[0].userId, ripNShipEvent.buyersQueue[0].linkedEventId);
	}, [JSON.stringify(ripNShipEvent)]);

	const cameraOnChangeClicked = useCallback(() => {
		if (cameraIndex == -1) {
			setCameraIndex(0);
		} else if (cameraIndex == 0) {
			setCameraIndex(1);
		} else if (cameraIndex == 1) {
			setCameraIndex(0);
		}
	}, [cameraIndex]);

	useEffect(() => {
		if (!isSeller) return;
		setShowHostDisconnectedModal(!hostParticipant && !hostingTheStream && !presenterIdentityRef.current);
	}, [isSeller, hostParticipant, hostingTheStream]);

	const onChangeHostParticipantSession = useCallback(
		(pendingMessage = 'Setting up the video and microphone...',
			successMessage = 'You are now hosting the stream!') => {
			setRejoiningTheStream(true);
			hostingTheStreamRef.current = true;
			setHostingTheStream(true);
			if (showHostDisconnectedModal) setShowHostDisconnectedModal(false);
			const randomNumber = Math.floor(Math.random() * 100);
			const newTwilioRoomIdentity = `${twilioRoomIdentityRef.current}#$$#${randomNumber}`;
			setTwilioRoomIdentity(null);
			twilioRoomIdentityRef.current = null;
			if (roomRef.current.disconnect) {
				roomRef.current.disconnect();
			}

			if (isMobile) {
				setMuted(true);
			}

			onTwilioVideoReconnect({
				audio: true,
				video: true,
				identity: newTwilioRoomIdentity,
				pendingMessage,
				successMessage,
				overrideRefreshCheck: true,
				onSuccess: () => {
					updateNewLiveEventHostParticipant(eventId, newTwilioRoomIdentity, webSocketGroupName).then(() => {
						setRejoiningTheStream(false);
						setTwilioRoomIdentity(newTwilioRoomIdentity);
						twilioRoomIdentityRef.current = newTwilioRoomIdentity;
						presenterIdentityRef.current = newTwilioRoomIdentity;

						if (isMobile) onChatHeightRecalalculate();
					}).catch((err) => {
						console.error(err);
					});
				},
				onFailure: () => {
					setRejoiningTheStream(false);
					hostingTheStreamRef.current = false;
					setHostingTheStream(false);
					setTwilioRoomIdentity(newTwilioRoomIdentity);
					twilioRoomIdentityRef.current = newTwilioRoomIdentity;
					if (isMobile) onChatHeightRecalalculate();
				}
			});
		}, [showHostDisconnectedModal, eventId, webSocketGroupName, isMobile]
	);

	return (
		<ProductsProvider>
			<div className={`${isMobileSize ? 'w-full' : ''} container-fluid md:block flex justify-center mx-auto px-1 md:px-12`} id="video-page">
				<div
					className={`flex flex-wrap -mx-1 lg:-mx-4 ${isMobileSize ? 'w-full' : ''} ${resize === true ? 'justify-center' : ''}`}
				>
					<div className={`w-full my-1 px-1 lg:my-4 lg:px-4 ${isTruthy(isAndroid) && 'flex justify-center'}`}>
						{selectedProduct && !showThankYou && (
							<ProductModalEventQueue
								landscape={isMobileSize && landscapeMode}
								showModal={showProductModal}
								setShowModal={setShowProductModal}
								addToCartEnabled={!disableRipNShipFunctionality}
								onAddToCart={(eventProduct, image, quantity) => {
									const existingEventProduct = cartItems.find((f) => f.id == eventProduct.eventProductId);
									if (existingEventProduct) {
										changeProductQuantityToCart(existingEventProduct, quantity);
									} else {
										addProductToCart(eventProduct, image, quantity);
									}
									setShowProductModal(false);
								}}
								buyNowEnabled={eventDetails && eventDetails.length && eventDetails[0].buyNowEnabled}
								onBuyNow={(eventProduct, image, quantity) => {
									let paymentIdToProcess = null;
									if (isMobile) {
										paymentIdToProcess = Date.now();
										window.open(
											`${window.location.origin}/events?openCart=true&buyNowPaymentIdToProcess=${paymentIdToProcess}`,
											'_blank'
										);
									}

									const existingEventProduct = cartItems.find(
										(f) => f.id == eventProduct.eventProductId
									);
									if (existingEventProduct) {
										changeProductQuantityToCart(
											existingEventProduct,
											quantity
										);
									} else {
										const foundEvent = eventDetails.find((e) => e.event.eventId == eventProduct.eventId);
										if (!foundEvent || !foundEvent.event) return;
										buyNow(foundEvent.event.eventId, foundEvent, { eventProduct, image, quantity }, () => {
										}, (item) => {
											dispatch(addCartItem(item));
										}, (newBuyNowData) => {
											dispatch(setBuyNowData(newBuyNowData));
										}, paymentIdToProcess);
									}
									setShowProductModal(false);
								}}
								eventProduct={selectedProduct}
								trigger={trigger}
								isLiveEvent
								authenticated={authenticated}
							/>
						)}

						{mainEventDetails && !showThankYou && <StoreModal isOpen={isStoreModalOpen} setIsOpen={setIsStoreModalOpen} storeInfo={mainEventDetails.seller.account} sellerImage={mainEventDetails.seller.sellerImage} />}
						{(purchasedProductsUserId || queueOrderId) && (
							<PurchasedProductsModal
								userId={purchasedProductsUserId}
								showModal={showPurchasedItemsModal}
								setShowModal={setShowPurchasedItemsModal}
								queueOrderId={queueOrderId}
								setQueueOrderId={setQueueOrderId}
							/>
						)}

						{!isMobile && (
							<StreamSettingsModal
								showModal={showStreamSettingsModal}
								setShowModal={() => setShowStreamSettingsModal(false)}
								setSettings={setStreamSettings}
								settings={streamSettings}
								onSave={(settings) => {
									if (settings && settings.length > 0) {
										const cameraSettings = settings.find((s) => s.type == 'camera');
										if (cameraSettings && cameraSettings.deviceId) {
											setDeviceId(cameraSettings.deviceId);
										}
										const microphoneSettings = settings.find((s) => s.type == 'microphone');
										if (microphoneSettings && microphoneSettings.deviceId) {
											setMicrophoneDeviceId(microphoneSettings.deviceId);
										}
										const speakerSettings = settings.find((s) => s.type == 'speaker');
										if (speakerSettings && speakerSettings.deviceId) {
											setSpeakerDeviceId(speakerSettings.deviceId);
										}
									}
								}}
							/>
						)}

						{isSeller && (
							<>
								<EventProductsRemove />
								<AddedProductModal />
								{(isOnline && !showThankYou && !exitingByLeaveButtonRef.current && !rejoiningTheStream) && (
									<HostDisconnectedFromLiveEventModal
										showModal={showHostDisconnectedModal}
										onClose={() => setShowHostDisconnectedModal(false)}
										onRejoinEvent={() => {
											setShowHostDisconnectedModal(false);
											onChangeHostParticipantSession();
										}}
									/>
								)}
							</>
						)}
						{(isHost || isRipNShip) && (
							<>
								<LiveEventActionsModal
									showModal={showRipNShipModal}
									setShowModal={setShowRipNShipModal}
									isSeller={isSeller}
									onEndTurn={nextUpOrEndTurn}
									joinQueue={joinQueue}
									isEventInformationReinitiating={isEventInformationReinitiating}
									setBuyerExitId={setBuyerExitId}
									onBuyerRemove={(buyer) => {
										setBuyerExitId(buyer);
										setShowRipNShipModal(false);
										hasUserPurchasedItemsInEvent(ripNShipEventIdRef.current, buyer).then((res) => {
											if (res.data == true) {
												setShowSellerExitConfirmationModal(true);
											} else {
												handleExitQueue(buyer, true);
											}
										});
									}}
									onExitLine={() => {
										setShowRipNShipModal(false);
										const buyer = ripNShipEvent.buyersQueue.find((b) => b.userId == getCurrentUser().id);
										if (buyer.purchasedItem) {
											showToastError('If you no longer want to keep these items and stay in the line, please request a refund');
										} else {
											hasUserPurchasedItemsInEvent(ripNShipEventIdRef.current, getCurrentUser().id).then((res) => {
												if (res.data == true) {
													setShowBuyerExitConfirmationModal(true);
												} else {
													handleExitQueue(getCurrentUser().id, true);
												}
											});
										}
									}}
									lineClosed={disableRipNShipFunctionality}
									initiateEndTurn={initiateEndTurn}
									onCloseLine={() => {
										setShowRipNShipModal(false);
										setShowEndEventModal(false);
										setShowCloseLineModal(true);
									}}
									onOpenLine={() => {
										setShowRipNShipModal(false);
										setShowEndEventModal(false);
										openRipNShipQueueLine(ripNShipEventIdRef.current, webSocketGroupName).catch((err) => {
											console.error(err);
										});
									}}
									isInLine={isInRipnShipQueueActiveLine}
									setIsInLine={setIsInRipnShipQueueActiveLine}
								/>
								<CloseLineModal
									isSeller={isSeller}
									showModal={showCloseLineModal}
									setShowModal={setShowCloseLineModal}
									showEndEventModal={showEndEventModal}
									setShowEndEventModal={setShowEndEventModal}
									timerInSeconds={(closeLineTime && closeLineTime.timerInSeconds) ? closeLineTime.timerInSeconds : null}
									countdownTimerStartTime={(closeLineTime && closeLineTime.countdownTimerStartTime) ? closeLineTime.countdownTimerStartTime : null}
									futureEventDate={futureEventDate}
									onEndEvent={(skipConfirmation = false) => {
										setShowCloseLineModal(false);
										exitEventRoom(true, skipConfirmation);
									}}
									onCancel={() => setShowCloseLineModal(false)}
									onLineClosed={onRipNShipLineClosed}
								/>
							</>
						)}

						<CameraChangeModal
							showModal={showCameraChange}
							changeCamera={changeCamera}
							setShowModal={setShowCameraChange}
						/>

						{ripNShipEvent && ripNShipEvent.buyersQueue && (
							<BuyerRipNShipExitConfirmation
								onExitLine={() => {
								}}
								showModal={showBuyerExitConfirmationModal}
								setShowModal={setShowBuyerExitConfirmationModal}
								buyers={ripNShipEvent.buyersQueue}
								isEventInformationReinitiating={isEventInformationReinitiating}
							/>
						)}

						{ripNShipEvent && ripNShipEvent.buyersQueue && (
							<SellerRipNShipExitConfirmation
								onExitLine={() => {
									if (buyerExitId) {
										handleExitQueue(buyerExitId, true);
									}
								}}
								showModal={showSellerExitConfirmationModal}
								buyerExitId={buyerExitId}
								setShowModal={setShowSellerExitConfirmationModal}
								buyers={ripNShipEvent.buyersQueue}
								isEventInformationReinitiating={isEventInformationReinitiating}
							/>
						)}

						<div className={`${isMobileSize ? 'w-full' : ''}  ${landscapeMode ? 'flex' : 'flex'} ${isTablet && 'justify-center'}`}>
							<DesktopResizableViewsContainer canResize={userCanResizeViews}>
								<div
									className={clsx(isMobileSize ? 'w-full flex justify-center' : (userCanResizeViews ? 'w-full h-full' : 'w-96 md:h-room-grid-item-height md:mt-2 mt-0'),
										!userCanResizeViews && 'bg-white border dark:border-black px-5 py-2 dark:bg-darkGray-50',
										!openQueueContainer ? 'hidden' : 'flex flex-col')}
									style={!userCanResizeViews ? { borderRadius: '25px' } : {}}
								>
									<EventQueue
										setSearchInputActive={setSearchInputActive}
										canResizeViews={userCanResizeViews}
										showProductModal={showProductModal}
										setShowProductModal={setShowProductModal}
										setSelectedProduct={setSelectedProduct}
										disableRipNShipFunctionality={disableRipNShipFunctionality}
										reinitiateEventInformation={reinitiateEventInformation}
										eventId={eventId}
										setShowPurchasedItemsModal={setShowPurchasedItemsModal}
										changeWsMessage={changeWsMessage}
										initiatedEndTurnsWsMessage={initiatedEndTurnsWsMessage}
										setInitiatedEndTurnsWsMessage={setInitiatedEndTurnsWsMessage}
										endTurnWsMessage={endTurnWsMessage}
										exitWsMessage={exitWsMessage}
										newBuyersWsMessage={newBuyersWsMessage}
										setPurchasedProductsUserId={setPurchasedProductsUserId}
										updatedBuyersQueueWsMessage={updatedBuyersQueueWsMessage}
										joinQueue={joinQueue}
										onEndTurn={() => endQueueActiveTurn(ripNShipEvent.buyersQueue[0].position, ripNShipEvent.buyersQueue[0].userId)}
										openPurchasedItemsModalByQueueOrderId={openPurchasedItemsModalByQueueOrderId}
										setShowQueueModal={setShowRipNShipModal}
										showSeller={() => setIsStoreModalOpen(true)}
										eventGeneralDetails={{ eventName, eventDescription }}
										fetchAttendeesAndUpdateAudioParticipants={fetchAttendeesAndUpdateAudioParticipants}
									/>
								</div>
							</DesktopResizableViewsContainer>

							<article
								className={`${isMobileSize ? 'w-full' : ''}  overflow-y-hidden md:overflow-y-auto rounded-lg container-fluid pb-3 ${isMobileSize && resize && ('w-screen h-screen')} ${openQueueContainer && (isMobileSize || isTablet) ? 'hidden' : 'block'}`}
							>
								<div
									className={`${isMobileSize ? 'w-full' : ''}  grid grid-cols-${((isMobileSize && !isTablet) || (isTablet && !landscapeMode) || resize === true) ? '1' : '3'}`}
								>
									<div
										id="video-ref-container"
										className={`row-span-1 md:col-span-2 ${isMobileSize ? 'w-full' : ''} `}
									>
										{room ? (
											isHost === true ? (
												<div className="local-participant">
													<Participant
														participant={
															room.localParticipant
														}
														hostParticipantAudioTracksRef={hostParticipantAudioTracksRef}
														cam={cam}
														roomRef={roomRef}
														setCam={setCam}
														audioTracks={audioTracks}
														videoTracks={videoTracks}
														isLocal
														videoDisabled={videoDisabled}
														setVideoDisabled={setVideoDisabled}
														resize={resize}
														isSeller={isSeller}
														muted={muted}
														setMuted={setMuted}
														lastAudioTrackIdsBeforeChangeRef={lastAudioTrackIdsBeforeChangeRef}
														saveTrack={saveTrack}
														cameraIndex={cameraIndex}
														setResize={setResize}
														isStopped={isStopped}
														speakerDeviceId={speakerDeviceId}
														deviceId={deviceId}
														microphoneDeviceId={microphoneDeviceId}
														onEndTurn={nextUpOrEndTurn}
														showEventNameInChatContainer={showEventNameInChatContainer}
														setShowEventNameInChatContainer={setShowEventNameInChatContainer}
														changeCameraOnClick={cameraOnChangeClicked}
														exitEventRoom={onEventEndingMethod}
														setVideoHeight={setVideoHeight}
														eventId={eventId}
														setLeftArrowButtonPosition={setLeftArrowButtonPosition}
														setTopArrowButtonPosition={setTopArrowButtonPosition}
														onOrientationChange={onOrientationChange}
														showLinkedEventsSliderInMobileChatContainer={showLinkedEventsSliderInMobileChatContainer}
														setShowLinkedEventsSliderInMobileChatContainer={setShowLinkedEventsSliderInMobileChatContainer}
														onSettingsClick={() => setShowStreamSettingsModal(true)}
													/>
												</div>
											) : hostParticipant
                                            && hostParticipant.sid ? (
													<div className="local-participant w-full h-full">
														<Participant
															noParticipantHostingTheStream={noParticipantHostingTheStream && !presenterIdentityRef.current}
															onRefreshButtonClicked={onRefreshButtonClicked}
															participant={
																hostParticipant
															}
															cam={cam}
															setCam={setCam}
															audioTracks={audioTracks}
															videoTracks={videoTracks}
															onChangeHostSession={onChangeHostParticipantSession}
															showThankYou={showThankYou}
															isSeller={isSeller}
															roomRef={roomRef}
															videoDisabled={videoDisabled}
															setVideoDisabled={setVideoDisabled}
															resize={resize}
															muted={muted}
															setMuted={setMuted}
															lastAudioTrackIdsBeforeChangeRef={lastAudioTrackIdsBeforeChangeRef}
															saveTrack={saveTrack}
															hostParticipantAudioTracksRef={hostParticipantAudioTracksRef}
															localParticipant={
																room.localParticipant
															}
															setResize={setResize}
															showEventNameInChatContainer={showEventNameInChatContainer}
															setShowEventNameInChatContainer={setShowEventNameInChatContainer}
															onEndTurn={nextUpOrEndTurn}
															exitEventRoom={() => {
																if (room.disconnect) {
																	room.disconnect();
																}
																exitEventRoom();
															}}
															setVideoHeight={setVideoHeight}
															eventId={eventId}
															setLeftArrowButtonPosition={setLeftArrowButtonPosition}
															setTopArrowButtonPosition={setTopArrowButtonPosition}
															onOrientationChange={onOrientationChange}
															showLinkedEventsSliderInMobileChatContainer={showLinkedEventsSliderInMobileChatContainer}
															setShowLinkedEventsSliderInMobileChatContainer={setShowLinkedEventsSliderInMobileChatContainer}
														/>
													</div>
												) : (
													<div className="local-participant w-full h-full">
														<Participant
															onRefreshButtonClicked={onRefreshButtonClicked}
															participant={
																room.localParticipant
															}
															noParticipantHostingTheStream={noParticipantHostingTheStream && !presenterIdentityRef.current}
															audioTracks={audioTracks}
															videoTracks={videoTracks}
															roomRef={roomRef}
															onChangeHostSession={onChangeHostParticipantSession}
															showThankYou={showThankYou}
															isSeller={isSeller}
															resize={resize}
															muted={muted}
															setMuted={setMuted}
															saveTrack={saveTrack}
															videoDisabled={videoDisabled}
															hostParticipantAudioTracksRef={hostParticipantAudioTracksRef}
															setVideoDisabled={setVideoDisabled}
															localParticipant={
																room.localParticipant
															}
															setResize={setResize}
															showEventNameInChatContainer={showEventNameInChatContainer}
															setShowEventNameInChatContainer={setShowEventNameInChatContainer}
															onEndTurn={nextUpOrEndTurn}
															exitEventRoom={() => {
																if (room.disconnect) {
																	room.disconnect();
																}
																exitEventRoom();
															}}
															setVideoHeight={setVideoHeight}
															eventId={eventId}
															setLeftArrowButtonPosition={setLeftArrowButtonPosition}
															setTopArrowButtonPosition={setTopArrowButtonPosition}
															onOrientationChange={onOrientationChange}
															showLinkedEventsSliderInMobileChatContainer={showLinkedEventsSliderInMobileChatContainer}
															setShowLinkedEventsSliderInMobileChatContainer={setShowLinkedEventsSliderInMobileChatContainer}
														/>
													</div>
												)
										) : (
											''
										)}

										{showThankYou === true ? null : <></>}
									</div>
									{
										!resize && (
											<div className={`md:block ${removeChildrenContainerHeight ? '' : 'h-96'} overflow-y-hidden md:h-auto rounded-md flex justify-center md:px-0`}>
												{children}
											</div>
										)
									}

								</div>
							</article>
						</div>
						{!isMobileSize && (
							<LinkedEventsSliderContainer />
						)}
					</div>
				</div>
			</div>
		</ProductsProvider>
	);
};

export default connect((_) => ({
}), null)(VideoStream);
