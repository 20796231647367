export const inputTypes = {
	primary: {
		focusColor: 'indigo-400',
		borderColor: 'gray-200'
	},
	validPrimary: {
		focusColor: 'indigo-400',
		borderColor: 'green-500'
	},
	invalidPrimary: {
		focusColor: 'indigo-400',
		borderColor: 'red-500'
	}
};

export const checkboxTypes = {
	primary: {
		checkedColor: 'djawn',
		darkCheckedColor: 'djawn',
		defaultColor: 'white',
		defaultDarkColor: 'djawn-50',
		defaultBorderColor: 'djawn',
		defaultDarkBorderColor: 'gray-50'
	},
	secondary: {
		checkedColor: 'blue-500',
		darkCheckedColor: 'blue-500',
		defaultColor: 'white',
		defaultDarkColor: 'darkGray-50',
		defaultBorderColor: 'gray-300',
		defaultDarkBorderColor: 'gray-50'
	}
};
