import { useEffect, useState } from 'react';

const useRipNShipActiveLine = (
	nameInActiveLine,
	setNameInActiveLine,
	breakIsInActiveLine,
	setBreakIsInActiveLine
) => {
	const [ripNShipTimers, setRipNShipTimers] = useState([]);
	const [joinedQueue, setJoinedQueue] = useState(false);
	const [endingTurn, setEndingTurn] = useState(false);
	const [ripNShipActiveBuyersInLine, setRipNShipActiveBuyersInLine] = useState(
		[]
	);
	const [
		disableRipNShipFunctionality,
		setDisableRipNShipFunctionality
	] = useState(false);
	const [
		userWantsToKeepItemsWsMessage,
		setUserWantsToKeepItemsWsMessage
	] = useState(null);

	useEffect(() => {
		if (!ripNShipActiveBuyersInLine || !ripNShipActiveBuyersInLine.length) {
			setBreakIsInActiveLine(false);
			setNameInActiveLine(null);
			return;
		}
		const firstPosition = ripNShipActiveBuyersInLine.find(
			(buyer) => buyer.position == 1
		);
		if (!firstPosition) {
			setBreakIsInActiveLine(false);
			setNameInActiveLine(null);
			return;
		}
		setBreakIsInActiveLine(firstPosition.userId == null);
		let name = firstPosition.nickname;
		if (!name) {
			name = firstPosition.name;
		}

		setNameInActiveLine(name);
	}, [JSON.stringify(ripNShipActiveBuyersInLine)]);

	useEffect(() => {
		if (!userWantsToKeepItemsWsMessage) return;
		if (userWantsToKeepItemsWsMessage.userId) {
			const tempBuyers = [...ripNShipActiveBuyersInLine].map((buyer) => {
				if (buyer.userId == userWantsToKeepItemsWsMessage.userId) {
					buyer.keepItems = userWantsToKeepItemsWsMessage.keepItems;
				}
				return buyer;
			});

			setRipNShipActiveBuyersInLine(tempBuyers);
		}
	}, [userWantsToKeepItemsWsMessage]);

	return {
		setUserWantsToKeepItemsWsMessage,
		ripNShipTimers,
		setRipNShipActiveBuyersInLine,
		ripNShipActiveBuyersInLine,
		setRipNShipTimers,
		disableRipNShipFunctionality,
		setDisableRipNShipFunctionality,
		joinedQueue,
		setJoinedQueue,
		breakIsInActiveLine,
		nameInActiveLine,
		endingTurn,
		setEndingTurn
	};
};

export default useRipNShipActiveLine;
