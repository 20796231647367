import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { isTablet } from 'react-device-detect';
import { isMobileAndNotTablet } from '../../utils/device';
import useReferenceData from '../../hooks/use-reference-data';
import { SHIPPING_LABEL_STATUSES_REFERENCE_TYPE } from '../../utils/constants';
import SelectInput from '../../components/input/select-input';
import SearchInput from '../../components/input/search-input';
import Button from '../../components/button';
import { SHIPPING_LABEL_PENDING_LABEL_CREATION } from '../../utils/constants/shipping-labels';
import useDebounce from '../../hooks/useDebounce';

const ShipmentFilter = ({
	onFilter,
	currentPage,
	rowsPerPage,
	selectedShippingLabelStatusId,
	searchValue,
	setSearchValue,
	setSelectedShippingLabelStatusId
}) => {
	const debouncedSearchValue = useDebounce(searchValue, 400);
	const [filter, setFilter] = useState(true);
	const [shippingLabelStatuses] = useReferenceData(
		SHIPPING_LABEL_STATUSES_REFERENCE_TYPE
	);

	useEffect(() => {
		if (!filter) return;
		onFilter(debouncedSearchValue, selectedShippingLabelStatusId);
	}, [
		selectedShippingLabelStatusId,
		currentPage,
		debouncedSearchValue,
		rowsPerPage,
		filter
	]);

	return (
		<>
			<div className="flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-between lg:items-stretch w-full">
				<div
					className={clsx(
						'w-full flex flex-col md:flex-row justify-between items-center',
						isTablet && 'flex-wrap space-y-2 items-center'
					)}
				>
					<div className="flex space-x-2">
						<SearchInput
							SearchInput
							hasShadows
							value={searchValue}
							setValue={(value) => setSearchValue(value)}
							placeholder="To search enter name, order #, etc"
							widthClassName="w-75"
							searchIconLocation="left"
						/>

						{!isMobileAndNotTablet ? (
							<>
								<SelectInput
									id="shipping_label_status"
									width="42"
									mobileWidth="42"
									height="10"
									mobileHeight="10"
									name="shipping_label_status"
									autoComplete="shipping_label_status"
									onBlur={(e) => setSelectedShippingLabelStatusId(e.target.value)}
									onChange={(e) => setSelectedShippingLabelStatusId(e.target.value)}
									value={selectedShippingLabelStatusId || ''}
								>
									<option key={null} value={0}>
										Shipping Label Status
									</option>
									{shippingLabelStatuses.map((shippingLabelStatus) => (
										<option
											key={shippingLabelStatus.id}
											value={shippingLabelStatus.id}
										>
											{shippingLabelStatus.value}
										</option>
									))}
								</SelectInput>
							</>
						) : null}
					</div>
					<div>
						<Button
							width="36"
							mobileWidth="auto"
							height="12"
							textSize="sm"
							mobileHeight="12"
							roundedSize="sm"
							disabled={
								!searchValue
								&& selectedShippingLabelStatusId
									== SHIPPING_LABEL_PENDING_LABEL_CREATION
							}
							onClick={() => {
								setFilter(false);
								setSearchValue('');
								setSelectedShippingLabelStatusId(
									SHIPPING_LABEL_PENDING_LABEL_CREATION
								);
								setFilter(true);
							}}
						>
							Reset Filter
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ShipmentFilter;
