import clsx from 'clsx';
import React from 'react';

const SymbolBoxInput = ({
	width = 'full',
	variant = 'primary',
	mobileWidth = 'full',
	height = '12',
	mobileHeight = 'auto',
	textSize = 'sm',
	additionalClassName = '',
	rtl = false,
	symbol = '$',
	...restProps
}) => (
	<div
		className={clsx(
			'inline-flex shadow-md rounded-md',
			`w-${mobileWidth} md:w-${width} h-${mobileHeight} md:h-${height} text-${textSize}`
		)}
	>
		{rtl ? null : (
			<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
				{symbol}
			</span>
		)}
		<input
			type="text"
			className={clsx(
				`input input--${variant} input--no-shadows`,
				rtl ? 'input--currency-rtl' : 'input--currency',
				`w-${mobileWidth} md:w-${width} h-${mobileHeight} md:h-${height} text-${textSize}`,
				additionalClassName
			)}
			{...restProps}
		/>
		{rtl ? (
			<span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
				{symbol}
			</span>
		) : null}
	</div>
);
export default SymbolBoxInput;
