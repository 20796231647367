/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Modal = ({
	showModal,
	onClose,
	hideCloseButton = false,
	hideXButton = false,
	children,
	className = '',
	containerClassName = '',
	header = '',
	headerMargin,
	noOutsideClick = false,
	hideOverflow = true,
	hideHeader,
	addMarginTop = null,
	addBorderColor = null,
	wrap = true
}) => {
	const cancelButtonRef = useRef();

	const doNothing = () => {};

	return (
		<Transition.Root show={showModal} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed inset-0 overflow-y-auto "
				style={{ zIndex: 120 }}
				initialFocus={cancelButtonRef}
				onClose={noOutsideClick ? doNothing : onClose}
			>
				<Transition.Child
					as={Fragment}
					enter="duration-300 ease-out"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="duration-200 ease-in"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Dialog.Overlay
						className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
						style={{ zIndex: '1040!important' }}
					/>
				</Transition.Child>

				<div
					className={`flex items-center md:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0  ${
						addMarginTop && `mt-${addMarginTop}`
					}`}
				>
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="duration-300 ease-out"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="duration-200 ease-in"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
						style={{ zIndex: '1050!important' }}
					>
						<div
							className={clsx(
								'inline-block fixed md:relative bg-opacity-100 align-bottom bg-white rounded-lg text-left',
								{ 'overflow-hidden': hideOverflow === true },
								'shadow-xl transform sm:my-8 sm:align-middle sm:max-w-lg w-11/12',
								addBorderColor && `border-2 border-${addBorderColor}`,
								className
							)}
						>
							{header && (
								<div className="border-b">
									<div className="flex justify-between m-1 items-center">
										<h4 className="flex justify-center pl-4">{header}</h4>
										{!hideXButton && (
											<button
												type="button"
												className={clsx(
													'px-2 text-2xl text-gray-500 top-0 right-0 cursor-pointer outline-none border-none focus:outline-none',
													hideHeader && 'hidden'
												)}
												onClick={onClose}
											>
												<svg
													className="w-7 h-7"
													fill="none"
													stroke="currentColor"
													viewBox="0 0 24 24"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M6 18L18 6M6 6l12 12"
													/>
												</svg>
											</button>
										)}
									</div>
								</div>
							)}
							<div
								className={clsx(
									'bg-white',
									!hideHeader && 'px-4 sm:p-6',
									'pt-5 pb-4 sm:pb-4 relative'
								)}
							>
								<div
									className="flex items-center justify-center"
									ref={cancelButtonRef}
								>
									<div
										className={clsx(
											'mt-3 text-center justify-center sm:mt-0',
											!hideHeader && 'sm:ml-4',
											'sm:text-left w-full',
											containerClassName
										)}
									>
										<div
											className={clsx(
												'flex flex-col',
												header && header.length && 'items-start',
												(!header || !header.length) && 'items-center',
												'justify-center w-full'
											)}
										>
											<div
												className={clsx(
													'sm:col-auto md:col-span-1 lg:col-span-1 xl:col-s store-logo-info w-full',
													!hideHeader && `mt-${headerMargin}`
												)}
											>
												<div
													className={clsx(
														'flex',
														wrap && 'flex-wrap',
														'items-center justify-center'
													)}
												>
													{children}
													{!hideCloseButton && (
														<div className="flex mt-8 justify-center items-center">
															<div className="sm:text-center store-next-button">
																<button
																	type="button"
																	className="min-w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-10 rounded-md "
																	onClick={onClose}
																>
																	Close
																</button>
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									{/* this button is needed because the modal crashes the site when there is no content so we needed to add a button with no opacity to avoid crashing the site */}
									<button
										className="opacity-0 absolute bottom-0"
										type="button"
									/>
									{!header && (
										<button
											type="button"
											className={clsx(
												'absolute pt-2 px-2 text-2xl text-gray-500 top-0 right-0 cursor-pointer outline-none border-none focus:outline-none',
												hideHeader && 'hidden'
											)}
											onClick={onClose}
											tabIndex={-1}
										>
											<svg
												className="w-7 h-7"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									)}
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

Modal.propTypes = {
	showModal: PropTypes.bool,
	onClose: PropTypes.func,
	hideCloseButton: PropTypes.bool,
	noOutsideClick: PropTypes.bool,
	headerMargin: PropTypes.number,
	hideXButton: PropTypes.bool,
	className: PropTypes.string,
	containerClassName: PropTypes.string,
	hideOverflow: PropTypes.bool,
	hideHeader: PropTypes.bool,
	addMarginTop: PropTypes.number,
	addBorderColor: PropTypes.string,
	wrap: PropTypes.bool
};

Modal.defaultProps = {
	hideCloseButton: false,
	noOutsideClick: false,
	headerMargin: 0,
	hideXButton: false,
	className: '',
	containerClassName: '',
	hideOverflow: true,
	hideHeader: false,
	addMarginTop: null,
	addBorderColor: null,
	wrap: true
};
export default Modal;
