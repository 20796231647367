/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import { PlusIcon, LinkIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import Button from '../../../components/button';
import EllipisWithTooltip from '../../../components/ellipsis-tooltip';
import { LiveEventDataContext } from '../../../contexts/live-event-data';
import { notificationToast } from '../../../utils/information-toast';
import AvailableSlots from '../../../components/common/available-slots';
import { RIP_N_SHIP } from '../../../utils/constants';
import LinkEventModal from '../link-event-modal';
import CreateLiveEventModal from './create-live-event-modal';
import { LiveEventViewsContainerContext } from '../../../contexts/live-event-views-container';

const LinkedEventsView = () => {
	const debouncedTimerTooltipRef = useRef();
	const [showLinkEventModal, setShowLinkEventModal] = useState(false);
	const [showCreateNewEventModal, setShowCreateNewEventModal] = useState(false);
	const {
		eventId, linkedEvents, isSeller, hasRipNShipEvent
	} = useContext(
		LiveEventDataContext
	);
	const { width: eventViewsWidth } = useContext(LiveEventViewsContainerContext);

	useEffect(() => {
		debouncedTimerTooltipRef.current = setTimeout(() => {
			ReactTooltip.rebuild();
		}, [1000]);

		return () => clearTimeout(debouncedTimerTooltipRef.current);
	}, [JSON.stringify(linkedEvents)]);

	const onLinkedEventClick = useCallback((linkedEventId) => {
		window.open(
			`${window.location.origin}/event/${linkedEventId}?closeOnPurchase=true`,
			'_blank'
		);
	}, []);

	const copyLinkTableHeaderWidth = useMemo(() => {
		if (!eventViewsWidth || eventViewsWidth <= 620) return '3/12';
		if (eventViewsWidth <= 700) return '2/12';
		return '2/12';
	}, [eventViewsWidth]);

	const eventStatusHeaderWidth = useMemo(() => {
		if (!eventViewsWidth || eventViewsWidth <= 620) return '4/12';
		if (eventViewsWidth <= 700) return '3/12';
		return '2/12';
	}, [eventViewsWidth]);

	return (
		<div className="flex flex-col h-full">
			<LinkEventModal
				showModal={showLinkEventModal}
				setShowModal={setShowLinkEventModal}
				eventId={eventId}
				linkedEvents={linkedEvents}
				updateLinkedEventStatus
			/>
			{isSeller && (
				<CreateLiveEventModal
					eventId={eventId}
					hasRipNShipEvent={hasRipNShipEvent}
					showModal={showCreateNewEventModal}
					setShowModal={setShowCreateNewEventModal}
				/>
			)}
			<div className="flex-1">
				<table className="table-fixed text-left w-full">
					<thead>
						<tr>
							<th
								className={clsx(
									eventViewsWidth > 620 ? 'w-5/12' : 'w-4/12',
									'p-4 text-left text-sm font-bold text-gray-100 bg-djawn'
								)}
							>
								Event
							</th>
							<th
								className={clsx(
									'p-4 text-left text-sm font-bold text-gray-100 bg-djawn',
									isSeller ? `w-${eventStatusHeaderWidth}` : 'w-2/12'
								)}
							>
								Status
							</th>
							{isSeller && (
								<th
									className={clsx(
										`w-${copyLinkTableHeaderWidth}`,
										'p-4 text-center text-sm font-bold text-gray-100 bg-djawn'
									)}
								>
									Link
								</th>
							)}
						</tr>
					</thead>
					<tbody className="text-gray-800 text-sm dark:text-white dark:bg-darkGray-50">
						<ReactTooltip />

						{linkedEvents.map((eventLinkResult) => {
							let availableSlots = 0;
							let totalSlots = 0;
							if (eventLinkResult.event.eventTypeId != RIP_N_SHIP) {
								let soldSlots = 0;
								eventLinkResult.breaks.forEach((b) => {
									if (b.slotStatusId === 49) soldSlots++;
								});

								availableSlots = eventLinkResult.breaks.length - soldSlots;
								totalSlots = eventLinkResult.breaks.length;
							}
							return (
								<>
									<tr
										key={eventLinkResult.event.eventId}
										className="hover:cursor-pointer hover:bg-gray-100 dark:hover:bg-darkGray-200"
									>
										<td className="px-4 xxs:px-4 py-2">
											<div
												className="no-underline"
												onClick={() => onLinkedEventClick(eventLinkResult.event.eventId)}
												onKeyDown={() => onLinkedEventClick(eventLinkResult.event.eventId)}
											>
												<div className="flex items-center">
													<p className="text-sm font-light leading-relaxed text-left w-full">
														<EllipisWithTooltip
															isParagraph
															placement="bottom"
															className="text-sm"
															overridenStyle={{
																whiteSpace: 'nowrap',
																overflow: 'hidden',
																textOverflow: 'ellipsis'
															}}
														>
															{availableSlots == 0
															&& eventLinkResult.event.eventTypeId != RIP_N_SHIP
																? `(FULL) - ${eventLinkResult.event.eventName} - Event Number #${eventLinkResult.event.eventNumber}`
																: `${eventLinkResult.event.eventName} - Event Number #${eventLinkResult.event.eventNumber}`}
														</EllipisWithTooltip>
													</p>
												</div>
											</div>
										</td>
										<td className="px-4 xxs:px-4 py-2">
											<div
												className="no-underline"
												onClick={() => onLinkedEventClick(eventLinkResult.event.eventId)}
												onKeyDown={() => onLinkedEventClick(eventLinkResult.event.eventId)}
											>
												{eventLinkResult.event.eventTypeId != RIP_N_SHIP && (
													<AvailableSlots
														abbrevatedText
														availableSlotNumber={availableSlots}
														totalNumberOfSlots={totalSlots}
													/>
												)}
											</div>
										</td>
										{isSeller && (
											<td className="px-4 xxs:px-4 py-2">
												<CopyToClipboard
													onCopy={() => {
														notificationToast('Link copied to clipboard!');
													}}
													text={`${window.location.origin}/event/${
														eventLinkResult.event.eventId
													}?linkedEventChatMessageTransform=true&eventName=${encodeURIComponent(
														eventLinkResult.event.eventName
															? `${eventLinkResult.event.eventName.trim()} - Event Number #${
																eventLinkResult.event.eventNumber
															  }`
															: ''
													)}&closeOnPurchase=true`}
												>
													<Button width="full" height="9" mobileHeight="8">
														copy
													</Button>
												</CopyToClipboard>
											</td>
										)}
									</tr>
								</>
							);
						})}
					</tbody>
				</table>
			</div>

			{isSeller && (
				<div className="flex justify-between p-2">
					<Button
						height="9"
						mobileHeight="9"
						textSize="xs"
						additionalClassName="flex items-center"
						width="32"
						onClick={() => setShowCreateNewEventModal(true)}
					>
						<PlusIcon className="w-8 h-8" />
						<span className="w-full">Create new event</span>
					</Button>
					<Button
						variant="secondary"
						height="9"
						mobileHeight="9"
						textSize="xs"
						additionalClassName="flex items-center"
						width="32"
						onClick={() => setShowLinkEventModal(true)}
					>
						<LinkIcon className="w-8 h-8" />
						<span className="w-full">Link event</span>
					</Button>
				</div>
			)}
		</div>
	);
};

export default LinkedEventsView;
