import { XIcon } from '@heroicons/react/solid';
import React, { useCallback, useContext, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import RoomAddEventProduct from '../../containers/event/room/room-add-event-product';
import { LiveEventViewsContainerContext } from '../../contexts/live-event-views-container';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import { ProductsContext } from '../../contexts/products';
import useErrorNotifier from '../../hooks/use-error-notifier';
import Loader from '../loader';
import { VIEWS_DESKTOP_MIN_WIDTH } from '../../utils/constants';

const ProductsEventQueue = ({
	eventId,
	setShowProductModal,
	setSelectedProduct,
	disableRowClickedFunctionality,
	showAddToCartInformationPopup,
	isDarkMode,
	userCanAddOrRemoveEventProducts,
	userCanAddProducts,
	productSelectionId
}) => {
	const { showToastError } = useErrorNotifier();
	const { setEventProductIdToRemove } = useContext(ProductsContext);
	const {
		productsLoading,
		productsCurrentPage,
		loadRipNShipProducts,
		products,
		productsTotalRows,
		productsPerPageRef,
		setProductsSortBy
	} = useContext(LiveEventDataContext);
	const {
		updatePopupsStatus,
		addToCartPopupFadingOut,
		openQueueContainer,
		width
	} = useContext(LiveEventViewsContainerContext);

	const columns = useMemo(() => {
		let nameWidth = 60;
		let priceWidth = 40;
		let removeProductWidth = 0;
		if (width > VIEWS_DESKTOP_MIN_WIDTH) {
			nameWidth = 70;
			priceWidth = 30;
		}

		if (userCanAddOrRemoveEventProducts) {
			removeProductWidth = 30;
			nameWidth -= 20;
			priceWidth -= 10;
		}

		const defaultColumns = [
			{
				name: 'Name',
				selector: (row) => row.product.productName,
				sortable: true,
				width: `${nameWidth}%`,
				cell: (row) => (
					<div
						className="font-bold hover:underline cursor-pointer"
						data-tag="allowRowEvents"
					>
						{row.product.productName}
					</div>
				),
				wrap: true
			},
			{
				name: 'Price',
				selector: (row) => row.product.salesPriceRipShip,
				sortable: true,
				right: true,
				width: `${priceWidth}%`,
				cell: (row) => (
					<div
						className="font-bold hover:underline cursor-pointer"
						data-tag="allowRowEvents"
					>
						$
						{row.product.salesPriceRipShip}
					</div>
				),
				wrap: true
			}
		];

		if (userCanAddOrRemoveEventProducts) {
			defaultColumns.push({
				name: 'Remove product',
				sortable: true,
				right: true,
				width: `${removeProductWidth}%`,
				cell: (row) => (
					<XIcon
						className="cursor-pointer w-8 h-8 dark:text-white text-black"
						onClick={() => {
							if (products.length <= 1) {
								showToastError(
									'You cannot remove the product, you should have at least one product in the event.'
								);
								return;
							}
							setEventProductIdToRemove(row.eventProductId);
						}}
					/>
				),
				wrap: true
			});
		}
		return defaultColumns;
	}, [userCanAddOrRemoveEventProducts, products]);

	const onProductNamePopupClose = (dismissed) => {
		updatePopupsStatus('show_addtocart_information', dismissed);
	};

	const onSort = useCallback((column, sortDirection) => {
		setProductsSortBy({
			column: column.name,
			direction: sortDirection == 'asc' ? 'ascending' : 'descending'
		});
	}, [setProductsSortBy]);

	if (!openQueueContainer) return null;

	return (
		<>
			{productsLoading && (
				<div className="w-full h-full flex items-center justify-center">
					<Loader
						overridenCss={`display: block;
		margin: 0 auto;
		border-color: blue;`}
					/>
				</div>
			)}
			<div className="relative h-full" hidden={productsLoading}>
				{showAddToCartInformationPopup && (
					<div
						className={clsx(
							'flex justify-end flex-col items-start absolute ml-1 top-11',
							addToCartPopupFadingOut ? 'signin-popup-fade-out' : 'signin-popup'
						)}
					>
						<div className="triangle-up ml-4" style={{ zIndex: '60' }} />

						<div
							className="w-64 md:w-80 border rounded"
							style={{
								backgroundColor: '#0c6ae6',
								borderColor: '#0c6ae6',
								zIndex: '60'
							}}
						>
							<h3
								className="text-white text-sm pt-4 pl-3"
								style={{ zIndex: '60' }}
							>
							Click on a product name to see more information and add to cart.
							</h3>
							<div
								className="flex justify-between px-3 pt-3 mb-1"
								style={{ zIndex: '60' }}
							>
								<p
									className="cursor-pointer text-white text-md"
									onClick={() => onProductNamePopupClose(true)}
									onKeyDown={() => onProductNamePopupClose(true)}
								>
								Dismiss
								</p>
								<p
									className="cursor-pointer text-white text-md"
									onClick={() => onProductNamePopupClose(false)}
									onKeyDown={() => onProductNamePopupClose(false)}
								>
								Don't show again
								</p>
							</div>
						</div>
					</div>
				)}

				<div
					className={clsx(
						'flex flex-col justify-between h-full',
						(userCanAddOrRemoveEventProducts || userCanAddProducts)
						&& 'overflow-hidden'
					)}
				>
					<div
						className={clsx(
							'products-event-queue-table-container',
							!isMobile && 'products-event-queue-table-container-desktop',
							(userCanAddOrRemoveEventProducts || userCanAddProducts)
							&& 'overflow-y-auto'
						)}
					>
						<DataTable
							columns={columns}
							data={products}
							pagination
							sortServer
							onSort={onSort}
							paginationTotalRows={productsTotalRows}
							onRowClicked={(row) => {
								if (!disableRowClickedFunctionality) {
									setSelectedProduct(row);
									setShowProductModal(true);
								}
							}}
							paginationComponentOptions={{
								rowsPerPageText: '',
								rangeSeparatorText: 'of',
								noRowsPerPage: isMobile,
								selectAllRowsItem: false,
								selectAllRowsItemText: 'All'
							}}
							striped
							paginationRowsPerPageOptions={[15, 25, 50]}
							contextActions={null}
							noContextMenu
							onChangePage={(page) => {
								loadRipNShipProducts(page - 1);
							}}
							onChangeRowsPerPage={(rows) => {
								productsPerPageRef.current = rows;
								loadRipNShipProducts(productsCurrentPage);
							}}
							paginationPerPage={productsPerPageRef.current}
							paginationDefaultPage={productsCurrentPage + 1}
							paginationServer
							noDataComponent={
								userCanAddOrRemoveEventProducts || userCanAddProducts ? (
									<p className="flex justify-center items-center m-5">
									There is no product in the event which matches to your search
									criteria. Please use the buttons below to create a new product
									or to add existing product/s to the live event.
									</p>
								) : (
									<p className="flex justify-center items-center m-5">
									There are no records to display
									</p>
								)
							}
							customStyles={{
								className: 'bg-white dark:bg-darkGray-100',
								rows: {
									stripedStyle: {
										backgroundColor: isDarkMode ? '#323131' : '#fffcfc',
										color: isDarkMode && '#FFFFFF'
									},
									style: {
										backgroundColor: isDarkMode && '#201F1F',
										color: isDarkMode && '#FFFFFF'
									}
								},
								headCells: {
									style: {
										backgroundColor: 'rgb(232, 46, 44)',
										color: 'white',
										fontSize: '1rem',
										fontWeight: 'bold'
									}
								},
								pagination: {
									style: {
										backgroundColor: isDarkMode && '#201F1F',
										color: isDarkMode && '#FFFFFF'
									},
									pageButtonsStyle: {
										color: isDarkMode && '#FFFFFF',
										fill: isDarkMode && '#FFFFFF',
										backgroundColor: 'transparent',
										'&:disabled': {
											cursor: 'unset',
											color: isDarkMode && '#FFFFFF',
											fill: isDarkMode && '#FFFFFF'
										}
									}
								}
							}}
						/>
					</div>
					<RoomAddEventProduct
						excludedProductIds={products.map(
							(eventProduct) => eventProduct.productId
						)}
						eventId={eventId}
						userCanAddOrRemoveEventProducts={userCanAddOrRemoveEventProducts}
						userCanAddProducts={userCanAddProducts}
						productSelectionId={productSelectionId}
					/>
				</div>
			</div>
		</>
	);
};
export default connect(
	(state) => ({
		isDarkMode: state.theme.isDarkMode
	}),
	null
)(ProductsEventQueue);
