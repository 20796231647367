import React from 'react';
import Slider from 'react-slick';
import LinkedEventSliderItem from './linked-event-slider-item';

const MAX_SLIDES_TO_SCROLL = 4;

const LinkedEventSlider = ({ linkedEvents, className }) => {
	if (!linkedEvents || !linkedEvents.length) return null;

	const onEventLinkClick = (eventId) => {
		window.open(
			`${window.location.origin}/event/${eventId}?closeOnPurchase=true`,
			'_blank'
		);
	};

	return (
		<>
			<div className={`border border-sm p-5 ${className}`}>
				<Slider
					slidesToShow={
						linkedEvents.length > MAX_SLIDES_TO_SCROLL
							? MAX_SLIDES_TO_SCROLL
							: linkedEvents.length
					}
					slidesPerRow={linkedEvents.length == 1 ? 4 : 1}
					arrows
					infinite={false}
				>
					{linkedEvents.map((linkedEvent) => (
						<LinkedEventSliderItem
							key={linkedEvent.event.eventId}
							linkedEvent={linkedEvent}
							onEventLinkClick={onEventLinkClick}
						/>
					))}
				</Slider>
			</div>
		</>
	);
};

export default LinkedEventSlider;
