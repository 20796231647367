import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { referenceDataStatuses } from './reference-data-statuses';

const StatusBadge = ({
	referenceDataId,
	tooltipText,
	text,
	color,
	noContainerPadding = false
}) => {
	const [options, setOptions] = useState(null);

	useEffect(() => {
		if (referenceDataId) {
			let tempOptions = {};
			const foundOptions = referenceDataStatuses.find(
				(option) => option.referenceDataId === referenceDataId
			);
			if (foundOptions) {
				tempOptions = foundOptions;
			}

			setOptions(tempOptions);
		}
	}, [referenceDataId, text, color]);

	if (!options) return null;
	return (
		<td
			className={clsx(
				'text-gray-700 whitespace-nowrap',
				!noContainerPadding && 'md:p-4 p-1'
			)}
		>
			<strong
				className={clsx(
					'px-1 md:px-3 py-0 md:py-1.5 rounded text-xs font-semibold flex items-center justify-center',
					options.badgeColor,
					options.textColor
				)}
			>
				<span
					className={clsx(
						options.additionalClasses && options.additionalClasses
					)}
				>
					{options.text}
				</span>
				{tooltipText && (
					<span data-tip={tooltipText}>
						<svg
							className="w-6 h-6"
							fill="currentColor"
							stroke="white"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
					</span>
				)}
			</strong>
		</td>
	);
};
export default StatusBadge;
