import React, { createContext } from 'react';
import useSoundNotification from '../hooks/useSoundNotification';

const SoundNotificationContext = createContext();

const SoundNotificationProvider = ({ isSeller, isMuted, children }) => {
	const {
		playSoundNotificationSound,
		playDefaultAudio,
		onCreateAudioElements
	} = useSoundNotification(isSeller, isMuted);

	return (
		<SoundNotificationContext.Provider
			value={{
				playSoundNotificationSound,
				playDefaultAudio,
				onCreateAudioElements
			}}
		>
			{children}
		</SoundNotificationContext.Provider>
	);
};

export { SoundNotificationContext, SoundNotificationProvider };
