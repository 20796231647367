import React from 'react';
import LayoutHome from '../../components/layouts/layout-home';
import SEO from '../../components/seo';
import Conversation from '../../components/conversation';

const ConversationPage = () => (
	<LayoutHome sidebar={false}>
		<SEO title="Conversation Page" auth />
		<div className="bg-gray-100 dark:bg-darkGray-50 mx-auto w-full h-full pt-0">
			<Conversation />
		</div>
	</LayoutHome>
);

export default ConversationPage;
