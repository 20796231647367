/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from 'react';
import Button from '../../components/button';
import CheckboxInput from '../../components/input/checkbox-input';
import {
	SHIPPING_LABEL_LABEL_CANCELLED,
	SHIPPING_LABEL_LABEL_CREATED,
	SHIPPING_LABEL_PENDING_EVENT_COMPLETION,
	SHIPPING_LABEL_PENDING_LABEL_CREATION
} from '../../utils/constants/shipping-labels';
import { areAllArrayItemsTheSame } from '../../utils/commonFunction';

const ShipmentActions = ({
	onBuyLabel,
	onSelectAll,
	hasAtLeastOneItemSelected,
	onCancelLabel,
	itemsInPageLength,
	onPrintLabel,
	shippingLabelStatusIds,
	itemsLength,
	selectedShippingLabelStatusId,
	onIgnore
}) => {
	const [selectAll, setSelectAll] = useState(false);

	useEffect(() => {
		setSelectAll(itemsInPageLength === itemsLength);
	}, [itemsLength, itemsInPageLength]);

	return (
		<div className="flex pb-4 justify-between items-center">
			<div className="space-x-2">
				<Button
					width="36"
					mobileWidth="auto"
					height="12"
					textSize="sm"
					disabled={
						!hasAtLeastOneItemSelected
						|| !areAllArrayItemsTheSame(shippingLabelStatusIds)
						|| shippingLabelStatusIds.length === 0
						|| shippingLabelStatusIds[0] == SHIPPING_LABEL_LABEL_CREATED
						|| shippingLabelStatusIds[0] == SHIPPING_LABEL_LABEL_CANCELLED
						|| shippingLabelStatusIds[0] == SHIPPING_LABEL_PENDING_EVENT_COMPLETION
					}
					mobileHeight="12"
					roundedSize="sm"
					onClick={onBuyLabel}
				>
					Create Labels
				</Button>
				<Button
					width="36"
					mobileWidth="auto"
					height="12"
					disabled={
						!hasAtLeastOneItemSelected
						|| !areAllArrayItemsTheSame(shippingLabelStatusIds)
						|| shippingLabelStatusIds[0] != SHIPPING_LABEL_LABEL_CREATED
					}
					textSize="sm"
					mobileHeight="12"
					roundedSize="sm"
					onClick={onPrintLabel}
				>
					Print Label
				</Button>
				<Button
					width="36"
					mobileWidth="auto"
					height="12"
					textSize="sm"
					disabled
					mobileHeight="12"
					roundedSize="sm"
					onClick={onCancelLabel}
				>
					Cancel Label
				</Button>
				<Button
					width="36"
					mobileWidth="auto"
					height="12"
					textSize="sm"
					mobileHeight="12"
					roundedSize="sm"
					disabled={
						!hasAtLeastOneItemSelected
						|| !areAllArrayItemsTheSame(shippingLabelStatusIds)
						|| shippingLabelStatusIds[0] != SHIPPING_LABEL_PENDING_LABEL_CREATION
					}
					onClick={onIgnore}
				>
					Ignore
				</Button>
			</div>
			<div className="pr-2">
				<div className="inline-block mr-2 ml-4" style={{ float: 'right' }}>
					<label className="flex items-center pt-4">
						<CheckboxInput
							className="h-6 w-6"
							checked={selectAll}
							disabled={
								selectedShippingLabelStatusId
								== SHIPPING_LABEL_PENDING_EVENT_COMPLETION
							}
							onChange={() => {
								setSelectAll((prev) => {
									onSelectAll(!prev);
									return !prev;
								});
							}}
							type="primary"
						/>
						<span>Select all</span>
					</label>
				</div>
			</div>
		</div>
	);
};

export default ShipmentActions;
