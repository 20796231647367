/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
	useEffect, useState, useContext, useMemo
} from 'react';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import { getCurrentUser } from '../../utils/auth';
import Select from '../select';
import { TwilioTrackContext } from '../../contexts/twilio-track';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import Modal from '../modals';
import Button from '../button';
import { RipNShipActiveLineContext } from '../../contexts/ripnship-activeline';

const clipLoaderCss = css`
	display: block;
	border-color: white;
`;

const LiveEventActionsModal = ({
	showModal,
	setShowModal,
	isSeller,
	initiateEndTurn,
	joinQueue,
	onEndTurn,
	onBuyerRemove,
	onExitLine,
	setBuyerExitId,
	isEventInformationReinitiating,
	onCloseLine,
	isInLine,
	setIsInLine,
	onOpenLine,
	lineClosed
}) => {
	const [approvingAllMicRequests, setApprovingAllMicRequests] = useState(false);
	const [decliningAllMicRequests, setDecliningAllMicRequests] = useState(false);

	const [selectedBuyer, setSelectedBuyer] = useState(0);
	const [position, setPosition] = useState(null);
	const [formated, setFormated] = useState(false);

	const {
		audioParticipants,
		approvedAudioParticipants,
		approveOrDeclineMicRequest,
		muteOrUnmuteMicParticipants,
		closeMic,
		mutingMic,
		setMutingMic,
		closingMic,
		setClosingMic
	} = useContext(TwilioTrackContext);
	const [
		hasAttendeesWaitingForMicApproval,
		setHasAttendeesWaitingForMicApproval
	] = useState(false);
	const { isRipNShip, attendees, ripNShipEvent } = useContext(
		LiveEventDataContext
	);
	const { breakIsInActiveLine, endingTurn } = useContext(
		RipNShipActiveLineContext
	);

	const showEndTurn = useMemo(
		() => ripNShipEvent
			&& ripNShipEvent.buyersQueue
			&& ripNShipEvent.buyersQueue.length
			&& ripNShipEvent.buyersQueue[0].position,
		[JSON.stringify(ripNShipEvent)]
	);

	useEffect(() => {
		if (!showModal) return;
		if (!attendees || !attendees.length) {
			setHasAttendeesWaitingForMicApproval(false);
			return;
		}

		const twilioParticipantIdsWithMicRequests = [...attendees]
			.map((attendee) => {
				if (attendee.micRequest && attendee.micRequest === true) return attendee.twilioParticipantId;
				return null;
			})
			.filter((attendee) => attendee != null);
		if (
			!twilioParticipantIdsWithMicRequests
			|| !twilioParticipantIdsWithMicRequests.length
		) {
			setHasAttendeesWaitingForMicApproval(false);
			return;
		}

		if (!audioParticipants || !audioParticipants.length) {
			setHasAttendeesWaitingForMicApproval(true);
			return;
		}

		const participantTwilioParticipantIds = [...audioParticipants].map(
			(p) => p.twilioParticipantId
		);

		const foundMicRequest = twilioParticipantIdsWithMicRequests.find(
			(attendeeTwilioParticipantId) => !participantTwilioParticipantIds.includes(attendeeTwilioParticipantId)
		);

		if (foundMicRequest) {
			setHasAttendeesWaitingForMicApproval(true);
			return;
		}

		setHasAttendeesWaitingForMicApproval(false);
	}, [showModal]);

	useEffect(() => {
		if (isEventInformationReinitiating) return;
		setFormated(false);
		const userId = getCurrentUser().id;
		if (
			ripNShipEvent
			&& ripNShipEvent.buyersQueue
			&& ripNShipEvent.buyersQueue.length
		) {
			const { buyersQueue: buyers } = ripNShipEvent;
			const filteredBuyer = buyers.find((f) => f.userId == userId);
			if (filteredBuyer) {
				setPosition(filteredBuyer.position);
				setIsInLine(true);
			} else {
				setIsInLine(false);
			}
		} else {
			setIsInLine(false);
		}
		setFormated(true);
	}, [JSON.stringify(ripNShipEvent), isEventInformationReinitiating]);

	const getMicRequestedUsers = () => {
		let attendeesWithMicRequest = [...attendees].filter(
			(attendee) => attendee.micRequest === true
		);
		if (!attendeesWithMicRequest || !attendeesWithMicRequest.length) return null;
		if (!audioParticipants || !audioParticipants.length) {
			return attendeesWithMicRequest.map((att) => ({
				userId: att.userId,
				twilioParticipantId: att.twilioParticipantId
			}));
		}
		audioParticipants.forEach((audioParticipant) => {
			const foundAttendee = attendeesWithMicRequest.find(
				(att) => att.twilioParticipantId == audioParticipant.twilioParticipantId
			);
			if (foundAttendee) {
				attendeesWithMicRequest = attendeesWithMicRequest.filter(
					(att) => att.twilioParticipantId !== foundAttendee.twilioParticipantId
				);
			}
		});
		return attendeesWithMicRequest.map((att) => ({
			userId: att.userId,
			twilioParticipantId: att.twilioParticipantId
		}));
	};

	return (
		<Modal
			showModal={showModal && !isEventInformationReinitiating && formated}
			onClose={() => setShowModal(false)}
			hideCloseButton
		>
			<div
				className="flex flex-col items-center justify-center dark:bg-darkGray-50 w-full space-y-2 mt-4"
				tabIndex={-6}
			>
				{isRipNShip && (
					<>
						{isSeller && (
							<h4 className="mb-1 w-full" tabIndex={-4}>
								Line Actions
							</h4>
						)}
						<div className="flex flex-col items-center justify-center dark:bg-darkGray-50 w-full space-y-2">
							{isSeller ? (
								<>
									{showEndTurn ? (
										<Button
											width="full"
											tabIndex={-1}
											mobileWidth="full"
											height="10"
											mobileHeight="10"
											disabled={endingTurn}
											onClick={onEndTurn}
										>
											{breakIsInActiveLine ? 'End Break' : 'End Turn'}
										</Button>
									) : null}
									<div className="flex w-full justify-between items-center">
										<Select
											id="buyerToRemoveFromLine"
											name="buyerToRemoveFromLine"
											autoComplete="buyerToRemoveFromLine"
											options={
												ripNShipEvent
												&& ripNShipEvent.buyersQueue
												&& ripNShipEvent.buyersQueue.length
													? ripNShipEvent.buyersQueue
														.filter((buyer) => buyer.userId != null)
														.map((b) => ({
															key: b.userId,
															value: b.userId,
															displayValue: b.nickname
														}))
													: []
											}
											onChange={(value) => setSelectedBuyer(value)}
										/>
										<Button
											width="1/2"
											mobileWidth="1/2"
											disabled={
												!ripNShipEvent
												|| !ripNShipEvent.buyersQueue.length
												|| ripNShipEvent.buyersQueue.find(
													(u) => u.userId != null
												) == null
											}
											height="10"
											mobileHeight="10"
											onClick={() => {
												if (selectedBuyer && selectedBuyer != 0) {
													setBuyerExitId(selectedBuyer);
													onBuyerRemove(selectedBuyer);
												}
											}}
										>
											Remove Buyer From Line
										</Button>
									</div>
									<div className="flex items-center justify-center w-full pt-1">
										{lineClosed ? (
											<Button
												width="full"
												mobileWidth="full"
												height="10"
												mobileHeight="10"
												onClick={onOpenLine}
											>
												Open Line
											</Button>
										) : (
											<Button
												width="full"
												mobileWidth="full"
												height="10"
												mobileHeight="10"
												onClick={onCloseLine}
											>
												Close Line
											</Button>
										)}
									</div>
								</>
							) : (
								<>
									{position && position == 1 && isInLine && (
										<Button
											width="full"
											mobileWidth="full"
											height="10"
											mobileHeight="10"
											onClick={initiateEndTurn}
										>
											Indicate "All Done"
										</Button>
									)}
									{!isInLine && (
										<Button
											width="full"
											mobileWidth="full"
											height="10"
											mobileHeight="10"
											onClick={() => {
												joinQueue();
												setShowModal(false);
											}}
										>
											Get In Line
										</Button>
									)}
									{isInLine && (
										<Button
											width="full"
											mobileWidth="full"
											height="10"
											mobileHeight="10"
											onClick={onExitLine}
										>
											Get Out Of Line
										</Button>
									)}
								</>
							)}
						</div>
					</>
				)}

				{isSeller && (
					<div className="w-full py-2 border-t-2">
						<h4 className="mb-1">Mic Actions</h4>
						<div className="w-full flex flex-col items-center space-y-2">
							<Button
								width="full"
								height="10"
								mobileHeight="10"
								disabled={
									!hasAttendeesWaitingForMicApproval || approvingAllMicRequests
								}
								additionalClassName={
									approvingAllMicRequests
										? 'flex items-center justify-center'
										: ''
								}
								onClick={() => {
									setApprovingAllMicRequests(true);
									const users = getMicRequestedUsers();
									if (users && users.length) {
										approveOrDeclineMicRequest(users, true, true).finally(
											() => {
												setApprovingAllMicRequests(false);
												setShowModal(false);
											}
										);
									}
								}}
							>
								{approvingAllMicRequests && (
									<ClipLoader size={30} css={clipLoaderCss} />
								)}
								<>
									<span>Approve all mic requests</span>
								</>
							</Button>
							<Button
								width="full"
								height="10"
								mobileHeight="10"
								additionalClassName={
									decliningAllMicRequests
										? 'flex items-center justify-center'
										: ''
								}
								disabled={
									!hasAttendeesWaitingForMicApproval || decliningAllMicRequests
								}
								onClick={() => {
									setDecliningAllMicRequests(true);
									const users = getMicRequestedUsers();
									if (users && users.length) {
										approveOrDeclineMicRequest(users, false, true).finally(
											() => {
												setDecliningAllMicRequests(false);
												setShowModal(false);
											}
										);
									}
								}}
							>
								{decliningAllMicRequests && (
									<ClipLoader size={30} css={clipLoaderCss} />
								)}
								<>
									<span>Decline all mic requests</span>
								</>
							</Button>
							<Button
								width="full"
								height="10"
								mobileHeight="10"
								additionalClassName={
									mutingMic ? 'flex items-center justify-center' : ''
								}
								disabled={
									!audioParticipants || !audioParticipants.length || mutingMic
								}
								onClick={() => {
									setMutingMic(true);
									const users = [...audioParticipants].map((participant) => ({
										userId: participant.userId,
										twilioParticipantId: participant.twilioParticipantId
									}));

									muteOrUnmuteMicParticipants(users, true, true, true);

									setShowModal(false);
								}}
							>
								{mutingMic && <ClipLoader size={30} css={clipLoaderCss} />}
								<>
									<span>Mute all active mics</span>
								</>
							</Button>
							<Button
								width="full"
								mobileHeight="10"
								height="10"
								additionalClassName={
									closingMic ? 'flex items-center justify-center' : ''
								}
								disabled={
									!(
										(audioParticipants && audioParticipants.length)
										|| (approvedAudioParticipants
											&& approvedAudioParticipants.length)
									) || closingMic
								}
								onClick={() => {
									setClosingMic(true);
									const twilioParticipantIds = [...audioParticipants].map(
										(participant) => participant.twilioParticipantId
									);

									if (
										(audioParticipants && audioParticipants.length)
										|| (approvedAudioParticipants
											&& approvedAudioParticipants.length)
									) {
										const approvedAudioParticipantsNotInTwilioParticipants = approvedAudioParticipants.filter(
											(approvedAudioParticipant) => !audioParticipants
												.map((a) => a.buyerId)
												.includes(approvedAudioParticipant.userId)
										);
										if (
											approvedAudioParticipantsNotInTwilioParticipants
											&& approvedAudioParticipantsNotInTwilioParticipants.length
										) {
											twilioParticipantIds.push(
												...approvedAudioParticipantsNotInTwilioParticipants.map(
													(approvedAudioParticipant) => approvedAudioParticipant.twilioParticipantId
												)
											);
										}
									}
									closeMic(twilioParticipantIds, true, true);
									setShowModal(false);
								}}
							>
								{closingMic && <ClipLoader size={30} css={clipLoaderCss} />}
								<>
									<span>Cancel all active mics</span>
								</>
							</Button>
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default LiveEventActionsModal;
