import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import Layout from '../components/layouts/layout-default';
import Header from '../components/header';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const FAQ = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// authState();
		setLoading(false);
	}, []);

	return (
		<Layout>
			<div className="flex flex-col flex-1">
				<Header auth />
				{loading && (
					<div className="w-screen h-screen bg-white absolute left-0 top-0 z-50">
						<div className="flex h-full items-center">
							<BounceLoader loading css={override} size={30} />
						</div>
					</div>
				)}

				{!loading && (
					<div className="flex flex-col flex-1 justify-start content-center">
						<div className="flex flex-1 flex-col justify-items-center bg-white w-8/12 xxs:w-full sm:w-full md:w-full xl:w-8/12 mx-auto px-6 py-10 rounded-lg">
							<h2 className="text-xl text-gray-700 font-semibold text-center sm:text-lg sm:max-w-xl sm:mx-auto  md:text-xl mb-4">
								FAQs
							</h2>
							<div className="w-6 h-0.5 mx-auto bg-gray-600 mb-6" />

							<div className="flex flex-col w-full mx-auto px-16 xxs:px-4 sm:px-4 md:px-6 xl:px-10">
								<p className="text-lg font-semibold text-gray-800 mt-8 mb-2">
									<u>Question</u>
								</p>
								<p className="text-base font-normal text-gray-900  mb-4">
									What is a "break"?
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-4 mb-2">
									<u>Answer</u>
								</p>
								<p className="text-base font-normal text-gray-900  mb-10">
									A box break is an event in which a box(es), case(s), and/or
									pack(s) of cards are divided up among collectors who purchase
									the right to receive them as part of a random drawing. The
									events are promoted as providing an opportunity for baseball
									card and sports memorabilia fanatics to obtain valuable cards
									at an affordable price.
									<a
										className="inline  text-base text-blue-500 hover:text-blue-700"
										href="https://www.cardboardconnection.com/sports-card-group-breaking-101"
									>
										{' '}
										Here's a great explanation provided by Cardboard Connection:
										{' '}
									</a>
								</p>

								<hr className="border-t-2 border-black" />

								<p className="text-lg font-semibold text-gray-800 mt-10 mb-2">
									<u>Question</u>
								</p>
								<p className="text-base font-normal text-gray-900  mb-4">
									What is a "Rip & Ship"?
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-4 mb-2">
									<u>Answer</u>
								</p>
								<p className="text-base font-normal text-gray-900  mb-10">
									A Rip & Ship is an event where a collector buys unopened
									cards, watches the seller "rip" (open) them, and then the
									seller mails the cards to the collector. Rip & ships are
									attractive to collectors for many reason. First, rather than
									leaving their home, the collector has the ability to choose
									among many types of cards and have them opened immediately. No
									searching local Target or Walmart stores to find cards. No
									waiting for unopened cards to arrive in the mail. The buyer
									gets the satisfaction of seeing their cards ripped and then
									the enjoyment of seeing them again when they arrive in the
									mail. There's also often a great community around rip & ships
									where collectors can comment on rips as they occur.
								</p>

								<hr className="border-t-2 border-black" />

								<p className="text-lg font-semibold text-gray-800 mt-10 mb-2">
									<u>Question</u>
								</p>
								<p className="text-base font-normal text-gray-900  mb-4">
									How does randomization work?
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-4 mb-2">
									<u>Answer</u>
								</p>
								<p className="text-base font-normal text-gray-900  mb-10">
									Many breaks (not all) use randomization to determine which
									slots go to which collectors. DJAWN performs this
									randomization automatically once a break has been filled.
									{' '}
									<Link
										to="/randomization-policy"
										activeClassName="bg-gray-200"
										className="inline  text-base text-blue-500 hover:text-blue-700"
									>
										See our Randomization Policy here
									</Link>
									.
								</p>

								<hr className="border-t-2 border-black" />

								<p className="text-lg font-semibold text-gray-800 mt-10 mb-2">
									<u>Question</u>
								</p>
								<p className="text-base font-normal text-gray-900  mb-4">
									Do you ship all cards or just the "hits"?
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-4 mb-2">
									<u>Answer</u>
								</p>
								<p className="text-base font-normal text-gray-900  mb-10">
									Any rip & ships or breaks will ship all cards unless otherwise
									noted.
								</p>

								<hr className="border-t-2 border-black" />

								<p className="text-lg font-semibold text-gray-800 mt-10 mb-2">
									<u>Question</u>
								</p>
								<p className="text-base font-normal text-gray-900  mb-4">
									What if I pull an expired redemption card in a break or a rip
									& ship?
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-8 mb-2">
									<u>Answer</u>
								</p>
								<p className="text-base font-normal text-gray-900  mb-10">
									Unfortunately, expired redemption cards are a risk with older
									cards. The buyer should attempt to redeem them via the
									manufacturer as some will still honor them. However, DJAWN is
									not responsible for fulfilling or exchanging any expired
									redemption cards.
								</p>

								<hr className="border-t-2 border-black" />

								<p className="text-lg font-semibold text-gray-800 mt-10 mb-2">
									<u>Question</u>
								</p>
								<p className="text-base font-normal text-gray-900  mb-4">
									I am unable to see video. Is there a problem with the site?
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-8 mb-2">
									<u>Answer</u>
								</p>
								<p className="text-base font-normal text-gray-900  mb-4">
									Please check your browser to see if is listed in the browsers
									we currently support.
								</p>

								<ul className="list-disc pl-4">
									<li className="text-base font-normal text-gray-900  mb-4">
										Android: Chrome and Firefox
									</li>
									<li className="text-base font-normal text-gray-900  mb-4">
										iOS: Safari
									</li>
									<li className="text-base font-normal text-gray-900  mb-4">
										Windows: Chrome, Firefox and Edge
									</li>
									<li className="text-base font-normal text-gray-900  mb-4">
										MacOS: Safari, Chrome, Firefox, Edge
									</li>
								</ul>
							</div>
						</div>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default FAQ;
