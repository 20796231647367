import React, { useContext, useEffect, useState } from 'react';
import { TwilioTrackContext } from '../../../contexts/twilio-track';
import Modal from '../../../components/modals/index';

const DeclineMicModalContent = () => (
	<div className="flex items-center justify-center px-4 py-4">
		<p>
			Your mic request is declined by the seller, you can request the mic again.
		</p>
	</div>
);
const ApproveMicModalContent = ({ onApproved, onCanceled }) => (
	<div>
		<p>Your mic request is approved. Do you want to activate your mic?</p>
		<div className="mt-3 flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5">
			<div className="mr-3">
				<button
					type="button"
					className="h-12 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-green-600 hover:bg-green-800  md:py-4 md:text-lg md:px-10"
					tabIndex={-1}
					onClick={onApproved}
				>
					Yes
				</button>
			</div>
			<div className="mr-3">
				<button
					type="button"
					tabIndex={-1}
					className="h-12 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-red-700 hover:bg-red-900 md:py-4 md:text-lg md:px-10"
					onClick={onCanceled}
				>
					No
				</button>
			</div>
		</div>
	</div>
);

const ParticipantMicRequestDecision = () => {
	const [showModal, setShowModal] = useState(false);

	const {
		setRequestedMicAccess,
		micAccessApprovedBySeller,
		setMicAccessApprovedByBuyer,
		setMicAccessApprovedBySeller,
		requestedMicAccess,
		deleteCurrentMicRequest
	} = useContext(TwilioTrackContext);

	useEffect(() => {
		if (micAccessApprovedBySeller == null) {
			setShowModal(false);
			return;
		}
		if (requestedMicAccess == false && micAccessApprovedBySeller != null) {
			setShowModal(true);
		} else {
			setShowModal(requestedMicAccess);
		}
	}, [micAccessApprovedBySeller, requestedMicAccess]);

	return (
		<Modal
			showModal={showModal}
			onClose={() => {
				setShowModal(false);
				if (requestedMicAccess == false) {
					setMicAccessApprovedBySeller(null);
				}

				deleteCurrentMicRequest(true);
				setRequestedMicAccess(false);
				setMicAccessApprovedByBuyer(false);
				setMicAccessApprovedBySeller(null);
			}}
			hideCloseButton
			hideHeader={micAccessApprovedBySeller === true}
			noOutsideClick
		>
			{micAccessApprovedBySeller === true && (
				<ApproveMicModalContent
					onApproved={() => {
						setRequestedMicAccess(false);
						setMicAccessApprovedByBuyer(true);
						setMicAccessApprovedBySeller(null);
					}}
					onCanceled={() => {
						deleteCurrentMicRequest(true);
						setRequestedMicAccess(false);
						setMicAccessApprovedByBuyer(false);
						setMicAccessApprovedBySeller(null);
					}}
				/>
			)}
			{micAccessApprovedBySeller === false && requestedMicAccess === false && (
				<DeclineMicModalContent />
			)}
		</Modal>
	);
};

export default ParticipantMicRequestDecision;
