/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable operator-linebreak */
/* eslint-disable prefer-template */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isMobile } from 'react-device-detect';
import { PencilAltIcon } from '@heroicons/react/solid';
import useErrorNotifier from '../hooks/use-error-notifier';

const EventEditDate = ({
	date,
	actualDate,
	setDate,
	showEditDate,
	setShowEditDate,
	isSeller,
	statusId,
	updateDate
}) => {
	const { showToastError } = useErrorNotifier();
	const [eventDate, setEventDate] = useState(date);
	const [minimumTime, setMinimumTime] = useState(date);
	const [eventTime, setEventTime] = useState(new Date().getDate());

	useEffect(() => {
		setMinimumTime(getMinimumTime(date));
		initFormData();
	}, []);

	const onEventTimeChange = (value) => {
		if (value == '') return;
		let time = value.split(':');

		setEventTime(value);
		let dateVal = date;
		dateVal.setHours(time[0]);
		dateVal.setMinutes(time[1]);
		setEventDate(dateVal);
	};

	const initFormData = () => {
		let hours;
		let minutes;
		if (eventDate) {
			let tempDate = new Date(Date.parse(eventDate));
			let formated_date = new Date(tempDate.setDate(tempDate.getDate()));
			hours = date.getHours();
			minutes = date.getMinutes();
			formated_date.setHours(hours, minutes, 0, 0);
			setEventDate(formated_date);
		} else {
			hours = date_today.getHours();
			minutes = date_today.getMinutes();
		}
		let sHours = hours.toString();
		let sMinutes = minutes.toString();
		if (hours < 10) sHours = '0' + sHours;
		if (minutes < 10) sMinutes = '0' + sMinutes;

		setEventTime(sHours + ':' + sMinutes);
	};

	const getMinimumTime = (value) => {
		let startDateYear = value.getFullYear();
		let startDateMonth = value.getMonth();
		let startDateDate = value.getDate();

		let currentDateYear = new Date().getFullYear();
		let currentDateMonth = new Date().getMonth();
		let currentDateDate = new Date().getDate();

		if (
			startDateYear === currentDateYear &&
			startDateMonth === currentDateMonth &&
			startDateDate === currentDateDate
		) {
			return new Date(0, 0, 0, new Date().getHours() + 1);
		} else {
			return new Date(0, 0, 0, 0);
		}
	};

	return (
		<>
			{showEditDate == true ? (
				<div>
					<DatePicker
						className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
						selected={date}
						minDate={new Date()}
						onChange={(selectedDate) => {
							setDate(selectedDate);
						}}
					/>

					<div className="mt-1 relative rounded-md shadow-sm grid justify-items-stretch step1-time-picker w-full md:w-48 ">
						<input
							type="time"
							value={eventTime}
							onChange={(ev) => onEventTimeChange(ev.target.value)}
							className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
						></input>
					</div>
				</div>
			) : isMobile ? (
				<p className="border-gray-200 rounded w-full px-4 text-gray-800 text-lg xxs:text-sm leading-tight">
					{actualDate}
				</p>
			) : (
				<p
					className="border-gray-200 rounded w-auto px-4 text-gray-800 text-lg xxs:text-sm leading-tight"
					style={{ whiteSpace: 'nowrap' }}
				>
					{actualDate}
				</p>
			)}

			{statusId == 68 && isSeller && (
				<>
					{showEditDate == true ? (
						<div
							className="flex justify-center items-center cursor-pointer hover:bg-opacity-50 ml-3"
							onClick={() => {
								const currentDate = new Date();

								if (date < currentDate) {
									showToastError('The date and time must not be in the past');
								} else {
									updateDate();
								}
							}}
						>
							<svg
								className="w-8 h-8"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M5 13l4 4L19 7"
								></path>
							</svg>
						</div>
					) : (
						<div
							className="flex justify-end items-center cursor-pointer hover:bg-opacity-50"
							onClick={() => setShowEditDate(true)}
						>
							<PencilAltIcon className="w-4 h-4" />
						</div>
					)}
				</>
			)}
		</>
	);
};

export default EventEditDate;
