import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import ShowApproveOrDeclineMicActions from './show-approve-or-decline-mic-actions';

const SellerMicDecisionMessage = ({ message }) => {
	const [attendee, setAttendee] = useState(null);
	const { attendees } = useContext(LiveEventDataContext);

	useEffect(() => {
		const foundAttendee = attendees.find(
			(at) => at.twilioParticipantId == message.request_twilio_participant_id
		);

		if (foundAttendee) {
			setAttendee(foundAttendee);
			return;
		}
		setAttendee(null);
	}, [JSON.stringify(attendees), message]);

	if (!message.request_twilio_participant_id && !message.resolved) {
		return (
			<p className="flex pl-6 py-1 text-sm font-semibold justify-start">
				The user canceled the request
			</p>
		);
	}

	if (message.resolved === true) {
		return (
			<p
				className={clsx(
					'flex pl-6 py-1 text-sm font-semibold justify-start',
					message.approved ? 'text-green-700' : 'text-djawn'
				)}
			>
				{message.approved
					? 'Mic request is approved'
					: 'Mic request is declined'}
			</p>
		);
	}

	if (!attendee) {
		return (
			<p className="flex pl-6 py-1 text-sm font-semibold justify-start">
				The user left the event
			</p>
		);
	}
	return (
		<ShowApproveOrDeclineMicActions
			twilioParticipantId={message.request_twilio_participant_id}
			userId={message.request_user_id}
		/>
	);
};

export default SellerMicDecisionMessage;
