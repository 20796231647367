/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const StripeExternalIdPage = ({ params }) => {
	useEffect(() => {
		const { id } = params;
		if (id) {
			navigate('/events', {
				state: {
					stripeAccountId: id
				}
			});
		}
	}, [params]);

	return (
		<>
			<h1 />
		</>
	);
};

export default StripeExternalIdPage;
