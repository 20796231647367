import React from 'react';
import { UserIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';

const AttendeesCount = ({ className, count }) => (
	<div
		className={clsx('space-x-1', className)}
		style={{
			alignItems: 'center',
			zIndex: '30',
			fontSize: isMobile ? '15px' : '23px'
		}}
	>
		<span>
			<UserIcon className="w-3.5 h-3.5 md:w-5 md:h-5" />
		</span>
		<p>{count}</p>
	</div>
);

export default AttendeesCount;
