/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import { referenceData } from '../../api/referenceData.request';
import NextButton from '../button/next-button';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

function ShippingAndPayments({ stepData, onPrev, onNext }) {
	const [referenceDataValue, setReferenceDataValue] = useState('');
	const [shippingTypesDataValue, setShippingTypesDataValue] = useState('');
	const [, setPaymentMethodDataValue] = useState('');
	const [shipCardValue, setShipCardValue] = useState(
		stepData.shipCardValue ? stepData.shipCardValue : ''
	);
	const [frequencyShipping, setFrequencyShipping] = useState(
		stepData.frequencyShipping ? stepData.frequencyShipping : ''
	);
	const [shippingType, setShippingType] = useState(
		stepData.shippingType ? stepData.shippingType : ''
	);
	const [, setPaymentUserName] = useState({});
	const [rate, setRate] = useState(stepData.rate);
	const [shipCardValueErr, setShipCardValueErr] = useState(false);
	const [shippingTypeErr, setShippingTypeErr] = useState(false);
	const [frequencyShippingErr, setFrequencyShippingErr] = useState(false);
	const [rateErr, setRateErr] = useState(false);
	const [loading, setLoading] = useState(true);
	const [shippingTypeText, setShippingTypeText] = useState('');
	const [shippingCardText, setShippingCardText] = useState('');

	useEffect(() => {
		const referenceTypeIdShipCards = 38;
		referenceData(referenceTypeIdShipCards)
			.then((res) => {
				setReferenceDataValue(res.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
		const referenceTypeIdShippingTypes = 39;
		referenceData(referenceTypeIdShippingTypes)
			.then((res) => {
				setShippingTypesDataValue(res.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
		const referenceTypeIdPaymentMethod = 40;
		referenceData(referenceTypeIdPaymentMethod)
			.then((res) => {
				setPaymentMethodDataValue(res.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		const value = stepData.paymentUserName;
		setPaymentUserName(value);
	}, []);

	const prevStep = () => {
		onPrev(3);
	};

	const nextStep = () => {
		if (!shipCardValue) {
			setShipCardValueErr(true);
		} else {
			setShipCardValueErr(false);
		}
		if (!frequencyShipping) {
			setFrequencyShippingErr(true);
		} else {
			setFrequencyShippingErr(false);
		}
		if (!shippingType) {
			setShippingTypeErr(true);
		} else {
			setShippingTypeErr(false);
		}
		if (!rate && shippingType === 139) {
			setRateErr(true);
		} else {
			setRateErr(false);
		}

		const setp4values = {
			shipCardValue,
			frequencyShipping,
			shippingType,
			shippingTypeText,
			shippingCardText,
			rate
		};

		let formStatus = false;
		if (
			setp4values.shipCardValue
			&& setp4values.frequencyShipping
			&& setp4values.shippingType
			&& ((setp4values.rate && setp4values.shippingType === 139)
				|| (!setp4values.rate && setp4values.shippingType != 139))
		) {
			formStatus = true;
		} else {
			formStatus = false;
		}

		if (formStatus) {
			onNext(setp4values);
		} else {
			// triggerError('Please fill all the fields.')
		}
	};

	const handleShippingRate = (rateValue) => {
		setRate(rateValue);
		if (!rateValue && shippingType === 139) {
			// return setRateErr(true);
		} else {
			setRateErr(false);
		}
	};

	const handleShipCards = (id, value) => {
		setShipCardValue(id);
		setShippingCardText(value);
		if (!id) {
			// return setShipCardValueErr(true);
		} else {
			setShipCardValueErr(false);
		}
	};

	const handleFrequencyShipping = (value) => {
		setFrequencyShipping(value);
		if (!value) {
			// return setFrequencyShippingErr(true);
		} else {
			setFrequencyShippingErr(false);
		}
	};

	const handleShippingType = (id, value) => {
		if (id !== 139) {
			setRate('');
		}
		setShippingType(id);
		setShippingTypeText(value);
		if (!id) {
			// return setShippingTypeErr(true);
		} else {
			setShippingTypeErr(false);
		}
	};

	return (
		<div className="flex-grow grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2  gap-0 mt-5">
			<div className="sm:col-auto md:col-span-1 lg:col-span-1 xl:col-s ">
				<div className="mb-6 sm:w-1/2 md:w-1/6 lg:w-full xl:w-full mt-6">
					<label
						htmlFor="ship_cards"
						className="block text-sm font-medium text-gray-700 pl-2 required"
					>
						Ship Cards
					</label>
					<div className="grid sm:grid-cols-1 grid-flow-row lg:grid-cols-3 gap-4 justify-items-center items-center">
						{referenceDataValue
							&& referenceDataValue.length > 0
							&& referenceDataValue.map((data) => (
								// <div class="mt-4">
								// <span class="text-gray-700">Account Type</span>
								<div className="mt-2 sm:w-full md:w-full lg:w-full xl:w-full">
									<label className="inline-flex items-center">
										<input
											type="radio"
											className="form-radio"
											name="shipCards"
											// value={data.referenceDataValue}
											checked={shipCardValue === data.referenceDataId}
											onChange={() => handleShipCards(
													data.referenceDataId,
													data.referenceDataValue
												)}
											// onBlur={handleShipCardsErr}
										/>
										<span className="ml-2">{data.referenceDataValue}</span>
									</label>
								</div>
								// </div>
							))}
					</div>
					{shipCardValueErr && (
						<p className="text-red-700 ">This field is required</p>
					)}
				</div>
			</div>
			<div className="sm:col-auto md:col-span-1 lg:col-span-1 xl:col-span-1">
				<div className="mb-6 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 mt-6">
					<label
						htmlFor="frequency_shipping"
						className="block text-sm font-medium text-gray-700 pl-2 required"
					>
						Frequency of shipping
					</label>
					<div className="mt-1 relative rounded-md shadow-sm">
						<input
							type="textarea"
							name="frequency_shipping"
							id="frequency_shipping"
							className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
							value={
								frequencyShipping.length <= 1
									? frequencyShipping.replace(/\s/g, '')
									: frequencyShipping
							}
							required
							maxLength={100}
							onChange={(e) => handleFrequencyShipping(e.target.value)}
						/>
					</div>
					{frequencyShippingErr && (
						<p className="text-red-700">This field is required</p>
					)}
				</div>
			</div>

			{loading && (
				<div className="w-screen h-screen bg-white absolute left-0 top-0">
					<div className="flex h-full items-center">
						<BounceLoader loading css={override} size={30} />
					</div>
				</div>
			)}

			<div className="sm:col-auto md:col-span-1 lg:col-span-1 xl:col-s">
				<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full mt-6 lg:col-span-2">
					<label
						htmlFor="ship_cards"
						className="block text-sm font-medium text-gray-700 pl-2 required"
					>
						Shipping Type
					</label>
					<div className="grid sm:grid-cols-1 grid-flow-row lg:grid-cols-3 gap-4 justify-items-center items-center">
						{shippingTypesDataValue
							&& shippingTypesDataValue.length > 0
							&& shippingTypesDataValue.map((data) => (
								<div className="mt-2 sm:w-full md:w-full lg:w-full xl:w-full">
									<label className="inline-flex items-center">
										<input
											type="radio"
											className="form-radio"
											name="shippingType"
											checked={shippingType === data.referenceDataId}
											onChange={() => handleShippingType(
													data.referenceDataId,
													data.referenceDataValue
												)}
										/>
										<span className="ml-2">{data.referenceDataValue}</span>
									</label>
								</div>
								// </div>
							))}
					</div>

					{shippingTypeErr && (
						<p className="text-red-700 shipping-type-validation">
							This field is required
						</p>
					)}
				</div>
				{shippingType === 139 && (
					<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full mt-6 lg:col-span-1">
						<label
							htmlFor="frequency_shipping"
							className="block text-sm font-medium text-gray-700 pl-2 required"
						>
							Rate
						</label>
						<div className="mt-1 relative rounded-md shadow-sm">
							<CurrencyInput
								id="rate"
								className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
								name="rate"
								placeholder="Please enter a value"
								decimalsLimit={2}
								defaultValue={rate}
								maxLength={10}
								value={rate}
								onValueChange={(value) => handleShippingRate(value)}
								intlConfig={{
									locale: 'en-US',
									currency: 'GBP'
								}}
								prefix="$"
							/>
						</div>
						{rateErr && <p className="text-red-700">This field is required</p>}
					</div>
				)}
			</div>

			<div className="grid flex flex-row xxs:flex-col md:flex-row h-16 xxs:h-12 xl:h-16 col-span-2 gap-4 mt-8 mb-5 md:mb-0">
				<p className="text-gray-500">
					Please note that these shipping options are defaults that can be
					changed for individual rips when planning the event.
				</p>
			</div>
			<div className="sm:col-auto md:col-span-1 lg:col-span-1 xl:col-s " />

			<div className="grid flex flex-row xxs:flex-col md:flex-row h-16 xxs:h-12 xl:h-16 col-span-2 gap-4 mt-8">
				<div className="flex justify-between mx-auto w-full">
					<div className="w-2/5">
						<NextButton
							variant="primary"
							overridenText="PREVIOUS"
							onClick={prevStep}
							width="full"
						/>
					</div>
					<div className="w-2/5">
						<NextButton variant="primary" onClick={nextStep} width="full" />
					</div>
				</div>
			</div>
		</div>
	);
}
export default ShippingAndPayments;
