import React, { useContext } from 'react';
import LinkedEventsMobileSliderChat from './linked-events-mobile-slider-chat';
import ConversationEventName from './conversation-event-name';
import { RipNShipActiveLineContext } from '../../../contexts/ripnship-activeline';

const ChatOverlayPlugin = ({
	showLinkedEventsSliderInMobileChatContainer,
	setShowLinkedEventsSliderInMobileChatContainer,
	linkedEvents,
	showEventNameInChatContainer,
	eventName,
	setShowEventNameInChatContainer
}) => {
	const { nameInActiveLine } = useContext(RipNShipActiveLineContext);
	if (showLinkedEventsSliderInMobileChatContainer) {
		return (
			<LinkedEventsMobileSliderChat
				opened={showLinkedEventsSliderInMobileChatContainer}
				onClose={() => setShowLinkedEventsSliderInMobileChatContainer(false)}
				linkedEvents={linkedEvents}
			/>
		);
	}
	if (showEventNameInChatContainer) {
		return (
			<ConversationEventName
				eventName={eventName}
				nameInActiveLine={nameInActiveLine}
				closeEventName={() => setShowEventNameInChatContainer(false)}
			/>
		);
	}
	return null;
};

export default ChatOverlayPlugin;
