import React, { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import HeaderLoginButton from '../../components/common/header-login-button';
import HeaderMobileAuthenticationButton from './header-mobile-authentication-button';
import HeaderAuthLinkButton from './header-auth-link-button';

const HeaderAuthenticationButtons = ({
	redirectLocation = undefined,
	onClick
}) => {
	const onClickHasValue = useMemo(
		() => onClick !== undefined && typeof onClick === 'function',
		[onClick]
	);

	return (
		<>
			{isMobile ? (
				<HeaderMobileAuthenticationButton
					onClick={onClick}
					onClickHasValue={onClickHasValue}
				/>
			) : (
				<>
					<HeaderAuthLinkButton
						to="/login"
						onClick={onClick}
						onClickHasValue={onClickHasValue}
						redirectLocation={redirectLocation}
					>
						<HeaderLoginButton />
					</HeaderAuthLinkButton>

					<HeaderAuthLinkButton
						to="/signup"
						onClick={onClick}
						onClickHasValue={onClickHasValue}
						redirectLocation={redirectLocation}
					>
						<HeaderLoginButton text="SIGN UP" />
					</HeaderAuthLinkButton>
				</>
			)}
		</>
	);
};
export default HeaderAuthenticationButtons;
