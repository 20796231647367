import clsx from 'clsx';
import React from 'react';

const SearchInput = ({
	value,
	setValue,
	placeholder = 'Search',
	searchIconLocation = 'right',
	hasShadows = false,
	variant = 'primary',
	widthClassName = 'lg:w-64 md:w-48',
	textSize = 'sm',
	disabled = false
}) => (
	<div
		className={clsx(
			'relative dark:text-white  dark:bg-darkGray-100 text-gray-600 mb-2 md:mb-0 rounded-md',
			widthClassName,
			hasShadows && 'shadow-md'
		)}
	>
		<input
			type="search"
			name="search"
			disabled={disabled}
			placeholder={placeholder}
			className={clsx(
				`h-10 px-5 input--${variant} text-${textSize}`,
				searchIconLocation === 'left' ? 'pl-10' : 'pl-3 pr-10',
				'focus:outline-none dark:bg-darkGray-100 border rounded-md w-full'
			)}
			value={value}
			onChange={({ target }) => setValue(target.value)}
		/>
		<div
			className={`absolute ${
				searchIconLocation === 'left' ? 'left-0 ml-4' : 'right-4'
			} top-0 mt-3`}
		>
			<svg
				className="h-4 w-4 fill-current"
				xmlns="http://www.w3.org/2000/svg"
				version="1.1"
				id="Capa_1"
				x="0px"
				y="0px"
				viewBox="0 0 56.966 56.966"
				width="512px"
				height="512px"
			>
				<path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
			</svg>
		</div>
	</div>
);

export default SearchInput;
