import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ClipboardIcon, ClipboardCheckIcon } from '@heroicons/react/solid';
import { navigate } from 'gatsby';
import Modal from '../../../components/modals';
import blockImage from '../../../images/block.png';
import Button from '../../../components/button/index';
import { notificationToast } from '../../../utils/information-toast';
import chromeLogo from '../../../images/chromelogo.png';
import safariLogo from '../../../images/safarilogo.png';
import TextInput from '../../../components/input/text-input';
import { validateEmail } from '../../../utils/commonFunction';
import { generateEventRequestLink } from '../../../api/event.request';
import SuccessModal from '../../../components/modals/success-modal';

const UnsupportedBrowserModal = ({ showModal, onClose, eventId }) => {
	const [emailAddress, setEmailAddress] = useState();
	const [validEmailAddress, setValidEmailAddress] = useState(false);
	const [copiedToClipboard, setCopiedToClipboard] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	useEffect(() => {
		if (!emailAddress) setValidEmailAddress(false);
		setValidEmailAddress(validateEmail(emailAddress));
	}, [emailAddress]);

	return (
		<>
			<SuccessModal
				isOpen={showSuccessModal}
				onClose={() => setShowSuccessModal(false)}
				onSuccess={() => navigate('/events')}
				body="In order to join the event, simply check your inbox and click on the link in the email we have just sent you. If it is not there, please check your spam mail folder if it is in spam mail folder, remember to mark the email as 'not spam'"
			/>
			<Modal
				showModal={showModal && !showSuccessModal}
				onClose={onClose}
				hideOverflow={false}
				hideCloseButton
				hideHeader
				addMarginTop={4}
				addBorderColor="djawn"
			>
				<div className="w-81">
					<div className="flex justify-between items-center px-2 py-3">
						<img src={blockImage} alt="block" className="h-16 w-16" />
						<div className="pl-4">
							<h4>
								Your browser does not support the technology requirements for
								DJAWN live event. Please choose from one of the options below to
								continue.
								{' '}
							</h4>
						</div>
					</div>
					<div className="border-gray-300 border-t-2 flex w-full px-3 pt-3 pb-3">
						<div className="flex">
							<div className="flex pr-4">
								<CopyToClipboard
									onCopy={() => {
										notificationToast('Link copied to clipboard!');
										setCopiedToClipboard(true);
									}}
									text={`${window.location.origin}/event/room/${eventId}`}
								>
									<Button
										variant="dark"
										width="20"
										mobileWidth="14"
										additionalClassName="flex items-center justify-center"
										height="16"
										mobileHeight="14"
										rounded={false}
									>
										{copiedToClipboard ? (
											<ClipboardCheckIcon className="h-10 w-10" />
										) : (
											<ClipboardIcon className="h-10 w-10" />
										)}
									</Button>
								</CopyToClipboard>
							</div>
							<div className="flex flex-col px-2">
								<p className="font-semibold text-left">
									1. Click the clipboard icon to copy the DJAWN link
								</p>
								<p className="font-semibold text-left">
									2. Next open a supported browser and paste the link.
								</p>
								<div className="flex justify-around">
									<img src={safariLogo} alt="safari" className="h-12 w-12" />
									<img src={chromeLogo} alt="chrome" className="h-12 w-12" />
								</div>
								<a
									href="https://support.djawn.com/supported-browsers/"
									target="_blank"
									rel="noreferrer"
									className="underline text-blue-600 hover:text-blue-800 flex justify-center visited:text-purple-600"
								>
									(Click here to see supported browsers)
								</a>
								<p className="flex justify-center">or</p>
							</div>
						</div>
					</div>
					<div className="flex flex-col px-16">
						<p className="font-semibold mb-2">
							Enter email address below and receive an email with a link to the
							event.
						</p>
						<TextInput
							height="8"
							mobileHeight="8"
							inputType="text"
							type={`${validEmailAddress ? 'validPrimary' : 'invalidPrimary'}`}
							placeholder="Enter email address here"
							value={emailAddress}
							rounded={false}
							mobileWidth="full"
							onChange={({ target }) => setEmailAddress(target.value)}
						/>
						<Button
							width="full"
							height="8"
							mobileWidth="full"
							mobileHeight="8"
							additionalClassName="mt-2"
							rounded={false}
							disabled={!validEmailAddress}
							onClick={() => {
								generateEventRequestLink(eventId, emailAddress)
									.then(() => {
										setShowSuccessModal(true);
									})
									.catch((err) => {
										console.error(err);
									});
							}}
						>
							Submit
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default UnsupportedBrowserModal;
