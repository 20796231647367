import React, { useEffect, useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import AutoComplete from 'react-google-autocomplete';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import InputMask from 'react-input-mask';
import clsx from 'clsx';
import { isTablet } from 'react-device-detect';
import upload from '../../images/upload.png';
import { getDefaultLogo, sellerStoreNameCheck } from '../../api/seller.request';
import env from '../../../environment.config';
import { findAddressComponent } from '../../utils/placesParser';
import ImageDimensionInfo from '../common/image-dimension-info';
import AvatarImageCropModal from '../avatar-image-crop';
import { defaultAutocompleteOptions } from '../../utils/autocomplete-utils';
import useScreenOrientation from '../../hooks/useScreenOrientation';
import NextButton from '../button/next-button';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

function StoreInformation({ stepData, onNext, triggerError }) {
	const [storeName, setStoreName] = useState(stepData.storeName);
	const [email, setEmail] = useState(stepData.email);
	const [phoneNumber, setPhoneNumber] = useState(stepData.phoneNumber);
	const [addressLine1, setAddressLine1] = useState(stepData.addressLine1);
	const [, setPhoto] = useState('');
	const [storeLogoName, setStoreLogoName] = useState(stepData.storeLogoName);
	const [defaultLogo, setDefaultLogo] = useState(stepData.storeLogo);
	const [postalCode, setPostalCode] = useState(stepData.postalCode);
	const [state, setState] = useState(stepData.state);
	const [city, setCity] = useState(stepData.city);
	const [apartmentSuite, setApartmentSuite] = useState(stepData.apartmentSuite);
	const [recaptcha, setRecaptcha] = useState('');
	const [photoSizeErr, setPhotoSizeErr] = useState(false);
	const [photoRatioErr, setPhotoRatioErr] = useState(false);
	const [storeNameErr, setStoreNameErr] = useState(false);
	const [storeNameExistErr, setStoreNameExistErr] = useState(true);
	const [emailErr, setEmailErr] = useState(false);
	const [emailValidErr, setEmailValidErr] = useState(false);
	const [phoneNumberErr, setPhoneNumberErr] = useState(false);
	const [addressLine1Err, setAddressLine1Err] = useState(false);
	const [postalCodeErr, setPostalCodeErr] = useState(false);
	const [stateErr, setStateErr] = useState(false);
	const [cityErr, setCityErr] = useState(false);
	const [recaptchaErr, setRecaptchaErr] = useState(false);
	const [, setStoreLogo] = useState(stepData.storeLogo);
	const [loading, setLoading] = useState(true);
	const [isRemoveBtn, setIsRemoveBtn] = useState(false);
	const placesRef = useRef();
	const [showCropper, setShowCropper] = useState(false);
	const [photoDataUrl, setPhotoDataUrl] = useState(null);
	const [defaultStoreLogoUrl, setDefaultStoreLogoUrl] = useState(null);
	const { isLandscape } = useScreenOrientation();

	function handleRecaptcha(value) {
		if (value) {
			setRecaptchaErr(false);
		} else {
			// setRecaptchaErr(true);
		}
		setRecaptcha(value);
	}

	function validateEmail(emailAddress) {
		if (emailAddress.length <= 1) {
			setEmailErr(false);
			setEmail(emailAddress.replace(/\s/g, ''));
			if (emailAddress.length === 1 && emailAddress === '') {
				setEmailValidErr(false);
			} else {
				setEmailValidErr(false);
			}
			if (emailAddress.length == 1 && emailAddress !== '') {
				setEmailValidErr(true);
			}
		} else if (emailAddress) {
			setEmail(emailAddress);
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			const result = re.test(String(emailAddress).toLowerCase());
			if (result === false) {
				setEmailValidErr(true);
				setEmailErr(false);
			} else {
				setEmailValidErr(false);
				setEmailErr(false);
			}
		} else {
			setEmail(emailAddress);
			setEmailValidErr(false);
		}
	}

	useEffect(() => {
		if (!photoDataUrl) return;

		setShowCropper(true);
	}, [photoDataUrl]);

	const nextStep = async () => {
		if (photoRatioErr) {
			setPhotoRatioErr(true);
		} else {
			setPhotoRatioErr(false);
		}
		if (photoSizeErr) {
			setPhotoSizeErr(true);
		} else {
			setPhotoSizeErr(false);
		}
		if (!storeNameExistErr) {
			setStoreNameExistErr(false);
		} else {
			setStoreNameExistErr(true);
		}
		if (!storeName) {
			setStoreNameErr(true);
		} else {
			setStoreNameErr(false);
		}
		if (!email) {
			setEmailErr(true);
		} else {
			setEmailErr(false);
		}
		if (emailValidErr) {
			setEmailValidErr(true);
		} else {
			setEmailValidErr(false);
		}
		if (!phoneNumber) {
			setPhoneNumberErr(true);
		} else {
			setPhoneNumberErr(false);
		}
		if (!addressLine1) {
			setAddressLine1Err(true);
		} else {
			setAddressLine1Err(false);
		}
		if (!city) {
			setCityErr(true);
		} else {
			setCityErr(false);
		}
		if (!state) {
			setStateErr(true);
		} else {
			setStateErr(false);
		}
		if (!postalCode) {
			setPostalCodeErr(true);
		} else {
			setPostalCodeErr(false);
		}
		if (!recaptcha) {
			setRecaptchaErr(true);
		} else {
			setRecaptchaErr(false);
		}

		const setp1values = {
			storeName,
			email,
			phoneNumber,
			addressLine1,
			apartmentSuite,
			city,
			state,
			postalCode,
			storeLogo: defaultLogo,
			storeLogoName
		};

		let formStatus = false;
		if (
			setp1values.storeName.length > 0
			&& setp1values.email.length > 0
			&& setp1values.phoneNumber.length > 0
			&& setp1values.addressLine1.length > 0
			&& setp1values.city.length > 0
			&& setp1values.state.length > 0
			&& setp1values.postalCode.length > 0
			&& recaptcha
			&& !emailValidErr
			&& !photoSizeErr
			&& storeNameExistErr
		) {
			formStatus = true;
		} else {
			formStatus = false;
		}

		if (formStatus) {
			onNext(setp1values);
		} else {
			// triggerError('Please fill all the fields.');
		}
	};

	const calculateTopToPlacesContainer = () => {
		const pacContainer = document.querySelector('.pac-container');

		if (pacContainer) {
			let top = 0;
			setTimeout(() => {
				top = placesRef.current.getBoundingClientRect().top + 40;
			}, 500);

			pacContainer.style.top = `${top}px`;

			// debounce(() => {
			// if (isDone) {
			//         pacContainer.style.top = `${top}px`;
			//         isDone = false;
			//     } else {
			//         // clearInterval(interval);
			//     }

			// }, 500);

			// const interval = setInterval(() => {

			// }, 100);
		}
	};

	const getBlobFromUrl = (myImageUrl) => new Promise((resolve, reject) => {
		const request = new XMLHttpRequest();
		request.open('GET', myImageUrl, true);
		request.responseType = 'blob';
		request.onload = () => {
			resolve(request.response);
		};
		request.onerror = reject;
		request.send();
	});

	const getDataFromBlob = (myBlob) => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.onerror = reject;
		reader.readAsDataURL(myBlob);
	});
	const convertUrlToImageData = async (myImageUrl) => {
		try {
			const myBlob = await getBlobFromUrl(myImageUrl);
			const myImageData = await getDataFromBlob(myBlob);
			setDefaultLogo(myImageData);
			return myImageData;
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	function handleDefaultLogo() {
		setLoading(true);
		if (!defaultLogo && !storeLogoName) {
			getDefaultLogo()
				.then((res) => {
					setStoreLogoName('defaultlogo.png');
					setDefaultStoreLogoUrl(res.data);
					setIsRemoveBtn(false);
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					console.error(err);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			if (storeLogoName !== 'defaultlogo.png') {
				setIsRemoveBtn(true);
			}
			setLoading(false);
		}
	}

	function handleRemoveImage() {
		setLoading(true);
		setPhotoRatioErr(false);
		setPhotoSizeErr(false);
		getDefaultLogo()
			.then((res) => {
				convertUrlToImageData(res.data);
				setIsRemoveBtn(false);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const ratioAspectImage = async (file) => {
		if (file) {
			const outputImageAspectRatio = 1;
			const imageAspect = await ImageDimensionInfo(file);
			const inputImageAspectRatio = imageAspect.width / imageAspect.height;
			if (inputImageAspectRatio != outputImageAspectRatio) {
				setPhotoRatioErr(true);
			} else {
				setPhotoRatioErr(false);
			}
		}
	};

	function handlePhoto(e) {
		if (e && e.target && e.target.files && e.target.files[0]) {
			if (e.target.files[0].size > 2097152) {
				setPhotoSizeErr(true);
				triggerError(
					'You cannot upload a file bigger than 2MB.Please upload an image smaller than 2MB'
				);
				return;
			}

			ratioAspectImage(e.target.files[0]);
			setStoreLogoName(e.target.files[0].name);
			setStoreLogo(e.target.files[0]);
		} else {
			setStoreLogoName('');
			setStoreLogo('');
		}

		let bytes = 0;
		if (e.target.files[0] && e.target.files[0].size) {
			bytes = e.target.files[0].size;
		}

		const kb = (bytes / 1024).toFixed(2);

		if (kb > 2048) {
			setPhotoSizeErr(true);
			triggerError(
				'You cannot upload a file bigger than 2MB.Please upload an image smaller than 2MB'
			);
			return;
		}

		const file = e.target.files[0];
		const reader = new FileReader();

		if (reader !== undefined && file !== undefined) {
			reader.onloadend = () => {
				// setDefaultLogo(reader.result);
				setPhotoDataUrl(reader.result);
			};
			reader.readAsDataURL(file);
		}

		if (kb > 2048) {
			setPhotoSizeErr(true);
		} else {
			setPhotoSizeErr(false);
			setPhoto(e.target.files[0]);
		}
	}
	const checkStoreName = () => {
		sellerStoreNameCheck(storeName)
			.then((res) => {
				setStoreNameExistErr(res.data);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleStoreName = (value) => {
		if (value) {
			setStoreNameErr(false);
			setStoreNameExistErr(true);
			if (value.length <= 1) {
				setStoreName(value.replace(/\s/g, ''));
			} else {
				setStoreName(value);
			}
		} else {
			setStoreName(value);
			// setStoreNameErr(true);
		}
	};

	const handlePhoneNumber = (event) => {
		setPhoneNumber(event.target.value.replace(/\s/g, ''));

		if (event.target.value) {
			setPhoneNumberErr(false);
		} else {
			// setPhoneNumberErr(true);
		}
	};

	useEffect(() => {
		// handleAddressLine1();

		if (addressLine1) {
			setAddressLine1Err(false);
		} else {
			// setAddressLine1Err(true);
		}
		if (!city) {
			// setCityErr(true);
		} else {
			setCityErr(false);
		}
		if (!state) {
			// setStateErr(true);
		} else {
			setStateErr(false);
		}
		if (!postalCode) {
			// setPostalCodeErr(true);
		} else {
			setPostalCodeErr(false);
		}
	}, [addressLine1, city, state, postalCode]);

	const handleAddressLine1 = (event) => {
		if (event) {
			if (event.target.value === '') {
				setAddressLine1('');
			}
		}

		if (addressLine1) {
			setAddressLine1Err(false);
		}
		if (city) {
			setCityErr(false);
		}
		if (!state) {
			// setStateErr(true);
		} else {
			setStateErr(false);
		}
		if (!postalCode) {
			// setPostalCodeErr(true);
		} else {
			setPostalCodeErr(false);
		}
	};

	const beforeMaskedValueChange = (newState, oldState, userInput) => {
		let { value } = newState;
		let { selection } = newState;
		let cursorPosition = selection ? selection.start : null;

		// keep minus if entered by user
		if (value.endsWith('-') && userInput !== '-') {
			if (cursorPosition === value.length) {
				cursorPosition--;
				selection = { start: cursorPosition, end: cursorPosition };
			}
			value = value.slice(0, -1);
		}

		return {
			value,
			selection
		};
	};

	useEffect(() => {
		handleDefaultLogo();
		calculateTopToPlacesContainer();

		window.addEventListener(
			'scroll',
			() => {
				calculateTopToPlacesContainer(20);
			},
			true
		);

		return () => window.removeEventListener(
			'scroll',
			() => {
				calculateTopToPlacesContainer(20);
			},
			true
		);
	}, []);

	return (
		<div
			className={clsx(
				'flex-grow flex justify-center grid lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-1 gap-0 mt-5',
				isTablet && !isLandscape ? 'md:grid-cols-1`' : 'md:grid-cols-4'
			)}
		>
			<AvatarImageCropModal
				open={showCropper}
				setOpen={setShowCropper}
				image={photoDataUrl}
				onUpload={(img) => {
					const xhr = new XMLHttpRequest();
					xhr.responseType = 'blob';

					xhr.onload = function () {
						const recoveredBlob = xhr.response;

						const reader = new FileReader();

						reader.onload = function () {
							const blobAsDataUrl = reader.result;

							setIsRemoveBtn(true);
							setDefaultLogo(blobAsDataUrl);
							setShowCropper(false);
						};

						reader.readAsDataURL(recoveredBlob);
					};

					xhr.open('GET', img);
					xhr.send();
				}}
			/>

			<div className="sm:col-auto md:col-span-1 lg:col-span-1 xl:col-s store-logo-info">
				<div
					className={clsx(
						'mb-6 sm:w-full md:w-full lg:w-full xl:w-full',
						isTablet && !isLandscape ? 'flex flex-col items-center' : ''
					)}
				>
					<label
						htmlFor="photos"
						className="block text-sm font-medium text-gray-700 pl-2"
					>
						{isRemoveBtn && isRemoveBtn
							? 'Your logo'
							: 'Default photo for your store'}
					</label>
					{loading ? (
						<div className="flex h-full items-center">
							<BounceLoader loading css={override} size={30} />
						</div>
					) : (
						<>
							<div className="w-48 store-logo">
								{isRemoveBtn && (
									<span
										className="float-right cursor-pointer"
										onClick={handleRemoveImage}
										onKeyDown={handleRemoveImage}
									>
										X
									</span>
								)}
								<img
									alt="default store"
									htmlFor="default_photo"
									id="defaultLogo"
									src={defaultLogo || defaultStoreLogoUrl}
									width="192px"
									height="192px"
								/>
							</div>
							<div className="mt-1 relative rounded-md shadow-sm">
								<label htmlFor="photo" className="custom-file-upload fas">
									<div className="img-wrap img-upload">
										<img alt="upload" htmlFor="photo" src={upload} />
										{' '}
										<span className="text-red-700">Upload Image</span>
									</div>
									<input
										type="file"
										name="photo"
										id="photo"
										className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
										// placeholder="unit/apartment number"
										// value={photo ? photo : ""}
										accept="image/png, image/jpg, image/jpeg"
										// onBlur={handlePhoto}
										onChange={handlePhoto}
									/>
								</label>
							</div>
						</>
					)}
					{photoSizeErr && (
						<p className="text-red-700">Maximum size can be 2 mb</p>
					)}

					<ul className="list-disc mt-4 pr-6 store-info-ul">
						<li>
							It should be of one of these
							<br />
							{' '}
format : jpeg, jpg, png
						</li>
						<li> Maximum size can be 2 mb</li>
					</ul>
				</div>
			</div>

			<div className="sm:col-auto md:col-span-3 lg:col-span-3 xl:col-span-3">
				<div className="flex-grow px-0 grid sm:grid-rows-3 md:grid-rows-3 lg:grid-rows-1 xl:grid-rows-1 lg:grid-flow-col xl:grid-flow-col gap-4">
					<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
						<label
							htmlFor="store_name"
							className="block text-sm font-medium text-gray-700 pl-2 required"
						>
							Store Name
						</label>
						<div className="mt-1 relative rounded-md shadow-sm">
							<input
								autoComplete="autocomplete_off_hack_xfr4!k"
								type="text"
								name="store_name"
								id="store_name"
								className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
								placeholder="Name of your store"
								value={storeName}
								required
								maxLength={30}
								// onKeyPress={(e) => handleOnKeyPress(e.target.value, e)}
								onBlur={checkStoreName}
								onChange={(e) => handleStoreName(e.target.value)}
							/>
							{storeNameErr && (
								<p className="text-red-700">This field is required</p>
							)}
							{!storeNameExistErr && (
								<p className="text-red-700">
									Store name is already used for another seller application
									please use another store name
								</p>
							)}
						</div>
					</div>

					<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
						<label
							htmlFor="email"
							className="block text-sm font-medium text-gray-700 pl-2 required"
						>
							Email
						</label>
						<div className="mt-1 relative rounded-md shadow-sm">
							<input
								type="text"
								name="email"
								id="email"
								className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
								placeholder="Example : store@store.com"
								value={email}
								required
								maxLength={50}
								// onBlur={(e) => validateEmail(e.target.value)}
								onChange={(e) => validateEmail(e.target.value)}
							/>
							{emailErr && (
								<p className="text-red-700">This field is required</p>
							)}
							{emailValidErr && (
								<p className="text-red-700">Please enter valid Email address</p>
							)}
						</div>
					</div>

					<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
						<label
							htmlFor="phone_number"
							className="block text-sm font-medium text-gray-700 pl-2 required"
						>
							Phone Number
						</label>
						<div className="mt-1 relative rounded-md shadow-sm">
							<InputMask
								className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
								placeholder="Example : +1 (205) 258-5898"
								value={phoneNumber}
								onChange={handlePhoneNumber}
								beforeMaskedValueChange={beforeMaskedValueChange}
								mask="+1 (999) 999-9999"
								maskChar=" "
							/>

							{phoneNumberErr && (
								<p className="text-red-700">This field is required</p>
							)}
						</div>
					</div>
				</div>

				<div className="flex-grow px-0 grid sm:grid-rows-2 md:grid-rows-2 lg:grid-rows-2 xl:grid-rows-2 lg:grid-flow-col xl:grid-flow-col gap-4">
					<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full store-address">
						<label
							htmlFor="address_line1"
							className="block text-sm font-medium text-gray-700 pl-2 required"
						>
							Address
						</label>
						<div className="mt-1 relative rounded-md shadow-sm ">
							<AutoComplete
								apiKey={env.google_places_key}
								ref={placesRef}
								onPlaceSelected={(place, e) => {
									const streetNumber = findAddressComponent(
										place.address_components,
										'street_number'
									);
									const foundPostalCode = findAddressComponent(
										place.address_components,
										'postal_code'
									);

									if (place.vicinity) setCity(place.vicinity);
									if (!place.vicinity) setCity('');

									const route = findAddressComponent(
										place.address_components,
										'route'
									);

									if (route) {
										if (streetNumber) {
											setAddressLine1(`${streetNumber} ${route}`);
											if (e.value) {
												e.value = `${streetNumber} ${route}`;
											}
										} else {
											setAddressLine1(route);
											if (e.value) {
												e.value = route;
											}
										}
									}

									if (foundPostalCode) setPostalCode(foundPostalCode);

									if (!foundPostalCode) setPostalCode('');

									const foundState = findAddressComponent(
										place.address_components,
										'administrative_area_level_1',
										true
									);

									if (foundState) setState(foundState);
									if (!foundState) setState('');
								}}
								className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
								options={defaultAutocompleteOptions}
								defaultValue={
									addressLine1.length <= 1
										? addressLine1.replace(/\s/g, '')
										: addressLine1
								}
								placeholder="123 Ave Rackford, WI, 6383q - Required value"
								// onBlur={handleAddressLine1}
								onChange={handleAddressLine1}
								onFocus={() => {
									calculateTopToPlacesContainer();
								}}
								onBlur={() => {
									calculateTopToPlacesContainer();
								}}
							/>

							{addressLine1Err && (
								<p className="text-red-700">This field is required</p>
							)}
						</div>
					</div>

					<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full store-apartment">
						<label
							htmlFor="apartment_suite"
							className="block text-sm font-medium text-gray-700 pl-2 "
						>
							Apartment,suite,etc
						</label>
						<div className="mt-1 relative rounded-md shadow-sm">
							<input
								type="text"
								name="apartment_suite"
								id="apartment_suite"
								className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
								placeholder="1716 East 7th Street"
								value={
									apartmentSuite.length <= 1
										? apartmentSuite.replace(/\s/g, '')
										: apartmentSuite
								}
								// required
								maxLength={100}
								onBlur={(e) => setApartmentSuite(e.target.value)}
								onChange={(e) => setApartmentSuite(e.target.value)}
							/>
							{/* {
								apartmentSuiteErr && <p className="text-red-700" >This field is required</p>
							} */}
						</div>
					</div>
				</div>

				<div className="flex px-0 grid sm:grid-rows-3 md:grid-rows-3 lg:grid-rows-1 xl:grid-rows-1 lg:grid-flow-col xl:grid-flow-col gap-4">
					<div className="flex-1 mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
						<label
							htmlFor="city"
							className="block text-sm font-medium text-gray-700 pl-2 required"
						>
							City
						</label>
						<div className="mt-1 relative rounded-md shadow-sm">
							<input
								type="text"
								name="city"
								id="city"
								className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
								placeholder="Los Angeles"
								value={city}
								required
								disabled
								onBlur={(e) => setCity(e.target.value)}
								onChange={(e) => setCity(e.target.value)}
							/>
							{cityErr && (
								<p className="text-red-700">This field is required</p>
							)}
						</div>
					</div>

					<div className="flex-1 mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
						<label
							htmlFor="state"
							className="block text-sm font-medium text-gray-700 pl-2 required"
						>
							State
						</label>
						<div className="mt-1 relative rounded-md shadow-sm">
							<input
								type="text"
								name="state"
								id="state"
								className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
								placeholder="New York"
								value={state}
								required
								disabled
								onBlur={(e) => setState(e.target.value)}
								onChange={(e) => setState(e.target.value)}
							/>

							{stateErr && (
								<p className="text-red-700">This field is required</p>
							)}
						</div>
					</div>

					<div className="flex-1 mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
						<label
							htmlFor="postal_code"
							className="block text-sm font-medium text-gray-700 pl-2 required"
						>
							Postal code
						</label>
						<div className="mt-1 relative rounded-md shadow-sm">
							<input
								type="text"
								name="postal_code"
								id="postal_code"
								className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
								placeholder="90021"
								value={postalCode}
								required
								disabled
								onBlur={(e) => setPostalCode(e.target.value)}
								onChange={(e) => setPostalCode(e.target.value)}
							/>
							{postalCodeErr && (
								<p className="text-red-700">This field is required</p>
							)}
						</div>
					</div>
				</div>

				<div className="flex-grow px-0 grid grid-rows-2 grid-flow-col gap-4 sm:mt-2 lg:mt-6 ">
					<div className="mb-0 sm:w-full md:w-full lg:w-full xl:w-full store-recaptcha">
						<label
							htmlFor="recaptcha"
							className="block text-sm font-medium text-gray-700 pl-2"
						>
							Please check the box below to procced.
						</label>
						<div className="mt-1 relative rounded-md shadow-sm">
							<ReCAPTCHA
								theme="light"
								size="normal"
								type="image"
								badge="inline"
								sitekey={env.google_captcha_key}
								onChange={handleRecaptcha}
							/>
						</div>
						{recaptchaErr && (
							<p className="text-red-700">This field is required</p>
						)}
					</div>

					<div className="grid flex flex-row xxs:flex-col md:flex-row h-16 xxs:h-12 xl:h-16 col-span-2 gap-4 mt-8">
						<div className="flex justify-between mx-auto">
							<div className="w-full">
								<NextButton variant="primary" onClick={nextStep} width="full" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default StoreInformation;
