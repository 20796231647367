import React from 'react';

const CustomError = ({ error }) => (error && (error.shortMessage || error.longMessage) ? (
	<>
		<h1>
				⚠️
			{error.shortMessage}
		</h1>
		<br />
		<p>{error.longMessage}</p>
		<br />
		{error.suggestAction && <p className="pt-2">{error.suggestAction}</p>}
	</>
) : null);

export default CustomError;
