import React, { createContext } from 'react';
import useLiveEventChat from '../hooks/useLiveEventChat';

const LiveEventChatContext = createContext();

const LiveEventChatProvider = ({ eventId, userId, children }) => {
	const { conversation, initializeConversation } = useLiveEventChat(
		userId,
		eventId
	);

	return (
		<LiveEventChatContext.Provider
			value={{
				conversation,
				initializeConversation
			}}
		>
			{children}
		</LiveEventChatContext.Provider>
	);
};

export { LiveEventChatContext, LiveEventChatProvider };
