import React from 'react';

export default ({ ...restProps }) => (
	<svg
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...restProps}
	>
		<rect width="24" height="24" transform="translate(0.845947)" fill="none" />
		<path
			d="M22.1266 18.424C21.7788 19.2275 21.3671 19.9672 20.8901 20.6472C20.2398 21.5743 19.7074 22.216 19.2971 22.5724C18.6611 23.1573 17.9797 23.4568 17.2499 23.4739C16.726 23.4739 16.0943 23.3248 15.3589 23.0224C14.621 22.7214 13.943 22.5724 13.323 22.5724C12.6728 22.5724 11.9754 22.7214 11.2295 23.0224C10.4824 23.3248 9.88063 23.4824 9.42051 23.498C8.72073 23.5278 8.02323 23.2197 7.327 22.5724C6.88263 22.1848 6.32682 21.5204 5.66097 20.5791C4.94657 19.5739 4.35924 18.4084 3.89911 17.0795C3.40633 15.6442 3.1593 14.2543 3.1593 12.9087C3.1593 11.3673 3.49237 10.0379 4.15949 8.92385C4.68379 8.029 5.38129 7.32312 6.25427 6.80493C7.12725 6.28674 8.0705 6.02267 9.08631 6.00578C9.64212 6.00578 10.371 6.1777 11.2768 6.51559C12.18 6.85462 12.7599 7.02655 13.0142 7.02655C13.2043 7.02655 13.8486 6.82552 14.9408 6.42473C15.9736 6.05305 16.8453 5.89916 17.5594 5.95978C19.4945 6.11595 20.9483 6.87876 21.9151 8.25303C20.1845 9.30163 19.3284 10.7703 19.3454 12.6544C19.361 14.122 19.8934 15.3432 20.9397 16.3129C21.4139 16.7629 21.9435 17.1107 22.5327 17.3578C22.4049 17.7283 22.27 18.0832 22.1266 18.424ZM17.6886 0.960131C17.6886 2.11039 17.2684 3.18439 16.4307 4.17847C15.4199 5.36023 14.1973 6.04311 12.8714 5.93536C12.8545 5.79736 12.8447 5.65213 12.8447 5.49951C12.8447 4.39526 13.3254 3.21349 14.1791 2.24724C14.6053 1.75801 15.1473 1.35122 15.8047 1.02671C16.4606 0.707053 17.081 0.530273 17.6645 0.5C17.6815 0.653772 17.6886 0.807554 17.6886 0.960116V0.960131Z"
			fill="white"
		/>
	</svg>
);
