import React, { createContext } from 'react';
import useRipNShipActiveLine from '../hooks/useRipNShipActiveLine';

const RipNShipActiveLineContext = createContext();

const RipNShipActiveLineProvider = ({
	nameInActiveLine,
	setNameInActiveLine,
	breakIsInActiveLine,
	setBreakIsInActiveLine,
	children
}) => {
	const {
		setUserWantsToKeepItemsWsMessage,
		ripNShipTimers,
		setRipNShipActiveBuyersInLine,
		ripNShipActiveBuyersInLine,
		setRipNShipTimers,
		disableRipNShipFunctionality,
		setDisableRipNShipFunctionality,
		joinedQueue,
		setJoinedQueue,
		endingTurn,
		setEndingTurn
	} = useRipNShipActiveLine(
		nameInActiveLine,
		setNameInActiveLine,
		breakIsInActiveLine,
		setBreakIsInActiveLine
	);

	return (
		<RipNShipActiveLineContext.Provider
			value={{
				setUserWantsToKeepItemsWsMessage,
				ripNShipTimers,
				setRipNShipActiveBuyersInLine,
				ripNShipActiveBuyersInLine,
				setRipNShipTimers,
				disableRipNShipFunctionality,
				setDisableRipNShipFunctionality,
				joinedQueue,
				setJoinedQueue,
				nameInActiveLine,
				endingTurn,
				setEndingTurn,
				breakIsInActiveLine,
				setBreakIsInActiveLine
			}}
		>
			{children}
		</RipNShipActiveLineContext.Provider>
	);
};

export { RipNShipActiveLineContext, RipNShipActiveLineProvider };
