import { Link } from 'gatsby';
import React from 'react';

const HeaderAuthLinkButton = ({
	children,
	to,
	onClick,
	onClickHasValue,
	redirectLocation,
	className = ''
}) => {
	if (onClickHasValue) {
		return (
			<div
				onClick={() => onClick(to)}
				onKeyDown={() => onClick(to)}
				className={className}
			>
				{children}
			</div>
		);
	}

	return (
		<Link
			to={to}
			state={{ redirectUrl: redirectLocation }}
			className={className}
		>
			{children}
		</Link>
	);
};

export default HeaderAuthLinkButton;
