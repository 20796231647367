import React from 'react';
import Button from '.';

const NextButton = ({
	onClick,
	overridenText = 'NEXT',
	disabled = false,
	variant = 'secondary',
	width = 'auto'
}) => (
	<Button
		variant={variant}
		width={width}
		height="auto"
		mobileHeight="auto"
		mobileWidth={width}
		textSize="md"
		disabled={disabled}
		additionalClassName="py-2 px-6 font-bold shadow-md flex justify-center"
		onClick={onClick}
	>
		{overridenText}
	</Button>
);

export default NextButton;
