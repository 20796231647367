import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useInterval } from '../common/use-interval';
import { getElapsedTimestampWithCurrentDate } from '../../utils/formatter';

const VideoTimestamp = ({
	date,
	className,
	margin = 'initial',
	marginRight = 'initial',
	children
}) => {
	const [timestamp, setTimestamp] = useState('00:00:00');

	useInterval(() => {
		setTimestamp(getElapsedTimestampWithCurrentDate(date));
	}, 1000);

	return (
		<p
			className={className}
			style={{
				marginRight,
				alignItems: 'center',
				fontSize: isMobile ? '15px' : '25px',
				zIndex: '30',
				margin
			}}
		>
			{children ? (
				<>
					<span>{timestamp}</span>
					<span className="w-full">{children}</span>
				</>
			) : (
				timestamp
			)}
		</p>
	);
};

export default VideoTimestamp;
