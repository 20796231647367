import React from 'react';
import { connect } from 'react-redux';
import Feeds from '../components/feeds';
import {
	ALMOST_FULL,
	DEFAULT_FEEDS_SELLERS_SIZE
} from '../utils/listTypeConstants';

const LiveFeedsPage = ({
	cartItems, showMobileSearch, dispatch, location
}) => (
	<Feeds
		cartItems={cartItems}
		showMobileSearch={showMobileSearch}
		dispatch={dispatch}
		location={location}
		groupedBySeller
		type={ALMOST_FULL}
		pageSize={400 || DEFAULT_FEEDS_SELLERS_SIZE}
	/>
);

export default connect(
	(state) => ({
		cartItems: state.cart.cartItems,
		showMobileSearch: state.utils.showMobileSearch
	}),
	null
)(LiveFeedsPage);
