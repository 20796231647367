import React, { useState, useEffect, useCallback } from 'react';
import { ToastContainer } from 'react-toastify';
import {
	Tab, Tabs, TabList, TabPanel
} from 'react-tabs';
import { useQueryParam, StringParam } from 'use-query-params';
import { isMobile } from 'react-device-detect';
import Layout from '../components/layouts/layout-default';
import HeaderHome from '../components/common/header-home';
import 'react-tabs/style/react-tabs.css';
import ProfilePage from './user-profile';
import SellerEditViewProfilePage from '../components/seller/seller-profile';
import { getSellerProfile } from '../api/seller.request';
import Sidebar from '../components/common/sidebar';

const ProfileViewerPage = ({ location }) => {
	const [tabValue, setTabValue] = useState(0);
	const [showSellerTab, setShowSellerTab] = useState(true);
	const [shipmentCodeToSave, setShipmentCodeToSave] = useState(null);
	const [merchantId] = useQueryParam('merchantId', StringParam);
	const [paypalMerchantId] = useQueryParam('merchantIdInPayPal', StringParam);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	useEffect(() => {
		getSellerProfile()
			.then(() => {
				setShowSellerTab(true);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (paypalMerchantId && merchantId) {
			setTabValue(1);
		}
	}, [paypalMerchantId]);

	useEffect(() => {
		if (!location.state || !showSellerTab) return;
		if (location.state.shipmentCode) {
			setShipmentCodeToSave(location.state.shipmentCode);
			setTabValue(1);
		}
	}, [location.state, showSellerTab]);

	const toggleSidebar = useCallback(() => {
		setIsSidebarOpen(!isSidebarOpen);
	}, [isSidebarOpen]);

	return (
		<Layout auth>
			{isMobile ? (
				<Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
			) : null}
			<div className="flex flex-col flex-1">
				<HeaderHome hideSearch onChange={toggleSidebar} />
				<div className="">
					<ToastContainer />

					{showSellerTab ? (
						<div className="flex flex-col min-w-0 break-words bg-white w-full rounded h-full">
							<div className="mx-5">
								<Tabs
									className="w-full"
									onSelect={(e) => setTabValue(e)}
									selectedIndex={tabValue}
									selectedTabClassName="selected-tab"
								>
									<TabList className="cursor-pointer flex mb-0 list-none flex-wrap flex-row bg-gray-200 rounded-lg">
										<Tab className="-mb-px last:mr-0 flex-auto pt-3 pb-4 text-center ">
											Personal
										</Tab>

										<Tab className="-mb-px last:mr-0 flex-auto pt-3 pb-4 text-center">
											Seller
										</Tab>
									</TabList>
									<TabPanel className="tab-content tab-space">
										<ProfilePage />
									</TabPanel>
									<TabPanel className="tab-content tab-space">
										<SellerEditViewProfilePage
											paypalMerchantId={paypalMerchantId}
											merchantId={merchantId}
											shipmentCodeToSave={shipmentCodeToSave}
										/>
									</TabPanel>
								</Tabs>
							</div>
						</div>
					) : (
						<ProfilePage />
					)}
				</div>
			</div>
		</Layout>
	);
};

export default ProfileViewerPage;
