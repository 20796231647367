import React, {
	useEffect,
	useState,
	useMemo,
	useCallback,
	useRef
} from 'react';
import { ToastContainer } from 'react-toastify';
import { css } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import BounceLoader from 'react-spinners/BounceLoader';
import DataTable from 'react-data-table-component';
import { navigate } from 'gatsby-link';
import { NumberParam, useQueryParam } from 'use-query-params';
import HeaderHome from '../../components/common/header-home';
import Layout from '../../components/layouts/layout-default';
import ShipmentFilter from '../../containers/shipment/shipment-filter';
import { isMobileAndNotTablet } from '../../utils/device';
import {
	getLabelUrl,
	getShipmentList,
	getShipmentTransactionsByPotentialGroupedShipmentIds,
	updatePotentialGroupedShipmentsStatus
} from '../../api/shipment.request';
import { formatUTCDateToLocal } from '../../utils/formatter';
import ShipmentActions from '../../containers/shipment/shipment-actions';
import EllipisWithTooltip from '../../components/ellipsis-tooltip';
import CheckboxInput from '../../components/input/checkbox-input';
import { getDetailedSlotName } from '../../utils/slot';
import { RIP_N_SHIP } from '../../utils/constants';
import {
	SHIPPING_LABEL_IGNORED,
	SHIPPING_LABEL_PENDING_EVENT_COMPLETION,
	SHIPPING_LABEL_PENDING_LABEL_CREATION
} from '../../utils/constants/shipping-labels';
import { openPopupWindow } from '../../utils/window';
import ConfirmationModal from '../../components/confirmation-modal';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const ShipmentPage = ({ location }) => {
	const [loading, setLoading] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const [selectedItems, setSelectedItems] = useState([]);
	const [pendingShipments, setPendingShipments] = useState([]);
	const tooltipTimeoutRef = useRef(null);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const [totalPotentialShipments, setTotalPotentialShipments] = useState(0);
	const [
		showMultipleLabelsWillBeCreatedForEveryBuyerModal,
		setShowMultipleLabelsWillBeCreatedForEveryBuyerModal
	] = useState(false);
	const [
		selectedShippingLabelStatusId = SHIPPING_LABEL_PENDING_LABEL_CREATION,
		setSelectedShippingLabelStatusId
	] = useQueryParam('shippingLabelStatusId', NumberParam);
	const [
		showIgnorePotentialGroupedShipmentsModal,
		setShowIgnorePotentialGroupedShipmentsModal
	] = useState(false);

	const addOrRemoveSelectedItem = useCallback((item, removeItem) => {
		if (removeItem) {
			setSelectedItems((selectedItem) => selectedItem.filter((i) => JSON.stringify(i) !== JSON.stringify(item)));
			return;
		}
		setSelectedItems((selectedItem) => [...selectedItem, item]);
	}, []);

	useEffect(() => {
		setCurrentPage(0);
	}, [selectedShippingLabelStatusId]);

	const columns = useMemo(
		() => [
			{
				name: 'Buyer',
				selector: (row) => row.buyer.buyerName + row.buyer.buyerMailingAddress1,
				cell: (shipment) => (
					<div className="w-full py-1">
						<p>{shipment.buyer.buyerName}</p>
						<div className="w-full">
							<p>
								{shipment.buyer.buyerMailingAddress1}
								<br />
							</p>
							{shipment.buyer.buyerMailingAddress2 ? (
								<p>{shipment.buyer.buyerMailingAddress2}</p>
							) : null}
							<p>
								{shipment.buyer.buyerMailingCity}
,
								{shipment.buyer.buyerMailingState}
,
								{shipment.buyer.buyerMailingZip}
							</p>
						</div>
					</div>
				),
				sortable: true,
				wrap: true,
				width: '13%'
			},
			{
				name: 'Event Date',
				cell: (shipment) => (
					<div className="flex flex-col space-y-1 py-2 w-30">
						{shipment.orderDetails.map((orderDetail) => {
							let eventDateTime = formatUTCDateToLocal(
								orderDetail.event.eventTime,
								'DD-MMM-YYYY HH:mm'
							);
							eventDateTime = eventDateTime.replace(/--/g, '-');
							return (
								<p
									className="flex items-center justify-center h-8"
									key={orderDetail.orderDetailsId}
								>
									{eventDateTime}
									<br />
								</p>
							);
						})}
					</div>
				),
				sortable: true,
				wrap: true,
				width: '10%'
			},
			{
				name: 'Event #',
				cell: (shipment) => (
					<div className="flex flex-col space-y-1 py-2 w-full">
						{shipment.orderDetails.map((orderDetail) => (
							<a
								className="link underline cursor-pointer"
								href={`${window.location.origin}/event/${orderDetail.eventId}`}
								rel="noreferrer"
								target="_blank"
								key={orderDetail.orderDetailsId}
							>
								<span
									key={orderDetail.orderDetailsId}
									className="flex items-center justify-start h-8"
								>
									<EllipisWithTooltip
										isParagraph
										placement="bottom"
										isMultiline
										className="text-sm line-clamp-2"
									>
										{orderDetail.event.eventNumber}
										<br />
									</EllipisWithTooltip>
								</span>
							</a>
						))}
					</div>
				),
				sortable: true,
				wrap: true,
				width: '7%'
			},
			{
				name: 'Event',
				cell: (shipment) => (
					<div className="flex flex-col space-y-1 py-2 w-full">
						{shipment.orderDetails.map((orderDetail) => (
							<a
								className="link underline cursor-pointer"
								href={`${window.location.origin}/event/${orderDetail.eventId}`}
								rel="noreferrer"
								target="_blank"
								key={orderDetail.orderDetailsId}
							>
								<span className="flex items-center justify-start h-8">
									<EllipisWithTooltip
										isParagraph
										placement="bottom"
										isMultiline
										className="text-sm line-clamp-2"
									>
										{orderDetail.event.eventName}
										<br />
									</EllipisWithTooltip>
								</span>
							</a>
						))}
					</div>
				),
				width: '13%',
				sortable: true,
				wrap: true,
				left: true
			},
			{
				name: 'Order #',
				cell: (shipment) => (
					<div className="flex flex-col space-y-1 py-2 w-18">
						{shipment.orderDetails.map((orderDetail) => (
							<p
								className="flex items-center justify-start h-8"
								key={orderDetail.orderDetailsId}
							>
								{orderDetail.order.orderNumber}
								<br />
							</p>
						))}
					</div>
				),
				sortable: true,
				width: '6%'
			},
			{
				name: 'Item',
				selector: (row) => row.orderDetails && row.orderDetails.length,
				cell: (shipment) => (
					<div className="flex flex-col space-y-1 py-2 w-full">
						{shipment.orderDetails.map((orderDetail) => {
							if (orderDetail.event.eventTypeId === RIP_N_SHIP) {
								return (
									<a
										className="link underline cursor-pointer"
										href={`${window.location.origin}/product/${orderDetail.productId}`}
										rel="noreferrer"
										target="_blank"
										key={orderDetail.orderDetailsId}
									>
										<span className="flex items-center justify-start h-8">
											<EllipisWithTooltip
												isParagraph
												placement="bottom"
												isMultiline
												className="text-sm line-clamp-2"
											>
												{orderDetail.event.eventTypeId === RIP_N_SHIP
													? orderDetail.productName
													: getDetailedSlotName(orderDetail)}
												<br />
											</EllipisWithTooltip>
										</span>
									</a>
								);
							}
							return (
								<span
									key={orderDetail.orderDetailsId}
									className="flex items-center justify-start h-8"
								>
									<EllipisWithTooltip
										isParagraph
										placement="bottom"
										isMultiline
										className="text-sm line-clamp-2"
									>
										{orderDetail.event.eventTypeId === RIP_N_SHIP
											? orderDetail.productName
											: getDetailedSlotName(orderDetail)}
										<br />
									</EllipisWithTooltip>
								</span>
							);
						})}
					</div>
				),
				width: '15%',
				sortable: true,
				wrap: true
			},
			{
				// name: 'Shipping Label Status',
				selector: (shipment) => shipment.shippingLabelStatus,
				name: <h1 className="line-clamp-2">Shipping Label Status</h1>,
				cell: (shipment) => (
					<div className="flex flex-col space-y-1 py-2 w-full">
						<p className="flex items-center h-7">
							{shipment.shippingLabelStatus}
						</p>
					</div>
				),
				width: '10%',
				sortable: true
			},
			{
				name: <h1 className="line-clamp-2">Label Cost</h1>,
				selector: (row) => row.labelCost,
				cell: (shipment) => {
					if (shipment.labelCost == null) return null;
					return (
						<div className="flex flex-col space-y-1 py-2 w-full">
							<p className="flex items-center justify-between h-7">
								<span>$</span>
								<span>{Number(shipment.labelCost).toFixed(2)}</span>
							</p>
						</div>
					);
				},
				width: '7%',
				sortable: true
			},
			{
				name: <h1 className="line-clamp-2">Label Purchased Date</h1>,
				selector: (row) => row.shipmentDate,
				cell: (shipment) => {
					if (shipment.shipmentDate == null) return null;
					let date = formatUTCDateToLocal(
						shipment.shipmentDate,
						'DD-MMM-YYYY HH:mm'
					);
					date = date.replace(/--/g, '-');
					return (
						<div className="flex flex-col space-y-1 py-2 w-30">
							<p className="flex items-center justify-center h-8">
								{date}
								<br />
							</p>
						</div>
					);
				},
				width: '13%',
				sortable: true
			},
			{
				name: 'Select',
				cell: (row) => {
					const itemExists = selectedItems.find(
						(item) => JSON.stringify(item) == JSON.stringify(row)
					);
					return (
						<CheckboxInput
							checked={itemExists != null}
							disabled={
								selectedShippingLabelStatusId
								== SHIPPING_LABEL_PENDING_EVENT_COMPLETION
							}
							onChange={() => addOrRemoveSelectedItem(row, itemExists)}
							className="h-6 w-6"
							type="primary"
						/>
					);
				},
				right: true,
				width: '6%'
			}
		],
		[selectedItems, selectedShippingLabelStatusId]
	);

	const getShipmentsData = useCallback(
		async (filters) => {
			setLoading(true);
			let shipments = [];
			try {
				const shipmentsRes = await getShipmentList(
					filters.shippingLabelStatusId,
					currentPage,
					rowsPerPage,
					filters.searchValue
				);
				shipments = JSON.parse(shipmentsRes.data.potentialShipments);
				setTotalPotentialShipments(shipmentsRes.data.totalPotentialShipments);
			} catch (err) {
				console.error(err);
			}

			setPendingShipments(shipments);
			setLoading(false);

			tooltipTimeoutRef.current = setTimeout(() => {
				ReactTooltip.rebuild();
			}, 2000);
		},
		[currentPage, rowsPerPage]
	);

	const onSelectAll = useCallback(
		(selectAll) => {
			if (selectAll) {
				setSelectedItems([...pendingShipments]);
			} else {
				setSelectedItems([]);
			}
		},
		[pendingShipments]
	);

	const handlePerRowsChange = (rows) => {
		setRowsPerPage(rows);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page - 1);
	};

	const getShipmentLabelDetailsByPotentialGroupedShipmentId = useCallback(
		async (potentialGroupedShipmentIds) => {
			const shipmentsRes = await getShipmentTransactionsByPotentialGroupedShipmentIds(
				potentialGroupedShipmentIds
			);
			return shipmentsRes.data.map((transaction) => ({
				transactionId: transaction.externalTransactionId,
				buyerAddress1: transaction.buyerMailingAddress1,
				buyerAddress2: transaction.buyerMailingAddress2,
				buyerState: transaction.buyerState,
				buyerCity: transaction.buyerCity,
				buyerZip: transaction.buyerZip
			}));
		},
		[]
	);

	const fetchLabelUrls = useCallback(async (transactions) => {
		try {
			const urls = await Promise.all(
				transactions.map(async (transaction) => {
					try {
						const urlRes = await getLabelUrl(transaction.transactionId);
						return {
							...transaction,
							url: urlRes.data
						};
					} catch (err) {
						console.error(err);
						return {
							...transaction,
							url: ''
						};
					}
				})
			);

			return urls;
		} catch (error) {
			console.error(error);
			return [];
		}
	}, []);

	const showPrintLabelsPopup = useCallback(
		async (potentialGroupedShipmentIds) => {
			const transactions = await getShipmentLabelDetailsByPotentialGroupedShipmentId(
				potentialGroupedShipmentIds
			);

			const urls = await fetchLabelUrls(transactions);

			const stringifiedLabels = urls.map((label) => JSON.stringify(label));

			let urlsEncodedParams = stringifiedLabels.map(encodeURIComponent);
			urlsEncodedParams = `?urls[]=${urlsEncodedParams.join('&urls[]=')}`;
			openPopupWindow(
				`${window.origin}/shipment/label/print${urlsEncodedParams}`,
				'Labels',
				1200,
				1100
			);
		},
		[]
	);

	useEffect(() => {
		if (!location.state) return;
		if (
			location.state.potentialGroupedShipmentIdsToPrint
			&& location.state.potentialGroupedShipmentIdsToPrint.length
		) {
			showPrintLabelsPopup(location.state.potentialGroupedShipmentIdsToPrint);
			navigate('/shipments');
		}
	}, [location]);

	useEffect(() => {
		document.body.style.overflowY = 'hidden';

		return () => {
			document.body.style.overflowY = 'auto';
		};
	}, []);

	return (
		<Layout auth>
			<ReactTooltip />
			<div className="flex flex-col flex-1 overflow-hidden">
				<ConfirmationModal
					showModal={showMultipleLabelsWillBeCreatedForEveryBuyerModal}
					setShowModal={setShowMultipleLabelsWillBeCreatedForEveryBuyerModal}
					onConfirm={() => navigate('/shipment/label', {
						state: {
							selectedItems
						}
					})}
					showConfirmationIcon={false}
					cancelText="No"
					confirmText="Yes"
					onCancel={() => {
						setShowMultipleLabelsWillBeCreatedForEveryBuyerModal(false);
					}}
					text="Multiple labels will be created for each buyer. Do you want to continue?"
				/>
				<ConfirmationModal
					showModal={showIgnorePotentialGroupedShipmentsModal}
					setShowModal={setShowIgnorePotentialGroupedShipmentsModal}
					onConfirm={() => {
						updatePotentialGroupedShipmentsStatus(
							selectedItems.map((item) => item.potentialGroupedShipmentId),
							SHIPPING_LABEL_IGNORED
						)
							.then(() => {
								getShipmentsData({
									shippingLabelStatusId: selectedShippingLabelStatusId,
									searchValue
								});
								setShowIgnorePotentialGroupedShipmentsModal(false);
								setSelectedItems([]);
							})
							.catch((err) => {
								console.error(err);
							});
					}}
					showConfirmationIcon={false}
					cancelText="Cancel"
					confirmText="Continue"
					onCancel={() => {
						setShowIgnorePotentialGroupedShipmentsModal(false);
					}}
					text="You have selected one or more orders to hide/ignore. Please click continue to hide these records."
				/>
				<HeaderHome hideSearch setLoading={setLoading} />
				<div className="flex flex-col flex-grow w-full h-full mt-4 items-center">
					<ToastContainer />
					<div className="flex flex-col bg-white rounded-lg content-center justify-center w-full">
						<div className="w-full justify-center pb-8 shadow-lg">
							<ShipmentFilter
								onFilter={() => {
									setSelectedItems([]);
									getShipmentsData({
										shippingLabelStatusId: selectedShippingLabelStatusId,
										searchValue
									});
								}}
								setSearchValue={setSearchValue}
								searchValue={searchValue}
								selectedShippingLabelStatusId={selectedShippingLabelStatusId}
								setSelectedShippingLabelStatusId={
									setSelectedShippingLabelStatusId
								}
								currentPage={currentPage}
								rowsPerPage={rowsPerPage}
							/>
							<div
								className={`${
									isMobileAndNotTablet
										? 'flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-between lg:items-stretch w-full'
										: 'grid w-full overflow-x-scroll xl:overflow-x-hidden rounded-md'
								}`}
							>
								<div className="flex flex-col px-1 py-1">
									<ShipmentActions
										onSelectAll={onSelectAll}
										itemsLength={selectedItems.length}
										shippingLabelStatusIds={selectedItems.map(
											(item) => item.shippingLabelStatusId
										)}
										onIgnore={() => {
											setShowIgnorePotentialGroupedShipmentsModal(true);
										}}
										itemsInPageLength={
											rowsPerPage > pendingShipments.length
												? pendingShipments.length
												: rowsPerPage
										}
										onBuyLabel={() => {
											if (selectedItems.length > 1) {
												setShowMultipleLabelsWillBeCreatedForEveryBuyerModal(
													true
												);
												return;
											}
											navigate('/shipment/label', {
												state: {
													selectedItems
												}
											});
										}}
										selectedShippingLabelStatusId={
											selectedShippingLabelStatusId
										}
										hasAtLeastOneItemSelected={selectedItems.length > 0}
										onCancelLabel={() => {
											console.warn('Cancel label is not developed yet');
										}}
										onPrintLabel={() => showPrintLabelsPopup(
											selectedItems.map(
												(item) => item.potentialGroupedShipmentId
											)
										)}
									/>
									{loading ? (
										<div className="flex h-full items-center">
											<BounceLoader loading css={override} size={30} />
										</div>
									) : (
										<DataTable
											columns={columns}
											data={pendingShipments}
											pagination
											fixedHeader
											fixedHeaderScrollHeight="60vh"
											paginationDefaultPage={currentPage + 1}
											onChangeRowsPerPage={handlePerRowsChange}
											onChangePage={handlePageChange}
											noContextMenu
											className=""
											contextActions={null}
											noDataComponent="No results found"
											striped
											paginationTotalRows={totalPotentialShipments}
											paginationComponentOptions={{
												rowsPerPageText: 'Rows per page:',
												rangeSeparatorText: 'of',
												noRowsPerPage: false,
												selectAllRowsItem: true,
												selectAllRowsItemText: 'All'
											}}
											paginationServer
											paginationRowsPerPageOptions={[10, 25, 100]}
											paginationPerPage={rowsPerPage}
											customStyles={{
												rows: {
													style: {}
												},
												headCells: {
													style: {
														backgroundColor: '#e82e2c',
														color: 'white',
														fontSize: '1rem',
														fontWeight: 'bold'
													}
												}
											}}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ShipmentPage;
