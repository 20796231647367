import clsx from 'clsx';
import React from 'react';
import ErrorFieldMessage from '../error-field-message';

const TextArea = ({
	width = 'full',
	variant = 'primary',
	mobileWidth = 'full',
	height = '12',
	mobileHeight = 'auto',
	textSize = 'sm',
	type = 'text',
	additionalClassName = '',
	containerClassName = '',
	error = '',
	resizeable = true,
	...restProps
}) => (
	<div
		className={clsx(
			error
				&& `flex flex-col items-center justify-center ${additionalClassName}`,
			containerClassName
		)}
	>
		<textarea
			type={type}
			className={clsx(
				`input input--${variant}`,
				`w-${mobileWidth} md:w-${width} h-${mobileHeight} md:h-${height} text-${textSize}`,
				!resizeable && 'resize-none',
				!error && additionalClassName,
				error && 'input--invalid'
			)}
			{...restProps}
		/>
		{error && <ErrorFieldMessage error={error} />}
	</div>
);

export default TextArea;
