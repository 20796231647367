import React, {
	useState, useMemo, useContext, useEffect
} from 'react';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../components/button';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import { TwilioTrackContext } from '../../contexts/twilio-track';

const clipLoaderCss = css`
	display: block;
	border-color: white;
`;

const ShowApproveOrDeclineMicActions = ({ twilioParticipantId, userId }) => {
	const {
		audioParticipants,
		approveOrDeclineMicRequest,
		usersApprovingMic,
		setUsersApprovingMic,
		usersDecliningMic,
		setUsersDecliningMic
	} = useContext(TwilioTrackContext);
	const { isHost } = useContext(LiveEventDataContext);
	const [audioParticipant, setAudioParticipant] = useState(null);

	useEffect(() => {
		if (
			!twilioParticipantId
			|| !userId
			|| !audioParticipants
			|| !audioParticipants.length
		) return;

		const foundAudioParticipant = audioParticipants.find(
			(a) => a.twilioParticipantId == twilioParticipantId
		);
		if (foundAudioParticipant) {
			setAudioParticipant(foundAudioParticipant);
		}
	}, [audioParticipants, twilioParticipantId, userId]);

	const approvingMic = useMemo(
		() => usersApprovingMic
			&& usersApprovingMic.length
			&& usersApprovingMic.includes(userId),
		[usersApprovingMic, userId]
	);

	const decliningMic = useMemo(
		() => usersDecliningMic
			&& usersDecliningMic.length
			&& usersDecliningMic.includes(userId),
		[usersDecliningMic, userId]
	);

	if (!isHost) return <></>;

	if (!audioParticipant) {
		return (
			<div className="flex flex-row space-x-4 px-4 py-2">
				<Button
					height="6"
					disabled={approvingMic}
					additionalClassName={
						approvingMic ? 'flex items-center justify-center' : ''
					}
					onClick={() => {
						setUsersApprovingMic((prev) => [...prev, userId]);
						approveOrDeclineMicRequest(
							[
								{
									userId,
									twilioParticipantId
								}
							],
							true,
							false
						)
							.then(() => {
								setUsersApprovingMic((prev) => prev.filter((u) => u != userId));
							})
							.catch((err) => {
								console.error(err);
							});
					}}
				>
					{approvingMic ? <ClipLoader size={15} css={clipLoaderCss} /> : null}
					<>
						<span>Approve</span>
					</>
				</Button>
				<Button
					height="6"
					disabled={decliningMic}
					additionalClassName={
						decliningMic ? 'flex items-center justify-center' : ''
					}
					onClick={() => {
						setUsersDecliningMic((prev) => [...prev, userId]);
						approveOrDeclineMicRequest(
							[
								{
									userId,
									twilioParticipantId
								}
							],
							false,
							false
						)
							.then(() => {
								setUsersDecliningMic((prev) => prev.filter((u) => u != userId));
							})
							.catch((err) => {
								console.error(err);
							});
					}}
				>
					{decliningMic ? <ClipLoader size={15} css={clipLoaderCss} /> : null}
					<>
						<span>Decline</span>
					</>
				</Button>
			</div>
		);
	}
	return <></>;
};

export default ShowApproveOrDeclineMicActions;
