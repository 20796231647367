import React, { createContext } from 'react';
import useSellerEventAttendeeActions from '../hooks/useSellerEventAttendeeActions';

const SellerEventAttendeeActionsContext = createContext();

const SellerEventAttendeeActionsProvider = ({
	eventId,
	children,
	websocketGroupName
}) => {
	const {
		setBanUserNickname,
		setUserNicknameToBan,
		userNicknameToBan,
		selectedUserForActions,
		setSelectedUserForActions
	} = useSellerEventAttendeeActions(eventId, websocketGroupName);

	return (
		<SellerEventAttendeeActionsContext.Provider
			value={{
				setBanUserNickname,
				setUserNicknameToBan,
				userNicknameToBan,
				selectedUserForActions,
				setSelectedUserForActions
			}}
		>
			{children}
		</SellerEventAttendeeActionsContext.Provider>
	);
};

export {
	SellerEventAttendeeActionsProvider,
	SellerEventAttendeeActionsContext
};
