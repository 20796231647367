import React, {
	useCallback, useEffect, useRef, useState
} from 'react';
import clsx from 'clsx';
import Modal from '../../components/modals/v2';
import {
	sendEmailVerificationCode,
	verifyEmailVerificationCode
} from '../../api/auth.request';
import useErrorNotifier from '../../hooks/use-error-notifier';
import CountdownTimer from '../../components/timer/countdown-timer';
import { SuccessAnimationIcon } from '../../components/icons';

const CODE_LENGTH = 6;

const VerifyEmailModal = ({
	showModal, setShowModal, email, onVerified
}) => {
	const [submitting, setSubmitting] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [expirationTimerInSeconds, setExpirationTimerInSeconds] = useState(
		null
	);
	const [disableResend, setDisableResend] = useState(true);

	const inputRefs = useRef([]);

	const { showToastError } = useErrorNotifier();

	const sendVerificationCode = useCallback(
		async (resend = false) => {
			if (resend && disableResend) return;

			try {
				const sentEmailVerificationCodeResponse = await sendEmailVerificationCode(
					email
				);
				if (
					sentEmailVerificationCodeResponse
					&& sentEmailVerificationCodeResponse.data
				) {
					if (resend) {
						setDisableResend(true);
					}
					setExpirationTimerInSeconds(
						sentEmailVerificationCodeResponse.data.expirationCodeInSeconds
					);
				}
			} catch (err) {
				showToastError(err);
				setShowModal(false);
			}
		},
		[email, disableResend]
	);

	useEffect(() => {
		if (!email) return;
		sendVerificationCode();
	}, [email]);

	const focusInputToTheNextEmptyInput = useCallback(() => {
		let index = 0;
		for (let i = 0; i < inputRefs.current.length; i++) {
			if (inputRefs.current[i].value === '') {
				index = i;
				break;
			}
		}

		inputRefs.current[index].focus();
	}, []);

	const onSubmit = useCallback(
		async (code) => {
			setSubmitting(true);

			try {
				const verifiedResult = await verifyEmailVerificationCode(email, code);

				setExpirationTimerInSeconds(null);
				setShowSuccess(true);

				setTimeout(() => {
					if (onVerified && typeof onVerified === 'function') {
						onVerified(verifiedResult);
					}
				}, 2000);
			} catch (error) {
				if (
					!error
					|| !error.response
					|| !error.response.data
					|| !error.response.data.error
				) {
					showToastError(
						'The code your provided is invalid. Please try again.'
					);
					setSubmitting(false);
					for (let i = 0; i < inputRefs.current.length; i++) {
						inputRefs.current[i].value = '';
					}
					inputRefs.current[0].focus();
				} else {
					showToastError(error);
					setSubmitting(false);
				}
			}
		},
		[email]
	);

	return (
		<Modal
			closeOnOutsideClick={false}
			showModal={showModal}
			setShowModal={setShowModal}
		>
			<div
				className="p-3 pb-5 rounded text-center md:w-full w-72"
				onClick={focusInputToTheNextEmptyInput}
				onKeyDown={focusInputToTheNextEmptyInput}
			>
				<span className="text-xl md:text-2xl font-bold">
					Please enter the received code in your e-mail to verify your email
					address
				</span>
				<div className="flex flex-col mt-4">
					{expirationTimerInSeconds && (
						<CountdownTimer
							timerInSeconds={expirationTimerInSeconds}
							onCountdownExpired={() => setShowModal(false)}
							on30SecondsReached={() => setDisableResend((prev) => !prev)}
						/>
					)}
					<div
						id="otp"
						className="flex flex-row justify-center text-center px-2 mt-5"
					>
						{Array(CODE_LENGTH)
							.fill(0)
							.map((_, i) => (
								<input
									key={i}
									className="m-2 border md:h-10 md:w-10 h-8 w-8 text-center form-control rounded phone-number-code-input"
									type="number"
									onClick={(e) => {
										e.stopPropagation();
									}}
									// eslint-disable-next-line jsx-a11y/no-autofocus
									autoFocus={i === 0}
									max={9}
									onPaste={(e) => {
										e.preventDefault();

										const clipboardData =											e.clipboardData || window.clipboardData;
										const pastedText = clipboardData.getData('text/plain');

										for (let j = 0; j < CODE_LENGTH; j++) {
											inputRefs.current[j].value = pastedText[j];
										}

										if (
											inputRefs.current.every((input) => input.value !== '')
										) {
											onSubmit(
												inputRefs.current.map((input) => input.value).join('')
											);
										}
									}}
									disabled={submitting}
									onKeyDown={(e) => {
										e.stopPropagation();
										const characterCode = e.key;
										if (characterCode === 'Backspace') {
											inputRefs.current[i].value = '';
											if (i > 0 && inputRefs.current[i - 1]) {
												inputRefs.current[i - 1].focus();
											}
										}
									}}
									onChange={() => {
										focusInputToTheNextEmptyInput();

										if (
											inputRefs.current.every((input) => input.value !== '')
										) {
											onSubmit(
												inputRefs.current.map((input) => input.value).join('')
											);
										}
									}}
									// eslint-disable-next-line no-return-assign
									ref={(el) => (inputRefs.current[i] = el)}
									maxLength="1"
								/>
							))}
					</div>
					<div className="flex justify-center text-center mt-5">
						{showSuccess ? (
							<SuccessAnimationIcon className="h-12 w-12" />
						) : (
							<a
								className={clsx(
									'flex items-center',
									submitting || disableResend
										? 'cursor-not-allowed text-gray-400'
										: 'text-blue-700 hover:text-blue-900 cursor-pointer'
								)}
								onClick={() => sendVerificationCode(true)}
								onKeyDown={() => sendVerificationCode(true)}
							>
								<span className="font-bold">Resend Code</span>
								<i className="bx bx-caret-right ml-1" />
							</a>
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default VerifyEmailModal;
