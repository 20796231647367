import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';

const SuccessAnimationIcon = ({
	isDarkMode,
	className = '',
	containerClassName = ''
}) => (
	<div className={containerClassName}>
		<svg
			className={clsx('success-checkmark', className)}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 52 52"
		>
			<circle
				className="success-checkmark__circle"
				cx="26"
				cy="26"
				r="25"
				fill={isDarkMode ? '#201F1F' : '#fff'}
			/>
			<path
				className="success-checkmark__check"
				fill="none"
				d="M14.1 27.2l7.1 7.2 16.7-16.8"
			/>
		</svg>
	</div>
);

export default connect(
	(state) => ({ isDarkMode: state.theme.isDarkMode }),
	null
)(SuccessAnimationIcon);
