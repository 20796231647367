export const isWebView = () => {
	const { standalone } = window.navigator;
	const userAgent = window.navigator.userAgent.toLowerCase();
	const safari = /safari/.test(userAgent);
	const ios = /iphone|ipod|ipad/.test(userAgent);

	if (ios) {
		if (!standalone && !safari) {
			return true;
		}
		return false;
	} if (userAgent.includes('wv')) {
		return true;
	}

	return false;
};

// will check if its a facebook app
export const isFacebookApp = () => {
	const ua = navigator.userAgent || navigator.vendor || window.opera;
	return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
};

export const isInstagramApp = () => {
	const ua = navigator.userAgent || navigator.vendor || window.opera;
	return (ua.indexOf('Instagram') > -1);
};
