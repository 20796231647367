import React from 'react';
import HamburgerMenuIcon from '../../../assets/icons/hamburger-menu-icon';

const EventQueueFooterButtons = ({ onClick }) => (
	<>
		<div className="flex justify-center items-center mt-4">
			<button
				type="button"
				className="bg-white border border-djawn dark:bg-darkGray-50 rounded-full h-10 w-10 flex items-center justify-center focus:outline-none"
				onClick={onClick}
			>
				<HamburgerMenuIcon className="w-8 h-8" />
			</button>
		</div>
	</>
);

export default EventQueueFooterButtons;
