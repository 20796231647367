/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* eslint-disable one-var-declaration-per-line */
/* eslint-disable one-var */
/* eslint-disable no-sequences */
/* eslint-disable no-return-assign */

export const validateEmail = (email) => {
	if (email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const result = re.test(String(email).toLowerCase());
		return result;
	}
};

export const PhoneNumberFormat = (phoneNumber) => {
	const result = phoneNumber.replace(/\D/g, '')
		.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3$4');
	return result;
};

/**
 * Groups an array of objects by a key an returns an object or array grouped by provided key.
 * @param array - array to group objects by key.
 * @param key - key to group array objects by.
 * @param removeKey  - remove the key and it's value from the resulting object.
 * @param outputType - type of structure the output should be contained in.
 */
export const groupBy = (
	inputArray,
	key,
	removeKey = false,
	outputType = {},
) => inputArray.reduce(
	(previous, current) => {
		// Get the current value that matches the input key and remove the key value for it.
		const {
			[key]: keyValue
		} = current;
		// remove the key if option is set
		// eslint-disable-next-line no-unused-expressions
		removeKey && keyValue && delete current[key];
		// If there is already an array for the user provided key use it else default to an empty array.
		const {
			[keyValue]: reducedValue = []
		} = previous;

		// Create a new object and return that merges the previous with the current object
		return Object.assign(previous, {
			[keyValue]: reducedValue.concat(current)
		});
	},
	// Replace the object here to an array to change output object to an array
	outputType,
);

export const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export const debounce = (func, wait, immediate) => {
	let timeout;
	return () => {
		// eslint-disable-next-line no-undef
		const context = this, args = arguments;
		const later = () => {
			timeout = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) {
			func.apply(context, args);
		}
	};
};

export const isTruthy = (value) => !!value;

export const bytesToMegaBytes = (bytes) => bytes / (1024 * 1024);

export const setCurrency = (
	value,
	callback,
	callbackWithCurrency,
	currency = '$'
) => {
	if (value[0] == currency) {
		const valueWithoutCurrency = value.substring(1);
		if (isNaN(valueWithoutCurrency)) return;
		callbackWithCurrency(value);
		callback(valueWithoutCurrency);
	} else {
		if (isNaN(value)) return;
		callbackWithCurrency(currency + value);
		callback(value);
	}
};

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

export const formatBytes = (bytes) => {
	const marker = 1024; // Change to 1000 if required
	const decimal = 3; // Change as required
	const megaBytes = marker * marker; // One MB is 1024 KB

	return `${(bytes / megaBytes).toFixed(decimal)} MB`;
};

export const isEqual = (value, other) => {
	// Get the value type
	const type = Object.prototype.toString.call(value);

	// If the two objects are not the same type, return false
	if (type !== Object.prototype.toString.call(other)) return false;

	// If items are not an object or array, return false
	if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

	// Compare the length of the length of the two items
	const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
	const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
	if (valueLen !== otherLen) return false;

	// Compare two items
	const compare = (item1, item2) => {
		// Get the object type
		const itemType = Object.prototype.toString.call(item1);

		// If an object or array, compare recursively
		if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
			if (!isEqual(item1, item2)) return false;
		} else {
			// If the two items are not the same type, return false
			if (itemType !== Object.prototype.toString.call(item2)) return false;

			// Else if it's a function, convert to a string and compare
			// Otherwise, just compare
			if (itemType === '[object Function]') {
				if (item1.toString() !== item2.toString()) return false;
			} else if (item1 !== item2) return false;
		}
	};

	// Compare properties
	if (type === '[object Array]') {
		for (let i = 0; i < valueLen; i++) {
			if (compare(value[i], other[i]) === false) return false;
		}
	} else {
		// eslint-disable-next-line no-restricted-syntax
		for (const key in value) {
			// eslint-disable-next-line no-prototype-builtins
			if (value.hasOwnProperty(key)) {
				if (compare(value[key], other[key]) === false) return false;
			}
		}
	}

	// If nothing failed, return true
	return true;
};

export const convertNumericToPixel = (object, property) => {
	if (typeof object[property] === 'number') {
		object[property] = `${object[property]}px`;
	}
};

/** assumes array elements are primitive types
* check whether 2 arrays are equal sets.
* @param  {} a1 is an array
* @param  {} a2 is an array
*/
export const areArraysEqual = (a1, a2) => {
	const superSet = {};
	for (const i of a1) {
		const e = i + typeof i;
		superSet[e] = 1;
	}

	for (const i of a2) {
		const e = i + typeof i;
		if (!superSet[e]) {
			return false;
		}
		superSet[e] = 2;
	}

	for (const e in superSet) {
		if (superSet[e] === 1) {
			return false;
		}
	}

	return true;
};

export const getDestructuredTwilioIdentity = (identity, delimiter = '#$$#') => {
	try {
		const array = identity.split(delimiter);
		if (array && array.length > 0) {
			const isOwner = array[0] === 'presenter';
			const isAnonymous = !isOwner && array.length === 1;
			return {
				nickname: array[0],
				userId: array.length > 1 ? array[1] : null,
				isOwner,
				isAnonymous
			};
		}
	} catch (error) {
		return null;
	}
};

export const areAllArrayItemsTheSame = (array) => array.every((v) => v === array[0]);
