import React, { useEffect, useState } from 'react';

const ShipmentSummaryItem = ({ shipment, sellerAddress }) => {
	const [orderIds, setOrderIds] = useState([]);

	useEffect(() => {
		if (!shipment) return;
		setOrderIds([
			...new Set(shipment.orderDetails.map((x) => x.order.orderNumber))
		]);
	}, [shipment]);

	return (
		<div className="border-b border-gray-400">
			<div className="inline-flex justify-between w-full">
				{orderIds && orderIds.length ? (
					<p className="font-bold">
						Order
						{' '}
						{orderIds.map((orderId, index) => (
							<span>
								{`${orderId}${index == orderIds.length - 1 ? '' : ', '}`}
							</span>
						))}
						:
					</p>
				) : null}
				<p>
$
					{Number.isNaN(shipment.rate) ? 0 : shipment.rate}
				</p>
			</div>
			<div className="text-gray-500 text-sm pt-2 pb-4">
				<p>USPS Priority Mail</p>
				<p className="font-bold">
					From Address:
					{' '}
					<span className="font-normal">
						{`${sellerAddress.mailingAddress1}, ${
							sellerAddress.mailingAddress2
							&& `${sellerAddress.mailingAddress2},`
						} ${sellerAddress.mailingCity}, ${sellerAddress.mailingState}, ${
							sellerAddress.mailingZip
						}`}
					</span>
				</p>
				<p className="font-bold">
					To Address:
					{' '}
					<span className="font-normal">
						{`${shipment.buyer.buyerName}, ${
							shipment.buyer.buyerMailingAddress1
						}, ${
							shipment.buyer.buyerMailingAddress2
							&& `${shipment.buyer.buyerMailingAddress2},`
						} ${shipment.buyer.buyerMailingCity}, ${
							shipment.buyer.buyerMailingState
						}, ${shipment.buyer.buyerMailingZip}`}
					</span>
				</p>
			</div>
		</div>
	);
};

export default ShipmentSummaryItem;
