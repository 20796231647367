// import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Beforeunload } from 'react-beforeunload';
import { useQueryParam, StringParam } from 'use-query-params';
import LayoutHome from '../components/layouts/layout-home';
import HeaderHome from '../components/common/header-home';
import SEO from '../components/seo';
import CreateSellerStepper from '../components/seller/create-seller-stepper';
import {
	getMerchantOnBoard,
	elligibleForSellerApplication
} from '../api/seller.request';
import { defaultToastError } from '../utils/toast-error';

const SellerRegisterPage = () => {
	const [eligible, setEligible] = useState(false);
	const [isMerchantOnBoard, setIsMerchantOnBoard] = useState(false);
	const [merchantOnBoardUrl, setMerchantOnBoardUrl] = useState('');

	const [merchantId] = useQueryParam('merchantId', StringParam);
	const [paypalMerchantId] = useQueryParam('merchantIdInPayPal', StringParam);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		elligibleForSellerApplication()
			.then(() => {
				setEligible(true);
			})
			.catch((err) => {
				if (err && err.response) {
					defaultToastError(err.response.data.error);
					setEligible(false);
				}
			});

		if (merchantId && paypalMerchantId) {
			getMerchantOnBoard()
				.then((res) => {
					if (res.data) {
						if (res.data.links && res.data.links.length > 0) {
							setMerchantOnBoardUrl(res.data.links[1].href);
							setIsMerchantOnBoard(true);
						} else {
							setIsMerchantOnBoard(true);
						}
					} else {
						setIsMerchantOnBoard(true);
					}
				})
				.finally(() => {
					setLoaded(true);
				});
		} else {
			getMerchantOnBoard()
				.then((res) => {
					if (res.data) {
						if (res.data.links && res.data.links.length > 0) {
							setMerchantOnBoardUrl(res.data.links[1].href);
						} else {
							setIsMerchantOnBoard(true);
						}
					} else {
						setIsMerchantOnBoard(true);
					}
				})
				.finally(() => {
					setLoaded(true);
				});
		}
	}, []);

	if (!loaded) {
		return null;
	}

	return (
		<Beforeunload
			onBeforeunload={(event) => {
				event.preventDefault();
				event.returnValue = 'we';
			}}
		>
			<LayoutHome>
				<ToastContainer />
				<SEO title="Home" auth />
				<div className="bg-gray-100 mx-auto w-full h-full pt-0">
					<HeaderHome auth leavePage />
					{eligible && (
						<CreateSellerStepper
							merchantOnBoardUrl={merchantOnBoardUrl}
							isMerchantOnBoard={isMerchantOnBoard}
							trackingId={paypalMerchantId}
							paypalMerchantId={merchantId}
						/>
					)}
				</div>
			</LayoutHome>
		</Beforeunload>
	);
};

export default SellerRegisterPage;
