/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
	useState, useEffect, useRef, useCallback
} from 'react';
import 'emoji-mart/css/emoji-mart.css';
import firebase from 'gatsby-plugin-firebase';
import useOutsideClick from '../common/useOutsideClick';
import { ATTACHMENT_OVER_LIMITED_SIZE } from '../../utils/errorCodes';
import {
	MAXIMUM_NUMBER_OF_FILES,
	MAXIMUM_SIZE_OF_FILES,
	MAXIMUM_SIZE_OF_MESSAGE
} from '../../utils/conversation-constants';
import { uploadMessageAttachment } from '../../api/auth.request';
import 'react-quill/dist/quill.snow.css';
import ConversationEditor from './conversation-editor';
import { formatBytes, isTruthy } from '../../utils/commonFunction';

const ConversationTypeMessage = ({
	onConversationCreation = false,
	onEditingMessage = false,
	messageToEdit,
	createConversation,
	onSendMessage,
	showError,
	setSelectedForEdit,
	participants,
	setInputFocused,
	hideSendButton = false,
	hideAttachmentsButton = false,
	hidePlaceholder = false,
	setMessageValue = null,
	removeListsFromToolbar = false,
	authenticated,
	reduceReplyMessageSize,
	useInlineInputEditor,
	toolbarId,
	onRedirectToSignIn,
	enabledChatInput,
	enableMentions
}) => {
	const editorRef = useRef();
	const [showModal, setShowModal] = useState(false);
	const [error, setError] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [currentAttachments, setCurrentAttachments] = useState([]);
	const numberOfSelectedFiles =		currentAttachments.length + selectedFiles.length;
	const [value, setValue] = useState('');
	const [editor, setEditor] = useState();
	const [attachmentsChanged, setAttachmentsChanged] = useState(false);

	const modalButtonRef = useRef();

	useOutsideClick(modalButtonRef, () => {
		if (showModal) setShowModal(false);
	});

	useEffect(() => {
		if (onEditingMessage) {
			if (messageToEdit.attachments) {
				setCurrentAttachments(messageToEdit.attachments);
			}
			setValue(messageToEdit.message);
		}
	}, []);

	const editMessage = useCallback(
		(newMessage, hasSelectedFiles) => {
			let newMessageObject = {};

			if (currentAttachments) {
				newMessageObject = {
					conversation_id: messageToEdit.conversation_id,
					message: newMessage,
					sent_date: messageToEdit.send_date_server,
					user_id: messageToEdit.user_id,
					attachments: currentAttachments,
					edited: true
				};
			} else {
				newMessageObject = {
					conversation_id: messageToEdit.conversation_id,
					message: newMessage,
					sent_date: messageToEdit.send_date_server,
					user_id: messageToEdit.user_id,
					edited: true
				};
			}

			if (hasSelectedFiles) {
				const formData = new FormData();
				selectedFiles.forEach((item) => {
					formData.append('files', item.file);
				});

				const attachments = currentAttachments;

				uploadMessageAttachment(formData)
					.then((attachmentRes) => {
						if (attachmentRes.data) {
							attachmentRes.data.forEach((item) => {
								attachments.push({
									display_file_name: item.fileName,
									generated_file_name: item.generatedFileName
								});
								newMessageObject.attachments = attachments;
							});
						}
					})
					.catch((err) => {
						console.error(err);
					})
					.finally(() => {
						firebase
							.database()
							.ref('/message')
							.child(messageToEdit.id)
							.update(newMessageObject);
						setSelectedForEdit(null);
					});
			} else {
				firebase
					.database()
					.ref('/message')
					.child(messageToEdit.id)
					.update(newMessageObject);
				setSelectedForEdit(null);
			}
		},
		[messageToEdit, currentAttachments, selectedFiles]
	);

	const filesTotalSize =		selectedFiles
		&& selectedFiles.length > 0
		&& formatBytes(selectedFiles.reduce((a, b) => a + b.size, 0));

	function handleFile(e) {
		if (e && e.target && e.target.files && e.target.files[0]) {
			const file = e.target.files[0];
			const currentTargetSize = file.size;
			if (currentTargetSize > MAXIMUM_SIZE_OF_FILES) {
				showError(ATTACHMENT_OVER_LIMITED_SIZE);
				return;
			}
			if (selectedFiles && selectedFiles.length > 0) {
				const sum = selectedFiles.reduce((a, b) => a + b.size, 0);
				if (currentTargetSize + sum > MAXIMUM_SIZE_OF_FILES) {
					showError(ATTACHMENT_OVER_LIMITED_SIZE);
					return;
				}
			}

			if (onEditingMessage) setAttachmentsChanged(true);
			setSelectedFiles((prevFile) => [
				...prevFile,
				{
					fileName: file.name,
					size: file.size,
					file
				}
			]);
		}
	}

	const sendMessage = useCallback(() => {
		if (editorRef.current) editorRef.current.focus();
		const mentions = [];
		if (!editor) return;
		if (editor && editor.getContents()) {
			const { ops } = editor.getContents();
			for (let i = 0; i <= ops.length; i += 1) {
				const currentOp = ops[i];
				if (currentOp && currentOp.insert.mention) {
					const mention = {
						id: currentOp.insert.mention.id,
						name: currentOp.insert.mention.value
					};
					mentions.push(mention);
				}
			}
			const { length } = value.replace(/<(.|\n)*?>/g, '').trim();
			const hasImageNode = value && value.length && value.includes('<img');
			if (!length && length == 0 && !hasImageNode) {
				setError(null);
				return;
			}
			if (length && length >= MAXIMUM_SIZE_OF_MESSAGE && !hasImageNode) {
				setError(
					`Maximum size of message should be ${MAXIMUM_SIZE_OF_MESSAGE}!`
				);
				return;
			}
		}

		setError(null);

		let message = '';

		message = value;
		const hasSelectedFiles = selectedFiles && selectedFiles.length > 0;
		if (onConversationCreation) {
			setValue('');
			createConversation(message);
			return;
		}
		if (onEditingMessage) {
			if (message === messageToEdit.message && !attachmentsChanged) {
				setSelectedForEdit(null);
				return;
			}

			editMessage(message, hasSelectedFiles);
			return;
		}

		if (hasSelectedFiles) {
			onSendMessage(message, 'attachment', selectedFiles, mentions);
		} else {
			onSendMessage(message, 'message', [], mentions);
		}
		setSelectedFiles([]);
		setValue('');
	}, [
		editorRef,
		editor,
		value,
		selectedFiles,
		onConversationCreation,
		onEditingMessage,
		messageToEdit,
		attachmentsChanged
	]);

	useEffect(() => {
		if (setMessageValue) {
			setMessageValue(value);
		}
	}, [value]);

	return (
		<>
			<div className="w-full flex flex-col">
				{((selectedFiles && selectedFiles.length > 0)
					|| currentAttachments) && (
					<div className="flex flex-row justify-between items-center">
						<div className="flex p-1 flex-wrap">
							{selectedFiles
								&& selectedFiles.map((object, i) => (
									<div className="flex pt-2 col-span-0" key={i}>
										<div className="md:p-1 text-left md:text-left space-y-4 break-all overflow-x-hidden">
											<div className="flex flex-none items-center border rounded-2xl">
												<div className="flex items-center justify-between text-center w-full">
													<span className="text-md mr-1 p-1 font-sans w-full pl-5">
														{object.fileName}
													</span>
													<button
														type="button"
														className="focus:outline-none text-black hover:text-black text-lg p-3 rounded-md rounded-tr-none rounded-br-none"
														onClick={() => {
															const filteredSelectedUsers = selectedFiles.filter(
																(item) => item !== object
															);
															setSelectedFiles(filteredSelectedUsers);
														}}
													>
														<svg
															className="w-5 h-4"
															fill="none"
															stroke="currentColor"
															viewBox="0 0 24 24"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth="2"
																d="M6 18L18 6M6 6l12 12"
															/>
														</svg>
													</button>
												</div>
											</div>
										</div>
									</div>
								))}
						</div>
						<div className="flex p-1 flex-wrap">
							{currentAttachments.map((object, i) => (
								<div className="flex pt-2 col-span-0" key={i}>
									<div className="md:p-1 text-left md:text-left space-y-4 break-all overflow-x-hidden">
										<div className="flex flex-none items-center border rounded-2xl">
											<div className="flex items-center justify-between text-center w-full">
												<span className="text-md mr-1 p-1 font-sans w-full pl-5">
													{object.display_file_name}
												</span>
												<button
													type="button"
													className="focus:outline-none text-black hover:text-black text-lg p-3 rounded-md rounded-tr-none rounded-br-none"
													onClick={() => {
														if (onEditingMessage) setAttachmentsChanged(true);
														const filteredSelectedUsers = currentAttachments.filter(
															(item) => item !== object
														);
														setCurrentAttachments(filteredSelectedUsers);
													}}
												>
													<svg
														className="w-5 h-4"
														fill="none"
														stroke="currentColor"
														viewBox="0 0 24 24"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M6 18L18 6M6 6l12 12"
														/>
													</svg>
												</button>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>

						{filesTotalSize && (
							<div
								className="bg-white"
								style={{ backgroundColor: messageToEdit && '#E29890' }}
							>
								<div className="float-right pt-2 pb-2 pr-5">
									{`Total ${filesTotalSize}`}
								</div>
							</div>
						)}
					</div>
				)}
				{error && (
					<div className="flex flex-col">
						<div className="bg-white">
							<div className="float-right pt-2 pb-2 pr-5 text-yellow-400">
								{`${error}`}
							</div>
						</div>
					</div>
				)}
				<div
					className={`flex flex-row bg-white dark:bg-darkGray-50 rounded-md ${
						useInlineInputEditor ? 'py-1 px-2' : 'py-2 px-4'
					} z-10 max-w-full mx-auto dark:text-white w-full h-auto`}
					style={
						useInlineInputEditor
							? {
								borderBottomRightRadius: '25px',
								borderBottomLeftRadius: '25px'
							  }
							: {}
					}
				>
					<div
						className={`w-full ${
							useInlineInputEditor ? 'flex flex-row-reverse' : ''
						}`}
					>
						<ConversationEditor
							value={value}
							setValue={setValue}
							onSendMessage={sendMessage}
							setEditor={setEditor}
							enabledChatInput={enabledChatInput}
							participants={participants}
							editorRef={editorRef}
							onRedirectToSignIn={onRedirectToSignIn}
							sendButtonEnabled={isTruthy(
								value && value.length > 0 && value != '<p><br></p>'
							)}
							hideSendButton={hideSendButton}
							hideAttachmentsButton={hideAttachmentsButton}
							hidePlaceholder={hidePlaceholder}
							onFileAttached={handleFile}
							attachmentDisabled={
								numberOfSelectedFiles == MAXIMUM_NUMBER_OF_FILES
							}
							removeListsFromToolbar={removeListsFromToolbar}
							setInputFocused={setInputFocused}
							authenticated={authenticated}
							enableMentions={enableMentions}
							reduceReplyMessageSize={reduceReplyMessageSize}
							useInlineInputEditor={useInlineInputEditor}
							toolbarId={toolbarId}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default ConversationTypeMessage;
