import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useQueryParam, StringParam } from 'use-query-params';
import Button from '../../../components/button';
import { getReferenceDataById } from '../../../api/referenceData.request';
import { EVENT_EXPIRATION_AFTER_SELLER_IS_DISCONNECTED_IN_SECONDS_REFERENCE_DATA_ID } from '../../../utils/constants/reference-data';

const LiveEventFinishedModal = ({
	showModal,
	eventId,
	isHost,
	hostParticipantRejoining
}) => {
	const [linkedEventId] = useQueryParam('linkedEventId', StringParam);
	const [eventExpiringInMinutes, setEventExpiringInMinutes] = useState(null);

	useEffect(() => {
		getReferenceDataById(
			EVENT_EXPIRATION_AFTER_SELLER_IS_DISCONNECTED_IN_SECONDS_REFERENCE_DATA_ID
		)
			.then((referenceDataRes) => {
				// this value is in seconds so we need to devide it by 60 to get minutes
				setEventExpiringInMinutes(
					referenceDataRes.data.referenceDataValue / 60
				);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	return (
		<>
			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
							<div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
								<svg
									className="cursor-pointer w-6 h-6"
									onClick={() => {
										navigate('/events');
									}}
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</div>
							<div className="flex flex-col px-6 py-5 bg-gray-50 mb-10">
								<p className="flex flex-col font-semibold text-gray-700 pt-10 mb-5 px-10 text-lg">
									<span>
										{isHost
										&& hostParticipantRejoining
										&& eventExpiringInMinutes
											? `The event is ended as you didn't rejoin within ${eventExpiringInMinutes} minutes, you cannot join the event.`
											: 'Live event has finished, please click the button below to go to the event page in the past events.'}
									</span>
								</p>
								<div className="flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5">
									<div className="mr-3">
										<Button
											width="auto"
											mobileWidth="auto"
											height="12"
											roundedSize="sm"
											mobileHeight="12"
											onClick={() => navigate(`/event/${linkedEventId || eventId}`)}
											additionalClassName="flex items-center justify-center px-6 py-2 mx-auto md:py-4 md:text-lg md:px-10 font-medium"
										>
											{isHost
											&& hostParticipantRejoining
											&& eventExpiringInMinutes
												? ' Please click here to open it in the past events.'
												: 'Go to the event page'}
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black" />
				</>
			) : null}
		</>
	);
};

export default LiveEventFinishedModal;
