/* eslint-disable quote-props */

const lineClamp = require('@tailwindcss/line-clamp');

module.exports = {
	purge: ['./public/**/*.html', './src/**/*.{js,jsx,ts,tsx,css}'],
	darkMode: 'class', // or 'media' or 'class'
	theme: {
		fontFamily: {
			sans: ['Inter', 'sans-serif'],
			serif: ['Inter', 'serif'],
		},
		fontSize: {
			'xs': '.75rem',
			'xsm': '.82rem',
			'sm': '.875rem',
			'tiny': '.875rem',
			'base': '1rem',
			'lg': '1.125rem',
			'xl': '1.25rem',
			'2xl': '1.5rem',
			'3xl': '1.875rem',
			'4xl': '2.25rem',
			'5xl': '3rem',
			'6xl': '4rem',
			'7xl': '5rem',
		},
		screens: {
			xxxs: '300px',
			xxs: '375px',
			xsm: '390px',
			xs: '480px',
			sm: '640px',
			md: '768px',
			lg: '1024px',
			xl: '1280px',
			'2xl': '1600px',
			'3xl': '2300px',
			'h-md': { 'min-height': '768px' },
			'h-lg': { 'min-height': '1024px' },
			'h-xl': { 'min-height': '1280px' }
		},
		extend: {
			height: {
				'3.5': '0.83rem',
				'screen/2': '50vh',
				'screen/3': 'calc(100vh / 3)',
				'screen/4': 'calc(100vh / 4)',
				'screen/5': 'calc(100vh / 5)',
				'10rem': '10rem',
				'room-grid-item-height': '60vh',
				85: '85vh',
				90: '90vh',
				82: '24rem',
				86: '22rem',
				102: '27rem',
				103: '30rem',
				105: '33rem',
				112: '39rem',
				'per-63.8': '63.8%',
				'per-64.4': '64.4%',
				'per-65': '65%',
				'per-65.5': '65.5%',
				'per-66.8': '66.8%',
				'per-67.4': '67.4%',
				'per-68': '68%',
				'per-68.5': '68.5%',
				'per-73': '73%',
				'per-73.5': '73.5%',
				'per-75': '75%',
				'per-78': '78%',
				'per-82': '82%',
				'per-84': '84%',
				'per-94': '94%',
				'per-97': '97%',
			},
			animation: {
				'modal-enter': 'modal-enter',
				'modal-exit': 'modal-exit',
			},
			keyframes: {
				'modal-enter': {
					'0%': { opacity: 0, transform: 'translate-y-full' },
					'100%': { opacity: 1, transform: 'translate-y-0' },
				},
				'modal-exit': {
					'0%': { opacity: 1, transform: 'translate-y-0' },
					'100%': { opacity: 0, transform: 'translate-y-full' },
				},
			},
			maxHeight: {
				102: '27rem',
				103: '30rem',
				112: '39rem',
				120: '42rem',
				122: '46rem',
			},
			padding: {
				'per-16': '16%',
				'per-8': '8%',
				'per-10': '10%',
				13: '3.25rem',
				68: '17rem'
			},
			margin: {
				15: '3.5rem'
			},
			width: {
				'3.5': '0.83rem',
				18: '4.5rem',
				29: '7.5rem',
				40: '10rem',
				75: '18.5rem',
				112: '39rem',
				'10rem': '10rem',
				'96%': '96%',
				'6xl': '72rem',
			},
			maxWidth: {
				'72': '18rem',
			},
			spacing: { '2/3': '66.666667%', 15: '4.05rem' },
			colors: {
				coolDark: {
					400: '#40444B',
					500: '#36393f',
					600: '#32353b',
				},
				primary: {
					50: '#fbeff3',
					100: '#d96d91',
					200: '#d55d85',
					300: '#d14d79',
					400: '#cd3c6d',
					500: '#c33364',
					600: '#b22e5a',
					700: '#a22a52',
					800: '#92264a',
					900: '#822142',
				},
				amber: {
					50: '#fffbeb',
					100: '#fef3c7',
					200: '#fde68a',
					300: '#fcd34d',
					400: '#fbbf24',
					500: '#f59e0b',
					600: '#d97706',
					700: '#b45309',
					800: '#92400e',
					900: '#78350f',
				},
				pressedIconsRedColor: {
					DEFAULT: '#EA2B21'
				},
				facebook: {
					DEFAULT: '#1877F2'
				},
				greenModified: {
					500: '#11A632',
					700: '#199F36',
				},
				darkGray: {
					50: '#201F1F',
					100: '#323131',
					200: '#747272'
				},
				djawn: {
					DEFAULT: '#e82e2c',
					light: '#e82e2c',
					dark: '#e82e2c',
					300: '#f04a48',
					700: '#d91d1a',
					800: '#C61C19',
					900: '#a10f0d'
				}
			},
			boxShadow: {
				button: 'var(--shadow-button)',
				card: 'var(--shadow-card)',
			},
		},
		tableLayout: ['hover', 'focus'],
	},
	variants: {
		extend: {
			opacity: ['disabled'],
			cursor: ['disabled'],
			padding: ['hover'],
			borderWidth: ['hover', 'disabled', 'focus', 'focus-within', 'dark'],
		},
	},
	plugins: [
		lineClamp,
		({ addUtilities }) => {
			const newUtilities = {
				'.product-input-width': {
					width: '40.3125vw',
				},
				'.w-81': {
					width: '22rem'
				},
				'.h-80vh': {
					height: '80vh'
				},
				'.border-radius-25px': {
					'border-radius': '25px'
				}
			};
			addUtilities(newUtilities, ['responsive']);
		},
	]
};
