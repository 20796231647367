export const convertPoundsToPoundsAndOunces = (pounds) => {
	let updatedPounds = pounds;
	let updatedOunces = 0;
	const decimalPoints = Math.round(((pounds % 1) + Number.EPSILON) * 100) / 100;
	if (decimalPoints > 0) {
		updatedPounds = Math.round(((pounds - decimalPoints) + Number.EPSILON) * 100) / 100;
		updatedOunces = Math.round(((decimalPoints * 16) + Number.EPSILON) * 100) / 100;
	}

	return { pounds: updatedPounds, ounces: updatedOunces };
};

export const convertPoundsAndOuncesToPounds = (pounds, ounces) => {
	pounds = Number(pounds);
	ounces = Number(ounces);
	if (ounces <= 0) return Math.round(pounds);
	let totalPounds = Math.round((pounds + Number.EPSILON) * 100) / 100;
	totalPounds += Math.round(((ounces / 16) + Number.EPSILON) * 100) / 100;
	return Math.round((totalPounds + Number.EPSILON) * 100) / 100;
};
