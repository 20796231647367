import clsx from 'clsx';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { ResizableBox } from 'react-resizable';
import { LiveEventViewsContainerContext } from '../../../contexts/live-event-views-container';
import {
	VIEWS_DESKTOP_MAX_WIDTH,
	VIEWS_DESKTOP_MIN_WIDTH
} from '../../../utils/constants';

const DesktopResizableViewsContainer = ({
	children,
	canResize,
	isDarkMode
}) => {
	const { width, setWidth } = useContext(LiveEventViewsContainerContext);
	if (!canResize) return children;

	return (
		<ResizableBox
			className="views-resizable-box md:h-room-grid-item-height md:mt-2 mt-0"
			width={width}
			axis="x"
			onResize={(e, { size }) => {
				if (size && size.width) {
					setWidth(size.width);
				}
			}}
			minConstraints={[VIEWS_DESKTOP_MIN_WIDTH, 0]}
			maxConstraints={[VIEWS_DESKTOP_MAX_WIDTH, 0]}
			handle={(
				<div
					className={clsx(
						'react-resizable-handle',
						isDarkMode
							? 'react-resizable-handle-icon-light'
							: 'react-resizable-handle-icon-dark'
					)}
				/>
			)}
		>
			{children}
		</ResizableBox>
	);
};
export default connect(
	(state) => ({
		isDarkMode: state.theme.isDarkMode
	}),
	null
)(DesktopResizableViewsContainer);
