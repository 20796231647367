import React from 'react';
import { Link } from 'gatsby';
import useScript from '../../hooks/useScript';
import logo from '../../images/djawn_logo.png';
import env from '../../../environment.config';

const KlaviyoSmsRegisterPage = () => {
	useScript(
		`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${env.klaviyo_company_id}`,
		'text/javascript'
	);

	return (
		<div className="flex items-center justify-center h-screen px-16">
			<Link to="/events">
				<img src={logo} alt="djawn-logo" />
			</Link>
		</div>
	);
};

export default KlaviyoSmsRegisterPage;
