import clsx from 'clsx';
import React from 'react';

const SelectInput = ({
	width = 'full',
	variant = 'primary',
	mobileWidth = 'full',
	height = '12',
	mobileHeight = 'auto',
	textSize = 'sm',
	additionalClassName = '',
	children,
	...restProps
}) => (
	<select
		type="text"
		className={clsx(
			`input input--${variant}`,
			`w-${mobileWidth} md:w-${width} h-${mobileHeight} md:h-${height} text-${textSize}`,
			additionalClassName
		)}
		{...restProps}
	>
		{children}
	</select>
);

export default SelectInput;
