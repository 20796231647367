import React, {
	useState, useEffect, useCallback, useRef
} from 'react';
import SelectInput from '../../../components/input/select-input';

const StreamCameraSettings = ({ settings, setSettings, showPreview }) => {
	const [cameras, setCameras] = useState([]);
	const [selectedCameraId, setSelectedCameraId] = useState(null);

	const videoRef = useRef(null);

	const onCameraChange = useCallback(
		(deviceId, updateSettings = false, setState = true) => {
			if (showPreview) {
				navigator.mediaDevices
					.getUserMedia({
						video: {
							deviceId
						}
					})
					.then((stream) => {
						videoRef.current.srcObject = stream;
					})
					.catch((err) => {
						console.error('error getting stream', err);
					});
			}

			if (setState) setSelectedCameraId(deviceId);
			if (updateSettings) {
				setSettings((prevSettings) => {
					let foundSetting = false;
					let newSettings = [...prevSettings]
						.map((setting) => {
							if (settings.type == 'camera') {
								setting.deviceId = deviceId;
								foundSetting = true;
							}
							return settings;
						})
						.filter((setting) => !!setting.type);
					if (!foundSetting) {
						if (newSettings.length > 0) {
							newSettings.push({
								type: 'camera',
								deviceId
							});
						} else {
							newSettings = [
								{
									type: 'camera',
									deviceId
								}
							];
						}
					}
					return newSettings;
				});
			}
		},
		[settings, showPreview]
	);

	useEffect(() => {
		let cameraSetting = null;
		if (settings && settings.length) {
			cameraSetting = settings.find((setting) => setting.type === 'camera');
		}
		navigator.mediaDevices
			.enumerateDevices()
			.then((mediaDevices) => {
				const cameraDevices = mediaDevices.filter(
					(device) => device.kind == 'videoinput'
				);
				setCameras(cameraDevices);
				if (cameraDevices.length > 0 && !cameraSetting) {
					onCameraChange(cameraDevices[0].deviceId, false, !cameraSetting);
				}
			})
			.catch((err) => {
				console.error('error fetching media devices', err);
			});

		if (cameraSetting && cameraSetting.deviceId) {
			onCameraChange(cameraSetting.deviceId, false, true);
		}
	}, [settings]);

	return (
		<div className="relative px-3 flex-auto flex items-start space-x-2">
			<div>
				<label
					htmlFor="camera"
					className="block text-sm font-medium text-gray-700 dark:text-white pl-2"
				>
					<div className="flex justify-start space-x-2">
						<div>Camera</div>
					</div>
				</label>
				<div>
					<SelectInput
						id="camera"
						width="full"
						mobileWidth="full"
						height="16"
						mobileHeight="16"
						name="camera"
						onBlur={(e) => onCameraChange(e.target.value, true)}
						onChange={(e) => onCameraChange(e.target.value, true)}
						value={selectedCameraId}
					>
						{cameras.map((camera) => (
							<option key={camera.deviceId} value={camera.deviceId}>
								{camera.label}
							</option>
						))}
					</SelectInput>
				</div>
			</div>
			{showPreview && (
				<div>
					<video className="camera-preview shadow-lg" ref={videoRef} autoPlay />
				</div>
			)}
		</div>
	);
};

export default StreamCameraSettings;
