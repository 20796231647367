import React, { createContext } from 'react';
import useLiveEventViewsContainer from '../hooks/useLiveEventViewsContainer';

const LiveEventViewsContainerContext = createContext();

const LiveEventViewsContainerProvider = ({ children }) => {
	const {
		popups,
		dismissedPopups,
		updatePopupsStatus,
		arrowButtonContainerRef,
		eventNamePopupRef,
		onQueueContainerOpen,
		viewPopupFadingOut,
		addToCartPopupFadingOut,
		arrowButtonRef,
		openQueueContainer,
		setOpenQueueContainer,
		width,
		setWidth
	} = useLiveEventViewsContainer();

	return (
		<LiveEventViewsContainerContext.Provider
			value={{
				popups,
				dismissedPopups,
				updatePopupsStatus,
				arrowButtonContainerRef,
				eventNamePopupRef,
				onQueueContainerOpen,
				viewPopupFadingOut,
				addToCartPopupFadingOut,
				arrowButtonRef,
				openQueueContainer,
				setOpenQueueContainer,
				width,
				setWidth
			}}
		>
			{children}
		</LiveEventViewsContainerContext.Provider>
	);
};

export { LiveEventViewsContainerContext, LiveEventViewsContainerProvider };
