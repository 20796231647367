/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

const Select = ({
	onChange,
	emptyDefaultValue = false,
	options = [],
	children,
	...restProps
}) => (
	<select
		className="pl-2 pr-2 block w-1/2 h-10 sm:text-sm border bg-white border-gray-200 dark:border-darkGray-100 dark:bg-darkGray-200 dark:text-white rounded-md shadow-md mr-2 outline-none"
		onBlur={(e) => onChange(e.target.value)}
		onChange={(e) => onChange(e.target.value)}
		{...restProps}
	>
		{children || (
			<>
				{emptyDefaultValue && <option key={0} value={0} />}
				{options.map((option) => (
					<option key={option.key} value={option.value}>
						{option.displayValue}
					</option>
				))}
			</>
		)}
	</select>
);

export default Select;
