/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { ProfileContext } from '../../../contexts/profile';
import shippoLogo from '../../../images/shippo.png';
import { getShipmentOAuthUrl } from '../../../api/seller.request';
import { SHIPPO_REDIRECT_TO_PROFILE_PAGE_STATE } from '../../../utils/constants/cache-keys';

const SellerShippoInformation = () => {
	const { formik, showShippoTab } = useContext(ProfileContext);
	const [shipmentOAuthUrl, setShipmentOAuthUrl] = useState('');
	const [shipmentCode, setShipmentCode] = useState('');

	useEffect(() => {
		getShipmentOAuthUrl()
			.then((res) => {
				setShipmentOAuthUrl(res.data.url);
				localStorage.setItem(
					SHIPPO_REDIRECT_TO_PROFILE_PAGE_STATE,
					res.data.state
				);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [showShippoTab]);

	return (
		<>
			<div className="flex w-full">
				<p className="md:text-2xl text-xl text-gray-500 text-center mx-auto">
					Shippo Information
				</p>
			</div>
			<div className="flex flex-col w-full px-8 md:px-32 mx-auto">
				<div className="flex flex-col items-center mt-1 relative sm:w-full md:w-full lg:w-full xl:w-full space-y-2">
					<img alt="stripe" src={shippoLogo} width={250} height={100} />
					<a
						href={shipmentOAuthUrl}
						className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
					>
						You are linked to Shippo, please click here to link to another
						account
					</a>
				</div>
				{shipmentCode && (
					<p className="flex justify-center my-1 text-gray-600">
						Please click save button to connect to new Shippo account
					</p>
				)}
			</div>
		</>
	);
};

export default SellerShippoInformation;
