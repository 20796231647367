/* eslint-disable no-restricted-globals */
export const openPopupWindow = (url, title, width, height) => {
	const left = screen.width / 2 - width / 2;
	const top = screen.height / 2 - height / 2;
	window.open(
		url,
		title,
		`toolbar=no, titlebar=no, titlebars=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
	);
};
