import React, {
	useEffect, useMemo, useRef, useState
} from 'react';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import VideoCameraIcon from '@heroicons/react/outline/VideoCameraIcon';
import MicrophoneIcon from '@heroicons/react/outline/MicrophoneIcon';
import StreamCameraSettings from './stream-camera-settings';
import Modal from '../../../components/modals/v2';
import Button from '../../../components/button';
import StreamAudioSettings from './stream-audio-settings';

const StreamSettingsModal = ({
	showModal,
	setShowModal,
	settings,
	setSettings,
	onSave
}) => {
	const [currentSettings, setCurrentSettings] = useState(settings);
	const [hideTabContent, setHideTabContent] = useState(false);
	const hideTabContentTimeoutRef = useRef(null);

	const tabs = useMemo(
		() => [
			{
				icon: VideoCameraIcon,
				title: 'Camera',
				component: StreamCameraSettings
			},
			{
				icon: MicrophoneIcon,
				title: 'Audio',
				component: StreamAudioSettings
			}
		],
		[]
	);

	useEffect(() => {
		setCurrentSettings(settings);
	}, [settings]);

	useEffect(() => {
		if (showModal) {
			setHideTabContent(false);
		} else {
			hideTabContentTimeoutRef.current = setTimeout(() => {
				setHideTabContent(true);
			}, 2000);
		}

		return () => {
			clearTimeout(hideTabContentTimeoutRef.current);
		};
	}, [showModal]);

	return (
		<Modal showModal={showModal} setShowModal={setShowModal} title="Settings">
			{!hideTabContent && (
				<div className="w-full px-2 sm:px-0 flex">
					<Tab.Group vertical>
						<Tab.List
							className="flex flex-col space-y-1 items-center"
							style={{ width: '13rem' }}
						>
							{tabs.map((tab) => (
								<Tab
									key={tab.title}
									className={({ selected }) => clsx(
										'stream-settings-tab-btn',
										selected ? 'stream-settings-tab-btn-selected' : ''
									)}
								>
									<div className="inline-flex space-x-2">
										<tab.icon className="w-6 h-6" />
										<span>{tab.title}</span>
									</div>
								</Tab>
							))}
						</Tab.List>
						<Tab.Panels>
							{tabs.map((tab) => (
								<Tab.Panel
									key={tab.title}
									className="h-64 border-l flex flex-col items-between dark:border-gray-600"
								>
									<tab.component
										settings={settings}
										setSettings={setCurrentSettings}
										showPreview
									/>
									<div>
										<div className="flex justify-center space-x-2 m-4">
											<Button
												height="12"
												mobileHeight="12"
												mobileWidth="24"
												onClick={() => setShowModal(false)}
											>
												Cancel
											</Button>
											<Button
												height="12"
												variant="secondary"
												mobileHeight="12"
												mobileWidth="24"
												onClick={() => {
													setSettings(currentSettings);
													onSave(currentSettings);
													setShowModal(false);
												}}
											>
												Save
											</Button>
										</div>
									</div>
								</Tab.Panel>
							))}
						</Tab.Panels>
					</Tab.Group>
				</div>
			)}
		</Modal>
	);
};

export default StreamSettingsModal;
