import React, { useState, useEffect } from 'react';
import EllipisWithTooltip from '../ellipsis-tooltip';
import AvailableSlots from '../common/available-slots';
import { PICK_YOUR_SLOTS, RANDOMIZED, RIP_N_SHIP } from '../../utils/constants';
import { formatDate, getTimezoneName } from '../../utils/formatter';

const LinkedEventSliderMobileItem = ({ linkedEvent, onEventLinkClick }) => {
	const [availableSlots, setAvailableSlots] = useState(0);
	const [totalSlots, setTotalSlots] = useState(0);
	const [breakPrice, setBreakPrice] = useState(0);
	const [localDate, setLocalDate] = useState(null);
	const [localTime, setLocalTime] = useState(null);
	const [timezone, setTimezone] = useState(null);

	useEffect(() => {
		if (!linkedEvent) return;

		if (linkedEvent.event.eventTypeId == RIP_N_SHIP) {
			let { eventTime } = linkedEvent.event;

			eventTime = `${eventTime}.000Z`;
			eventTime = new Date(eventTime);

			const formated_date = eventTime;
			const local_date = formatDate(formated_date, 'en');

			const time_format_options = {
				hour: 'numeric',
				minute: '2-digit'
			};
			const local_time = formated_date.toLocaleTimeString(
				'en-US',
				time_format_options
			);

			setTimezone(getTimezoneName(eventTime));

			setLocalDate(local_date);
			setLocalTime(local_time);
		}

		if (linkedEvent.event.eventTypeId != RIP_N_SHIP) {
			let soldSlots = 0;
			let smallestSlotPrice = null;
			linkedEvent.breaks.forEach((b, i) => {
				if (i == 0 && linkedEvent.event.eventTypeId == RANDOMIZED) {
					setBreakPrice(b.slotPrice);
				} else if (linkedEvent.event.eventTypeId == PICK_YOUR_SLOTS) {
					if (smallestSlotPrice == null) {
						smallestSlotPrice = b.slotPrice;
					} else if (b.slotPrice < smallestSlotPrice) {
						smallestSlotPrice = b.slotPrice;
					}
				}
				if (b.slotStatusId === 49) soldSlots++;
			});

			if (linkedEvent.event.eventTypeId == PICK_YOUR_SLOTS) {
				setBreakPrice(smallestSlotPrice);
			}

			setAvailableSlots(linkedEvent.breaks.length - soldSlots);
			setTotalSlots(linkedEvent.breaks.length);
		}
	}, [linkedEvent]);

	return (
		<div
			className="flex flex-col justify-center items-center hover:bg-gray-100 p-2 cursor-pointer"
			key={linkedEvent.event.eventId}
			onClick={() => onEventLinkClick(linkedEvent.event.eventId)}
			onKeyDown={() => onEventLinkClick(linkedEvent.event.eventId)}
		>
			<h4 className="flex justify-center w-full">
				<EllipisWithTooltip
					isParagraph
					placement="bottom"
					className="text-xs"
					overridenStyle={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}
				>
					{availableSlots == 0 && linkedEvent.event.eventTypeId != RIP_N_SHIP
						? `(FULL) - ${linkedEvent.event.eventName}`
						: linkedEvent.event.eventName}
				</EllipisWithTooltip>
			</h4>
			<p className="text-gray-700 dark:text-white flex justify-center text-xs tracking-widest">
				{`Event Number #${linkedEvent.event.eventNumber}`}
			</p>
			{linkedEvent.event.eventTypeId == RANDOMIZED ? (
				<p className="flex justify-center text-xs tracking-widest">{`$${breakPrice}`}</p>
			) : null}
			{linkedEvent.event.eventTypeId == PICK_YOUR_SLOTS ? (
				<p className="flex justify-center text-xs tracking-widest">{`$${breakPrice}+`}</p>
			) : null}
			{linkedEvent.event.eventTypeId == RIP_N_SHIP ? (
				<>
					<p className="flex justify-center text-xs whitespace-nowrap">
						{localDate}
					</p>
					<p className="flex justify-center text-xs whitespace-nowrap">
						{localTime}
						{' '}
						{timezone}
					</p>
				</>
			) : null}
			{linkedEvent.event.eventTypeId != RIP_N_SHIP && (
				<div className="flex justify-center">
					<AvailableSlots
						availableSlotNumber={availableSlots}
						totalNumberOfSlots={totalSlots}
					/>
				</div>
			)}
		</div>
	);
};

export default LinkedEventSliderMobileItem;
