/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
	useState,
	Fragment,
	useEffect,
	useRef,
	useCallback
} from 'react';
import { Link, navigate } from 'gatsby';
import { Dialog, Transition } from '@headlessui/react';
import Moment from 'moment';
import { isMobile, isTablet } from 'react-device-detect';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import { informationToast } from '../../utils/information-toast';
import MiniEventTimer from './mini-event-timer';
import {
	createOrder,
	removeOrder,
	getPaymentOrder,
	applyPromoCode,
	hasPaymentIntentSucceeded
} from '../../api/payment.request';
import { releaseBreak } from '../../api/break.request';
import { releaseProduct } from '../../api/product.request';
import Checkout from '../checkout';
import useBooleanQueryParam from '../../hooks/useBooleanQueryParam';
import { setBuyNowData, setCheckoutSessionId } from '../../state/action';
import { SHOPPING_CART_CART_MODIFIED_TOAST_MESSAGE } from '../../utils/constants';
import useErrorNotifier from '../../hooks/use-error-notifier';
import PromoCode from '../checkout/promo-code';

const clipLoaderCss = css`
	display: block;
	border-color: white;
	border-bottom-color: transparent;
`;

const MiniCart = ({
	productName,
	items,
	isOpen,
	closeCart,
	currentCheckoutSessionId,
	cartItemLength,
	removeItemDispatch,
	updateTimerDispatch,
	addProductQuantityToCart,
	decreaseProductQuantityFromCart,
	onPurchased,
	showThankYou,
	onLoadedItems,
	cartOpenedFromLiveEvent,
	liveEvent = false,
	dispatch,
	buyNowData,
	redirectEventId
}) => {
	const [checkoutDisabled, setCheckoutDisabled] = useState(true);
	const [loadedItems] = useState(false);
	const [, setRemoveItemDisabled] = useState(false);
	const [showCheckout, setShowCheckout] = useState(false);
	const [paymentOrder, setPaymentOrder] = useState(null);
	const [timerObject, setTimerObject] = useState(null);
	const [stripeClientSecret, setStripeClientSecret] = useState(null);
	const [transferGroup, setTransferGroup] = useState(null);
	const [chargeId, setChargeId] = useState(null);
	const [checkoutUrl, setCheckoutUrl] = useState(null);
	const [showStep3] = useState(false);
	const dialogDivRef = useRef(null);
	const [closeOnPurchase] = useBooleanQueryParam('closeOnPurchase');
	const [discountFromPromoCode, setDiscountFromPromoCode] = useState(null);
	const [payNowLoading, setPayNowLoading] = useState(false);
	const { showToastError } = useErrorNotifier();

	const getBreaks = () => {
		const breaks = [];
		items.forEach((item) => {
			if (!item.isProduct) breaks.push(item.id);
		});

		return breaks;
	};

	const getProducts = (onlyBuyNowItems = false) => {
		const products = [];
		let itemsToCalculate = [...items];
		if (onlyBuyNowItems) {
			itemsToCalculate = itemsToCalculate.filter((item) => item.buyNow);
		}
		itemsToCalculate.forEach((item) => {
			if (item.isProduct) products.push(item.id);
		});

		return products;
	};

	const getProductsForOrder = () => {
		const products = [];
		items.forEach((item) => {
			if (item.isProduct) {
				products.push({
					eventProductId: item.id,
					quantity: item.quantity
				});
			}
		});

		return products;
	};

	const getTotalPrice = (onlyBuyNowItems = false) => {
		let price = 0;

		let itemsToCalculate = [...items];
		if (onlyBuyNowItems) {
			itemsToCalculate = itemsToCalculate.filter((item) => item.buyNow);
		}
		itemsToCalculate.forEach((item) => {
			let currentQuantity = 1;
			if (item.isProduct && item.quantity) {
				currentQuantity = item.quantity;
			}
			price += item.price * currentQuantity;
		});

		return price.toFixed(2);
	};

	useEffect(() => {
		if (!items || items.length == 0) {
			setCheckoutDisabled(true);
			setDiscountFromPromoCode(null);
		} else {
			setCheckoutDisabled(false);
		}
	}, [items]);

	const getEventBreaks = (onlyBuyNowItems = false) => {
		const eventBreaks = [];

		const eventIds = [...new Set(items.map((x) => x.eventId))];
		eventIds.forEach((eventId) => {
			const isProduct = items
				.filter((f) => f.eventId == eventId && f.isProduct)
				.map((s) => s.id);
			if (!isProduct || isProduct.length == 0) {
				if (onlyBuyNowItems) {
					const buyNowItem = items.find((f) => f.buyNow == true);
					if (buyNowItem) {
						eventBreaks.push({
							eventId,
							breakIds: [buyNowItem.id]
						});
					}
				} else {
					eventBreaks.push({
						eventId,
						breakIds: items
							.filter((f) => f.eventId == eventId && !f.isProduct)
							.map((s) => s.id)
					});
				}
			}
		});
		return eventBreaks;
	};

	useEffect(() => {
		if (!loadedItems) return;
		if (onLoadedItems) {
			onLoadedItems();
		}
	}, [loadedItems]);

	useEffect(() => {
		if (!currentCheckoutSessionId || !items || !items.length) return;

		const clearItemsFromCartIfPaymentHasSucceeded = async () => {
			const paymentSucceededRes = await hasPaymentIntentSucceeded(
				currentCheckoutSessionId
			);

			if (
				paymentSucceededRes
				&& paymentSucceededRes.data
				&& paymentSucceededRes.data.succeeded == true
			) {
				items.forEach((item) => {
					removeItemDispatch(item);
				});

				dispatch(setCheckoutSessionId(null));
			}
		};
		clearItemsFromCartIfPaymentHasSucceeded();
	}, [currentCheckoutSessionId, items]);

	const removeItemsFromOrder = async () => {
		setRemoveItemDisabled(false);
		if (window.myButton) {
			window.myButton.close();
		}

		const res = await removeOrder({
			eventProductIds: getProducts(),
			breakIds: getBreaks()
		});

		if (res.status && res.status === 200) {
			let timer = 5;
			if (
				res.data.breaksSlotReservation
				&& res.data.breaksSlotReservation.length > 0
			) {
				timer = res.data.breaksSlotReservation[0].timerInSeconds;

				if (timer == null) timer = 5;
				items.map((item) => {
					const foundBreak = res.data.breaksSlotReservation.filter(
						(s) => s.breakId == item.id
					);
					if (foundBreak) {
						item.time = Moment.utc(foundBreak[0].reservationDate)
							.add(timer, 'seconds')
							.toDate();
						updateTimerDispatch(item);
					}
					return item;
				});
			} else {
				timer = res.data.validProductReservations[0].timerInSeconds;

				if (timer == null) timer = 5;
				items.map((item) => {
					const foundProduct = res.data.validProductReservations.filter(
						(p) => p.eventProductId == item.id
					);

					if (foundProduct) {
						item.time = Moment.utc(foundProduct[0].reservationDate)
							.add(timer, 'seconds')
							.toDate();
						updateTimerDispatch(item);
					}

					return item;
				});
			}

			setShowCheckout(false);
		} else {
			alert('Couldnt Lock the breaks');
		}
	};

	const removeCartItem = (cartItem, expired) => {
		if (window.myButton) {
			window.myButton.close();
		}
		if (cartItem.isProduct) {
			releaseProduct(cartItem.eventId, cartItem.id, currentCheckoutSessionId)
				.then((res) => {
					if (
						(cartItem.paymentProcess == true
							&& items
							&& items.length
							&& items.length > 1)
						|| currentCheckoutSessionId != null
					) {
						informationToast(SHOPPING_CART_CART_MODIFIED_TOAST_MESSAGE);
					}
					dispatch(setCheckoutSessionId(null));
					if (expired) {
						showToastError({
							shortMessage: `${cartItem.title} has been removed`,
							longMessage: `${
								expired == true
									? 'This item has been removed from your cart because the timer has expired'
									: ''
							}`
						});
					}
					removeItemDispatch(cartItem);
				})
				.catch((error) => {
					removeItemDispatch(cartItem);
				});
		} else {
			releaseBreak(cartItem.id, currentCheckoutSessionId)
				.then((res) => {
					if (
						cartItem.paymentProcess == true
						|| currentCheckoutSessionId != null
					) {
						informationToast(SHOPPING_CART_CART_MODIFIED_TOAST_MESSAGE);
					}
					dispatch(setCheckoutSessionId(null));
					if (expired) {
						showToastError({
							shortMessage: `${cartItem.title} has been removed`,
							longMessage: `${
								expired == true
									? 'This item has been removed from your cart because the timer has expired'
									: ''
							}`
						});
					}

					removeItemDispatch(cartItem);
				})
				.catch((error) => {
					removeItemDispatch(cartItem);
				});
		}
	};

	const toggleMiniCart = () => {
		closeCart();
		setCheckoutDisabled(false);
		setRemoveItemDisabled(false);

		if (window.myButton) {
			window.myButton.close();
		}
	};

	const onCheckoutButtonClicked = async () => {
		setRemoveItemDisabled(true);
		if (window.myButton) {
			window.myButton.close();
		}

		const res = await getPaymentOrder({
			eventProductIds: getProductsForOrder(),
			breakIds: getBreaks(),
			discount: discountFromPromoCode
		});

		if (res.status && res.status === 200) {
			setPaymentOrder(res.data);

			setTimerObject(items[0]);
			setShowCheckout(true);
		} else {
			alert('Couldnt Lock the breaks');
		}
	};

	// TODO: createOrder request should be done through .then()
	const onCheckout = async (
		buyerName = null,
		buyerAddress = null,
		buyerAddress2 = null,
		buyerState = null,
		buyerZip = null,
		buyerCity = null
	) => {
		setTimerObject(null);
		setRemoveItemDisabled(true);

		const totalPrice = parseFloat(getTotalPrice());
		const eventProductIds = getProducts();
		const eventBreaks = getEventBreaks();

		try {
			const res = await createOrder({
				eventProductIds,
				breakIds: getBreaks(),
				discount: discountFromPromoCode,
				paymentMetadata: {
					buyerName,
					buyerAddress,
					buyerAddress2,
					buyerState,
					buyerZip,
					buyerCity,
					total: totalPrice,
					eventBreaks
				}
			});

			if (res.status && res.status === 200) {
				// setStripeClientSecret(res.data.intentSecret);
				setTransferGroup(res.data.transferGroup);
				setChargeId(res.data.chargeId);

				let timer = 5;
				if (
					res.data.breaksSlotReservation
					&& res.data.breaksSlotReservation.length > 0
				) {
					timer = res.data.breaksSlotReservation[0].timerInSeconds;
				} else {
					timer = res.data.validProductReservations[0].timerInSeconds;
				}

				const location = window.location.href;

				const returnUrl =					closeOnPurchase
					|| (cartOpenedFromLiveEvent && cartOpenedFromLiveEvent())
					? ''
					: location;

				const reqBody = {
					total: totalPrice,
					eventBreaks,
					buyerName,
					buyerAddress,
					buyerAddress2,
					buyerState,
					buyerZip,
					buyerCity,
					eventProductIds,
					buyerFee: parseFloat(res.data.paymentOrder.buyerFeePrice),
					sellerFees: res.data.paymentOrder.sellerFees,
					paypalOrderId: 'N/A',
					transferGroup: res.data.transferGroup,
					paymentIntentId: res.data.paymentIntentId,
					returnUrl,
					redirectEventId,
					processPayment: totalPrice <= 0
				};

				// TODO: Test one case here to see if commenting this breaks anything
				if (
					discountFromPromoCode
					&& res.data.paymentOrder
					&& res.data.paymentOrder.promoCodeDiscountTotal > 0
				) {
					reqBody.discountDetail = {
						discountId: discountFromPromoCode.discountId,
						discountAmount: res.data.paymentOrder.promoCodeDiscountTotal
					};

					reqBody.processPayment =						totalPrice - res.data.paymentOrder.promoCodeDiscountTotal <= 0;
				}

				if (timer == null) timer = 5;
				timer /= 60;

				items.map((item) => {
					item.time = new Date(Date.parse(new Date()) + timer * 60 * 1000);
					item.paymentProcess = true;
					updateTimerDispatch(item);
					return item;
				});

				setTimerObject(items[0]);

				dispatch(setCheckoutSessionId(res.data.checkoutSessionId));
				localStorage.setItem(res.data.uniquePaymentId, JSON.stringify(reqBody));

				if (res.data.url) {
					setCheckoutUrl(res.data.url);
				} else {
					navigate(`/thank-you/${res.data.uniquePaymentId}`);
				}
			} else {
				alert('Couldnt Lock the breaks');
			}
		} catch (err) {
			if (err.response && err.response.data.error) {
				showToastError(err.response.data.error);
			}
		}
	};

	useEffect(() => {
		if (!checkoutUrl) return;
		window.onbeforeunload = undefined;
		window.location.href = checkoutUrl;
	}, [checkoutUrl]);

	useEffect(() => {
		if (!buyNowData) return;
		const returnUrl = buyNowData.openInNewTab ? '' : window.location.href;
		const reqBody = {
			total: parseFloat(getTotalPrice(true)),
			eventBreaks: getEventBreaks(true),
			buyerName: buyNowData.buyerName,
			buyerAddress: buyNowData.buyerAddress,
			buyerAddress2: buyNowData.buyerAddress2,
			buyerState: buyNowData.buyerState,
			buyerZip: buyNowData.buyerZip,
			buyerCity: buyNowData.buyerCity,
			eventProductIds: getProducts(true),
			buyerFee: parseFloat(buyNowData.paymentOrder.buyerFeePrice),
			sellerFees: buyNowData.paymentOrder.sellerFees,
			paypalOrderId: 'N/A',
			transferGroup: buyNowData.transferGroup,
			paymentIntentId: buyNowData.paymentIntentId,
			returnUrl,
			redirectEventId,
			buyNow: true,
			url: buyNowData.url
		};
		dispatch(setCheckoutSessionId(buyNowData.checkoutSessionId));
		if (buyNowData.paymentIdToProcess) {
			localStorage.setItem(
				buyNowData.paymentIdToProcess,
				JSON.stringify(reqBody)
			);
		}

		if (
			discountFromPromoCode
			&& buyNowData.paymentOrder
			&& buyNowData.paymentOrder.promoCodeDiscountTotal > 0
		) {
			reqBody.discountDetail = {
				discountId: discountFromPromoCode.discountId,
				discountAmount: buyNowData.paymentOrder.promoCodeDiscountTotal
			};
		}
		localStorage.setItem(buyNowData.uniquePaymentId, JSON.stringify(reqBody));

		setTimerObject(items[0]);

		const { uniquePaymentId, url, openInNewTab } = buyNowData;
		dispatch(setBuyNowData(null));
		if (url) {
			if (openInNewTab) {
				if (!isMobile) {
					window.open(url, '_blank');
				}
			} else {
				setCheckoutUrl(url);
			}
		} else {
			navigate(`/thank-you/${uniquePaymentId}`);
		}
	}, [buyNowData, discountFromPromoCode]);

	const backgroundItems = () => items.map((object, i) => (
		<li className="hidden" key={i}>
			<MiniEventTimer
				date={object.time}
				breakId={object.id}
				item={object}
				timerEnded={(id) => removeCartItem(object, true)}
			/>
		</li>
	));

	const onPromoCodeApplied = useCallback(
		async (promoCode) => {
			try {
				const discountCodeRes = await applyPromoCode(
					promoCode,
					getProducts(),
					getBreaks()
				);
				setDiscountFromPromoCode(discountCodeRes.data);
			} catch (err) {
				showToastError(err);
			}
		},
		[items]
	);

	if (!isOpen) {
		return backgroundItems();
	}

	return (
		<Transition.Root show={isOpen} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed inset-0 overflow-hidden cart-dialog"
				open={isOpen}
				onClose={() => {}}
				initialFocus={dialogDivRef}
			>
				<div className="absolute inset-0 overflow-hidden" ref={dialogDivRef}>
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="absolute inset-0 dark:bg-gray-800 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
						<Transition.Child
							as={Fragment}
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enterFrom="translate-x-full"
							enterTo="translate-x-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leaveFrom="translate-x-0"
							leaveTo="translate-x-full"
						>
							<div
								className={`fixed flex top-0 md:h-screen h-full pt-0 pb-0 md:pb-0 z-51 md:z-20 bg-white dark:bg-darkGray-50 transform ${
									isOpen
										? 'right-0 w-full md:w-full ease-in transition'
										: 'right-0 w-0 ease-out transition'
								}`}
							>
								<div className="flex flex-1 flex-col relative w-full h-full bg-white dark:bg-darkGray-50 overflow-hidden pb-5 md:pb-10 pt-2 md:pt-5">
									<div className="absolute -left-12">
										<button
											type="button"
											className="w-12 h-12 focus:outline-none text-white hover:text-white text-lg p-3 rounded-md rounded-tr-none rounded-br-none bg-red-500 hover:bg-red-600"
											onClick={(e) => toggleMiniCart()}
										>
											{!isOpen && (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
													/>
												</svg>
											)}
											{items.length > 0 && `(${items.length})`}

											{isOpen && items.length === 0 && (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
													/>
												</svg>
											)}
										</button>
									</div>
									<div
										className="border-2 border-gray dark:border-black rounded-lg mx-2 md:mx-8 flex flex-col justify-between h-full shadow-lg overflow-y-scroll overflow-x-hidden md:overflow-x-auto"
										style={{
											WebkitOverflowScrolling: 'touch'
										}}
									>
										{showCheckout == false ? (
											<>
												<div className="">
													<div className="flex flex-row px-2 py-2">
														<div className="flex flex-grow items-center border-b-2 border-gray-600 dark:border-darkGray-50">
															<p className="text-sm md:text-xl align-middle leading-4 px-5 text-black dark:text-white ">
																SHOPPING CART&nbsp;
																{cartItemLength() > 0
																	&& `(${cartItemLength()} ${
																		cartItemLength() == 1 ? 'item' : 'items'
																	} in your cart)`}
															</p>
														</div>
														<div className="flex flex-none text-white">
															<button
																type="button"
																className="w-8 h-8 focus:outline-none text-white hover:text-gray-500 text-sm p-1 rounded-sm hover:bg-white"
																onClick={(e) => toggleMiniCart()}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 20 20"
																	fill="currentColor"
																>
																	<path
																		fillRule="evenodd"
																		d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
																		clipRule="evenodd"
																	/>
																</svg>
															</button>
														</div>
													</div>
													<div className="flex-grow px-0 py-2">
														<ul className="flex flex-col">
															{items.map((object, i) => (
																<li
																	className="border-gray-400 flex flex-row mb-1 w-full"
																	key={i}
																>
																	<div className="select-none hover:bg-gray-100 rounded-md flex flex-1 items-center p-4 w-full justify-between">
																		<div className="flex flex-1 flex-col md:flex-row items-center p-4 w-full justify-between border-b-2">
																			<div className="flex flex-row w-full">
																				{object.image ? (
																					<img
																						alt="product"
																						className="flex flex-wrap w-28 h-28 md:h-48 md:w-48"
																						src={object.image}
																						style={{
																							objectFit: 'cover'
																						}}
																					/>
																				) : null}

																				<div className="flex flex-col md:flex-row px-3 md:px-0">
																					<div
																						className={clsx(
																							'flex flex-wrap flex-col font-medium justify-center items-start md:items-center',
																							isTablet
																								? 'md:px-per-10'
																								: 'md:px-per-16'
																						)}
																					>
																						<p className="hover:underline cursor-pointer font-medium w-48 md:w-80">
																							<Link
																								to={`/event/${object.eventId}`}
																							>
																								{object.eventName}
																							</Link>
																						</p>
																						<p className="w-48 md:w-80">
																							{object.title}
																						</p>
																						{isTablet && (
																							<span
																								className="text-red-700 font-bold text-md w-80"
																								key={object.id}
																							>
																								<MiniEventTimer
																									date={object.time}
																									breakId={object.id}
																									item={object}
																									timerEnded={(id) => removeCartItem(object, true)}
																								/>
																							</span>
																						)}
																					</div>
																					{!isTablet && (
																						<div className="text-gray-600 text-xs text-left flex flex-row justify-center items-center">
																							<span
																								className="text-red-700 font-bold text-md w-80"
																								key={object.id}
																							>
																								<MiniEventTimer
																									date={object.time}
																									breakId={object.id}
																									item={object}
																									timerEnded={(id) => removeCartItem(object, true)}
																								/>
																							</span>
																						</div>
																					)}
																				</div>
																			</div>
																			<div className="flex flex-row justify-end md:justify-center items-center w-full md:w-auto">
																				<div
																					className={`flex justify-center pr-16 max-h-8 ${
																						!object.isProduct && 'hidden'
																					}`}
																				>
																					<svg
																						className="cursor-pointer fill-current text-gray-600 w-3"
																						viewBox="0 0 448 512"
																						onClick={() => decreaseProductQuantityFromCart(
																							object
																						)}
																					>
																						<path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
																					</svg>

																					<input
																						className="mx-2 border text-center w-8"
																						type="text"
																						value={object.quantity}
																					/>

																					<svg
																						className="cursor-pointer fill-current text-gray-600 w-3"
																						viewBox="0 0 448 512"
																						onClick={() => addProductQuantityToCart(object)}
																					>
																						<path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
																					</svg>
																				</div>
																				<div
																					className="text-gray-600 text-base text-left flex flex-row md:pr-per-8 justify-content-end"
																					style={{
																						whiteSpace: 'nowrap'
																					}}
																				>
																					$
																					{object.price}
																				</div>
																				<div className="text-gray-600 text-xs text-right flex flex-col justify-content-end">
																					<button
																						type="button"
																						className="w-8 h-8 focus:outline-none text-gray-500 hover:text-white text-sm p-1 rounded-sm hover:bg-red-300  disabled:opacity-50 disabled:cursor--allowed"
																						onClick={() => removeCartItem(object)}
																						// disabled={removeItemDisabled}
																					>
																						<svg
																							xmlns="http://www.w3.org/2000/svg"
																							viewBox="0 0 20 20"
																							fill="currentColor"
																						>
																							<path
																								fillRule="evenodd"
																								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
																								clipRule="evenodd"
																							/>
																						</svg>
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</li>
															))}
														</ul>
													</div>
													{items.length ? (
														<PromoCode
															onPromoCodeApplied={onPromoCodeApplied}
															appliedDiscount={discountFromPromoCode}
															onRemoveDiscount={() => setDiscountFromPromoCode(null)}
														/>
													) : null}
												</div>
												<div className="flex flex-row px-2 py-1 mb-10 md:mb-8 justify-between px-10">
													<div className={clsx(isMobile && 'flex items-end')}>
														<div className="flex flex-row">
															<button
																type="button"
																className="w-full focus:outline-none text-white text-sm py-2.5 px-5 rounded-sm bg-djawn hover:bg-red-600 hover:shadow-lg disabled:opacity-50 disabled:cursor-wait"
																onClick={closeCart}
															>
																Shop More
															</button>
														</div>
													</div>
													<div className="">
														<div className="flex md:flex-row flex-col md:space-x-9 space-x-0 space-y-2 md:space-y-0 items-center w-full">
															<button
																type="button"
																className={clsx(
																	'h-1/2 w-full md:w-auto focus:outline-none text-white text-sm py-2.5 px-5 rounded-sm bg-blue-500 hover:bg-blue-700 hover:shadow-lg disabled:opacity-50 disabled:cursor-wait',
																	payNowLoading
																		&& 'flex items-center justify-center'
																)}
																onClick={() => {
																	setPayNowLoading(true);
																	onCheckout().finally(() => setPayNowLoading(false));
																}}
																disabled={checkoutDisabled || payNowLoading}
															>
																{payNowLoading && (
																	<ClipLoader size={20} css={clipLoaderCss} />
																)}
																Pay Now
															</button>
															<button
																type="button"
																className="h-1/2 focus:outline-none text-white text-sm py-2.5 px-5 rounded-sm bg-djawn hover:bg-red-600 hover:shadow-lg disabled:opacity-50 disabled:cursor-wait"
																onClick={(e) => onCheckoutButtonClicked()}
																disabled={checkoutDisabled}
															>
																Review Order
															</button>
														</div>
													</div>
												</div>
											</>
										) : (
											<Checkout
												toggleMiniCart={toggleMiniCart}
												items={items}
												setShowCheckout={setShowCheckout}
												totalPrice={getTotalPrice}
												timerObject={timerObject}
												removeItemDispatch={removeItemDispatch}
												productName={productName}
												paymentOrder={paymentOrder}
												removeItemsFromOrder={removeItemsFromOrder}
												onPurchased={onPurchased}
												showThankYou={showThankYou}
												onCheckout={onCheckout}
												transferGroup={transferGroup}
												stripeClientSecret={stripeClientSecret}
												setStripeClientSecret={setStripeClientSecret}
												setTransferGroup={setTransferGroup}
												chargeId={chargeId}
												checkoutUrl={checkoutUrl}
												setCheckoutUrl={setCheckoutUrl}
												showStep3={showStep3}
												liveEvent={liveEvent}
											/>
										)}
									</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default connect(
	({ cart, buyNowCart }) => ({
		currentCheckoutSessionId: cart.currentCheckoutSessionId,
		buyNowData: buyNowCart.buyNowData
	}),
	null
)(MiniCart);
