import React from 'react';
import Layout from '../components/layouts/layout-default';
import HeaderHome from '../components/common/header-home';

const PrivacyPolicy = () => (
	<Layout>
		<div className="flex flex-col flex-1">
			<HeaderHome hideSearch />
			<div className="flex flex-col flex-1 justify-start content-center">
				<div className="flex flex-1 flex-col justify-items-center bg-white w-8/12 xxs:w-full lg:w-8/12 mx-auto px-6 xxs:px-0 py-10 xxs:py-8 rounded-lg my-5 shadow-lg">
					<div className="px-8 policy-container">
						<h2>Privacy Policy</h2>
						<br />
						<div>
							<p>
								This Privacy Policy describes how www.djawn.com (the “Site” or
								“we”) collects, uses, and discloses your Personal Information
								when you visit or make a purchase from the Site.
							</p>
							<br />
							<p>
								<strong>Collecting Personal Information</strong>
							</p>
							<br />
							<p>
								When you visit the Site, we collect certain information about
								your device, your interaction with the Site, and information
								necessary to process your purchases. We may also collect
								additional information if you contact us for customer support.
								In this Privacy Policy, we refer to any information that can
								uniquely identify an individual (including the information
								below) as “Personal Information”. See the list below for more
								information about what Personal Information we collect and why.
							</p>
							<br />
							<p>
								<strong>Device Information</strong>
							</p>
							<br />
							<ul>
								<li>
									Examples of Personal Information collected:&nbsp;type of web
									browser, device, general location/time zone, cookie
									information.
								</li>

								<li>
									Purpose of collection:&nbsp;to load the Site accurately for
									you, and to perform analytics on Site usage to optimize our
									Site.
								</li>

								<li>
									Source of collection:&nbsp;Collected automatically when you
									access our Site using cookies, log files, web beacons, tags,
									or pixels (Facebook pixel/Google Analytics).
								</li>

								<li>Disclosure for a business purpose:&nbsp;N/A</li>
							</ul>
							<br />
							<p>
								<strong>Order Information</strong>
							</p>
							<br />

							<ul>
								<li>
									Examples of Personal Information collected:&nbsp;name, billing
									address, shipping address, payment information (including
									credit card numbers, paypal and/or venmo user account), email
									address, and phone number.
								</li>

								<li>
									Purpose of collection:&nbsp;to provide products or services to
									you to fulfill our contract, to process your payment
									information, arrange for shipping, and provide you with
									invoices and/or order confirmations, communicate with you,
									screen our orders for potential risk or fraud, and when in
									line with the preferences you have shared with us, provide you
									with information or advertising relating to our products or
									services.
								</li>

								<li>Source of collection:&nbsp;collected from you.</li>

								<li>
									Disclosure for a business purpose:&nbsp;shared with our
									processor Paypal
								</li>
							</ul>

							<br />
							<p>
								<strong>Customer Support Information</strong>
							</p>
							<br />
							<ul>
								<li>
									Examples of Personal Information collected:&nbsp;order number,
									transaction date, name, billing address, shipping address,
									payment information (including credit card numbers, paypal
									and/or venmo user account), email address, and phone number.
								</li>

								<li>
									Purpose of collection:&nbsp;to provide customer support.
								</li>

								<li>Source of collection:&nbsp;collected from you.</li>

								<li>Disclosure for a business purpose:&nbsp;N/A</li>
							</ul>
							<br />
							<p>
								<strong>Minors</strong>
							</p>
							<br />
							<p>
								The Site is not intended for individuals under the age of 18. We
								do not intentionally collect Personal Information from children.
								If you are the parent or guardian and believe your child has
								provided us with Personal Information, please contact us at the
								address below to request deletion.
							</p>
							<br />
							<p>
								<strong>Sharing Personal Information</strong>
							</p>
							<br />
							<p>
								We share your Personal Information with service providers to
								help us provide our services and fulfill our contracts with you,
								as described above. For example:
							</p>

							<br />
							<ul>
								<li>
									We may share your Personal Information to comply with
									applicable laws and regulations, to respond to a subpoena,
									search warrant or other lawful request for information we
									receive, or to otherwise protect our rights.
								</li>
							</ul>
							<br />
							<p>
								<strong>Behavioral Advertising</strong>
							</p>
							<br />
							<p>
								As described above, we use your Personal Information to provide
								you with targeted advertisements or marketing communications we
								believe may be of interest to you. For example:
							</p>

							<ul>
								<li>
									We use Google Analytics to help us understand how our
									customers use the Site. You can read more about how Google
									uses your Personal Information here:&nbsp;
									<a
										href="https://policies.google.com/privacy?hl=en"
										data-wpel-link="external"
										target="_blank"
										className="link-message-text"
										rel="external noopener noreferrer"
									>
										https://policies.google.com/privacy?hl=en
									</a>
									.&nbsp;You can also opt-out of Google Analytics here:&nbsp;
									<a
										href="https://tools.google.com/dlpage/gaoptout"
										data-wpel-link="external"
										target="_blank"
										className="link-message-text"
										rel="external noopener noreferrer"
									>
										https://tools.google.com/dlpage/gaoptout
									</a>
									.
								</li>

								<li>
									We share information about your use of the Site, your
									purchases, and your interaction with our ads on other websites
									with our advertising partners. We collect and share some of
									this information directly with our advertising partners, and
									in some cases through the use of cookies or other similar
									technologies (which you may consent to, depending on your
									location).
								</li>
							</ul>

							<p>
								For more information about how targeted advertising works, you
								can visit the Network Advertising Initiative’s (“NAI”)
								educational page at&nbsp;
								<a
									href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
									data-wpel-link="external"
									className="link-message-text"
									target="_blank"
									rel="external noopener noreferrer"
								>
									http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
								</a>
								.
							</p>

							<p>You can opt out of targeted advertising via:</p>

							<ul>
								<li>
									FACEBOOK –&nbsp;
									<a
										href="https://www.facebook.com/settings/?tab=ads"
										data-wpel-link="external"
										target="_blank"
										className="link-message-text"
										rel="external noopener noreferrer"
									>
										https://www.facebook.com/settings/?tab=ads
									</a>
								</li>

								<li>
									GOOGLE –&nbsp;
									<a
										href="https://www.google.com/settings/ads/anonymous"
										data-wpel-link="external"
										target="_blank"
										className="link-message-text"
										rel="external noopener noreferrer"
									>
										https://www.google.com/settings/ads/anonymous
									</a>
								</li>
							</ul>

							<p>
								Additionally, you can opt out of some of these services by
								visiting the Digital Advertising Alliance’s opt-out portal
								at:&nbsp;
								<a
									href="http://optout.aboutads.info/"
									data-wpel-link="external"
									target="_blank"
									className="link-message-text"
									rel="external noopener noreferrer"
								>
									http://optout.aboutads.info/
								</a>
								.
							</p>

							<br />
							<p>
								<strong>Using Personal Information</strong>
							</p>

							<br />
							<p>
								We use your personal Information to provide our services to you,
								which includes: offering products for sale, processing payments,
								shipping and fulfillment of your order, and keeping you up to
								date on new products, services, and offers.
							</p>

							<br />
							<p>
								<strong>Your Rights</strong>
							</p>

							<br />
							<p>
								<strong>CCPA</strong>
							</p>

							<br />
							<p>
								If you are a resident of California, you have the right to
								access the Personal Information we hold about you (also known as
								the ‘Right to Know’), to port it to a new service, and to ask
								that your Personal Information be corrected, updated, or erased.
								If you would like to exercise these rights, please contact us
								through the contact information below.
							</p>

							<p>
								If you would like to designate an authorized agent to submit
								these requests on your behalf, please contact us at the address
								below.
							</p>
							<br />
							<p>
								<strong>Cookies</strong>
							</p>
							<br />
							<p>
								A cookie is a small amount of information that is downloaded to
								your computer or device when you visit our Site. We use a number
								of different cookies, including functional, performance,
								advertising, and social media or content cookies. Cookies make
								your browsing experience better by allowing the website to
								remember your actions and preferences (such as login and region
								selection). This means you do not have to re-enter this
								information each time you return to the site or browse from one
								page to another. Cookies also provide information on how people
								use the website, for instance whether it is their first time
								visiting or if they are a frequent visitor.
							</p>

							<br />
							<p>
								The length of time that a cookie remains on your computer or
								mobile device depends on whether it is a “persistent” or
								“session” cookie. Session cookies last until you stop browsing
								and persistent cookies last until they expire or are deleted.
								Most of the cookies we use are persistent and will expire
								between 30 minutes and two years from the date they are
								downloaded to your device.
							</p>

							<br />
							<p>
								You can control and manage cookies in various ways. Please keep
								in mind that removing or blocking cookies can negatively impact
								your user experience and parts of our website may no longer be
								fully accessible.
							</p>

							<br />
							<p>
								Most browsers automatically accept cookies, but you can choose
								whether or not to accept cookies through your browser controls,
								often found in your browser’s “Tools” or “Preferences” menu. For
								more information on how to modify your browser settings or how
								to block, manage or filter cookies can be found in your
								browser’s help file or through such sites as&nbsp;
								<a
									href="https://www.allaboutcookies.org/"
									data-wpel-link="external"
									target="_blank"
									className="link-message-text"
									rel="external noopener noreferrer"
								>
									www.allaboutcookies.org
								</a>
								.
							</p>

							<br />
							<p>
								Additionally, please note that blocking cookies may not
								completely prevent how we share information with third parties
								such as our advertising partners. To exercise your rights or
								opt-out of certain uses of your information by these parties,
								please follow the instructions in the “Behavioral Advertising”
								section above.
							</p>

							<br />
							<p>
								<strong>Do Not Track</strong>
							</p>

							<br />
							<p>
								Please note that because there is no consistent industry
								understanding of how to respond to “Do Not Track” signals, we do
								not alter our data collection and usage practices when we detect
								such a signal from your browser.
							</p>

							<br />
							<p>
								<strong>Changes</strong>
							</p>

							<br />
							<p>
								We may update this Privacy Policy from time to time in order to
								reflect, for example, changes to our practices or for other
								operational, legal, or regulatory reasons.
							</p>

							<br />
							<p>
								<strong>Contact</strong>
							</p>

							<br />
							<p>
								For more information about our privacy practices, if you have
								questions, or if you would like to make a complaint, please
								contact us by e-mail at
								{' '}
								<a
									href="mailto:support.djawn.com"
									className="link-message-text"
								>
									support@djawn.com
								</a>
								{' '}
								or by mail using the details provided below:
							</p>

							<br />
							<p>
								DJAWN LLC
								<br />
								Attn: Privacy Dept.
								<br />
								109 Ravenhill Rd
								<br />
								Phoenixville, PA 19460
								<br />
								United States
							</p>

							<br />
							<p>
								<br />
								Last updated: January 20th, 2023
							</p>

							<p>
								If you are not satisfied with our response to your complaint,
								you have the right to lodge your complaint with the relevant
								data protection authority
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
);

export default PrivacyPolicy;
