import React from 'react';
import Modal from '.';
import { SuccessAnimationIcon } from '../../icons';
import Button from '../../button';

const SuccessModal = ({
	showModal,
	setShowModal,
	title = 'Successful',
	message = 'Changes are saved successfully'
}) => (
	<Modal showModal={showModal} setShowModal={setShowModal} showHeader={false}>
		<div className="px-8 py-4 flex items-center justify-center flex-col">
			<div className="space-y-2 pl-3">
				<SuccessAnimationIcon className="h-12 w-12" />
				<h3 className="pr-3 dark:text-gray-300">{title}</h3>
			</div>

			{message && <p className="pt-4 text-md dark:text-gray-300">{message}</p>}

			<div className="pt-7 w-full">
				<Button
					height="10"
					mobileHeight="10"
					variant="success"
					width="full"
					additionalClassName="dark:text-gray-300"
					mobileWidth="full"
					onClick={() => setShowModal(false)}
				>
					Done
				</Button>
			</div>
		</div>
	</Modal>
);

export default SuccessModal;
