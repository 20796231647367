import React from 'react';
import { isMobile } from 'react-device-detect';
import { CreateEventProvider } from '../../../contexts/create-event';
import CreateEvent from '../../create-event';
import Modal from '../../../components/modals/index';

const CreateLiveEventModal = ({
	eventId,
	showModal,
	setShowModal,
	hasRipNShipEvent
}) => (
	<Modal
		showModal={showModal}
		onClose={() => setShowModal(false)}
		hideCloseButton
		hideOverflow={false}
		className="lg:max-w-6xl md:max-w-5xl h-per-94 md:h-per-97 lg:w-6xl overflow-auto"
		containerClassName="md:h-112 lg:h-full h-full overflow-auto m-0"
	>
		<CreateEventProvider
			useCurrentDate
			linkToCurrentEvent
			mainEventId={eventId}
			wrappedAroundModal
			showRipNShipEventType={!hasRipNShipEvent}
			onFinalization={() => setShowModal(false)}
		>
			<CreateEvent
				noPaddings={isMobile}
				shrinkSelectTemplateHeader={isMobile}
			/>
		</CreateEventProvider>
	</Modal>
);

export default CreateLiveEventModal;
