import { useCallback, useState } from 'react';
import { getAuthenticatedUserId } from '../api/auth.request';
import { logChatEventRoomError, updateChatId } from '../api/event.request';
import { getDjawnUser } from '../api/referenceData.request';
import { getCurrentUser } from '../utils/auth';
import useConversation from './useConversation';

const useLiveEventChat = (userId, eventId) => {
	const {
		createConversation,
		addParticipantToConversation,
		findConversationByChild
	} = useConversation();
	const [conversation, setConversation] = useState(null);

	const initializeConversation = useCallback(
		async (eventHost, conversationName, updateConversation = false) => {
			if (eventHost) {
				await logChatEventRoomError(
					eventId,
					`Event host is calling initializeConversation with the following conversation name: ${conversationName}`
				);
				const existingConversation = await findConversationByChild(
					'event_id',
					eventId,
					true
				);
				if (!existingConversation) {
					let newConversationId;
					try {
						newConversationId = await createConversation(
							`Live Event - ${conversationName}`,
							userId,
							true,
							true,
							{ live_event: true, event_id: eventId },
							false
						);

						if (!newConversationId) {
							await logChatEventRoomError(
								eventId,
								'Conversation Id was not created'
							);
						}
					} catch (err) {
						let errorMessage = '';
						if (typeof err === 'string') {
							errorMessage = err;
						} else if (typeof err == 'object') {
							errorMessage = JSON.stringify(err);
						}
						if (errorMessage && errorMessage.length) {
							await logChatEventRoomError(eventId, errorMessage);
						}
					}
					const djawnUserRes = await getDjawnUser();
					try {
						await addParticipantToConversation(
							newConversationId,
							djawnUserRes.data.userId,
							false,
							{
								live_event: true
							}
						);
					} catch (err) {
						console.error(err);
					}

					initializeConversation(eventHost, conversationName, true);
				} else {
					setConversation(existingConversation);
					if (updateConversation) {
						try {
							updateChatId(eventId, existingConversation.id);
						} catch (err) {
							console.error(err);
						}
					}
				}
			} else {
				try {
					const foundConversation = await findConversationByChild(
						'event_id',
						eventId,
						true
					);
					if (foundConversation && userId) {
						await addParticipantToConversation(
							foundConversation.id,
							userId,
							false,
							{
								live_event: true
							}
						);
						setConversation(foundConversation);
					} else if (foundConversation && !userId) {
						setConversation(foundConversation);
						try {
							const userRes = await getAuthenticatedUserId();
							await addParticipantToConversation(
								foundConversation.id,
								userRes.data,
								false,
								{
									live_event: true
								}
							);
							setConversation(foundConversation);
						} catch (err) {
							console.error(err);
							const foundUserIdByLocalStorage = getCurrentUser();
							if (foundUserIdByLocalStorage && foundUserIdByLocalStorage.id) {
								await addParticipantToConversation(
									foundConversation.id,
									foundUserIdByLocalStorage.id,
									false,
									{
										live_event: true
									}
								);
							}
							setConversation(foundConversation);
						}
					}
				} catch (err) {
					console.error(err);
				}
			}
		},
		[eventId, userId]
	);

	return { initializeConversation, conversation };
};

export default useLiveEventChat;
