import React, { useContext, useMemo } from 'react';
import { ProfileContext } from '../../../contexts/profile';
import SellerGeneralInformation from './seller-general-information';
import Tabs from '../../../components/Tabs';
import SellerShippingInformation from './seller-shipping-information';
import SellerShippoInformation from './seller-shippo-information';

const SellerProfileTabs = () => {
	const { showShippoTab } = useContext(ProfileContext);

	const tabs = useMemo(() => {
		const array = [
			{
				title: 'General',
				component: () => <SellerGeneralInformation />
			},
			{
				title: 'Shipping',
				component: () => <SellerShippingInformation />
			}
		];
		if (showShippoTab) {
			array.push({
				title: 'Shippo information',
				component: () => <SellerShippoInformation />
			});
		}

		return array;
	}, [showShippoTab]);

	return <Tabs isNested tabs={tabs} />;
};

export default SellerProfileTabs;
