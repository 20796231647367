/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { ReactSortable } from 'react-sortablejs';
import ReactTooltip from 'react-tooltip';
import { moveQueuePosition } from '../../api/event.request';
import { useInterval } from '../common/use-interval';
import {
	getElapsedTimestampWithCurrentDateQueue,
	getElapsedTimestampWithCurrentDateQueueTwoDates
} from '../../utils/formatter';
import { getCurrentUser } from '../../utils/auth';
import { defaultToastError } from '../../utils/toast-error';
import EllipisWithTooltip from '../ellipsis-tooltip';
import { TwilioTrackContext } from '../../contexts/twilio-track';
import { SellerEventAttendeeActionsContext } from '../../contexts/seller-event-attendee-actions';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import MicOrRequestIcon from '../mic-or-request-mic-icon';
import HamburgerMenuIcon from '../../assets/icons/hamburger-menu-icon';
import { RipNShipActiveLineContext } from '../../contexts/ripnship-activeline';

const RipNShipEventQueue = ({
	eventId,
	initialBuyers,
	changeWsMessage,
	initiatedEndTurnsWsMessage,
	setInitiatedEndTurnsWsMessage,
	onEndTurn,
	endTurnWsMessage,
	exitWsMessage,
	newBuyersWsMessage,
	reinitiateEventInformation,
	visible,
	setPurchasedProductsUserId,
	updatedBuyersQueueWsMessage,
	barrierLine,
	joinQueue,
	hideJoinButton
}) => {
	const [enableMovement, setEnableMovement] = useState(true);
	const [initiatedEndTurns, setInitiatedEndTurns] = useState([]);
	const [enableEndTurn, setEnableEndTurn] = useState(true);
	const { audioParticipants } = useContext(TwilioTrackContext);
	const { setSelectedUserForActions } = useContext(
		SellerEventAttendeeActionsContext
	);
	const { isSeller, attendees, webSocketGroupName } = useContext(
		LiveEventDataContext
	);
	const [raisedHandOrIconUserIds, setRaisedHandOrIconUserIds] = useState([]);
	const {
		ripNShipTimers: timers,
		setRipNShipTimers: setTimers,
		ripNShipActiveBuyersInLine: buyers,
		setRipNShipActiveBuyersInLine: setBuyers,
		joinedQueue,
		setJoinedQueue
	} = useContext(RipNShipActiveLineContext);

	useEffect(() => {
		if (!changeWsMessage) return;

		updateTimerOnPositionUpdate(
			changeWsMessage.position,
			changeWsMessage.swappedPosition,
			changeWsMessage.positionDateTime,
			changeWsMessage.previousPositionDateTime
		);
		const tempBuyers = [...buyers]
			.map((buyer) => {
				let done = false;
				if (buyer.position == changeWsMessage.swappedPosition) {
					buyer.position = changeWsMessage.position;
					done = true;
				} else if (!done && buyer.position == changeWsMessage.position) {
					buyer.position = changeWsMessage.swappedPosition;
				}

				return buyer;
			})
			.sort((a, b) => a.position - b.position);

		setBuyers(tempBuyers);
	}, [changeWsMessage]);

	useEffect(() => {
		if (!endTurnWsMessage) return;
		reinitiateEventInformation();
	}, [endTurnWsMessage]);

	useEffect(() => {
		if (!updatedBuyersQueueWsMessage) return;
		setBuyers(updatedBuyersQueueWsMessage);

		const currentUser = getCurrentUser();
		if (currentUser && currentUser.id) {
			if (updatedBuyersQueueWsMessage && updatedBuyersQueueWsMessage.length) {
				const foundUserInQueue = updatedBuyersQueueWsMessage.find(
					(r) => r.userId == currentUser.id
				);
				setJoinedQueue(foundUserInQueue != null);
			} else {
				setJoinedQueue(false);
			}
		}

		reinitiateEventInformation();
	}, [updatedBuyersQueueWsMessage]);

	useEffect(() => {
		if (!exitWsMessage) return;
		let tempBuyers = [...buyers]
			.map((buyer) => {
				const foundBuyer = exitWsMessage.find((p) => p.item1 == buyer.userId);
				if (!foundBuyer) return buyer;
				buyer.position = foundBuyer.item2;
				return buyer;
			})
			.sort((a, b) => a.position - b.position)
			.filter((el) => el != null);

		const deletedPerson = exitWsMessage.find((p) => p.item2 == 0); // if position 0 delete

		tempBuyers = tempBuyers
			.filter((f) => f.userId != deletedPerson.item1)
			.sort((a, b) => a.position - b.position);

		let tempTimers = [...timers]
			.map((timer) => {
				const foundTimer = exitWsMessage.find((t) => t.item1 == timer.userId);
				if (!foundTimer) return timer;
				timer.position = foundTimer.item2;
				timer.startTime = foundTimer.item3;
				timer.time = '00:00:00';

				return timer;
			})
			.sort((a, b) => a.position - b.position)
			.filter((el) => el != null);

		tempTimers = tempTimers
			.filter((f) => f.userId != deletedPerson.item1)
			.sort((a, b) => a.position - b.position);

		setBuyers(tempBuyers);
		setTimers(tempTimers);

		const tempInitiatedEndTurns = [...initiatedEndTurns].filter(
			(f) => f != deletedPerson.item1
		);
		setInitiatedEndTurns(tempInitiatedEndTurns);
	}, [exitWsMessage]);

	useEffect(() => {
		if (!initiatedEndTurnsWsMessage) return;

		const tempInitiatedEndTurns = [...initiatedEndTurns].concat(
			initiatedEndTurnsWsMessage
		);
		setInitiatedEndTurns(tempInitiatedEndTurns);
		setInitiatedEndTurnsWsMessage(null);
	}, [initiatedEndTurnsWsMessage]);

	const createTimers = () => {
		const tempTimers = [];
		[...initialBuyers].forEach((buyer) => {
			tempTimers.push({
				position: buyer.position,
				userId: buyer.userId,
				linkedEventId: buyer.linkedEventId,
				startTime: buyer.positionStartDate,
				lineStartTime: buyer.lineStartDate,
				useSameTime: buyer.positionStartDate == buyer.lineStartDate,
				time: '00:00:00',
				lineTime: '00:00:00'
			});
		});
		setTimers(tempTimers);
	};

	useInterval(() => {
		const copiedTimers = [...timers];
		const tempTimers = copiedTimers.map((timer) => {
			if (timer.useSameTime) {
				const time = getElapsedTimestampWithCurrentDateQueue(timer.startTime);
				timer.time = time;
				timer.lineTime = time;
			} else {
				const [
					time,
					lineTime
				] = getElapsedTimestampWithCurrentDateQueueTwoDates(
					timer.startTime,
					timer.lineStartTime
				);
				timer.time = time;
				timer.lineTime = lineTime;
			}

			return timer;
		});

		setTimers(tempTimers);
	}, 1000);

	// TODO: Can be refactored V2.0
	useEffect(() => {
		if (!eventId || !initialBuyers || !initialBuyers.length) return;
		const user = getCurrentUser();
		const userHasJoinedTheQueue = initialBuyers.find(
			(f) => f.userId == user.id
		);
		if (userHasJoinedTheQueue) setJoinedQueue(true);
		createTimers();

		const tempArr = [...initialBuyers]
			.map((b) => {
				if (b.initiatedEndTurn == true) {
					if (b.userId) return b.userId;
					return null;
				}
				return null;
			})
			.filter((b) => b != null);

		setInitiatedEndTurns(tempArr);

		const hasCurrentUserInitiatedEndTurn = [...buyers].find(
			(f) => f.initatedEndTurn == true && f.userId == user.id
		);

		if (hasCurrentUserInitiatedEndTurn) setEnableEndTurn(false);
		setBuyers(initialBuyers);
	}, []);

	useEffect(() => {
		if (!initialBuyers || !initialBuyers.length) return;
		createTimers();
		setBuyers(initialBuyers);
	}, [initialBuyers]);

	useEffect(() => {
		if (!newBuyersWsMessage) return;
		const newBuyer = {
			id: newBuyersWsMessage.queueOrder.ripNShipQueueOrderId,
			imageUrl: newBuyersWsMessage.queueOrder.profileImageName,
			name: newBuyersWsMessage.queueOrder.fullName,
			nickname: newBuyersWsMessage.queueOrder.nickname,
			position: newBuyersWsMessage.queueOrder.currentPosition,
			purchasedItem: newBuyersWsMessage.queueOrder.purchasedItem,
			userId: newBuyersWsMessage.queueOrder.userId,
			linkedEventId: newBuyersWsMessage.queueOrder.linkedEventId
		};

		const tempTimers = [...timers].concat({
			position: newBuyer.position,
			startTime: newBuyersWsMessage.positionStartedDate,
			lineStartTime: newBuyersWsMessage.lineStartedDate,
			time: '00:00:00',
			userId: newBuyer.userId,
			useSameTime:
				newBuyersWsMessage.positionStartedDate
				== newBuyersWsMessage.lineStartedDate
		});

		const user = getCurrentUser();
		if (user && newBuyer.userId == user.id) setJoinedQueue(true);
		setTimers(tempTimers);
		if (!buyers || !buyers.length) {
			setBuyers([newBuyer]);
		} else {
			let tempBuyers = [...buyers];
			tempBuyers = tempBuyers.filter((b) => b.userId != newBuyer.userId);
			tempBuyers.push(newBuyer);

			tempBuyers = tempBuyers.sort((a, b) => a.position - b.position);

			setBuyers(tempBuyers);
		}

		reinitiateEventInformation();
	}, [newBuyersWsMessage]);

	const movePosition = ({ id, position }, upInterval) => {
		setEnableMovement(false);
		if (upInterval) {
			const tempBuyers = [...buyers]
				.map((b) => {
					if (b.id == id) {
						b.position = parseInt(position) + upInterval;
					} else if (parseInt(position) + upInterval == parseInt(b.position)) {
						b.position = position;
					}

					return b;
				})
				.sort((a, b) => a.position - b.position);

			setBuyers(tempBuyers);
			position = parseInt(position) + upInterval;
		}
		moveQueuePosition(eventId, id, position, webSocketGroupName)
			.then(({ data }) => {
				updateTimerOnPositionUpdate(
					position,
					data.swappedPosition,
					data.positionTurnStartDate,
					data.previousPositionTurnStartDate
				);
			})
			.catch((err) => {
				if (err && err.response && err.response.data.error) {
					defaultToastError(err.response.data.error);
				}
			})
			.finally((_) => {
				setEnableMovement(true);
			});
	};

	const updateTimerOnPositionUpdate = (
		position,
		nextPosition,
		date,
		nextDate
	) => {
		const tempTimersArr = [...timers];
		const nextPositionLineStartTime = tempTimersArr.filter(
			(p) => p.position == nextPosition
		)[0].lineStartTime;
		const previousPositionTimerLineStartTime = tempTimersArr.filter(
			(p) => p.position == position
		)[0].lineStartTime;

		const tempTimers = [...timers].map((timer) => {
			if (timer.position == position) {
				timer.startTime = date;
				timer.time = '00:00:00';
				timer.lineStartTime = nextPositionLineStartTime;
			} else if (timer.position == nextPosition) {
				timer.startTime = nextDate;
				timer.time = '00:00:00';
				timer.lineStartTime = previousPositionTimerLineStartTime;
			}
			return timer;
		});

		setTimers(tempTimers);
	};

	const getTimerElapsedByPosition = (position) => {
		const timer = timers.find((p) => p.position == position);
		if (!timer || !timer.time) return null;
		return <p>{timer.time}</p>;
	};

	const getLineStartTimeElapsedByPosition = (position) => {
		const timer = timers.find((p) => p.position == position);

		if (!timer || !timer.lineTime) return null;
		return <p>{timer.lineTime}</p>;
	};

	const getEndTurnButton = (userId) => {
		const initiated = initiatedEndTurns.filter((f) => f == userId)[0];
		if (initiated) {
			return (
				<button
					type="button"
					className="w-64 bg-djawn hover:bg-djawn-300 text-gray-100 hover:text-gray-100 text-xs font-semibold rounded-sm px-4 xxs:px-2 py-2 border-0 flex justify-center mb-1"
					onClick={onEndTurn}
				>
					All Done
				</button>
			);
		}
		return null;
	};

	return (
		<>
			<div className={`${visible == true ? 'block' : 'hidden'}`}>
				<div className="flex flex-col w-2/3 xxs:w-11/12 mx-auto text-left py-6 pt-2 overflow-x-scroll">
					<div>
						<table className="table-fixed w-full text-left shadow-md">
							<thead>
								<tr className="border-b flex justify-center">
									<th>
										<p className="w-64 flex justify-center font-bold dark:text-white">
											Ripping Now
										</p>
									</th>
								</tr>
							</thead>
							<ReactSortable
								tag="tbody"
								className="text-gray-700 text-sm"
								list={buyers}
								disabled={!isSeller}
								setList={(newState) => {
									const tempBuyers = [...newState]
										.filter((f) => f != null)
										.map((buyer, index) => {
											buyer.position = index + 1;
											return buyer;
										});
									setBuyers(tempBuyers);
								}}
								onUpdate={(order, sortable, store) => {
									let oldIndex = order.oldIndex + 1;
									let newIndex = order.newIndex + 1;

									if (oldIndex > 3) {
										oldIndex -= 2;
									}
									if (newIndex > 3) {
										newIndex -= 2;
									}

									const movedBuyer = [...buyers].find(
										(b) => b.position == oldIndex
									);

									if (movedBuyer) {
										movedBuyer.position = newIndex;
										movePosition(movedBuyer);
									}
								}}
								swap
							>
								{buyers.map((buyer, index) => (
									<>
										<tr
											className={`
									${isSeller == true && 'cursor-move'} 
									bg-white font-medium text-sm ${buyer.position > 1 && 'border-b'} ${
										buyer.position == barrierLine
												&& 'border-b-4 border-black'
									} flex justify-between dark:bg-darkGray-50 dark:text-white items-center py-3`}
											key={buyer.id}
										>
											<ReactTooltip />
											<td className="inline-flex">
												<div className="flex items-center">
													<p className="inline-flex text-sm">
														{buyer.position}
														<span
															className={`flex space-x-4 items-between pl-3 w-${
																(raisedHandOrIconUserIds
																	&& raisedHandOrIconUserIds.length
																	&& raisedHandOrIconUserIds.find(
																		(uid) => buyer.userId
																	) != null)
																|| buyer.purchasedItem == true
																	? '32'
																	: '48'
															}`}
														>
															{isSeller ? (
																<span
																	data-tip={buyer.name}
																	className="text-sm truncate"
																>
																	{!buyer.nickname
																		? buyer.name
																		: buyer.nickname}
																</span>
															) : (
																<EllipisWithTooltip
																	isParagraph
																	placement="bottom"
																	className="text-sm"
																	overridenStyle={{
																		whiteSpace: 'nowrap',
																		overflow: 'hidden',
																		textOverflow: 'ellipsis'
																	}}
																>
																	{!buyer.nickname
																		? buyer.name
																		: buyer.nickname}
																</EllipisWithTooltip>
															)}
														</span>
														{attendees && attendees.length ? (
															<MicOrRequestIcon
																attendee={attendees.find(
																	(a) => a.userId == buyer.userId
																)}
																audioParticipants={audioParticipants}
																matchAttendeesViaUserId
																setRaisedHandOrMicIconUserId={(userId) => {
																	setRaisedHandOrIconUserIds((userIds) => {
																		if (!userIds || !userIds.length) return [userId];
																		const foundUserId = userIds.find(
																			(uid) => uid == userId
																		);
																		if (foundUserId != null) return userIds;
																		const newUserIds = [...userIds];
																		newUserIds.push(userId);
																		return newUserIds;
																	});
																}}
															/>
														) : null}
													</p>
												</div>
											</td>
											<td className="inline-flex items-center text-sm space-x-2">
												{buyer.position == 1
													? getTimerElapsedByPosition(buyer.position)
													: getLineStartTimeElapsedByPosition(buyer.position)}

												{buyer.purchasedItem && (
													<div
														className="pl-2"
														onClick={() => {
															setPurchasedProductsUserId(buyer.userId);
														}}
														onKeyDown={() => {
															setPurchasedProductsUserId(buyer.userId);
														}}
													>
														<svg
															className="md:w-6 w-5 h-5 md:h-6"
															fill="none"
															stroke="currentColor"
															viewBox="0 0 24 24"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth="2"
																d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
															/>
														</svg>
													</div>
												)}
												{isSeller && buyer.userId ? (
													<HamburgerMenuIcon
														className="w-6 h-6"
														onClick={() => {
															const attendee = attendees.find(
																(b) => b.userId == buyer.userId
															);
															if (attendee) {
																setSelectedUserForActions(attendee);
															}
														}}
													/>
												) : null}
											</td>
										</tr>

										{buyer.position == 1 && isSeller && (
											<>
												<tr>
													<td className="px-4 xxs:px-4 py-2 whitespace-nowrap dark:text-white">
														{getEndTurnButton(buyer.userId)}
													</td>
												</tr>
												<tr className="border-b">
													<td>
														<p className="w-64 flex justify-center font-bold dark:text-white">
															Next
														</p>
													</td>
												</tr>
											</>
										)}

										{buyer.position == 1 && !isSeller && (
											<>
												<tr>
													<td className="px-4 xxs:px-4 py-2 whitespace-nowrap dark:text-white" />
												</tr>
												<tr className="border-b">
													<td>
														<p className="w-64 flex justify-center font-bold dark:text-white">
															Next
														</p>
													</td>
												</tr>
											</>
										)}
									</>
								))}
							</ReactSortable>
						</table>
						{!isSeller && !joinedQueue && !hideJoinButton && (
							<div className="flex-1 hidden md:block mt-3 dark:bg-darkGray-50">
								<button
									type="button"
									className="w-full h-12 px-6 text-red-100 transition-colors duration-150 bg-red-700 rounded-sm focus:shadow-outline hover:bg-red-800"
									onClick={joinQueue}
								>
									Get In Line
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default RipNShipEventQueue;
