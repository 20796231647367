/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
	useState, useEffect, useRef, useContext, useMemo
} from 'react';
import { isMobile as isMobileSize, isTablet } from 'react-device-detect';
import clsx from 'clsx';
import AttendeesEventQueue from './attendees-event-queue';
import ProductsEventQueue from './products-event-queue';
import LineHistoryEventQueue from './line-history-event-queue';
import RipNShipEventQueue from './ripnship-event-queue';
import BreaksEventQueue from './breaks-event-queue';
import RandomizedBreaksEventQueue from './randomized-breaks-event-queue';
import SellerInformationEventQueue from './seller-information-event-queue';
import {
	RIP_N_SHIP,
	SELECT_PRODUCTS_PRODUCT_SELECTION_OPTION
} from '../../utils/constants';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import {
	ACTIVE_LINE_VIEW,
	ATTENDEES_VIEW,
	AVAILABLE_PRODUCTS_VIEW,
	LINE_HISTORY_VIEW,
	LINKED_EVENTS_VIEW,
	PICK_YOUR_SLOTS_VIEW,
	RANDOMIZED_BREAKS_VIEW,
	SELLER_INFORMATION_VIEW
} from '../../utils/views';
import EventQueueFooterButtons from '../../containers/event/room/event-queue-footer-buttons';
import BanConfirmation from '../ban-confirmation';
import { SellerEventAttendeeActionsProvider } from '../../contexts/seller-event-attendee-actions';
import SellerAttendeeActionsModal from '../../containers/event/room/seller-attendee-actions-modal';
import LinkedEventsView from '../../containers/event/room/linked-events-view';
import { LiveEventViewsContainerContext } from '../../contexts/live-event-views-container';
import SellerAttendeesFilter from '../../containers/event/room/seller-attendees-filter';

const EventQueue = ({
	setShowProductModal,
	setSearchInputActive,
	eventId,
	setSelectedProduct,
	setShowPurchasedItemsModal,
	onEndTurn,
	changeWsMessage,
	initiatedEndTurnsWsMessage,
	setInitiatedEndTurnsWsMessage,
	endTurnWsMessage,
	exitWsMessage,
	newBuyersWsMessage,
	setPurchasedProductsUserId,
	updatedBuyersQueueWsMessage,
	joinQueue,
	reinitiateEventInformation,
	setShowQueueModal,
	openPurchasedItemsModalByQueueOrderId,
	showSeller,
	disableRipNShipFunctionality = false,
	canResizeViews,
	fetchAttendeesAndUpdateAudioParticipants
}) => {
	const {
		views,
		activeView,
		setActiveView,
		isRipNShip,
		ripNShipEvent,
		ripNShipEventIdRef,
		setEventDetails,
		webSocketGroupName,
		isSeller,
		productSearchValue,
		setProductSearchValue
	} = useContext(LiveEventDataContext);
	const [historyBuyers, setHistoryBuyers] = useState([]);
	const [barrierLine, setBarrierLine] = useState(null);
	const productSearchInputRef = useRef();
	const [
		userCanAddOrRemoveEventProducts,
		setUserCanAddOrRemoveEventProducts
	] = useState(false);
	const [userCanAddProducts, setUserCanAddProducts] = useState(false);
	const [productSelectionId, setProductSelectionId] = useState(null);
	const { popups, dismissedPopups } = useContext(
		LiveEventViewsContainerContext
	);

	// usually this will have a ripnship event
	useEffect(() => {
		if (
			!ripNShipEvent
			|| !ripNShipEvent.event
			|| ripNShipEvent.event.eventTypeId != RIP_N_SHIP
		) {
			return;
		}

		setHistoryBuyers(ripNShipEvent.buyersHistoryQueue);
		setBarrierLine(ripNShipEvent.queuePaymentLineBarrier);

		if (
			isSeller
			&& ripNShipEvent.event.ripNShip
			&& ripNShipEvent.event.ripNShip.productSelectionId
		) {
			setProductSelectionId(ripNShipEvent.event.ripNShip.productSelectionId);
			if (
				ripNShipEvent.event.ripNShip.productSelectionId
				== SELECT_PRODUCTS_PRODUCT_SELECTION_OPTION
			) {
				setUserCanAddOrRemoveEventProducts(true);
			}

			setUserCanAddProducts(true);
		}
	}, [JSON.stringify(ripNShipEvent)]);

	const setAllBuyers = (newBuyers) => {
		setEventDetails((tempEventDetails) => {
			const newEventDetails = tempEventDetails.map((e) => {
				if (e.event.eventTypeId == RIP_N_SHIP) {
					e.buyersQueue = newBuyers;
				}
				return e;
			});
			return newEventDetails;
		});
	};

	const viewsHeightClass = useMemo(() => {
		if (isMobileSize) {
			if (activeView == AVAILABLE_PRODUCTS_VIEW) {
				return 'h-80';
			}
			return 'h-82';
		}

		if (activeView == AVAILABLE_PRODUCTS_VIEW) {
			return 'h-per-64.4 h-lg:h-per-67.4 h-xl:h-per-73.5';
		}

		if (isSeller && activeView == ATTENDEES_VIEW) {
			return 'h-per-63.8 h-lg:h-per-66.8 h-xl:h-per-73';
		}

		return 'h-per-75 h-lg:h-per-78 h-xl:h-per-82';
	}, [isMobileSize, activeView]);

	return (
		<div className="md:h-full">
			<div
				className={clsx(
					canResizeViews || isMobileSize ? 'w-full' : 'w-80',
					'dark:bg-darkGray-50 md:h-full'
				)}
			>
				<div className="flex row dark:border-black">
					<label
						htmlFor="product_status"
						className="block text-sm font-medium text-gray-700 pl-2 dark:text-white "
					>
						Change View:
					</label>
					<select
						id="product_status"
						name="product_status"
						autoComplete="event_type"
						className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border dark:border-black bg-white dark:bg-darkGray-50 border-gray-200 dark:text-white rounded-md shadow-md mb-1"
						onBlur={(e) => setActiveView(e.target.value)}
						onChange={(e) => setActiveView(e.target.value)}
						value={activeView}
					>
						{views.map((object, i) => (
							<option key={object} value={object}>
								{object}
							</option>
						))}
					</select>
				</div>
				{activeView === AVAILABLE_PRODUCTS_VIEW && (
					<div className="relative text-gray-600 dark:bg-darkGray-50 dark:border-black w-full md:w-auto mt-3 mb-3 flex justify-center">
						<input
							type="search"
							name="search"
							placeholder="Search"
							ref={productSearchInputRef}
							onBlur={() => setSearchInputActive(false)}
							onFocus={() => {
								setSearchInputActive(true);
							}}
							value={productSearchValue}
							className={clsx(
								'h-10 px-5 pl-10 text-sm focus:outline-none border bg-white dark:bg-darkGray-50 dark:border-black text-black dark:text-white rounded-md w-full ios-input-background',
								!isTablet && (canResizeViews ? 'md:w-full' : 'md:w-auto')
							)}
							onChange={({ target }) => setProductSearchValue(target.value)}
						/>
						<button
							type="submit"
							className="absolute left-0 top-0 mt-3 ml-4 dark:text-white"
							onClick={() => {
								if (
									productSearchInputRef
									&& productSearchInputRef.current
									&& productSearchInputRef.current.focus
								) {
									productSearchInputRef.current.focus();
								}
							}}
						>
							<svg
								className="h-4 w-4 fill-current"
								xmlns="http://www.w3.org/2000/svg"
								version="1.1"
								id="Capa_1"
								x="0px"
								y="0px"
								viewBox="0 0 56.966 56.966"
								width="512px"
								height="512px"
							>
								<path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
							</svg>
						</button>
					</div>
				)}
				{isSeller && activeView === ATTENDEES_VIEW && <SellerAttendeesFilter />}
				{/* TODO: 78% height makes thing look better */}
				<div
					className={clsx(
						'shadow-md rounded-md border dark:border-black',
						viewsHeightClass,
						(activeView != AVAILABLE_PRODUCTS_VIEW
							|| !(
								popups
								&& popups.show_addtocart_information
								&& dismissedPopups
								&& dismissedPopups.show_addtocart_information
							)
							|| !isRipNShip)
							&& 'overflow-y-auto'
					)}
				>
					{activeView === AVAILABLE_PRODUCTS_VIEW && (
						<ProductsEventQueue
							eventId={ripNShipEventIdRef.current}
							setShowProductModal={setShowProductModal}
							setSelectedProduct={setSelectedProduct}
							disableRowClickedFunctionality={disableRipNShipFunctionality}
							showAddToCartInformationPopup={
								popups
								&& popups.show_addtocart_information
								&& dismissedPopups
								&& dismissedPopups.show_addtocart_information
							}
							userCanAddOrRemoveEventProducts={userCanAddOrRemoveEventProducts}
							userCanAddProducts={userCanAddProducts}
							productSelectionId={productSelectionId}
						/>
					)}
					{activeView === LINE_HISTORY_VIEW && (
						<LineHistoryEventQueue
							historyBuyers={historyBuyers}
							openPurchasedItemsModalByQueueOrderId={
								openPurchasedItemsModalByQueueOrderId
							}
						/>
					)}
					{activeView === PICK_YOUR_SLOTS_VIEW && <BreaksEventQueue />}

					{activeView === RANDOMIZED_BREAKS_VIEW && (
						<RandomizedBreaksEventQueue />
					)}
					{activeView === SELLER_INFORMATION_VIEW && (
						<SellerInformationEventQueue showSeller={showSeller} />
					)}
					<SellerEventAttendeeActionsProvider
						eventId={eventId}
						websocketGroupName={webSocketGroupName}
					>
						{isSeller ? (
							<>
								<BanConfirmation />
								<SellerAttendeeActionsModal
									fetchAttendeesAndUpdateAudioParticipants={
										fetchAttendeesAndUpdateAudioParticipants
									}
								/>
							</>
						) : null}
						{activeView === ATTENDEES_VIEW && <AttendeesEventQueue />}
						{activeView === LINKED_EVENTS_VIEW && <LinkedEventsView />}
						{isRipNShip && (
							<RipNShipEventQueue
								eventId={ripNShipEventIdRef.current}
								initialBuyers={ripNShipEvent.buyersQueue}
								historyBuyers={ripNShipEvent.buyersHistoryQueue}
								changeWsMessage={changeWsMessage}
								initiatedEndTurnsWsMessage={initiatedEndTurnsWsMessage}
								setInitiatedEndTurnsWsMessage={setInitiatedEndTurnsWsMessage}
								endTurnWsMessage={endTurnWsMessage}
								exitWsMessage={exitWsMessage}
								reinitiateEventInformation={reinitiateEventInformation}
								newBuyersWsMessage={newBuyersWsMessage}
								barrierLine={barrierLine}
								visible={activeView === ACTIVE_LINE_VIEW}
								setAllBuyers={setAllBuyers}
								setShowPurchasedItemsModal={setShowPurchasedItemsModal}
								setPurchasedProductsUserId={setPurchasedProductsUserId}
								updatedBuyersQueueWsMessage={updatedBuyersQueueWsMessage}
								joinQueue={joinQueue}
								onEndTurn={onEndTurn}
								hideJoinButton={disableRipNShipFunctionality}
							/>
						)}
					</SellerEventAttendeeActionsProvider>
				</div>
				{isRipNShip && (
					<div className="flex justify-center items-center m-1">
						{disableRipNShipFunctionality ? (
							<p className="text-red-700 font-semibold">Line is closed</p>
						) : (
							<p className="text-blue-500 font-semibold">Line is open</p>
						)}
					</div>
				)}
				{isSeller || isRipNShip ? (
					<EventQueueFooterButtons onClick={() => setShowQueueModal(true)} />
				) : null}
			</div>
		</div>
	);
};

export default EventQueue;
