/* eslint-disable no-mixed-spaces-and-tabs */
import {
	useCallback, useState, useEffect, useRef, useMemo
} from 'react';
import { getBreakTeamsRandomization } from '../api/break.request';
import {
	getEventStepTeamDistribution,
	getLiveEventParticipantsInfo,
	getRipNShipActiveProducts
} from '../api/event.request';
import {
	getDestructuredTwilioIdentity,
	groupBy
} from '../utils/commonFunction';
import {
	DEFAULT_RIPNSHIP_PRODUCTS_PER_PAGE,
	EVENT_STOPPED_STATUS,
	PICK_YOUR_SLOTS,
	RANDOMIZED,
	RIP_N_SHIP
} from '../utils/constants';
import useDebounce from './useDebounce';
import useStateRef from './useStateRef';
import {
	ACTIVE_LINE_VIEW,
	ATTENDEES_VIEW,
	PICK_YOUR_SLOTS_VIEW,
	RANDOMIZED_BREAKS_VIEW,
	SELLER_INFORMATION_VIEW,
	LINE_HISTORY_VIEW,
	AVAILABLE_PRODUCTS_VIEW,
	LINKED_EVENTS_VIEW
} from '../utils/views';

// TODO: Check if we can improve the rerendering of this method
const useLiveEventData = (
	eventId,
	initialLinkedEvents,
	initialEventDetails,
	isSeller,
	authenticated
) => {
	// eslint-disable-next-line no-unused-vars
	const [initiallyAuthenticated, setInitiallyAuthenticated] = useState(
		authenticated
	);
	const [isHost, setIsHost, isHostRef] = useStateRef(isSeller);
	const [
		hostingTheStream,
		setHostingTheStream,
		hostingTheStreamRef
	] = useStateRef(false);
	const [
		attendeesFilterSelectedOption,
		setAttendeesFilterSelectedOption
	] = useState('');
	const [
		twilioParticipants,
		setTwilioParticipants,
		twilioParticipantsRef
	] = useStateRef([]);
	const [
		presenterIdentity,
		setPresenterIdentity,
		presenterIdentityRef
	] = useStateRef('');
	const [attendeesSearchValue, setAttendeesSearchValue] = useState('');
	const [productsInitiallyLoaded, setProductsInitiallyLoaded] = useState(false);
	const [productSearchValue, setProductSearchValue] = useState('');
	const [productsTotalRows, setProductsTotalRows] = useState(0);
	const debounceProductSearchValue = useDebounce(productSearchValue, 500);
	const [productsCurrentPage, setProductsCurrentPage] = useState(0);
	const productsPerPageRef = useRef(DEFAULT_RIPNSHIP_PRODUCTS_PER_PAGE);
	const [productsLoading, setProductsLoading] = useState(false);
	const [eventName, setEventName] = useState('');
	const [ownerNickname, setOwnerNickname] = useState('');
	const ripNShipEventIdRef = useRef();
	const [products, setProducts] = useState([]);
	const [productsSortBy, setProductsSortBy] = useState({
		direction: '',
		column: ''
	});
	const [mainEventDetails, setMainEventDetails] = useState(null);
	const [eventDetails, setEventDetails] = useState(initialEventDetails);
	const [activeView, setActiveView] = useState(ATTENDEES_VIEW);
	const [isRipNShip, setIsRipNShip] = useState(false);
	const [isRandomizedBreak, setIsRandomizedBreak] = useState(false);
	const [isPickYourSlotsBreak, setIsPickYourSlotsBreak] = useState(false);
	const [views, setViews] = useState([]);
	const [attendees, setAttendees] = useState([]);
	const [teamsRandomization, setTeamsRandomization] = useState([]);
	const [ripNShipEvent, setRipNShipEvent] = useState(null);
	const [
		eventEndedDueToTechnicalIssues,
		setEventEndedDueToTechnicalIssues
	] = useState(false);
	const [
		preselectedBreakEventIdOnOpenedView,
		setPreselectedBreakEventIdOnOpenedView
	] = useState(null);
	const [eventStartDate, setEventStartDate] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [linkedEvents, setLinkedEvents] = useState(
		initialLinkedEvents != null
			? initialLinkedEvents.filter(
				(linkedEvent) => linkedEvent.event.statusId != EVENT_STOPPED_STATUS
			  )
			: []
	);
	const [storeInfo, setStoreInfo] = useState(null);
	const [sellerImage, setSellerImage] = useState(null);
	const [eventExpirationInMinutes, setEventExpirationInMinutes] = useState(
		null
	);

	const generateAttendeesByParticipants = useCallback(async () => {
		const participants = twilioParticipantsRef.current
			.map((participant) => {
				const twilioIdentity = getDestructuredTwilioIdentity(
					participant.identity
				);
				if (!twilioIdentity) return null;
				if (twilioIdentity.isOwner || twilioIdentity.isAnonymous) return null;
				return {
					userId: twilioIdentity.userId,
					nickname: twilioIdentity.nickname,
					twilioParticipantId: participant.sid
				};
			})
			.filter((participant) => participant != null);

		try {
			const res = await getLiveEventParticipantsInfo(eventId, participants);
			const anonymousParticipants = twilioParticipantsRef.current.filter(
				(twilioParticipant) => {
					const foundParticipant = participants.find(
						(p) => p.twilioParticipantId === twilioParticipant.sid
					);
					if (foundParticipant == null) {
						const twilioIdentity = getDestructuredTwilioIdentity(
							twilioParticipant.identity
						);
						return twilioIdentity != null && twilioIdentity.isAnonymous;
					}

					return false;
				}
			);

			let attendeesToUpdate = res.data.sort((a) => (a.isOwner ? 1 : -1));

			const ownerAttendee = attendeesToUpdate.find((a) => a.isOwner);
			if (ownerAttendee && ownerAttendee.userId) {
				attendeesToUpdate = attendeesToUpdate
					.map((attendee) => {
						if (attendee.userId == ownerAttendee.userId && !attendee.isOwner) return null;
						if (attendee.isOwner) {
							attendee.identity = presenterIdentityRef.current;
						}
						return attendee;
					})
					.filter((attendee) => attendee != null);
			}

			if (anonymousParticipants && anonymousParticipants.length > 0) {
				attendeesToUpdate.push({
					name: `Anonymous Guest (${anonymousParticipants.length})`,
					nickname: `Anonymous Guest (${anonymousParticipants.length})`,
					anonymousUsers: anonymousParticipants.length
				});
			}

			setAttendees(attendeesToUpdate);
			return attendeesToUpdate;
		} catch (err) {
			console.error(err);
			return [];
		}
	}, [eventId, twilioParticipantsRef.current]);

	useEffect(() => {
		generateAttendeesByParticipants();
	}, [twilioParticipants]);

	const getPreRandomizedTiers = useCallback(async (breakTemplateId) => {
		try {
			const preRandomizedTiersRes = await getEventStepTeamDistribution(
				breakTemplateId
			);
			if (preRandomizedTiersRes.data && preRandomizedTiersRes.data.length) {
				return groupBy(preRandomizedTiersRes.data, 'tier', false);
			}
		} catch (err) {
			console.error(err);
		}
	}, []);

	const attendeesCount = useMemo(() => {
		let count = 0;
		if (!attendees || !attendees.length) return count;
		const uniqueUserIds = new Set(
			attendees.filter((at) => at.userId != null).map((at) => at.userId)
		).size;
		count += uniqueUserIds;
		const anonymousGuestAttendee = attendees.find((at) => at.userId == null);
		if (anonymousGuestAttendee) {
			count += anonymousGuestAttendee.anonymousUsers;
		}
		return count;
	}, [JSON.stringify(attendees)]);

	const getBreakTeamsRandomizationWithDisplayOrder = useCallback(
		(breakTeamsRandomization, preTierRandomization) => {
			let teamsRandomizationWithDisplayOrder = [...breakTeamsRandomization].map(
				(teamRandomization) => {
					let displayOrder = 0;

					teamRandomization.tiers.forEach((tierTeam) => {
						if (tierTeam.tier == 1) {
							const foundPreRandomizedTierIndex = preTierRandomization.findIndex(
								(tier) => tier.teamId == tierTeam.teamId && tier.team == tierTeam.team
							);
							if (!Number.isNaN(foundPreRandomizedTierIndex)) {
								displayOrder = foundPreRandomizedTierIndex + 1;
							}
						}
					});

					return {
						...teamRandomization,
						displayOrder
					};
				}
			);
			if (teamsRandomizationWithDisplayOrder) {
				teamsRandomizationWithDisplayOrder = teamsRandomizationWithDisplayOrder.sort(
					(a, b) => a.displayOrder - b.displayOrder
				);
			}

			return teamsRandomizationWithDisplayOrder;
		},
		[]
	);

	const loadTeamsRandomization = useCallback(async () => {
		const randomizedBreaks = eventDetails.filter(
			(eventDetail) => eventDetail.event.eventTypeId == RANDOMIZED
		);
		if (!randomizedBreaks || !randomizedBreaks.length) return;
		const randomizedBreaksResults = [];
		getBreakTeamsRandomization(
			randomizedBreaks.map((eventDetail) => eventDetail.event.eventId)
		)
			.then(async (randomizationsRes) => {
				randomizedBreaks.forEach(async ({ event }) => {
					const preRandomizedTiers = await getPreRandomizedTiers(
						event.initialBreakTemplateId
					);
					if (!preRandomizedTiers || Object.keys(preRandomizedTiers) <= 0) return;
					const firstTierPreRandomizedBreaks =						preRandomizedTiers[Object.keys(preRandomizedTiers)[0]];
					if (
						!firstTierPreRandomizedBreaks
						|| !firstTierPreRandomizedBreaks.length
					) return;
					const randomizationRes = randomizationsRes.data.find(
						(res) => res.eventId == event.eventId
					);

					const randomizedBreakResult = {
						eventId: event.eventId,
						teamsRandomization: getBreakTeamsRandomizationWithDisplayOrder(
							randomizationRes.breakTeamRandomization,
							firstTierPreRandomizedBreaks
						),
						eventName: event.eventName,
						eventNumber: event.eventNumber,
						isFull: false
					};
					if (
						randomizedBreakResult
						&& randomizedBreakResult.teamsRandomization
						&& randomizedBreakResult.teamsRandomization.length
					) {
						randomizedBreakResult.isFull = true;
						randomizedBreakResult.eventName = `(FULL) - ${randomizedBreakResult.eventName}`;
					}
					randomizedBreaksResults.push(randomizedBreakResult);
				});

				if (randomizedBreaksResults && randomizedBreaksResults.length) {
					const sortedRandomizedBreaksResults = randomizedBreaksResults.sort(
						(a, b) => a.isFull - b.isFull
					);
					setTeamsRandomization(sortedRandomizedBreaksResults);
				} else {
					setTeamsRandomization(randomizedBreaksResults);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [JSON.stringify(eventDetails)]);

	useEffect(() => {
		if (!eventDetails || !eventDetails.length || !eventId) return;
		const eventTypeIds = [...eventDetails].map(
			(eventDetail) => eventDetail.event.eventTypeId
		);

		if (eventDetails.length > 1) {
			const foundMainEvent = eventDetails.find(
				(e) => e.event.hasLinkedEvents === true
			);
			if (foundMainEvent) setMainEventDetails(eventDetails[0]);
		} else {
			setMainEventDetails(eventDetails[0]);
		}

		if ((!storeInfo || !sellerImage) && eventDetails[0].seller) {
			setStoreInfo(eventDetails[0].seller.account);
			setSellerImage(eventDetails[0].seller.sellerImage);
		}

		const foundRipNShipEvent = eventDetails.find(
			(e) => e.event.eventTypeId == RIP_N_SHIP
		);
		if (foundRipNShipEvent) {
			setRipNShipEvent(foundRipNShipEvent);
			ripNShipEventIdRef.current = foundRipNShipEvent.event.eventId;
		}

		const tempViews = [];

		tempViews.push(ATTENDEES_VIEW);
		tempViews.push(SELLER_INFORMATION_VIEW);
		tempViews.push(LINKED_EVENTS_VIEW);

		if (eventTypeIds.find((eventTypeId) => eventTypeId == RANDOMIZED) != null) {
			tempViews.push(RANDOMIZED_BREAKS_VIEW);
			setIsRandomizedBreak(true);
			loadTeamsRandomization();
		}
		if (
			eventTypeIds.find((eventTypeId) => eventTypeId == PICK_YOUR_SLOTS) != null
		) {
			tempViews.push(PICK_YOUR_SLOTS_VIEW);
			setIsPickYourSlotsBreak(true);
		}
		if (eventTypeIds.find((eventTypeId) => eventTypeId == RIP_N_SHIP) != null) {
			tempViews.push(ACTIVE_LINE_VIEW);
			tempViews.push(LINE_HISTORY_VIEW);
			tempViews.push(AVAILABLE_PRODUCTS_VIEW);
			setIsRipNShip(true);
		}

		setViews(tempViews);
	}, [eventId, eventDetails]);

	useEffect(() => {
		if (!isRipNShip) return;

		if (isHost) {
			setActiveView(ACTIVE_LINE_VIEW);
		} else {
			setActiveView(AVAILABLE_PRODUCTS_VIEW);
		}
	}, [isRipNShip]);

	const loadRipNShipProducts = useCallback(
		(
			newPage = 0,
			overrideProductJsonCheck = false,
			dontAddToTotalRows = false,
			loadFromCurrentPage = false,
		) => {
			if (!ripNShipEventIdRef.current) return;
			setProductsLoading(true);
			if (loadFromCurrentPage) {
				newPage = productsCurrentPage;
			} else {
				newPage = newPage < 0 ? 0 : newPage;
			}

			getRipNShipActiveProducts(
				ripNShipEventIdRef.current,
				newPage,
				productsPerPageRef.current,
				debounceProductSearchValue,
				productsSortBy.column,
				productsSortBy.direction
			)
				.then((productsRes) => {
					if (
						overrideProductJsonCheck
						|| JSON.stringify(productsRes.data) != JSON.stringify(products)
					) {
						setProducts(productsRes.data);
					}
					setProductsCurrentPage(newPage);
					setProductsLoading(false);

					if (productsRes.data.length == 0 && newPage > 0) {
						loadRipNShipProducts(newPage < 0 ? 0 : newPage - 1, true, true);
					} else if (productsRes.data.length == productsPerPageRef.current) {
						setProductsTotalRows(
							productsRes.data.length * (newPage + 1)
								+ (dontAddToTotalRows ? 0 : productsPerPageRef.current)
						);
					} else if (newPage > 0) {
						setProductsTotalRows(
							productsPerPageRef.current * (newPage == 0 ? 1 : newPage + 1)
								+ (dontAddToTotalRows
								|| productsRes.data.length < productsPerPageRef.current
									? 0
									: productsRes.data.length)
						);
					} else {
						setProductsTotalRows(
							productsRes.data.length * (newPage == 0 ? 1 : newPage + 1)
								+ (dontAddToTotalRows
								|| newPage == 0
								|| productsRes.data.length < productsPerPageRef.current
									? 0
									: productsPerPageRef.current)
						);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		},
		[
			ripNShipEventIdRef,
			JSON.stringify(products),
			productsCurrentPage,
			productsPerPageRef.current,
			debounceProductSearchValue,
			productsSortBy
		]
	);

	useEffect(() => {
		if (!productsSortBy || !productsSortBy.column.length) return;
		loadRipNShipProducts(0);
	}, [productsSortBy]);

	useEffect(() => {
		if (!productsInitiallyLoaded) return;
		loadRipNShipProducts(0);
	}, [debounceProductSearchValue, productsInitiallyLoaded]);

	return {
		views,
		activeView,
		setActiveView,
		isRandomizedBreak,
		isRipNShip,
		isPickYourSlotsBreak,
		teamsRandomization,
		loadTeamsRandomization,
		setAttendees,
		attendees,
		attendeesCount,
		linkedEvents,
		setLinkedEvents,
		eventDetails,
		setEventDetails,
		hasRipNShipEvent: ripNShipEvent != null,
		ripNShipEvent,
		setRipNShipEvent,
		mainEventDetails,
		storeInfo,
		sellerImage,
		ripNShipEventIdRef,
		preselectedBreakEventIdOnOpenedView,
		setPreselectedBreakEventIdOnOpenedView,
		ownerNickname,
		setOwnerNickname,
		eventName,
		setEventName,
		products,
		setProducts,
		loadRipNShipProducts,
		productsLoading,
		eventStartDate,
		setEventStartDate,
		productsCurrentPage,
		setProductsCurrentPage,
		productSearchValue,
		setProductSearchValue,
		productsInitiallyLoaded,
		setProductsInitiallyLoaded,
		productsTotalRows,
		productsPerPageRef,
		attendeesSearchValue,
		setAttendeesSearchValue,
		attendeesFilterSelectedOption,
		setAttendeesFilterSelectedOption,
		generateAttendeesByParticipants,
		twilioParticipants,
		setTwilioParticipants,
		presenterIdentity,
		setPresenterIdentity,
		presenterIdentityRef,
		twilioParticipantsRef,
		isHost,
		setIsHost,
		isHostRef,
		eventExpirationInMinutes,
		setEventExpirationInMinutes,
		eventEndedDueToTechnicalIssues,
		setEventEndedDueToTechnicalIssues,
		hostingTheStream,
		setHostingTheStream,
		hostingTheStreamRef,
		initiallyAuthenticated,
		productsSortBy,
		setProductsSortBy
	};
};

export default useLiveEventData;
