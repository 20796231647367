import React from 'react';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import clsx from 'clsx';
import Modal from '../modals';
import Button from '../button';

const clipLoaderCss = css`
	display: block;
	border-color: white;
`;

const ConfirmationModal = ({
	showModal,
	setShowModal,
	onConfirm,
	text = 'Please confirm cancelling the event by clicking Confirm otherwise click Close',
	confirmText = 'Confirm',
	cancelText = 'Close',
	onCancel,
	textHtml = null,
	textCss = 'font-semibold dark:text-white text-gray-700 mb-5 px-8 text-lg w-96',
	hideHeader = false,
	confirmButtonColorClassNames = 'bg-red-700 hover:bg-red-900',
	showConfirmationIcon = true,
	confirmButtonVariant = 'success',
	cancelButtonVariant = 'primary',
	confirmButtonDisabled = false,
	confirmButtonWidth = '',
	cancelButtonWidth = '',
	confirmButtonLoading = false
}) => (
	<Modal
		showModal={showModal}
		hideHeader={hideHeader}
		onClose={() => setShowModal(false)}
		hideCloseButton
	>
		<div className="flex flex-col px-6 bg-white dark:bg-darkGray-50">
			<div className="flex flex-row items-center">
				{showConfirmationIcon && (
					<svg
						className="w-20 h-20"
						fill="red"
						stroke="white"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
				)}
				{!textHtml ? (
					<p className={textCss}>{text}</p>
				) : (
					<div
						dangerouslySetInnerHTML={{
							__html: textHtml
						}}
					/>
				)}
			</div>
			<div className="flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5">
				<div className="mr-3">
					<Button
						variant={confirmButtonVariant}
						width={confirmButtonWidth || '24'}
						mobileWidth={confirmButtonWidth || '24'}
						height="12"
						mobileHeight="12"
						additionalClassName={clsx(
							confirmButtonColorClassNames,
							confirmButtonLoading && 'flex justify-evenly items-center'
						)}
						onClick={onConfirm}
						disabled={confirmButtonDisabled || confirmButtonLoading}
					>
						<>
							{confirmButtonLoading && (
								<ClipLoader size={20} css={clipLoaderCss} />
							)}
							<span>{confirmText}</span>
						</>
					</Button>
				</div>
				<div className="mr-3">
					<Button
						variant={cancelButtonVariant}
						width={cancelButtonWidth || '24'}
						mobileWidth={cancelButtonWidth || '24'}
						height="12"
						mobileHeight="12"
						onClick={() => {
							setShowModal(false);
							if (onCancel) {
								onCancel();
							}
						}}
					>
						{cancelText}
					</Button>
				</div>
			</div>
		</div>
	</Modal>
);

export default ConfirmationModal;
