/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { LiveEventDataContext } from '../../contexts/live-event-data';

const SellerInformationEventQueue = ({ showSeller }) => {
	const { mainEventDetails } = useContext(LiveEventDataContext);

	return (
		<div className="mt-4">
			<div className="flex flex-col w-full">
				<div className="mt-1 relative w-full px-3 py-2 text-gray-700 dark:text-white dark:bg-darkGray-50 focus:outline-none h-64 gap-4">
					<h3
						onKeyDown={showSeller}
						onClick={showSeller}
						className="cursor-pointer"
					>
						<u>
							<b>{mainEventDetails.seller.storeName}</b>
						</u>
					</h3>
					<div className="flex items-center">
						<div>
							<p>
								{mainEventDetails.seller.city}
,
								{mainEventDetails.seller.state}
							</p>
						</div>
					</div>
					<br />
					<div className="flex flex-col">
						<p>
							Frequency of Shipping:&nbsp;
							<b>{mainEventDetails.seller.shippingFrequency}</b>
						</p>
						<br />
						<p>
							Ship Cards:&nbsp;
							<b>{mainEventDetails.seller.shipCards}</b>
						</p>
						<p>
							Shipping Type:&nbsp;
							<b>{mainEventDetails.seller.shippingType}</b>
						</p>
						<br />
						<p>
							Shipping Cost:&nbsp;
							<b>
$
								{mainEventDetails.seller.shippingCost}
							</b>
						</p>
						<br />
						<p>
							Event Name:&nbsp;
							<b>{mainEventDetails.event.eventName}</b>
						</p>
						<p>
							Event Number:&nbsp;
							<b>{mainEventDetails.event.eventNumber}</b>
						</p>
						<br />
						<p>
							Event Details:&nbsp;
							<b>{mainEventDetails.event.eventDetails}</b>
						</p>
					</div>
					<br />
				</div>
			</div>
		</div>
	);
};

export default SellerInformationEventQueue;
