import React from 'react';
import { ToastContainer } from 'react-toastify';
import Header from '../components/header';
import Layout from '../components/layouts/layout-default';
import Authentication from '../containers/auth/authentication';

const SignUpPage = ({ location }) => (
	<Layout>
		<div className="flex flex-col flex-1">
			<Header auth />
			<div className="flex flex-col md:flex-1 flex-none md:py-0 py-2 justify-center content-center">
				<ToastContainer />
				<Authentication isLogin={false} location={location} />
			</div>
		</div>
	</Layout>
);
export default SignUpPage;
