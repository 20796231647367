import { PICK_YOUR_SLOTS, SHOPPING_CART_CART_MODIFIED_TOAST_MESSAGE } from './constants';
import { informationToast } from './information-toast';

export const getSlotName = (orderDetails) => {
	let slotName;
	if (orderDetails.event.eventTypeId == PICK_YOUR_SLOTS) {
		// eslint-disable-next-line prefer-destructuring
		slotName = orderDetails.event.breaks.filter(
			(brk) => brk.slot == orderDetails.slotPurchased
		)[0].slotName;
	} else {
		// eslint-disable-next-line prefer-destructuring
		const slot = orderDetails.event.breakTeams.filter(
			(brk) => brk.slot == orderDetails.slotPurchased
		)[0];
		if (slot.teamName) {
			slotName = slot.teamName;
		} else if (slot.team && slot.team.displayName) {
			slotName = slot.team.displayName;
		}
	}

	if (!slotName) {
		slotName = `Slot - ${orderDetails.slotPurchased}`;
	}
	return slotName;
};

export const getDetailedSlotName = (orderDetails) => {
	let slotName = '';
	if (orderDetails.event.eventTypeId == PICK_YOUR_SLOTS) {
		slotName = orderDetails.event.breaks.filter(
			(brk) => brk.slot == orderDetails.slotPurchased
		).sort((a, b) => a.tier - b.tier).map((brk) => brk.slotName).join(', ');
	} else {
		slotName = orderDetails.event.breakTeams.filter(
			(brk) => brk.slot == orderDetails.slotPurchased
		).sort((a, b) => a.tier - b.tier).map((slot) => {
			if (slot.teamName) {
				return `${slot.teamName} (Tier ${slot.tier})`;
			}
			if (slot.team && slot.team.displayName) {
				return `${slot.team.displayName} (Tier ${slot.tier})`;
			}

			return '';
		}).join(', ');
	}

	if (!slotName) {
		slotName = `Slot - ${orderDetails.slotPurchased}`;
	}
	return slotName;
};

export const notifyUserForModifiedShoppingCartAfterPaymentProcess = (cartItems, checkoutSessionId = null) => {
	const itemInPaymentProcess = cartItems.find((cartItem) => cartItem.paymentProcess && cartItem.paymentProcess === true);
	if (itemInPaymentProcess || (checkoutSessionId != null)) {
		informationToast(SHOPPING_CART_CART_MODIFIED_TOAST_MESSAGE);
	}
};
