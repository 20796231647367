/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, {
 useEffect, useRef, useState, useCallback
} from 'react';
import moment from 'moment';
import { elementScrollIntoView, polyfill } from 'seamless-scroll-polyfill';
import { isMobile } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import ConversationMessage from './conversation-message';
import { groupBy } from '../../../utils/commonFunction';
import Divider from '../../divider';
import { isFacebookApp, isInstagramApp } from '../../../utils/browser';

const ConversationMessages = ({
	messages,
	isSeller,
	showUserNameTooltip,
	safariScrollToBottomClicked,
	setSafariScrollToBottomClicked,
	setSelectedForReply,
	onAttachmentDownloaded,
	messageToReply,
	setMessageToReply,
	sendingMessage,
	setSendingMessage,
	authenticatedUserId,
	mobileSize,
	hostUserId,
	disableMessaging,
	reduceReplyMessageSize,
	truncateName,
	disableReplying,
	removeDayDivider,
	scrollToBottom,
	isScrolledToBottom,
	authenticated,
	hasReplyMessages,
	onPurchasedItemsHyperlinkClicked,
	onRandomizationHyperlinkClicked,
	setReactions,
	setShowReactions,
	setShowMessageOptions,
	setSelectedForEdit,
	selectedForEdit,
	setSelectedMessage
}) => {
	const [groupedMessages, setGroupedMessages] = useState([]);
	const bottomListRef = useRef();
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	const elementRef = useRef();
	const debouncedTimerTooltipRef = useRef();
	const isFacebookAppBrowser = isFacebookApp();
	const isInstagramAppBrowser = isInstagramApp();
	useEffect(() => {
		if (isSafari) {
			polyfill();

			setTimeout(() => {
				elementScrollIntoView(elementRef.current, {
					behavior: 'smooth',
					block: 'nearest',
					inline: 'start'
				});
			}, 450);
		}
	}, [isSafari]);

	const scroll = () => {
		if (!elementRef || !elementRef.current) return;

		if (isSafari) {
			if (elementRef.current.scrollIntoViewIfNeeded) {
				elementRef.current.scrollIntoViewIfNeeded();
			}
		} else if (isFacebookAppBrowser || isInstagramAppBrowser) {
			elementRef.current.scrollIntoView({
				block: 'end',
				inline: 'start'
			});
		} else if (isMobile) {
			elementRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'start'
			});
		} else {
			elementRef.current.scrollIntoView({
				block: 'end',
				inline: 'start'
			});
		}
	};

	useEffect(() => {
		if (!safariScrollToBottomClicked) return;
		elementRef.current.scrollIntoView({
			block: 'end',
			inline: 'end'
		});
		setSafariScrollToBottomClicked(false);
	}, [safariScrollToBottomClicked]);

	useEffect(() => {
		scroll();
	}, [scrollToBottom]);

	const AlwaysScrollToBottom = () => {
		useEffect(() => {
			setTimeout(() => {
				if (!isScrolledToBottom) return;

				scroll();
			}, 300);
		}, []);
		return <div ref={elementRef} />;
	};

	const isDateDifferentFromToday = useCallback(
		(date) => !moment(date).startOf('day').isSame(moment().startOf('day')),
		[]
	);

	useEffect(() => {
		if (messages && messages.length && !removeDayDivider) {
			const groupedBy = groupBy(messages, 'sent_date_only');
			setGroupedMessages(groupedBy);
		} else {
			setGroupedMessages([]);
		}
	}, [messages]);

	useEffect(() => {
		if (!showUserNameTooltip) return;

		debouncedTimerTooltipRef.current = setTimeout(() => {
			ReactTooltip.rebuild();
		}, 4000);

		return () => clearTimeout(debouncedTimerTooltipRef.current);
	}, [messages, showUserNameTooltip]);

	useEffect(() => {
		if (!bottomListRef) return;
		if (sendingMessage) {
			setSendingMessage(false);
		}
		if (messageToReply) {
			setMessageToReply(null);
		}
	}, [groupedMessages]);

	const repliedMessage = (replyMessageId, parentMessageHasReactions) => {
		const repliedMessages = messages.filter(
			(obj) => obj.reply_message_id && obj.reply_message_id === replyMessageId
		);
		return (
			<div
				className={`mb-1 ${parentMessageHasReactions && 'mt-5'}
				`}
			>
				{repliedMessages.map((message, i) => (
					<div className="flex flex-col items-start ml-3 w-full" key={i}>
						<div
							className={`flex items-start ${
								reduceReplyMessageSize
									? 'md:mx-2 mx-2 mb-1'
									: 'md:mx-5 mx-5 p-5 mb-2'
							}  rounded-lg bg-gray border-2  border-grey  border-b py-2`}
						>
							<ConversationMessage
								key={i}
								message={message}
								onReply
								isSeller={isSeller}
								onAttachmentDownloaded={onAttachmentDownloaded}
								mobileSize={mobileSize}
								disableMessaging={disableMessaging}
								reduceReplyMessageSize={reduceReplyMessageSize}
								truncateName={truncateName}
								disableReplying={disableReplying}
								authenticated={authenticated}
								onPurchasedItemsHyperlinkClicked={
									onPurchasedItemsHyperlinkClicked
								}
								onRandomizationHyperlinkClicked={
									onRandomizationHyperlinkClicked
								}
								setShowMessageOptions={setShowMessageOptions}
								setSelectedMessage={setSelectedMessage}
							/>
						</div>
					</div>
				))}
				{(showUserNameTooltip || truncateName) && <ReactTooltip />}
			</div>
		);
	};

	return (
		<div>
			{removeDayDivider
				? messages.map((message, i) => (
						<div
							key={i}
							className={`${reduceReplyMessageSize ? 'mb-1' : 'mb-4'}`}
						>
							<div
								className={`flex items-start ${
									reduceReplyMessageSize ? 'mb-1' : 'mb-4'
								}bg-white`}
								key={i}
							>
								<ConversationMessage
									key={i}
									isSeller={isSeller}
									isHost={hostUserId == message.user_id}
									message={message}
									onAttachmentDownloaded={onAttachmentDownloaded}
									selectedForReply={(m) => {
										setSelectedForReply(m);
									}}
									onReply={false}
									authenticatedUserId={authenticatedUserId}
									mobileSize={mobileSize}
									disableMessaging={disableMessaging}
									truncateName={truncateName}
									disableReplying={disableReplying}
									reduceReplyMessageSize={reduceReplyMessageSize}
									authenticated={authenticated}
									onPurchasedItemsHyperlinkClicked={
										onPurchasedItemsHyperlinkClicked
									}
									onRandomizationHyperlinkClicked={
										onRandomizationHyperlinkClicked
									}
									removeDayDivider
									setReactions={setReactions}
									setShowReactions={setShowReactions}
									setShowMessageOptions={setShowMessageOptions}
									setSelectedForEdit={setSelectedForEdit}
									selectedForEdit={selectedForEdit}
									setSelectedMessage={setSelectedMessage}
								/>
								{hasReplyMessages
									? messages
									  && messages.length
									  && messages.some((v) => v.reply_message_id == message.id) && (
											<>
												{repliedMessage(
													message.id,
													message.reactions && message.reactions.length
												)}
											</>
									  )
									: null}
							</div>
						</div>
				  ))
				: groupedMessages && (
						<>
							{Object.keys(groupedMessages).map((keyName, index) => {
								if (!groupedMessages[keyName][0]) return null;
								return (
									<div key={index}>
										{!removeDayDivider ? (
											<>
												{isDateDifferentFromToday(
													groupedMessages[keyName][0]
														? groupedMessages[keyName][0].sent_date
														: null
												) === true ? (
													<Divider
														key={index}
														hasBorderColor
														borderColor="black"
													>
														<span className="p-10 text-black">
															{groupedMessages[keyName][0].sent_date_only}
														</span>
													</Divider>
												) : (
													<Divider key={index} bgColor="djawn">
														<span className="p-10 text-white">today</span>
													</Divider>
												)}
											</>
										) : null}

										{groupedMessages[keyName].map((message, i) => (
											<div
												key={i}
												className={`${
													reduceReplyMessageSize ? 'mb-1' : 'mb-4'
												}`}
											>
												{!message.reply_message_id && (
													<div
														className={`flex items-start ${
															reduceReplyMessageSize ? 'mb-1' : 'mb-4'
														}bg-white`}
														key={i}
													>
														<ConversationMessage
															key={i}
															isSeller={isSeller}
															isHost={hostUserId == message.user_id}
															message={message}
															onAttachmentDownloaded={onAttachmentDownloaded}
															selectedForReply={(m) => {
																setSelectedForReply(m);
															}}
															onReply={false}
															authenticatedUserId={authenticatedUserId}
															mobileSize={mobileSize}
															disableMessaging={disableMessaging}
															truncateName={truncateName}
															disableReplying={disableReplying}
															reduceReplyMessageSize={reduceReplyMessageSize}
															authenticated={authenticated}
															onPurchasedItemsHyperlinkClicked={
																onPurchasedItemsHyperlinkClicked
															}
															onRandomizationHyperlinkClicked={
																onRandomizationHyperlinkClicked
															}
															setReactions={setReactions}
															setShowReactions={setShowReactions}
															setShowMessageOptions={setShowMessageOptions}
															setSelectedForEdit={setSelectedForEdit}
															selectedForEdit={selectedForEdit}
															setSelectedMessage={setSelectedMessage}
														/>
													</div>
												)}
												{hasReplyMessages
													? messages
													  && messages.length
													  && messages.some(
															(v) => v.reply_message_id == message.id
													  ) && (
															<>
																{repliedMessage(
																	message.id,
																	message.reactions && message.reactions.length
																)}
															</>
													  )
													: null}
											</div>
										))}
									</div>
								);
							})}
						</>
				  )}
			<AlwaysScrollToBottom />
		</div>
	);
};

export default ConversationMessages;
