import React from 'react';

export function RaiseHandIcon({ ...restProps }) {
	return (
		<svg
			className="w-5 h-5"
			fill="none"
			stroke="currentColor"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			{...restProps}
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
			/>
		</svg>
	);
}

export function RaiseHandWithConfirmationIcon({ ...restProps }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.0"
			className="w-6 h-6"
			fill="none"
			stroke="currentColor"
			viewBox="0 0 222.000000 186.000000"
			preserveAspectRatio="xMidYMid meet"
			{...restProps}
		>
			<g
				transform="translate(0.000000,186.000000) scale(0.100000,-0.100000)"
				fill="#000000"
				stroke="none"
			>
				<path d="M1851 1619 c-184 -167 -225 -199 -235 -188 -7 8 -47 56 -90 107 -72 86 -80 92 -115 92 -26 0 -44 -7 -59 -23 -42 -44 -32 -67 103 -227 171 -203 140 -206 406 36 112 103 232 212 267 242 51 46 62 61 62 87 0 43 -35 75 -82 75 -32 0 -56 -19 -257 -201z" />
				<path d="M610 1572 c-19 -11 -44 -33 -55 -50 -15 -24 -25 -31 -40 -26 -100 28 -182 1 -222 -76 -22 -41 -23 -54 -23 -236 l0 -194 -26 10 c-72 28 -163 -9 -201 -80 -21 -39 -23 -56 -23 -179 0 -166 17 -250 69 -356 70 -140 171 -242 310 -310 94 -46 176 -65 291 -65 303 0 560 198 646 499 22 75 32 553 14 621 -25 91 -115 146 -202 123 l-38 -10 0 51 c0 71 -16 121 -51 161 -47 53 -109 66 -194 40 -16 -5 -24 0 -35 20 -35 66 -148 95 -220 57z m138 -98 c22 -25 22 -29 22 -310 l0 -284 45 0 45 0 0 247 c0 240 1 247 22 270 30 32 81 31 113 -2 l25 -24 0 -246 0 -245 45 0 45 0 0 122 c0 111 2 124 22 145 30 32 81 31 113 -2 l25 -24 0 -254 c0 -220 -3 -264 -20 -331 -28 -110 -74 -190 -155 -271 -116 -116 -250 -170 -420 -168 -159 2 -296 63 -410 185 -116 122 -155 237 -155 452 0 130 2 141 22 163 30 32 81 31 113 -2 24 -23 25 -30 25 -145 l0 -120 45 0 45 0 0 372 c0 368 0 372 22 395 30 32 81 31 113 -2 l25 -24 0 -246 0 -245 45 0 45 0 0 284 c0 156 3 292 6 301 9 22 63 46 89 39 11 -3 30 -17 43 -30z" />
			</g>
		</svg>
	);
}
