import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import { isMobile } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { BounceLoader, ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { connect } from 'react-redux';
import Layout from '../components/layouts/layout-default';
import Sidebar from '../components/common/sidebar';
import HeaderHome from '../components/common/header-home';
import PersonalInformation from '../containers/auth/personal-information';
import Card from '../components/Card';
import Tabs from '../components/Tabs';
import PhoneInformation from '../containers/auth/phone-information';
import {
	getProfile,
	updateProfileData,
	uploadProfileImage
} from '../api/auth.request';
import useErrorNotifier from '../hooks/use-error-notifier';
import { ProfileProvider } from '../contexts/profile';
import SuccessModal from '../components/modals/v2/success-modal';
import Button from '../components/button';
import { CUSTOM, FACEBOOK } from '../utils/constants/registration-type';
import SellerProfileTabs from '../containers/auth/seller/seller-profile-tabs';
import { sellerProfileUpdate } from '../api/seller.request';
import { FREE_SHIPPING_REFERENCE_DATA } from '../utils/constants';
import { isEqual, validateEmail } from '../utils/commonFunction';
import { getInvalidSignupPasswordError } from '../utils/passwordRules';
import useReferenceDataValue from '../hooks/useReferenceDataValue';
import { REQUIRED_PHONE_NUMBER_REFERENCE_DATA } from '../utils/constants/reference-data';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const ProfilePage = ({ location, userId }) => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showSellerTab, setShowSellerTab] = useState(false);
	const [activeTab, setActiveTab] = useState(undefined);
	const [shipmentCodeToSave, setShipmentCodeToSave] = useState(null);

	const { value: requiredPhoneNumber } = useReferenceDataValue(
		REQUIRED_PHONE_NUMBER_REFERENCE_DATA,
		'boolean'
	);

	const formSubmittedRef = useRef(false);

	const { showToastError } = useErrorNotifier();

	useEffect(() => {
		if (!location.state || !showSellerTab) return;
		if (location.state.shipmentCode) {
			setShipmentCodeToSave(location.state.shipmentCode);
			setActiveTab(1);
		}
	}, [location.state, showSellerTab]);

	const getDefaultSellerLogo = async () => 'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600w-1037719192.jpg';

	const getBlobFromUrl = (myImageUrl) => new Promise((resolve, reject) => {
		const request = new XMLHttpRequest();
		request.open('GET', myImageUrl, true);
		request.responseType = 'blob';
		request.onload = () => {
			resolve(request.response);
		};
		request.onerror = reject;
		request.send();
	});

	const getDataFromBlob = (myBlob) => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.onerror = reject;
		reader.readAsDataURL(myBlob);
	});

	const convertUrlToImageData = async (myImageUrl) => {
		try {
			const myBlob = await getBlobFromUrl(myImageUrl);
			const myImageData = await getDataFromBlob(myBlob);
			return myImageData;
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const loadProfile = useCallback(async () => {
		try {
			const profileResult = await getProfile(true, true);
			const profile = profileResult.data.user;
			if (!profile) return;
			const resetForm = {
				values: {
					firstName: profile.firstName,
					lastName: profile.lastName,
					nickname: profile.nickname,
					address: profile.mailingAddress1,
					address2: profile.mailingAddress2,
					city: profile.mailingCity,
					postal: profile.mailingZip,
					state: profile.mailingState,
					profilePic: profile.imageUrl,
					userId,
					emailAddress: profile.email,
					imageFiles: [],
					validPhoneNumber: false,
					verifiedPhoneNumber: profile.phoneNumber,
					phoneNumber: profile.phoneNumber,
					isPhoneNumberVerified: profile.phoneNumberConfirmed,
					twoFactorAuthentication: profile.twoFactorEnabled,
					registrationTypeId: profile.registrationTypeId,
					addressHasStreetNumber:
						!!profile.mailingCity
						&& !!profile.mailingZip
						&& !!profile.mailingState
				}
			};

			const sellerProfile = profileResult.data.seller;
			if (sellerProfile) {
				setShowSellerTab(true);

				let defaultLogo = '';

				if (!sellerProfile.storeLogo) {
					defaultLogo = await getDefaultSellerLogo();
				} else if (!sellerProfile.isDefaultLogo) {
					defaultLogo = await convertUrlToImageData(sellerProfile.storeLogo);
				} else {
					defaultLogo = sellerProfile.storeLogo;
				}
				resetForm.values.seller = {
					emailAddress: sellerProfile.email,
					storeLogo: sellerProfile.storeLogo,
					isDefaultLogo: sellerProfile.isDefaultLogo,
					storeName: sellerProfile.storeName,
					address: sellerProfile.mailingAddress1,
					address2: sellerProfile.mailingAddress2,
					city: sellerProfile.mailingCity,
					postal: sellerProfile.mailingZip,
					state: sellerProfile.mailingState,
					phoneNumber: sellerProfile.phoneNumber,
					showShippoTab: sellerProfile.hasAccessToShipment,
					shippingFrequency: sellerProfile.shippingFrequency,
					shippingTypeId: sellerProfile.shippingTypeId,
					shipAllCardsId: sellerProfile.shipAllCardsId,
					fixedShipRates: sellerProfile.fixedShipRates,
					defaultLogo
				};
			}

			// eslint-disable-next-line no-use-before-define
			formik.resetForm(resetForm);
		} catch (err) {
			showToastError(err);
		} finally {
			setLoading(false);
		}
	}, [userId]);

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			nickname: '',
			address: '',
			address2: '',
			city: '',
			postal: '',
			state: '',
			profilePic: '',
			emailAddress: '',
			imageFiles: [],
			validPhoneNumber: false,
			verifiedPhoneNumber: '',
			phoneNumber: '',
			addressHasStreetNumber: false,
			isPhoneNumberVerified: true,
			twoFactorAuthentication: false,
			currentPassword: '',
			newPassword: '',
			registrationTypeId: FACEBOOK,
			userId: '',
			confirmNewPassword: '',
			seller: {
				emailAddress: '',
				companyName: '',
				storeName: '',
				phoneNumber: '',
				address: '',
				address2: '',
				city: '',
				postal: '',
				state: '',
				showShippoTab: false,
				shippingTypeId: '',
				shippingFrequency: '',
				shipAllCardsId: '',
				fixedShipRates: 0,
				showRemoveImageButton: false,
				storeLogo: '',
				storeLogoName: '',
				storeLogoDataUrl: '',
				defaultLogo: '',
				photoDataUrl: ''
			}
		},
		validate: ({
			firstName,
			lastName,
			nickname,
			blobImage,
			profilePic,
			imageFiles,
			currentPassword,
			newPassword,
			confirmNewPassword,
			addressHasStreetNumber,
			city,
			state,
			postal,
			validPhoneNumber,
			registrationTypeId,
			phoneNumber,
			verifiedPhoneNumber,
			seller
		}) => {
			const errors = {
				firstName: '',
				lastName: '',
				nickname: '',
				image: '',
				seller: {}
			};
			const requiredMessage = 'This field is required';

			if (!firstName || !firstName.length) {
				errors.firstName = requiredMessage;
			}
			if (!lastName || !lastName.length) {
				errors.lastName = requiredMessage;
			}
			if (!nickname || !nickname.length) {
				errors.nickname = requiredMessage;
			}

			if (!blobImage && !profilePic && !imageFiles.length) {
				errors.image = 'Please upload a profile picture';
			}

			if (registrationTypeId == CUSTOM) {
				if (currentPassword && currentPassword.length > 0) {
					if (currentPassword.length < 8) {
						errors.currentPassword = 'Password must be at least 8 characters';
					}
					if (!newPassword || !newPassword.length) {
						errors.newPassword = requiredMessage;
					} else if (newPassword.length < 8) {
						errors.newPassword = 'Password must be at least 8 characters';
					} else {
						errors.newPassword = getInvalidSignupPasswordError(newPassword);
					}

					if (!confirmNewPassword || !confirmNewPassword.length) {
						errors.confirmNewPassword = confirmNewPassword;
					} else if (newPassword !== confirmNewPassword) {
						errors.confirmNewPassword = 'Passwords do not match';
					}
				}
			}

			if (!city || !state || !postal) {
				errors.address = 'Please enter a valid street address';
			} else if (!addressHasStreetNumber) {
				errors.address = 'Please enter an address with a street number';
			}

			if (requiredPhoneNumber) {
				if (!phoneNumber || !phoneNumber.length) {
					errors.phoneNumber = requiredMessage;
				}
			} else if (verifiedPhoneNumber != phoneNumber && validPhoneNumber) {
				errors.phoneNumber = 'Please verify your phone number';
			}

			if (seller) {
				if (!seller.shippingFrequency) {
					errors.seller.shippingFrequency = requiredMessage;
				}
				if (!seller.storeName) {
					errors.seller.storeName = requiredMessage;
				}
				if (!seller.address) {
					errors.seller.storeName = requiredMessage;
				} else if (!seller.city || !seller.state || !seller.postal) {
					errors.seller.address = 'Please enter a valid street address';
				}

				if (!seller.emailAddress) {
					errors.seller.emailAddress = requiredMessage;
				} else {
					errors.seller.emailAddress =						validateEmail(seller.emailAddress) == false
						&& 'Please enter a valid email address';
				}
			}

			const allFieldsEmpty = Object.values({ ...errors, seller: '' }).every(
				(value) => value == ''
			);

			const sellerFieldsEmpty = Object.values({ ...errors.seller }).every(
				(value) => value == ''
			);

			if (!allFieldsEmpty) {
				if (formSubmittedRef.current == true) setActiveTab(0);
				formSubmittedRef.current = false;
				return errors;
			}

			if (!sellerFieldsEmpty) {
				if (formSubmittedRef.current == true) setActiveTab(1);
				formSubmittedRef.current = false;
				return errors;
			}
			formSubmittedRef.current = false;
			if (allFieldsEmpty) return {};

			return errors;
		},
		onSubmit: async ({
			firstName,
			lastName,
			address,
			address2,
			city,
			state,
			postal,
			nickname,
			imageFiles,
			twoFactorAuthentication,
			currentPassword,
			newPassword,
			confirmNewPassword,
			seller
		}) => {
			const formData = new FormData();

			if (imageFiles && imageFiles.length > 0) {
				formData.append('image', imageFiles[0]);

				try {
					await uploadProfileImage(formData);
				} catch (err) {
					console.error(err);
				}
			}

			const userProfile = {
				nickName: nickname,
				firstName,
				lastName,
				mailingAddress1: address,
				mailingAddress2: address2,
				mailingCity: city,
				mailingZip: postal,
				mailingState: state,
				twoFactorEnabled: twoFactorAuthentication
			};

			const initialUserProfile = {
				nickName: formik.initialValues.nickname,
				firstName: formik.initialValues.firstName,
				lastName: formik.initialValues.lastName,
				mailingAddress1: formik.initialValues.address,
				mailingAddress2: formik.initialValues.address2,
				mailingCity: formik.initialValues.city,
				mailingZip: formik.initialValues.postal,
				mailingState: formik.initialValues.state,
				twoFactorEnabled: formik.initialValues.twoFactorAuthentication
			};

			if (currentPassword && currentPassword.length) {
				userProfile.password = {
					currentPassword,
					newPassword,
					confirmNewPassword
				};

				initialUserProfile.password = {
					currentPassword: formik.initialValues.currentPassword,
					newPassword: formik.initialValues.newPassword,
					confirmNewPassword: formik.initialValues.confirmNewPassword
				};
			}

			if (!isEqual(userProfile, initialUserProfile)) {
				try {
					await updateProfileData(userProfile);
				} catch (err) {
					showToastError(err);
					return;
				}
			}

			if (showSellerTab) {
				try {
					const storeLogo64 = seller.defaultLogo.replace(
						/^data:image\/\w+;base64,/,
						''
					);

					let rateValue = seller.fixedShipRates;
					if (seller.shippingTypeId === FREE_SHIPPING_REFERENCE_DATA) {
						rateValue = 0;
					}

					const sellerProfile = {
						email: seller.emailAddress,
						mailingAddress1: seller.address,
						mailingAddress2: seller.address2,
						mailingState: seller.state,
						mailingZip: seller.postal,
						mailingCity: seller.city,
						storeLogoName: seller.storeLogoName,
						shipAllCards: seller.shipAllCardsId,
						shippingType: seller.shippingTypeId,
						fixedShiprates: rateValue,
						shippingFrequency: seller.shippingFrequency,
						updatedStoreLogo: seller.storeLogoUpdated,
						shipmentCode: shipmentCodeToSave,
						phone: seller.phoneNumber
					};

					if (seller.storeLogo && seller.showRemoveImageButton) {
						sellerProfile.storeLogo = storeLogo64;
					}
					await sellerProfileUpdate(sellerProfile);
				} catch (err) {
					showToastError(err);
					return;
				}
			}

			setShowSuccessModal(true);
			loadProfile();
		}
	});

	useEffect(() => {
		loadProfile();
	}, [loadProfile, userId]);

	const tabs = useMemo(() => {
		const array = [
			{
				title: 'Personal',
				component: () => (
					<>
						<div className="w-full md:w-1/2">
							<PersonalInformation />
						</div>
						<div className="pt-2 md:pt-0 w-full md:w-1/2 border-l-0 md:border-l border-t md:border-t-0">
							<PhoneInformation
								focusPhoneNumber={
									location.state && location.state.focusPhoneNumber
								}
								formik={formik}
							/>
						</div>
					</>
				)
			}
		];

		if (showSellerTab) {
			array.push({
				title: 'Seller ',
				component: () => (
					<div className="w-full">
						<SellerProfileTabs />
					</div>
				)
			});
		}
		return array;
	}, [showSellerTab]);

	if (loading) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}

	return (
		<Layout auth>
			{isMobile && <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />}
			<SuccessModal
				showModal={showSuccessModal}
				setShowModal={setShowSuccessModal}
			/>
			<div className="flex flex-col flex-1">
				<HeaderHome
					hideSearch
					onChange={() => setSidebarOpen((open) => !open)}
				/>
				<div className="flex flex-col pt-8 justify-center content-center">
					<ToastContainer />
					<div className="px-4">
						<Card
							className="w-full mx-auto pb-10"
							transition={false}
							transitionTimeout={300}
						>
							<ProfileProvider formik={formik} showCreatePasswordFields={false}>
								<Tabs
									tabs={tabs}
									activeTab={activeTab}
									justifyTabsEvenly
									onTabIndexChanged={() => setActiveTab(undefined)}
								/>
								<div className="flex w-full justify-center">
									<Button
										onClick={(e) => {
											formSubmittedRef.current = true;
											formik.handleSubmit(e);
										}}
										width="96"
										height="10"
										textSize="lg"
										mobileHeight="10"
										disabled={!formik.dirty || formik.isSubmitting}
										mobileWidth="full"
										additionalClassName={clsx(
											'md:mx-0 mx-4',
											formik.isSubmitting && 'flex justify-center items-center'
										)}
									>
										{formik.isSubmitting && (
											<ClipLoader color="white" size={20} />
										)}
										<span className={clsx(formik.isSubmitting && 'pl-1')}>
											SAVE
										</span>
									</Button>
								</div>
							</ProfileProvider>
						</Card>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default connect(
	(state) => ({
		userId: state.utils.userId
	}),
	null
)(ProfilePage);
