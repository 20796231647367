/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unreachable */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
	useState, useEffect, useCallback, useRef
} from 'react';
import FileSaver from 'file-saver';
import clsx from 'clsx';
import { PencilAltIcon } from '@heroicons/react/solid';
import { navigate } from 'gatsby';
import ImageGallery from 'react-image-gallery';
import ReactTooltip from 'react-tooltip';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import moment from 'moment';
import firebase from 'gatsby-plugin-firebase';
import { isMobile } from 'react-device-detect';
import { getTimezoneName } from '../../utils/formatter';
import {
	releaseEventBreak,
	reserveBreak,
	releaseBreak
} from '../../api/break.request';
import {
	getEventDetails,
	startRipNShipEvent,
	bookmarkOrUnbookmarkEvent,
	getBookmarkedEvent,
	updateEventName,
	updateEventDate,
	getEventReport,
	getEventStepTeamDistribution,
	cancelEvent,
	getVideoRooms,
	getRipNShipActiveProducts,
	getAllEventProducts
} from '../../api/event.request';
import { reserveProduct } from '../../api/product.request';
import { shouldRedirectToSignIn } from '../../utils/auth';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import MiniCart from '../../components/common/mini-cart';
import { defaultToastError } from '../../utils/toast-error';
import HeaderHome from '../../components/common/header-home';
import EventSidebar from '../../components/common/event-sidebar';
import { createHubConnection } from '../../utils/hubConnection';
import {
	addCartItem,
	removeCartItem,
	setBuyNowData,
	setCheckoutSessionId,
	updateCartItemTimer
} from '../../state/action';
import EventDetailsMenuModal from '../../components/event-details-menu-modal';

import EventEditDate from '../../components/event-edit-date';
import EventProducts from '../../components/event-product/event-products';
import FavoriteIcon from '../../components/favorite-icon';
import Layout from '../../components/layout';
import StoreModal from '../../components/common/store-modal';
import VideoPlayer from '../../components/video-player';

import ProductModalEventQueue from '../../components/room/product-modal-event-queue';
import AccessDeniedMessage from '../../components/room/access-denied';
import Accordion from '../../components/accordion';
import { groupBy } from '../../utils/commonFunction';
import Loader from '../../components/loader/index';
import { getAuthenticatedUserId } from '../../api/auth.request';
import { redirectToDefaultBrowserIfInsideWebViewAndroid } from '../../utils/redirect';
import {
	ALL_PRODUCTS_PRODUCT_SELECTION_OPTION,
	EVENT_CANCELLED_STATUS,
	EVENT_LIVE_STATUS,
	EVENT_PAUSED_STATUS,
	EVENT_PLANNED_STATUS,
	EVENT_STOPPED_STATUS,
	PICK_YOUR_SLOTS,
	RANDOMIZED,
	RIP_N_SHIP,
	SLOT_STATUS_SOLD
} from '../../utils/constants';
import ConfirmationModal from '../../components/confirmation-modal';
import SortingComponent from '../../components/sorting';
import ChangeEventPictureModal from '../../components/Event/change-event-picture';
import { CONVERSATION_PARTICIPANT_COULD_NOT_BE_UPDATED } from '../../utils/errorCodes';
import EllipisWithTooltip from '../../components/ellipsis-tooltip';
import useConversation from '../../hooks/useConversation';
import useErrorNotifier from '../../hooks/use-error-notifier';
import EventDetailsAddProduct from '../../containers/event/event-details-add-product';
import { ProductsProvider } from '../../contexts/products';
import EventProductsRemove from '../../containers/event/event-products-remove';
import RandomizedBreakUnsoldSlotsModal from '../../containers/event/randomized-break-unsold-slots-modal';
import LinkEventModal from '../../containers/event/link-event-modal';
import { informationToast } from '../../utils/information-toast';
import useScreenOrientation from '../../hooks/useScreenOrientation';
import { notifyUserForModifiedShoppingCartAfterPaymentProcess } from '../../utils/slot';
import useCartItems from '../../hooks/useCartItems';
import { getReferenceDataById } from '../../api/referenceData.request';
import { ENABLE_VIDEO_COMPOSITION_REFERENCE_DATA_ID } from '../../utils/constants/reference-data';
import useDebounce from '../../hooks/useDebounce';

let images = [];

const SellerInformationAccordionPanel = ({ seller, setIsStoreModalOpen }) => {
	if (!seller) return;
	return (
		<div className="flex flex-row w-11/12 mx-auto text-left py-2">
			<Accordion title="Seller Information">
				<div className="flex flex-col w-full">
					<div className="mt-1 relative w-full px-3 py-2 text-gray-700 focus:outline-none h-64 gap-4">
						<h3
							className="cursor-pointer"
							onClick={() => setIsStoreModalOpen(true)}
							onKeyDown={() => setIsStoreModalOpen(true)}
						>
							<u>
								<b>{seller.storeName}</b>
							</u>
						</h3>
						<div className="flex items-center">
							<div>
								<p>
									{seller.city}
,
									{seller.state}
								</p>
							</div>
						</div>
						<br />
						<div className="flex flex-col">
							<p>
								Frequency of Shipping:&nbsp;
								<b>{seller.shippingFrequency}</b>
							</p>
							<br />
							<p>
								Ship Cards:&nbsp;
								<b>{seller.shipCards}</b>
							</p>
							<p>
								Shipping Type:&nbsp;
								<b>{seller.shippingType}</b>
							</p>
							<br />
							<p>
								Shipping Cost:&nbsp;
								<b>
$
									{seller.shippingCost}
								</b>
							</p>
						</div>
						<br />
					</div>
				</div>
			</Accordion>
		</div>
	);
};
const EventDetailsPage = ({
	eventId,
	cartItems,
	dispatch,
	currentCheckoutSessionId
}) => {
	const [
		showVideoPendingRecordMessage,
		setShowVideoPendingRecordMessage
	] = useState(false);
	const [videoRooms, setVideoRooms] = useState([]);
	const { isLandscape } = useScreenOrientation();
	const { showToastError } = useErrorNotifier();
	const [loading, setLoading] = useState(true);
	const [isCartOpen, setIsCartOpen] = useState(false);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [eventDetails, setEventDetails] = useState();
	const [eventName, setEventName] = useState('');
	const [eventDate, setEventDate] = useState('');
	const [eventDescription, setEventDescription] = useState();
	const [eventBreaks, setEventBreaks] = useState([]);
	const [isLive, setIsLive] = useState(false);
	const [isSeller, setIsSeller] = useState(false);
	const [hubConnectionWithGroup, setHubConnectionWithGroup] = useState({
		hubConnection: null,
		websocketName: null
	});
	const [liveEventIdInProgress, setLiveEventIdInProgress] = useState(null);
	const [linkedEvents, setLinkedEvents] = useState([]);
	const [wsMessage, setWsMessage] = useState(null);
	const [quantityUpdateWsMessage, setQuantityUpdateWsMessage] = useState(null);
	const [eventType, setEventType] = useState(null);
	const [showMenuModal, setShowMenuModal] = useState(false);

	const [selectedBrakes, setSelectedBreaks] = useState([]);
	const [showEditName, setShowEditName] = useState(false);
	const [editName, setEditName] = useState(eventName);
	const [editNameErr, setEditNameErr] = useState(false);

	const [showEditDate, setShowEditDate] = useState(false);
	const [editDate, setEditDate] = useState(eventDate);
	const [editDateErr, setEditDateErr] = useState(false);
	const [bookmark, setBookmark] = useState(false);
	const [
		deletedProductsNotificationMessage,
		setDeletedProductsNotificationMessage
	] = useState(null);
	const [isStoreModalOpen, setIsStoreModalOpen] = useState(false);
	const [storeInfo, setStoreInfo] = useState(null);
	const [sellerImage, setSellerImage] = useState(null);
	const [showProductModal, setShowProductModal] = useState(false);
	const [eventProduct, setEventProduct] = useState(null);
	const [randomizations, setRandomizations] = useState([]);
	const [showDeniedAccess, setShowDeniedAccess] = useState(false);
	const [videoReady, setVideoReady] = useState(false);
	const [eventCompleted, setEventCompleted] = useState(false);
	const [preRandomizedTiers, setPreRandomizedTiers] = useState({});
	const [randomizationCols, setRandomizationCols] = useState([]);
	const [userCanAddOrRemoveProduct, setUserCanAddOrRemoveProduct] = useState(
		false
	);
	const [
		randomizationColsForSorting,
		setRandomizationColsForSorting
	] = useState([]);
	const [teamSize, setTeamSize] = useState(0);
	const [conversation, setConversation] = useState(null);
	const [authenticatedUserId, setAuthenticatedUserId] = useState(null);
	const [selectedConversation, setSelectedConversation] = useState(null);
	const [showStartConversationBtn, setShowStartConversationBtn] = useState(
		false
	);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [showChangePictureModal, setShowChangePictureModal] = useState(false);
	const [reload, setReload] = useState(false);
	const database = firebase.database();
	const [roomConversation, setRoomConversation] = useState(null);
	const [roomConversationMessages, setRoomConversationMessages] = useState(
		false
	);
	const [
		showProductSelectionMessage,
		setShowProductSelectionMessage
	] = useState(false);
	const {
		findConversationByChild,
		getMessages: getConversationMessages
	} = useConversation();
	const [isUsingTwilioChatService, setIsUsingTwilioChatService] = useState(
		false
	);
	const [
		showRandomizedBreakUnsoldSlotsWarning,
		setShowRandomizedBreakUnsoldSlotsWarning
	] = useState(false);
	const [hasUnsoldSlots, setHasUnsoldSlots] = useState(false);
	const [
		unparsedBreakTeamsRandomization,
		setUnparsedBreakTeamsRandomization
	] = useState([]);
	const [showLinkEventModal, setShowLinkEventModal] = useState(false);
	const [showProductAccordion, setShowProductAccordion] = useState(true);
	const [products, setProducts] = useState([]);
	const [productSearchValue, setProductSearchValue] = useState('');
	const debounceProductSearchValue = useDebounce(productSearchValue, 500);
	const [productsLoading, setProductsLoading] = useState(true);
	const [productsTotalRows, setProductsTotalRows] = useState(0);
	const [productsInitiallyLoaded, setProductsInitiallyLoaded] = useState(false);
	const [productsSortBy, setProductsSortBy] = useState({
		direction: '',
		column: ''
	});
	const [allProducts, setAllProducts] = useState([]);
	const productsPerPageRef = useRef(10);
	const productsCurrentPageRef = useRef(0);

	const { buyNow } = useCartItems(selectedBrakes, false);

	const initializeRoomConversationMessages = useCallback(
		(eventIdToFetchMessages) => {
			findConversationByChild('event_id', eventIdToFetchMessages, true).then(
				(c) => {
					if (c) {
						setRoomConversation(c);
						getConversationMessages(c.id).then((messages) => {
							setRoomConversationMessages(messages);
						});
					}
				}
			);
		},
		[]
	);

	const isHost = useCallback((userId) => {
		const cachedUser = JSON.parse(localStorage.getItem('gatsbyUser'));
		if (!cachedUser) return false;
		return cachedUser.id == userId;
	}, []);

	const startRipNShip = useCallback(() => {
		startRipNShipEvent(eventId)
			.then((_) => {
				navigate(`/event/room/${eventId}`);
			})
			.catch((err) => {
				if (err && err.response) {
					defaultToastError(err.response.data.error);
				}
			});
	}, [eventId]);

	const onProductModalShow = useCallback((eventProduct) => {
		setEventProduct(eventProduct);
		setShowProductModal(true);
	}, []);

	useEffect(() => {
		redirectToDefaultBrowserIfInsideWebViewAndroid();
	}, [window]);

	const updateBreaks = useCallback(
		(breaks) => {
			const tempBreaks = [...eventBreaks].map((b) => {
				const foundBreak = breaks.find((el) => el.breakId == b.breakId);
				if (foundBreak) {
					b.slotStatusId = foundBreak.statusId;
					b.status = foundBreak.status;
					b.slotStatus = foundBreak.status;
					b.buyerNickname =						foundBreak.nickname && foundBreak.nickname.length > 0
						? foundBreak.nickname
						: foundBreak.status;
				}
				return b;
			});

			setEventBreaks(tempBreaks);
		},
		[eventBreaks]
	);

	const formatDate = useCallback((date, langCode) => {
		const day = date.toLocaleString(langCode, { day: '2-digit' });
		const month = date.toLocaleString(langCode, { month: 'short' });
		const year = date.toLocaleString(langCode, { year: 'numeric' });
		return `${day}-${month}-${year}`;
	}, []);

	const parseRandomizations = useCallback(
		(result, preRandomizedTiersTeams) => {
			const cols = ['Slot #', 'Name'];
			let randColsForSorting = [];
			let maxCols = 0;
			if (Object.keys(preRandomizedTiersTeams) == 0) return;

			const firstTiersRandomizedTiers =				preRandomizedTiers[Object.keys(preRandomizedTiers)[0]];

			result.forEach((item) => {
				if (item.tiers.length > maxCols) {
					maxCols = item.tiers.length;
				}
			});

			randColsForSorting.push('slot');
			randColsForSorting.push('nickname');

			for (let i = 0; i < maxCols; i++) {
				cols.push(`Tier ${i + 1}`);
				randColsForSorting.push(`Tier${i + 1}`);
			}
			cols.push('Sort Order');
			randColsForSorting.push('displayOrder');

			setRandomizationCols(cols);

			setRandomizationColsForSorting(randColsForSorting);

			const tempTableData = [];
			result.forEach((item) => {
				const tableRow = {
					slot: item.slot,
					nickname: item.nickname
				};

				let displayOrder = 0;
				item.tiers.forEach((tierTeam) => {
					tableRow[`Tier${tierTeam.tier}`] = tierTeam.team;
					if (tierTeam.tier == 1) {
						const foundPreRandomizedTierIndex = firstTiersRandomizedTiers.findIndex(
							(tier) => tier.teamId == tierTeam.teamId && tier.team == tierTeam.team
						);
						if (!Number.isNaN(foundPreRandomizedTierIndex)) {
							displayOrder = foundPreRandomizedTierIndex + 1;
						}
					}
				});

				tableRow.displayOrder = displayOrder;

				tempTableData.push(tableRow);
			});

			setRandomizations(
				tempTableData.sort((a, b) => a.displayOrder - b.displayOrder)
			);
		},
		[preRandomizedTiers]
	);

	const initializeVideoRooms = useCallback(
		async (eventDetail) => {
			let videoRoomsRes = null;
			try {
				videoRoomsRes = await getVideoRooms(eventId);
			} catch (err) {
				console.error(err);
			}

			// eslint-disable-next-line no-lonely-if
			if (
				videoRoomsRes
				&& videoRoomsRes.data
				&& videoRoomsRes.data.length
				&& videoRoomsRes.data[0].jsonEventDate
			) {
				setVideoRooms(videoRoomsRes.data);
				let event_time = videoRoomsRes.data[0].jsonEventDate;
				event_time = new Date(event_time);
				const formated_date = event_time;
				const local_date = formatDate(formated_date, 'en');
				let time_format_options = { hour: 'numeric', minute: '2-digit' };
				const local_time = formated_date.toLocaleTimeString(
					'en-US',
					time_format_options
				);

				setIsUsingTwilioChatService(
					videoRoomsRes.data[0].isUsingTwilioAsChatService
				);
				if (!videoRoomsRes.data[0].isUsingTwilioAsChatService) {
					let eventIdToFetchMessages = eventDetail.event.eventId;
					if (eventDetail.event.mainEventLinkedToId) {
						eventIdToFetchMessages = eventDetail.event.mainEventLinkedToId;
					}
					initializeRoomConversationMessages(eventIdToFetchMessages);
				}

				setEventDate(
					`${local_date} • ${local_time} (${getTimezoneName(event_time)})`
				);

				if (videoRoomsRes.data[0].video == null) {
					try {
						const enableVideoCompositionReferenceDataRes = await getReferenceDataById(
							ENABLE_VIDEO_COMPOSITION_REFERENCE_DATA_ID
						);
						// show the pending message only if the video composition is enabled
						if (
							enableVideoCompositionReferenceDataRes
							&& enableVideoCompositionReferenceDataRes.data
							&& enableVideoCompositionReferenceDataRes.data.referenceDataValue
								== '1'
						) {
							setShowVideoPendingRecordMessage(true);
						}
					} catch (err) {
						console.warn('Failed to fetch video composition status');
					}
				}
			}
		},
		[eventId]
	);

	const loadProducts = useCallback(
		async (
			newPage = 0,
			dontAddToTotalRows = false,
			loadFromCurrentPage = false
		) => {
			setProductsLoading(true);

			if (loadFromCurrentPage) {
				newPage = productsCurrentPageRef.current;
			} else {
				newPage = newPage < 0 ? 0 : newPage;
			}
			try {
				const productsRes = await getRipNShipActiveProducts(
					eventId,
					newPage,
					productsPerPageRef.current,
					debounceProductSearchValue,
					productsSortBy.column,
					productsSortBy.direction
				);
				setProducts(productsRes.data);
				productsCurrentPageRef.current = newPage;
				const productsLength = productsRes.data.length;
				if (productsLength == 0 && newPage > 0) {
					loadProducts(newPage < 0 ? 0 : newPage - 1, true);
				} else if (productsLength == productsPerPageRef.current) {
					setProductsTotalRows(
						productsLength * (newPage + 1)
							+ (dontAddToTotalRows ? 0 : productsPerPageRef.current)
					);
				} else if (newPage > 0) {
					setProductsTotalRows(productsLength + (productsPerPageRef.current * newPage));
				} else {
					setProductsTotalRows(
						productsLength * (newPage == 0 ? 1 : newPage + 1)
							+ (dontAddToTotalRows
							|| newPage == 0
							|| productsLength < productsPerPageRef.current
								? 0
								: productsPerPageRef.current)
					);
				}

				if (!productsInitiallyLoaded) setProductsInitiallyLoaded(true);
			} catch (err) {
				console.error('Failed to load products', err);
			}
			setProductsLoading(false);
		},
		[eventId, productsCurrentPageRef.current, productsPerPageRef.current, debounceProductSearchValue, productsSortBy]
	);

	useEffect(() => {
		if (!productsInitiallyLoaded) return;
		loadProducts(0);
	}, [debounceProductSearchValue, productsInitiallyLoaded]);

	useEffect(() => {
		if (!productsSortBy || !productsSortBy.column.length) return;
		loadProducts(0);
	}, [productsSortBy]);

	const getPageDetails = async (eventId, init = false) => {
		const res = await getEventDetails(eventId, false, true).catch((error) => {
			if (error.response) {
				defaultToastError(error.response.data.error);
			}
		});

		if (!res || !res.data || !res.data.length) {
			navigate('/events');
			return;
		}

		let eventDetail = null;

		if (res.data.length === 1) {
			setEventDetails(res.data[0]);
			// eslint-disable-next-line prefer-destructuring
			eventDetail = res.data[0];
			setLinkedEvents([]);
		} else {
			setEventDetails(res.data.find((e) => e.event.eventId == eventId));
			eventDetail = res.data.find((e) => e.event.eventId == eventId);
			setLinkedEvents(res.data.filter((e) => e.event.eventId != eventId));
		}
		setAllProducts([]);

		if (eventDetail && eventDetail.products && eventDetail.products.length) {
			setProducts(eventDetail.products);
		} else if (eventDetail.event.eventTypeId == RIP_N_SHIP) {
			loadProducts();
		}
		if (eventDetail.event.eventTypeId == RANDOMIZED) {
			setUnparsedBreakTeamsRandomization(eventDetail.breakTeamsRandomization);
			if (
				!eventDetail.breakTeamsRandomization
				|| !eventDetail.breakTeamsRandomization.length
			) {
				setHasUnsoldSlots(true);
			} else {
				setHasUnsoldSlots(false);
			}
		}
		setEventName(eventDetail.event.eventName);
		setEditName(eventDetail.event.eventName);

		images = [];
		eventDetail.event.eventImages
			.filter((image) => image.isActive)
			.forEach(({ image }) => {
				images.push({
					original: image,
					thumbnail: image,
					fullscreen: image
				});
			});

		if (eventDetail.event.statusId == EVENT_CANCELLED_STATUS) {
			navigate('/events');
		}
		if (eventDetail.event.statusId !== EVENT_STOPPED_STATUS) {
			let event_time = eventDetail.event.eventTime;

			event_time = `${event_time}.000Z`;
			event_time = new Date(event_time);

			const formated_date = event_time;
			const local_date = formatDate(formated_date, 'en');

			let time_format_options = { hour: 'numeric', minute: '2-digit' };
			const local_time = formated_date.toLocaleTimeString(
				'en-US',
				time_format_options
			);

			setEventDate(
				`${local_date} • ${local_time} (${getTimezoneName(event_time)})`
			);
		} else {
			initializeVideoRooms(eventDetail);
		}

		const tempEditDate = moment(
			eventDetail.event.jsonEventDate,
			'YYYY-MM-DDTHH:mm:ss.SSSSZ'
		).toDate();

		setEditDate(tempEditDate);

		let isEventSeller = isHost(eventDetail.event.sellerId);

		if (
			isEventSeller
			&& eventDetail.event
			&& eventDetail.event.statusId == EVENT_PLANNED_STATUS
		) {
			if (
				eventDetail.event.ripNShip
				&& eventDetail.event.ripNShip.productSelectionId
				&& eventDetail.event.ripNShip.productSelectionId
					!== ALL_PRODUCTS_PRODUCT_SELECTION_OPTION
			) {
				setShowProductSelectionMessage(true);
			} else if (!eventDetail.event.ripNShip && eventDetail.hasSales) {
				setUserCanAddOrRemoveProduct(false);
			} else {
				setUserCanAddOrRemoveProduct(true);
			}
		}

		if (
			(eventDetail.event.statusId === EVENT_LIVE_STATUS
				|| eventDetail.event.statusId === EVENT_PAUSED_STATUS
				|| isEventSeller === true)
			&& eventDetail.event.statusId !== EVENT_STOPPED_STATUS
		) {
			setIsLive(true);
		}

		if (eventDetail.event.statusId === EVENT_STOPPED_STATUS) {
			setEventCompleted(true);
		}

		setIsSeller(isEventSeller);

		setEventDescription(eventDetail.event.description);
		setEventType(eventDetail.event.eventTypeId);

		setEventBreaks(eventDetail.breaks);

		setStoreInfo(eventDetail.seller.account);
		setSellerImage(eventDetail.seller.sellerImage);

		if (init === true) {
			let hub = 'break';
			if (eventDetail.event.eventTypeId == RIP_N_SHIP) {
				hub = 'event';
			}

			setHubConnectionWithGroup({
				websocketName: eventDetail.event.webSocketChannelGroupName,
				hubConnection: createHubConnection(hub)
			});
		}

		setLoading(false);
	};

	const getPreRandomizedTiers = useCallback((breakTemplateId) => {
		getEventStepTeamDistribution(breakTemplateId)
			.then((res) => {
				if (res.data && res.data.length) {
					const groupedPreRandomizedTiers = groupBy(res.data, 'tier', false);
					setPreRandomizedTiers(groupedPreRandomizedTiers);
					setTeamSize(
						res.data.length / Object.keys(groupedPreRandomizedTiers).length
					);
				}
			})
			.catch((err) => {
				const mute = err;
				console.error(mute);
			});
	}, []);

	useEffect(() => {
		if (
			!eventDetails
			|| !eventDetails.event
			|| eventDetails.event.eventTypeId != RANDOMIZED
		) {
			return;
		}
		getPreRandomizedTiers(eventDetails.event.initialBreakTemplateId);
	}, [eventDetails]);

	useEffect(() => {
		if (!wsMessage) return;
		updateBreaks(wsMessage);
	}, [wsMessage]);

	useEffect(() => {
		if (
			!preRandomizedTiers
			|| !Object.keys(preRandomizedTiers).length
			|| !unparsedBreakTeamsRandomization
			|| !unparsedBreakTeamsRandomization.length
		) {
			return;
		}
		parseRandomizations(unparsedBreakTeamsRandomization, preRandomizedTiers);
	}, [preRandomizedTiers, unparsedBreakTeamsRandomization]);

	useEffect(() => {
		getPageDetails(eventId, true);
		getBookmarkedEvent(eventId)
			.then((res) => {
				if (res.data) {
					setBookmark(res.data.isBookmarked);
				}
			})
			.catch((err) => {
				console.error(err);
			});

		getAuthenticatedUserId()
			.then((userRes) => {
				setAuthenticatedUserId(userRes.data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [eventId, reload]);

	useEffect(() => {
		if (!eventId || !authenticatedUserId) return;
		let tempConversation = {};
		database.ref('conversation').on('value', (snapshot) => {
			snapshot.forEach((childSnapshot) => {
				const conversationObj = childSnapshot.val();
				if (conversationObj.event_id === eventId) {
					conversationObj.id = childSnapshot.key;
					tempConversation = conversationObj;
					database
						.ref('conversation_participant')
						.orderByChild('conversation_id')
						.equalTo(tempConversation.id)
						.on('value', (snapshot) => {
							let isUserPartipant = false;
							let isConversationShown = false;
							snapshot.forEach((childSnapshot) => {
								const conversationParticipant = childSnapshot.val();
								if (conversationParticipant.user_id == authenticatedUserId) {
									isUserPartipant = true;
								}
							});

							if (
								!conversationObj.started_by_event
								&& (isUserPartipant
									|| authenticatedUserId == conversationObj.user_id)
							) {
								setShowStartConversationBtn(true);
							} else {
								setShowStartConversationBtn(false);
							}
						});
				}
			});
			setConversation(tempConversation);
		});
	}, [authenticatedUserId]);

	const startConversation = useCallback(() => {
		if (!conversation) return;

		if (!eventBreaks.some((s) => s.slotStatusId == SLOT_STATUS_SOLD)) {
			showToastError('Unable to send message as no slots have been sold yet');
			return;
		}

		if (conversation.user_id == authenticatedUserId) {
			database
				.ref(`conversation/${conversation.id}`)
				.update({
					show_conversation: true,
					started_by_event: true
				})
				.then(() => {
					setSelectedConversation(conversation);
				});
		} else {
			database.ref('conversation_participant').on('value', (snapshot) => {
				snapshot.forEach((childSnapshot) => {
					const tempParticipantObj = childSnapshot.val();
					const participantId = childSnapshot.key;
					if (
						tempParticipantObj.conversation_id == conversation.id
						&& authenticatedUserId == tempParticipantObj.user_id
					) {
						database
							.ref(`conversation_participant/${participantId}`)
							.update({
								show_conversation: true
							})
							.then(() => {
								database
									.ref(`conversation/${conversation.id}`)
									.update({
										started_by_event: true
									})
									.then(() => {
										setSelectedConversation(conversation);
									});
							})
							.catch(() => {
								showToastError(CONVERSATION_PARTICIPANT_COULD_NOT_BE_UPDATED);
							});
					}
				});
			});
		}
	}, [conversation, eventBreaks]);

	useEffect(() => {
		if (!deletedProductsNotificationMessage) return;
		const eventProductIds = [...deletedProductsNotificationMessage];
		eventProductIds.forEach((eventProductId) => {
			const foundProduct = cartItems.find((item) => item.id == eventProductId);
			if (!foundProduct) return;
			dispatch(removeCartItem(foundProduct));
			defaultToastError({
				shortMessage: `${foundProduct.title} has been removed`,
				longMessage:
					"'This item has been removed from your cart because the seller has deleted the product.'"
			});
		});
		getPageDetails(eventId, true);
	}, [deletedProductsNotificationMessage]);

	useEffect(() => {
		setEditNameErr(!editName);
	}, [editName]);

	useEffect(() => {
		if (!quantityUpdateWsMessage) return;
		const tempEventDetail = { ...eventDetails };
		tempEventDetail.products.map((eventProduct) => {
			if (
				eventProduct.eventProductId == quantityUpdateWsMessage.eventProductId
			) {
				eventProduct.reservedQuantityByBuyers
					+= quantityUpdateWsMessage.quantity;
				eventProduct.product.totalAvailableStock
					-= quantityUpdateWsMessage.quantity;
			}
			return eventProduct;
		});

		setEventDetails(tempEventDetail);
	}, [quantityUpdateWsMessage]);

	const onBookmarkClicked = useCallback(() => {
		bookmarkOrUnbookmarkEvent(eventId)
			.then((res) => {
				setBookmark((b) => {
					informationToast(
						b === true
							? `You have removed the event "${eventName}" from your favorites`
							: `You have added the event "${eventName}" to your favorites`
					);
					return !b;
				});
			})
			.catch((err) => {
				if (err.response.data) {
					defaultToastError(err.response.data.error);
				}
			});
	}, [eventId, eventName]);

	const onNewWebsocketNameUpdated = useCallback((newWebsocketName) => {
		setHubConnectionWithGroup({
			websocketName: newWebsocketName,
			hubConnection: createHubConnection('event')
		});
	}, []);

	useEffect(() => {
		if (
			!hubConnectionWithGroup
			|| !hubConnectionWithGroup.hubConnection
			|| !eventDetails
		) {
			return;
		}

		const { hubConnection, websocketName } = hubConnectionWithGroup;

		hubConnection
			.start()
			.then((res) => {
				hubConnection.invoke('JoinEvent', websocketName);
			})
			.then((result) => {
				if (eventDetails.event.eventTypeId != RIP_N_SHIP) {
					hubConnection.on('ReceiveMessage', (message) => {
						setWsMessage(message);
					});
				} else {
					hubConnection.on('NotifyForReservedProducts', (message) => {
						setQuantityUpdateWsMessage(message);
					});

					hubConnection.on('NotifyForDeletedProducts', (message) => {
						setDeletedProductsNotificationMessage(message);
					});
				}

				hubConnection.on(
					'NotifyUsersForEventDetailsChangedWebsocketName',
					(message) => {
						onNewWebsocketNameUpdated(message);
					}
				);
			})
			.catch((err) => {
				console.error(err);
			});

		hubConnection.on('stop', (e) => {
			console.error(e);
		});

		return () => {
			if (hubConnection && hubConnection.stop) {
				hubConnection.stop().catch((err) => {
					console.error(err);
				});
			}
		};
	}, [hubConnectionWithGroup]);

	const changeProductQuantityToCart = useCallback(
		async (product, quantity) => {
			if (shouldRedirectToSignIn() === true) {
				const redirectUrl = `/event/${eventId}`;
				navigate('/login', {
					state: { redirectUrl }
				});
				return;
			}
			if (product.quantity + quantity <= 0) {
				showToastError('You cannot choose a quantity of 0');
				return;
			}

			reserveProduct(eventId, product.id, quantity, currentCheckoutSessionId)
				.then((res) => {
					notifyUserForModifiedShoppingCartAfterPaymentProcess(
						cartItems,
						currentCheckoutSessionId
					);
					dispatch(setCheckoutSessionId(null));
					dispatch(removeCartItem(product));
					product.quantity += quantity;
					dispatch(addCartItem(product));
				})
				.catch((err) => {
					if (err.response) {
						const { isSlotAvailable } = err.response.data;
						if (!isSlotAvailable) {
							showToastError('Adding additional products is not possible');
						}
					}
				});
		},
		[eventId]
	);

	const addProductToCart = useCallback(
		(eventProduct, image, quantity) => {
			if (shouldRedirectToSignIn() === true) {
				const redirectUrl = `/event/${eventId}`;
				navigate('/login', {
					state: { redirectUrl }
				});
				return;
			}
			reserveProduct(
				eventId,
				eventProduct.eventProductId,
				quantity,
				currentCheckoutSessionId
			)
				.then((res) => {
					notifyUserForModifiedShoppingCartAfterPaymentProcess(
						cartItems,
						currentCheckoutSessionId
					);
					dispatch(setCheckoutSessionId(null));
					let { timerInSeconds: timer } = res.data.slot;
					if (timer == null) timer = 5;
					timer /= 60;

					const item = {
						id: eventProduct.eventProductId,
						eventName: `${eventDetails.event.eventName} - Event Number #${eventDetails.event.eventNumber}`,
						quantity,
						image,
						price: eventProduct.product.salesPriceRipShip,
						eventId,
						isProduct: true,
						time: new Date(Date.parse(new Date()) + timer * 60 * 1000),
						title: `${eventProduct.product.productName}`
					};

					dispatch(addCartItem(item));
				})
				.catch((err) => {
					if (err.response) {
						defaultToastError(err.response.data.error);
					}
				});
		},
		[eventId, eventDetails]
	);

	const addItemToCart = useCallback(
		async (e, eventBreak) => {
			e.persist();
			e.target.disabled = true;
			if (shouldRedirectToSignIn() === true) {
				const redirectUrl = `/event/${eventId}`;
				navigate('/login', {
					state: { redirectUrl }
				});
				return;
			}

			let rawResponse = null;
			try {
				rawResponse = await reserveBreak(
					eventBreak.breakId,
					eventDetails.event.webSocketChannelGroupName,
					currentCheckoutSessionId
				);
				notifyUserForModifiedShoppingCartAfterPaymentProcess(
					cartItems,
					currentCheckoutSessionId
				);
				dispatch(setCheckoutSessionId(null));
			} catch (error) {
				if (error.response) {
					defaultToastError(error.response.data.error);
				}
			}
			if (!rawResponse) return;
			if (rawResponse.status === 200) {
				let { timerInSeconds: timer } = rawResponse.data.slot;
				if (timer == null) timer = 5;
				timer /= 60;

				const item = {
					id: eventBreak.breakId,
					eventName: `${eventDetails.event.eventName} - Event Number #${eventDetails.event.eventNumber}`,
					title: `${eventName} Slot#${eventBreak.slot}`,
					eventId,
					time: new Date(Date.parse(new Date()) + timer * 60 * 1000),
					price: eventBreak.slotPrice
				};

				if (
					eventDetails.event.eventImages
					&& eventDetails.event.eventImages.length
				) {
					item.image = eventDetails.event.eventImages[0].image;
				}

				const tempBreaks = [...selectedBrakes];
				tempBreaks.push(item);

				const filterArray = [];
				tempBreaks.forEach((item) => {
					const i = filterArray.findIndex((x) => x.id === item.id);
					if (i <= -1) {
						filterArray.push({
							id: item.id,
							eventId: item.eventId,
							title: item.title,
							description: item.description,
							time: item.time,
							price: item.price
						});
					}
				});

				setSelectedBreaks([...new Set(filterArray)]);

				dispatch(addCartItem(item));
				window.localStorage.djwanCart = JSON.stringify(filterArray);
				e.target.disabled = false;
			} else {
				showToastError("Couldn't Reserve the slot, please try again.");
			}
		},
		[eventId, eventDetails, eventName, selectedBrakes]
	);

	const removeItemFromCart = async (eventBreak, reason) => {
		if (window.myButton) {
			window.myButton.close();
		}

		// const response = await releaseEventBreak(eventBreak.id);

		const response = await releaseBreak(eventBreak.id).catch((error) => {
			if (error.response) {
				defaultToastError(error.response.data.error);
			}
		});

		if (!response) return;
		dispatch(removeCartItem(eventBreak));
		const tempBreaks = window.localStorage.djwanCart
			? JSON.parse(window.localStorage.djwanCart)
			: [];
		let remove_index = -1;
		tempBreaks.forEach((item, index) => {
			if (item.id === eventBreak.id) {
				remove_index = index;
			}
		});

		if (remove_index !== -1) {
			tempBreaks.splice(remove_index, 1);
		}

		window.localStorage.djwanCart = JSON.stringify(tempBreaks);
		setSelectedBreaks(
			window.localStorage.djwanCart
				? JSON.parse(window.localStorage.djwanCart)
				: []
		);

		const temp_array = window.localStorage.djwanCart
			? JSON.parse(window.localStorage.djwanCart)
			: [];
		getPageDetails(eventId);

		if (response.status === 200) {
			if (reason === 'remove') {
				showToastError('The item has been removed from your cart.');
			}

			if (reason === 'timeout') {
				showToastError(
					'The item has been removed from your cart due as you may have taken to long to check-out. Please try again.'
				);
			}
		} else {
			showToastError('Couldnt Release the Slot. Please try again.');
		}
	};

	const removeTimeOutItem = async (itemId, reason) => {
		if (window.myButton) {
			window.myButton.close();
		}

		const response = await releaseEventBreak(itemId);

		const tempBreaks = window.localStorage.djwanCart
			? JSON.parse(window.localStorage.djwanCart)
			: [];
		let remove_index = -1;
		tempBreaks.forEach((item, index) => {
			if (item.id === itemId) {
				remove_index = index;
			}
		});

		if (remove_index !== -1) {
			tempBreaks.splice(remove_index, 1);
		}

		window.localStorage.djwanCart = JSON.stringify(tempBreaks);
		setSelectedBreaks(
			window.localStorage.djwanCart
				? JSON.parse(window.localStorage.djwanCart)
				: []
		);

		const temp_array = window.localStorage.djwanCart
			? JSON.parse(window.localStorage.djwanCart)
			: [];

		// getPageDetails(eventId);

		if (response.status === 200) {
			if (reason === 'remove') {
				showToastError('The item has been removed from your cart.');
			}

			if (reason === 'timeout') {
				showToastError(
					'The item has been removed from your cart due as you may have taken to long to check-out. Please try again.'
				);
			}
		} else {
			showToastError('Couldnt Release the Slot. Please try again.');
		}
	};

	const setLoadingActive = () => {
		setLoading(true);
	};

	const updateName = useCallback(() => {
		if (editNameErr) return;
		updateEventName({
			eventId,
			name: editName
		}).then((res) => {
			setEventName(editName);
			setShowEditName(false);
		});
	}, [eventId, editNameErr, editName]);

	const formatNewDate = useCallback((date) => {
		let event_time = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
		event_time = new Date(event_time);

		const formated_date = event_time;
		const local_date = formatDate(formated_date, 'en');

		const time_format_options = { hour: 'numeric', minute: '2-digit' };
		const local_time = formated_date.toLocaleTimeString(
			'en-US',
			time_format_options
		);

		setEventDate(
			`${local_date} • ${local_time} (${getTimezoneName(event_time)})`
		);
	}, []);

	const onCancelEvent = useCallback(() => {
		cancelEvent(eventId)
			.then(() => {
				navigate('/events');
			})
			.catch((err) => {
				if (err && err.response) {
					defaultToastError(err.response.data.error);
				}
			});
	}, [eventId]);

	const updateDate = useCallback(() => {
		if (editDateErr) return;

		updateEventDate({
			eventId,
			date: editDate
		}).then((res) => {
			formatNewDate(editDate);
			setShowEditDate(false);
		});
	}, [eventId, editDate, editDateErr]);

	const b64toBlob = useCallback(
		(b64Data, contentType = '', sliceSize = 512) => {
			const byteCharacters = atob(b64Data);
			const byteArrays = [];

			for (
				let offset = 0;
				offset < byteCharacters.length;
				offset += sliceSize
			) {
				const slice = byteCharacters.slice(offset, offset + sliceSize);

				const byteNumbers = new Array(slice.length);
				for (let i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}

				const byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}

			const blob = new Blob(byteArrays, { type: contentType });
			return blob;
		},
		[]
	);

	const downloadEventReport = useCallback(() => {
		getEventReport(eventId)
			.then((res) => {
				const blob = b64toBlob(res.data.base64, {
					type:
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
				});
				FileSaver.saveAs(blob, `${eventName}.xlsx`);
			})
			.catch((err) => {
				if (err && err.response) {
					defaultToastError(err.response.data.error);
				}
			});
	}, [eventId, eventName]);

	if (loading) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<Loader />
				</div>
			</div>
		);
	}

	const sortRandomizations = (label, columnName) => (
		<SortingComponent
			label={label}
			arrayToBeSorted={randomizations.slice()}
			setArray={setRandomizations}
			field={columnName}
			defaultColumnColor="black"
		/>
	);

	const sortBreaks = (label, columnName) => (
		<SortingComponent
			label={label}
			arrayToBeSorted={eventBreaks.slice()}
			setArray={setEventBreaks}
			field={columnName}
			changeColorOfColumn
		/>
	);

	return (
		<ProductsProvider>
			<Layout auth>
				<StoreModal
					isOpen={isStoreModalOpen}
					setIsOpen={setIsStoreModalOpen}
					store={storeInfo}
					image={sellerImage}
				/>

				<div style={{ display: 'none' }}>
					<div id="event-buyers-template-eventid" />
				</div>
				<AccessDeniedMessage
					showModal={showDeniedAccess}
					setShowModal={setShowDeniedAccess}
					isHost
				/>
				<LinkEventModal
					showModal={showLinkEventModal}
					setShowModal={setShowLinkEventModal}
					onSaved={() => {
						getPageDetails(eventId, false);
					}}
					eventId={eventId}
					linkedEvents={linkedEvents}
				/>
				<div className="flex flex-col flex-1 h-full w-full relative">
					<ReactTooltip />
					<ConfirmationModal
						showModal={showConfirmationModal}
						setShowModal={setShowConfirmationModal}
						onConfirm={onCancelEvent}
					/>
					<HeaderHome
						siteTitle={`${eventName} | DJAWN`}
						hideSearch
						defaultEventIdInProgress={liveEventIdInProgress}
						setDefaultEventIdInProgress={setLiveEventIdInProgress}
						showSidebar={false}
						logoPosition="left"
						cartOnClick={() => setIsCartOpen(!isCartOpen)}
						showCart
						cartItemCount={() => {
							let total = 0;
							cartItems.forEach((cartItem) => {
								if (cartItem.quantity) {
									total += cartItem.quantity;
								} else {
									total += 1;
								}
							});
							return total;
						}}
						onChange={() => setIsSidebarOpen(!isSidebarOpen)}
						setLoading={setLoading}
						onError={(errorMessage) => {
							defaultToastError(errorMessage);
						}}
						selectedConversation={selectedConversation}
						showHelpIconForEvent
						isRipNShip={eventType == RIP_N_SHIP}
					/>
					<div className="flex flex-col flex-grow h-full w-full mt-2 md:mt-4 items-center">
						<EventDetailsMenuModal
							startRipNShip={startRipNShip}
							setLiveEventIdInProgress={setLiveEventIdInProgress}
							showModal={showMenuModal}
							isRipNShip={eventType == RIP_N_SHIP}
							setShowModal={setShowMenuModal}
							mainEventId={eventDetails.event.mainEventLinkedToId}
							completed={eventCompleted}
							className="event-details-seller-menu"
							isLive={isLive && isSeller}
							eventId={eventId}
							hasUnsoldSlots={hasUnsoldSlots}
							setShowRandomizedBreakUnsoldSlotsWarning={
								setShowRandomizedBreakUnsoldSlotsWarning
							}
							downloadEventReport={downloadEventReport}
							isPlanned={eventDetails.event.statusId == EVENT_PLANNED_STATUS}
							setShowDeniedAccess={setShowDeniedAccess}
							onCancelEvent={() => {
								setShowMenuModal(false);
								setShowConfirmationModal(true);
							}}
							canCancelEvent={isSeller}
							conversation={conversation}
							setSelectedConversation={setSelectedConversation}
							showMessageBuyersBtn={!showStartConversationBtn}
							canChangePicture={isSeller}
							onChangePicture={() => {
								setShowMenuModal(false);
								setShowChangePictureModal(true);
							}}
							canLinkEvent={
								isSeller
								&& eventDetails
								&& eventDetails.event
								&& eventDetails.event.statusId == EVENT_PLANNED_STATUS
								&& !eventDetails.mainEventLinked
							}
							onLinkEvent={() => {
								setShowMenuModal(false);
								setShowLinkEventModal(true);
							}}
						/>

						<ChangeEventPictureModal
							showModal={showChangePictureModal}
							setShowModal={setShowChangePictureModal}
							eventId={eventId}
							onChangePicture={() => {
								setReload(!reload);
							}}
						/>

						<EventSidebar
							isOpen={isSidebarOpen}
							isSeller={isSeller}
							setLiveEventIdInProgress={setLiveEventIdInProgress}
							liveEventId={eventId}
							mainEventId={eventDetails.event.mainEventLinkedToId}
							isLive={isLive}
							hasUnsoldSlots={hasUnsoldSlots}
							setShowRandomizedBreakUnsoldSlotsWarning={
								setShowRandomizedBreakUnsoldSlotsWarning
							}
							onCancelEvent={() => {
								setShowMenuModal(false);
								setShowConfirmationModal(true);
							}}
							isPlanned={eventDetails.event.statusId == EVENT_PLANNED_STATUS}
							setShowDeniedAccess={setShowDeniedAccess}
							canCancelEvent={isSeller}
						/>
						<div className="flex flex-col content-center w-full justify-center ">
							<div className="bg-white h-full px-2 md:px-4 mx-2 md:mx-4 border shadow-lg rounded-md py-2 md:py-4">
								<ToastContainer />
								<div className="lg:grid lg:grid-cols-3 ">
									<div className="col-span-1 mx-auto">
										{images.length > 0 && (
											<div className="py-6 mb-0 w-full mx-auto">
												<div className="flex flex-wrap items-center justify-center mx-auto">
													<div className="flex">
														{authenticatedUserId && (
															<div className="flex justify-center">
																<button
																	type="button"
																	className={`${
																		bookmark
																			? 'bg-djawn text-djawn'
																			: 'bg-white'
																	} border bg-opacity-75 rounded-full h-10 w-10 flex items-center justify-center mx-2
														disabled:opacity-50 focus:outline-none  border-djawn hover:bg-djawn`}
																	onClick={onBookmarkClicked}
																	data-tip={
																		bookmark
																			? 'Remove from favorites'
																			: 'Add to favorites'
																	}
																>
																	{bookmark ? (
																		<FavoriteIcon
																			fill="#e82e2c"
																			stroke="white"
																			className="h-8 w-8 hover:fill-black hover:text-black ml-1 mt-1"
																		/>
																	) : (
																		<FavoriteIcon
																			fill="white"
																			stroke="#e82e2c"
																			className="h-8 w-8 hover:fill-black hover:text-black ml-1 mt-1"
																		/>
																	)}
																</button>
															</div>
														)}
														{images.length && (
															<ImageGallery
																items={images}
																showPlayButton={false}
																thumbnailClass="flex"
																originalClass="h-60 max-h-60"
																additionalClass="flex flex-wrap max-h-full max-w-full"
															/>
														)}
													</div>
												</div>
											</div>
										)}

										{isSeller && (
											<div className="flex flex-row w-full mx-auto text-left xxs:px-4 md:px-0 justify-center hover:text-red-500">
												<button
													type="button"
													className={`bg-white text-djawn border bg-opacity-75 rounded-full h-10 w-10 flex items-center justify-center mx-2
													disabled:opacity-50 focus:outline-none  border-djawn hover:bg-djawn hover:border-white hover:text-white`}
													onClick={() => setShowMenuModal(!showMenuModal)}
												>
													<svg
														className="w-10 h-10"
														fill="none"
														stroke="currentColor"
														viewBox="0 0 24 24"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M4 6h16M4 12h16M4 18h16"
														/>
													</svg>
												</button>
											</div>
										)}

										<div
											className="flex flex-col items-center mx-auto w-full"
											style={{ maxWidth: '95vw' }}
										>
											<div className="flex flex-row w-11/12 mx-auto text-left py-2 xxs:px-4 md:px-0">
												<div className="flex w-full items-center content-start">
													<label
														htmlFor="event_date_span"
														className="w-1/2 md:w-36 xxs:w-28 text-gray-800 block font-bold text-lg xxs:text-sm tracking-wide pr-3"
													>
														Event Name
													</label>
													<div className="ml-3 flex-1 flex flex-row w-1/2 items-center">
														{showEditName == true ? (
															<div className="flex flex-col w-full px-4 leading-tight">
																<input
																	type="text"
																	autoFocus
																	className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
																	onChange={(e) => setEditName(e.target.value)}
																	maxLength="60"
																	defaultValue={editName}
																/>

																{editNameErr && (
																	<p className="text-red-700 text-xss w-auto mx-4 px-2 leading-tight pt-2">
																		This field is required
																	</p>
																)}
															</div>
														) : (
															<p className="border-gray-200 rounded w-auto px-4 text-gray-800 text-lg xxs:text-sm leading-tight">
																{eventName}
															</p>
														)}

														{eventDetails.event.statusId
															== EVENT_PLANNED_STATUS
															&& isSeller && (
															<>
																{showEditName == true ? (
																	<div
																		className="flex justify-start items-start cursor-pointer hover:bg-opacity-50"
																		onClick={updateName}
																		onKeyDown={updateName}
																	>
																		<svg
																			className="w-4 h-4"
																			fill="none"
																			stroke="currentColor"
																			viewBox="0 0 24 24"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				strokeLinecap="round"
																				strokeLinejoin="round"
																				strokeWidth="2"
																				d="M5 13l4 4L19 7"
																			/>
																		</svg>
																	</div>
																) : (
																	<div
																		className="flex justify-end items-center cursor-pointer hover:bg-opacity-50"
																		onClick={() => setShowEditName(true)}
																		onKeyDown={() => setShowEditName(true)}
																	>
																		<PencilAltIcon className="w-4 h-4" />
																	</div>
																)}
															</>
														)}
													</div>
												</div>
											</div>
											<div className="flex flex-row w-11/12 mx-auto text-left py-2 xxs:px-4 md:px-0">
												<div className="flex w-full items-center content-start">
													{showEditDate ? (
														<div>
															<div className="flex">
																<label
																	htmlFor="event_date_span"
																	className={`w-1/2 md:w-36 xxs:w-28 text-gray-800 block font-bold text-lg xxs:text-sm tracking-wide pr-3 ${
																		showEditDate && 'mb-5'
																	}`}
																>
																	Event Date
																</label>
															</div>
															{showEditDate && (
																<div className="flex">
																	<label
																		htmlFor="event_date_span"
																		className="w-1/2 md:w-36 xxs:w-28 text-gray-800 block font-bold text-lg xxs:text-sm tracking-wide pr-3"
																	>
																		Event Time
																	</label>
																</div>
															)}
														</div>
													) : (
														<label
															htmlFor="event_date_span"
															className="w-1/2 md:w-36 xxs:w-28 text-gray-800 block font-bold text-lg xxs:text-sm tracking-wide pr-3"
														>
															Event Date
														</label>
													)}

													<div className="ml-3 flex-1 flex flex-row w-1/2 items-center">
														<EventEditDate
															isSeller={isSeller}
															statusId={eventDetails.event.statusId}
															showEditDate={showEditDate}
															setDate={setEditDate}
															actualDate={eventDate}
															setShowEditDate={setShowEditDate}
															date={editDate}
															updateDate={updateDate}
														/>
													</div>
												</div>
											</div>
											<div className="flex flex-row w-11/12 mx-auto text-left py-2 xxs:px-4 md:px-0">
												<div className="flex w-full items-center content-start">
													<label
														htmlFor="event_date_span"
														className="w-1/2 md:w-36 xxs:w-28 text-gray-800 block font-bold text-lg xxs:text-sm tracking-wide pr-3"
													>
														Event ID
													</label>
													<div className="ml-3 flex-1 flex flex-row w-1/2 items-center">
														<p className="border-gray-200 rounded w-auto px-4 text-gray-800 text-lg xxs:text-sm leading-tight">
															{eventDetails
																&& eventDetails.event
																&& eventDetails.event.eventNumber}
														</p>
													</div>
												</div>
											</div>
											{eventDetails.mainEventLinked != null && (
												<div className="flex flex-row w-11/12 mx-auto text-left py-2 xxs:px-4 md:px-0">
													<div className="flex w-full items-center content-start">
														<label
															htmlFor="event_date_span"
															className="w-1/2 md:w-36 xxs:w-28 text-gray-800 block font-bold text-lg xxs:text-sm tracking-wide pr-3"
														>
															Linked event
														</label>
														<div className="ml-3 flex-1 flex flex-row w-full items-center">
															<p className=" border-gray-200 rounded w-auto px-4 text-gray-800 text-lg xxs:text-sm leading-tight">
																{eventDetails.mainEventLinked.eventName}
																{' - Event Number #'}
																{eventDetails.mainEventLinked.eventNumber}
															</p>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>

									<div className="col-span-2 h-auto md:h-80vh overflow-y-hidden md:overflow-y-scroll">
										{showVideoPendingRecordMessage && (
											<p className="font-bold w-11/12 flex text-center py-2 items-center justify-center mx-auto text-gray-800">
												Event replay will be available soon. Check back in a few
												minutes.
											</p>
										)}
										{!!(
											videoRooms
											&& videoRooms.length
											&& videoRooms[0].video
										)
											&& videoRooms
												.filter((f) => f.video != null)
												.map((room) => (
													<div
														className={`flex w-full md:w-11/12 py-0 xxs:px-4 md:px-0 mx-auto ${
															!videoReady
																? 'items-center justify-center'
																: 'items-stretch'
														}`}
													>
														<div
															className={`w-full flex ${
																videoReady
																	? 'md:justify-start'
																	: 'md:justify-center'
															}`}
														>
															<VideoPlayer
																videoReady={videoReady}
																setVideoReady={setVideoReady}
																eventDetails={eventDetails}
																room={room}
																isUsingTwilioChatService={
																	isUsingTwilioChatService
																}
																conversation={roomConversation}
																roomConversationMessages={
																	roomConversationMessages
																}
															/>
														</div>
													</div>
												))}

										{showStartConversationBtn && !eventCompleted && (
											<div className="flex flex-row w-11/12 mx-auto text-left py-2">
												<button
													type="button"
													className="h-12 ml-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-red-600 hover:bg-red-700 md:py-4 md:text-lg md:px-10"
													onClick={() => {
														startConversation();
													}}
												>
													Start conversation
												</button>
											</div>
										)}
										{eventDetails.seller
											&& eventDetails.event.eventTypeId == RIP_N_SHIP && (
											<>
												<SellerInformationAccordionPanel
													seller={eventDetails.seller}
													setIsStoreModalOpen={setIsStoreModalOpen}
												/>
												<div
													className="flex flex-row w-11/12 mx-auto text-left py-2"
													style={{ maxWidth: '95vw' }}
												>
													<Accordion title="Event Details" showAccordion>
														<div className="flex flex-row w-full p-4 border border-t-0 rounded-md shadow-sm whitespace-pre-line break-words">
															{eventDescription}
														</div>
													</Accordion>
												</div>

												{!eventCompleted ? (
													<EventProducts
														products={products}
														onProductModalShow={onProductModalShow}
														showRemoveProductOption={
															userCanAddOrRemoveProduct
														}
														showAccordion={showProductAccordion}
														setShowAccordion={setShowProductAccordion}
														useEventProductName={
															eventDetails.event.eventTypeId != RIP_N_SHIP
														}
														showPrice={
															eventDetails.event.eventTypeId == RIP_N_SHIP
														}
														showQuantity={
															eventDetails.event.eventTypeId != RIP_N_SHIP
														}
														serverSide={!allProducts.length}
														currentPageRef={productsCurrentPageRef}
														rowsPerPageRef={productsPerPageRef}
														totalRows={productsTotalRows}
														loadProducts={loadProducts}
														loading={productsLoading}
														onSearch={({ target }) => setProductSearchValue(target.value)}
														onSort={(column, sortDirection) => {
															setProductsSortBy({
																column: column.name,
																direction: sortDirection == 'asc' ? 'ascending' : 'descending'
															});
														}}
													/>
												) : null}
											</>
										)}
										{eventProduct && (
											<ProductModalEventQueue
												showModal={showProductModal}
												eventDetails={eventDescription}
												eventHasSoldSlots={
													eventBreaks
													&& eventBreaks.length
													&& eventBreaks.find(
														(b) => b.slotStatusId == SLOT_STATUS_SOLD
													) != null
												}
												landscape={isLandscape}
												setShowModal={setShowProductModal}
												authenticated={authenticatedUserId != null}
												buyNowEnabled={
													eventDetails && eventDetails.buyNowEnabled
												}
												useEventProductImage={
													eventDetails.event.eventTypeId != RIP_N_SHIP
												}
												onlyShowProductImage={
													eventDetails.event.eventTypeId != RIP_N_SHIP
													&& !isSeller
												}
												updateQuantityEnabled={
													eventDetails.event.eventTypeId != RIP_N_SHIP
													&& isSeller
												}
												onQuantityUpdated={() => {
													getPageDetails(eventId);
												}}
												addToCartEnabled={
													eventDetails.event.statusId
														!== EVENT_STOPPED_STATUS
													&& eventDetails.ripNShipAddToCartButtonEnabled
												}
												onBuyNow={(eventProduct, image, quantity) => {
													const existingEventProduct = cartItems.find(
														(f) => f.id == eventProduct.eventProductId
													);
													if (existingEventProduct) {
														changeProductQuantityToCart(
															existingEventProduct,
															quantity
														);
													} else {
														if (shouldRedirectToSignIn() === true) {
															const redirectUrl = `/event/${eventId}`;
															navigate('/login', {
																state: { redirectUrl }
															});
															return;
														}
														buyNow(
															eventId,
															eventDetails,
															{ eventProduct, image, quantity },
															setSelectedBreaks,
															(item) => {
																dispatch(addCartItem(item));
															},
															(newBuyNowData) => {
																dispatch(setBuyNowData(newBuyNowData));
															}
														);
													}
													setShowProductModal(false);
												}}
												onAddToCart={(eventProduct, image, quantity) => {
													const existingEventProduct = cartItems.find(
														(f) => f.id == eventProduct.eventProductId
													);
													if (existingEventProduct) {
														changeProductQuantityToCart(
															existingEventProduct,
															quantity
														);
													} else {
														addProductToCart(eventProduct, image, quantity);
													}
													setShowProductModal(false);
												}}
												eventProduct={eventProduct}
											/>
										)}
										{userCanAddOrRemoveProduct
											&& showProductAccordion
											&& eventType == RIP_N_SHIP && (
											<>
												<EventProductsRemove
													onDelete={() => {
														getPageDetails(eventId);
													}}
												/>
												<EventDetailsAddProduct
													onAddClicked={async () => {
														try {
															const allProductsRes = await getAllEventProducts(eventId);
															setAllProducts(allProductsRes.data);
														} catch (err) {
															console.error('Failed to get all event products', err);
														}
													}}
													excludedProductIds={allProducts.map(
														(eventProduct) => eventProduct.productId
													)}
													onProductsAdded={() => {
														getPageDetails(eventId);
													}}
													eventId={eventId}
													isRipNShip={
														eventDetails.event.eventTypeId == RIP_N_SHIP
													}
												/>
											</>
										)}

										{eventType == RANDOMIZED || eventType == PICK_YOUR_SLOTS ? (
											<>
												{isSeller && eventType == RANDOMIZED && (
													<RandomizedBreakUnsoldSlotsModal
														showModal={showRandomizedBreakUnsoldSlotsWarning}
														setShowModal={
															setShowRandomizedBreakUnsoldSlotsWarning
														}
														onConfirm={() => navigate(`/event/room/${eventId}`)}
													/>
												)}
												{eventType == RANDOMIZED && (
													<>
														<div
															className="flex flex-row w-11/12 mx-auto text-left py-2"
															style={{ maxWidth: '95vw' }}
														>
															<Accordion title="Pre-randomized Tiers">
																{preRandomizedTiers && (
																	<table className="table-auto w-full">
																		<thead>
																			<tr>
																				{Object.keys(preRandomizedTiers).map(
																					(tier, index) => (
																						<th
																							className="whitespace-nowrap text-center py-1 border"
																							key={index}
																						>
																							Tier
																							{' '}
																							{tier}
																						</th>
																					)
																				)}
																			</tr>
																		</thead>
																		<tbody>
																			{Array(teamSize)
																				.fill(null)
																				.map((value, i) => (
																					<tr key={i}>
																						{Object.keys(
																							preRandomizedTiers
																						).map((tier, j) => (
																							<td
																								key={j}
																								className="whitespace-nowrap text-center py-1 border"
																							>
																								{' '}
																								{preRandomizedTiers[tier][i]
																									&& preRandomizedTiers[tier][i]
																										.team}
																							</td>
																						))}
																					</tr>
																				))}
																		</tbody>
																	</table>
																)}
															</Accordion>
														</div>

														<div
															className="flex flex-row w-11/12 mx-auto text-left py-2"
															style={{ maxWidth: '95vw' }}
														>
															<Accordion title="Randomized Results">
																{randomizations.length <= 0 && (
																	<div className="w-full border shadow-sm rounded-md border-t-0 p-2">
																		<p className="w-full text-center">
																			Randomization will occur once all slots
																			are filled
																		</p>
																	</div>
																)}

																{randomizations.length > 0 && (
																	<table className="table-auto w-full">
																		<thead>
																			<tr>
																				{randomizationCols.map(
																					(item, index) => (
																						<th
																							className="whitespace-nowrap text-center py-1 border"
																							key={index}
																						>
																							<div className="flex flex-row justify-center">
																								{sortRandomizations(
																									item,
																									randomizationColsForSorting[
																										index
																									]
																								)}
																							</div>
																						</th>
																					)
																				)}
																			</tr>
																		</thead>
																		<tbody>
																			{randomizations.map((object, i) => (
																				<tr key={i}>
																					{Object.keys(object).map(
																						(key, index) => (
																							<td
																								className="whitespace-nowrap text-center py-1 border"
																								key={index}
																							>
																								{object[key]}
																							</td>
																						)
																					)}
																				</tr>
																			))}
																		</tbody>
																	</table>
																)}
															</Accordion>
														</div>
													</>
												)}

												<div className="flex w-11/12 mx-auto text-left py-6 pt-2">
													<table
														className="table-fixed w-full text-left  border rounded-md shadow-md"
														style={{
															overflow: 'hidden'
														}}
													>
														<thead className="bg-red-600">
															<tr className="">
																<th className="w-1/12 md:w-2/12 p-4 text-left text-sm font-bold text-gray-100">
																	{sortBreaks('#', 'slot')}
																</th>
																<th className="w-3/12 md:w-4/12 p-4 text-left text-sm font-bold text-gray-100">
																	<div className="flex flex-row justify-items-start">
																		{sortBreaks(
																			eventType == PICK_YOUR_SLOTS
																				? 'Buyer'
																				: 'Name',
																			'buyerNickname'
																		)}
																	</div>
																</th>
																{eventType == PICK_YOUR_SLOTS && (
																	<th className="w-4/12 xxs:w-3/12 p-4 text-left text-sm font-bold text-gray-100">
																		{sortBreaks('Slot Name', 'slotName')}
																	</th>
																)}
																<th className="w-2/12 p-4 text-left text-sm font-bold text-gray-100">
																	{sortBreaks('Cost', 'slotPrice')}
																</th>
																<th className="w-3/12 p-4 text-left text-sm font-bold text-gray-100">
																	{' '}
																</th>
															</tr>
														</thead>

														<tbody className="text-gray-700 text-sm divide-y divide-gray-300">
															{eventBreaks
																&& eventBreaks.map((eventBreak, index) => (
																	<tr
																		className="bg-white font-medium text-sm divide-gray-200"
																		key={index}
																	>
																		<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
																			{eventBreak.slot}
																		</td>
																		<td className="px-4 xxs:px-4 py-2 w-24">
																			<ReactTooltip />
																			<EllipisWithTooltip
																				isParagraph
																				placement="bottom"
																				className="text-sm"
																				overridenStyle={{
																					whiteSpace: 'nowrap',
																					overflow: 'hidden',
																					textOverflow: 'ellipsis'
																				}}
																			>
																				{eventBreak.buyerNickname}
																			</EllipisWithTooltip>
																		</td>
																		{eventType == PICK_YOUR_SLOTS && (
																			<td className="px-4 xxs:px-4 py-2">
																				{eventBreak.slotName}
																			</td>
																		)}
																		<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
																			$
																			{eventBreak.slotPrice}
																		</td>

																		<td
																			className={clsx(
																				'px-4 xxs:px-4 py-2 text-center md:text-right',
																				eventBreak.slotStatus === 'Available'
																					&& eventDetails.buyNowEnabled
																					&& 'flex flex-col md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-1 items-center justify-center md:justify-end'
																			)}
																		>
																			{eventBreak.slotStatus
																				=== 'Available' && (
																				<>
																					{eventDetails
																					&& eventDetails.buyNowEnabled ? (
																							<button
																								type="button"
																								className={`w-full md:w-24 h-12 md:h-auto bg-blue-500 hover:bg-blue-700 text-gray-100 hover:text-gray-100 text-xs font-semibold py-2 rounded-md border-0 ${
																									isMobile
																								&& 'flex items-center justify-center'
																								}`}
																								onClick={() => {
																									if (
																										shouldRedirectToSignIn()
																									=== true
																									) {
																										const redirectUrl = `/event/${eventId}`;
																										navigate('/login', {
																											state: { redirectUrl }
																										});
																										return;
																									}
																									buyNow(
																										eventId,
																										eventDetails,
																										{ eventBreak },
																										setSelectedBreaks,
																										(item) => {
																											dispatch(addCartItem(item));
																										},
																										(newBuyNowData) => {
																											dispatch(
																												setBuyNowData(
																													newBuyNowData
																												)
																											);
																										}
																									);
																								}}
																							>
																							Buy Now
																							</button>
																						) : null}
																					<button
																						type="button"
																						className={clsx(
																							'w-full md:w-24 h-12 md:h-auto bg-red-500 hover:bg-red-700 text-gray-100 hover:text-gray-100 text-xs font-semibold py-2 rounded-md border-0',
																							isMobile
																								&& 'flex items-center justify-center'
																						)}
																						onClick={(e) => addItemToCart(e, eventBreak)}
																					>
																						Add to Cart
																					</button>
																				</>
																			)}
																			{eventBreak.slotStatus === 'Sold' && (
																				<button
																					type="button"
																					className={clsx(
																						'w-full md:w-24 h-12 md:h-auto bg-gray-500 opacity-70 hover:cursor-not-allowed text-gray-100 hover:text-gray-100 text-xs font-semibold px-4 xxs:px-2 py-2 rounded-md border-0 cursor-not-allowed',
																						isMobile
																							&& 'flex items-center justify-center'
																					)}
																					disabled
																				>
																					{eventBreak.slotStatus}
																				</button>
																			)}
																			{eventBreak.slotStatus
																				=== 'Unavailable' && (
																				<button
																					type="button"
																					className={clsx(
																						'w-full md:w-24 h-12 md:h-auto bg-gray-500 opacity-70 hover:cursor-not-allowed text-gray-100 hover:text-gray-100 text-xs font-semibold px-4 xxs:px-2 py-2 rounded-md border-0 cursor-not-allowed',
																						isMobile
																							&& 'flex items-center justify-center'
																					)}
																					disabled
																				>
																					{eventBreak.slotStatus}
																				</button>
																			)}
																			{eventBreak.slotStatus === 'Pending' && (
																				<button
																					type="button"
																					className={clsx(
																						'w-full md:w-24 h-12 md:h-auto bg-red-500 opacity-70 hover:cursor-not-allowed text-gray-100 hover:text-gray-100 text-xs font-semibold px-4 xxs:px-2 py-2 rounded-md border-0 cursor-not-allowed',
																						isMobile
																							&& 'flex items-center justify-center'
																					)}
																					disabled
																				>
																					{eventBreak.slotStatus}
																				</button>
																			)}
																		</td>
																	</tr>
																))}
														</tbody>
													</table>
												</div>
												{eventDetails.seller
													&& eventDetails.event.eventTypeId !== RIP_N_SHIP && (
													<>
														<SellerInformationAccordionPanel
															seller={eventDetails.seller}
															setIsStoreModalOpen={setIsStoreModalOpen}
														/>
														<div
															className="flex flex-row w-11/12 mx-auto text-left py-2"
															style={{ maxWidth: '95vw' }}
														>
															<Accordion title="Event Details" showAccordion>
																<div className="flex flex-row w-full p-4 border border-t-0 rounded-md shadow-sm whitespace-pre-line break-words">
																	{eventDescription}
																</div>
															</Accordion>
														</div>

														{!eventCompleted ? (
															<EventProducts
																products={eventDetails.products}
																onProductModalShow={onProductModalShow}
																showRemoveProductOption={
																	userCanAddOrRemoveProduct
																}
																showAccordion={showProductAccordion}
																setShowAccordion={setShowProductAccordion}
																showPrice={
																	eventDetails.event.eventTypeId == RIP_N_SHIP
																}
																showQuantity={
																	eventDetails.event.eventTypeId != RIP_N_SHIP
																}
															/>
														) : null}

														{userCanAddOrRemoveProduct
																&& showProductAccordion
																&& eventType !== RIP_N_SHIP && (
															<>
																<EventProductsRemove
																	onDelete={() => {
																		getPageDetails(eventId);
																	}}
																/>
																<EventDetailsAddProduct
																	excludedProductIds={eventDetails.products.map(
																		(eventProduct) => eventProduct.productId
																	)}
																	onProductsAdded={() => {
																		getPageDetails(eventId);
																	}}
																	eventId={eventId}
																	isRipNShip={
																		eventDetails.event.eventTypeId
																				== RIP_N_SHIP
																	}
																/>
															</>
														)}
													</>
												)}
											</>
										) : (
											<>
												{showProductSelectionMessage ? (
													<div className="flex flex-row w-11/12 mx-auto text-left py-2">
														<p>
															*All active products option is chosen while
															creating the event. All the available products
															will be displayed in the live event. In the list
															above you can see all available products as of
															now.
														</p>
													</div>
												) : null}
											</>
										)}
									</div>
								</div>
								<MiniCart
									isOpen={isCartOpen}
									removeItemDispatch={(item) => {
										dispatch(removeCartItem(item));
									}}
									updateTimerDispatch={(item) => {
										dispatch(updateCartItemTimer(item));
									}}
									closeCart={() => setIsCartOpen(!isCartOpen)}
									productName={eventName}
									items={cartItems}
									deletedProductsNotificationMessage={
										deletedProductsNotificationMessage
									}
									getPageDetails={() => {
										getPageDetails(eventId);
									}}
									cartItemLength={() => {
										let total = 0;
										cartItems.forEach((cartItem) => {
											if (cartItem.quantity) {
												total += cartItem.quantity;
											} else {
												total += 1;
											}
										});
										return total;
									}}
									addProductQuantityToCart={(product) => {
										changeProductQuantityToCart(product, 1);
									}}
									decreaseProductQuantityFromCart={(product) => changeProductQuantityToCart(product, -1)}
									isLoading={() => setLoadingActive()}
									onError={(item) => showItemRemoveError(item)}
								/>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</ProductsProvider>
	);
};

export default connect(
	(state) => ({
		cartItems: state.cart.cartItems,
		currentCheckoutSessionId: state.cart.currentCheckoutSessionId
	}),
	null
)(EventDetailsPage);
