import React, { createContext, useEffect, useRef } from 'react';
import useTwilioTracks from '../hooks/useTwilioTracks';

const TwilioTrackContext = createContext();

const TwilioTrackProvider = ({
	eventId,
	isHost,
	twilioRoomIdentity,
	setTwilioRoomIdentity,
	twilioRoomIdentityRef,
	hasSound,
	setHasSound,
	websocketGroupName,
	children
}) => {
	const {
		setLocalAudioTracks,
		twilioParticipantId,
		setTwilioParticipantId,
		requestedMicAccess,
		setNewAudioParticipant,
		setRequestedMicAccess,
		onRequestMicDecision,
		approveOrDeclineMicRequest,
		audioParticipants,
		setAudioParticipants,
		setCanceledMicRequestAccess,
		setNewCanceledMicRequestAccess,
		setExpiredMicAccessMessage,
		setApprovedAudioParticipants,
		micAccessApprovedBySeller,
		micAccessApprovedByBuyer,
		setMicAccessApprovedBySeller,
		setMicAccessApprovedByBuyer,
		muteOrUnmuteMicParticipants,
		setNewMutedAudioParticipants,
		closeMic,
		mutedByBuyer,
		mutedBySeller,
		buyerNotificationType,
		setBuyerNotificationType,
		deleteCurrentMicRequest,
		removeLocalMicAccess,
		hostAudioTrackMuted,
		setHostAudioTrackMuted,
		approvedAudioParticipants,
		removeAudioParticipantsFromApprovedAudioParticipants,
		requestingMic,
		setRequestingMic,
		cancellingMicRequest,
		setCancellingMicRequest,
		closingMic,
		setClosingMic,
		mutingMic,
		setMutingMic,
		usersApprovingMic,
		setUsersApprovingMic,
		usersDecliningMic,
		setUsersDecliningMic,
		currentUserIsOnMic
	} = useTwilioTracks(eventId, isHost, twilioRoomIdentity, websocketGroupName);
	const hasSoundRef = useRef(hasSound);

	useEffect(() => {
		hasSoundRef.current = hasSound;
	}, [hasSound]);

	return (
		<TwilioTrackContext.Provider
			value={{
				setLocalAudioTracks,
				twilioParticipantId,
				setTwilioParticipantId,
				requestedMicAccess,
				setNewAudioParticipant,
				setRequestedMicAccess,
				onRequestMicDecision,
				approveOrDeclineMicRequest,
				audioParticipants,
				setAudioParticipants,
				setCanceledMicRequestAccess,
				setNewCanceledMicRequestAccess,
				setExpiredMicAccessMessage,
				setApprovedAudioParticipants,
				micAccessApprovedBySeller,
				micAccessApprovedByBuyer,
				setMicAccessApprovedBySeller,
				setMicAccessApprovedByBuyer,
				muteOrUnmuteMicParticipants,
				setNewMutedAudioParticipants,
				closeMic,
				mutedByBuyer,
				mutedBySeller,
				buyerNotificationType,
				setBuyerNotificationType,
				deleteCurrentMicRequest,
				removeLocalMicAccess,
				hostAudioTrackMuted,
				setHostAudioTrackMuted,
				approvedAudioParticipants,
				removeAudioParticipantsFromApprovedAudioParticipants,
				requestingMic,
				setRequestingMic,
				cancellingMicRequest,
				setCancellingMicRequest,
				closingMic,
				setClosingMic,
				mutingMic,
				setMutingMic,
				usersApprovingMic,
				setUsersApprovingMic,
				usersDecliningMic,
				setUsersDecliningMic,
				hasSound,
				setHasSound,
				twilioRoomIdentity,
				setTwilioRoomIdentity,
				twilioRoomIdentityRef,
				currentUserIsOnMic,
				hasSoundRef
			}}
		>
			{children}
		</TwilioTrackContext.Provider>
	);
};

export { TwilioTrackContext, TwilioTrackProvider };
