import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { css } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import BounceLoader from 'react-spinners/BounceLoader';
import { Link, navigate } from 'gatsby';
import HeaderHome from '../../../components/common/header-home';
import Layout from '../../../components/layouts/layout-default';
import {
	createShipmentAndGetRates,
	getDefaultParcelSize,
	buyLabels
} from '../../../api/shipment.request';
import ShipmentLabelTable from '../../../containers/shipment/shipment-label-table';
import Button from '../../../components/button';
import ShipmentSummary from '../../../containers/shipment/shipment-summary';
import ConfirmationModal from '../../../components/confirmation-modal/index';
import useDebounce from '../../../hooks/useDebounce';
import useErrorNotifier from '../../../hooks/use-error-notifier';
import { getSellerProfile } from '../../../api/seller.request';
import {
	convertPoundsAndOuncesToPounds,
	convertPoundsToPoundsAndOunces
} from '../../../utils/weight';
import { MAX_URI_CHARACTER_LENGTH } from '../../../utils/constants/uri-limitations';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const ShipmentLabelPage = ({ location }) => {
	const [ratesLoading, setRatesLoading] = useState(true);
	const [loading, setLoading] = useState(true);
	const [shipments, setShipments] = useState([]);
	const [buyLabelDisabled, setBuyLabelDisabled] = useState(false);
	const [sellerAddress, setSellerAddress] = useState({
		mailingAddress1: '',
		mailingAddress2: null,
		mailingCity: '',
		mailingState: '',
		mailingZip: ''
	});
	const [labelCreating, setLabelCreating] = useState(false);
	const [parcelsDimensions, setParcelsDimensions] = useState([]);
	const [getRates, setGetRates] = useState(false);
	const { showToastError } = useErrorNotifier();
	const debouncedParcelDimensions = useDebounce(parcelsDimensions, 200);

	const [
		showCreateLabelConfirmationModal,
		setShowCreateLabelConfirmationModal
	] = useState(false);

	useEffect(() => {
		if (!location.state) return;
		setLoading(true);
		let labelsToFetchDefaultParcelSize = location.state.selectedItems.map(
			(item, index) => ({
				label: index + 1,
				orderDetailIds: item.orderDetails.map(
					(orderDetail) => orderDetail.orderDetailsId
				)
			})
		);

		/*
		If the length of the uri encoded string is greater than 2000,
		we need to split the request into a smaller, though there is a "delay" as we now need to send the potentialGroupedShipmentId then we process the orderDetailIds from the backend
		*/

		if (
			JSON.stringify(labelsToFetchDefaultParcelSize).length
			> MAX_URI_CHARACTER_LENGTH
		) {
			labelsToFetchDefaultParcelSize = location.state.selectedItems.map(
				(item, index) => ({
					label: index + 1,
					potentialGroupedShipmentId: item.potentialGroupedShipmentId
				})
			);
		}

		const fetchSellerAddress = async () => {
			try {
				const sellerProfileRes = await getSellerProfile(false);
				const address = {
					mailingAddress1: sellerProfileRes.data.mailingAddress1,
					mailingAddress2: sellerProfileRes.data.mailingAddress2,
					mailingCity: sellerProfileRes.data.mailingCity,
					mailingState: sellerProfileRes.data.state,
					mailingZip: sellerProfileRes.data.postalCode
				};

				setSellerAddress(address);
			} catch (err) {
				console.error(err);
			}
		};

		const fetchShipmentsDefaultWeight = async (labels) => {
			try {
				const parcelSizesRes = await getDefaultParcelSize(labels);
				setParcelsDimensions(
					parcelSizesRes.data.map((parcelSize) => {
						const size = {
							label: parcelSize.label,
							height: parcelSize.height,
							width: parcelSize.width,
							length: parcelSize.length
						};

						const { pounds, ounces } = convertPoundsToPoundsAndOunces(
							parcelSize.labelWeight.totalWeight
						);
						size.pounds = pounds;
						size.ounces = ounces;
						return size;
					})
				);
				const shipmentsWithWeight = location.state.selectedItems.map(
					(label) => {
						const orderDetails = label.orderDetails.map((orderDetail) => {
							const labelWeightWithOrderDetails = parcelSizesRes.data.find(
								(labelWeight) => labelWeight.labelWeight.orderDetailWeights
									.map(
										(orderDetailWeight) => orderDetailWeight.orderDetailsId
									)
									.includes(orderDetail.orderDetailsId)
							);
							if (
								!labelWeightWithOrderDetails
								|| !labelWeightWithOrderDetails.labelWeight
								|| !labelWeightWithOrderDetails.labelWeight.orderDetailWeights
								|| !labelWeightWithOrderDetails.labelWeight.orderDetailWeights
									.length
							) return orderDetail;
							const foundOrderDetailWeight = labelWeightWithOrderDetails.labelWeight.orderDetailWeights.find(
								(orderDetailWeight) => orderDetailWeight.orderDetailsId
									=== orderDetail.orderDetailsId
							);
							if (!foundOrderDetailWeight) return orderDetail;
							orderDetail.weight = foundOrderDetailWeight.weight;
							return orderDetail;
						});

						label.orderDetails = orderDetails;
						return label;
					}
				);
				setShipments(shipmentsWithWeight);
			} catch (err) {
				console.error(err);
			} finally {
				setGetRates(true);
				setLoading(false);
			}
		};

		fetchSellerAddress();
		fetchShipmentsDefaultWeight(labelsToFetchDefaultParcelSize);
	}, [location.state]);

	const onCreateLabel = async () => {
		setLabelCreating(true);
		const shipmentData = {
			shipments: shipments.map((shipment, index) => {
				const parcelDimension = debouncedParcelDimensions.find(
					(parcel) => parcel.label === index + 1
				);
				if (!parcelDimension) return null;

				return {
					buyerId: shipment.buyer.buyerId,
					orderDetailIds: shipment.orderDetails.map(
						(orderDetail) => orderDetail.orderDetailsId
					),
					buyerName: shipment.buyer.buyerName,
					buyerMailingAddress1: shipment.buyer.buyerMailingAddress1,
					buyerMailingAddress2: shipment.buyer.buyerMailingAddress2,
					buyerMailingCity: shipment.buyer.buyerMailingCity,
					buyerMailingState: shipment.buyer.buyerMailingState,
					buyerMailingZip: shipment.buyer.buyerMailingZip,
					rateId: shipment.rateId,
					potentialGroupedShipmentId: shipment.potentialGroupedShipmentId,
					pounds: convertPoundsAndOuncesToPounds(
						parcelDimension.pounds,
						parcelDimension.ounces
					),
					length: parcelDimension.length,
					height: parcelDimension.height,
					width: parcelDimension.width
				};
			})
		};
		try {
			const createdLabelsRes = await buyLabels(shipmentData);
			if (createdLabelsRes.data && createdLabelsRes.data) {
				navigate('/shipments', {
					state: {
						potentialGroupedShipmentIdsToPrint: shipmentData.shipments.map(
							(shipment) => shipment.potentialGroupedShipmentId
						)
					}
				});
			}
		} catch (err) {
			console.error(err);
		}
		setLabelCreating(false);
		setShowCreateLabelConfirmationModal(false);
	};

	useEffect(() => {
		if (!getRates || !shipments.length) return;
		if (!debouncedParcelDimensions || !debouncedParcelDimensions.length) return;
		setRatesLoading(true);
		const invalidParcelDimensions = debouncedParcelDimensions.find((parcel) => {
			if (!parcel) return true;
			const nonWeightDimensionsMissing =				!parcel.length || !parcel.width || !parcel.height;
			if (nonWeightDimensionsMissing) return true;
			const weightMissing = !parcel.pounds && !parcel.ounces;
			if (weightMissing || (parcel.pounds == 0 && parcel.ounces == 0)) return true;
			return false;
		});
		if (invalidParcelDimensions && invalidParcelDimensions.length) {
			setShipments((prevShipments) => prevShipments.map((shipment) => ({
				...shipment,
				rate: 0,
				rateId: null
			})));
			setRatesLoading(false);
			setBuyLabelDisabled(true);
			return;
		}
		setBuyLabelDisabled(false);
		createShipmentAndGetRates({
			shipments: shipments.map((shipment, index) => {
				const parcelDimension = debouncedParcelDimensions.find(
					(parcel) => parcel.label === index + 1
				);
				if (!parcelDimension) return null;
				return {
					buyerId: shipment.buyer.buyerId,
					buyerMailingAddress1: shipment.buyer.buyerMailingAddress1,
					buyerMailingAddress2: shipment.buyer.buyerMailingAddress2,
					buyerMailingCity: shipment.buyer.buyerMailingCity,
					buyerMailingState: shipment.buyer.buyerMailingState,
					buyerMailingZip: shipment.buyer.buyerMailingZip,
					buyerName: shipment.buyer.buyerName,
					pounds: convertPoundsAndOuncesToPounds(
						parcelDimension.pounds,
						parcelDimension.ounces
					),
					length: parcelDimension.length,
					height: parcelDimension.height,
					width: parcelDimension.width
				};
			})
		})
			.then((ratesRes) => {
				if (ratesRes.data && ratesRes.data && ratesRes.data.length) {
					setShipments((prevShipments) => prevShipments.map((shipment) => {
						const shipmentRate = ratesRes.data.find(
							(rate) => rate.buyerId === shipment.buyer.buyerId
									&& rate.buyerAddress == shipment.buyer.buyerMailingAddress1
						);
						const cheapestRate = shipmentRate.rates.sort(
							(a, b) => a.amount - b.amount
						)[0];

						return {
							...shipment,
							rate: cheapestRate.amount,
							rateId: cheapestRate.object_id
						};
					}));
				}
			})
			.catch((err) => {
				setBuyLabelDisabled(true);
				setShipments((prevShipments) => prevShipments.map((shipment) => ({
					...shipment,
					rate: 0,
					rateId: null
				})));
				showToastError(err);
				console.error(err);
			})
			.finally(() => {
				setRatesLoading(false);
			});
	}, [getRates, debouncedParcelDimensions, shipments.length]);

	if (loading) {
		return (
			<div className="flex h-full items-center">
				<BounceLoader loading css={override} size={30} />
			</div>
		);
	}
	return (
		<Layout auth>
			<ReactTooltip />
			<div className="flex flex-col flex-1 bg-white w-full">
				<HeaderHome hideSearch setLoading={setLoading} />
				<div className="flex flex-col flex-grow w-full h-full m-4 items-center">
					<ToastContainer />
					<ConfirmationModal
						showModal={showCreateLabelConfirmationModal}
						setShowModal={setShowCreateLabelConfirmationModal}
						onCancel={() => setShowCreateLabelConfirmationModal(false)}
						onConfirm={() => onCreateLabel()}
						text="Label(s) will be purchased and your account will be charged. Do you want to continue?"
						confirmText="Yes"
						cancelText="No"
						showConfirmationIcon={false}
						confirmButtonLoading={labelCreating}
					/>
					<div className="flex flex-col rounded-lg content-center justify-center w-full">
						<div className="w-full justify-center pb-8">
							<div className="flex">
								<div style={{ flex: '1 0 73%' }}>
									<div>
										{shipments.map((shipment, index) => {
											const parcelDimension = parcelsDimensions.find(
												(parcel) => parcel.label == index + 1
											);
											if (!parcelDimension) return null;
											return (
												<ShipmentLabelTable
													labelNumber={index + 1}
													parcelHeight={parcelDimension.height}
													parcelWidth={parcelDimension.width}
													parcelLength={parcelDimension.length}
													parcelPounds={parcelDimension.pounds}
													parcelOunces={parcelDimension.ounces}
													setParcelHeight={(newHeight) => {
														setParcelsDimensions((oldDimensions) => oldDimensions.map((old) => {
															if (old.label === parcelDimension.label) {
																return {
																	...old,
																	height: newHeight
																};
															}
															return old;
														}));
													}}
													setParcelPounds={(newPounds) => {
														setParcelsDimensions((oldDimensions) => oldDimensions.map((old) => {
															if (old.label === parcelDimension.label) {
																return {
																	...old,
																	pounds: newPounds
																};
															}
															return old;
														}));
													}}
													setParcelOunces={(newOunces) => {
														setParcelsDimensions((oldDimensions) => oldDimensions.map((old) => {
															if (old.label === parcelDimension.label) {
																return {
																	...old,
																	ounces: newOunces
																};
															}
															return old;
														}));
													}}
													setParcelLength={(newLength) => {
														setParcelsDimensions((oldDimensions) => oldDimensions.map((old) => {
															if (old.label === parcelDimension.label) {
																return {
																	...old,
																	length: newLength
																};
															}
															return old;
														}));
													}}
													setParcelWidth={(newWidth) => {
														setParcelsDimensions((oldDimensions) => oldDimensions.map((old) => {
															if (old.label === parcelDimension.label) {
																return {
																	...old,
																	width: newWidth
																};
															}
															return old;
														}));
													}}
													removeEnabled={shipments.length > 1}
													onRemove={() => {
														setShipments((prevShipments) => prevShipments.filter(
															(sh) => sh.potentialGroupedShipmentId
																	!= shipment.potentialGroupedShipmentId
														));
														setParcelsDimensions((prevDimensions) => {
															const newDimensions = prevDimensions.filter(
																(parcel) => parcel.label != index + 1
															);
															return newDimensions.map((parcel, i) => ({
																...parcel,
																label: i + 1
															}));
														});
													}}
													shipment={shipment}
													key={shipment.potentialGroupedShipmentId}
												/>
											);
										})}
									</div>
								</div>
								<div className="w-full h-full pr-8 pl-3 sticky top-20">
									<div className="h-full flex flex-col">
										<div className="w-full flex justify-end pb-3">
											<Link to="/shipments">
												<Button
													additionalClassName="flex items-center justify-center px-4"
													height="9"
													width="full"
													mobileHeight="8"
												>
													<span className="rounded-xl bg-black mr-2">
														<svg
															className="w-6 h-6"
															fill="none"
															stroke="currentColor"
															viewBox="0 0 24 24"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth="3"
																d="M15 19l-7-7 7-7"
															/>
														</svg>
													</span>
													Shipment view
												</Button>
											</Link>
										</div>
										<div className="h-auto">
											<ShipmentSummary
												shipments={shipments}
												loading={ratesLoading}
												sellerAddress={sellerAddress}
												buyLabelDisabled={buyLabelDisabled}
												onCreateLabel={() => {
													const shipmentsWithProductsWithNoWeight = shipments.filter(
														(shipment) => shipment.orderDetails.filter(
															(orderDetail) => orderDetail.weight == null
																	|| orderDetail.weight == 0
														).length > 0
													);
													if (shipmentsWithProductsWithNoWeight.length) {
														showToastError(
															'One of the products does not have weight, please update it and try again.'
														);
														return;
													}
													setShowCreateLabelConfirmationModal(true);
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ShipmentLabelPage;
