const useNavigatorDevices = () => {
	const stop = async () => {
		const userMedia = await navigator.mediaDevices.getUserMedia({
			audio: true,
			video: true
		});
		if (!userMedia) return;
		const videoTracks = userMedia.getVideoTracks();
		if (videoTracks && videoTracks.length) userMedia.getVideoTracks()[0].stop();

		const audioTracks = userMedia.getAudioTracks();
		if (audioTracks && audioTracks.length) userMedia.getAudioTracks()[0].stop();
	};

	return { stop };
};

export default useNavigatorDevices;
