import React from 'react';
import ShipmentSummaryItem from './shipment-summary-item';
import Button from '../../components/button';
import ShipmentSummarySkeletonItem from './shipment-summary-skeleton';

const ShipmentSummary = ({
	shipments,
	onCreateLabel,
	loading,
	sellerAddress,
	buyLabelDisabled
}) => (
	<div className="border border-gray-300 p-4 h-full shadow-md rounded-md">
		<h4 className="pb-4">Summary</h4>
		{loading ? (
			<>
				{shipments.map((shipment, i) => (
					<ShipmentSummarySkeletonItem key={i} />
				))}
			</>
		) : (
			<div className="flex flex-col justify-between">
				{shipments.map((shipment, i) => (
					<ShipmentSummaryItem
						sellerAddress={sellerAddress}
						shipment={shipment}
						key={i}
					/>
				))}
				<div className="inline-flex justify-between w-full">
					<p className="font-bold">Total: </p>
					<p>
						$
						{' '}
						{shipments
							&& shipments.length
							&& shipments
								.reduce(
									(previousValue, currentValue) => previousValue
										// eslint-disable-next-line no-nested-ternary
										+ (currentValue && currentValue.rate
											? Number.isNaN(currentValue.rate)
												? 0
												: currentValue.rate
											: 0),
									0
								)
								.toFixed(2)}
					</p>
				</div>
			</div>
		)}
		{loading ? null : (
			<div className="flex justify-center items-center py-3">
				<Button
					width="36"
					variant="secondary"
					height="10"
					mobileHeight="8"
					disabled={buyLabelDisabled}
					onClick={onCreateLabel}
				>
					Buy Labels
					{' '}
				</Button>
			</div>
		)}
	</div>
);

export default ShipmentSummary;
