import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import clsx from 'clsx';

const ButtonComponent = ({
	children,
	variant,
	onClick,
	width,
	mobileWidth,
	height,
	mobileHeight,
	additionalClassName,
	outline,
	hasArrowIcon,
	textSize,
	type = 'button',
	...restProps
}) => (
	<button
		// eslint-disable-next-line react/button-has-type
		type={type}
		className={clsx(
			`btn btn--${variant} btn-shrink w-${mobileWidth} md:w-${width} h-${mobileHeight} md:h-${height} text-${textSize}`,
			outline && 'btn--outline',
			hasArrowIcon && 'btn--arrow',
			additionalClassName
		)}
		{...restProps}
		onClick={onClick}
		onKeyDown={onClick}
	>
		{hasArrowIcon ? (
			<span>
				{children}
				{' '}
				<svg
					version="1.1"
					id="Layer_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					viewBox="0 0 36.1 25.8"
					enableBackground="new 0 0 36.1 25.8"
					xmlSpace="preserve"
				>
					<g>
						<line
							fill="none"
							stroke="#FFFFFF"
							strokeWidth="3"
							strokeMiterlimit="10"
							x1="0"
							y1="12.9"
							x2="34"
							y2="12.9"
						/>
						<polyline
							fill="none"
							stroke="#FFFFFF"
							strokeWidth="3"
							strokeMiterlimit="10"
							points="22.2,1.1 34,12.9 22.2,24.7   "
						/>
					</g>
				</svg>
			</span>
		) : (
			children
		)}
	</button>
);

const Button = ({
	children,
	variant = 'primary',
	onClick,
	width = '20',
	mobileWidth = 'full',
	height = '12',
	mobileHeight = 'auto',
	outline = false,
	additionalClassName = '',
	tooltipMessage = '',
	textSize = 'md',
	hasArrowIcon = false,
	...restProps
}) => {
	if (tooltipMessage) {
		return (
			<>
				<ReactTooltip place="top" />
				<div data-tip={tooltipMessage}>
					<ButtonComponent
						width={width}
						mobileWidth={mobileWidth}
						height={height}
						mobileHeight={mobileHeight}
						variant={variant}
						onClick={onClick}
						outline={outline}
						textSize={textSize}
						hasArrowIcon={hasArrowIcon}
						additionalClassName={additionalClassName}
						{...restProps}
					>
						{children}
					</ButtonComponent>
				</div>
			</>
		);
	}
	return (
		<ButtonComponent
			width={width}
			mobileWidth={mobileWidth}
			height={height}
			mobileHeight={mobileHeight}
			onClick={onClick}
			variant={variant}
			textSize={textSize}
			outline={outline}
			hasArrowIcon={hasArrowIcon}
			additionalClassName={additionalClassName}
			{...restProps}
		>
			{children}
		</ButtonComponent>
	);
};

Button.propTypes = {
	type: PropTypes.string,
	onClick: PropTypes.func,
	width: PropTypes.string,
	mobileWidth: PropTypes.string,
	height: PropTypes.string,
	mobileHeight: PropTypes.string,
	textSize: PropTypes.string,
	additionalClassName: PropTypes.string,
	rounded: PropTypes.bool,
	roundedSize: PropTypes.string
};

export default Button;
