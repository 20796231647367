import React, { useContext } from 'react';
import SearchInput from '../../../components/input/search-input';
import { LiveEventDataContext } from '../../../contexts/live-event-data';
import { sellerAttendeeFilterOptions } from '../../../utils/seller-attendees-filter-options';

const SellerAttendeesFilter = () => {
	const {
		attendeesSearchValue,
		setAttendeesSearchValue,
		attendeesFilterSelectedOption,
		setAttendeesFilterSelectedOption
	} = useContext(LiveEventDataContext);

	return (
		<div className="relative text-gray-600 dark:bg-darkGray-50 dark:border-black w-full md:w-auto mt-3 mb-3 space-x-1 flex justify-center">
			<SearchInput
				value={attendeesSearchValue}
				setValue={setAttendeesSearchValue}
				searchIconLocation="left"
				widthClassName="md:w-full"
			/>
			<div className="flex row dark:border-black md:w-full">
				<select
					id="product_status"
					name="product_status"
					autoComplete="event_type"
					className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border dark:border-black bg-white dark:bg-darkGray-50 border-gray-200 dark:text-white rounded-md shadow-md mb-1"
					onBlur={(e) => setAttendeesFilterSelectedOption(e.target.value)}
					onChange={(e) => setAttendeesFilterSelectedOption(e.target.value)}
					value={attendeesFilterSelectedOption}
				>
					{sellerAttendeeFilterOptions.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default SellerAttendeesFilter;
