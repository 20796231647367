import axiosConfig, { getConfig } from './config';

export const getShipmentList = async (shippingLabelStatusId, page, perPage, searchValue) => {
	const config = getConfig();
	config.params = {
		page, per_page: perPage, searchValue: searchValue ? searchValue.toLowerCase() : searchValue, shippingLabelStatusId
	};
	return await axiosConfig.get('shipment/list', config);
};

export const createShipmentAndGetRates = async (data) => await axiosConfig.post('shipment/rates', data);
export const getDefaultParcelSize = async (labels) => await axiosConfig.get(`shipment/default-parcel-sizes?labelsQuery=${JSON.stringify(labels)}`);
export const buyLabels = async (shipmentData) => await axiosConfig.post('shipment/buy-label', shipmentData);
export const getLabelUrl = async (transactionId) => await axiosConfig.get(`shipment/label/url/${transactionId}`);
export const getShipmentTransactionsByPotentialGroupedShipmentIds = async (potentialGroupedShipmentIds) => {
	const config = getConfig();
	config.params = {
		potentialGroupedShipmentIds
	};
	return await axiosConfig.get('shipment/transactions', config);
};
export const getShipmentTotal = async (transactionId) => await axiosConfig.get(`shipment/total/${transactionId}`);
export const hasAccessToShipmentTransaction = async (transactionId) => await axiosConfig.get(`shipment/access/transaction/${transactionId}`);
export const updatePotentialGroupedShipmentsStatus = async (potentialGroupedShipmentIds, shippingLabelStatusId) => await axiosConfig.put('shipment/update/status', {
	shippingLabelStatusId, potentialGroupedShipmentIds
});
