export const DEFAULT_STYLES = {
	table: {
		style: {
			border: '1px #a7a6a6 solid'
		}
	},
	headCells: {
		style: {
			backgroundColor: '#e82e2c',
			color: 'white',
			fontSize: '0.75rem',
			fontWeight: 'bold'
		}
	}
};
