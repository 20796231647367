/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

const LineHistoryEventQueue = ({
	historyBuyers,
	openPurchasedItemsModalByQueueOrderId
}) => (
	<>
		<table className="table-fixed text-left w-full">
			<thead>
				<tr>
					<th className="w-1/12 p-4 text-left text-sm font-bold text-gray-100 bg-djawn">
						#
					</th>
					<th className="w-3/12 p-4 text-left text-sm font-bold text-gray-100 bg-djawn">
						Screen name
					</th>
					<th className="w-1/12 p-4 text-left text-sm font-bold text-gray-100 bg-djawn" />
				</tr>
			</thead>
			<tbody className="text-gray-800 text-sm dark:text-white dark:bg-darkGray-50">
				{historyBuyers.map((buyer, index) => (
					<tr>
						<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
							{index + 1}
						</td>
						<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
							{buyer.nickname}
						</td>
						{buyer.purchasedItem && (
							<td
								className="px-4 xxs:px-4 py-2 whitespace-nowrap cursor-pointer"
								onClick={() => {
									openPurchasedItemsModalByQueueOrderId(buyer.queueOrderId);
								}}
								onKeyDown={() => {
									openPurchasedItemsModalByQueueOrderId(buyer.queueOrderId);
								}}
							>
								<svg
									className="w-4 h-4"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
							</td>
						)}
					</tr>
				))}
			</tbody>
		</table>
	</>
);

export default LineHistoryEventQueue;
