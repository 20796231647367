import React, { useContext } from 'react';
import LinkedEventSlider from '../../../components/linked-event-slider';
import { LiveEventDataContext } from '../../../contexts/live-event-data';

const LinkedEventsSliderContainer = () => {
	const { linkedEvents } = useContext(LiveEventDataContext);

	if (!linkedEvents || !linkedEvents.length) return null;
	return (
		<div className="flex items-center mt-3">
			<h4 className="w-36 text-black dark:text-white">Linked events</h4>
			<LinkedEventSlider
				className="flex-1 shadow-md bg-white dark:bg-darkGray-100 border-radius-25px w-48"
				linkedEvents={linkedEvents}
			/>
		</div>
	);
};

export default LinkedEventsSliderContainer;
