import { useEffect, useState, useCallback } from 'react';
import { isSafari, isMobile } from 'react-device-detect';
import useAudio from './useAudio';
import { getWebSocketSoundNotifications } from '../api/websocket-sound-notification.request';

const useSoundNotification = (isSeller, isMuted) => {
	const { playAudio } = useAudio();
	const [
		webSocketSoundNotifications,
		setWebSocketSoundNotifications
	] = useState([]);

	const playSoundNotificationSound = useCallback(
		(webSocketMessage) => {
			if (isMuted || !webSocketMessage) return;
			const notificationSound = webSocketSoundNotifications.find(
				(p) => p.webSocketMessage == webSocketMessage
			);
			if (!notificationSound) return;

			const shouldPlaySound =				notificationSound.notifyAllBuyers
				|| (notificationSound.notifySeller && isSeller);
			if (shouldPlaySound) {
				if (isSafari && isMobile) {
					const audioEl = document.getElementById(
						`notification-audio-${notificationSound.webSocketMessage}`
					);
					if (audioEl) audioEl.play();
				} else {
					playAudio(notificationSound.soundUrl);
				}
			}
		},
		[webSocketSoundNotifications, isMuted, isSeller, isSafari, isMobile]
	);

	const playDefaultAudio = () => {
		playAudio();
	};

	useEffect(() => {
		getWebSocketSoundNotifications()
			.then((res) => {
				setWebSocketSoundNotifications(res.data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	// in ios safari we are not able to play audio without user interaction,
	// so we need to preload the audio elements when a user clicks the button then play them
	const onCreateAudioElements = useCallback(() => {
		if (!webSocketSoundNotifications || !webSocketSoundNotifications.length) return;
		webSocketSoundNotifications.forEach((webSocketSoundNotification) => {
			const audioEl = document.createElement('audio');
			audioEl.id = `notification-audio-${webSocketSoundNotification.webSocketMessage}`;
			audioEl.src = webSocketSoundNotification.soundUrl;
			audioEl.load();
			document.body.appendChild(audioEl);
		});
	}, [webSocketSoundNotifications]);

	return {
		playSoundNotificationSound,
		playDefaultAudio,
		onCreateAudioElements
	};
};

export default useSoundNotification;
