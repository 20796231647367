import axiosConfig from './config';

export const getSportTeams = async (sportTypeId) => await axiosConfig.get(`sport/teams/${sportTypeId}`);
export const referenceData = async (referenceTypeId) => await axiosConfig.get(`referencedata/reference-type/${referenceTypeId}`);
export const getProductStatuses = async () => await axiosConfig.get('referencedata/reference-type/23');
export const getRipNShipShippingTypes = async () => await axiosConfig.get('referencedata/reference-type/50');
export const getRipNShipShippingCards = async () => await axiosConfig.get('referencedata/reference-type/38');
export const getError = async (errorCode) => await axiosConfig.get(`referencedata/error/${errorCode}`);
export const getDjawnUser = async () => await axiosConfig.get('referencedata/user/djawn');
export const getReferenceDataById = async (referenceDataId) => await axiosConfig.get(`referencedata/${referenceDataId}`);
