import clsx from 'clsx';
import React, { forwardRef, useState } from 'react';

import AutoComplete from 'react-google-autocomplete';
import env from '../../../environment.config';
import ErrorFieldMessage from '../error-field-message';

const AddressAutocompleteInput = forwardRef(
	(
		{
			width = 'full',
			variant = 'primary',
			mobileWidth = 'full',
			height = '10',
			mobileHeight = 'auto',
			textSize = 'sm',
			additionalClassName = '',
			floatingLabelText = '',
			floatingLabelClassName = '',
			onFocus = () => {},
			error = '',
			disabled = false,
			invisibleValue = '',
			...restProps
		},
		ref
	) => {
		const [useFloatingLabelClassName, setUseFloatingLabelClassName] = useState(
			false
		);

		return (
			<div
				className={clsx(
					floatingLabelText && floatingLabelText.length > 0
						? `relative w-${mobileWidth} md:w-${width}`
						: 'w-full',
					(ref.current
						&& ref.current.value
						&& ref.current
						&& ref.current.value.length > 0)
						|| (invisibleValue && invisibleValue.length > 0)
						|| useFloatingLabelClassName
						? floatingLabelClassName
						: ''
				)}
			>
				<AutoComplete
					ref={ref}
					apiKey={env.google_places_key}
					className={clsx(
						`input input--${variant}`,
						floatingLabelText && floatingLabelText.length > 0
							? 'w-full placeholder-transparent'
							: `w-${mobileWidth} md:w-${width}`,
						`h-${mobileHeight} md:h-${height} text-${textSize}`,
						!error && additionalClassName,
						error && 'input--invalid'
					)}
					onFocus={() => {
						if (ref.current && floatingLabelClassName) {
							setUseFloatingLabelClassName(true);
						}

						onFocus();
					}}
					disabled={disabled}
					{...restProps}
				/>
				{floatingLabelText && floatingLabelText.length ? (
					<label
						tabIndex={-3}
						onClick={() => ref.current.focus()}
						onKeyDown={() => ref.current.focus()}
						htmlFor="email"
						className={clsx(
							'input--floating-label',
							((ref.current
								&& ref.current.value
								&& ref.current
								&& ref.current.value.length > 0)
								|| (invisibleValue && invisibleValue.length > 0))
								&& 'input--floating-label--active',
							disabled && 'input--floating-label--disabled'
						)}
						disabled={disabled}
					>
						{floatingLabelText}
					</label>
				) : null}
				{error && <ErrorFieldMessage error={error} />}
			</div>
		);
	}
);

export default AddressAutocompleteInput;
