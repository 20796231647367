/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import clsx from 'clsx';
import MiniEventTimer from '../common/mini-event-timer';
import { defaultToastError } from '../../utils/toast-error';
import CheckboxInput from '../input/checkbox-input';

const clipLoaderCss = css`
	display: block;
	border-color: white;
	border-bottom-color: transparent;
`;

const CheckoutPayment = ({
	setActiveStep,
	timerObject,
	paymentOrder,
	closeCheckout,
	mailingAddress1,
	mailingAddress2,
	mailingCity,
	mailingZip,
	mailingState,
	firstName,
	lastName,
	onCheckout,
	checkoutUrl,
	setCheckoutUrl,
	liveEvent
}) => {
	const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
	const [proceedToCheckoutClicked, setProceedToCheckoutClicked] = useState(
		false
	);
	const [payNowLoading, setPayNowLoading] = useState(false);

	return (
		<div className="flex-grow px-0 py-2 flex flex-col items-between dark:text-white">
			<div className="flex flex-col justify-center items-center content-center mt-1 relative rounded-md shadow-sm md:px-20 md:py-4 w-full lg:w-1/2 mx-auto">
				<div className="border-2 rounded-lg shadow-md px-5 py-5">
					<div>
						<div>
							<p>
								Your order value is
								{' '}
								<span className="font-bold">
									$
									{Number(paymentOrder.total).toFixed(2)}
								</span>
							</p>
						</div>
						{timerObject && timerObject.time && (
							<span className="text-red-500">
								<MiniEventTimer
									date={timerObject.time}
									breakId={timerObject.id}
									item={timerObject}
									timerEnded={() => {
										defaultToastError({
											shortMessage: 'Purchase not finalized',
											longMessage:
												'You have not finalized your purchase within the allowed time and the items have been removed from your cart.'
										});

										closeCheckout();
									}}
									text="Time left to finalise purchase"
								/>
							</span>
						)}
					</div>
					<div>
						<label className="flex items-center font-bold pt-4">
							<CheckboxInput
								className="h-7 w-7"
								checked={hasAgreedToTerms}
								onChange={() => setHasAgreedToTerms((prev) => !prev)}
							/>
							<a
								className="underline link md:ml-2 ml-5"
								href="https://support.djawn.com/terms-of-service"
								target="_blank"
							>
								<span>I have read and agree with the terms of service</span>
							</a>
						</label>
					</div>
					<div
						className={`flex flex-col items-center pb-5 pt-5 ${
							!hasAgreedToTerms ? 'hidden' : 'block'
						}`}
					>
						{/* {!stripeClientSecret && <p className="pb-5">Select Payment</p>} */}
						<div className="w-full">
							{/* <div ref={paypal} disabled={!hasAgreedToTerms} className="flex pb-2"></div> */}
							{!checkoutUrl && (
								<button
									type="button"
									className={clsx(
										'bg-djawn hover:bg-djawn-300 text-white font-bold py-2 px-4 rounded w-full disabled:opacity-50 disabled:cursor-wait',
										payNowLoading && 'flex items-center justify-center'
									)}
									disabled={
										(proceedToCheckoutClicked && !liveEvent) || payNowLoading
									}
									onClick={() => {
										setPayNowLoading(true);
										setProceedToCheckoutClicked(true);
										onCheckout(
											`${firstName} ${lastName}`,
											mailingAddress1,
											mailingAddress2,
											mailingState,
											mailingZip,
											mailingCity
										).finally(() => setPayNowLoading(false));
									}}
								>
									{payNowLoading && (
										<ClipLoader size={20} css={clipLoaderCss} />
									)}
									Pay Now
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-row px-2 py-1 mb-8 justify-between items-end px-10 h-full">
				<div className="">
					<div className="flex flex-row">
						<button
							type="button"
							className="w-full focus:outline-none text-white text-sm py-2.5 px-5 rounded-sm bg-djawn hover:bg-red-600 hover:shadow-lg disabled:opacity-50 disabled:cursor-wait"
							onClick={async () => {
								setActiveStep(1);
								setCheckoutUrl(null);
							}}
						>
							Back to Shipping
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CheckoutPayment;
