import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

function useNetwork(
	runEventListeners = true,
	notifyViaToast = true,
	onOnlineCallback = null
) {
	const [isOnline, setNetwork] = useState(window && window.navigator.onLine);
	const toastId = useRef(null);

	const updateNetwork = () => {
		setNetwork(window && window.navigator.onLine);
		if (onOnlineCallback && window && window.navigator.onLine) {
			onOnlineCallback();
		}
	};

	useEffect(() => {
		if (!runEventListeners || !notifyViaToast) return;
		if (!isOnline && !toastId.current) {
			toastId.current = toast.error('No error connection', {
				position: 'top-center',
				hideProgressBar: false,
				autoClose: false,
				progress: 1000,
				closeOnClick: false,
				closeButton: false
			});
			return;
		}

		if (toastId.current) {
			toast.dismiss(toastId.current);
		}
	}, [runEventListeners, isOnline, notifyViaToast]);

	useEffect(() => {
		window.addEventListener('offline', updateNetwork);
		window.addEventListener('online', updateNetwork);

		return () => {
			window.removeEventListener('offline', updateNetwork);
			window.removeEventListener('online', updateNetwork);
		};
	}, [runEventListeners]);

	return isOnline;
}
export default useNetwork;
