import React from 'react';
import { isTablet } from 'react-device-detect';
import clsx from 'clsx';
import MiniEventTimer from '../common/mini-event-timer';
import { defaultToastError } from '../../utils/toast-error';

const CheckoutOrder = ({
	items,
	setShowCheckout,
	setActiveStep,
	paymentOrder
}) => (
	<>
		<div className="flex-grow px-0 py-2 h-auto">
			<ul className="flex flex-col">
				<div className="">
					{items.map((object, i) => (
						<li
							className="border-gray-400 dark:border-darkGray-50 flex flex-row mb-1 w-full"
							key={i}
						>
							<div className="select-none hover:bg-gray-100 rounded-md flex flex-1 items-center p-4 w-full justify-between dark:text-white">
								<div className="flex flex-1 flex-col md:flex-row items-center p-4 w-full justify-between border-b-2">
									<div className="flex flex-row w-full">
										<img
											className="flex flex-wrap w-28 h-28 md:h-48 md:w-48"
											src={object.image}
											alt="product"
											style={{ objectFit: 'cover' }}
										/>

										<div className="flex flex-col md:flex-row px-3 md:px-0">
											<div
												className={clsx(
													'flex flex-wrap flex-col font-medium justify-center items-center',
													isTablet ? 'md:px-per-10' : 'md:px-48'
												)}
											>
												<p className="hover:underline cursor-pointer font-medium w-80">
													{object.eventName}
												</p>
												<p className="w-80">{object.title}</p>
												{isTablet && (
													<span
														className="text-red-700 font-bold text-md w-80"
														key={object.id}
													>
														<MiniEventTimer
															date={object.time}
															breakId={object.id}
															item={object}
															timerEnded={() => {
																defaultToastError({
																	shortMessage: 'Purchase not finalized',
																	longMessage:
																		'You have not finalized your purchase within the allowed time and the items have been removed from your cart.'
																});

																setShowCheckout(false);
															}}
														/>
													</span>
												)}
											</div>
											{!isTablet && (
												<div className="text-gray-600 text-xs text-left flex flex-row justify-center items-center">
													<span
														className="text-red-700 font-bold text-md w-80"
														key={object.id}
													>
														<MiniEventTimer
															date={object.time}
															breakId={object.id}
															item={object}
															timerEnded={() => {
																defaultToastError({
																	shortMessage: 'Purchase not finalized',
																	longMessage:
																		'You have not finalized your purchase within the allowed time and the items have been removed from your cart.'
																});

																setShowCheckout(false);
															}}
														/>
													</span>
												</div>
											)}
										</div>
									</div>
									<div className="flex flex-row justify-end md:justify-center items-center w-full md:w-auto">
										<div
											className="text-gray-600 text-base text-left flex flex-row md:pr-16 justify-content-end dark:text-white"
											style={{ whiteSpace: 'nowrap' }}
										>
											$
											{object.price}
										</div>
									</div>
								</div>
							</div>
						</li>
					))}
				</div>
				<div className="bg-gray-200 dark:bg-gray-500 pt-5 px-5 w-full dark:text-white">
					<div className="flex flex-row justify-between mt-3">
						<p className="font-bold">Sub total</p>
						<p className="font-bold">
							$
							{Number(paymentOrder.subtotal).toFixed(2)}
						</p>
					</div>
					<div className="flex flex-row justify-between mt-3">
						<p className="font-bold">Shipping and handling</p>
						<p className="font-bold">
							$
							{Number(paymentOrder.shippingPrice).toFixed(2)}
						</p>
					</div>
					<div className="flex flex-row justify-between mt-3">
						<p className="font-bold">DJAWN fees</p>
						<p className="font-bold">
							$
							{Number(paymentOrder.buyerFeePriceBeforeDiscount).toFixed(2)}
						</p>
					</div>
					<div
						className={clsx(
							'flex flex-row justify-between border-b mt-3',
							paymentOrder.promoCodeDiscountTotal > 0
								? 'border-gray-300'
								: 'border-black mb-3'
						)}
					>
						<p className="font-bold">Discount</p>
						<p className="font-bold">
							{Number(paymentOrder.buyerDiscountAmount * -1).toFixed(2) >= 0
								? `$${Number(paymentOrder.buyerDiscountAmount * -1).toFixed(2)}`
								: `- $${Number(paymentOrder.buyerDiscountAmount).toFixed(2)}`}
						</p>
					</div>
					{paymentOrder.promoCodeDiscountTotal > 0 ? (
						<div className="flex flex-row justify-between border-b border-black">
							<p className="font-semibold italic text-gray-500">
								-
								{' '}
								{paymentOrder.promoCode}
								{paymentOrder.promoCodeDiscountCodeAmountIsFixed
									? ''
									: `(${paymentOrder.promoCodeAmount}%)`}
							</p>
							<p className="font-bold italic text-gray-500">
								- $
								{Number(paymentOrder.promoCodeDiscountTotal).toFixed(2)}
							</p>
						</div>
					) : null}
					<div className="flex flex-row justify-between mt-3">
						<p className="font-bold">Total before taxes</p>
						<p className="font-bold">
							$
							{Number(paymentOrder.totalWithoutTaxes).toFixed(2)}
						</p>
					</div>
					<div className="flex flex-row justify-between mt-3">
						<p className="font-bold">Taxes</p>
						<p className="font-bold">
							$
							{Number(paymentOrder.taxes).toFixed(2)}
						</p>
					</div>
					<div className="flex flex-row justify-between mt-3">
						<p className="font-bold">Order total</p>
						<p className="font-bold">
							$
							{Number(paymentOrder.total).toFixed(2)}
						</p>
					</div>
				</div>
			</ul>
		</div>
		<div
			className={clsx(
				'flex flex-row px-2 py-1 justify-between items-end px-10 h-full',
				isTablet ? 'mb-4 m-2' : 'mb-8'
			)}
		>
			<div className="">
				<div className="flex flex-row">
					<button
						type="button"
						className="w-full focus:outline-none text-white text-sm py-2.5 px-5 rounded-sm bg-djawn hover:bg-red-600 hover:shadow-lg disabled:opacity-50 disabled:cursor-wait"
						onClick={async () => {
							setShowCheckout(false);
						}}
					>
						Back to cart
					</button>
				</div>
			</div>
			<div className="">
				<div className="flex flex-row">
					<button
						type="button"
						className="w-full focus:outline-none text-white text-sm py-2.5 px-5 rounded-sm bg-djawn hover:bg-red-600 hover:shadow-lg disabled:opacity-50 disabled:cursor-wait"
						onClick={() => setActiveStep(1)}
					>
						Shipping
					</button>
				</div>
			</div>
		</div>
	</>
);

export default CheckoutOrder;
