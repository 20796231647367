import React from 'react';
import Button from '../../../components/button';
import Modal from '../../../components/modals';

const HostDisconnectedFromLiveEventModal = ({
	showModal,
	onClose,
	onRejoinEvent
}) => (
	<Modal showModal={showModal} onClose={onClose} hideCloseButton>
		<div className="flex flex-col space-y-2 items-center justify-center  font-semibold text-gray-700 pr-1">
			<p>
				You disconnected from live event, please click the "Rejoin event" button
				below to rejoin the event.
			</p>
			<Button
				variant="secondary"
				width="1/2"
				mobileWidth="auto"
				height="12"
				mobileHeight="12"
				onClick={onRejoinEvent}
			>
				Rejoin Event
			</Button>
		</div>
	</Modal>
);

export default HostDisconnectedFromLiveEventModal;
