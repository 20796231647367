/* eslint-disable no-unused-vars */
import React from 'react';
import { toast, Icons } from 'react-toastify';

export const defaultToastSucesss = (message) => {
	toast.info(message, {
		position: 'top-center',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'colored',
		icon: Icons.success
	});
};
