import React from 'react';
import Button from '../../components/button';
import Modal from '../../components/modals';

const ConcurrentEventsModal = ({ showModal, onClose, onJoinCurrentEvent }) => (
	<Modal showModal={showModal} onClose={onClose} hideCloseButton>
		<div className="flex flex-col space-y-2 items-center justify-center  font-semibold text-gray-700 pr-1">
			<p>You cannot start another event when you have an active live event</p>
			<Button
				variant="secondary"
				width="1/2"
				mobileWidth="full"
				height="12"
				mobileHeight="12"
				onClick={onJoinCurrentEvent}
			>
				Join Current Live Event
			</Button>
		</div>
	</Modal>
);

export default ConcurrentEventsModal;
