/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import { Link, navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import success from '../../images/success.svg';
import {
	elligibleForSellerApplication,
	sellerRegister
} from '../../api/seller.request';
import { defaultToastError } from '../../utils/toast-error';
import NextButton from '../button/next-button';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		textAlign: 'center',
		borderRadius: '10px'
	}
};

function Submit({
	stepData,
	onPrev,
	onNext,
	paypalMerchantId,
	paypalTrackingId
}) {
	const [termCondition, setTermCondition] = useState(
		stepData.termCondition ? stepData.termCondition : false
	);
	const [sellerPolicy, setSellerPolicy] = useState(
		stepData.sellerPolicy ? stepData.sellerPolicy : false
	);
	const [formData, setFormData] = useState('');
	const [sellerPolicyErr, setSellerPolicyErr] = useState(false);
	const [termConditionErr, setTermConditionErr] = useState(false);
	const [modalIsOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	function closeModal() {
		setIsOpen(false);
		navigate('/events');
	}

	const initFormData = () => {
		const form_data = window.localStorage.sellerData
			? JSON.parse(window.localStorage.sellerData)
			: '';

		const currentSellerData = {
			storeName: form_data.step1.storeName,
			email: form_data.step1.email,
			phoneNumber: form_data.step1.phoneNumber,
			addressLine1: form_data.step1.addressLine1,
			apartmentSuite: form_data.step1.apartmentSuite,
			city: form_data.step1.city,
			state: form_data.step1.state,
			postalCode: form_data.step1.postalCode,
			storeLogo: form_data.step1.storeLogo,
			storeLogoName: form_data.step1.storeLogoName,
			plateform: form_data.step2.plateform,
			scammer: form_data.step2.scammer,
			references: form_data.step2.references,
			plateformText: form_data.step2.plateformText,
			scammerText: form_data.step2.scammerText,
			referencesText: form_data.step2.referencesText,
			plateformHtml: form_data.step2.plateformHtml,
			scammerHtml: form_data.step2.scammerHtml,
			referencesHtml: form_data.step2.referencesHtml,
			plateformQuestionData: form_data.step2.plateformQuestionData.questionText,
			referencesQuestionData:
				form_data.step2.referencesQuestionData.questionText,
			scammerQuestionData: form_data.step2.scammerQuestionData.questionText,
			cardData: form_data.step3.cardData,
			otherCardDetail: form_data.step3.otherCardDetail,
			favouriteBrand: form_data.step3.favouriteBrand,
			shipCardValue: form_data.step4.shipCardValue,
			frequencyShipping: form_data.step4.frequencyShipping,
			shippingType: form_data.step4.shippingType,
			rate: form_data.step4.rate,
			shippingTypeText: form_data.step4.shippingTypeText,
			shippingCardText: form_data.step4.shippingCardText,
			paypalMerchantId,
			paypalTrackingId
		};
		setFormData(currentSellerData);
	};

	useEffect(() => {
		initFormData();
	}, []);

	function handleSellerPolicy(e) {
		setSellerPolicy(e.target.checked);
		if (!e.target.checked) {
			// setSellerPolicyErr(true)
		} else {
			setSellerPolicyErr(false);
		}
	}

	function handleTermCondition(e) {
		setTermCondition(e.target.checked);
		if (!e.target.checked) {
			// setTermConditionErr(true)
		} else {
			setTermConditionErr(false);
		}
	}
	const prevStep = async () => {
		onPrev(4);
	};

	const nextStep = async () => {
		setSubmitting(true);
		let basketball;
		let baseball;
		let nonsports;
		let football;
		let hockey;
		let others;

		if (!termCondition) {
			setTermConditionErr(true);
		} else {
			setTermConditionErr(false);
		}
		if (!sellerPolicy) {
			setSellerPolicyErr(true);
		} else {
			setSellerPolicyErr(false);
		}

		const setp5values = {
			termCondition,
			sellerPolicy
		};

		formData
			&& formData.cardData
			&& formData.cardData.length > 0
			&& formData.cardData.map((data) => {
				if (data === 'Basketball') {
					basketball = 'Y';
				}
				if (data === 'Baseball') {
					baseball = 'Y';
				}
				if (data === 'Non-Sports') {
					nonsports = 'Y';
				}
				if (data === 'Football') {
					football = 'Y';
				}
				if (data === 'Hockey') {
					hockey = 'Y';
				}
				if (data === 'Other') {
					others = 'Y';
				}
			});

		// var ext = formData.storeLogo.split(';')[0].match(/jpeg|png|jpg/)[0];
		const storeLogo64 = formData.storeLogo.replace(
			/^data:image\/\w+;base64,/,
			''
		);

		const body = {
			companyName: formData.storeName,
			email: formData.email,
			phone: formData.phoneNumber,
			mailingAddress1: formData.addressLine1,
			mailingAddress2: formData.apartmentSuite,
			mailingState: formData.state,
			mailingZip: formData.postalCode,
			mailingCity: formData.city,
			platform: formData.plateformText,
			scammer: formData.scammerText,
			reference: formData.referencesText,
			paypalTrackingId: formData.paypalMerchantId,
			paypalMerchantId: formData.paypalTrackingId,
			storeLogo: storeLogo64,
			storeLogoName: formData.storeLogoName,
			sellBaseBall: baseball || 'N',
			sellBasketBall: basketball || 'N',
			sellFootball: football || 'N',
			sellHockey: hockey || 'N',
			sellNonSports: nonsports || 'N',
			sellOthers: others || 'N',
			otherCardDetails: formData.otherCardDetail,
			favouriteBrand: formData.favouriteBrand,
			shipAllCards: formData.shipCardValue,
			shippingType: formData.shippingType,
			fixedShiprates: formData.rate,
			shippingFrequency: formData.frequencyShipping,
			acceptTerms: termCondition ? 'true' : 'false',
			acceptSellerPolicy: sellerPolicy ? 'true' : 'false'
		};

		let formStatus = false;

		if (setp5values.termCondition && setp5values.sellerPolicy) {
			formStatus = true;
		} else {
			formStatus = false;
		}

		if (formStatus) {
			setLoading(true);
			onNext(setp5values);
			elligibleForSellerApplication()
				.then(() => {
					sellerRegister(body)
						.then(() => {
							setLoading(false);
							setIsOpen(true);
							setSubmitting(false);
						})
						.catch((error) => {
							if (error.response) {
								setSubmitting(false);
								setLoading(false);
								defaultToastError(error.response.data.error);
							}
						});
				})
				.catch((err) => {
					if (err && err.response.data) {
						setSubmitting(false);
						setLoading(false);
						defaultToastError(err.response.data.error);
					}
				});
		} else {
			setLoading(false);
			setSubmitting(false);
			// triggerError('Please fill all the fields.')
		}
	};

	const handleSellerPolicyLink = () => {
		window.open('https://support.djawn.com/seller-policy', '_blank');
	};

	const handleSellerTermServiceLink = () => {
		window.open('https://support.djawn.com/terms-of-service', '_blank');
	};

	return (
		<div className="flex-row sm:grid-rows-4 lg:grid-rows-4 xl:grid-rows-4 lg:grid-flow-col gap-4">
			<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
				<label
					htmlFor="frequency_shipping"
					className="block text-sm font-medium text-gray-700 pl-2 "
				>
					Application Data
				</label>
				<div className="mt-1 relative rounded-md shadow-sm w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none h-64 overflow-scroll gap-4">
					<h3>
						<u>
							<b>Store Information</b>
						</u>
					</h3>
					<p>
Store Name :
						{formData.storeName}
					</p>
					<p>
Email :
						{formData.email}
					</p>
					<div className="flex items-center pr-3">
						<div className="pr-3">Address: </div>
						<div>
							<p>{formData.addressLine1}</p>
							{formData.apartmentSuite && <p>{formData.apartmentSuite}</p>}
							<p>
								{formData.city}
,
								{formData.state}
								{' '}
,
								{formData.postalCode}
							</p>
						</div>
					</div>
					<p>
Phone Number :
						{formData.phoneNumber}
					</p>
					<p>
						Store Logo :
						{' '}
						<img
							alt="store"
							src={formData.storeLogo}
							width="100px"
							height="100px"
						/>
					</p>
					<br />
					<h3>
						<u>
							<b>General Questions</b>
						</u>
					</h3>
					<p>
						{formData.plateformQuestionData}
						{' '}
					</p>
					<span
						className="text-show-question"
						dangerouslySetInnerHTML={{
							__html: formData.plateformHtml
						}}
					/>
					<p>
						{formData.scammerQuestionData}
						{' '}
					</p>
					<span
						className="text-show-question"
						dangerouslySetInnerHTML={{
							__html: formData.scammerHtml
						}}
					/>
					<p>{formData.referencesQuestionData}</p>
					<span
						className="text-show-question"
						dangerouslySetInnerHTML={{
							__html: formData.referencesHtml
						}}
					/>
					<br />
					<h3>
						<u>
							<b>Cards</b>
						</u>
					</h3>
					<p>
						Which type of cards do you plan on selling ?(you can select
						multiple)
					</p>
					{formData
						&& formData.cardData
						&& formData.cardData.length > 0
						&& formData.cardData.map((data) => (
							<span>
								{data}
								{' '}
							</span>
						))}
					<p>
Card Preferences :
						{formData.otherCardDetail}
					</p>
					<p>What is your favourite brand of cards?</p>
					<span>{formData.favouriteBrand}</span>
					<br />
					<h3>
						<u>
							<b>Shipping & Payment</b>
						</u>
					</h3>
					<p>
						Frequency of Shipping:
						{formData.frequencyShipping}
					</p>
					<p>
						Ship Cards:
						{formData.shippingCardText}
					</p>
					<p>
						Ship Type:
						{formData.shippingTypeText}
					</p>
					<p>
						Rate:
						{formData.rate}
					</p>
				</div>
			</div>
			{loading && (
				<div className="w-screen h-screen bg-white absolute left-0 top-0">
					<div className="flex h-full items-center">
						<BounceLoader loading css={override} size={30} />
					</div>
				</div>
			)}
			<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
				<div className="flex mt-6 ml-2">
					<label className="flex items-center">
						<input
							type="checkbox"
							className="form-checkbox"
							checked={termCondition}
							onChange={handleTermCondition}
						/>
						<span className="ml-2">I accept</span>
						<span>
							<Link
								// onClick={() => window.open("/terms-and-conditions", "_blank")}
								// to="/terms-and-conditions"
								activeClassName="bg-gray-200"
								className="w-full h-10 bg-white text-base text-gray-700 hover:text-blue-500 rounded-sm font-medium py-2 px-2 flex items-center"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
									/>
								</svg>
								<span
									className="ml-4 cursor-pointer"
									onClick={handleSellerTermServiceLink}
									onKeyDown={handleSellerTermServiceLink}
								>
									Terms of Service
								</span>
							</Link>
						</span>
					</label>
				</div>
				{termConditionErr && (
					<p className="text-red-700">This field is required</p>
				)}
			</div>

			<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
				<div className="flex mt-6 ml-2">
					<label className="flex items-center">
						<input
							type="checkbox"
							className="form-checkbox"
							checked={sellerPolicy}
							onChange={handleSellerPolicy}
						/>
						<span className="ml-2">I accept</span>
						<span>
							<Link
								// to="/seller-policy"
								// target="_blank"
								// onClick={handleSellerPolicyLink}
								activeClassName="bg-gray-200"
								className="w-full h-10 bg-white text-base text-gray-700 hover:text-blue-500 rounded-sm font-medium py-2 px-2 flex items-center"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path d="M23.995 24h-1.995c0-3.104.119-3.55-1.761-3.986-2.877-.664-5.594-1.291-6.584-3.458-.361-.791-.601-2.095.31-3.814 2.042-3.857 2.554-7.165 1.403-9.076-1.341-2.229-5.413-2.241-6.766.034-1.154 1.937-.635 5.227 1.424 9.025.93 1.712.697 3.02.338 3.815-.982 2.178-3.675 2.799-6.525 3.456-1.964.454-1.839.87-1.839 4.004h-1.995l-.005-1.241c0-2.52.199-3.975 3.178-4.663 3.365-.777 6.688-1.473 5.09-4.418-4.733-8.729-1.35-13.678 3.732-13.678 4.983 0 8.451 4.766 3.732 13.678-1.551 2.928 1.65 3.624 5.09 4.418 2.979.688 3.178 2.143 3.178 4.663l-.005 1.241zm-13.478-6l.91 2h1.164l.92-2h-2.994zm2.995 6l-.704-3h-1.615l-.704 3h3.023z" />
								</svg>

								<span
									onClick={handleSellerPolicyLink}
									onKeyDown={handleSellerPolicyLink}
									className="ml-4 cursor-pointer"
								>
									Seller Policy
								</span>
							</Link>
						</span>
					</label>
				</div>
				{sellerPolicyErr && (
					<p className="text-red-700">This field is required</p>
				)}
			</div>

			<div className="grid">
				<div className="flex justify-between mx-auto w-full">
					<div className="w-2/5">
						<NextButton
							variant="primary"
							overridenText="PREVIOUS"
							onClick={prevStep}
							width="full"
						/>
					</div>
					<div className="w-2/5">
						<NextButton
							variant="primary"
							overridenText="SUBMIT"
							disabled={submitting}
							onClick={nextStep}
							width="full"
						/>
					</div>
				</div>
			</div>

			<div>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					style={customStyles}
					contentLabel="Submit_Modal"
				>
					<span
						className="justify-between mx-auto"
						style={{
							marginLeft: '0px',
							left: '147px',
							right: '147px',
							alignItems: 'center',
							position: 'absolute'
						}}
					>
						<img alt="success" src={success} width="40px" height="40px" />
					</span>
					<br />
					<h1 className="mt-8">
						<b>Successful</b>
					</h1>
					<br />
					<p>Your application is submitted successfully.</p>
					<p>Approval will take around 3 days.</p>
					<br />
					<button
						type="button"
						style={{ backgroundColor: '#008000' }}
						className="min-w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded-lg"
						onClick={closeModal}
					>
						Done
					</button>
				</Modal>
			</div>
		</div>
	);
}
export default Submit;
