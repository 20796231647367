import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import Button from '../../components/button';
import SymbolBoxInput from '../../components/input/symbol-box-input';
import { RIP_N_SHIP } from '../../utils/constants';
import { getDetailedSlotName } from '../../utils/slot';

const ShipmentLabelTable = ({
	labelNumber,
	shipment,
	removeEnabled,
	onRemove,
	parcelPounds,
	setParcelPounds,
	parcelHeight,
	setParcelHeight,
	parcelWidth,
	setParcelWidth,
	parcelLength,
	setParcelLength,
	parcelOunces,
	setParcelOunces
}) => {
	const [orderIds, setOrderIds] = useState([]);

	useEffect(() => {
		if (!shipment) return;
		setOrderIds([
			...new Set(shipment.orderDetails.map((x) => x.order.orderNumber))
		]);
	}, [shipment]);

	const columns = useMemo(
		() => [
			{
				name: 'Image',
				cell: (orderDetail) => {
					let imageSrc = null;
					if (orderDetail.productImage != null) {
						imageSrc = orderDetail.productImage;
					} else {
						const images = orderDetail.event.eventImages;
						if (images && images.length) {
							const activeImage = images.find((x) => x.isActive);
							if (activeImage && activeImage.image) {
								imageSrc = activeImage.image;
							}
						}
					}
					return (
						<div className="border">
							<img
								src={imageSrc}
								alt="product"
								className="object-cover"
								width={300}
								height={300}
							/>
						</div>
					);
				},
				sortable: true,
				wrap: true,
				width: '15%'
			},
			{
				name: 'Product Name',
				cell: (orderDetail) => {
					if (orderDetail.event.eventTypeId === RIP_N_SHIP) {
						return <p>{orderDetail.productName}</p>;
					}
					return <p>{getDetailedSlotName(orderDetail)}</p>;
				},
				sortable: true,
				wrap: true
			},
			{
				name: 'Event Id',
				selector: (row) => row.event.eventNumber,
				sortable: true,
				wrap: true,
				width: '15%'
			},
			{
				name: 'Event Name',
				selector: (row) => row.event.eventName,
				sortable: true,
				wrap: true,
				width: '25%'
			},
			{
				name: 'Price',
				cell: (row) => (
					<div className="flex justify-between">
						<div className="flex flex-col justify-end pr-2">
							<span>$</span>
						</div>
						<div className="flex justify-end items-center">
							<span style={{ textAlign: 'right' }}>
								{Number(row.unitCost).toFixed(2)}
							</span>
						</div>
					</div>
				),
				sortable: true,
				wrap: true,
				width: '10%'
			},
			{
				name: 'Weight',
				cell: (row) => <p>{row.weight ? `${row.weight} lb` : null}</p>,
				sortable: true,
				wrap: true,
				width: '15%'
			}
		],
		[]
	);

	return (
		<div className="w-full border shadow-md rounded-md border-gray-300 mb-1">
			<div className="flex w-full justify-between px-2">
				<div className="inline-flex space-x-3 items-center">
					<h4>
						Label
						{` ${labelNumber}`}
					</h4>
					{orderIds && orderIds.length ? (
						<p className="text-sm w-1/3">
							<span>Order&nbsp;</span>
							{orderIds.map((orderId, index) => (
								<span>
									{`${orderId}${index == orderIds.length - 1 ? '' : ', '}`}
								</span>
							))}
						</p>
					) : null}
					<Button
						width="auto"
						additionalClassName="text-sm px-3"
						height="auto"
						mobileHeight="8"
						disabled={!removeEnabled}
						onClick={onRemove}
					>
						Remove
					</Button>
				</div>
				<div className="w-8/12 p-1">
					<div className="flex w-full space-x-1 pt-1 pb-1 px-1">
						<label className="inline-flex w-full md:w-full text-sm w-full md:w-full  text-sm">
							Length
						</label>
						<label className="inline-flex w-full md:w-full text-sm w-full md:w-full text-sm">
							Height
						</label>
						<label className="inline-flex w-full md:w-full text-sm w-full md:w-full text-sm">
							Width
						</label>
						<label className="inline-flex w-full md:w-full text-sm w-full md:w-full  text-sm">
							Pounds
						</label>
						<label className="inline-flex w-full md:w-full text-sm w-full md:w-full  text-sm">
							Ounces
						</label>
					</div>
					<div className="flex w-full space-x-1 py-1">
						<SymbolBoxInput
							min="0"
							name="length"
							id="length"
							width="full"
							mobileWidth="full"
							height="10"
							mobileHeight="10"
							rtl
							placeholder="Length"
							symbol="in"
							value={parcelLength}
							onChange={(e) => {
								const { value } = e.target;
								if (value < 0) {
									setParcelLength(0);
									return;
								}
								setParcelLength(value);
							}}
						/>
						<SymbolBoxInput
							min="0"
							name="height"
							id="height"
							width="full"
							mobileWidth="full"
							height="10"
							mobileHeight="10"
							placeholder="Height"
							rtl
							symbol="in"
							value={parcelHeight}
							onChange={(e) => {
								const { value } = e.target;
								if (value < 0) {
									setParcelHeight(0);
									return;
								}
								setParcelHeight(value);
							}}
						/>

						<SymbolBoxInput
							min="0"
							name="width"
							id="width"
							width="full"
							mobileWidth="full"
							height="10"
							mobileHeight="10"
							placeholder="Width"
							rtl
							symbol="in"
							value={parcelWidth}
							onChange={(e) => {
								const { value } = e.target;
								if (value < 0) {
									setParcelWidth(0);
									return;
								}
								setParcelWidth(value);
							}}
						/>

						<SymbolBoxInput
							min="0"
							name="pounds"
							id="pounds"
							width="full"
							mobileWidth="full"
							placeholder="Pounds"
							height="10"
							mobileHeight="10"
							rtl
							symbol="lb"
							value={parcelPounds}
							onChange={(e) => {
								const { value } = e.target;
								if (value < 0) {
									setParcelPounds(0);
									return;
								}
								setParcelPounds(value);
							}}
						/>
						<SymbolBoxInput
							min="0"
							name="ounces"
							id="ounces"
							width="full"
							mobileWidth="full"
							placeholder="Ounces"
							height="10"
							mobileHeight="10"
							rtl
							symbol="oz"
							value={parcelOunces}
							onChange={(e) => {
								const { value } = e.target;
								if (value < 0) {
									setParcelOunces(0);
									return;
								}
								if (value > 16) {
									setParcelOunces(16);
									return;
								}
								setParcelOunces(value);
							}}
						/>
					</div>
				</div>
			</div>
			<div className="border border-gray-300 mb-1">
				<DataTable
					columns={columns}
					data={shipment.orderDetails}
					noContextMenu
					contextActions={null}
					striped
					paginationComponentOptions={{
						rowsPerPageText: 'Rows per page:',
						rangeSeparatorText: 'of',
						noRowsPerPage: false,
						selectAllRowsItem: true,
						selectAllRowsItemText: 'All'
					}}
					customStyles={{
						rows: {
							style: {}
						},
						headCells: {
							style: {
								backgroundColor: '#e82e2c',
								color: 'white',
								fontSize: '1rem',
								fontWeight: 'bold'
							}
						}
					}}
				/>
			</div>
		</div>
	);
};

export default ShipmentLabelTable;
