/* eslint-disable */

import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react';
import { ProfileContext } from '../../../contexts/profile';
import TextInput from '../../../components/input/text-input';
import PhoneInput from '../../../components/input/phone-input';
import AddressAutocompleteInput from '../../../components/input/address-autocomplete-input';
import env from '../../../../environment.config';
import { redirectToDefaultBrowserIfInsideWebViewAndroid } from '../../../utils/redirect';
import { findAddressComponent } from '../../../utils/placesParser';
import { defaultAutocompleteOptions } from '../../../utils/autocomplete-utils';
import AvatarImageCropModal from '../../../components/avatar-image-crop';
import ErrorFieldMessage from '../../../components/error-field-message';
import { getDefaultLogo } from '../../../api/seller.request';
import getInfo from '../../../components/common/image-dimension-info';

const SellerGeneralInformation = () => {
	const { formik } = useContext(ProfileContext);

	const mailingAddress1Ref = useRef(null);

	const [showLogoCropperModal, setShowLogoCropperModal] = useState(false);

	const calculateTopToPlacesContainer = useCallback(() => {
		const pacContainer = document.querySelectorAll('.pac-container');
		const addressLine1Container = pacContainer[0];
		if (addressLine1Container) {
			const topFirstAddress =
				mailingAddress1Ref.current.getBoundingClientRect().top + 40;
			addressLine1Container.style.top = `${topFirstAddress}px`;

			let isDone = true;

			const interval = setInterval(() => {
				if (isDone) {
					addressLine1Container.style.top = `${topFirstAddress}px`;
					isDone = false;
				} else {
					clearInterval(interval);
				}
			}, 10);
		}
	}, []);

	useEffect(() => {
		redirectToDefaultBrowserIfInsideWebViewAndroid();

		if (!window) return;
		window.addEventListener(
			'scroll',
			() => {
				calculateTopToPlacesContainer();
			},
			true
		);

		return () =>
			window.removeEventListener(
				'scroll',
				() => {
					calculateTopToPlacesContainer();
				},
				true
			);
	}, [window]);

	const ratioAspectImage = async (file) => {
		if (file) {
			const outputImageAspectRatio = 1;
			const imageAspect = await getInfo(file);
			const inputImageAspectRatio = imageAspect.width / imageAspect.height;
			if (inputImageAspectRatio != outputImageAspectRatio) {
				formik.setFieldValue('seller.storeLogoRatioErr', true);
			} else {
				formik.setFieldValue('seller.storeLogoRatioErr', false);
			}
		}
	};

	useEffect(() => {
		if (!formik.values.seller.photoDataUrl) return;

		setShowLogoCropperModal(true);
	}, [formik.values.seller.photoDataUrl]);

	function handlePhoto(e) {
		if (e && e.target && e.target.files && e.target.files[0]) {
			const file = e.target.files[0];
			formik.setValues({
				...formik.values,
				seller: {
					...formik.values.seller,
					storeLogo: file,
					storeLogoUpdated: true,
					storeLogoName: file.name,
					showRemoveImageButton: true
				}
			});
			ratioAspectImage(e.target.files[0]);
		} else {
			formik.setValues({
				...formik.values,
				seller: {
					...formik.values.seler,
					storeLogo: '',
					storeLogoName: '',
					showRemoveImageButton: true
				}
			});
		}

		let bytes = 0;
		if (e.target.files[0] && e.target.files[0].size) {
			bytes = e.target.files[0].size;
		}
		const kb = (bytes / 1024).toFixed(2);

		const file = e.target.files[0];
		const reader = new FileReader();

		if (reader !== undefined && file !== undefined) {
			reader.onloadend = () => {
				// setDefaultLogo(reader.result);
				formik.setFieldValue('seller.photoDataUrl', reader.result);
			};
			reader.readAsDataURL(file);
		}

		// if (kb > 2048) {
		// 	setPhotoSizeErr(true);
		// } else {
		// 	setPhoto(e.target.files[0]);
		// }
	}

	function handleDefaultLogo() {
		getDefaultLogo()
			.then((res) => {
				formik.setValues({
					...formik.values,
					seller: {
						...formik.values.seller,
						storeLogoDataUrl:
							'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600w-1037719192.jpg',
						showRemoveImageButton: false,
						defaultLogo: ''
					}
				});
			})
			.catch(() => {
				// setLoading(false);
			})
			.finally(() => {
				// formik
				// setLoading(false);
				// setIsRemoveBtn(false);
			});
	}
	function handleRemoveImage() {
		formik.setValues({
			...formik.values,
			seller: {
				...formik.values.seller,
				storeLogoUpdated: true
			}
		});
		handleDefaultLogo();
		// setStoreLogoUpdated(true);
		// setPhotoRatioErr(false);
		// handleDefaultLogo();
	}

	return (
		<>
			<div className="flex w-full">
				<p className="md:text-2xl text-xl text-gray-500 text-center mx-auto">
					General Information
				</p>
			</div>
			<AvatarImageCropModal
				open={showLogoCropperModal}
				setOpen={setShowLogoCropperModal}
				image={formik.values.seller.photoDataUrl}
				onUpload={(img) => {
					const xhr = new XMLHttpRequest();
					xhr.responseType = 'blob';

					xhr.onload = function () {
						const recoveredBlob = xhr.response;

						const reader = new FileReader();

						reader.onload = function () {
							const blobAsDataUrl = reader.result;
							setShowLogoCropperModal(false);
							formik.setValues({
								...formik.values,
								seller: {
									...formik.values.seller,
									defaultLogo: blobAsDataUrl,
									photoDataUrl: null
								}
							});
						};

						reader.readAsDataURL(recoveredBlob);
					};

					xhr.open('GET', img);
					xhr.send();
				}}
			/>
			<div className="flex flex-col w-full px-8 md:px-32 mx-auto">
				<div className="flex-grow p-4">
					<div className="pl-2 mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
						<label className="block text-sm font-medium text-gray-700 pl-2">
							<h1 className="mb-4 mt-2 pl-2">
								<b>{formik.values.storeName}</b>
							</h1>
						</label>
						<div className="flex flex-row-reverse justify-center flex-grow-0 relative xxs:w-full sm:w-full md:w-1/2 h-full xxs:h-auto mx-auto p-4 pt-0">
							{formik.values.seller.showRemoveImageButton && (
								<button
									type="button"
									className="w-10 h-10 p-2 rounded-full text-gray-900 hover:bg-gray-200 focus:outline-none"
									onClick={handleRemoveImage}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-6 w-6"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
										/>
									</svg>
								</button>
							)}

							<div className="">
								<button
									type="button"
									className="flex justify-center items-center w-10 h-10 rounded-full text-gray-900 hover:bg-gray-200 focus:outline-none"
								>
									<label
										htmlFor="photo"
										className="seller-view-custom-file-upload fas"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
											/>
										</svg>

										<input
											type="file"
											name="photo"
											id="photo"
											className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
											accept="image/png, image/jpg, image/jpeg"
											onChange={handlePhoto}
										/>
									</label>
								</button>
							</div>
							<img
								alt="seller default"
								htmlFor="default_photo"
								id="defaultLogo"
								src={
									formik.values.seller.defaultLogo ||
									formik.values.seller.storeLogoDataUrl
								}
								style={{
									width: '192px',
									height: '192px'
								}}
							/>
						</div>
						{/* {formik.errors && formik.errors.seller.image && <ErrorFieldMessage error={formik.errors.seller.image} />} */}
					</div>
					<div className="flex flex-col md:flex-row w-full space-x-0 md:space-x-4">
						<div className="flex flex-col w-full mb-4">
							<div className="w-full mt-1">
								<TextInput
									type="text"
									name="seller.emailAddress"
									height="10"
									mobileHeight="10"
									id="seller.emailAddress"
									value={formik.values.seller.emailAddress}
									onChange={formik.handleChange}
									isTouched={
										formik.touched &&
										formik.touched.seller &&
										formik.touched.seller.emailAddress
									}
									onBlur={formik.handleBlur}
									error={
										formik.touched &&
										formik.touched &&
										formik.touched.seller &&
										formik.touched.seller.emailAddress &&
										formik.errors &&
										formik.errors.seller &&
										formik.errors.seller.emailAddress
									}
									floatingLabelText="Email Address"
								/>
							</div>
						</div>

						<div className="flex flex-col w-full mb-4">
							<div className="w-full mt-1">
								<PhoneInput
									type="text"
									name="seller.phoneNumber"
									width="full"
									mobileWidth="full"
									height="10"
									mobileHeight="10"
									id="seller.phoneNumber"
									value={formik.values.seller.phoneNumber}
									error={
										formik.touched.seller &&
										formik.touched.seller.phoneNumber &&
										formik.errors &&
										formik.errors.seller &&
										formik.errors.seller.phoneNumber
									}
									onChange={({ value }, valid) => {
										formik.setFieldValue('seller.phoneNumber', value);
									}}
									isTouched={
										formik.touched &&
										formik.touched.seller &&
										formik.touched.seller.phoneNumber
									}
									onBlur={formik.handleBlur}
									floatingLabelClassName="mt-3"
								/>
							</div>
						</div>
					</div>
					<div className="flex flex-col w-full mb-4">
						<div>
							<div className="mt-1">
								<AddressAutocompleteInput
									apiKey={env.google_places_key}
									value={formik.values.seller.address}
									invisibleValue={formik.values.seller.address}
									width="full"
									mobileWidth="full"
									name="seller.address"
									id="seller.address"
									height="10"
									mobileHeight="10"
									ref={mailingAddress1Ref}
									onFocus={() => {
										calculateTopToPlacesContainer();
									}}
									onBlur={(e) => {
										calculateTopToPlacesContainer();
										formik.handleBlur(e);
									}}
									onChange={(e) => {
										calculateTopToPlacesContainer();
										if (e.target.value || e.target.value.length === 0) {
											formik.setValues({
												...formik.values,
												seller: {
													...formik.values.seller,
													city: '',
													postal: '',
													state: ''
												}
											});
										}

										formik.setFieldValue('seller.address', e.target.value);
									}}
									onPlaceSelected={(place, e) => {
										const route = findAddressComponent(
											place.address_components,
											'route'
										);

										const streetNumber = findAddressComponent(
											place.address_components,
											'street_number'
										);

										formik.setFieldValue(
											'addressHasStreetNumber',
											!!streetNumber
										);
										if (route) {
											if (streetNumber) {
												formik.setFieldValue(
													'seller.address',
													`${streetNumber} ${route}`
												);
												if (e.value) {
													e.value = `${streetNumber} ${route}`;
												}
											} else {
												formik.setFieldValue('seller.address', route);
												if (e.value) {
													e.value = route;
												}
											}
										}

										const foundPostalCode = findAddressComponent(
											place.address_components,
											'postal_code'
										);
										if (foundPostalCode) {
											formik.setFieldValue('seller.postal', foundPostalCode);
										}

										const foundState = findAddressComponent(
											place.address_components,
											'administrative_area_level_1',
											true
										);
										if (foundState) {
											formik.setFieldValue('seller.state', foundState);
										}

										if (place.vicinity) {
											formik.setFieldValue('seller.city', place.vicinity);
										}
									}}
									options={defaultAutocompleteOptions}
									floatingLabelText="Street Address Line"
									floatingLabelClassName="mt-3"
									isTouched={
										formik.touched &&
										formik.touched.seller &&
										formik.touched.seller.address
									}
									error={
										formik.touched &&
										formik.touched.seller &&
										formik.touched.seller.address &&
										formik.errors &&
										formik.errors.seller &&
										formik.errors.seller.address
									}
								/>
							</div>
						</div>
					</div>

					<div className="flex flex-col w-full mb-4">
						<div>
							<div className="mt-1">
								<TextInput
									type="text"
									name="seller.address2"
									id="seller.address2"
									height="10"
									mobileHeight="10"
									floatingLabelText="Apartment, suite, etc."
									value={formik.values.seller.address2}
									onChange={formik.handleChange}
									floatingLabelClassName="mt-3"
								/>
							</div>
						</div>
					</div>

					<div className="flex flex-col md:flex-row w-full mb-4">
						<div className="flex flex-col w-full mb-4 lg:pr-4">
							<div className="w-full mt-1">
								<TextInput
									type="text"
									name="seller.city"
									id="seller.city"
									height="10"
									mobileHeight="10"
									floatingLabelText="City"
									value={formik.values.seller.city}
									disabled
									floatingLabelClassName="mt-3"
								/>
							</div>
						</div>

						<div className="flex flex-col w-full mb-4 lg:pr-4">
							<div className="w-full mt-1">
								<TextInput
									type="text"
									name="seller.state"
									id="seller.state"
									height="10"
									mobileHeight="10"
									floatingLabelText="State"
									value={formik.values.seller.state}
									disabled
									floatingLabelClassName="mt-3"
								/>
							</div>
						</div>

						<div className="flex flex-col w-full md:mb-4 mb-0">
							<div className="w-full mt-1">
								<TextInput
									type="text"
									name="seller.postal"
									id="seller.postal"
									height="10"
									mobileHeight="10"
									floatingLabelText="Zip"
									value={formik.values.seller.postal}
									disabled
									floatingLabelClassName="mt-3"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SellerGeneralInformation;
