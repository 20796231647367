/* eslint-disable no-multi-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-shadow */
import React, {
	useState,
	useEffect,
	useRef,
	useContext,
	useCallback,
	useMemo
} from 'react';
import { connect, Logger } from 'twilio-video';
import BounceLoader from 'react-spinners/BounceLoader';
import { css } from '@emotion/core';
import { navigate } from 'gatsby';
import {
	isMobile as isMobileSize,
	isTablet,
	isAndroid
} from 'react-device-detect';
import { connect as ReduxConnect } from 'react-redux';
import { toast } from 'react-toastify';
import { useInterval } from '../common/use-interval';
import Layout from '../layouts/layout-default';
import VideoStream from '../video/video';
import { getAccessToken, getCurrentUser } from '../../utils/auth';
import env from '../../../environment.config';

import {
	getRoomTokens,
	startRoom,
	exitRoom,
	exitRoomByAnonymousToken,
	isEventStopped,
	savedEventJoinFailure,
	saveSubscribedTrack,
	saveSubscribedTrackAnonymousUser,
	addBuyersToNextLineInFutureEvent,
	reissueVideoToken,
	revertEventStartedStatus,
	updateNewLiveEventHostParticipant,
	getEventDetails
} from '../../api/event.request';
import { reserveProduct } from '../../api/product.request';

import RoomHeader from '../room-header';
import {
	generateTwilioVideoConfig,
	getVideoSetting,
	getVideoSettings
} from '../../utils/videoSettings';
import SellerRoomLeaveConfirmation from '../seller-room-leave-confirmation';
import BuyerRoomLeaveConfirmation from '../buyer-room-leave-confirmation';
import MiniCart from '../common/mini-cart';
import {
	addCartItem,
	removeCartItem,
	updateCartItemTimer,
	setCheckoutSessionId
} from '../../state/action';

import { defaultToastError } from '../../utils/toast-error';
import AccessDeniedMessage from './access-denied';
import Conversation from '../conversation';
import useVirtualAndroidKeyboardDetector from '../../hooks/useVirtualAndroidKeyboardDetector';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import {
	PICK_YOUR_SLOTS_VIEW,
	RANDOMIZED_BREAKS_VIEW
} from '../../utils/views';
import { TwilioTrackProvider } from '../../contexts/twilio-track';
import ChatOverlayPlugin from '../../containers/event/room/chat-overlay-plugin';
import { LiveEventViewsContainerContext } from '../../contexts/live-event-views-container';
import { RipNShipActiveLineProvider } from '../../contexts/ripnship-activeline';
import { notifyUserForModifiedShoppingCartAfterPaymentProcess } from '../../utils/slot';
import {
	EVENT_EXPIRATION_IN_SECONDS_REFERENCE_TYPE_NAME,
	EVENT_STOPPED_STATUS
} from '../../utils/constants';
import useStateRef from '../../hooks/useStateRef';
import useInAppBrowserDetection from '../../hooks/useInAppBrowserDetection';
import { LiveEventChatContext } from '../../contexts/live-event-chat';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const Room = ({
	exitingByLeaveButtonRef,
	nicknameRef,
	isRoomHost,
	setIsRoomHost,
	cartItems,
	currentCheckoutSessionId,
	dispatch,
	isSeller,
	userId,
	hasSound,
	setHasSound,
	roomRef,
	hostParticipantRejoining
}) => {
	const [nameInActiveLine, setNameInActiveLine] = useState(null);
	const [breakIsInActiveLine, setBreakIsInActiveLine] = useState(false);
	const [resize, setResize] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [videoRoomToken, setVideoRoomToken] = useState(null);
	const [eventDescription, setEventDescription] = useState('');
	const [room, setRoom] = useState(null);
	const [stopped, setStopped] = useState(false);
	const [showThankYou, setShowThankYou] = useState(false);
	const [goToHome, setGoToHome] = useState(false);
	const [videoHeight, setVideoHeight] = useState(0);
	const [
		showSellerRoomLeaveConfirmation,
		setShowSellerRoomLeaveConfirmation
	] = useState(false);
	const [
		showBuyerRoomLeaveConfirmation,
		setShowBuyerRoomLeaveConfirmation
	] = useState(false);
	const [additionalEndTimeInSeconds, setAdditionalEndTimeInSeconds] = useState(
		0
	);
	const [isCartOpen, setIsCartOpen] = useState(false);
	const [showPermissionDeniedModal, setShowPermissionDeniedModal] = useState(
		false
	);
	const [openedCartWindow, setOpenedCartWindow] = useState(null);
	const [optionPopupFadingOut, setOptionPopupFadingOut] = useState(false);
	const [topArrowButtonPosition, setTopArrowButtonPosition] = useState(null);
	const [leftArrowButtonPosition, setLeftArrowButtonPosition] = useState(null);
	const [
		showEventNameInChatContainer,
		setShowEventNameInChatContainer
	] = useState(
		isMobileSize
			&& mainEventDetails
			&& mainEventDetails.event
			&& mainEventDetails.event.hasLinkedEvents
			&& mainEventDetails.showEventNameOnMobileByDefault
			? mainEventDetails.showEventNameOnMobileByDefault
			: false
	);
	const [
		showLinkedEventsSliderInMobileChatContainer,
		setShowLinkedEventsSliderInMobileChatContainer
	] = useState(
		isMobileSize
			&& mainEventDetails
			&& mainEventDetails.showLinkedEventSliderOnMobileByDefault
			? mainEventDetails.showLinkedEventSliderOnMobileByDefault
			: false
	);
	const conversationContainerRef = useRef();
	const [
		conversationContainerHeight,
		setConversationContainerHeight
	] = useState(0);
	const [landscapeMode, setLandscapeMode] = useState(false);
	const [evaluateConversationHeight, setEvaluateConversationHeight] = useState(
		false
	);
	const [portraitChatHeight, setPortraitChatHeight] = useState(0);
	const [enabledChatInput, setEnabledChatInput] = useState(!isMobileSize);
	const [videoOptions, setVideoOptions] = useState(null);
	const [purchasedProductsUserId, setPurchasedProductsUserId] = useState(null);
	const [
		twilioReconnectOnEveryRefresh,
		setTwilioReconnectOnEveryRefresh
	] = useState(false);
	const [
		twilioConnectionHasBeenRefreshed,
		setTwilioConnectionHasBeenRefreshed
	] = useState(false);
	const [limitedChatMessages, setLimitedChatMessages] = useState(false);
	const [chatLimits, setChatLimits] = useState(0);
	const [searchInputActive, setSearchInputActive] = useState(false);
	const [virtualKeyboardVisible] = useVirtualAndroidKeyboardDetector(
		isAndroid,
		searchInputActive
	);
	const [
		twilioRoomIdentity,
		setTwilioRoomIdentity,
		twilioRoomIdentityRef
	] = useStateRef(null);
	const {
		eventId,
		setActiveView,
		ripNShipEvent,
		mainEventDetails,
		linkedEvents,
		webSocketGroupName,
		setPreselectedBreakEventIdOnOpenedView,
		authenticated,
		setOwnerNickname,
		eventName,
		setEventName,
		setEventStartDate,
		presenterIdentityRef,
		isHost,
		setIsHost,
		isHostRef,
		setEventExpirationInMinutes,
		setEventEndedDueToTechnicalIssues,
		setHostingTheStream,
		initiallyAuthenticated,
		setLinkedEvents,
		setEventDetails,
		eventDetails
	} = useContext(LiveEventDataContext);
	const {
		popups,
		dismissedPopups,
		updatePopupsStatus,
		arrowButtonContainerRef,
		eventNamePopupRef,
		viewPopupFadingOut,
		openQueueContainer,
		setOpenQueueContainer,
		arrowButtonRef
	} = useContext(LiveEventViewsContainerContext);
	const { conversation, initializeConversation } = useContext(
		LiveEventChatContext
	);
	const {
		isAppCalledWithinFacebookAppBrowser,
		isAppCalledWithinInstagramAppBrowser
	} = useInAppBrowserDetection();

	useEffect(() => {
		// bug on android: keeps scrolling up when keyboard is visible
		if (!virtualKeyboardVisible && window && isAndroid) {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
		}
	}, [virtualKeyboardVisible]);

	useEffect(() => {
		if (goToHome) {
			if (typeof window !== 'undefined') {
				if (loaded) {
					window.location.href = `${env.frontend_rul}event/${eventId}`;
				}
			}
		}
	}, [goToHome]);

	const showError = (message) => {
		toast.error(message, {
			position: 'top-center',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};

	const changeProductQuantityToCart = useCallback(
		async (product, quantity) => {
			if (product.quantity + quantity <= 0) {
				showError('You cannot choose a quantity of 0');
				return;
			}

			reserveProduct(eventId, product.id, quantity, currentCheckoutSessionId)
				.then((res) => {
					notifyUserForModifiedShoppingCartAfterPaymentProcess(
						cartItems,
						currentCheckoutSessionId
					);
					dispatch(setCheckoutSessionId(null));
					dispatch(removeCartItem(product));
					product.quantity += quantity;
					dispatch(addCartItem(product));
				})
				.catch((err) => {
					if (err.response) {
						const { isSlotAvailable } = err.response.data;
						if (!isSlotAvailable) {
							showError('Adding additional products is not possible');
						}
					}
				});
		},
		[eventId]
	);

	// Will be called when the screen orientation changes
	const onOrientationChange = useCallback((portrait = true) => {
		setLandscapeMode(!portrait);
	}, []);

	const addProductToCart = useCallback(
		(eventProduct, image, quantity) => {
			reserveProduct(
				eventId,
				eventProduct.eventProductId,
				quantity,
				currentCheckoutSessionId
			)
				.then((res) => {
					notifyUserForModifiedShoppingCartAfterPaymentProcess(
						cartItems,
						currentCheckoutSessionId
					);
					dispatch(setCheckoutSessionId(null));
					let { timerInSeconds: timer } = res.data.slot;
					if (timer == null) timer = 5;
					timer /= 60;

					const item = {
						id: eventProduct.eventProductId,
						eventName: `${ripNShipEvent.event.eventName} - Event Number #${ripNShipEvent.event.eventNumber}`,
						quantity,
						image,
						price: eventProduct.product.salesPriceRipShip,
						eventId,
						isProduct: true,
						time: new Date(Date.parse(new Date()) + timer * 60 * 1000),
						title: `${eventProduct.product.productName}`
					};

					dispatch(addCartItem(item));
				})
				.catch((err) => {
					if (err.response) {
						defaultToastError(err.response.data.error);
					}
				});
		},
		[eventId, ripNShipEvent]
	);

	const persistAnonymousToken = (token) => {
		localStorage.setItem(`anon-${eventId}`, token);
	};

	const joinRoomViaTokens = async () => {
		getRoomTokens(eventId, nicknameRef.current)
			.then(async (res) => {
				const { data } = res;
				const isOwner =					data.owner
					|| (hostParticipantRejoining && isRoomHost && !data.presenterIdentity);
				// if its the new owner who didnt start the event initially
				if (isOwner && !data.owner) {
					try {
						await updateNewLiveEventHostParticipant(
							eventId,
							data.twilioRoomIdentity,
							webSocketGroupName
						);
						presenterIdentityRef.current = data.twilioRoomIdentity;
					} catch (err) {
						console.error('Failed to update new host participant', err);
					}
				} else {
					presenterIdentityRef.current = data.presenterIdentity;
				}
				if (isOwner) setHostingTheStream(true);
				setIsHost(isOwner);
				setIsRoomHost(isOwner);
				isHostRef.current = isOwner;
				setVideoRoomToken(data.roomToken);
				setOwnerNickname(data.ownerNickname);
				setEventStartDate(data.eventDate);
				setEventName(data.eventName);
				nicknameRef.current = data.nickname;
				setEventDescription(data.details);
				setTwilioRoomIdentity(data.twilioRoomIdentity);
				twilioRoomIdentityRef.current = data.twilioRoomIdentity;

				if (data.anonymousToken && data.anonymousToken.length > 0) {
					// persist to localStorage
					persistAnonymousToken(data.anonymousToken);
				}

				const { videoSettings } = data;

				if (data && data.twilioReconnectOnEveryRefresh) {
					setTwilioReconnectOnEveryRefresh(data.twilioReconnectOnEveryRefresh);
				}

				if (data && data.limitChatMessages && data.limitChatMessages === true) {
					setLimitedChatMessages(true);
					const referenceTypeName = isMobileSize
						? 'LiveEventChatLimitMobile'
						: 'LiveEventChatLimitDesktop';
					const chatLimitReferenceData = getVideoSetting(
						videoSettings,
						referenceTypeName
					);
					if (chatLimitReferenceData) {
						setChatLimits(Number(chatLimitReferenceData.referenceDataValue));
					}
				}

				const eventExpirationInSecondsReferenceData = getVideoSetting(
					videoSettings,
					EVENT_EXPIRATION_IN_SECONDS_REFERENCE_TYPE_NAME
				);

				if (
					eventExpirationInSecondsReferenceData
					&& eventExpirationInSecondsReferenceData.referenceDataValue
					&& eventExpirationInSecondsReferenceData.referenceDataValue > 0
				) {
					setEventExpirationInMinutes(
						eventExpirationInSecondsReferenceData.referenceDataValue / 60
					);
				}
				let videoStreamOptions = {
					desktop: {
						height: 400,
						width: 800
					},
					mobile: {
						height: 480,
						width: 640
					},
					fps: 24
				};

				if (isOwner) {
					videoStreamOptions = getVideoSettings(
						videoSettings,
						videoStreamOptions
					);
				}

				setVideoOptions(videoStreamOptions);

				const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

				const twilioConfig = {
					name: data.roomToken,
					audio: false,
					video: false,
					automaticSubscription: false,
					workaroundWebKitBug180748: true,
					workaroundWebKitBug1208516: false
				};

				if (isOwner) {
					twilioConfig.video = {
						width: videoStreamOptions.desktop.width,
						frameRate: 24,
						height: videoStreamOptions.desktop.height
					};

					twilioConfig.audio = true;
					twilioConfig.frameRate = videoStreamOptions.fps;
					twilioConfig.bandwidthProfile = {
						video: {
							mode: 'presentation',
							maxTracks: 10,
							dominantSpeakerPriority: 'standard',
							renderDimensions: {
								high: { height: 720, width: 1280 },
								standard: { height: 400, width: 800 },
								low: { height: 176, width: 144 }
							}
						}
					};
					twilioConfig.dominantSpeaker = true;
					twilioConfig.maxAudioBitrate = 16000;
					twilioConfig.preferredVideoCodecs = [
						{ codec: 'VP8', simulcast: true }
					];

					if (isMobile) {
						twilioConfig.video = {
							height: videoStreamOptions.mobile.height,
							frameRate: videoStreamOptions.fps,
							width: videoStreamOptions.mobile.width
						};

						twilioConfig.video.facingMode = { exact: 'environment' };
						twilioConfig.video.focusMode = { ideal: 'continuous' };

						twilioConfig.bandwidthProfile = {
							video: {
								mode: 'presentation',
								maxSubscriptionBitrate: 2500000,
								maxTracks: 5,
								dominantSpeakerPriority: 'standard',
								renderDimensions: {
									high: { height: 1080, width: 1920 },
									standard: { height: 720, width: 1280 },
									low: { height: 176, width: 144 }
								}
							}
						};
						twilioConfig.dominantSpeaker = true;
						twilioConfig.maxAudioBitrate = 16000;
						twilioConfig.preferredVideoCodecs = [
							{ codec: 'VP8', simulcast: true }
						];
					}
				}

				const logger = Logger.getLogger('twilio-video');

				const originalFactory = logger.methodFactory;
				logger.methodFactory = function (methodName, logLevel, loggerName) {
					const method = originalFactory(methodName, logLevel, loggerName);
					return function (datetime, logLevel, component, message, data) {
						const prefix = 'Djawn';
						method(prefix, datetime, logLevel, component, message, data);
					};
				};
				logger.setLevel('ERROR');

				connect(data.roomToken, twilioConfig)
					.then((room) => {
						setLoaded(true);
						setRoom(room);
					})
					.catch((err) => {
						// the reason data.owner was used was because data.owner is used when there is no participant at all but isOwner can be used when reconnecting
						if (data.owner) {
							revertEventStartedStatus(eventId).catch((err) => {
								console.error(err);
							});
						}
						savedEventJoinFailure(eventId, err).then(() => {
							if (isOwner) {
								navigate('/events', { state: { errorMessage: err.message } });
							} else if (window.location) {
								window.location.href = `${window.location.origin}/events`;
							} else {
								navigate('/events');
							}
						});
						setLoaded(true);
					});
				initializeConversation(isOwner, data.eventName);
			})
			.catch((err) => {
				setLoaded(true);
			});
	};

	useEffect(() => {
		if (!userId || initiallyAuthenticated) return;
		initializeConversation(false, eventName);
	}, [userId, initiallyAuthenticated, eventName]);

	const onTwilioVideoReconnect = useCallback(
		(settings = {}) => {
			if (
				(settings.overrideRefreshCheck
					&& settings.overrideRefreshCheck === true)
				|| (!twilioReconnectOnEveryRefresh
					&& roomRef
					&& roomRef.current
					&& roomRef.current.state
					&& roomRef.current.state === 'disconnected'
					&& !showThankYou)
				|| twilioReconnectOnEveryRefresh
			) {
				if (
					document
					&& document.body
					&& document.documentElement
					&& document.documentElement.scrollTop
				) {
					document.body.scrollTop = document.documentElement.scrollTop = 0;
				}
				let errorMessage = '';
				const resolvePromise = new Promise((resolve, reject) => {
					setTwilioConnectionHasBeenRefreshed(true);
					const isMobile = /iPhone|iPad|iPod|Android/i.test(
						navigator.userAgent
					);

					const twilioConfig = generateTwilioVideoConfig(
						videoOptions,
						videoRoomToken,
						isHost,
						isMobile,
						settings.audio && settings.audio === true,
						settings.video && settings.video === true
					);

					setRoom(null);
					let identity = nicknameRef.current;
					if (settings.identity) {
						identity = settings.identity;
					}
					reissueVideoToken(eventId, identity)
						.then((reissuedTokenRes) => {
							if (settings.onBeforeReconnect) settings.onBeforeReconnect();
							connect(reissuedTokenRes.data, twilioConfig)
								.then((reconnectedRoom) => {
									setRoom(reconnectedRoom);
									resolve();
									if (settings.onSuccess) {
										settings.onSuccess();
									}
								})
								.catch((err) => {
									console.error(err);
									if (!settings.video) {
										errorMessage = "You've blocked the mic access";
									} else {
										errorMessage =											'Video is blocked on this browser, please activate it from the settings and try again';
									}
									if (settings.overrideRefreshCheck) {
										if (settings.onFailure) {
											settings.onFailure();
										}
									}
									reject();
								});
						})
						.catch((err) => {
							resolve();
							console.error(err);
						});
				});

				// TODO: Test reconnect on twilio
				toast.promise(
					resolvePromise,
					{
						pending: settings.pendingMessage
							? settings.pendingMessage
							: 'Please wait while we connect you to the event.',
						success: {
							render: () => (settings.successMessage
								? settings.successMessage
								: 'Connection to the event has been reestablished.'),
							autoClose: 1500,
							position: 'top-center',
							hideProgressBar: false,
							pauseOnHover: false,
							draggable: true,
							type: 'success'
						},
						error: {
							render: () => errorMessage,
							autoClose: 1500,
							position: 'top-center',
							hideProgressBar: false,
							pauseOnHover: false,
							draggable: true,
							type: 'error'
						}
					},
					{
						position: 'top-center'
					}
				);

				resolvePromise.catch((err) => {
					if (settings.overrideRefreshCheck) {
						if (errorMessage) {
							const options = {
								pendingMessage: settings.pendingMessage,
								successMessage:
									settings.video && errorMessage ? '' : settings.successMessage
							};
							if (settings.identity) options.identity = settings.identity;
							onTwilioVideoReconnect(options);
						}
					}
					savedEventJoinFailure(eventId, `Failed refreshing ${err}`);
				});
			}
		},
		[eventId, nicknameRef.current, videoOptions, videoRoomToken, isHost]
	);

	const eventStopped = useCallback(
		(quit, onEventStillPlaying) => {
			isEventStopped(eventId)
				.then((res) => {
					if (
						res.data
						&& res.data.stopped === true
						&& res.data.additionalEndTimeInSeconds
						&& res.data.additionalEndTimeInSeconds > 0
					) {
						if (eventId === res.data.eventId) {
							setEventEndedDueToTechnicalIssues(
								res.data.endedDueToTechnicalIssues
							);
							setShowThankYou(true);
							setAdditionalEndTimeInSeconds(
								res.data.additionalEndTimeInSeconds
							);

							if (quit) {
								setGoToHome(true);
							}
						}
					} else if (onEventStillPlaying) {
						onEventStillPlaying();
					}
				})
				.catch((err) => {
					if (onEventStillPlaying) {
						onEventStillPlaying();
					}
				});
		},
		[eventId]
	);

	const onEventStopped = useCallback((seconds, endedDueToTechnicalIssues) => {
		if (!seconds) return;
		setShowThankYou(true);
		setEventEndedDueToTechnicalIssues(endedDueToTechnicalIssues);
		setAdditionalEndTimeInSeconds(seconds);
	}, []);

	useInterval(() => {
		if (!isHost) eventStopped(false);
	}, 100000);

	const saveTrack = useCallback(
		(trackId, kind, identity) => {
			let saveSubsribedRoomParam = nicknameRef.current;
			let saveTrackMethod = saveSubscribedTrack;
			const accessToken = getAccessToken();
			if (!accessToken) {
				const anonToken = localStorage.getItem(`anon-${eventId}`);
				if (anonToken) {
					saveSubsribedRoomParam = anonToken;
					saveTrackMethod = saveSubscribedTrackAnonymousUser;
				}
			}

			saveTrackMethod(eventId, saveSubsribedRoomParam, trackId, kind, identity);
		},
		[eventId, nicknameRef.current]
	);

	const exitEventRoom = useCallback(
		(xClicked, dontNavigate = false) => {
			let setTrue = false;
			let exitRoomParam = nicknameRef.current;
			let exitRoomMethod = exitRoom;
			const accessToken = getAccessToken();
			if (!accessToken) {
				const anonToken = localStorage.getItem(`anon-${eventId}`);
				if (anonToken) {
					exitRoomParam = anonToken;
					exitRoomMethod = exitRoomByAnonymousToken;
				}
			}
			setStopped(true);
			exitRoomMethod(eventId, exitRoomParam, webSocketGroupName)
				.then((res) => {
					if (xClicked) return;
					setTrue = true;
				})
				.catch((err) => {
					const mute = err;
				})
				.finally(() => {
					exitingByLeaveButtonRef.current = true;

					if (isHost) {
						navigate('/events', {
							state: {
								refresh: true,
								stopEventId: eventId,
								nickname: nicknameRef.current
							}
						});
					} else {
						/*
							this is a turnaround for an issue where the users would get kicked out of the room if
							they were to leave the room via 'navigate' functionality,
							this issue was never 'resolved' but this hack of using the window location href
							instead of navigating works, so we're using this one here for that purpose
						*/
						// eslint-disable-next-line no-lonely-if
						if (!dontNavigate) {
							if (window.location) {
								window.location.href = `${window.location.origin}/events`;
							} else {
								navigate('/events');
							}
						}
					}
				});

			if (setTrue) {
				setShowThankYou(true);
			}
		},
		[eventId, nicknameRef.current, isHost, webSocketGroupName]
	);

	useEffect(() => {
		eventStopped(true);
		if (isHost) {
			startRoom(eventId)
				.then((_) => {})
				.catch((_) => {})
				.finally((_) => {
					joinRoomViaTokens();
				});
		} else {
			joinRoomViaTokens();
		}
	}, []);

	const showBuyCards = useMemo(() => !isMobileSize, [isMobileSize]);

	useEffect(() => {
		if (landscapeMode && isMobileSize && isTablet) {
			setEnabledChatInput(true);

			if (
				arrowButtonRef
				&& arrowButtonRef.current
				&& arrowButtonRef.current.getBoundingClientRect
			) {
				const arrowButtonRect = arrowButtonRef.current.getBoundingClientRect();
				setLeftArrowButtonPosition(arrowButtonRect.left);
				setTopArrowButtonPosition(arrowButtonRect.top);
			}
		}

		if (!window || !isMobileSize || !conversation || landscapeMode) return;
		setEnabledChatInput(false);
		if (!landscapeMode && portraitChatHeight && portraitChatHeight > 0) {
			setConversationContainerHeight(portraitChatHeight);
			setEnabledChatInput(true);
			return;
		}
		if (
			conversationContainerRef.current
			&& conversationContainerRef.current.getBoundingClientRect
		) {
			const rect = conversationContainerRef.current.getBoundingClientRect();
			if (rect) {
				if (!landscapeMode) {
					const height = window.innerHeight - rect.top - 7;
					if (!isAndroid) {
						setConversationContainerHeight(height);
						setPortraitChatHeight(height);
					}
					const timer = isAndroid ? 4000 : 2000;

					setTimeout(() => {
						setEnabledChatInput(true);
						if (
							arrowButtonRef
							&& arrowButtonRef.current
							&& arrowButtonRef.current.getBoundingClientRect
						) {
							const arrowButtonRect = arrowButtonRef.current.getBoundingClientRect();
							setLeftArrowButtonPosition(arrowButtonRect.left);
							setTopArrowButtonPosition(arrowButtonRect.top);
						}
						if (
							!openQueueContainer
							&& popups
							&& (popups.show_event_name === true
								|| typeof popups.show_event_name === 'undefined')
							&& dismissedPopups
							&& (dismissedPopups.show_event_name === true
								|| typeof popups.dismissedPopups === 'undefined')
						) {
							if (
								eventNamePopupRef
								&& eventNamePopupRef.current
								&& eventNamePopupRef.current.open
							) {
								eventNamePopupRef.current.open();
							}
						}
					}, timer);
				}
			}
		}
	}, [evaluateConversationHeight, landscapeMode]);

	useEffect(() => {
		setTimeout(() => {
			if (
				popups
				&& popups.show_cards
				&& dismissedPopups
				&& dismissedPopups.show_cards
			) {
				if (
					arrowButtonContainerRef
					&& arrowButtonContainerRef.current
					&& arrowButtonContainerRef.current.open
				) {
					const arrowButtonRect = arrowButtonRef.current.getBoundingClientRect();
					setLeftArrowButtonPosition(arrowButtonRect.left);
					setTopArrowButtonPosition(arrowButtonRect.top);
					const height = window.innerHeight - arrowButtonRect.top - 50;
					setConversationContainerHeight(height);
					setPortraitChatHeight(height);
					arrowButtonContainerRef.current.open();
				}
			}
		}, 8000);
	}, [arrowButtonContainerRef]);

	const onExitRoom = useCallback(
		(addUsersInNewEvent, skipConfirmation = false) => {
			if (isHost) {
				if (addUsersInNewEvent === true) {
					addBuyersToNextLineInFutureEvent(eventId).catch((err) => {
						console.error(err);
					});
				}
				if (skipConfirmation) {
					exitEventRoom();
				} else {
					setShowSellerRoomLeaveConfirmation(true);
				}
			} else if (cartItems.length > 0) {
				setShowBuyerRoomLeaveConfirmation(true);
			} else {
				exitEventRoom();
			}
		},
		[eventId, isHost, cartItems]
	);

	const onConversationMessagesLoaded = useCallback(() => {
		setEvaluateConversationHeight((evaluate) => !evaluate);
	}, []);

	const onConversationRedirectToSignIn = useCallback(() => {
		exitEventRoom(false, true);
		navigate('/login', {
			state: {
				redirectUrl: `/event/room/${eventId}`
			}
		});
	}, [eventId]);

	const onConversationPurchasedItemsHyperlinkClicked = useCallback(
		(purchasedItemUserId) => {
			if (purchasedItemUserId) {
				setPurchasedProductsUserId(purchasedItemUserId);
			}
		},
		[]
	);

	const onConversationRandomizationHyperlinkClicked = useCallback(
		(breakEventId, isRandomizedBreak) => {
			if (breakEventId) {
				setPreselectedBreakEventIdOnOpenedView(breakEventId);
				const breakEvent = eventDetails.find(
					(e) => e.event.eventId == breakEventId
				);
				if (breakEvent && breakEvent.breaks && breakEvent.breaks.length) {
					const allSlotsPurchased = breakEvent.breaks.every(
						(b) => b.isPurchased
					);
					if (!allSlotsPurchased) {
						reloadAllEventDetails();
					}
				} else {
					reloadAllEventDetails();
				}
			}
			setActiveView(
				isRandomizedBreak ? RANDOMIZED_BREAKS_VIEW : PICK_YOUR_SLOTS_VIEW
			);
			setOpenQueueContainer(true);
		},
		[eventDetails]
	);

	const reloadAllEventDetails = useCallback(() => {
		getEventDetails(eventId, true, false).then((eventDetailRes) => {
			if (eventDetailRes.data.length > 0) {
				setLinkedEvents(
					eventDetailRes.data.filter(
						(e) => e.event.eventId != eventId
							&& e.event.statusId != EVENT_STOPPED_STATUS
					)
				);
			}
			setEventDetails(eventDetailRes.data);
		});
	}, [eventId]);

	if (!loaded) {
		return (
			<div className="w-screen h-screen bg-gray absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}

	return (
		<Layout>
			<div className="flex flex-col flex-1 dark:bg-black">
				{showPermissionDeniedModal && (
					<AccessDeniedMessage
						showModal={showPermissionDeniedModal}
						setShowModal={setShowPermissionDeniedModal}
						onExit={exitEventRoom}
					/>
				)}
				{isSeller ? (
					<SellerRoomLeaveConfirmation
						showModal={showSellerRoomLeaveConfirmation}
						setShowModal={setShowSellerRoomLeaveConfirmation}
						onExit={exitEventRoom}
					/>
				) : (
					<BuyerRoomLeaveConfirmation
						showModal={showBuyerRoomLeaveConfirmation}
						setShowModal={setShowBuyerRoomLeaveConfirmation}
						onExit={exitEventRoom}
					/>
				)}
				{!resize && (
					<RoomHeader
						siteTitle={`${
							breakIsInActiveLine && nameInActiveLine
								? nameInActiveLine
								: eventName
						} | DJAWN`}
						exitRoom={exitEventRoom}
						eventName={`${eventName}${
							mainEventDetails && mainEventDetails.event
								? ` - ${mainEventDetails.event.eventNumber}`
								: ''
						}`}
						showEventName={!isMobileSize}
						landscapeMode={landscapeMode}
						showCart
						cartOnClick={() => {
							const isInAppBrowser =								isAppCalledWithinFacebookAppBrowser()
								|| isAppCalledWithinInstagramAppBrowser();
							window.open(
								`${window.location.origin}/events?openCart=true${
									isInAppBrowser ? `&redirectEventId=${eventId}` : ''
								}`,
								'_blank'
							);
						}}
						cartItemCount={() => {
							let total = 0;
							cartItems.forEach((cartItem) => {
								if (cartItem.quantity) {
									total += cartItem.quantity;
								} else {
									total += 1;
								}
							});
							return total;
						}}
					/>
				)}
				<MiniCart
					isOpen={isCartOpen}
					showError={(item) => {
						showError(item);
					}}
					removeItemDispatch={(item) => {
						dispatch(removeCartItem(item));
					}}
					updateTimerDispatch={(item) => {
						dispatch(updateCartItemTimer(item));
					}}
					onPurchased={() => {
						setIsCartOpen(!isCartOpen);
					}}
					showThankYou
					cartItemLength={() => {
						let total = 0;
						cartItems.forEach((cartItem) => {
							if (cartItem.quantity) {
								total += cartItem.quantity;
							} else {
								total += 1;
							}
						});
						return total;
					}}
					addProductQuantityToCart={(product) => {
						changeProductQuantityToCart(product, 1);
					}}
					decreaseProductQuantityFromCart={(product) => changeProductQuantityToCart(product, -1)}
					closeCart={() => setIsCartOpen(!isCartOpen)}
					items={cartItems}
					liveEvent
				/>
				{openQueueContainer
					&& popups
					&& popups.show_views
					&& dismissedPopups
					&& dismissedPopups.show_views && (
					<div
						className={`flex justify-end flex-col items-end pr-2 absolute signin-popup${
							viewPopupFadingOut ? '-fade-out' : ''
						} ml-1`}
						style={
							isMobileSize
								? {
									zIndex: '60',
									top: '2.8rem',
									right: '9%'
									  }
								: { zIndex: '60', top: '4.9rem', left: '5.7rem' }
						}
					>
						<div className="triangle-up mr-4" style={{ zIndex: '60' }} />

						<div
							className="w-64 md:w-80 border rounded"
							style={{
								backgroundColor: '#0c6ae6',
								borderColor: '#0c6ae6',
								zIndex: '60'
							}}
						>
							<h3
								className="text-white text-sm pt-4 pl-3"
								style={{ zIndex: '60' }}
							>
									Click here to see more options.
							</h3>
							<div
								className="flex justify-between px-3 pt-3 mb-1"
								style={{ zIndex: '60' }}
							>
								<p
									className="cursor-pointer text-white text-md"
									onClick={() => updatePopupsStatus('show_views', true)}
									onKeyDown={() => updatePopupsStatus('show_views', true)}
								>
										Dismiss
								</p>
								<p
									className="cursor-pointer text-white text-md"
									onClick={() => updatePopupsStatus('show_views', false)}
									onKeyDown={() => updatePopupsStatus('show_views', false)}
								>
										Don't show again
								</p>
							</div>
						</div>
					</div>
				)}
				{popups
					&& popups.show_cards
					&& dismissedPopups
					&& dismissedPopups.show_cards
					&& showBuyCards
					&& !isMobileSize && (
					<div
						className={`flex justify-end flex-col items-start pr-2 md:left-0 absolute ${
							isMobileSize ? 'buy-cards-popup' : 'signin-popup'
						}${optionPopupFadingOut ? '-fade-out' : ''} ml-0 md:ml-1`}
						style={{
							left:
									isMobileSize && `${Math.abs(leftArrowButtonPosition - 3)}px`,
							zIndex: '60',
							top: isMobileSize
								? `${topArrowButtonPosition + 5}px`
								: 'calc(50% - 14rem)'
						}}
					>
						{isMobileSize && (
							<div
								className="triangle-up ml-0 md:ml-3"
								style={{ zIndex: '60' }}
							/>
						)}
						<div
							className="w-80 border rounded md:mt-0 -mt-1"
							style={{
								backgroundColor: '#0c6ae6',
								borderColor: '#0c6ae6',
								zIndex: '60'
							}}
						>
							<h3
								className="text-white text-sm pt-4 pl-3"
								style={{ zIndex: '60' }}
							>
									Click here to buy cards.
							</h3>
							<div
								className="flex justify-between px-3 pt-3 mb-1"
								style={{ zIndex: '60' }}
							>
								<p
									className="cursor-pointer text-white text-md"
									onClick={() => updatePopupsStatus('show_cards', true)}
									onKeyDown={() => updatePopupsStatus('show_cards', true)}
								>
										Dismiss
								</p>
								<p
									className="cursor-pointer text-white text-md"
									onClick={() => updatePopupsStatus('show_cards', false)}
									onKeyDown={() => updatePopupsStatus('show_cards', false)}
								>
										Don't show again
								</p>
							</div>
						</div>
						{!isMobileSize && (
							<div
								className="triangle-bottom ml-3"
								style={{ zIndex: '60' }}
							/>
						)}
					</div>
				)}
				<div className="mx-auto w-full h-full relative">
					{!showPermissionDeniedModal
						&& (!isMobileSize
							|| (isTablet && landscapeMode)
							|| openQueueContainer) && (
						<>
							<div
								className="animate-ping fixed inline-flex items-center justify-center duration-1000 bg-djawn text-gray-100 rounded-full focus:shadow-outline hover:bg-djawn-300"
								style={{
									top: isMobileSize
										? `${topArrowButtonPosition + 6}px`
										: 'calc(50% - 3.3rem)',
									outline: 'none',
									left: isMobileSize && !isTablet ? '7px' : '23px',
									width: '1.6rem',
									height: '1.6rem',
									zIndex: '60'
								}}
							/>
							<button
								type="button"
								className="fixed left-0 md:left-4 inline-flex items-center justify-center w-10 h-10 mr-2 text-indigo-100 transition-colors duration-150 bg-djawn rounded-full focus:shadow-outline hover:bg-djawn-300
						bg-opacity-50"
								style={{
									top: isMobileSize
										? `${topArrowButtonPosition}px`
										: 'calc(50% - 60px)',
									outline: 'none',
									zIndex: '60'
								}}
								onClick={() => {
									updatePopupsStatus('show_cards', true);
									setOpenQueueContainer(!openQueueContainer);
								}}
							>
								{openQueueContainer ? (
									<svg
										className="w-4 h-4 fill-current"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M9 5l7 7-7 7"
										/>
									</svg>
								) : (
									<svg
										className="w-4 h-4 fill-current"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M15 19l-7-7 7-7"
										/>
									</svg>
								)}
							</button>
						</>
					)}

					{loaded == true && videoRoomToken && !showPermissionDeniedModal ? (
						<TwilioTrackProvider
							eventId={eventId}
							isHost={isHost}
							twilioRoomIdentity={twilioRoomIdentity}
							setTwilioRoomIdentity={setTwilioRoomIdentity}
							hasSound={hasSound}
							setHasSound={setHasSound}
							websocketGroupName={webSocketGroupName}
							twilioRoomIdentityRef={twilioRoomIdentityRef}
						>
							<RipNShipActiveLineProvider
								nameInActiveLine={nameInActiveLine}
								setNameInActiveLine={setNameInActiveLine}
								breakIsInActiveLine={breakIsInActiveLine}
								setBreakIsInActiveLine={setBreakIsInActiveLine}
							>
								<VideoStream
									setSearchInputActive={setSearchInputActive}
									onTwilioVideoReconnect={onTwilioVideoReconnect}
									exitingByLeaveButtonRef={exitingByLeaveButtonRef}
									room={room}
									onChatHeightRecalalculate={() => {
										setPortraitChatHeight(0);
										setEvaluateConversationHeight((evaluate) => !evaluate);
									}}
									setIsRoomHost={setIsRoomHost}
									landscapeMode={landscapeMode}
									removeChildrenContainerHeight={
										isMobileSize && conversationContainerHeight > 0
									}
									roomRef={roomRef}
									additionalEndTimeInSeconds={additionalEndTimeInSeconds}
									isSeller={isSeller}
									eventDescription={eventDescription}
									purchasedProductsUserId={purchasedProductsUserId}
									setPurchasedProductsUserId={setPurchasedProductsUserId}
									showThankYou={showThankYou}
									saveTrack={saveTrack}
									isStopped={stopped}
									onOrientationChange={onOrientationChange}
									setRoom={setRoom}
									exitEventRoom={onExitRoom}
									addProductToCart={addProductToCart}
									cartItems={cartItems}
									changeProductQuantityToCart={changeProductQuantityToCart}
									setVideoHeight={setVideoHeight}
									onEventStopped={onEventStopped}
									resize={resize}
									setResize={setResize}
									eventStopped={eventStopped}
									arrowButtonContainerRef={arrowButtonContainerRef}
									openedCartWindow={openedCartWindow}
									setLeftArrowButtonPosition={setLeftArrowButtonPosition}
									setTopArrowButtonPosition={setTopArrowButtonPosition}
									showEventNameInChatContainer={showEventNameInChatContainer}
									setShowEventNameInChatContainer={
										setShowEventNameInChatContainer
									}
									showLinkedEventsSliderInMobileChatContainer={
										showLinkedEventsSliderInMobileChatContainer
									}
									setShowLinkedEventsSliderInMobileChatContainer={
										setShowLinkedEventsSliderInMobileChatContainer
									}
								>
									<div
										className={`w-full h-full relative ${
											landscapeMode && !isTablet ? 'hidden' : 'block'
										}`}
									>
										<div
											className={`
								row-span-1 md:col-span-1 w-full dark:bg-black ${
						isMobileSize ? 'mb-1 h-auto' : 'h-full'
						}`}
											ref={conversationContainerRef}
										>
											<div>
												{!resize && conversation != null ? (
													<div
														style={{
															height:
																isMobileSize || isTablet || videoHeight == 0
																	? '100%'
																	: `${videoHeight + 10}px`,
															marginTop: '5px'
														}}
													>
														<div
															className={`${
																conversationContainerHeight > 0
																&& ((isMobileSize && !isTablet)
																	|| (isTablet && !landscapeMode))
																	? ''
																	: isMobileSize && !isTablet
																		? 'chat-container-mobile'
																		: isTablet && !landscapeMode
																			? 'tablet-portait-live-event-video-height'
																			: 'chat-desktop-height'
															}  dark:text-white dark:bg-black`}
															id="chat-container-fx"
															style={
																conversationContainerHeight > 0
																&& ((isMobileSize && !isTablet)
																	|| (isTablet && !landscapeMode))
																	? {
																		height: `${conversationContainerHeight}px`
																	  }
																	: {}
															}
														>
															<Conversation
																startedConversation={conversation}
																showConversations
																removeToastContainer
																isSeller={isSeller}
																hideSidebar
																enabledChatInput={enabledChatInput}
																removeHiddenOverflows
																hideHeader
																enableMentions
																onMessagesLoaded={onConversationMessagesLoaded}
																absoluteEditorPosition={isMobileSize}
																removeListsFromToolbar
																limitedChatMessages={limitedChatMessages}
																chatLimits={chatLimits}
																borderTopRightRadius
																borderTopLeftRadius
																showUserNameTooltip={isSeller && !isMobileSize}
																borderBottomRightRadius
																borderBottomLeftRadius
																showScreenName
																hideAttachmentsButton
																reduceReplyMessageSize
																disableReplying
																authenticated={authenticated}
																removeDayDivider
																onRedirectToSignIn={
																	onConversationRedirectToSignIn
																}
																useInlineInputEditor
																insideLiveEvent
																onPurchasedItemsHyperlinkClicked={
																	onConversationPurchasedItemsHyperlinkClicked
																}
																onRandomizationHyperlinkClicked={
																	onConversationRandomizationHyperlinkClicked
																}
															>
																{isMobileSize
																&& (showLinkedEventsSliderInMobileChatContainer
																	|| showEventNameInChatContainer) ? (
																		<ChatOverlayPlugin
																			showEventNameInChatContainer={
																				showEventNameInChatContainer
																			}
																			setShowEventNameInChatContainer={
																				setShowEventNameInChatContainer
																			}
																			eventName={`${eventName}${
																				mainEventDetails && mainEventDetails.event
																					? ` - ${mainEventDetails.event.eventNumber}`
																					: ''
																			}`}
																			linkedEvents={linkedEvents}
																			showLinkedEventsSliderInMobileChatContainer={
																				showLinkedEventsSliderInMobileChatContainer
																			}
																			setShowLinkedEventsSliderInMobileChatContainer={
																				setShowLinkedEventsSliderInMobileChatContainer
																			}
																		/>
																	) : null}
															</Conversation>
														</div>
													</div>
												) : null}
											</div>
										</div>
									</div>
								</VideoStream>
							</RipNShipActiveLineProvider>
						</TwilioTrackProvider>
					) : null}
				</div>
			</div>
		</Layout>
	);
};

export default ReduxConnect(
	(state) => ({
		cartItems: state.cart.cartItems,
		userId: state.utils.userId,
		currentCheckoutSessionId: state.cart.currentCheckoutSessionId
	}),
	null
)(Room);
